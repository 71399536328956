import { MonetizeNowLogo, MonetizeNowTextLogo } from '@monetize/ui/icons';
import { FC } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AppMenu } from '../components/App/AppMenu';
import AppMenuTenantSwitch from '../components/App/AppMenuTenantSwitch';
import { AppHelpSearch } from '../components/AppSupport/AppHelpSearch';
import { MBox, MFlex, MGrid, MIcon, MTooltip } from '../components/Monetize';
import { ROUTES } from '../constants';
import { useAuth } from '../services/auth0';
import { appLayoutState } from '../store/global.store';
import { ProfileNav } from './ProfileNav';

const HoverLogo = ({
  isOverlay,
  toggleSidebar,
}: {
  isOverlay: boolean;
  toggleSidebar: () => void;
}) => {
  return (
    <MTooltip label={isOverlay ? 'Expand' : 'Collapse'} placement="bottom-end">
      <MFlex
        alignItems="center"
        justifyContent="center"
        role="group"
        h={8}
        w={8}
        onClick={toggleSidebar}
      >
        <MBox
          w={8}
          h={8}
          cursor="pointer"
          bgColor="transparent"
          display={'none'}
          justifyContent="center"
          alignItems="center"
          _groupHover={{
            display: 'block',
            backgroundColor: 'tPurple.dark',
          }}
          position="relative"
          zIndex="10"
        >
          <MIcon
            as={isOverlay ? MdKeyboardArrowRight : MdKeyboardArrowLeft}
            color="tGray.darkPurple"
            _hover={{
              color: 'tWhite.base',
            }}
            w={8}
            h={8}
          />
        </MBox>
        <MonetizeNowLogo height={23} width={28} />
      </MFlex>
    </MTooltip>
  );
};
const App: FC<any> = ({ children }: any) => {
  const { user, currentTenantUser } = useAuth();
  const [layoutMode, setLayoutMode] = useRecoilState(appLayoutState);

  const isOverlay = layoutMode === 'overlay';
  const location = useLocation();

  const toggleSidebar = () => {
    if (isOverlay) {
      setLayoutMode('static');
      return;
    }
    setLayoutMode('overlay');
  };

  const renderNavLogo = () => {
    return (
      <MFlex
        backgroundColor="tPurple.dark"
        alignItems="center"
        justifyContent="center"
        h={16}
        mb={3}
        pl={1}
      >
        <HoverLogo isOverlay={isOverlay} toggleSidebar={toggleSidebar} />
        {!isOverlay && (
          <MFlex alignItems="center" pl={1}>
            <MonetizeNowTextLogo height={18} width={140} />
          </MFlex>
        )}
      </MFlex>
    );
  };

  const isSettingsPath = location.pathname === ROUTES.SETTINGS;

  return (
    <MBox>
      <MGrid
        position="fixed"
        width={isOverlay ? 68 : 52}
        maxW={isOverlay ? 68 : 52}
        height="full"
        bgColor="tWhite.base"
        boxShadow="sideNavbar"
        gridTemplateRows="1fr 1fr 0.1fr"
      >
        <MBox maxW="inherit">
          {renderNavLogo()}
          <AppMenu layoutMode={layoutMode} />
        </MBox>

        <MBox flex="1" maxW="inherit">
          {isOverlay && (
            <MBox h="100%" cursor="pointer" onClick={toggleSidebar} />
          )}
        </MBox>

        <AppHelpSearch isOverlay={isOverlay} />
        <AppMenuTenantSwitch isOverlay={isOverlay} user={user} />
        <ProfileNav
          isOverlay={isOverlay}
          user={user}
          currentTenantUser={currentTenantUser}
        />
      </MGrid>

      <MBox
        marginLeft={isOverlay ? 68 : 208}
        transition="margin-left 0.2s"
        overflowX="clip"
        bgColor={isSettingsPath ? 'tGray.sidebarDark' : undefined}
      >
        {children || <Outlet />}
      </MBox>
    </MBox>
  );
};

export default App;
