import {
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  AccordionPanelProps,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MAccordionCustomButton, MText } from '.';

export interface MSettingAccordionItemProps extends AccordionItemProps {
  children?: React.ReactNode;
  description?: string;
  label?: string;
  accordionPanelProps?: AccordionPanelProps;
  renderAccordionButton?: ({
    isExpanded,
  }: {
    isExpanded: boolean;
  }) => React.ReactNode;
}

const MSettingAccordionItem: FC<MSettingAccordionItemProps> = React.forwardRef<
  any,
  MSettingAccordionItemProps
>(
  (
    {
      children,
      description,
      renderAccordionButton,
      label,
      accordionPanelProps,
      ...rest
    }: MSettingAccordionItemProps,
    ref,
  ) => {
    return (
      <AccordionItem
        borderWidth="1px"
        borderRadius="md"
        borderColor="tGray.back"
        {...rest}
      >
        {({ isExpanded }) => (
          <>
            {!!label && (
              <MAccordionCustomButton isExpanded={isExpanded} label={label} />
            )}

            {renderAccordionButton && renderAccordionButton({ isExpanded })}

            <AccordionPanel px={2} {...accordionPanelProps}>
              {!!description && (
                <MText color="tPurple.dark" mb={4}>
                  {description}
                </MText>
              )}

              {children}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  },
);

export { MSettingAccordionItem };
