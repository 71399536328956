import { CardBody, Flex } from '@chakra-ui/react';
import { toDateTimeShort } from '@monetize/utils/core';
import { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { handleApiErrorToast } from '../../api/axios';
import { useAuth } from '../../services/auth0';
import { INoteReqSchema, INoteResSchema, IUser } from '../../types';
import {
  MBox,
  MCard,
  MDropdownActionItem,
  MDropdownActions,
  MFlex,
  MIconAction,
  MText,
} from '../Monetize';
import { DeleteConfirmCard } from './DeleteConfirmCard';
import { NoteForm } from './NoteForm';

interface NoteCardProps {
  note: INoteResSchema;
  unsavedNotes?: Map<string, INoteResSchema>;
  handleNoteModified: (action: 'ADD' | 'CANCEL', note: INoteResSchema) => void;
  createNote: (data: INoteReqSchema) => void;
  updateNote: (note: INoteReqSchema) => void;
  deleteNote: (note: INoteReqSchema) => void;
  usersById: Record<string, IUser>;
  isReadOnly?: boolean;
}

export const NoteCard = (props: NoteCardProps) => {
  const {
    note,
    unsavedNotes,
    handleNoteModified,
    createNote,
    updateNote,
    deleteNote,
    usersById,
    isReadOnly,
  } = props;

  const { userId } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(
    () => !!unsavedNotes?.has(note.id),
  );
  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);

  const handleFormClose = () => {
    setIsEditMode(false);
    handleNoteModified('CANCEL', note);
  };
  const handleUpdatePopup = () => {
    setIsEditMode(true);
  };

  const handleDeleteConfirmOpen = () => {
    setDeleteConfirmVisible(true);
  };
  const handleDeleteConfirmClose = () => {
    setDeleteConfirmVisible(false);
  };
  const clickYesDelete = async () => {
    setLoading(true);
    try {
      await deleteNote(note);
    } catch (err) {
      handleApiErrorToast(err);
    }
    setLoading(false);
    setDeleteConfirmVisible(false);
  };

  const getUserAndDateForNote = (item: any) => {
    const user = usersById[item.createdBy];
    const date = toDateTimeShort(item.modifyDate);
    return user ? `${date} - ${user.name}` : date;
  };

  const renderNoteActionMenu = (data: INoteResSchema) => (
    <MDropdownActions
      popOverContentProps={{ minW: '100px' }}
      renderTrigger={() => (
        <MFlex justify="space-between" p={1}>
          <MIconAction icon={MdMoreVert} />
        </MFlex>
      )}
    >
      <MDropdownActionItem onClick={() => handleUpdatePopup()}>
        <MText color="inherit">Edit</MText>
      </MDropdownActionItem>

      <MDropdownActionItem
        onClick={() => handleDeleteConfirmOpen()}
        colorScheme="tRed.base"
      >
        <MText color="inherit">Delete</MText>
      </MDropdownActionItem>
    </MDropdownActions>
  );
  const isUserNoteCreator = note.createdBy === userId;

  return (
    <MCard variant="borderless">
      <CardBody display="flex" flexDir="column" p={!isEditMode ? 4 : 0}>
        {deleteConfirmVisible && (
          <DeleteConfirmCard
            loading={loading}
            deleteNote={clickYesDelete}
            cancelDelete={handleDeleteConfirmClose}
          />
        )}
        {isEditMode && !deleteConfirmVisible && (
          <NoteForm
            initialNote={unsavedNotes?.get(note.id) || note}
            onClose={handleFormClose}
            createNote={createNote}
            updateNote={updateNote}
            handleNoteModified={handleNoteModified}
          />
        )}
        {!isEditMode && !deleteConfirmVisible && (
          <MBox>
            <Flex alignItems="center" justifyContent="space-between">
              <MText fontSize="sm" color="tGray.darkPurple">
                {getUserAndDateForNote(note)}
              </MText>
              {!note.locked && !isReadOnly && isUserNoteCreator
                ? renderNoteActionMenu(note)
                : null}
            </Flex>

            <MText fontWeight="bold" fontSize="md" mt={3}>
              {note.subject}
            </MText>
            <MText mt="2" mb="2" fontSize="sm">
              {note.note}
            </MText>
          </MBox>
        )}
      </CardBody>
    </MCard>
  );
};
