import { object, string, z } from 'zod';

export enum AddressSourceEnum {
  CONTACT = 'CONTACT',
  ACCOUNT = 'ACCOUNT',
}

export const AddressSourceEnumZ = z.nativeEnum(AddressSourceEnum);

export const AddressSchema = object({
  id: string().optional(),
  line1: string().max(255).nullish(),
  line2: string().max(255).nullish(),
  city: string().nullish(),
  state: string().nullish(),
  postalCode: string().max(50).nullish(),
  country: string().nullish(),
  description: string().nullish(),
});
export type IAddressSchema = z.infer<typeof AddressSchema>;

export const ContactAddressDataSchema = z.object({
  addressSource: AddressSourceEnumZ,
  shippingAddressId: z.string().nullish(),
  billingAddressId: z.string().nullish(),
});

export type IContactAddressDataSchema = z.infer<
  typeof ContactAddressDataSchema
>;
