import { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  doGetContractsByAccount,
  doGetQuotesByAccount,
} from '../../../../../api/cpqService';
import {
  MCustomSelect,
  MFormField,
  MText,
} from '../../../../../components/Monetize';
import { StandardOptionRender } from '../../../../../components/Monetize/MCustomSelect/components/StandardOptionRender';
import { NEW_CONTRACT_OPTION } from '../../../../../constants/contracts';
import { NEW_CONTRACT_SLUG } from '../../../../../constants/quotes';
import { DEFAULT_PAGER, IContractWithQuotes } from '../../../../../types';
import { buildContractOptions } from '../../../../../utils/quotes';

interface ContractSelectFieldProps {
  accountId: string;
  contracts: IContractWithQuotes[];
  setContracts: (val: IContractWithQuotes[]) => void;
  setIsContractLoading: (value: boolean) => void;
  isContractLoading: boolean;
  accountCurrency?: string;
  selectedContract: IContractWithQuotes | undefined;
  setSelectedContract: (val: IContractWithQuotes | undefined) => void;
}

export const ContractSelectField: FC<ContractSelectFieldProps> = ({
  accountId,
  contracts,
  setContracts,
  setIsContractLoading,
  isContractLoading,
  accountCurrency,
  selectedContract,
  setSelectedContract,
}: ContractSelectFieldProps) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    const getContracts = async () => {
      try {
        setIsContractLoading(true);
        const contractData = await doGetContractsByAccount(accountId, {
          ...DEFAULT_PAGER,
          sortField: 'modifyDate',
          rows: 1000,
        });

        const quoteData = await doGetQuotesByAccount(accountId, {
          ...DEFAULT_PAGER,
          sortField: 'modifyDate',
          rows: 1000,
        });

        const finalContracts = buildContractOptions(
          contractData.content,
          quoteData.content,
        );
        !finalContracts.length && setValue('contractId', NEW_CONTRACT_SLUG);
        setContracts(finalContracts);
      } catch (error) {
        handleApiErrorToast(error);
      } finally {
        setIsContractLoading(false);
      }
    };

    if (accountId) {
      getContracts();
    }
  }, [accountId, setValue]);

  useEffect(() => {
    if (contracts && contracts.length === 1) {
      const defaultSelectedContract = contracts[0];
      setValue('contractId', defaultSelectedContract.id, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setSelectedContract(defaultSelectedContract);
    } else {
      setSelectedContract(undefined);
    }
  }, [contracts]);

  const renderContractItems = ({
    title,
    item,
  }: {
    title: string;
    item: IContractWithQuotes;
  }) => {
    return item.id !== NEW_CONTRACT_SLUG ? ( // the 'New Contract' option has id===NEW_CONTRACT_SLUG (and results in creation of a new Contract)
      <StandardOptionRender
        content={title}
        isSubtitle={false}
        isSelected={false}
        isHighlight={false}
      />
    ) : (
      <MText
        color="tIndigo.base"
        isTruncated
        noOfLines={1}
        fontSize="14px"
        mb="-2px"
        mt="0px"
        fontWeight="bold"
      >
        {title}
      </MText>
    );
  };

  return (
    <MFormField label="Select Contract" isRequired>
      <Controller
        name="contractId"
        control={control}
        defaultValue=""
        render={({ field: { onChange, ...rest } }) => (
          <MCustomSelect
            placeholder="Select Contract"
            items={[NEW_CONTRACT_OPTION].concat(
              contracts.map((contract) => ({
                ...contract,
                contractName: `${
                  contract.quotes[0]?.description
                    ? `${contract.quotes[0].description}-`
                    : ''
                }${contract.startDate}`,
              })),
            )}
            itemTitle="contractName"
            itemValue="id"
            renderItemContent={renderContractItems}
            loading={isContractLoading}
            clearable
            onChange={(e: any) => {
              onChange(e);
              const selectedItem = contracts.find(({ id }) => id === e);
              setSelectedContract(selectedItem);
              if (selectedItem) {
                setValue('legalEntityId', selectedItem.legalEntityId);
                setValue('currency', selectedItem.currency);
              } else if (accountCurrency) {
                /** At this step user has selected + New Contract for Select Contract select box
                 * and the Currency select box should select account currency by default
                 */
                setValue('currency', accountCurrency);
              }
            }}
            {...rest}
          />
        )}
      />
    </MFormField>
  );
};
