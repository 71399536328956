import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle(
  ({ isFitted, orientation, align = 'start' }) => {
    const alignments: any = {
      end: 'flex-end',
      center: 'center',
      start: 'flex-start',
    };
    return {
      root: defineStyle({
        display: orientation === 'vertical' ? 'flex' : 'block',
        marginBottom: 6,
      }),
      tab: defineStyle({
        flex: isFitted ? 1 : undefined,
        transitionProperty: 'common',
        transitionDuration: 'normal',
        _focus: {
          zIndex: 1,
        },
        _active: {
          bg: 'none',
        },
      }),
      tablist: defineStyle({
        justifyContent: alignments[align],
        flexDirection: orientation === 'vertical' ? 'column' : 'row',
      }),
      tabpanel: defineStyle({ p: 4 }),
    };
  },
);

const variantLine = definePartsStyle((props) => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp =
    orientation === 'vertical' ? 'borderStart' : 'borderBottom';
  const marginProp = isVertical ? 'marginStart' : 'marginBottom';

  return {
    tab: defineStyle({
      fontWeight: 'normal',
      fontSize: '14px',
      paddingStart: '2px',
      paddingEnd: '2px',
      marginRight: '1.5rem',

      [borderProp]: '2px solid',
      borderColor: 'transparent',
      [marginProp]: '-3px',
      color: 'tPurple.base',
      borderBottomWidth: '4px',
      _selected: {
        color: c,
        borderColor: 'currentColor',
        fontWeight: 'bold',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _hover: {
        borderColor: 'tPurple.dark',
        fontWeight: 'bold',
        _selected: {
          color: c,
          borderColor: 'currentColor',
        },
      },
    }),
    tablist: defineStyle({
      [borderProp]: '2px solid',
      paddingBottom: '3px',
      borderColor: 'tGray.support',
    }),
  };
});

const variantCentered = definePartsStyle((props) => {
  return {
    root: defineStyle({
      backgroundColor: 'tWhite.base',
      padding: '.25rem 0 .5rem 0',
      display: 'flex',
      justifyContent: 'center',
    }),
    tablist: {
      ...variantLine(props).tablist,
    },
    tab: {
      ...variantLine(props).tab,
    },
  };
});

const sizes = {
  sm: definePartsStyle({
    tab: defineStyle({
      py: 1,
      fontSize: 'sm',
    }),
  }),
  md: definePartsStyle({
    tab: defineStyle({
      fontSize: 'md',
      py: 2,
      px: 4,
    }),
  }),
  lg: definePartsStyle({
    tab: defineStyle({
      fontSize: 'lg',
      py: 3,
      px: 4,
    }),
  }),
};

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    line: variantLine,
    centered: variantCentered,
  },
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'line',
    colorScheme: 'tIndigo.base',
  },
});

export default checkboxTheme;
