import { formatCurrency } from '@monetize/utils/core';
import { Link } from 'react-router-dom';
import { useGetAnalyticsSalesRenewalPipeline } from '../../../../api/dashboardsService';
import {
  MBox,
  MChart,
  MFlex,
  MLink,
  MSkeleton,
  MText,
} from '../../../../components/Monetize';
import { ROUTES } from '../../../../constants';
import { logger } from '../../../../services/logger';
import { colors } from '../../../../styles/theme';
import { SalesCreatedByFilter } from '../../../../types';
import { AccessDeniedSmall } from '../../../AccessDeniedSmall';
import {
  salesTooltipStyles,
  upcomingRenewalChartOptions,
} from './chartOptions';

const UpcomingRenewalsChart = ({
  createdByFilter,
  currency,
}: {
  createdByFilter: SalesCreatedByFilter;
  currency: string;
}) => {
  const {
    isLoading,
    data: seriesData,
    error,
  } = useGetAnalyticsSalesRenewalPipeline<
    Array<{ data: { x: any; y: any; currency: any }[] }>
  >(
    { query: { ...createdByFilter, currency } },
    {
      select: (data) => [
        {
          data: data.renewalPipeline
            .sort((m1: any, m2: any) =>
              m1.yearMonth.localeCompare(m2.yearMonth),
            )
            .map((ele: any) => ({
              x: ele.yearMonth,
              y: ele.amount,
              currency: ele.currency,
            })),
        },
      ],
    },
  );

  const chartOptions = {
    ...upcomingRenewalChartOptions(currency),
    fill: {
      colors: [colors.tTurquoise.ocean],
    },
    colors: [colors.tTurquoise.ocean],
    stroke: {
      dashArray: 2,
    },
    tooltip: {
      custom: (chartData: any) => {
        try {
          const { series, seriesIndex, dataPointIndex } = chartData;
          return `<div class="custom-tooltip" style="${salesTooltipStyles}"> 
            <p>${
              seriesData ? seriesData[seriesIndex].data[dataPointIndex].x : ''
            }</p>
            <p>Total: <b>${formatCurrency(series[seriesIndex][dataPointIndex], {
              currency,
            })}</b></p>
          </div>`;
        } catch (err) {
          logger.warn(err);
          return '';
        }
      },
    },
  };

  return (
    <MBox>
      <MFlex alignItems="center">
        <MText fontWeight="bold" fontSize="2xl">
          Upcoming Renewals
        </MText>
        <MLink
          fontSize="sm"
          as={Link}
          to={ROUTES.SALES_CONTRACTS_ROUTE}
          cursor="pointer"
          ml="3"
          mt="1"
          state={createdByFilter}
        >
          View Contracts
        </MLink>
      </MFlex>

      {isLoading && <MSkeleton w="100%" h="10px" />}
      {!isLoading && !error && (
        <MChart
          options={chartOptions}
          series={seriesData || []}
          height="350px"
        />
      )}
      {!isLoading && !!error && (
        <AccessDeniedSmall containerProps={{ mt: '4' }} />
      )}
    </MBox>
  );
};

export { UpcomingRenewalsChart };
