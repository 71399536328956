import { FC } from 'react';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import {
  KeyValueCallout,
  MBox,
  MButton,
  MDivider,
  MFlex,
  MGrid,
  MPageLoader,
} from '../../../components/Monetize';
import { getAccountOverviewBillGroupNewRoute } from '../../../constants/routes';
import { useOverviewData } from '../../../hooks/AccountDetail';
import { useAccountPastDueInformation } from '../../../hooks/AccountDetail/useAccountPastDueInformation';
import { ACLCheck } from '../../../services/acl/ACLCheck';
import { useFlags } from '../../../services/launchDarkly';
import { IAccountRespSchema } from '../../../types';
import SubscriptionOverviewTable from './SubscriptionOverviewTable';

type AccountOverviewProps = {
  account: IAccountRespSchema;
  billGroupId?: string;
};

const AccountOverview: FC<AccountOverviewProps> = ({
  account,
  billGroupId,
}: AccountOverviewProps) => {
  const { allowBillGroupCreation } = useFlags();
  const { id: accountId, defaultCurrency: currency } = account;
  const { listData, accountOverviewStats, loadingAll, loadingOverview } =
    useOverviewData(accountId, billGroupId);
  const { getDueAmountByBillGroupId, totalAmountDue } =
    useAccountPastDueInformation(accountId);

  return (
    <MBox data-testid="account-overview-list" overflow="hidden">
      <MGrid
        justifyItems="start"
        gridTemplateColumns="repeat(5, 1fr 0.1fr)"
        columnGap={2}
      >
        <KeyValueCallout
          testIdRoot="account-overview"
          isLoading={loadingAll}
          type="currency"
          label="Recent Invoice"
          value={accountOverviewStats?.recentInvoiceAmount}
          currency={currency}
          verticalDividerAfter
        />
        <KeyValueCallout
          testIdRoot="account-overview"
          isLoading={loadingAll}
          type="currency"
          label="ARR"
          value={accountOverviewStats?.arr}
          currency={currency}
          verticalDividerAfter
        />
        <KeyValueCallout
          testIdRoot="account-overview"
          isLoading={loadingAll}
          type="currency"
          label="MRR"
          value={accountOverviewStats?.mrr}
          currency={currency}
          verticalDividerAfter
        />
        <KeyValueCallout
          testIdRoot="account-overview"
          isLoading={loadingAll}
          type="currency"
          label="Lifetime Value"
          value={accountOverviewStats?.lifetimeValue}
          currency={currency}
          verticalDividerAfter
        />
        <KeyValueCallout
          testIdRoot="account-overview"
          type="text"
          label="Currency"
          value={account?.defaultCurrency}
        />
      </MGrid>
      <MDivider mt={5} variant="thick" />
      {loadingOverview ? (
        <MPageLoader mt={20} height="auto" />
      ) : (
        <MFlex flexDirection="column">
          {allowBillGroupCreation && (
            <MFlex justifyContent="end" mt={6} mb={2}>
              <ACLCheck acls={[['billing', 'create']]}>
                <MButton
                  variant="secondary"
                  as={Link}
                  to={getAccountOverviewBillGroupNewRoute(accountId)}
                >
                  New Bill Group
                </MButton>
              </ACLCheck>
            </MFlex>
          )}
          {listData.map((data) => (
            <SubscriptionOverviewTable
              key={data.billgroup.id}
              data={data}
              account={account}
              pastDueAmount={getDueAmountByBillGroupId(data.billgroup.id)}
            />
          ))}
        </MFlex>
      )}
      <Outlet />
    </MBox>
  );
};

export default AccountOverview;
