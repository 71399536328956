import { ApexOptions } from 'apexcharts';
import { formatCurrency, formatNumber } from '.';
import { FONTS } from '../constants';
import { colors } from '../styles/theme';

export const CHART_CURRENCY_TOOLTIP: ApexOptions['tooltip'] = {
  custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
    `<div class="custom-tooltip"> <span> ${formatCurrency(
      series[seriesIndex][dataPointIndex],
    )}</span></div> `,
  followCursor: true,
};

export const getBaseLineOptionsCurrencyYAxis = (
  currency: string = 'USD',
): ApexOptions => {
  return {
    ...BASE_LINE_CHART_OPTIONS,
    yaxis: {
      ...BASE_LINE_CHART_OPTIONS.yaxis,
      labels: {
        ...(BASE_LINE_CHART_OPTIONS.yaxis as ApexYAxis).labels,
        formatter: (value: any) =>
          `${formatCurrency(value, { currency, maximumFractionDigits: 0 })}`,
      },
    },
    xaxis: {
      ...BASE_LINE_CHART_OPTIONS.xaxis,
      labels: {
        ...BASE_LINE_CHART_OPTIONS.labels,
        rotateAlways: false,
        rotate: 0,
      },
    },
  };
};

export const getBaseLineOptionsNumberYAxis = (): ApexOptions => {
  return {
    ...BASE_LINE_CHART_OPTIONS,
    yaxis: {
      ...BASE_LINE_CHART_OPTIONS.yaxis,
      labels: {
        ...(BASE_LINE_CHART_OPTIONS.yaxis as ApexYAxis).labels,
        formatter: (value: any) =>
          `${formatNumber(value, { maximumFractionDigits: 0 })}`,
      },
    },
  };
};

const chartColors = [
  colors.tIndigo.base,
  colors.tTurquoise.ocean,
  colors.tTurquoise.bayside,
  colors.tGreen.lime,
  colors.tBlue.totalBlue,
];
export const BASE_RADICAL_CHART_OPTIONS: ApexOptions = {
  chart: {
    width: '100%',
    height: '100%',
    type: 'radialBar',
  },
  colors: chartColors,
  fill: {
    colors: chartColors,
  },
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: '60%',
        background: 'transparent',
        image: undefined,
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          show: false,
        },
      },
    },
  },
  legend: {
    show: true,
    floating: true,
    fontSize: '16px',
    position: 'left',
    offsetX: 0,
    offsetY: 15,
    labels: {
      useSeriesColors: true,
    },
    itemMargin: {
      vertical: 3,
    },
  },
};

const BASE_LINE_CHART_OPTIONS: ApexOptions = {
  chart: {
    width: '100%',
    height: '100%',
    toolbar: {
      show: false,
    },
  },
  colors: [colors.tIndigo.base],
  fill: {
    colors: [colors.tIndigo.base],
  },
  plotOptions: {
    bar: {
      columnWidth: '20%',
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        colors: [colors.tPurple.base],
      },
      rotate: -45,
      rotateAlways: true,
    },
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      style: {
        colors: [colors.tGray.darkPurple],
      },
    },
  },

  grid: {
    borderColor: colors.tGray.support,
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
};

export const BASE_PIE_CHART_OPTIONS: Partial<ApexOptions> = {
  chart: {
    fontFamily: FONTS.ROBOTO,
  },
  dataLabels: {
    enabled: true,
    style: { colors: ['#000'] },
    dropShadow: {
      enabled: false,
    },
    background: {
      enabled: true,
      opacity: 0.5,
      foreColor: '#fff',
      borderColor: 'transparent',
      dropShadow: {
        enabled: false,
      },
    },
    formatter(val: any, opts: any) {
      return val ? `${+val.toFixed(1)} %` : '';
    },
  },
  plotOptions: {
    pie: {
      donut: {
        size: '80%',
      },
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    fontSize: '14px',
    fontFamily: FONTS.ROBOTO,
    fontWeight: 400,
  },
  markers: {
    // radius: 16,
  },
};

export const BASE_BAR_CHART_OPTIONS: Partial<ApexOptions> = {
  chart: {
    fontFamily: FONTS.ROBOTO,
    toolbar: {
      show: false,
    },
    width: '100%',
    height: '100%',
  },

  dataLabels: {
    enabled: false,
  },

  fill: {
    colors: chartColors,
  },
  colors: chartColors,

  plotOptions: {
    bar: {
      columnWidth: '20%',
    },
  },

  xaxis: {
    labels: {
      style: {
        colors: colors.tPurple.base,
      },
      rotate: -45,
      rotateAlways: true,
    },
  },

  yaxis: {
    labels: {
      style: {
        fontSize: '13px',
        colors: colors.tGray.lightPurple,
      },
      formatter: (value: any) => `${formatCurrency(value)}`,
    },
  },

  tooltip: CHART_CURRENCY_TOOLTIP,

  states: {
    normal: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
    hover: {
      filter: {
        type: 'none',
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
      },
    },
  },

  grid: {
    borderColor: '#F6F6F6',
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
};
