import isEmpty from 'lodash/isEmpty';
import {
  useGetAccountingFields,
  useGetMonetizeNowCustomFields,
  useGetUserDefinedAccountingFieldMappings,
} from '../../../../../api/fieldMappingService';
import { MFlex, MSkeleton, MText } from '../../../../../components/Monetize';
import {
  mapExistingFieldMappingFromMn,
  mapMatchingNetsuiteFields,
} from '../../../../../utils/accountingFieldMapping';
import { AccountingFieldMappingAccordion } from './fieldmapping/AccountingFieldMappingAccordion';

export const NetSuiteFieldMapping = () => {
  const { data: netsuiteFields, isLoading: isNetsuiteFieldLoading } =
    useGetAccountingFields('netsuite');
  const { monetizeNowFields, isLoading: isMnFieldLoading } =
    useGetMonetizeNowCustomFields('netsuite');

  const {
    data: userDefinedFieldMappings,
    isLoading: isUserDefinedFieldMappingLoading,
  } = useGetUserDefinedAccountingFieldMappings('netsuite', {
    enabled: !isEmpty(netsuiteFields) && !isEmpty(monetizeNowFields),
  });

  const { creditToCreditMemo } = mapMatchingNetsuiteFields(
    netsuiteFields,
    monetizeNowFields,
  );

  const { userDefinedCreditNotesToCreditMemo } = mapExistingFieldMappingFromMn(
    userDefinedFieldMappings,
    netsuiteFields,
    monetizeNowFields,
  );

  const isLoading =
    isNetsuiteFieldLoading ||
    isMnFieldLoading ||
    isUserDefinedFieldMappingLoading;

  const renderAccordionItems = (
    <>
      {!isEmpty(creditToCreditMemo) && (
        <AccountingFieldMappingAccordion
          system="netsuite"
          accountingObjectType="CreditNote"
          mnObjectType="CreditMemo"
          matchedFields={creditToCreditMemo}
          userDefinedFields={userDefinedCreditNotesToCreditMemo}
        />
      )}
    </>
  );
  return (
    <MFlex mt={6} gap="4" direction="column">
      <MFlex color="tPurple.dark" direction="inherit" gap="2">
        <MText fontWeight="semibold">Accounting Data Mapping</MText>
        <MText>
          Map data from MonetizeNow to your Accounting system for reporting,
          automation, or other purposes.
        </MText>
      </MFlex>
      <MFlex direction="inherit" gap="4">
        {isLoading ? (
          <>
            <MSkeleton height={12} w="full" />
            <MSkeleton height={12} w="full" />
            <MSkeleton height={12} w="full" />
          </>
        ) : (
          renderAccordionItems
        )}
      </MFlex>
    </MFlex>
  );
};
