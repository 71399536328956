import { Icon } from '@chakra-ui/react';
import type { Identifier, XYCoord } from 'dnd-core';
import { ChangeEvent, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { MdDragIndicator } from 'react-icons/md';
import {
  MCheckbox,
  MCustomIconButton,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
} from '../../../../components/Monetize';
import { ICustomFieldReqUiSchema } from '../../../../types';
import { getValueFromLabel } from '../../../../utils';

type DragItem = FieldArrayWithId<ICustomFieldReqUiSchema> & { index: number };

export const ItemTypes = {
  CUSTOM_FIELD_ROW: 'CUSTOM_FIELD_ROW',
};

interface CustomFieldsFormDropdownValueRowProps {
  control: Control<ICustomFieldReqUiSchema>;
  errors: FieldErrors<ICustomFieldReqUiSchema>;
  field: FieldArrayWithId<ICustomFieldReqUiSchema>;
  index: number;
  showDragHandle?: boolean;
  fieldExists: boolean;
  preventDelete?: boolean;
  setValue: UseFormSetValue<ICustomFieldReqUiSchema>;
  onMove: (dragIndex: number, hoverIndex: number) => void;
  onRemove: () => void;
}

export const CustomFieldsFormDropdownValueRow = ({
  control,
  errors,
  field,
  index,
  showDragHandle,
  fieldExists,
  preventDelete,
  setValue,
  onMove,
  onRemove,
}: CustomFieldsFormDropdownValueRowProps) => {
  const dropContainerRef = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CUSTOM_FIELD_ROW,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!dropContainerRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect =
        dropContainerRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: ItemTypes.CUSTOM_FIELD_ROW,
    item: (): DragItem => ({ ...field, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(dragHandleRef);
  drop(dragPreview(dropContainerRef));

  return (
    <MGrid
      ref={dropContainerRef}
      maxWidth="512px"
      templateColumns="20px 1fr 1fr 50px 20px"
      gap={2}
      mt={2}
      alignItems="start"
      data-handler-id={handlerId}
      opacity={isDragging ? 0 : 1}
      border={isDragging ? '1px solid' : undefined}
      borderColor={isDragging ? 'tGray.lightPurple' : undefined}
    >
      <MGridItem pt={1}>
        {showDragHandle && (
          <div ref={dragHandleRef}>
            <Icon
              as={MdDragIndicator}
              boxSize={5}
              color="tPurple.dark"
              cursor="move"
            />
          </div>
        )}
      </MGridItem>
      <MGridItem>
        <MFormField error={errors.values?.[index]?.label} isRequired>
          <Controller
            name={`values.${index}.label`}
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MInput
                {...rest}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  onChange(ev);
                  if (!fieldExists) {
                    setValue(
                      `values.${index}.value`,
                      getValueFromLabel(ev.target.value),
                    );
                  }
                }}
              />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem>
        <MFormField error={errors.values?.[index]?.value} isRequired>
          <Controller
            name={`values.${index}.value`}
            control={control}
            render={({ field }) => (
              <MInput isDisabled={fieldExists} {...field} />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem pt={1}>
        <MFormField error={errors.values?.[index]?.enabled}>
          <Controller
            name={`values.${index}.enabled`}
            control={control}
            render={({ field: { value, ...rest } }) => (
              <MCheckbox ml="4" pt={1} isChecked={value} {...rest} />
            )}
          />
        </MFormField>
      </MGridItem>
      <MGridItem pt={1.5}>
        {!preventDelete && (
          <MCustomIconButton
            variant="icon"
            mb={1}
            icon={BsTrash}
            onClick={() => onRemove()}
            iconColor="tRed.base"
          />
        )}
      </MGridItem>
    </MGrid>
  );
};
