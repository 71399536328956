import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSendEmail } from '../../api/accountsService';
import { handleApiErrorToast } from '../../api/axios';
import { useGetById } from '../../api/queryUtils';
import {
  MButton,
  MCenterModal,
  MFlex,
  MSkeleton,
  MStack,
  MText,
} from '../../components/Monetize';
import MEmailTextarea from '../../components/Monetize/MEmailTextarea';
import { useToast } from '../../services/toast';
import {
  EmailStringToEmailListSchema,
  getBillGroupCCEmailsResSchema,
  IBillGroupCCEmailsResSchema,
  IBillGroupResp,
  IInvoiceRespSchema,
} from '../../types';

interface IEmailInvoiceModalProps {
  invoice: IInvoiceRespSchema;
  isOpen: boolean;
  onClose: () => void;
  refreshData: () => void;
}

export const EmailInvoiceModal = ({
  invoice: { id: invoiceId, billingContact, billGroupId },
  isOpen,
  onClose,
  refreshData,
}: IEmailInvoiceModalProps) => {
  const { addToast } = useToast();
  const { mutateAsync: doSendEmail } = useSendEmail();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: billGroups, isLoading } = useGetById<IBillGroupResp>(
    'billGroups',
    billGroupId,
    {
      enabled: isOpen && !!billGroupId,
      meta: { showErrorToast: true },
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (billGroups) {
      setValue('strCcEmails', billGroups?.ccEmails?.join(',') ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billGroups]);

  const { handleSubmit, control, setValue } =
    useForm<IBillGroupCCEmailsResSchema>({
      mode: 'onChange',
      resolver: getBillGroupCCEmailsResSchema(),
      defaultValues: {
        strCcEmails: billGroups?.ccEmails?.join(',') ?? '',
      },
    });

  const onSubmit = async (formData: IBillGroupCCEmailsResSchema) => {
    setLoading(true);
    try {
      const result = EmailStringToEmailListSchema.safeParse(
        formData.strCcEmails,
      );
      await doSendEmail({
        invoiceId,
        overrideCcEmails: result.success ? result.data : null,
      });
      addToast({
        summary: 'Invoice Sent',
        detail: 'The Invoice has been successfully sent.',
        severity: 'success',
      });
      refreshData();
      onClose();
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setLoading(false);
    }
  };
  const renderFooter = () => (
    <MStack spacing={4} direction="row" align="center" justify="right" flex={1}>
      <MButton variant="cancel" onClick={onClose} minW="auto">
        Cancel
      </MButton>
      <MButton
        form="send-email-form"
        isLoading={loading}
        variant="primary"
        type="submit"
        minW="auto"
      >
        Send
      </MButton>
    </MStack>
  );

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Send Invoice through email"
      size="md"
      modalHeaderProps={{
        pb: 2,
      }}
      renderFooter={renderFooter}
    >
      <form id="send-email-form" onSubmit={handleSubmit(onSubmit)}>
        <MFlex direction="column" gap={4} w="full">
          <MText>
            This invoice will be emailed to{' '}
            <MText as="span" color="tPurple.dark" fontWeight="semibold">
              {billingContact.email}
            </MText>{' '}
            The recipient will be able to pay for the invoice through email.
          </MText>
          <MText>
            You can add emails that you would like to send the invoice to
          </MText>
          {isLoading ? (
            <MSkeleton height={14} />
          ) : (
            <MEmailTextarea
              label="Send to additional email addresses"
              tooltip="Use commas or new lines to add multiple email addresses."
              placeholder="Use commas or new lines to add multiple email addresses."
              fieldName="strCcEmails"
              control={control}
            />
          )}
        </MFlex>
      </form>
    </MCenterModal>
  );
};
