import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  z,
  ZodBoolean,
  ZodDefault,
  ZodEffects,
  ZodNullable,
  ZodObject,
  ZodOptional,
  ZodString,
} from 'zod';
import { apiGetAllList } from '../api/axios';
import { settingsQueryKeys } from '../api/settingsService';
import { composeFiltersQuery, ensureArray } from '../api/utils';
import {
  CustomFieldEntityEnum,
  CustomFieldPermissionsEnum,
  CustomFieldTypeEnum,
  ICustomFieldResSchema,
} from '../types';

// Ensure stable data to avoid re-renders
const DEFAULT_EMPTY_VALUES: ICustomFieldResSchema[] = [];

const useCustomFields = (
  entity: CustomFieldEntityEnum | Array<CustomFieldEntityEnum>,
  includeHidden = false,
  enabled = true,
  options: Partial<
    Omit<
      UseQueryOptions<ICustomFieldResSchema[]>,
      'queryFn' | 'queryKey' | 'select'
    >
  > = {},
) => {
  const entities = ensureArray(entity);
  const entitySet = new Set(entities);

  /**
   * Zod schemas for each entity which can be used to validate custom field values
   * and ensure they are the correct type before submitting to the API.
   */
  const [zodSchemasByEntity, setZodSchemasByEntity] = useState<
    Record<CustomFieldEntityEnum, ZodObject<any>>
  >({} as Record<CustomFieldEntityEnum, ZodObject<any>>);

  const { data: customFieldList = DEFAULT_EMPTY_VALUES, isLoading } = useQuery({
    queryKey: [...settingsQueryKeys.customFieldsListByEntity(entity)],
    queryFn: () =>
      apiGetAllList<ICustomFieldResSchema>('/api/configurations/customFields', {
        filters: {
          sort: 'entity:asc',
          ...composeFiltersQuery({ entity: { in: ensureArray(entity) } }),
        },
      }),

    // Sort, filter by entity, and optionally filter out hidden fields
    select: (fields) => {
      return fields.filter(
        (field) =>
          entitySet.has(field.entity) &&
          (includeHidden ||
            field.permissions.includes(CustomFieldPermissionsEnum.READ)),
      );
    },
    refetchOnWindowFocus: false,
    enabled,
    ...options,
  });

  useEffect(() => {
    setZodSchemasByEntity(getCustomFieldSchemaMap(customFieldList));
  }, [customFieldList]);

  return {
    customFieldList,
    zodSchemasByEntity,
    isLoading,
  };
};

const getCustomFieldSchemaMap = (customFieldList: ICustomFieldResSchema[]) => {
  return Object.entries(groupBy(customFieldList, 'entity')).reduce(
    (
      acc: Record<CustomFieldEntityEnum, ZodObject<any>>,
      [entity, customFields],
    ) => {
      acc[entity as CustomFieldEntityEnum] = z.object(
        customFields.reduce(
          (
            acc: Record<
              string,
              | ZodDefault<ZodBoolean>
              | ZodNullable<ZodOptional<ZodBoolean>>
              | ZodNullable<ZodOptional<ZodString>>
              | ZodEffects<any>
            >,
            { key, type },
          ) => {
            switch (type) {
              case CustomFieldTypeEnum.CHECKBOX:
                acc[key] = z
                  .boolean()
                  .nullish()
                  .default(false)
                  .transform((val) => val ?? false);
                break;
              case CustomFieldTypeEnum.NUMBER:
                acc[key] = z
                  .union([z.string(), z.number()])
                  .nullish()
                  .transform((val) =>
                    isNil(val) || val === '' ? null : Number(val),
                  );
                break;
              case CustomFieldTypeEnum.DATE:
              case CustomFieldTypeEnum.SINGLE_LINE_TEXT:
              case CustomFieldTypeEnum.DROPDOWN:
                acc[key] = z
                  .string()
                  .nullish()
                  .transform((val) => val || '');
                break;
            }
            return acc;
          },
          {},
        ),
      );

      return acc;
    },
    {} as Record<CustomFieldEntityEnum, ZodObject<any>>,
  );
};

export { getCustomFieldSchemaMap, useCustomFields };
