import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Center } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { MAlert, MBox } from '../components/Monetize';
import { recordShareState } from '../components/RecordShare/record-share.utils';

// Example URL:
// /share/tennt_VW00CDXt5Q26Lfor/quotes/quot_CSZ5LNW7IAA9j?token=2b76d2cc-e45d-423d-8e5e-23bef4e8f970

interface AnonymousRecordShareWithCodeVerifierLayoutProps {
  /**
   * Set to false to return an error message - e.x. based on feature flag or tenant configuration
   */
  enabled?: boolean;
  recordIdPathParmName?: string;
  children: ReactNode;
}

/**
 * Layout which requires login using a secret+code verifier
 * This layout managed the login process and stores the code verifier in local storage
 *
 * Recoil is used to manage setting/accessing the authentication state
 * and child components should only rely on recoil without having to manage any URL parameters
 */
export const AnonymousRecordShareWithCodeVerifierLayout = ({
  enabled,
  recordIdPathParmName = 'recordId',
  children,
}: AnonymousRecordShareWithCodeVerifierLayoutProps) => {
  const pathParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage] = useState(() => searchParams.get('error'));

  const storeParams = useMemo(
    () => ({
      tenantId: pathParams.tenantId,
      recordId: pathParams[recordIdPathParmName],
      token: searchParams.get('token'),
    }),
    [pathParams, recordIdPathParmName, searchParams],
  );

  const { tenantId, recordId, token } = storeParams;

  const [shareState, setShareState] = useRecoilState(recordShareState);

  useEffect(() => {
    if (searchParams.has('error')) {
      searchParams.delete('error');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    setShareState({ tenantId, recordId, token });
  }, [tenantId, recordId, token, setShareState]);

  const hasAccessError = !enabled || !tenantId || !recordId || !token;

  // Show error if we are missing key elements from URL
  if (hasAccessError) {
    return (
      <Center mt={'5%'}>
        <MAlert type="warning" title="Unauthorized" maxW="600px">
          The link you are trying to access is invalid. Contact the sender for a
          new link.
        </MAlert>
      </Center>
    );
  }

  if (!shareState) {
    return null;
  }

  if (errorMessage) {
    return (
      <MBox bg="tBlue.hover" w="100%" h="100vh">
        <MBox maxW={'500px'} p="6" m="auto">
          <MAlert type="error" title="Access Error" mb="6">
            {errorMessage}
          </MAlert>
        </MBox>
      </MBox>
    );
  }

  // TODO: login screen once we enable this feature
  // Show the login screen if the code verifier is not set
  // if (!codeVerifier) {
  //   return (
  //     <RecordShareLogin
  //       tenantId={tenantId}
  //       recordId={recordId}
  //       token={token}
  //       email={email}
  //       errorMessage={errorMessage}
  //       onVerified={handleSetCodeVerifier}
  //     />
  //   );
  // }

  // Allow user to continue if code verifier is set
  // Child components should interact with store to clear the codeVerifier if it is invalid
  return children;
};
