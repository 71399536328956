import { ICustomFieldRecordSchema } from '../types';
import { apiPut } from './axios';

export const doUpdateCustomFields = async ({
  entity,
  id,
  data,
}: {
  entity: 'credits' | 'creditNotes';
  id: string;
  data: ICustomFieldRecordSchema;
}) => {
  const res = await apiPut<ICustomFieldRecordSchema>(
    `/api/${entity}/${id}/customFields`,
    data,
  );
  return res.data;
};
