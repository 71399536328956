import { unparse } from 'papaparse';
import { useCallback, useState } from 'react';
import { apiGet } from '../api/axios';
import { composeFiltersQuery } from '../api/utils';
import { logger } from '../services/logger';
import {
  ApiListInfiniteScrollResponse,
  GetListApiFilter,
  MimeTypeEnum,
} from '../types';
import { TIntegrationLogResSchema } from '../types/integrationLogTypes';
import { saveFile } from '../utils/download';

type Header = {
  label: string;
  value: string | ((row: TIntegrationLogResSchema) => string);
};

export function useIntegrationLogCsvExport(initialQuery: GetListApiFilter) {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadCsv = useCallback(
    async (
      apiCallCount: number,
      headers: Header[],
      filename = 'integration_log.csv',
    ) => {
      setIsDownloading(true);
      let allData: TIntegrationLogResSchema[] = [];
      let nextParam: string | undefined = undefined;
      const baseQuery = { ...initialQuery };

      for (let i = 0; i < apiCallCount; i++) {
        const params = {
          ...composeFiltersQuery(baseQuery),
          ...(nextParam ? { next: nextParam } : {}),
        };

        try {
          const response = await apiGet<
            ApiListInfiniteScrollResponse<TIntegrationLogResSchema>
          >(`/connector/monitoring/sync-logs`, { params });

          const resData = response.data;
          allData = allData.concat(resData.content);
          nextParam = resData.next;

          if (!nextParam) {
            break;
          }
        } catch (error) {
          logger.error(`Error during API call ${i + 1}:`, error);
          break;
        }
      }

      // Transform data based on headers
      const transformedData = allData.map((row) => {
        const transformedRow: { [key: string]: string } = {};
        headers.forEach((header) => {
          if (typeof header.value === 'function') {
            transformedRow[header.label] = header.value(row);
          } else {
            transformedRow[header.label] = row[header.value] || '';
          }
        });
        return transformedRow;
      });

      const csvContent = unparse(transformedData, { header: true });

      saveFile(csvContent, filename, MimeTypeEnum.CSV);
      setIsDownloading(false);
    },
    [initialQuery],
  );

  return { downloadCsv, isDownloading };
}
