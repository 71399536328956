import { Controller } from 'react-hook-form';
import { useQuoteTemplatesList } from '../../../hooks/useQuoteTemplatesList';
import { IQuoteTemplateFormWrapperProps } from '../../../types';
import { MCustomSelect, MFormField } from '../../Monetize';
import { QuoteTemplateForm } from './QuoteTemplateForm';

export const QuoteTemplateFormWrapper = ({
  source = 'tenant',
  control,
  errors,
  isDisabled,
  requireSigningPage,
  handleSubmit,
  isAmendmentV2Quote,
}: IQuoteTemplateFormWrapperProps) => {
  const { quoteTemplates } = useQuoteTemplatesList();

  return (
    <form
      id="customize-quoteTemplate-form"
      data-testid="customize-quoteTemplate-form"
      style={{ marginTop: '0' }}
      onSubmit={handleSubmit}
    >
      <MFormField label="Template" maxW={56} pt={3}>
        <Controller
          name="defaultTemplate"
          control={control}
          render={({ field: { ...rest } }) => (
            <MCustomSelect
              items={quoteTemplates}
              isDisabled={source !== 'tenant' || isDisabled}
              {...rest}
            />
          )}
        />
      </MFormField>

      <QuoteTemplateForm
        source={source}
        control={control}
        errors={errors}
        isDisabled={isDisabled}
        requireSigningPage={requireSigningPage}
        isAmendmentV2Quote={isAmendmentV2Quote}
      />
    </form>
  );
};
