import { toDateShort } from '@monetize/utils/core';
import { parseISO } from 'date-fns/parseISO';
import {
  Control,
  Controller,
  UseFormClearErrors,
  useFormState,
} from 'react-hook-form';
import {
  MBox,
  MFormField,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '../../../../components/Monetize';
import { DatePicker } from '../../../../components/Monetize/DatePicker/DatePicker';
import {
  ContractCancelTypeEnum,
  ICancelContractUISchema,
  IContract,
} from '../../../../types';

interface CancelContractFormProps {
  contract: IContract;
  control: Control<ICancelContractUISchema>;
  clearErrors: UseFormClearErrors<ICancelContractUISchema>;
}
export const CancelContractForm = ({
  contract,
  control,
  clearErrors,
}: CancelContractFormProps) => {
  const { errors } = useFormState({ control });
  return (
    <MBox>
      <MText color="tGray.darkPurple" noOfLines={1} fontSize="sm" mb={4}>
        This contract term is {toDateShort(contract.startDate)} to{' '}
        {toDateShort(contract.endDate)}.
      </MText>
      <MFormField error={errors?.type} mb="2">
        <Controller
          name="type"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <MRadioGroup
              onChange={(value) => {
                onChange(value);
                if (
                  errors.date &&
                  value === ContractCancelTypeEnum.ENTIRE_CONTRACT
                ) {
                  clearErrors('date');
                }
              }}
              {...rest}
            >
              <MStack direction="column">
                <MRadio value={ContractCancelTypeEnum.ENTIRE_CONTRACT}>
                  Cancel entire Contract from beginning (
                  {toDateShort(contract.startDate)})
                </MRadio>
                <MRadio value={ContractCancelTypeEnum.SPECIFIC_DATE}>
                  Cancel entire Contract as of a specific date
                </MRadio>
              </MStack>
            </MRadioGroup>
          )}
        />
      </MFormField>
      <MFormField error={errors?.date} pl="5">
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <MBox maxWidth="11rem">
              <DatePicker
                minDate={parseISO(contract.startDate)}
                maxDate={parseISO(contract.endDate)}
                {...field}
              />
            </MBox>
          )}
        />
      </MFormField>
      <MText mt="5" color="tGray.darkPurple">
        Clicking “Next” will create an Amendment Quote to cancel the Contract.
        The Quote must be processed for the cancelation to take effect
      </MText>
    </MBox>
  );
};
