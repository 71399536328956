import { objectToSelectOptions } from '@monetize/utils/core';
import { z } from 'zod';
import { NetTermsEnum, NetTermsEnumZ } from './enums.types';

export enum NetTermsStatusEnum {
  PRIMARY = 'PRIMARY',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export const NetTermsStatusEnumZ = z.nativeEnum(NetTermsStatusEnum);

export interface INetTerm {
  name: string;
  value: NetTermsEnum;
  active: boolean;
  primary: boolean;
}

export const NetTermsReqSchema = z.object({
  NET_0: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_5: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_7: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_8: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_10: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_14: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_15: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_20: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_21: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_30: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_45: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_60: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_75: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_90: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_100: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_120: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_150: z.object({
    status: NetTermsStatusEnumZ,
  }),
  NET_180: z.object({
    status: NetTermsStatusEnumZ,
  }),
});

export type INetTerms = z.infer<typeof NetTermsReqSchema>;

export const NET_TERMS_DISPLAY: {
  [key in NetTermsEnum]: string;
} = {
  NET_0: 'Net 0',
  NET_5: 'Net 5',
  NET_7: 'Net 7',
  NET_8: 'Net 8',
  NET_10: 'Net 10',
  NET_14: 'Net 14',
  NET_15: 'Net 15',
  NET_20: 'Net 20',
  NET_21: 'Net 21',
  NET_30: 'Net 30',
  NET_45: 'Net 45',
  NET_60: 'Net 60',
  NET_75: 'Net 75',
  NET_90: 'Net 90',
  NET_100: 'Net 100',
  NET_120: 'Net 120',
  NET_150: 'Net 150',
  NET_180: 'Net 180',
};

export const NET_TERMS_OPTIONS = objectToSelectOptions(NET_TERMS_DISPLAY);

export const NetTermObjSchema = z.object({
  name: z.string(),
  value: NetTermsEnumZ,
  active: z.boolean(),
  primary: z.boolean(),
});

export const INetTermSchema = z.object({
  netTerms: z.array(NetTermObjSchema),
});
export type INetTermSchemaUI = z.infer<typeof INetTermSchema>;
