import { formatCurrency } from '@monetize/utils/core';
import { MGrid, MText } from '../../../../../components/Monetize';
import { IQuoteOfferingRespSchema } from '../../../../../types';

function getOfferingAmounts(
  quoteOffering?: IQuoteOfferingRespSchema | null,
  childQuoteOfferings?: IQuoteOfferingRespSchema[],
) {
  const output = {
    amount: quoteOffering?.amount || 0,
    amountWithoutDiscount: quoteOffering?.amountWithoutDiscount || 0,
  };
  if (Array.isArray(childQuoteOfferings)) {
    childQuoteOfferings.forEach(({ amount, amountWithoutDiscount }) => {
      output.amount += amount;
      output.amountWithoutDiscount += amountWithoutDiscount;
    });
  }

  return {
    amount: formatCurrency(output.amount),
    amountWithoutDiscount:
      output.amountWithoutDiscount - output.amount > 0
        ? formatCurrency(output.amountWithoutDiscount)
        : '',
  };
}

export interface QuoteOfferingActionsProps {
  quoteOffering?: IQuoteOfferingRespSchema | null;
  childQuoteOfferings?: IQuoteOfferingRespSchema[];
}

/**
 * Manages the quote offering menu based on the state of the quote offering
 */
export const QuoteOfferingAmount = ({
  quoteOffering,
  childQuoteOfferings = [],
}: QuoteOfferingActionsProps) => {
  if (!quoteOffering) {
    return null;
  }

  // get currency formatted values to show if offering is collapsed
  // combines parent and all scheduled offerings in amounts
  const { amount, amountWithoutDiscount } = getOfferingAmounts(
    quoteOffering,
    childQuoteOfferings,
  );

  return (
    <MGrid gridTemplateColumns="1fr" minH={8} maxH={8} alignSelf="center">
      <>
        {!!amountWithoutDiscount.length && (
          <MText
            gridColumn="1"
            gridRow="1"
            textDecoration="line-through"
            fontWeight="bold"
            color="tGray.darkPurple"
            textAlign="right"
            fontSize="xs"
            alignSelf="start"
            data-testid={`${quoteOffering.id}-offering-amountBeforeDiscount`}
          >
            {amountWithoutDiscount}
          </MText>
        )}
        <MText
          gridColumn="1"
          gridRow="1"
          textAlign="right"
          alignSelf={!amountWithoutDiscount.length ? 'center' : 'end'}
          fontWeight="bold"
          data-testid={`${quoteOffering.id}-amount`}
        >
          {amount}
        </MText>
      </>
    </MGrid>
  );
};
