import { DOCUSIGN } from '@monetize/ui/config';
import { ESIGN } from '../constants';
import { EsignAccountBaseUrlEnum } from '../types';

export const generateDocusignOauthUri = (
  acccountUrl: EsignAccountBaseUrlEnum,
  redirectUri: string,
) => {
  // https://monetizenow.atlassian.net/browse/BP-3717
  const oauthBaseUrl =
    ESIGN.DOCUSIGN_ACCOUNT_URL_TO_OAUTH_URL_MAPPING[acccountUrl] ||
    acccountUrl ||
    EsignAccountBaseUrlEnum.DEMO;

  return `${oauthBaseUrl}?response_type=code&scope=signature impersonation&client_id=${DOCUSIGN.clientId}&redirect_uri=${redirectUri}`;
};
