import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMonthsCustom, isBetween, toDateShort } from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { isBefore } from 'date-fns/isBefore';
import { isSameMonth } from 'date-fns/isSameMonth';
import { parseISO } from 'date-fns/parseISO';
import { startOfMonth } from 'date-fns/startOfMonth';
import isUndefined from 'lodash/isUndefined';
import { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import { FaCaretDown } from 'react-icons/fa';
import { MdChevronRight, MdMoreVert } from 'react-icons/md';
import {
  MBox,
  MButton,
  MCenter,
  MCircularProgress,
  MCustomIconButton,
  MDivider,
  MDropdownActionItem,
  MDropdownActions,
  MFlex,
  MIcon,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '../../../../../components/Monetize';
import {
  DatePicker,
  DatePickerProps,
} from '../../../../../components/Monetize/DatePicker/DatePicker';
import MPopoverWrapper from '../../../../../components/Monetize/MPopoverWrapper';
import { QuoteOfferingConfigurationDrawer } from '../../../../../components/Quotes/QuoteOfferingConfigurationDrawer';
import { useFlags } from '../../../../../services/launchDarkly';
import { logger } from '../../../../../services/logger';
import {
  IOfferingRes,
  IQuoteOfferingAmendActionSchema,
  IQuoteOfferingDeleteActionSchema,
  IQuoteOfferingRemoveReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResSchema,
  OfferingTypesEnum,
  PriceDisplayEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteOfferingAmendActionOptionEnum,
  QuoteOfferingAmendActionSchema,
  QuoteOfferingDeleteActionOptionEnum,
  QuoteOfferingDeleteActionSchema,
  QuoteOfferingGroupRemovalModeEnum,
  QuoteOfferingProps,
  QuoteOfferingRemovalScopeEnum,
  QuoteOfferingState,
  QuoteOfferingWithChildren,
  RateBillingFrequencyEnum,
  RateUsageBillingFrequencyEnum,
  UseQuoteOfferingAmendV2Return,
} from '../../../../../types';
import {
  getQuoteOfferingsFromParentOfferings,
  getQuoteOfferingsWithChildren,
  isSameQuoteOfferingGroup,
} from '../../../../../utils/quotes';
import { useQuoteContext } from '../../quoteContext';
import { hasUsageProducts } from './quoteOfferingUtils';

export interface QuoteOfferingActionsAmendV2Props
  extends Pick<
    QuoteOfferingProps,
    | 'quoteOffering'
    | 'isChildOffering'
    | 'childQuoteOfferings'
    | 'onChange'
    | 'parentQuoteOffering'
  > {
  quoteOfferingState: QuoteOfferingState;
  offering?: IOfferingRes | null;
  quoteOfferingLoading: boolean;
  isOfferingOpen: boolean;
  updateQuoteOffering: (
    id: string,
    data: IQuoteOfferingReqSchema,
  ) => Promise<IQuoteOfferingRespSchema | null>;
  deleteQuoteOffering: (id: string) => Promise<void>;
  quoteOfferingFormState: UseFormReturn<IQuoteOfferingReqSchema>;
  useQuoteOfferingAmendV2State: UseQuoteOfferingAmendV2Return;
  removeQuoteOffering: (
    data: IQuoteOfferingRemoveReqSchema,
  ) => Promise<IQuoteRespSchema | null>;
  handleAddScheduledChange: ({
    startDate,
    endDate,
    rateId,
  }: {
    startDate: string;
    endDate?: string;
    rateId: string;
  }) => Promise<IQuoteOfferingRespSchema | null>;
  quoteOfferings: QuoteOfferingWithChildren[];
  monthInterval: number;
  handleQuoteOfferingUpdate: ({
    priceDisplay,
  }: {
    priceDisplay?: PriceDisplayEnum;
  }) => void;
  offeringRate: IRateResSchema | null;
}

export const QuoteOfferingActionsAmendV2 = ({
  quoteOffering,
  useQuoteOfferingAmendV2State,
  isChildOffering,
  parentQuoteOffering,
  childQuoteOfferings,
  quoteOfferingState,
  quoteOfferingLoading,
  removeQuoteOffering,
  onChange: onOfferingChange,
  offering,
  handleAddScheduledChange,
  quoteOfferings,
  handleQuoteOfferingUpdate,
  offeringRate,
  monthInterval,
}: QuoteOfferingActionsAmendV2Props) => {
  const { allowOptionalProducts } = useFlags();
  const { quoteData, quoteStateData, isReadOnly } = useQuoteContext();
  const {
    quote,
    fetchQuote,
    setQuote,
    quoteDataOnInitialLoad,
    updateInitialQuoteData,
  } = quoteData;
  const showQuoteOfferingConfDrawer =
    quoteOffering &&
    quoteOffering.billingFrequency !== RateBillingFrequencyEnum.ONETIME &&
    offering &&
    ![
      OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL,
      OfferingTypesEnum.MIN_COMMIT,
    ].includes(offering.type);
  const isMinCommit = offering?.type === OfferingTypesEnum.MIN_COMMIT;

  const hasUsageProduct = hasUsageProducts(
    parentQuoteOffering,
    offering,
    allowOptionalProducts,
  );

  const { offeringLoadingState, setOfferingLoadingState } = quoteStateData;
  const { showRevertToOriginal, handleRevertOriginal } =
    useQuoteOfferingAmendV2State;
  const {
    isNewQuoteOfferingForm,
    isRemoved,
    quoteOfferingGroupStartDate,
    quoteOfferingGroupEndDate,
    isOfferingOnetime,
  } = quoteOfferingState;
  const isOfferingDeleting =
    offeringLoadingState[quoteOffering?.id || '']?.isDeleting ||
    offeringLoadingState[parentQuoteOffering?.id || '']?.isDeleting ||
    false;
  const isOfferingLoading =
    offeringLoadingState[quoteOffering?.id || '']?.isLoading ||
    offeringLoadingState[parentQuoteOffering?.id || '']?.isLoading ||
    false;

  const amendPopoverState = useDisclosure();
  const deletePopoverState = useDisclosure();
  const deleteConfigPopoverState = useDisclosure();

  const todayOrStartDate = useMemo(() => {
    const today = new Date();
    const startDate = parseISO(quoteOfferingGroupStartDate!);
    const endDate = parseISO(quoteOfferingGroupEndDate!);
    let calculatedStartDate: Date;

    if (startDate && endDate && isBetween(today, startDate, endDate)) {
      if (isMinCommit || hasUsageProduct) {
        const startDay = startDate.getDate();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
        const lastDayOfCurrentMonth = new Date(
          currentYear,
          currentMonth + 1,
          0,
        ).getDate();

        const day = Math.min(startDay, lastDayOfCurrentMonth);

        calculatedStartDate = new Date(currentYear, currentMonth, day);
      } else {
        calculatedStartDate = today;
      }
    } else {
      calculatedStartDate = startDate;
    }

    return formatISO(calculatedStartDate, { representation: 'date' });
  }, [
    isMinCommit,
    hasUsageProduct,
    quoteOfferingGroupStartDate,
    quoteOfferingGroupEndDate,
  ]);

  const amendFormState = useForm<IQuoteOfferingAmendActionSchema>({
    resolver: zodResolver(QuoteOfferingAmendActionSchema),
    mode: 'onChange',
    defaultValues: {
      date: '',
    },
  });

  const deleteFormState = useForm<IQuoteOfferingDeleteActionSchema>({
    resolver: zodResolver(QuoteOfferingDeleteActionSchema),
    mode: 'onChange',
    defaultValues: {
      date: todayOrStartDate,
    },
  });

  useEffect(() => {
    amendFormState.reset({
      date: todayOrStartDate,
    });
    deleteFormState.reset({
      date: todayOrStartDate,
    });
  }, [todayOrStartDate, amendFormState, deleteFormState]);

  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  const handleDelete = async (deleteReq?: IQuoteOfferingDeleteActionSchema) => {
    try {
      deletePopoverState.onClose();
      deleteConfigPopoverState.onClose();
      if (quoteOffering?.id) {
        setOfferingLoadingState((prevValue) => ({
          ...prevValue,
          [quoteOffering.id]: {
            id: quoteOffering.id,
            isLoading: true,
            isDeleting: true,
          },
        }));

        // in the else of this condition onetime offering deletion is added
        if (
          !isUndefined(deleteReq) &&
          deleteReq?.option === QuoteOfferingDeleteActionOptionEnum.AS_OF &&
          deleteReq?.date
        ) {
          const newQuoteResp = await removeQuoteOffering({
            removal_scope: QuoteOfferingRemovalScopeEnum.GROUP,
            group_removal_mode: QuoteOfferingGroupRemovalModeEnum.TARGET_DATE,
            group_id: quoteOffering.id,
            end_date: formatISO(addDays(parseISO(deleteReq?.date), -1), {
              representation: 'date',
            }),
          });

          // Make the last segment of the offering amendmentStatus to NO_CHANGE from frontend state
          // find quoteOffering with quoteOffering.id. parentOfferingId and manually fill in state
          if (newQuoteResp) {
            // Manually update the last segment locked once delete as of date, requirement from zach
            const quoteOfferingsWithChildren = getQuoteOfferingsWithChildren(
              newQuoteResp.quoteOfferings,
            );
            const foundQuoteOffering = quoteOfferingsWithChildren.find(
              (quoteOff) => quoteOffering.id === quoteOff.quoteOffering.id,
            );
            const lastQuoteOffering =
              (foundQuoteOffering?.children || []).length > 0
                ? foundQuoteOffering?.children[
                    foundQuoteOffering?.children.length - 1
                  ]
                : foundQuoteOffering?.quoteOffering;
            if (lastQuoteOffering) {
              lastQuoteOffering.items.forEach(
                (item) =>
                  (item.amendmentStatusOverwrite =
                    QuoteItemAmendmentStatusEnum.NO_CHANGE),
              );
              newQuoteResp.quoteOfferings = newQuoteResp.quoteOfferings.map(
                (quoteOff) =>
                  quoteOff.id === lastQuoteOffering.id
                    ? lastQuoteOffering
                    : quoteOff,
              );
            }

            updateInitialQuoteData('MERGE', newQuoteResp);
            setQuote(newQuoteResp);
          }
        } else {
          const newQuoteResp = await removeQuoteOffering({
            removal_scope: QuoteOfferingRemovalScopeEnum.GROUP,
            group_removal_mode: QuoteOfferingGroupRemovalModeEnum.CONTRACT,
            group_id: quoteOffering.id,
          });
          if (newQuoteResp) {
            setQuote(newQuoteResp);
          }
        }
        setOfferingLoadingState((prevValue) => {
          const value = { ...prevValue };
          delete value[quoteOffering.id];
          return value;
        });
      }
    } catch (error) {
      logger.error('Error while deleting quote offering ====>', error);
    }
  };

  const handleAmend = async (amendReq: IQuoteOfferingAmendActionSchema) => {
    try {
      amendPopoverState.onClose();
      if (quoteOffering?.id) {
        setOfferingLoadingState((prevValue) => ({
          ...prevValue,
          [quoteOffering.id]: {
            id: quoteOffering.id,
            isLoading: true,
            isDeleting: true,
          },
        }));
        if (
          amendReq.option ===
          QuoteOfferingAmendActionOptionEnum.START_OF_CONTRACT
        ) {
          const newOfferingResponse = {
            ...quoteOffering,
            items: quoteOffering.items.map((item) => ({
              ...item,
              amendmentStatus: QuoteItemAmendmentStatusEnum.UPDATED,
            })),
          };
          // update stored data so that if quote is updated we don't get our current state wiped out
          quoteDataOnInitialLoad.quoteOfferings[quoteOffering.id] =
            newOfferingResponse;
          newOfferingResponse.items.forEach((item) => {
            quoteDataOnInitialLoad.quoteItems[item.id] = item;
          });
          await onOfferingChange(
            'UPDATE',
            newOfferingResponse,
            undefined,
            true,
          );
        } else {
          const allOfferings = [
            quoteOffering,
            ...(childQuoteOfferings || []),
          ].filter((offering) => !!offering) as IQuoteOfferingRespSchema[];

          let shouldCreateScheduleChange = true;
          const foundOfferingIndex = allOfferings.findIndex(
            ({ startDate, endDate }) => {
              if (startDate === amendReq.date) {
                // if offering startDate and amendReq.date is same, we dont have to create a new schedule change
                shouldCreateScheduleChange = false;
                return true;
              } else {
                // else find that belongs in between start/end date
                return (
                  isBefore(parseISO(amendReq.date!), parseISO(endDate)) &&
                  isBefore(parseISO(startDate), parseISO(amendReq.date!))
                );
              }
            },
          );

          if (foundOfferingIndex !== -1) {
            if (shouldCreateScheduleChange) {
              const newQuoteOffering = await handleAddScheduledChange({
                rateId: allOfferings[foundOfferingIndex].rateId,
                startDate: amendReq.date!,
              });
              // Manual update local state to UPDATE to make the editable state
              newQuoteOffering &&
                quote?.id &&
                (await fetchQuote(quote?.id, false, (newQuote) => {
                  /**
                   * Before we use quote response from backend, it manipulates to manage the updated statu
                   * 1. newQuoteOffering and all it's related quote offering group:  should use the response from backend
                   * 2. All other quote offering: state should remain same.
                   *  */
                  const currentQuoteOfferings =
                    getQuoteOfferingsFromParentOfferings(quoteOfferings);
                  const updatedNewQuoteOffering = {
                    ...newQuoteOffering,
                    items: newQuoteOffering.items.map((item) => ({
                      ...item,
                      amendmentStatus: QuoteItemAmendmentStatusEnum.UPDATED,
                    })),
                  };
                  return {
                    ...newQuote,
                    quoteOfferings: newQuote.quoteOfferings.map((item) => {
                      if (
                        isSameQuoteOfferingGroup(updatedNewQuoteOffering, item)
                      ) {
                        if (item.id === updatedNewQuoteOffering.id) {
                          return updatedNewQuoteOffering;
                        } else {
                          return item;
                        }
                      } else {
                        return (
                          currentQuoteOfferings.find(
                            (currentItem) => currentItem.id === item.id,
                          ) || item
                        );
                      }
                    }),
                  };
                }));
            } else {
              // Manually update the status
              if (foundOfferingIndex === 0) {
                await onOfferingChange(
                  'UPDATE',
                  {
                    ...quoteOffering,
                    items: quoteOffering.items.map((item) => ({
                      ...item,
                      amendmentStatus: QuoteItemAmendmentStatusEnum.UPDATED,
                    })),
                  },
                  undefined,
                  true,
                );
              } else {
                await onOfferingChange(
                  'UPDATE',
                  {
                    ...allOfferings[foundOfferingIndex],
                    items: allOfferings[foundOfferingIndex].items.map(
                      (item) => ({
                        ...item,
                        amendmentStatus: QuoteItemAmendmentStatusEnum.UPDATED,
                      }),
                    ),
                  },
                  undefined,
                  true,
                );
              }
            }
          }
        }

        setOfferingLoadingState((prevValue) => {
          const value = { ...prevValue };
          delete value[quoteOffering.id];
          return value;
        });
      }
    } catch (error) {
      logger.error('Error while amending quote offering ====>', error);
    }
  };
  if (!quote) {
    return null;
  }

  const minDate = parseISO(quote.contractStartDate);
  const maxDate = parseISO(quote.contractEndDate);

  const getDatePickerOptions = (): Partial<DatePickerProps> => {
    const datepickerOptions: Partial<DatePickerProps> = {};
    if (isMinCommit || hasUsageProduct) {
      datepickerOptions.mode =
        offeringRate?.usageBillingFrequency ===
        RateUsageBillingFrequencyEnum.ANNUALLY
          ? 'YEAR'
          : 'MONTH';
      datepickerOptions.interval =
        offeringRate?.usageBillingFrequency ===
        RateUsageBillingFrequencyEnum.ANNUALLY
          ? 1
          : (monthInterval as 1 | 3 | 6);

      datepickerOptions.maxDate = maxDate;
      if (quote?.contractStartDate && isSameMonth(minDate, new Date())) {
        datepickerOptions.minDate = startOfMonth(new Date());
      } else if (quote?.contractStartDate && isBefore(minDate, new Date())) {
        datepickerOptions.minDate = startOfMonth(minDate);
      } else {
        datepickerOptions.minDate = addMonthsCustom(minDate, monthInterval);
      }
      datepickerOptions.baseDate = minDate;
    } else {
      datepickerOptions.minDate = minDate;
      datepickerOptions.maxDate = maxDate;
    }

    return datepickerOptions;
  };

  const datepickerAsOfDateOptions = getDatePickerOptions();

  const deletePopoverBody = (
    <Controller
      name="option"
      control={deleteFormState.control}
      render={({ field: { ...rest } }) => (
        <MRadioGroup {...rest}>
          <MStack columnGap={2}>
            {!isOfferingOnetime && (
              <>
                <MRadio
                  value={QuoteOfferingDeleteActionOptionEnum.AS_OF}
                  alignItems="start"
                >
                  As of
                  <Controller
                    name="date"
                    control={deleteFormState.control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <DatePicker
                        value={value as string}
                        popoverContentProps={{
                          onMouseDown: (e) => e.stopPropagation(),
                        }}
                        onChange={(e) => {
                          onChange(e);
                          deleteFormState.setValue(
                            'option',
                            QuoteOfferingDeleteActionOptionEnum.AS_OF,
                            { shouldValidate: true },
                          );
                        }}
                        {...datepickerAsOfDateOptions}
                        {...rest}
                      />
                    )}
                  />
                </MRadio>
                <MDivider />
              </>
            )}
            <MRadio
              value={QuoteOfferingDeleteActionOptionEnum.START_OF_CONTRACT}
              alignItems="start"
            >
              <MText>From Start of Contract</MText>
              <MText color="tGray.darkPurple" fontSize="xs">
                {toDateShort(quote.contractStartDate)}
              </MText>
            </MRadio>
            <MDivider />
            <MBox display="flex" justifyContent="end">
              <MButton
                variant="primary"
                size="sm"
                minW="auto"
                isDisabled={!deleteFormState.formState.isValid}
                onClick={deleteFormState.handleSubmit(handleDelete)}
              >
                Save
              </MButton>
            </MBox>
          </MStack>
        </MRadioGroup>
      )}
    />
  );

  if (isChildOffering || isNewQuoteOfferingForm) {
    return null;
  }

  return (
    <MFlex alignItems="center">
      {isOfferingDeleting && (
        <MBox
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          backgroundColor="white"
          opacity={0.75}
        >
          <MCenter height="100%" justifyContent="flex-end" pr="1">
            <MCircularProgress isIndeterminate size={4} />
          </MCenter>
        </MBox>
      )}

      {!isReadOnly && (
        <>
          {showRevertToOriginal && !isChildOffering ? (
            <MButton
              variant="tertiary"
              onClick={handleRevertOriginal}
              isDisabled={quoteOfferingLoading || anyOfferingLoading}
            >
              <MIcon as={BsArrowCounterclockwise} mr="1" boxSize={4} />
              Revert to Original
            </MButton>
          ) : (
            <>
              {!isOfferingOnetime && (
                <MPopoverWrapper
                  isOpen={amendPopoverState.isOpen}
                  placement="bottom"
                  onOpen={amendPopoverState.onOpen}
                  onClose={amendPopoverState.onClose}
                  usePortal
                  triggerContent={
                    <MButton
                      variant="tertiary"
                      minW="auto"
                      onClick={(ev) => {
                        ev.stopPropagation();
                      }}
                      isDisabled={quoteOfferingLoading || anyOfferingLoading}
                    >
                      Amend
                      <MIcon as={FaCaretDown} ml="1" boxSize={4} />
                    </MButton>
                  }
                  popoverContentProps={{ maxW: 210 }}
                  bodyContent={
                    <Controller
                      name="option"
                      control={amendFormState.control}
                      render={({ field: { ...rest } }) => (
                        <MRadioGroup {...rest}>
                          <MStack columnGap={2}>
                            <MRadio
                              value={QuoteOfferingAmendActionOptionEnum.AS_OF}
                              alignItems="start"
                            >
                              As of
                              <Controller
                                name="date"
                                control={amendFormState.control}
                                render={({
                                  field: { value, onChange, ...rest },
                                }) => (
                                  <DatePicker
                                    value={value as string}
                                    popoverContentProps={{
                                      onMouseDown: (e) => e.stopPropagation(),
                                    }}
                                    onChange={(e) => {
                                      onChange(e);
                                      amendFormState.setValue(
                                        'option',
                                        QuoteOfferingAmendActionOptionEnum.AS_OF,
                                        { shouldValidate: true },
                                      );
                                    }}
                                    {...datepickerAsOfDateOptions}
                                    {...rest}
                                  />
                                )}
                              />
                            </MRadio>
                            <MDivider />
                            <MRadio
                              value={
                                QuoteOfferingAmendActionOptionEnum.START_OF_CONTRACT
                              }
                              alignItems="start"
                            >
                              <MText>From Start of Contract</MText>
                              <MText color="tGray.darkPurple" fontSize="xs">
                                {toDateShort(quote.contractStartDate)}
                              </MText>
                            </MRadio>
                            <MDivider />
                            <MBox display="flex" justifyContent="end">
                              <MButton
                                variant="primary"
                                size="sm"
                                minW="auto"
                                isDisabled={!amendFormState.formState.isValid}
                                onClick={amendFormState.handleSubmit(
                                  handleAmend,
                                )}
                              >
                                Save
                              </MButton>
                            </MBox>
                          </MStack>
                        </MRadioGroup>
                      )}
                    />
                  }
                />
              )}

              {isOfferingOnetime ? (
                <MButton
                  variant="deleteOutline"
                  minW="auto"
                  border="none"
                  onClick={(ev) => {
                    handleDelete();
                  }}
                  isDisabled={quoteOfferingLoading || anyOfferingLoading}
                  bgColor="transparent"
                >
                  Delete
                </MButton>
              ) : (
                <MPopoverWrapper
                  isOpen={deletePopoverState.isOpen}
                  placement="bottom"
                  onOpen={deletePopoverState.onOpen}
                  onClose={deletePopoverState.onClose}
                  usePortal
                  triggerContent={
                    <MButton
                      variant="deleteOutline"
                      minW="auto"
                      border="none"
                      onClick={(ev) => {
                        ev.stopPropagation();
                      }}
                      isDisabled={quoteOfferingLoading || anyOfferingLoading}
                      bgColor="transparent"
                    >
                      Delete
                      <MIcon as={FaCaretDown} ml="1" boxSize={4} />
                    </MButton>
                  }
                  popoverContentProps={{ maxW: 210 }}
                  bodyContent={deletePopoverBody}
                />
              )}
            </>
          )}
        </>
      )}

      {/* Ensure that we have space here even if the menu is not rendered */}
      <MBox minW="24px">
        {(showQuoteOfferingConfDrawer ||
          (!isRemoved && showRevertToOriginal && !isReadOnly)) && (
          <MDropdownActions
            popOverContentProps={{ minW: '210px' }}
            renderTrigger={() => (
              <MCustomIconButton
                variant="icon"
                btnSize={4}
                containerSize={6}
                p={3}
                icon={MdMoreVert}
                isDisabled={quoteOfferingLoading || anyOfferingLoading}
              />
            )}
          >
            {showQuoteOfferingConfDrawer && (
              <QuoteOfferingConfigurationDrawer
                renderTrigger={({ onOpen, isDisabled }) => (
                  <MDropdownActionItem onClick={onOpen}>
                    <MText color="inherit">Configure settings</MText>
                  </MDropdownActionItem>
                )}
                isDisabled={quoteOfferingLoading || anyOfferingLoading}
                onOfferingChange={onOfferingChange}
                quoteOffering={quoteOffering}
                isReadOnly={isReadOnly}
                handleQuoteOfferingUpdate={handleQuoteOfferingUpdate}
                offering={offering}
                offeringRate={offeringRate}
                quote={quote}
                isOfferingLoading={isOfferingLoading}
              />
            )}

            {!isRemoved && showRevertToOriginal && !isReadOnly && (
              <MPopoverWrapper
                isOpen={deleteConfigPopoverState.isOpen}
                placement="right"
                onOpen={deleteConfigPopoverState.onOpen}
                onClose={deleteConfigPopoverState.onClose}
                triggerContent={
                  <MDropdownActionItem onClick={() => {}} color="tRed.base">
                    <MFlex justifyContent="space-between" alignItems="center">
                      <MText color="inherit">Delete</MText>
                      <MIcon as={MdChevronRight} boxSize={4} />
                    </MFlex>
                  </MDropdownActionItem>
                }
                popoverContentProps={{ maxW: 210 }}
                bodyContent={deletePopoverBody}
              />
            )}
          </MDropdownActions>
        )}
      </MBox>
    </MFlex>
  );
};
