import { useStyleConfig } from '@chakra-ui/react';
import { Maybe } from '@monetize/types/common';
import isEmpty from 'lodash/isEmpty';
import React, { FocusEvent, MouseEvent, useMemo } from 'react';
import { truncateMiddle } from '../../../../utils';
import { MainInputProps } from './MainInput';

function isDescendant(containerId: string, childElement?: Maybe<Element>) {
  if (!containerId || !childElement) {
    return false;
  }
  const container = document.querySelector(
    `[data-content-id="${containerId}"]`,
  );
  return !!container?.contains(childElement);
}

/**
 * Logic for managing state for an input component used in a CustomSelect component
 * Events can be tricky to handle properly when dealing with a popover and input
 * This hook helps to manage the state and events for the input component
 */
export const useCustomSelectInput = (
  {
    clearable,
    containerId,
    disabled,
    getTitleFromValue,
    inputProps,
    isOpen,
    isTruncatedInputTitle,
    onChange,
    onClear,
    onClose,
    onOpen,
    query,
    renderRightElement,
    showQueryInput,
    useMainInputAsSearchInput,
    value,
  }: Omit<MainInputProps, 'ref'>,
  ref?: React.ForwardedRef<HTMLInputElement>,
) => {
  const styles = useStyleConfig('Input', {
    variant: inputProps?.variant ?? 'primary',
  });

  const handleClickInput = (ev: MouseEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  const handleFocusInput = (ev: FocusEvent<HTMLInputElement>) => {
    if (disabled) {
      return;
    }
    if (!isOpen) {
      onOpen();
    }
  };

  const handleToggleOpen = () => {
    if (disabled) {
      return;
    }

    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }

    // if the dropdown contains an input, don't focus on the input
    if (!useMainInputAsSearchInput) {
      return;
    }
  };

  const handleOnClear = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (onClear) {
      onClear();
    } else {
      onChange && onChange('' as any);
    }
  };

  const bgColor = useMemo(() => {
    if (disabled) {
      return 'tGray.back';
    }
    if (isOpen) {
      return 'tBlue.hover';
    }
    return 'tWhite.base';
  }, [disabled, isOpen]);

  const borderColor = isOpen ? 'tBlue.lightShade' : 'tGray.lightPurple';

  const rightElement = renderRightElement && renderRightElement({ value });

  const showClearBtn = clearable && !isEmpty(value) && !disabled;

  let rightMargin = rightElement ? rightElement.width : 8;

  if (showClearBtn) {
    rightMargin += 4;
  }

  let inputValue = '';

  if (isOpen && useMainInputAsSearchInput) {
    inputValue = query;
  } else {
    const title = getTitleFromValue(value) || '';
    inputValue = isTruncatedInputTitle ? truncateMiddle(title) : title;
  }

  return {
    bgColor,
    borderColor,
    handleClickInput,
    handleFocusInput,
    handleOnClear,
    handleToggleOpen,
    inputValue,
    rightElement,
    rightMargin,
    showClearBtn,
    styles,
  };
};
