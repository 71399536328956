import React from 'react';
import { BsPeople, BsPeopleFill } from 'react-icons/bs';
import {
  MBox,
  MCustomIconButton,
  MTooltip,
} from '../../../../../components/Monetize';
import { IQuoteContacts } from '../../../../../types';

interface QuoteContactIconProps {
  contacts: IQuoteContacts;
  showPrimaryContactRequiredBg: boolean;
  onOpen: () => void;
}

export const QuoteContactIcon = React.forwardRef(
  (
    { contacts, showPrimaryContactRequiredBg, onOpen }: QuoteContactIconProps,
    ref,
  ) => {
    const hasContactsConfigured =
      contacts.billing || contacts.primary || contacts?.esign.length;
    let iconColor = hasContactsConfigured ? 'tIndigo.base' : 'tPurple.base';
    let bgColor = showPrimaryContactRequiredBg ? 'tWhite.focus' : 'transparent';
    let iconName = !hasContactsConfigured ? BsPeople : BsPeopleFill;

    if (showPrimaryContactRequiredBg && !hasContactsConfigured) {
      iconColor = 'tOrange.base';
      bgColor = 'tOrange.bg';
      iconName = BsPeopleFill;
    }

    return (
      <MBox position="relative">
        <MTooltip label="Contacts" placement="bottom-end">
          <MBox>
            <MCustomIconButton
              variant="icon"
              btnSize={6}
              aria-label="Signature Contacts"
              p={4}
              icon={iconName}
              onClick={() => onOpen()}
              bg={bgColor}
              iconColor={iconColor}
              iconProps={{ _active: { iconColor } }}
            />
          </MBox>
        </MTooltip>
      </MBox>
    );
  },
);

export default QuoteContactIcon;
