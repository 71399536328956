import { GridProps } from '@chakra-ui/react';
import { formatCurrency } from '@monetize/utils/core';
import { MBox, MText } from '../../../../components/Monetize';
import { FooterRow } from '../../../../components/Quotes/FooterRow';
import { QUOTE_TOTAL_EDIT_STYLE } from '../../../../constants/quotes';
import { useFlags } from '../../../../services/launchDarkly';
import { getVariableAmountIndicator } from '../../../../utils/quotes';
import { useQuoteContext } from '../quoteContext';

export interface QuoteFormTotalRowProps {
  anyVariableAmountProducts: boolean;
}

export const QuoteFormTotalRow = ({
  anyVariableAmountProducts,
}: QuoteFormTotalRowProps) => {
  const {
    quoteData: { quote },
  } = useQuoteContext();

  const variableAmountIndicator = getVariableAmountIndicator(
    anyVariableAmountProducts,
  );
  const { totalExclVat } = useFlags();

  const totalCellStyles: GridProps = quote
    ? QUOTE_TOTAL_EDIT_STYLE[quote?.type]
    : {};

  const borderStyles: GridProps = totalCellStyles?.borderColor
    ? {
        borderWidth: '2px',
        borderRadius: 'md',
        borderColor: totalCellStyles.borderColor,
      }
    : { borderRadius: 'md' };

  return (
    <MBox mt={2}>
      <FooterRow
        name="Subtotal"
        value={
          formatCurrency(quote?.amountWithoutDiscount, {
            currency: quote?.currency,
          }) + variableAmountIndicator
        }
      />
      {!!quote?.discountAmount && (
        <FooterRow
          name="Discount"
          childrenProps={{ fontWeight: 'normal' }}
          value={
            `(${formatCurrency(quote?.discountAmount, {
              currency: quote?.currency,
            })})` + variableAmountIndicator
          }
        />
      )}
      {!!quote?.amount && (
        <FooterRow
          containerProps={{
            'data-testid': 'quoteTotalDue',
            ...totalCellStyles,
            ...borderStyles,
          }}
          name={`Total${totalExclVat ? ' excl. VAT' : ''}`}
          nameProps={{
            fontSize: 'lg',
            lineHeight: 'none',
            color: totalCellStyles.color,
          }}
          childrenProps={{
            fontSize: 'lg',
            lineHeight: 'none',
            color: totalCellStyles.color,
          }}
          value={`${formatCurrency(quote.amount, {
            currency: quote.currency,
          })}${variableAmountIndicator}`}
          dividerProps={{ display: 'none' }}
        />
      )}

      {anyVariableAmountProducts && (
        <MText fontWeight="400" pt="2" textAlign="right">
          *Amount can increase due to usage products.
        </MText>
      )}
    </MBox>
  );
};
