import { toDateShort } from '@monetize/utils/core';
import React, { FunctionComponent as FC, useMemo } from 'react';
import {
  DELATED_BILLING_QUOTE_START_SOURCE,
  QUOTE_PROGRESS_CANCEL_MESSAGE,
} from '../../../constants/quotes';
import { useQuoteSettings } from '../../../hooks';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  QuoteCancelationReasonEnum,
  QuoteStatusEnum,
} from '../../../types';
import { MFlex } from '../../Monetize';
import { QuoteStepApproval } from './QuoteStepApproval';
import { QuoteStepCustomer } from './QuoteStepCustomer';
import { QuoteStepEdit } from './QuoteStepEdit';
import { QuoteStepProcess } from './QuoteStepProcess';
import { QuoteStepSign } from './QuoteStepSign';

interface QuoteProgressBarProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData: () => void;
}

export const QuoteProgressBar: FC<QuoteProgressBarProps> = React.forwardRef<
  any,
  QuoteProgressBarProps
>((props: QuoteProgressBarProps, ref) => {
  const { quote } = props;
  const { quoteSettings } = useQuoteSettings();

  const pauseAtAccepted =
    quoteSettings?.pauseAtAccepted ||
    quote?.status === QuoteStatusEnum.ACCEPTED ||
    (!!quote.startDateSource &&
      DELATED_BILLING_QUOTE_START_SOURCE.has(quote.startDateSource));

  const overwriteStepMessage = useMemo(() => {
    const isCanceled = quote.status === QuoteStatusEnum.CANCELED;
    const isExpired = quote.status === QuoteStatusEnum.EXPIRED;

    if (isCanceled) {
      return {
        stepDate: toDateShort(quote.modifyDate),
        stepTitle: quote.cancellationReason
          ? QUOTE_PROGRESS_CANCEL_MESSAGE[quote.cancellationReason]
          : 'Cancelled',
        isError: true,
        showLightBackground:
          isCanceled &&
          quote.cancellationReason === QuoteCancelationReasonEnum.EXPIRED,
      };
    }
    if (isExpired) {
      return {
        stepDate: toDateShort(quote.expirationDate),
        stepTitle: 'Expired due to Inactivity',
        isError: true,
        showLightBackground: true,
      };
    }

    return null;
  }, [quote]);

  return (
    <MFlex w="100%" flexWrap="wrap" pl="7">
      <QuoteStepEdit {...props} />
      <QuoteStepApproval {...props} />
      <QuoteStepCustomer {...props} />
      <QuoteStepSign
        pauseAtAccepted={pauseAtAccepted}
        {...props}
        stepMessage={pauseAtAccepted ? null : overwriteStepMessage}
      />
      {pauseAtAccepted && (
        <QuoteStepProcess {...props} stepMessage={overwriteStepMessage} />
      )}
    </MFlex>
  );
});
