import { z } from 'zod';

export const SlackConnectionResponse = z.object({
  createdBy: z.string().nullish(),
  teamId: z.string().nullish(),
  teamName: z.string().nullish(),
});

export type ISlackConnectionResponse = z.infer<typeof SlackConnectionResponse>;

export const SlackConnectionActive = z.object({
  createdBy: z.string(),
  createDate: z.string(),
  updatedBy: z.string(),
  updateDate: z.string(),
  teamId: z.string(),
  teamName: z.string(),
  status: z.enum(['ACTIVE', 'INACTIVE']),
});

export type ISlackConnectionActive = z.infer<typeof SlackConnectionActive>;

export const SlackChannel = z.object({
  id: z.string(),
  name: z.string(),
});

export type ISlackChannel = z.infer<typeof SlackChannel>;

export const SlackChannelResponse = z.object({
  channels: z.array(SlackChannel),
});

export type ISlackChannelResponse = z.infer<typeof SlackChannelResponse>;
