import { Flex, Text } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { TDataTablePager } from '../../types';
import { MBox } from './chakra';
import MCustomIconButton from './MCustomIconButton';

interface MCustomPaginatorProps {
  totalRecords: number;
  totalPages: number;
  pager: TDataTablePager;
  setUpdatedPage: (value: TDataTablePager) => void;
}

const MCustomPaginator: FC<MCustomPaginatorProps> = React.forwardRef<
  any,
  MCustomPaginatorProps
>((props: MCustomPaginatorProps, ref) => {
  const { totalRecords, totalPages, pager, setUpdatedPage, ...rest } = props;

  const onChangePage = (changeType: string) => {
    const pageValue = getChangePageValue(changeType);
    setUpdatedPage(pageValue);
  };

  const getChangePageValue = (changePage: string) => {
    switch (changePage) {
      case 'increment':
        return { ...pager, page: pager.page + 1 };

      case 'decrement':
        return { ...pager, page: pager.page - 1 };

      default:
        return { ...pager, page: 0 };
    }
  };

  return (
    <MBox>
      <Flex alignItems="center" justifyContent="center" flex={1} py="3">
        <MCustomIconButton
          bg="none"
          btnSize={6}
          p="4"
          color="tPurple.base"
          _hover={{ borderRadius: '50%', bg: 'tGray.grey96' }}
          icon={MdChevronLeft}
          isDisabled={pager.page === 0}
          onClick={() => onChangePage('decrement')}
        />
        <Text color="tPurple.base" fontSize="12" fontWeight="bold" px="3">
          Page {pager.page + 1} of {totalPages === 0 ? 1 : totalPages}
        </Text>
        <MCustomIconButton
          bg="none"
          btnSize={6}
          p="3"
          color="tPurple.base"
          _hover={{ borderRadius: '50%', bg: 'tGray.grey96' }}
          icon={MdChevronRight}
          isDisabled={pager.page + 1 === totalPages}
          onClick={() => onChangePage('increment')}
        />
      </Flex>
    </MBox>
  );
});

export default MCustomPaginator;
