import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  MBox,
  MCustomSelect,
  QuoteSelectInput,
} from '../../../../components/Monetize';
import { QuoteBasicInfoLine } from '../../../../components/Quotes/QuoteBasicInfoLine';
import { QuoteContractLengthPeriodPopover } from '../../../../components/Quotes/QuoteContractLengthPeriodPopover';
import { QuoteContractRenewalTermsActionV2 } from '../../../../components/Quotes/QuoteContractRenewalTermsActionV2';
import { useNetTerms } from '../../../../hooks';
import { useFlags } from '../../../../services/launchDarkly';
import { IQuoteRequestSchema } from '../../../../types';
import { getQuoteType } from '../../../../utils';
import { useQuoteContext } from '../../Quote/quoteContext';

interface QuoteBasicContractProps {}

export const QuoteBasicContract: FC<
  QuoteBasicContractProps
> = ({}: QuoteBasicContractProps) => {
  const {
    handleSubmitButtonWithoutDirtyCheck,
    isReadOnly,
    quoteData: { quote, modifiedFields, updateQuote, setQuote },
    quoteSettingsData,
  } = useQuoteContext();
  const { isNew, isAmendment, isRenewal, isEarlyRenewal } = getQuoteType(quote);
  const { control, getValues, setValue } =
    useFormContext<IQuoteRequestSchema>();
  const { hideAutoRenewal } = useFlags();

  const { activeNetTerms } = useNetTerms(getValues('netTerms'));

  const handleSaveQuoteContractRenewalTerms = async (
    payload: IQuoteRequestSchema,
  ): Promise<void> => {
    try {
      await updateQuote(quote?.id!, payload);
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };
  if (!quote) {
    return null;
  }

  return (
    <MBox>
      <QuoteBasicInfoLine title="Length">
        <QuoteContractLengthPeriodPopover
          isCustomContractLength={
            quoteSettingsData.quoteSettings?.customContractLength
              .allowCustomLength!
          }
          isNew={isNew}
          isAmendment={isAmendment}
          quote={quote!}
          setValue={setValue}
          handleSubmitButton={handleSubmitButtonWithoutDirtyCheck}
          isReadOnly={isReadOnly}
          isRenewal={isRenewal}
          setQuote={setQuote}
        />
      </QuoteBasicInfoLine>

      {!hideAutoRenewal && (
        <QuoteBasicInfoLine title="End Action">
          <QuoteContractRenewalTermsActionV2
            contractRenewalTerms={quote?.contractRenewalTerms}
            defaultContractRenewalTerms={
              quoteSettingsData.quoteSettings?.contractRenewalTerms
            }
            onSubmit={async (contractRenewalTerms) => {
              try {
                await handleSaveQuoteContractRenewalTerms({
                  ...getValues(),
                  contractRenewalTerms,
                });
                setValue('contractRenewalTerms', contractRenewalTerms, {
                  shouldDirty: false,
                });
              } catch (ex) {
                handleApiErrorToast(ex);
              }
            }}
            isReadOnly={isReadOnly}
          />
        </QuoteBasicInfoLine>
      )}
      <QuoteBasicInfoLine title="Net Terms">
        <MBox mr={isReadOnly ? 0 : '-3'}>
          <Controller
            name="netTerms"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MCustomSelect
                isDisabled={isReadOnly}
                isReadOnly={isReadOnly}
                MainInputComponent={QuoteSelectInput}
                itemTitle="name"
                itemValue="value"
                items={activeNetTerms}
                popOverProps={{
                  placement: 'bottom-end',
                  matchWidth: false,
                }}
                popOverContentProps={{ minWidth: '10rem' }}
                onChange={(e: any) => {
                  onChange(e);
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                showContentInInput
                {...rest}
              />
            )}
          />
        </MBox>
      </QuoteBasicInfoLine>
    </MBox>
  );
};
