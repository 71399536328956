import { UseFormSetValue } from 'react-hook-form';
import { doUpdateQuoteOffering } from '../api/cpqService';
import { FeatureFlag } from '../services/launchDarkly';
import { logger } from '../services/logger';
import { arrayToObject } from '../utils/misc';
import {
  IQuoteItemReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
} from './quoteTypes';

/**
 * This file is dedicated to custom logic that is temporary and specific to sales demos
 * It is all here so that it is easy to identify when it is used and to remove when no longer needed
 */

/**
 * TEMPORARY LOGIC
 * This is for  sales demo for Teleport
 *
 * For one product, we disable the quantity and set it to a value based on the quantity of a different product
 *
 * This is meant to modify the data before sending to the server on PUT /quotes/{quoteId}/offerings
 */
export const TEMP_SALES_DEMO_TELEPORT_calculateQuantityOfDerivedProduct = ({
  teleportSalesDemoTempDependentPricingConfig: featureFlagValue,
  quoteItemsToModify,
  setValue,
}: {
  teleportSalesDemoTempDependentPricingConfig: FeatureFlag['teleportSalesDemoTempDependentPricingConfig'];
  quoteItemsToModify: IQuoteOfferingReqSchema;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
}) => {
  const derivedQuantityConfig = featureFlagValue?.derivedQuantity;
  if (derivedQuantityConfig) {
    const productsById = arrayToObject(quoteItemsToModify.items, 'productId');
    quoteItemsToModify.items.forEach((item, i) => {
      const derivedQuantity = derivedQuantityConfig[item.productId];
      if (derivedQuantity && productsById[derivedQuantity.sourceProduct]) {
        const { quantityMultiplier, sourceProduct } = derivedQuantity;
        const { quantity: sourceProductQuantity } = productsById[sourceProduct];
        const newQuantity = Number(
          (sourceProductQuantity * quantityMultiplier).toFixed(0),
        );
        if (newQuantity !== item.quantity) {
          item.quantity = newQuantity;
          // ensure form updates with changes
          const inputName = `items.${i}.quantity` as const;
          setValue(inputName, newQuantity);
        }
      }
    });
  }
};

/**
 * TEMPORARY LOGIC
 * This is for  sales demo for Medinsight where we want the PPU of a customPrice to be derived from a formula
 * base on another product in the quote
 *
 * Formula:
 * (("Membership Population" Amount / 2) * (qty - 1)) / qty
 *
 * This is meant to modify the data before sending to the server on PUT /quotes/{quoteId}/offerings
 */
export const TEMP_SALES_DEMO_MEDINSIGHT_calculateCustomPriceFormula = ({
  salesDemoMedinsightTempFormulaCustomPricing: featureFlagValue,
  quoteOffering,
  quoteItemsToModify,
  setValue,
}: {
  salesDemoMedinsightTempFormulaCustomPricing: FeatureFlag['salesDemoMedinsightTempFormulaCustomPricing'];
  quoteOffering: IQuoteOfferingRespSchema;
  quoteItemsToModify: IQuoteOfferingReqSchema;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
}) => {
  if (
    featureFlagValue?.formulaCustomQty &&
    featureFlagValue?.formulaCustomQty?.offering === quoteOffering.offeringId
  ) {
    const { dependentProduct, formulaProduct } =
      featureFlagValue.formulaCustomQty;
    const dependentProductAmount = quoteOffering?.items.find(
      (item) => item.productId === dependentProduct,
    )?.amount;
    const dependentProductItem = quoteItemsToModify.items.find(
      (item) => item.productId === dependentProduct,
    );
    const formulaProductItem = quoteItemsToModify.items.find(
      (item) => item.productId === formulaProduct,
    );
    if (dependentProductItem && formulaProductItem) {
      quoteItemsToModify.items.forEach((item, i) => {
        if (item === formulaProductItem) {
          const unitAmount =
            (((dependentProductAmount || 0) / 2) * (item.quantity - 1)) /
            item.quantity;
          item.customPrice = {
            ...item.customPrice,
            unitAmount,
          };
          // ensure form updates with changes
          setValue(`items.${i}.customPrice.unitAmount`, unitAmount);
        }
      });
    }
  }
};

export const TEMP_SALES_DEMO_MEDINSIGHT_calculateCustomPriceFormulaInGuidedSelling =
  async ({
    salesDemoMedinsightTempFormulaCustomPricing: featureFlagValue,
    quoteOfferings,
  }: {
    salesDemoMedinsightTempFormulaCustomPricing: FeatureFlag['salesDemoMedinsightTempFormulaCustomPricing'];
    quoteOfferings: IQuoteOfferingRespSchema[];
  }) => {
    try {
      const offeringId = featureFlagValue?.formulaCustomQty?.offering;
      if (
        !featureFlagValue?.formulaCustomQty ||
        !offeringId ||
        quoteOfferings.every(
          (quoteOffering) => quoteOffering.offeringId !== offeringId,
        )
      ) {
        return;
      }

      for (let quoteOffering of quoteOfferings) {
        if (quoteOffering.offeringId !== offeringId) {
          continue;
        }
        const { dependentProduct, formulaProduct } =
          featureFlagValue.formulaCustomQty;
        const dependentProductAmount = quoteOffering?.items.find(
          (item) => item.productId === dependentProduct,
        )?.amount;
        const dependentProductItem = quoteOffering.items.find(
          (item) => item.productId === dependentProduct,
        );
        const formulaProductItem = quoteOffering.items.find(
          (item) => item.productId === formulaProduct,
        );
        if (dependentProductItem && formulaProductItem) {
          const quoteOfferingUpdate: IQuoteOfferingReqSchema = {
            offeringId: quoteOffering.offeringId,
            rateId: quoteOffering.rateId,
            discountIds:
              quoteOffering.discounts?.map((discount) => discount.id) || [],
            description: quoteOffering.description,
            startDate: quoteOffering.startDate,
            endDate: quoteOffering.endDate,
            items: quoteOffering.items.map((item, i) => {
              // solving for type error - when adding customPrice to itemResponse, stuff broke across app
              const { customPrice } = item as any;
              const newQuoteItem = {
                id: item.id,
                customId: item.customId,
                productId: item.productId,
                sku: item.sku,
                description: item.description,
                quantity: item.quantity,
                customDiscountType: item.customDiscountType,
                customDiscountAmountOrPercent:
                  item.customDiscountAmountOrPercent,
                customDiscountName: item.customDiscountName,
                customDiscountDescription: item.customDiscountDescription,
                options: item.options,
                customPrice: !customPrice
                  ? undefined
                  : {
                      id: customPrice.id,
                      unitAmount: customPrice.amount,
                    },
                position: item.position,
              };

              if (item === formulaProductItem) {
                const unitAmount =
                  (((dependentProductAmount || 0) / 2) * (item.quantity - 1)) /
                  item.quantity;
                newQuoteItem.customPrice = {
                  ...newQuoteItem.customPrice!,
                  unitAmount,
                };
              }
              return newQuoteItem;
            }),
          };
          quoteOffering = await doUpdateQuoteOffering(
            quoteOffering.quoteId,
            quoteOffering.id,
            quoteOfferingUpdate,
          );
        }
      }
    } catch (ex) {
      logger.error(
        'Error in TEMP_SALES_DEMO_MEDINSIGHT_calculateCustomPriceFormulaInGuidedSelling',
        ex,
      );
    }
  };

/**
 * TEMPORARY LOGIC
 * Determines if we need to submit a second quote offering update if the dependent product amount changed
 * we don't know if it changed until after the first update
 */
export const TEMP_SALES_DEMO_MEDINSIGHT_checkIfSecondUpdateIsRequired = ({
  salesDemoMedinsightTempFormulaCustomPricing: featureFlagValue,
  priorQuoteOffering,
  quoteOfferingResponse,
}: {
  salesDemoMedinsightTempFormulaCustomPricing: FeatureFlag['salesDemoMedinsightTempFormulaCustomPricing'];
  priorQuoteOffering?: IQuoteOfferingRespSchema | null;
  quoteOfferingResponse: IQuoteOfferingRespSchema;
}): boolean => {
  if (
    priorQuoteOffering &&
    featureFlagValue?.formulaCustomQty &&
    featureFlagValue?.formulaCustomQty?.offering ===
      quoteOfferingResponse.offeringId
  ) {
    const { dependentProduct, formulaProduct } =
      featureFlagValue.formulaCustomQty;
    const dependentProductItemBefore = priorQuoteOffering.items.find(
      (item) => item.productId === dependentProduct,
    );
    const dependentProductItemAfter = quoteOfferingResponse.items.find(
      (item) => item.productId === dependentProduct,
    );
    if (
      dependentProductItemBefore &&
      dependentProductItemAfter &&
      dependentProductItemBefore.amount !== dependentProductItemAfter.amount
    ) {
      return true;
    }
  }
  return false;
};

/**
 * **************************************************************************
 * AcuityMD Automation
 * **************************************************************************
 */
export const CUSTOMER_AUTOMATION_QuoteOffering_AcuityMdShouldLockQuantity = ({
  customerAutomationAcuityMd: featureFlagValue,
  offeringId,
  quoteItem,
}: {
  customerAutomationAcuityMd: FeatureFlag['customerAutomationAcuityMd'];
  offeringId: string;
  quoteItem: IQuoteItemReqSchema;
}): boolean => {
  try {
    if (!featureFlagValue?.quantityAutomation?.length) {
      return false;
    }
    return featureFlagValue.quantityAutomation.some(
      ({ scopedOfferingIds, lockTargetQuantities, targetProductIds }) =>
        scopedOfferingIds.includes(offeringId) &&
        lockTargetQuantities &&
        targetProductIds.includes(quoteItem.productId),
    );
  } catch (ex) {
    logger.error(
      'Error in CUSTOMER_AUTOMATION_QuoteOffering_AcuityMdShouldLockQuantity',
      ex,
    );
    return false;
  }
};

/**
 * AcuityMD Automation
 * Quantity of products needs to be updated based on quantity changes of other products
 * Since this is cross-offering, an event is emitted so other offerings can update their quantities as needed
 */
export const CUSTOMER_AUTOMATION_QuoteOffering_AcuityMdQuantityAutomation = ({
  customerAutomationAcuityMd: featureFlagValue,
  quoteOffering,
  quoteItemsToModify,
  setValue,
}: {
  customerAutomationAcuityMd: FeatureFlag['customerAutomationAcuityMd'];
  quoteOffering: IQuoteOfferingRespSchema;
  quoteItemsToModify: IQuoteOfferingReqSchema;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
}) => {
  try {
    const quantityAutomation = featureFlagValue?.quantityAutomation;
    if (!quantityAutomation?.length) {
      return;
    }

    const automationToApply = quantityAutomation.find(
      ({ scopedOfferingIds, sourceProductId }) =>
        scopedOfferingIds.includes(quoteOffering.offeringId) &&
        quoteItemsToModify.items.some(
          (item) => item.productId === sourceProductId,
        ),
    );

    if (!automationToApply) {
      return;
    }

    const sourceQuoteItem = quoteItemsToModify.items.find(
      (item) => item.productId === automationToApply.sourceProductId,
    );
    if (!sourceQuoteItem) {
      return;
    }

    const targetProductIds = new Set(automationToApply.targetProductIds);

    quoteItemsToModify.items.forEach((item, i) => {
      if (targetProductIds.has(item.productId)) {
        item.quantity = sourceQuoteItem.quantity;
        // ensure form updates with changes
        setValue(`items.${i}.quantity`, sourceQuoteItem.quantity);
      }
    });
  } catch (ex) {
    logger.error(
      'Error in CUSTOMER_AUTOMATION_QuoteOffering_AcuityMdQuantityAutomation',
      ex,
    );
  }
};
