import {
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { MGrid, MGridItem, MLink, MText } from '../../../components/Monetize';
import { USAGE_TYPE_STATUS_DISPLAY } from '../../../constants/usageTypes';
import { IUsageTypeResSchema } from '../../../types';

interface UsageTypeDetailsProps {
  usageType: IUsageTypeResSchema;
}

export const UsageTypeDetails = ({ usageType }: UsageTypeDetailsProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { pluralName, status, description, unitName, decimalPlaces } =
    usageType;

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
    >
      <PopoverTrigger>
        <MLink onClick={onOpen} fontSize="sm" cursor="pointer">
          {usageType.name}
        </MLink>
      </PopoverTrigger>
      <PopoverContent p={4} border="0" boxShadow="popover">
        <FocusLock persistentFocus={false}>
          <PopoverArrow mt="1" />

          <MGrid templateColumns="repeat(12, 1fr)" gap={2}>
            <MGridItem colSpan={12}>
              <MText fontWeight="bold" mb="1">
                Description
              </MText>
              <MText>{description}</MText>
            </MGridItem>

            <MGridItem colSpan={4}>
              <MText fontWeight="bold" mb="1">
                Unit Name
              </MText>
              <MText>{unitName}</MText>
            </MGridItem>

            <MGridItem colSpan={4}>
              <MText fontWeight="bold" mb="1">
                Plural Name
              </MText>
              <MText>{pluralName}</MText>
            </MGridItem>

            <MGridItem colSpan={4}>
              <MText fontWeight="bold" mb="1">
                Status
              </MText>
              <MText>{USAGE_TYPE_STATUS_DISPLAY[status]}</MText>
            </MGridItem>

            <MGridItem colSpan={4}>
              <MText fontWeight="bold" mb="1">
                Decimal Place
              </MText>
              <MText>{decimalPlaces}</MText>
            </MGridItem>
          </MGrid>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
