/* eslint-disable react/prop-types */
import {
  CUSTOM_SELECT_SUBTITLE_TYPE,
  OPTION_TYPE_FIELD,
} from '../../constants/customSelect';
import { IPaymentMethodResp, PaymentMethodSummaryResponse } from '../../types';
import { CustomSelectRenderItemContentTypeFn } from '../../types/mCustomSelectTypes';
import { MFlex, MText } from '../Monetize';

/**
 * Renderer for payment method items in custom select
 */
export const PaymentMethodItemRenderer: CustomSelectRenderItemContentTypeFn<
  | PaymentMethodSummaryResponse
  | IPaymentMethodResp
  | {
      name: string;
      [OPTION_TYPE_FIELD]: typeof CUSTOM_SELECT_SUBTITLE_TYPE;
    }
> = ({ item }) => {
  if (OPTION_TYPE_FIELD in item) {
    return <MText fontWeight={600}>{(item as any).name}</MText>;
  }

  const name = 'paymentMethodName' in item ? item.paymentMethodName : item.name;

  const secondaryLabel = item.creditCardType || item.bankName;
  const lastFour = item.lastFour ? `Ending in ${item.lastFour}` : '';

  let expiration = '';
  if (item.expirationMonth && item.expirationYear) {
    expiration = `Exp ${item.expirationMonth}/${item.expirationYear}`;
  }

  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      w="100%"
    >
      <MFlex justifyContent="space-between" w="100%">
        <MText color="tPurple.base" fontSize="sm">
          {name}
        </MText>
        {expiration && (
          <MText color="tGray.darkPurple" fontSize="xs">
            {expiration}
          </MText>
        )}
      </MFlex>
      <MFlex justifyContent="space-between" w="100%">
        <MText color="tGray.darkPurple" fontSize="xs" noOfLines={1}>
          {secondaryLabel}
        </MText>
        <MText color="tGray.darkPurple" fontSize="xs">
          {lastFour}
        </MText>
      </MFlex>
    </MFlex>
  );
};
