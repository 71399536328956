import { formatCurrency } from '@monetize/utils/core';
import isNumber from 'lodash/isNumber';
import { FunctionComponent as FC } from 'react';
import { MGrid, MText } from '../../../../../components/Monetize';

interface TotalCardProps {
  labelName: string;
  totalAmount?: number;
  currency: string;
}

export const TotalCard: FC<TotalCardProps> = (props: TotalCardProps) => {
  const { labelName, totalAmount, currency } = props;

  if (!isNumber(totalAmount)) {
    return null;
  }

  return (
    <MGrid justifyItems="center">
      <MText fontSize="md" color="tGray.darkPurple">
        {labelName}
      </MText>
      <MText fontWeight="bold" fontSize="2.5xl" mt={2}>
        {formatCurrency(totalAmount, { currency })}
      </MText>
    </MGrid>
  );
};
