import { useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import {
  MBox,
  MCustomLinkTab,
  MFlex,
  MHeading,
  MPageContainer,
  MPageHeader,
  MStack,
  MTabList,
  MTabs,
  MText,
} from '../../components/Monetize';
import { ROLES, ROUTES } from '../../constants';
import { useDashboardTab } from '../../hooks';
import { useAuth } from '../../services/auth0';
import { useDocumentHead } from '../../services/documentHead';
import { SalesCreatedByFilter, UserRoleEnum } from '../../types';
import { PrivateRoute } from '../AppPrivateRoute';
import { DashboardCurrencyFilter } from './DashboardCurrencyFilter';
import { ApprovalDashboard } from './components/ApprovalDashboard';
import { BillingDashboard } from './components/BillingDashboard/BillingDashboard';
import { FinanceDashboardV2 } from './components/FinanceDashboard/FinanceDashboardV2';
import { PersonTeamFilter } from './components/PersonTeamFilter';
import { SalesDashboard } from './components/SalesDashboard/SalesDashboard';
import { useDashboardCurrencyFilter } from './components/useDashboardCurrencyFilter';

interface ITabRoutes {
  title: string;
  route: string;
  role: UserRoleEnum[];
}

const tabRoutes = [
  {
    title: 'Finance',
    route: ROUTES.DASHBOARD_FINANCE,
    role: ROLES.DASHBOARD_FINANCE_ROLES,
  },
  {
    title: 'Sales',
    route: ROUTES.DASHBOARD_SALES,
    role: ROLES.DASHBOARD_SALES_ROLES,
  },
  {
    title: 'Approvals',
    route: ROUTES.DASHBOARD_APPROVALS,
    role: ROLES.DASHBOARD_APPROVAL_ROLES,
  },
  {
    title: 'Invoices',
    route: ROUTES.DASHBOARD_BILLING,
    role: ROLES.DASHBOARD_BILLING_ROLES,
  },
];

type PersonOrTeam = 'person' | 'team';

// Recoil store to persist data across page navigation
export const approvalFilterValuesState = atom<{
  person: string | null;
  team: string | null;
}>({
  key: 'dashboard-approvalFilterValuesState',
  default: { person: null, team: null },
});
export const approvalFilterTypeState = atom<{ type: PersonOrTeam | null }>({
  key: 'dashboard-approvalFilterTypeState',
  default: { type: 'person' },
});

export const salesFilterTypeState = atom<{ type: PersonOrTeam | null }>({
  key: 'dashboard-salesFilterTypeState',
  default: { type: 'person' },
});

export const salesFilterValuesState = atom<{
  person: string | null;
  team: string | null;
  currentQuarter: string;
}>({
  key: 'dashboard-salesFilterValuesState',
  default: { person: null, team: null, currentQuarter: 'YTD' },
});

// currentTenantUser?.id ||
const Dashboard = () => {
  const { pathname } = useLocation();
  const { currentTenantUser, tenantId, currentTenantUserHasRole } = useAuth();
  const { getDashboardTabRouteByRole } = useDashboardTab();

  const [dashboardTabs, setDashboardTabs] = useState<ITabRoutes[]>();
  const [routeActiveIndex, setRouteActiveIndex] = useState<number>(0);

  // APPROVALS - SHOW DATA FOR
  const [approvalFilterValues, setApprovalFilterValues] = useRecoilState(
    approvalFilterValuesState,
  );
  const [approvalFilterType, setApprovalFilterType] = useRecoilState(
    approvalFilterTypeState,
  );

  // SALES - SHOW DATA FOR
  const [salesFilterType, setSalesFilterType] =
    useRecoilState(salesFilterTypeState);
  const [salesFilterValues, setSalesFilterValues] = useRecoilState(
    salesFilterValuesState,
  );

  // Currency
  const { currency } = useDashboardCurrencyFilter();

  useEffect(() => {
    if (
      currentTenantUser &&
      salesFilterValues.team === null &&
      salesFilterValues.person === null
    ) {
      setSalesFilterValues({
        ...salesFilterValues,
        person: currentTenantUser.id || null,
        team: null,
      });
    }
  }, [currentTenantUser, salesFilterValues, setSalesFilterValues]);

  useEffect(() => {
    if (
      currentTenantUser &&
      approvalFilterValues.team === null &&
      approvalFilterValues.person === null
    ) {
      setApprovalFilterValues({
        person: currentTenantUser.id || null,
        team: null,
      });
    }
  }, [currentTenantUser, approvalFilterValues, setApprovalFilterValues]);

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Dashboard');
  }, []);

  useEffect(() => {
    const userAccessibleTabs = tabRoutes.filter((tabRoute) =>
      currentTenantUserHasRole(tabRoute.role),
    );
    setDashboardTabs(userAccessibleTabs);

    const matchedRouteIndex = userAccessibleTabs.findIndex((route) => {
      return matchPath(route.route, pathname);
    });
    setRouteActiveIndex(matchedRouteIndex);
  }, [pathname]);

  const getSalesCreatedByFilter = (): SalesCreatedByFilter => {
    if (salesFilterType.type && salesFilterValues[salesFilterType.type]) {
      if (salesFilterType.type === 'person') {
        return { userId: salesFilterValues.person };
      }
      if (salesFilterType.type === 'team') {
        return { teamId: salesFilterValues.team };
      }
    }
    return {};
  };
  const getApprovalCreatedByFilter = () => {
    if (
      approvalFilterType.type &&
      approvalFilterValues[approvalFilterType.type]
    ) {
      if (approvalFilterType.type === 'person') {
        return { userId: approvalFilterValues.person };
      }

      if (approvalFilterType.type === 'team') {
        return { teamId: approvalFilterValues.team };
      }
    }

    return {};
  };

  const renderDashboardFilters = () => {
    if (pathname === ROUTES.DASHBOARD_FINANCE) {
      return <DashboardCurrencyFilter />;
    }

    if (pathname === ROUTES.DASHBOARD_SALES) {
      return (
        <MFlex gap="4">
          <PersonTeamFilter
            tenantId={tenantId}
            filterBy={salesFilterType}
            setFilterBy={setSalesFilterType}
            filterValues={salesFilterValues}
            setFilterValues={({ person, team }) => {
              setSalesFilterValues({ ...salesFilterValues, person, team });
            }}
            clearable
          />
          <DashboardCurrencyFilter />
        </MFlex>
      );
    }
    if (pathname === ROUTES.DASHBOARD_APPROVALS) {
      return (
        <PersonTeamFilter
          tenantId={tenantId}
          filterBy={approvalFilterType}
          setFilterBy={setApprovalFilterType}
          filterValues={approvalFilterValues}
          setFilterValues={setApprovalFilterValues}
          clearable
        />
      );
    }

    return <MBox h="8" />;
  };

  return (
    <MPageContainer enableAccountSearch data-testid="landing-dashboard">
      <MPageHeader title="Dashboard">
        <MHeading
          size="lg"
          fontWeight="normal"
          pl="1"
          color="tGray.lightPurple"
          marginBottom="1px"
        />

        <MFlex flexDir="column" alignItems="flex-end">
          <MStack spacing={2} direction="row">
            <MFlex alignItems="center">
              {[ROUTES.DASHBOARD_SALES, ROUTES.DASHBOARD_APPROVALS].includes(
                pathname,
              ) && (
                <MText fontWeight="bold" mr="2" minW="100">
                  Show data for:{' '}
                </MText>
              )}
              {renderDashboardFilters()}
            </MFlex>
            <MBox h="8" />
          </MStack>
          {[ROUTES.DASHBOARD_FINANCE, ROUTES.DASHBOARD_SALES].includes(
            pathname,
          ) && (
            <MText color="tGray.darkPurple" fontSize={12} mt={2} mr={0.5}>
              Only shows data in {currency}. No currency conversion occurs.
            </MText>
          )}
        </MFlex>
      </MPageHeader>
      <MBox
        flex="1"
        alignSelf="stretch"
        minH="0"
        display="flex"
        flexDir="column"
      >
        <MBox>
          <MTabs index={routeActiveIndex} size="sm" isLazy mt={-1}>
            <MTabList alignItems="flex-end">
              {dashboardTabs?.map((tabRoute: ITabRoutes, index: number) => (
                <MCustomLinkTab to={tabRoute.route} key={index}>
                  {tabRoute.title}
                </MCustomLinkTab>
              ))}
            </MTabList>
          </MTabs>
        </MBox>
        <MBox flex="1" minH="0" display="flex" flexDir="column">
          <Routes>
            <Route
              path=""
              element={<Navigate to={getDashboardTabRouteByRole()} replace />}
            />
            <Route
              path="finance"
              element={
                <PrivateRoute
                  roles={ROLES.DASHBOARD_FINANCE_ROLES}
                  component={<FinanceDashboardV2 currency={currency} />}
                />
              }
            />
            <Route
              path="sales/*"
              element={
                <PrivateRoute
                  roles={ROLES.DASHBOARD_SALES_ROLES}
                  component={
                    <SalesDashboard
                      createdByFilter={getSalesCreatedByFilter()}
                    />
                  }
                />
              }
            />
            <Route
              path="approvals"
              element={
                <PrivateRoute
                  roles={ROLES.DASHBOARD_APPROVAL_ROLES}
                  component={
                    <ApprovalDashboard
                      createdByFilter={getApprovalCreatedByFilter()}
                      resetAllFilters={() =>
                        setApprovalFilterValues({ team: '', person: '' })
                      }
                    />
                  }
                />
              }
            />
            <Route
              path="billing"
              element={
                <PrivateRoute
                  roles={ROLES.DASHBOARD_BILLING_ROLES}
                  component={<BillingDashboard />}
                />
              }
            />
          </Routes>
        </MBox>
      </MBox>
    </MPageContainer>
  );
};

export default Dashboard;
