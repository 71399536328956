import { toDateShort } from '@monetize/utils/core';
import {
  MBox,
  MDivider,
  MFlex,
  MGrid,
  MGridItem,
  MHeading,
  MText,
} from '../../../../../components/Monetize';
import { MDiscountCell } from '../../../../../components/Monetize/MDiscountCell';
import { MProductDisplay } from '../../../../../components/Monetize/MProductDisplay';
import { RATE_BILLING_FREQUENCY_MAP } from '../../../../../constants/offerings';
import {
  IApplicableItemDiscountsSchema,
  IGetSubscriptionItemSchema,
  IGetSubscriptionSchema,
  IRateResSchema,
} from '../../../../../types';

interface SubscriptionDetailsProps {
  productSubscriptionItems: IGetSubscriptionItemSchema[];
  rate?: IRateResSchema;
  subscription: IGetSubscriptionSchema;
}

export const SubscriptionDetails = ({
  productSubscriptionItems,
  rate,
  subscription,
}: SubscriptionDetailsProps) => {
  const haveDiscount: boolean = (subscription?.discounts || []).length > 0;

  return (
    <MFlex flexDir="column" alignItems="start" mt={8} mb={4}>
      <MFlex justifyContent="space-between" w="100%">
        <MHeading fontSize="18">Subscription</MHeading>
      </MFlex>
      <MFlex alignItems="center" mt={0}>
        <MText color="tGray.darkPurple" mr={4}>
          Frequency:{' '}
          {rate
            ? RATE_BILLING_FREQUENCY_MAP[rate.billingFrequency!](
                rate.billingFrequencyInMonths,
              ).label
            : ''}{' '}
          | Period: {toDateShort(subscription?.periodStartDate || '')} &mdash;{' '}
          {toDateShort(subscription?.periodEndDate || '')}
        </MText>
      </MFlex>

      <MGrid
        w="100%"
        mt={4}
        templateColumns={haveDiscount ? '2fr 4fr 1fr .7fr' : '2fr 4fr 1fr'}
        columnGap={8}
      >
        <MGridItem py={2} colSpan={2}>
          <MText fontSize="16px" pl={2} fontWeight="bold">
            Product
          </MText>
        </MGridItem>
        <MGridItem py={2} mr={haveDiscount ? 8 : 4}>
          <MText fontSize="16px" fontWeight="bold" textAlign="right">
            Quantity
          </MText>
        </MGridItem>
        {haveDiscount && (
          <MGridItem py={2}>
            <MText fontSize="16px" fontWeight="bold" textAlign="left">
              Discount
            </MText>
          </MGridItem>
        )}

        {productSubscriptionItems.map(
          (subItem: IGetSubscriptionItemSchema, index: number) => {
            const { product, applicableItemDiscounts, units } = subItem;
            const { name, description, productType, id } = product;
            return (
              <MBox
                key={id}
                data-testid={`${name}-${id}`}
                style={{ display: 'contents' }}
              >
                <MGridItem pl={2} py={2}>
                  <MProductDisplay
                    name={name}
                    productType={productType}
                    subscriptionTiming={rate?.subscriptionTiming}
                  />
                </MGridItem>
                <MGridItem py={2}>
                  <MText>{description}</MText>
                </MGridItem>
                <MGridItem py={2} mr={haveDiscount ? 8 : 4}>
                  <MText textAlign="right">{units}</MText>
                </MGridItem>
                {haveDiscount && (
                  <MGridItem py={2}>
                    <MDiscountCell
                      discounts={applicableItemDiscounts.map(
                        (itemDiscount: IApplicableItemDiscountsSchema) =>
                          itemDiscount.discount,
                      )}
                    />
                  </MGridItem>
                )}
                <MGridItem gridColumn="1/-1">
                  <MDivider orientation="horizontal" color="red" />
                </MGridItem>
              </MBox>
            );
          },
        )}
      </MGrid>
    </MFlex>
  );
};
