import { MAvatar, MGrid, MText, MVStack } from "../../../../../components/Monetize";
import { fromNow } from "../../../../../utils/dates";

interface TeamHistoryCardProps {
  message: string;
  time: string;
}

export const TeamHistoryCard = ({ message, time }: TeamHistoryCardProps) => {
  return (
    <MVStack
      p={4}
      bgColor={'tWhite.base'}
      borderRadius={4}
      justifyContent="flex-start"
      alignItems={'flex-start'}
    >
      <MGrid gridTemplateColumns="0.05fr 1fr" columnGap={2} alignItems="center">
        <MAvatar name={message} />
        <MText>{message}</MText>
      </MGrid>
      <MText fontSize="xs" color="tGray.darkPurple" pt={1}>
        {fromNow(time)}
      </MText>
    </MVStack>
  );
};
