import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useCreateOrUpdateCurrencySettings,
  useDeleteCurrencySettings,
  useGetCurrencySettings,
} from '../../../api/settingsService';
import {
  MBox,
  MButton,
  MCenterModal,
  MCurrencySelect,
  MFormField,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MStack,
  MText,
  MVStack,
} from '../../../components/Monetize';
import {
  ALL_CURRENCIES,
  CurrencyCodes,
  SUPPORTED_CURRENCIES,
} from '../../../constants/currencies';
import { useDocumentHead } from '../../../services/documentHead';
import { CurrencySchema, ICurrencyResp, ICurrencySchema } from '../../../types';
import CurrencyItem from './components/CurrencyItem';

const CurrencySettings = () => {
  const { setDocTitle } = useDocumentHead();
  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const {
    isLoading,
    data: { currencies, defaultCurrency, additionalCurrencies } = {
      currencies: [],
      additionalCurrencies: [],
    },
  } = useGetCurrencySettings<{
    currencies: ICurrencyResp[];
    defaultCurrency?: ICurrencyResp;
    additionalCurrencies: ICurrencyResp[];
  }>(
    {},
    {
      select: (data) => {
        return {
          currencies: data.content,
          defaultCurrency: data.content.find(
            (currency) => currency.defaultCurrency,
          ),
          additionalCurrencies: data.content.filter(
            (currency) => !currency.defaultCurrency,
          ),
        };
      },
    },
  );

  const { mutate: doCreateOrUpdate, isPending: isCreateUpdateLoading } =
    useCreateOrUpdateCurrencySettings({
      onSuccess: () => {
        onCloseModal();
      },
      meta: { showErrorToast: true },
    });
  const { mutate: doDelete, isPending: isDeleteLoading } =
    useDeleteCurrencySettings({
      meta: { showErrorToast: true },
    });

  const isSaving = isCreateUpdateLoading || isDeleteLoading;

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<ICurrencySchema>({
    resolver: zodResolver(CurrencySchema),
    mode: 'onChange',
    defaultValues: { code: '', defaultCurrency: false, description: '' },
  });

  const onSubmit = async (formData: ICurrencySchema) => {
    doCreateOrUpdate({
      action: 'create',
      payload: {
        ...formData,
        description: ALL_CURRENCIES[formData.code as CurrencyCodes].name,
      },
    });
  };

  const handleSetDefaultCurrency = async (currency: ICurrencyResp) => {
    doCreateOrUpdate({
      action: 'update',
      payload: { ...currency, defaultCurrency: true },
    });
  };

  const handleDeleteCurrency = async (currency: ICurrencyResp) => {
    doDelete({ id: currency.id });
  };

  useEffect(() => {
    setDocTitle('Settings', 'Currencies');
  }, []);

  return (
    <MPageContainer data-testid="currency-settings-page" alignItems="stretch">
      <MBox>
        <MSettingsPageHeader title="Currencies" />
      </MBox>
      {isLoading && !currencies?.length ? (
        <MPageLoader />
      ) : (
        <MVStack align="start">
          {defaultCurrency && (
            <>
              <MText fontWeight="bold">Default Currency</MText>
              <CurrencyItem
                data-testid="default-currency"
                currency={defaultCurrency}
                onSetDefault={handleSetDefaultCurrency}
                onDelete={handleDeleteCurrency}
              />
            </>
          )}
          <MText pt="1rem" fontWeight="bold">
            Additional Currencies
          </MText>
          {additionalCurrencies.map((currency) => (
            <CurrencyItem
              key={currency.code}
              currency={currency}
              onSetDefault={handleSetDefaultCurrency}
              onDelete={handleDeleteCurrency}
            />
          ))}
          {currencies.length < Object.keys(SUPPORTED_CURRENCIES).length && (
            <MButton variant="tertiary" onClick={onOpenModal}>
              + New Currency
            </MButton>
          )}
        </MVStack>
      )}
      <MCenterModal
        size="sm"
        isOpen={isModalOpen}
        onClose={onCloseModal}
        modalTitle="New Currency"
        modalHeaderProps={{ pb: 1 }}
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton
              variant="primary"
              isLoading={isSaving}
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid}
              data-testid="save-currency-btn"
              minW="auto"
            >
              Save
            </MButton>
          </MStack>
        )}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MFormField label="Choose Currency">
            <Controller
              name="code"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MCurrencySelect useUnselectedCurrencies {...field} />
              )}
            />
          </MFormField>
        </form>
      </MCenterModal>
    </MPageContainer>
  );
};

export default CurrencySettings;
