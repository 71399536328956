import { useRecoilState } from 'recoil';
import {
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '../../../../types/quoteTypes';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function AcceptedAdminEditableBanner({
  quote,
}: {
  quote: IQuoteRespSchema;
}) {
  const showBanner = quote?.status === QuoteStatusEnum.ACCEPTED_ADMIN_EDITABLE;

  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'AcceptedAdminEditableBanner']),
  );

  if (!showBanner || dismissed) {
    return null;
  }

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="warning">
      Proceed with Caution: You are updating this information at your own risk.
      Please double-check all changes to ensure accuracy before submission.
    </QuoteBannerItem>
  );
}
