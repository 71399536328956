import { TagProps } from '@chakra-ui/react';
import { getVariantByStatus } from '../../utils';
import { MTag } from './';

export const MEntityStatusBadge = ({
  status,
  ...rest
}: {
  status?: string;
} & TagProps) => {
  if (!status) {
    return null;
  }

  const variant = getVariantByStatus(status);

  return (
    <MTag ml=".5rem" variant={variant} {...rest}>
      {status}
    </MTag>
  );
};
