import { MdDoNotDisturbAlt } from 'react-icons/md';
import { useNavigate } from 'react-router';
import {
  MBox,
  MButton,
  MHeading,
  MIcon,
  MPageContainer,
  MText,
} from '../components/Monetize';
import { useDashboardTab } from '../hooks';

const AccessDenied = () => {
  const navigate = useNavigate();
  const { getDashboardTabRouteByRole } = useDashboardTab();

  const goDashboard = () => {
    navigate(getDashboardTabRouteByRole());
  };

  return (
    <MPageContainer>
      <MBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <MHeading fontSize="3xl" mb="4" color="tRed.base">
          Access Denied <MIcon as={MdDoNotDisturbAlt} />
        </MHeading>
        <MText size="lg">
          <p>Please contact the system administrator</p>
        </MText>

        <MButton onClick={goDashboard} variant="primary" my="4">
          Go to dashboard
        </MButton>
      </MBox>
    </MPageContainer>
  );
};

export default AccessDenied;
