import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  ApiListResponse,
  GenericApiResponse,
  GetListApiConfig,
  GetListApiFilter,
  INoteReqSchema,
  INoteResSchema,
} from '../types';
import { apiGet, apiPost, apiPut } from './axios';
import { notesServiceQueryKeys } from './queryKeysService';
import {
  updateListCacheWithRemovedItem,
  updateListCacheWithUpdatedItem,
} from './queryUtilsHelpers';
import { composeGetQuery } from './utils';

export function useGetNotes<SelectData = ApiListResponse<INoteResSchema>>(
  {
    entityPath,
    id,
    config = {},
    filters,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<INoteResSchema>, unknown, SelectData>
  > = {},
) {
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...notesServiceQueryKeys.noteListById(entityPath, id), params],
    queryFn: () =>
      apiGet<ApiListResponse<INoteResSchema>>(
        `/api/${entityPath}/${id}/notes`,
        {
          params,
        },
      ).then((res) => res.data),
    refetchOnWindowFocus: false,
    // This is off so the quote sticky header does not need to refetch this as often
    refetchOnMount: false,
    ...options,
  });
}

export function useCreateNote(
  {
    entityPath,
    id,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
  },
  options: Partial<
    UseMutationOptions<INoteResSchema, unknown, INoteReqSchema>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation({
    mutationFn: (payload) => {
      return apiPost<INoteResSchema>(
        `/api/${entityPath}/${id}/notes`,
        payload,
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...notesServiceQueryKeys.noteListById(entityPath, id)],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useUpdateNote(
  {
    entityPath,
    id,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
  },
  options: Partial<
    UseMutationOptions<INoteResSchema, unknown, INoteReqSchema>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<INoteResSchema, unknown, INoteReqSchema>({
    mutationFn: (payload) => {
      return apiPut<INoteResSchema>(`/api/notes/${payload?.id}`, payload).then(
        (res) => res.data,
      );
    },
    onSuccess: (data, variables, context) => {
      updateListCacheWithUpdatedItem(
        queryClient,
        [...notesServiceQueryKeys.noteListById(entityPath, id)],
        data,
      );
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useDeleteNote(
  {
    entityPath,
    id,
  }: {
    entityPath: 'accounts' | 'quotes';
    id: string;
  },
  options: Partial<
    UseMutationOptions<GenericApiResponse, unknown, string>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<GenericApiResponse, unknown, string>({
    mutationFn: (noteId) => {
      return apiPut<GenericApiResponse>(`/api/notes/${noteId}/archive`).then(
        (res) => res.data,
      );
    },
    onSuccess: (data, noteId, context) => {
      updateListCacheWithRemovedItem(
        queryClient,
        [...notesServiceQueryKeys.noteListById(entityPath, id)],
        noteId,
      );
      queryClient.invalidateQueries({
        queryKey: [...notesServiceQueryKeys.noteListById(entityPath, id)],
      });
      onSuccess && onSuccess(data, noteId, context);
    },
    ...restOptions,
  });
}
