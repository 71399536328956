import { ButtonProps } from '@chakra-ui/react';
import { useState } from 'react';
import { MButton } from '../../Monetize';

interface QuoteCollaborationRevokeLinkProps extends ButtonProps {
  isLoading: boolean;
  onRevoke: () => void;
}

export function QuoteCollaborationRevokeLink({
  isLoading,
  onRevoke,
  ...rest
}: QuoteCollaborationRevokeLinkProps) {
  const [isRevoking, setIsRevoking] = useState(false);

  const handleCopyLinkToClipboard = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    if (isRevoking) {
      onRevoke();
      return;
    }
    setIsRevoking(true);
    setTimeout(() => {
      setIsRevoking(false);
    }, 3000);
  };

  return (
    <MButton
      variant={isRevoking ? 'delete' : 'tertiaryDestructive'}
      size="sm"
      isLoading={isLoading}
      disabled={isLoading}
      onClick={handleCopyLinkToClipboard}
      {...rest}
    >
      {isRevoking ? 'Are you sure?' : 'Revoke'}
    </MButton>
  );
}
