import { FunctionComponent as FC, useState } from 'react';
import { MBox, MCenterModal } from '../../../../../components/Monetize';
import { QUOTE_READONLY_STATUSES } from '../../../../../constants/quotes';
import { IQuoteRequestSchema, IQuoteRespSchema } from '../../../../../types';
import { QuoteDocumentsBody } from './QuoteDocumentsBody';
import { QuoteDocumentsFooter } from './QuoteDocumentsFooter';

interface QuoteDocumentsModalProps {
  quote: IQuoteRespSchema;
  disabled?: boolean;
  onUpdate: (data: IQuoteRequestSchema) => void;
  isOpen: boolean;
  onClose: (didSave?: boolean) => void;
}

export const QuoteDocumentsModal: FC<QuoteDocumentsModalProps> = ({
  quote,
  disabled,
  onUpdate,
  isOpen,
  onClose,
}: QuoteDocumentsModalProps) => {
  const [saving, setSaving] = useState(false);
  const isReadOnly = disabled || QUOTE_READONLY_STATUSES.has(quote.status);

  function handleClose() {
    if (saving) {
      return;
    }
    onClose();
  }

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={handleClose}
      modalTitle="Upload Documents"
      // showCloseButton={false}
      closeOnOverlayClick
      closeOnEsc
      modalHeaderProps={{ fontSize: 'lg', display: 'flex' }}
      renderFooter={
        isReadOnly
          ? null
          : () => (
              <QuoteDocumentsFooter saving={saving} handleClose={handleClose} />
            )
      }
    >
      <MBox>
        <QuoteDocumentsBody
          showDivider
          quote={quote}
          saving={saving}
          isReadOnly={isReadOnly}
          setSaving={setSaving}
          onUpdate={onUpdate}
          handleClose={handleClose}
        />
      </MBox>
    </MCenterModal>
  );
};
