import { createContext, useCallback, useContext, useState } from 'react';

import { IConfirmModalProps } from '../types';

const defaultContext = {
  modalData: null,
};

export type confirmModalProps = Partial<IConfirmModalProps>;
export type confirmModalPromiseProps = Partial<
  Omit<IConfirmModalProps, 'onNo' | 'onYes'>
>;

type ConfirmModalType = {
  modalData: null | IConfirmModalProps;
  /**
   * Uses onYes and onNo callback to handle the result of the modal
   *
   * Alternative showConfirmModalPromise
   */
  showConfirmModal: (options: confirmModalProps) => void;
  /**
   * Uses a promise which resolves to a boolean to handle the result of the modal
   * This will never reject, so no need to try/catch
   *
   * Alternative with callbacks showConfirmModal
   */
  showConfirmModalPromise: (
    options: confirmModalPromiseProps,
  ) => Promise<boolean>;
  setModalLoading: (loading: boolean) => void;
  closeModal: () => void;
  loading: boolean;
};

const ConfirmModalContext = createContext<ConfirmModalType>(
  defaultContext as any,
);

export const useConfirmModal = () => useContext(ConfirmModalContext);

const defaultModalData = {
  title: '',
  description: '',
  content: null,
  yesButton: 'Confirm',
  yesBtnProps: {},
  noButton: 'Cancel',
  noBtnProps: {},
  modalProps: {},
  modalBodyProps: {},
  modalFooterProps: {},
  showCloseButton: true,
};

export const useConfirmModalProvider = () => {
  const [modalData, setModalData] = useState<IConfirmModalProps | null>(null);
  const [loading, setLoading] = useState(false);

  const closeModal = useCallback(() => {
    setModalData(null);
  }, []);

  const showConfirmModal = useCallback(
    (newModal: Partial<IConfirmModalProps>) => {
      setModalData({
        ...(defaultModalData as any),
        onYes: () => closeModal(),
        onNo: () => closeModal(),
        ...newModal,
      });
      setLoading(false);
    },
    [closeModal],
  );

  const showConfirmModalPromise = useCallback(
    (newModal: Partial<IConfirmModalProps>) => {
      return new Promise<boolean>((resolve) => {
        try {
          setLoading(false);
          showConfirmModal({
            ...newModal,
            onYes: () => resolve(true),
            onNo: () => resolve(false),
          });
        } catch (ex) {
          resolve(false);
        }
      }).finally(() => closeModal());
    },
    [closeModal],
  );

  return {
    modalData,
    showConfirmModal,
    showConfirmModalPromise,
    setModalLoading: setLoading,
    closeModal,
    loading,
  };
};

export const ConfirmModalProvider = ({ children }: { children: any }) => {
  const data = useConfirmModalProvider();
  return (
    <ConfirmModalContext.Provider value={data}>
      {children}
    </ConfirmModalContext.Provider>
  );
};
