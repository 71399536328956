import { toDateShort } from '@monetize/utils/core';
import { endOfDay } from 'date-fns/endOfDay';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import { FC, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useGetQuoteStorageById } from '../../../../../api/cpqService';
import {
  MBox,
  MButton,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import { MTooltipContentList } from '../../../../../components/Monetize/MTooltipContentList';
import { QUOTE_READONLY_STATUSES } from '../../../../../constants/quotes';
import { useQuoteSettings } from '../../../../../hooks';
import { useAuth } from '../../../../../services/auth0';
import { useConfirmModal } from '../../../../../services/confirmModal';
import { useFlags } from '../../../../../services/launchDarkly';
import { logger } from '../../../../../services/logger';
import {
  IQuoteRequestSchema,
  IQuoteRespSchema,
  OpportunityStatusEnum,
  QuoteStartDateSourceEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
  RuleValidationErrorLevelEnum,
  UserRoleEnum,
} from '../../../../../types';
import { getQuoteStatus } from '../../../../../utils';
import { QuoteModalDataTypes } from '../../QuoteReviewModal';
import { QuoteStateDataTypes, useQuoteContext } from '../../quoteContext';
import {
  getEsignContactValidity,
  getStandardContactsValidity,
} from '../contacts/quoteContactUtils';
import { SendQuoteManuallyContent } from './SendQuoteManuallyContent';
import { SendQuoteThroughEsignContent } from './SendQuoteThroughEsignContent';

interface ReviewQuoteActionButtonProps {
  isReadOnly: boolean;
  hasValidRole: boolean;
  isActionLoading: boolean;
  isEsignEnabled: boolean;
  quote: IQuoteRespSchema;
  quoteStateData: QuoteStateDataTypes;
  quoteFormMethods: UseFormReturn<IQuoteRequestSchema>;
  reviewQuoteModalData: QuoteModalDataTypes;
  anyOfferingLoading: boolean;
  onAcceptQuote: () => void;
  onRecreateQuote: () => void;
  onSendQuote: () => void;
}

export const ReviewQuoteActionButton: FC<ReviewQuoteActionButtonProps> = ({
  isReadOnly,
  hasValidRole,
  isActionLoading,
  isEsignEnabled,
  quote,
  quoteStateData,
  quoteFormMethods,
  reviewQuoteModalData,
  anyOfferingLoading,
  onAcceptQuote,
  onRecreateQuote,
  onSendQuote,
}: ReviewQuoteActionButtonProps) => {
  const { quoteAcceptanceAllowedRoles = ['ALL'] } = useFlags();
  const { currentTenantUserHasRole } = useAuth();
  const {
    quoteData: { transitionQuoteStatus },
  } = useQuoteContext();

  const { quoteSettings } = useQuoteSettings();

  const pauseAtAccepted = !!quoteSettings?.pauseAtAccepted;

  const hasAbilityToAcceptOrProcessQuote =
    !quoteAcceptanceAllowedRoles?.length ||
    quoteAcceptanceAllowedRoles[0] === 'ALL' ||
    currentTenantUserHasRole(quoteAcceptanceAllowedRoles as UserRoleEnum[]);

  const canAcceptQuote = pauseAtAccepted || hasAbilityToAcceptOrProcessQuote;
  const canProcessQuote = hasAbilityToAcceptOrProcessQuote;

  const { isReviewBtnPressed } = quoteStateData;
  const {
    formState: { errors },
  } = quoteFormMethods;

  const { showConfirmModal, closeModal, setModalLoading } = useConfirmModal();
  const expirationDateIsInPast = useMemo(
    () => isBefore(endOfDay(parseISO(quote.expirationDate)), new Date()),
    [quote.expirationDate],
  );

  const sowDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.sowDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.sowDocumentStorageId },
  );

  const msaDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote.documentLocation },
  );

  if (!quote || !hasValidRole) {
    return null;
  }

  const {
    isCanceled,
    isSent,
    isApproved,
    isDraft,
    isAcceptedAdminEditable,
    isAccepted,
  } = getQuoteStatus(quote);
  const isQuoteReadOnly = QUOTE_READONLY_STATUSES.has(quote.status);
  const isDocumentsLoading = msaDocument.isLoading || sowDocument.isLoading;

  const onAcceptChanges = () => {
    showConfirmModal({
      title: 'Are you sure you want to accept changes?',
      description:
        'Quote will be accepted with the changes made at your own risk.',
      onYes: async () => {
        setModalLoading(true);
        try {
          await transitionQuoteStatus(quote.id, {
            newState: QuoteStatusEnum.ACCEPTED,
          });
          closeModal();
        } catch (e) {
          logger.log(e);
        }
        setModalLoading(false);
      },
      yesButton: 'Accept',
      yesBtnProps: {
        variant: 'primary',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  };

  const onClickSendQuote = () => {
    if (isEsignEnabled && quote.contacts.esign.length > 0) {
      const internalContact = quote.contacts.esign.find(
        (contact) => contact.internal,
      );
      const externalContact = quote.contacts.esign.find(
        (contact) => !contact.internal,
      );
      showConfirmModal({
        title: `Would you like to send this quote through eSign?`,
        content: (
          <SendQuoteThroughEsignContent
            quote={quote}
            internalContact={internalContact}
            externalContact={externalContact}
            msaDocument={msaDocument}
            sowDocument={sowDocument}
          />
        ),
        onYes: () => {
          onSendQuote();
          closeModal();
        },
        yesButton: 'Send',
        yesBtnProps: {
          variant: 'primary',
        },
        noBtnProps: {
          variant: 'cancel',
        },
        modalBodyProps: {
          pb: 1,
        },
      });

      return;
    }

    showConfirmModal({
      title: 'Mark this Quote as Sent?',
      content: <SendQuoteManuallyContent quote={quote} />,
      onYes: () => {
        onSendQuote();
        closeModal();
      },
      yesButton: 'Confirm',
      yesBtnProps: {
        variant: 'primary',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  };

  if (canAcceptQuote && isSent && !quote.contacts.esign.length) {
    return (
      <MButton
        mr={1.5}
        variant="primary"
        data-testid="quote-accept-btn"
        isLoading={isActionLoading}
        isDisabled={isActionLoading}
        onClick={onAcceptQuote}
      >
        Accept Quote
      </MButton>
    );
  }

  if (isAcceptedAdminEditable) {
    return (
      <MButton
        mr={1.5}
        variant="primary"
        data-testid="quote-accept-btn"
        isLoading={isActionLoading}
        isDisabled={isActionLoading}
        onClick={onAcceptChanges}
      >
        Accept Changes
      </MButton>
    );
  }

  const onOpenProcessQuoteModal = () => {
    const shouldShowDelayedDate =
      quote.type === QuoteTypeEnum.NEW &&
      quote.startDateSource &&
      [
        QuoteStartDateSourceEnum.AT_TIME_OF_SIGNING,
        QuoteStartDateSourceEnum.MANUAL,
      ].includes(quote.startDateSource);

    showConfirmModal({
      title: 'Are you sure?',
      content: (
        <MBox>
          {shouldShowDelayedDate && (
            <MText mb="2">
              This quote will be processed and initiate billing on:{' '}
              <MText fontWeight="500">
                {quote.startDateSource ===
                QuoteStartDateSourceEnum.AT_TIME_OF_SIGNING
                  ? toDateShort(quote.acceptedAt)
                  : ''}
                {quote.startDateSource === QuoteStartDateSourceEnum.MANUAL
                  ? toDateShort(quote.contractStartDate)
                  : ''}
              </MText>
            </MText>
          )}

          <MText>
            Once this quote is processed, all information including Contract
            Start Date can no longer be edited.
          </MText>
        </MBox>
      ),
      onYes: async () => {
        try {
          setModalLoading(true);
          await transitionQuoteStatus(quote.id, {
            newState: QuoteStatusEnum.PROCESSED,
          });
        } catch (e) {
          console.error(e);
        }
        closeModal();
        setModalLoading(false);
      },
      yesButton: 'Process Quote',
      yesBtnProps: {
        variant: 'primary',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  };
  if (canProcessQuote && isAccepted) {
    return (
      <MButton
        mr={1.5}
        variant="primary"
        data-testid="quote-accept-btn"
        isLoading={isActionLoading}
        isDisabled={isActionLoading}
        onClick={onOpenProcessQuoteModal}
      >
        Process Quote
      </MButton>
    );
  }

  if (
    isCanceled &&
    quote.type === QuoteTypeEnum.NEW &&
    (!quote.opportunity?.status ||
      quote.opportunity?.status === OpportunityStatusEnum.ACTIVE)
  ) {
    return (
      <MButton
        mr={1.5}
        variant="secondary"
        data-testid="quote-recreate-btn"
        isLoading={isActionLoading}
        isDisabled={isActionLoading}
        onClick={onRecreateQuote}
      >
        Recreate Quote
      </MButton>
    );
  }

  if (!isQuoteReadOnly) {
    const {
      isValid: isStandardContactsValid,
      errorMsgs: standardContactsErrorMsgs,
    } = getStandardContactsValidity(quote);

    const { isValid: isEsignSetupValid, errorMsgs: eSignContactsErrorMsgs } =
      getEsignContactValidity(quote);

    const isSubmitButtonDisabled =
      isActionLoading ||
      !(isApproved && isStandardContactsValid && isEsignSetupValid) ||
      isDocumentsLoading;

    const errorValidations =
      quote?.validations?.filter(
        (validation) =>
          validation.errorLevel === RuleValidationErrorLevelEnum.ERROR,
      ) || [];

    let disabledSubmitMessages: string[] = [];
    if (isSubmitButtonDisabled) {
      /** Common Error messages for All the quote status */
      if (!isStandardContactsValid) {
        disabledSubmitMessages = disabledSubmitMessages.concat(
          standardContactsErrorMsgs,
        );
      }
      if (!isEsignSetupValid) {
        disabledSubmitMessages = disabledSubmitMessages.concat(
          eSignContactsErrorMsgs,
        );
      }

      if (!isDraft) {
        /** Error messages if quote not in draft status */
        if (isActionLoading) {
          disabledSubmitMessages.push('Wait for action to load');
        }
        if (!isApproved) {
          disabledSubmitMessages.push('Quote must first be approved');
        }
      } else {
        /** Error messages if quote in draft status */
        if (errorValidations.length > 0) {
          disabledSubmitMessages.push('Resolve validation error');
        }
        if (!(quote && quote.quoteOfferings?.length > 0)) {
          disabledSubmitMessages.push('At least one offering is required');
        }
        if (expirationDateIsInPast) {
          disabledSubmitMessages.push('Expiration date must be in the future');
        }
      }
    }

    const sendTooltipContent = (
      <MTooltipContentList
        title="To send, resolve required actions below"
        listItems={disabledSubmitMessages}
      />
    );

    if (isDraft) {
      const isDraftQuoteSubmitButtonDisabled =
        !(
          isStandardContactsValid &&
          quote?.quoteOfferings?.length &&
          isEsignSetupValid
        ) ||
        expirationDateIsInPast ||
        Object.keys(errors).length > 0 ||
        errorValidations.length > 0;

      return (
        <MTooltip
          shouldWrapChildren
          isDisabled={!isDraftQuoteSubmitButtonDisabled}
          bg="tPurple.base"
          label={sendTooltipContent}
        >
          <MButton
            mr={1.5}
            type="button"
            variant="primary"
            isLoading={isReviewBtnPressed}
            isDisabled={
              isDraftQuoteSubmitButtonDisabled ||
              isReviewBtnPressed ||
              isReadOnly
            }
            onClick={reviewQuoteModalData.handleReviewOrOpenModal}
          >
            Submit
          </MButton>
        </MTooltip>
      );
    }

    return (
      <MTooltip
        shouldWrapChildren
        isDisabled={!isSubmitButtonDisabled}
        bg="tPurple.base"
        label={sendTooltipContent}
      >
        <MButton
          mr={1.5}
          variant="primary"
          data-testid="quote-send-btn"
          isLoading={isActionLoading}
          isDisabled={isSubmitButtonDisabled}
          onClick={onClickSendQuote}
        >
          {isDraft ? 'Submit' : 'Send'}
        </MButton>
      </MTooltip>
    );
  }

  return null;
};
