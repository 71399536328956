import { useDisclosure } from '@chakra-ui/react';
import { AiOutlineDollar } from 'react-icons/ai';
import { MCustomIconButton, MText } from '../../../../../components/Monetize';
import MPopoverWrapper from '../../../../../components/Monetize/MPopoverWrapper';

const QuoteCustomPricePopover = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <MPopoverWrapper
      isOpen={isOpen}
      placement="bottom-start"
      showArrow
      onOpen={onOpen}
      onClose={onClose}
      triggerContent={
        <MCustomIconButton
          p={4}
          minH="8"
          variant="icon"
          aria-label="CustomPrice"
          btnSize={5}
          icon={AiOutlineDollar}
        />
      }
      bodyContent={
        <MText color="tPurple.dark" fontWeight="bold">
          This Quote has custom pricing
        </MText>
      }
    />
  );
};

export default QuoteCustomPricePopover;
