import {
  FocusLock,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMonthsCustom, toDateShort } from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { addYears } from 'date-fns/addYears';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { useEffect, useMemo, useState } from 'react';
import { Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../api/axios';
import { doUpdateDelayedBilling } from '../../api/cpqService';
import {
  DELATED_BILLING_QUOTE_START_SOURCE,
  QUOTE_CONTRACT_LENGTHS,
  QUOTE_CUSTOM_CONTRACT_LENGTHS,
  QUOTE_NOT_ACTIONABLE_STATUSES,
  QUOTE_START_DATE_SOURCE_OPTIONS,
} from '../../constants/quotes';
import { useQuoteSettings } from '../../hooks';
import { getAnniversaries } from '../../routes/Quotes/Quote/components/quoteOffering/quoteOfferingUtils';
import {
  ContactLengthPeriodPopoverSchema,
  IContactLengthPeriodPopoverSchema,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  QuoteAmendmentVersionEnum,
  QuoteStartDateSourceEnum,
  QuoteStatusEnum,
  RateBillingFrequencyEnum,
} from '../../types';
import { pluralize } from '../../utils';
import {
  getContractLengthInMonthOrYear,
  isContractLengthWholeYear,
} from '../../utils/quotes';
import {
  MBox,
  MButton,
  MCustomNumberInput,
  MDivider,
  MFlex,
  MFormField,
  MInputRightElement,
  MPopover,
  MRadio,
  MRadioGroup,
  MText,
  MVStack,
  RadioStyleButtonGroup,
} from '../Monetize';
import { DatePicker } from '../Monetize/DatePicker/DatePicker';
import { MTertiaryArrowButton } from '../Monetize/MTertiaryArrowButton';

interface IQuoteContractLengthPeriodPopoverProps {
  isCustomContractLength: boolean;
  isNew: boolean;
  isAmendment: boolean;
  isRenewal: boolean;
  quote: IQuoteRespSchema;
  setValue: UseFormSetValue<IQuoteRequestSchema>;
  handleSubmitButton: () => void;
  isReadOnly: boolean;
  renderTrigger?: (props: {
    isOpen: boolean;
    isDisabled: boolean;
    contractLengthFormattedVal: string;
    contractPeriodFormattedVal: string;
    handleOnOpen?: () => void;
  }) => React.ReactNode;
  setQuote: (quote: IQuoteRespSchema) => void;
}

const customContractOptions = QUOTE_CUSTOM_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));
const contractLengthOptions = QUOTE_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));

const getContractStartDateTooltipHint = (
  useDelayedBilling: boolean,
  allowQuoteLevelOverrideOfStartDateSource: boolean,
  canEditDelayedBillingStartDate: boolean,
  startDateSource?: QuoteStartDateSourceEnum | null,
) => {
  if (!useDelayedBilling) {
    return { tooltip: '', hint: '' };
  }

  if (!allowQuoteLevelOverrideOfStartDateSource) {
    switch (startDateSource) {
      case QuoteStartDateSourceEnum.MANUAL:
        return {
          tooltip:
            'Starting the contract once its manually processed can move the start date to a later time, which will shift the entire contract. The new dates will be reflected in the contract period and billing schedule.',
          hint: 'Contract Starts once it’s manually processed.',
        };
      case QuoteStartDateSourceEnum.AT_TIME_OF_SIGNING:
        return {
          tooltip:
            'Starting the contract once its signed can move the start date to a later time, which will shift the entire contract. The new dates will be reflected in the contract period and billing schedule.',
          hint: 'Contract Starts once signed.',
        };
      case QuoteStartDateSourceEnum.ORIGINAL_CONTRACT_START:
        return {
          tooltip: '',
          hint: 'Contract Starts once signed.',
        };
      default:
        return { tooltip: '', hint: '' };
    }
  }

  if (canEditDelayedBillingStartDate) {
    switch (startDateSource) {
      case QuoteStartDateSourceEnum.MANUAL:
        return {
          tooltip: '',
          hint: 'Contract Starts once it’s manually processed.',
        };
      case QuoteStartDateSourceEnum.AT_TIME_OF_SIGNING:
        return {
          tooltip: '',
          hint: 'Contract Starts once signed.',
        };
      case QuoteStartDateSourceEnum.ORIGINAL_CONTRACT_START:
        return {
          tooltip: '',
          hint: 'Contract Starts once signed.',
        };
      default:
        return { tooltip: '', hint: '' };
    }
  }

  switch (startDateSource) {
    case QuoteStartDateSourceEnum.MANUAL:
    case QuoteStartDateSourceEnum.AT_TIME_OF_SIGNING:
      return {
        tooltip:
          'This selection determines when the contract begins. Starting the contract once its signed or manually processed can move the start date to a later time, which will shift the entire contract. The new dates will be reflected in the contract period and billing schedule.',
        hint: '',
      };
    case QuoteStartDateSourceEnum.ORIGINAL_CONTRACT_START:
    default:
      return { tooltip: '', hint: '' };
  }
};

export const QuoteContractLengthPeriodPopover = ({
  isCustomContractLength,
  isNew,
  quote,
  setValue,
  isAmendment,
  isRenewal,
  handleSubmitButton,
  isReadOnly,
  renderTrigger,
  setQuote,
}: IQuoteContractLengthPeriodPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const isQuoteAccepted = quote.status === QuoteStatusEnum.ACCEPTED;
  const {
    quoteSettings: {
      allowQuoteLevelOverrideOfStartDateSource,
      pauseAtAccepted,
    } = {},
  } = useQuoteSettings();
  const [isLoading, setIsLoading] = useState(false);

  const defaultContractVal = getContractLengthInMonthOrYear(
    isCustomContractLength,
    quote.contractLength,
  );

  const defaultContractLen =
    !isCustomContractLength && !isContractLengthWholeYear(quote.contractLength)
      ? null // As CustomContractLength config changes from tenant level and value not whole year so showing blank on popover [BP-8982]
      : defaultContractVal;
  const {
    formState: { isDirty, isValid, errors },
    control,
    handleSubmit,
    setValue: setContractLengthFormValue,
    watch,
    setError,
    clearErrors,
    reset,
  } = useForm<IContactLengthPeriodPopoverSchema>({
    resolver: zodResolver(ContactLengthPeriodPopoverSchema),
    mode: 'onChange',
    defaultValues: {
      contractLength: defaultContractLen,
      contractStartDate: quote.contractStartDate,
      startDateSource: quote.startDateSource,
    },
  });

  const watchContractLength = watch('contractLength') || 0;
  const isQuoteNotActionable = QUOTE_NOT_ACTIONABLE_STATUSES.has(quote.status);
  const isDisabled = isQuoteNotActionable;

  const handleOnOpen = () => {
    if (isDisabled) {
      return;
    }

    onOpen();
  };

  useEffect(() => {
    if (quote.contractLength) {
      reset({
        contractLength: defaultContractLen,
        contractStartDate: quote.contractStartDate,
        startDateSource: quote.startDateSource,
      });
    }
  }, [isCustomContractLength, quote, reset, isOpen, defaultContractLen]);

  const onChangeContractLength = (value: number) => {
    const contractLengthMonths = isCustomContractLength ? value : value * 12;

    setContractLengthFormValue('contractLength', value, {
      shouldDirty: contractLengthMonths > 0 && contractLengthMonths < 121,
      shouldValidate: contractLengthMonths > 0 && contractLengthMonths < 121,
    });

    if (contractLengthMonths === 0 || contractLengthMonths > 120) {
      setError(
        'contractLength',
        {
          message: `The contract length must be between ${
            isCustomContractLength ? '1 and 120' : '1 and 10 years'
          }`,
        },
        { shouldFocus: true },
      );
    } else {
      clearErrors('contractLength');
    }
  };
  const watchContractStartDate = watch('contractStartDate') || '';
  const watchStartDateSource = watch('startDateSource');
  const watchContractEndDate = isCustomContractLength
    ? addDays(
        addMonthsCustom(
          startOfDay(parseISO(watchContractStartDate)),
          watchContractLength,
        ),
        -1,
      )
    : addDays(
        addYears(
          startOfDay(parseISO(watchContractStartDate)),
          watchContractLength,
        ),
        -1,
      );

  const willDateChange =
    watchStartDateSource &&
    DELATED_BILLING_QUOTE_START_SOURCE.has(watchStartDateSource) &&
    !QUOTE_NOT_ACTIONABLE_STATUSES.has(quote.status) &&
    isNew &&
    pauseAtAccepted;

  const canConfigureDelayedBilling = useMemo(() => {
    // We will allow quoteOfferings stants/ends on it's annual anniversary date
    const annualAnniversaries = getAnniversaries(
      quote.contractStartDate,
      quote.contractEndDate,
    );

    return quote?.quoteOfferings?.every(
      ({ startDate, endDate, billingFrequency }) => {
        return (
          billingFrequency !== RateBillingFrequencyEnum.ONETIME && // consider non-onetime billing frequencies
          annualAnniversaries.includes(startDate)
        );
      },
    );
  }, [quote]);

  const isDisabledQuoteUpdate =
    (isAmendment && quote?.amendmentVersion === QuoteAmendmentVersionEnum.v1) ||
    isReadOnly;

  const contractLengthLabel =
    !isCustomContractLength && isContractLengthWholeYear(quote.contractLength)
      ? pluralize('year', quote.contractLength / 12)
      : pluralize('month', quote.contractLength);
  const contractLengthFormattedVal = `${getContractLengthInMonthOrYear(
    isCustomContractLength,
    quote.contractLength,
  )} ${contractLengthLabel}`;

  const contractPeriodFormattedVal =
    quote?.contractStartDate && quote.contractEndDate
      ? `${toDateShort(quote?.contractStartDate || null)} - ${toDateShort(
          quote?.contractEndDate || null,
        )}`
      : '';

  const canEditDelayedBillingStartDate =
    !isRenewal &&
    !isAmendment &&
    isQuoteAccepted &&
    isNew &&
    watchStartDateSource &&
    [QuoteStartDateSourceEnum.MANUAL].includes(watchStartDateSource);

  const handleUpdateDelayedBilling = async () => {
    try {
      setIsLoading(true);
      const newQuote = await doUpdateDelayedBilling(
        quote.id,
        watchContractStartDate!,
      );
      setQuote(newQuote);
      onClose();
    } catch (err) {
      handleApiErrorToast(err);
    }
    setIsLoading(false);
  };

  const onSubmitForm = async () => {
    if (canEditDelayedBillingStartDate) {
      handleUpdateDelayedBilling();
    } else {
      onClose();

      setValue(
        'contractLength',
        isCustomContractLength ? watchContractLength : watchContractLength * 12,
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
      setValue('contractStartDate', watchContractStartDate, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('startDateSource', watchStartDateSource, {
        shouldDirty: true,
        shouldValidate: true,
      });
      handleSubmitButton();
    }
  };

  return (
    <MPopover
      isLazy
      isOpen={isOpen}
      onOpen={handleOnOpen}
      onClose={onClose}
      returnFocusOnClose={true}
      size={'medium'}
      strategy="fixed"
      placement="bottom-end"
      offset={[0, 0]}
    >
      <MFlex minH={8} align="center" mr={!isDisabled ? -3 : 0}>
        <PopoverTrigger>
          <MBox position="relative">
            {renderTrigger &&
              renderTrigger({
                isOpen,
                isDisabled,
                contractLengthFormattedVal,
                contractPeriodFormattedVal,
                handleOnOpen,
              })}

            {!renderTrigger && isDisabled && (
              <MBox textAlign="right">
                <MText>{contractPeriodFormattedVal}</MText>
                <MText fontSize="xs">{contractLengthFormattedVal}</MText>
              </MBox>
            )}

            {!renderTrigger && !isDisabled && (
              <MTertiaryArrowButton
                isOpen={isOpen}
                setIsOpen={(val) => (val ? handleOnOpen() : onClose())}
                minH="40px"
              >
                <MBox textAlign="right">
                  <MText>{contractPeriodFormattedVal}</MText>
                  <MText fontSize="xs">{contractLengthFormattedVal}</MText>
                </MBox>
              </MTertiaryArrowButton>
            )}
          </MBox>
        </PopoverTrigger>
      </MFlex>

      <Portal>
        <PopoverContent
          data-testid="custom-contractLength-popover-content"
          w="280px"
        >
          <PopoverBody>
            <FocusLock>
              <form onSubmit={handleSubmit(onSubmitForm)}>
                <MVStack spacing={2} alignItems={'flex-start'}>
                  {/* If quote is processed, we only allow to update start date */}
                  <MFormField
                    error={errors?.contractStartDate}
                    label={
                      willDateChange ? `Estimated Start Date` : 'Start Date'
                    }
                    {...getContractStartDateTooltipHint(
                      !isAmendment && !isRenewal && !!pauseAtAccepted,
                      !!allowQuoteLevelOverrideOfStartDateSource,
                      !!canEditDelayedBillingStartDate,
                      watchStartDateSource,
                    )}
                  >
                    <Controller
                      name="contractStartDate"
                      control={control}
                      render={({ field: { onChange, ...rest } }) => (
                        <>
                          {isRenewal || isAmendment ? (
                            <MText>
                              {toDateShort(quote?.contractStartDate || null)}
                            </MText>
                          ) : (
                            <DatePicker
                              {...rest}
                              btnText={toDateShort(
                                quote?.contractStartDate || null,
                              )}
                              onChange={(startDate) => {
                                onChange(startDate);
                              }}
                              isDisabled={!quote}
                              isReadOnly={
                                isDisabledQuoteUpdate &&
                                !canEditDelayedBillingStartDate
                              }
                            />
                          )}
                        </>
                      )}
                    />
                  </MFormField>

                  {allowQuoteLevelOverrideOfStartDateSource &&
                    pauseAtAccepted && (
                      <MBox>
                        <MFormField
                          error={errors?.startDateSource}
                          onClick={(ev) => ev.stopPropagation()}
                          w="auto"
                        >
                          <Controller
                            name="startDateSource"
                            control={control}
                            render={({
                              field: { value, onChange, ...rest },
                            }) => (
                              <MRadioGroup
                                onChange={(val: string) => onChange(val)}
                                value={value || ''}
                                rowGap={2}
                                isDisabled={
                                  !canConfigureDelayedBilling ||
                                  isDisabledQuoteUpdate
                                }
                              >
                                {QUOTE_START_DATE_SOURCE_OPTIONS.map(
                                  ({ value, title }) => (
                                    <MRadio value={value} key={value} mt="2">
                                      {title}
                                    </MRadio>
                                  ),
                                )}
                              </MRadioGroup>
                            )}
                          />
                        </MFormField>

                        {!canConfigureDelayedBilling && (
                          <MText color="tRed.base">
                            Edit the offerings to ensure they all begin on the
                            contract start anniversary.
                          </MText>
                        )}
                      </MBox>
                    )}

                  <MFormField
                    label={willDateChange ? `Estimated End Date` : 'End Date'}
                  >
                    <MText>
                      {toDateShort(watchContractEndDate, 'userTimezone')}
                    </MText>
                  </MFormField>

                  <MFormField
                    error={errors?.contractLength}
                    isRequired
                    label="Contract Length"
                    mt={4}
                  >
                    {isDisabledQuoteUpdate ? (
                      <MText>{contractLengthFormattedVal}</MText>
                    ) : (
                      <Controller
                        name="contractLength"
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <MCustomNumberInput
                            inputMode="numeric"
                            value={value as number}
                            onChange={(
                              valueAsString: string,
                              valueAsNumber: number,
                            ) => {
                              onChangeContractLength(valueAsNumber);
                            }}
                            RightElement={
                              <MInputRightElement>
                                <MText mr="6" color="tBlack.300">
                                  {isCustomContractLength ? 'months' : 'years'}
                                </MText>
                              </MInputRightElement>
                            }
                            {...rest}
                          />
                        )}
                      />
                    )}
                  </MFormField>

                  {!isDisabledQuoteUpdate && (
                    <RadioStyleButtonGroup
                      name="contractLength"
                      options={
                        isCustomContractLength
                          ? customContractOptions
                          : contractLengthOptions
                      }
                      value={`${watchContractLength || ''}` || undefined}
                      defaultValue={`${watchContractLength || ''}` || undefined}
                      onChange={(value: string) => {
                        setContractLengthFormValue(
                          'contractLength',
                          Number(value),
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          },
                        );
                      }}
                    />
                  )}
                  <MDivider />

                  <MVStack alignItems={'flex-end'} w="full">
                    <MButton
                      type="submit"
                      minWidth={'80px'}
                      isDisabled={!isValid || !isDirty}
                      isLoading={isLoading}
                    >
                      Save
                    </MButton>
                  </MVStack>
                </MVStack>
              </form>
            </FocusLock>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </MPopover>
  );
};
