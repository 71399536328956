import { z } from 'zod';

export const DealRoomSettingSchema = z.object({
  enabled: z.boolean(),
  useDefaultWelcomeMessage: z.boolean(),
  defaultWelcomeMessage: z.string().nullish(),
  defaultWelcomeUrl: z.string().url().nullish(),
  canEndCustomerChangeSigningContact: z.object({
    canChange: z.boolean(),
    canCreate: z.boolean(),
  }),
});

export type IDealRoomSettingSchema = z.infer<typeof DealRoomSettingSchema>;

export const DealRoomSettingWelcomeSchema = z.object({
  useDefaultWelcomeMessage: z.boolean(),
  defaultWelcomeMessage: z.string().nullish(),
  defaultWelcomeUrl: z.string().url().or(z.literal('')).nullable().optional(),
});

export type IDealRoomSettingWelcomeSchema = z.infer<
  typeof DealRoomSettingWelcomeSchema
>;
