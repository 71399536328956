import { BaseResponseSchema } from '@monetize/types/common';
import { getRequiredMessage } from '@monetize/utils/core';
import { isAfter } from 'date-fns/isAfter';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import isNumber from 'lodash/isNumber';
import { array, boolean, number, string, z } from 'zod';
import {
  OfferingStatusEnumZ,
  PriceModelEnum,
  ProductTypeEnum,
  ProductTypeEnumZ,
  RateBillingFrequencyEnumZ,
} from './enums.types';
import { PriceReqSchema, PriceResSchema } from './price.types';

export enum PriceDisplayEnum {
  PRODUCT = 'PRODUCT',
  OFFERING = 'OFFERING',
}
export const PriceDisplayEnumZ = z.nativeEnum(PriceDisplayEnum);

export enum RateStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}
export const RateStatusEnumZ = z.nativeEnum(RateStatusEnum);

export enum TrialEndActionEnum {
  INVOICE = 'INVOICE',
  CANCEL = 'CANCEL',
  DIFFERENT_RATE = 'DIFFERENT_RATE',
}

export const TrialEndActionEnumZ = z.nativeEnum(TrialEndActionEnum);

export enum SubscriptionTimingEnum {
  ADVANCE = 'ADVANCE',
  ARREARS = 'ARREARS',
}

export const SubscriptionTimingEnumZ = z.nativeEnum(SubscriptionTimingEnum);

export enum RateUsageBillingFrequencyEnum {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  SEMIANNUALLY = 'SEMIANNUALLY',
  ANNUALLY = 'ANNUALLY',
}

export const RateUsageBillingFrequencyEnumZ = z.nativeEnum(
  RateUsageBillingFrequencyEnum,
);

// not true enum on BE, these are lowercase by design.
export enum RateTypeEnum {
  CATALOG = 'CATALOG',
  ACCOUNT = 'ACCOUNT',
}
const RateTypeEnumZ = z.nativeEnum(RateTypeEnum);

export enum AggregationModelEnum {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
}

export const AggregationModelEnumZ = z.nativeEnum(AggregationModelEnum);

const ProductOptionSchema = z.object({
  productId: z.string().nonempty(getRequiredMessage('Product Id')),
  aggregationModel: AggregationModelEnumZ,
});

export type IProductOptionSchema = z.infer<typeof ProductOptionSchema>;

const ProductOptionsSchema = z.object({
  productOptions: z.array(ProductOptionSchema),
  priceDisplay: PriceDisplayEnumZ.nullish(),
});

export type IProductOptionsSchema = z.infer<typeof ProductOptionsSchema>;

export enum MinCommitConfigTypeEnum {
  USAGE_PRODUCTS = 'USAGE_PRODUCTS',
  SPECIFIC_USAGE_PRODUCTS = 'SPECIFIC_USAGE_PRODUCTS',
}
export const MinCommitConfigTypeEnumZ = z.nativeEnum(MinCommitConfigTypeEnum);

export enum PercentOfTotalConfigTypeEnum {
  ALL_ELIGIBLE = 'ALL_ELIGIBLE',
  SPECIFIED_PRODUCTS = 'SPECIFIED_PRODUCTS',
}
export const PercentOfTotalConfigTypeEnumZ = z.nativeEnum(
  PercentOfTotalConfigTypeEnum,
);
/**
 *
 * Handles validation logic for prices under any rateReq using zod
 *
 * @param product {IPricesAndOptionReqSchema}
 * @param key {string}
 * @param ctx {z.RefinementCtx}
 */

const doPricesReqValidation = ({
  product,
  key,
  ctx,
}: {
  product: IPricesAndOptionsReqSchema;
  key: string;
  ctx: z.RefinementCtx;
}) => {
  product.prices.map(({ to, from }, index) => {
    const lastProductIndex = product.prices.length - 1;

    if (
      (lastProductIndex !== index && !isNumber(to)) ||
      (isNumber(from) && isNumber(to) && from > to)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid value',
        path: [`products.${key}.prices.${index}.to`],
      });
    }

    if (
      product.productType === ProductTypeEnum.USAGE &&
      lastProductIndex === index &&
      isNumber(to)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The final tier must have infinity as the "Up to" value',
        path: [`products.${key}.prices.${index}.to`],
      });
    }
  });
};

// from POST /api/offerings/{offeringId}/rates
export const RateReqSchema = z.object({
  id: z.string().optional(),
  customId: z.string().nullish(),
  name: z.string().max(50).nonempty(getRequiredMessage('Name')),
  description: z
    .string()
    .max(80)
    .nonempty(getRequiredMessage('Description'))
    .refine((val) => !!val.trim().length, {
      message: getRequiredMessage('Description'),
    }),
  status: RateStatusEnumZ,
  currency: z.string().nonempty(getRequiredMessage('Currency')),
  billingFrequency: RateBillingFrequencyEnumZ.nullish(),
  billingFrequencyInMonths: z.number().nullish(),
  usageBillingFrequency: RateUsageBillingFrequencyEnumZ.nullish(),
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  parentRateId: z.string().nullish(),
  accountId: z.string().nullish(),
  // TODO: temporarily not required - if null, assumed to be "catalog"
  rateType: RateTypeEnumZ.nullish(),
  quotable: boolean(),
  prices: array(PriceReqSchema),
  subscriptionTiming: SubscriptionTimingEnumZ.nullish(),
  internalDescription: z.string().nullish(),
  options: ProductOptionsSchema.nullish(),
  // Required if Offering Type is Min Commit
  minCommitConfig: z
    .object({
      type: MinCommitConfigTypeEnumZ,
      // Only included if type is _USAGESPECIFIC_PRODUCTS
      productIds: z.array(z.string()).nullish(),
    })
    .nullish(),
  percentOfTotalConfig: z
    .object({
      type: PercentOfTotalConfigTypeEnumZ,
      // Only included if type is SPECIFIED_PRODUCTS
      productIds: z.array(z.string()).nullish(),
    })
    .nullish(),
});

export type IRateReqSchema = z.infer<typeof RateReqSchema>;

const PricesAndOptionsReqSchema = z.object({
  productType: ProductTypeEnumZ,
  prices: PriceReqSchema.array(),
  aggregationModel: AggregationModelEnumZ.nullish(),
});
export type IPricesAndOptionsReqSchema = z.infer<
  typeof PricesAndOptionsReqSchema
>;
// Crate catalog rates
export const AdditionalFrequencySchema = z.object({
  billingFrequencyInMonths: z.number(),
  billingFrequency: RateBillingFrequencyEnumZ.nullish(),
  name: z.string(),
  description: z.string(),
});
export const RateReqSchemaUI = RateReqSchema.extend({
  prices: PriceReqSchema.array().nullish(), // the UI builds the prices on the request after form validation, so we must make it optional until the UI code is fixed
  products: z.object({}).catchall(PricesAndOptionsReqSchema), // products are used by UI to capture price inputs -- keyed by productId, which we cannot predict, so this allows any key
  additionalFrequencies: z.array(AdditionalFrequencySchema).nullish(),
}).superRefine((data, ctx) => {
  const startDate = data.startDate ? parseISO(data.startDate) : undefined;
  const endDate = data.endDate ? parseISO(data.endDate) : undefined;

  if (startDate && endDate && isBefore(endDate, startDate)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `end date must be after start date`,
      path: ['endDate'],
    });
  }

  if (startDate && endDate && isAfter(startDate, endDate)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `start date must be before end date`,
      path: ['startDate'],
    });
  }

  // Handles validation logic for PercentOfTotalConfig if type is Specified Products
  if (
    data.percentOfTotalConfig?.type ===
      PercentOfTotalConfigTypeEnum.SPECIFIED_PRODUCTS &&
    !(
      data.percentOfTotalConfig.productIds &&
      data.percentOfTotalConfig.productIds?.length
    )
  ) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Minimum one product is required',
      path: [`percentOfTotalConfig.productIds`],
    });
  }

  // Validation rule for aggregation model
  Object.keys(data.products).map((key) => {
    const product = data.products[key];
    if (
      product.productType === ProductTypeEnum.USAGE &&
      !product.aggregationModel
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Aggregation Model is required',
        path: [`products.${key}.aggregationModel`],
      });
    }

    product.prices.map(({ priceModel, to, from, amount }, index) => {
      const lastProductIndex = product.prices.length - 1;

      if (
        (lastProductIndex !== index && !isNumber(to)) ||
        (isNumber(from) && isNumber(to) && from > to)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid value',
          path: [`products.${key}.prices.${index}.to`],
        });
      }

      if (
        product.productType === ProductTypeEnum.USAGE &&
        lastProductIndex === index &&
        isNumber(to)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The final tier must have infinity as the "Up to" value',
          path: [`products.${key}.prices.${index}.to`],
        });
      }

      if (
        priceModel === PriceModelEnum.PERCENT_OF_TOTAL &&
        (!isNumber(amount) || amount <= 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Percentage must be greater than 0',
          path: [`products.${key}.prices.${index}.amount`],
        });
      }
    });
  });

  // Validation rule for additional frequencies
  if (Array.isArray(data.additionalFrequencies)) {
    data.additionalFrequencies.map(({ name, description }, index) => {
      if (!name) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Rate Name is required',
          path: [`additionalFrequencies.${index}.name`],
        });
      }
      if (!description) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Description is required',
          path: [`additionalFrequencies.${index}.description`],
        });
      }
    });
  }
});

export type IRateReqSchemaUI = z.infer<typeof RateReqSchemaUI>;
export type AdditionalFrequency = z.infer<typeof AdditionalFrequencySchema>;

// Create account specific rates
export const AccountRateCreateReqSchema = RateReqSchema.pick({
  accountId: true,
  parentRateId: true,
  name: true,
  billingFrequency: true,
  billingFrequencyInMonths: true,
}).extend({
  rateType: z.literal(RateTypeEnum.ACCOUNT),
  prices: PriceReqSchema.array(),
});

export type IAccountRateCreateReqSchema = z.infer<
  typeof AccountRateCreateReqSchema
>;

// Create account specific rates - used for form validation prior to constructing the actual request
export const AccountRateCreateReqSchemaUI = AccountRateCreateReqSchema.extend({
  prices: PriceReqSchema.array().nullish(),
  products: z.object({}).catchall(PricesAndOptionsReqSchema), // products are used by UI to capture price inputs -- keyed by productId, which we cannot predict, so this allows any key
}).superRefine((data, ctx) => {
  // Validation rule for price tiers
  Object.keys(data.products).map((key) => {
    const product = data.products[key];
    doPricesReqValidation({ product, key, ctx });
  });
});

export type IAccountRateCreateReqSchemaUI = z.infer<
  typeof AccountRateCreateReqSchemaUI
>;

// Update account specific rates
export const AccountRateUpdateReqSchema = RateReqSchema.pick({
  name: true,
  billingFrequency: true,
  billingFrequencyInMonths: true,
}).extend({
  rateType: z.literal(RateTypeEnum.ACCOUNT),
  prices: PriceReqSchema.array(),
});

export type IAccountRateUpdateReqSchema = z.infer<
  typeof AccountRateUpdateReqSchema
>;

// Helper fn to distinguish between create and update
export function isAccountUpdateReqSchema(
  value: any,
): value is IAccountRateUpdateReqSchema {
  return !!value.id;
}

// Update account specific rates - used for form validation prior to constructing the actual request
export const AccountRateUpdateReqSchemaUI = AccountRateUpdateReqSchema.extend({
  prices: PriceReqSchema.array().nullish(),
  products: z.object({}).catchall(PricesAndOptionsReqSchema), // products are used by UI to capture price inputs -- keyed by productId, which we cannot predict, so this allows any key
}).superRefine((data, ctx) => {
  // Validation rule for price tiers
  Object.keys(data.products).map((key) => {
    const product = data.products[key];
    doPricesReqValidation({ product, key, ctx });
  });
});

export type IAccountRateUpdateReqSchemaUI = z.infer<
  typeof AccountRateUpdateReqSchemaUI
>;

// referenced in GET /api/offerings/${id}
export const RateResBaseSchema = BaseResponseSchema.extend({
  accountId: string().nullish(),
  // TODO: temporarily not required - if null, assumed to be "catalog"
  rateType: RateTypeEnumZ.nullish(),
  billingFrequency: RateBillingFrequencyEnumZ,
  billingFrequencyInMonths: number().nullish(),
  currency: z.string(),
  customId: string().nullish(),
  description: string(),
  endDate: string().nullable(),
  locked: boolean(),
  name: string(),
  quotable: boolean(),
  startDate: string().nullable(),
  status: RateStatusEnumZ,
  subscriptionTiming: SubscriptionTimingEnumZ.nullish(),
  usageBillingFrequency: RateUsageBillingFrequencyEnumZ.nullable(),
  parentRateId: string().nullish(),
  internalDescription: string().nullable(),
  options: ProductOptionsSchema.nullish(),
});
export type IRateResBaseSchema = z.infer<typeof RateResBaseSchema>;

// from GET /api/rates/{$rateId}
export const RateResSchema = RateResBaseSchema.extend({
  accountName: z.string().nullish(),
  offering: BaseResponseSchema.extend({
    customId: z.string(),
    name: z.string(),
    description: z.string(),
    status: OfferingStatusEnumZ,
    startDate: z.string(),
    endDate: z.string().nullable(),
  }),
  prices: z.array(PriceResSchema),
  productPriceRanges: z.record(
    z.object({
      min: z.number(),
      max: z.number().nullable(),
    }),
  ),
  minCommitConfig: z
    .object({
      type: MinCommitConfigTypeEnumZ,
      // Only included if type is _USAGESPECIFIC_PRODUCTS
      productIds: z.array(z.string()).nullish(),
    })
    .nullish(),
  percentOfTotalConfig: z
    .object({
      type: PercentOfTotalConfigTypeEnumZ,
      // Only included if type is SPECIFIED_PRODUCTS
      productIds: z.array(z.string()).nullish(),
    })
    .nullish(),
});
export type IRateResSchema = z.infer<typeof RateResSchema>;

export const RateNameReqSchema = z.object({
  name: string().max(50).nonempty(getRequiredMessage('Name')),
});

export type IRateNameReqSchema = z.infer<typeof RateNameReqSchema>;

export const RateSubscriptionFrequencyReqSchema = z.object({
  billingFrequency: RateBillingFrequencyEnumZ.nullish(),
  billingFrequencyInMonths: z
    .union([z.string(), z.number()])
    .refine(
      (val) => val !== null && val !== undefined && val !== '' && val !== 0,
      {
        message: getRequiredMessage('Billing Frequency'),
      },
    )
    .transform((val) => Number(val))
    .refine((val) => val <= 120, {
      message: 'Billing Frequency can not be more than 120 months',
    })
    .nullish(),
});

export type IRateSubscriptionFrequencyReqSchema = z.infer<
  typeof RateSubscriptionFrequencyReqSchema
>;
