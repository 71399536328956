import { QuoteBodyAccordion } from '../../../../components/Quotes/QuoteBodyAccordion';
import { QuotePageCustomer } from './QuotePageCustomer';

interface QuoteCustomizingPdfProps {
  setContactDrawerOpen: (value: boolean) => void;
  openAccordionIndex: number[];
  accordionIndex: number;
  customizePdfFormDisabled: boolean;
}

export const QuoteCustomizingPdf = ({
  accordionIndex,
  openAccordionIndex,
  customizePdfFormDisabled,
  setContactDrawerOpen,
}: QuoteCustomizingPdfProps) => {
  return (
    <QuoteBodyAccordion title="Preview Quote">
      {openAccordionIndex.includes(accordionIndex) && (
        <QuotePageCustomer
          setContactDrawerOpen={setContactDrawerOpen}
          showQuoteInformation={false}
          showDownloadButton={false}
          isExternalView={false}
          customizePdfFormDisabled={customizePdfFormDisabled}
        />
      )}
    </QuoteBodyAccordion>
  );
};
