import { RuleStatusEnum, RuleTargetEnum } from '../types';

/**
 * TODO: move these to rule.types
 * but needs to be updated in rules type lib and app, and there are two other dependencies
 * so a bit of a lift to work through it
 */

export const RULE_STATUS_DISPLAY: {
  [key in RuleStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ARCHIVED: 'Archived',
};

export const RULE_TARGET_DISPLAY: {
  [key in RuleTargetEnum]: string;
} = {
  QUOTE: 'Quote',
  QUOTE_ITEM: 'Quote Item',
};
