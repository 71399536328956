import { v4 as uuidv4 } from 'uuid';
import {
  ISubscriptionOfferingReqUI,
  SubscriptionBillingStatusEnum,
  SubscriptionScheduledChangeEnum,
  UsagePeriodPointerEnum,
} from '../types';
import { objectToSelectOptions } from '../utils/misc';

export const USAGE_PERIOD_ENUM_DISPLAY: {
  [key in UsagePeriodPointerEnum]: string;
} = {
  CURRENT: 'Current',
  PREVIOUS: 'Previous',
};

export const UsagePeriodSelectOptions = objectToSelectOptions(
  USAGE_PERIOD_ENUM_DISPLAY,
);

export const SubscriptionBillingStatusDisplayText: {
  [key in SubscriptionBillingStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  TRIAL: 'Trial',
};

export const STATUS_OPTIONS = objectToSelectOptions(
  SubscriptionBillingStatusDisplayText,
);

export const NEW_BILL_GROUP_OPTION = {
  name: '+ New Bill Group',
  id: 'NEW_BILL_GROUP',
};

export const SubscriptionScheduledChangeDisplayText: {
  [key in SubscriptionScheduledChangeEnum]: string;
} = {
  PENDING: 'Pending',
  INVOICED: 'Invoiced',
  COMPLETED: 'Completed',
};

export const getBlankSubscriptionOffering = (): ISubscriptionOfferingReqUI => {
  return {
    referenceId: `temp-${uuidv4()}`,
    billingStatus: SubscriptionBillingStatusEnum.ACTIVE,
    description: '',
    discountIds: [],
    subscriptionItems: [],
    offeringId: '',
    rateId: '',
    quoteOfferingId: '', // not used in FE
    addOn: false,
  };
};

export const SUBSCRIPTION_TABLE_HEADER: string[] = [
  'Offering',
  'Status',
  'Quantity',
  'Discount',
  'Additional Info',
  'Amount',
];

export const REVIEW_SUBSCRIPTION_TABLE_HEADER: string[] = [
  'Offering',
  '',
  'Quantity',
  'Discount',
  'Additional Info',
  'Amount',
];
