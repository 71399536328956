import { FC } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getAccountBillGroupsRoute,
  getAccountDetailRoute,
} from '../../../constants/routes';
import { BillGroupFormModal } from './BillGroupFormModal';

interface BillGroupModalPageProps {
  isReadOnly?: boolean;
}
const BillGroupModalPage: FC<BillGroupModalPageProps> = ({
  isReadOnly,
}: BillGroupModalPageProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const billGroupId = params.billGroupId || '';
  const accountId = params.accountId || '';

  const priorLocation = location.pathname.includes('/overview/')
    ? getAccountDetailRoute(accountId)
    : getAccountBillGroupsRoute(accountId);

  return (
    <BillGroupFormModal
      accountId={accountId}
      isOpen
      billGroupId={billGroupId}
      onClose={() => {
        navigate(priorLocation);
      }}
      isReadOnly={isReadOnly}
    />
  );
};

export default BillGroupModalPage;
