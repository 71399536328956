import { FlexProps, PlacementWithLogical } from '@chakra-ui/react';
import { formatCurrency, formatNumber } from '@monetize/utils/core';
import { MdLock } from 'react-icons/md';
import { NumberStyleEnum } from '../../types';
import { MFlex, MText } from './';
import { MIcon, MTooltip } from './chakra';
import { MTextProps } from './MText';


const lockedTextRendererObj = {
  CURRENCY: (text?: string | number | null, currency: string = 'USD') => {
    if (text || text === 0) {
      return formatCurrency(text, {
        maximumFractionDigits: 'max',
        currency,
      });
    }

    return null;
  },

  QUANTITY: (text?: string | number | null) => {
    if (text || text === 0) {
      return formatNumber(text, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 'max',
      });
    }

    return null;
  },

  LABEL: (text?: string | number | null) => {
    if (text || text === 0) {
      return text;
    }

    return null;
  },

  PERCENTAGE: (text?: string | number | null) => {
    if (text) {
      return formatCurrency(text, {
        maximumFractionDigits: 'max',
        style: NumberStyleEnum.PERCENT,
      });
    }

    return null;
  },
};

interface MLockedTextOrContentPropsBase extends FlexProps {
  isLocked: boolean;
  text?: string | number | null;
  textProps?: MTextProps;
  showLockedIcon?: boolean;
  tooltipText?: string;
  placement?: PlacementWithLogical;
  textType?: 'CURRENCY' | 'QUANTITY' | 'LABEL' | 'PERCENTAGE';
  blankText?: string;
}
interface MLockedTextOrContentPropsCurrency
  extends MLockedTextOrContentPropsBase {
  textType: 'CURRENCY';
  currency: string;
}
interface MLockedTextOrContentPropsOther extends MLockedTextOrContentPropsBase {
  textType?: 'QUANTITY' | 'LABEL' | 'PERCENTAGE';
  currency?: string;
}
type MLockedTextOrContentProps =
  | MLockedTextOrContentPropsCurrency
  | MLockedTextOrContentPropsOther;
const MLockedTextOrContent = ({
  isLocked,
  text = null,
  textProps,
  showLockedIcon = false,
  tooltipText,
  children,
  placement,
  textType = 'LABEL',
  blankText = 'Blank',
  currency,
  ...rest
}: MLockedTextOrContentProps) => {
  if (isLocked) {
    const textToRender = lockedTextRendererObj[textType](text, currency);

    return (
      <MFlex align="center" minH="8" {...rest}>
        {textToRender !== null && <MText {...textProps}>{textToRender}</MText>}
        {textToRender === null && (
          <MText fontStyle="italic" fontWeight="400" {...textProps}>
            {blankText}
          </MText>
        )}
        {showLockedIcon && (
          <MTooltip
            label={tooltipText}
            placement={placement}
            isDisabled={!tooltipText}
          >
            <MFlex>
              <MIcon
                w={4}
                h={6}
                ml={2}
                as={MdLock}
                fontSize="xl"
                alignSelf="center"
                style={{
                  cursor: 'pointer',
                }}
              />
            </MFlex>
          </MTooltip>
        )}
      </MFlex>
    );
  }
  return <>{children}</>;
};

export default MLockedTextOrContent;
