export const DunningIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.17 19L19 14.17L19 5L5 5L5 19L14.17 19ZM14.17 21L5 21C3.9 21 3 20.1 3 19L3 5C3 3.9 3.9 3 5 3L19 3C20.1 3 21 3.9 21 5L21 14.17C21 14.7 20.79 15.21 20.41 15.58L15.58 20.41C15.21 20.79 14.7 21 14.17 21ZM7 9L17 9L17 7L7 7L7 9ZM7 13L17 13L17 11L7 11L7 13ZM7 17L14 17L14 15L7 15L7 17Z"
        fill="currentColor"
      />
      <circle cx="17" cy="7" r="5" fill="white" />
      <path
        d="M17.3022 9.26667C16.3867 9.26667 16.0267 8.85778 15.9778 8.33333H15C15.0533 9.30667 15.7822 9.85333 16.6356 10.0356V11H17.9689V10.0444C18.8356 9.88 19.5244 9.37778 19.5244 8.46667C19.5244 7.20444 18.4444 6.77333 17.4356 6.51111C16.4267 6.24889 16.1022 5.97778 16.1022 5.55556C16.1022 5.07111 16.5511 4.73333 17.3022 4.73333C18.0933 4.73333 18.3867 5.11111 18.4133 5.66667H19.3956C19.3644 4.90222 18.8978 4.2 17.9689 3.97333V3H16.6356V3.96C15.7733 4.14667 15.08 4.70667 15.08 5.56444C15.08 6.59111 15.9289 7.10222 17.1689 7.4C18.28 7.66667 18.5022 8.05778 18.5022 8.47111C18.5022 8.77778 18.2844 9.26667 17.3022 9.26667Z"
        fill="currentColor"
      />
    </svg>
  );
};
