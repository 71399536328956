import { ENVIRONMENT } from '@monetize/ui/config';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import {
  useGetQuoteShare,
  usePrintQuoteShare,
  useSetupIntentForQuoteShare,
} from '../../api/shareService';
import {
  recordShareSelector,
  recordShareState,
} from '../../components/RecordShare/record-share.utils';
import { useRecordShareTrackView } from '../../components/RecordShare/useRecordShareTrackView';
import Sentry from '../../services/sentry';
import {
  IShareInvoiceGenerateSetupIntentResp,
  QuoteStatusEnum,
} from '../../types';

const messages = {
  default:
    'There was a problem processing your request. Ask the sender to share the quote again.',
  404: 'This link is invalid or the quote no longer exists. Ask the sender to share the quote again.',
  403: 'You are not permitted to view this quote, discuss with the sender to determine if they can share the quote again.',
};

export function useQuoteShareData() {
  const recordShareAuthData = useRecoilValue(recordShareSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const resetRecordShareState = useResetRecoilState(recordShareState);

  useRecordShareTrackView(
    recordShareAuthData.tenantId,
    recordShareAuthData.recordId,
    recordShareAuthData.token,
  );

  const [stripeKeys, setStripeKeys] =
    useState<IShareInvoiceGenerateSetupIntentResp>();

  const [paymentIntentError, setPaymentIntentError] = useState<boolean>(false);

  const {
    data: quoteData,
    isLoading: isLoadingQuote,
    isError: quoteDataHasError,
    error: quoteShareError,
  } = useGetQuoteShare(recordShareAuthData, {
    refetchOnWindowFocus: false,
  });

  const {
    data: quotePdfData,
    isLoading: isLoadingPdf,
    isError: quotePdfHasError,
    error: quotePdfError,
  } = usePrintQuoteShare(recordShareAuthData, {
    enabled: !!quoteData?.quote?.id,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: generateSetupIntent, isPending: _isLoadingSetupIntent } =
    useSetupIntentForQuoteShare();

  // initialize payment setup intent
  useEffect(() => {
    if (
      quoteData?.paymentGatewayId &&
      quoteData.quote.status === QuoteStatusEnum.APPROVED
    ) {
      generateSetupIntent({
        ...recordShareAuthData,
        paymentGatewayId: quoteData?.paymentGatewayId,
      })
        .then((keys) => setStripeKeys(keys))
        .catch(() => setPaymentIntentError(true));
    }
  }, [generateSetupIntent, quoteData, recordShareAuthData]);

  const dataLoadingCriticalError = quoteShareError || quotePdfError;

  useEffect(() => {
    if (dataLoadingCriticalError) {
      resetRecordShareState();

      if (ENVIRONMENT.IS_LOCAL_DEV) {
        Sentry.captureException(dataLoadingCriticalError, {
          tags: { type: 'RECORD_SHARE' },
        });
      }

      let errorMessage = messages.default;

      if (isAxiosError(dataLoadingCriticalError)) {
        if (
          dataLoadingCriticalError.response?.status &&
          dataLoadingCriticalError.response.status in messages
        ) {
          errorMessage = messages[dataLoadingCriticalError.response.status];
        }
      }
      errorMessage = errorMessage || messages.default;
      const params = new URLSearchParams(searchParams);
      params.set('error', errorMessage);
      setSearchParams(params);
      location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoadingCriticalError]);

  // Log payment intent errors to sentry
  useEffect(() => {
    if (
      !paymentIntentError ||
      ENVIRONMENT.IS_LOCAL_DEV ||
      // 500s are already logged to sentry
      (isAxiosError(paymentIntentError) && paymentIntentError.status === 500)
    ) {
      return;
    }
    Sentry.captureException(paymentIntentError, {
      tags: { type: 'RECORD_SHARE' },
    });
  }, [paymentIntentError]);

  const shouldCreateSetupIntent =
    quoteData?.paymentGatewayId &&
    quoteData.quote.status === QuoteStatusEnum.APPROVED;

  // Ensure that the UI does not "flash" between loading and rendered state since we use a useEffect to initiate the fetching of the setup intent
  const isLoadingSetupIntent =
    shouldCreateSetupIntent &&
    !paymentIntentError &&
    (_isLoadingSetupIntent || !stripeKeys);

  return {
    isLoading: isLoadingQuote || isLoadingSetupIntent,
    isLoadingPdf,
    isLoadingSetupIntent,
    stripeKeys,
    paymentIntentError,
    quoteData,
    quoteDataHasError,
    quotePdfData,
    quotePdfHasError,
    recordShareAuthData,
  };
}
