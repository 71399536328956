import { addDays } from 'date-fns/addDays';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import { useRecoilState } from 'recoil';
import { IQuoteRespSchema, QuoteTypeEnum } from '../../../../types';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function RenewalNotAvailableBanner({
  quote,
}: {
  quote: IQuoteRespSchema;
}) {
  const showBanner =
    quote?.type === QuoteTypeEnum.RENEWAL &&
    isBefore(new Date(), addDays(parseISO(quote.contractStartDate), -7));

  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'RenewalNotAvailableBanner']),
  );

  if (!showBanner || dismissed) {
    return null;
  }

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="warning">
      Processing this Renewal Quote will no longer allow Amendments to the
      current Contract. Consider waiting until the current Contract is complete
      or near complete before processing this Renewal.
    </QuoteBannerItem>
  );
}
