import { MdMoreVert } from 'react-icons/md';
import {
  MButton,
  MDropdownActionItem,
  MDropdownActions,
  MFlex,
  MIconAction,
  MIDCopyBox,
  MTag,
  MText,
} from '../../../components/Monetize';
import { TenantStatusEnum } from '../../../constants/tenants';
import { useFlags } from '../../../services/launchDarkly';
import { ITenant } from '../../../types';

interface TenantCardProps {
  origin?: 'modal' | 'page';
  tenant: ITenant;
  isCurrentTenant?: boolean;
  onClick: (tenant: ITenant) => void;
  onAccept: (tenant: ITenant) => void;
  onEdit?: (tenant: ITenant) => void;
  onDelete?: (tenant: ITenant) => void;
}

export const TenantCard = ({
  origin = 'modal',
  tenant,
  isCurrentTenant,
  onClick,
  onAccept,
  onEdit,
  onDelete,
}: TenantCardProps) => {
  const { allowTenantDeletion } = useFlags();
  const isDisabled = tenant.status === TenantStatusEnum.INVITED;

  return (
    <MFlex
      justify="space-between"
      p="4"
      borderWidth="1px"
      borderColor={isCurrentTenant ? 'tIndigo.base' : 'tGray.back'}
      boxSizing="border-box"
      borderRadius="2"
      overflow="hidden"
      color="tPurple.base"
      _hover={{
        borderColor: 'tBlue.lightShade',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
      onClick={() => !isDisabled && onClick(tenant)}
      role="group"
      data-testid="tenant-item"
    >
      <MFlex direction="column" w="full" align="start">
        <MFlex justify="start">
          <MText
            fontSize="md"
            fontWeight="semibold"
            color={isDisabled ? 'tGray.lightPurple' : 'tPurple.dark'}
          >
            {tenant.name}
          </MText>
          <MFlex align="flex-start" mt={1}>
            <MTag
              size="xs"
              variant={tenant.testTenant ? 'orange' : 'purple'}
              ml="2"
            >
              {tenant.testTenant ? 'Test' : 'Live'}
            </MTag>

            {isCurrentTenant && (
              <MTag variant="blue" whiteSpace="nowrap" ml="2">
                Current Tenant
              </MTag>
            )}

            {isDisabled && (
              <MTag variant="blue" ml="2" minWidth="14">
                Invited
              </MTag>
            )}
          </MFlex>
        </MFlex>
        <MFlex align="center" mt={1}>
          <MIDCopyBox
            copyValue={tenant.id!}
            displayIcon
            isDisabled={isDisabled}
            fontWeight="normal"
          />
        </MFlex>
      </MFlex>
      <MFlex align="start">
        {isDisabled && (
          <MButton
            variant="primary"
            width={70}
            minWidth={70}
            height="32px"
            minHeight="32px"
            ml="2"
            onClick={() => onAccept(tenant)}
          >
            Accept
          </MButton>
        )}

        {origin === 'page' && !isDisabled && tenant.isAdmin && (
          <MDropdownActions
            mt={0}
            renderTrigger={() => (
              <MFlex
                justify="space-between"
                p={1}
                data-testid={`${tenant.name}-tenant-more-btn`}
              >
                <MIconAction icon={MdMoreVert} />
              </MFlex>
            )}
          >
            <MDropdownActionItem onClick={() => onEdit && onEdit(tenant)}>
              <MText
                color="inherit"
                data-testid={`${tenant.name}-tenant-rename-btn`}
              >
                Edit
              </MText>
            </MDropdownActionItem>

            {allowTenantDeletion && tenant.testTenant && (
              <MDropdownActionItem
                onClick={() => onDelete && onDelete(tenant)}
                colorScheme="tRed.base"
                data-testid={`${tenant.name}-tenant-delete-btn`}
              >
                <MText color="inherit">Delete</MText>
              </MDropdownActionItem>
            )}
          </MDropdownActions>
        )}
      </MFlex>
    </MFlex>
  );
};
