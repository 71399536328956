import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  ApiListResponse,
  BillGroupSummary,
  GetListApiConfig,
  GetListApiFilter,
} from '../types';
import { apiGet, apiGetAllList } from './axios';
import { composeGetQuery } from './utils';

export const billGroupQueryKeys = {
  base: ['billGroups'] as const,
  list: () => [...billGroupQueryKeys.base, 'list'] as const,
};

export function useGetBillGroups<
  SelectData = ApiListResponse<BillGroupSummary>,
>(
  {
    config,
    filters,
  }: {
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  } = {},
  options: Partial<
    UseQueryOptions<ApiListResponse<BillGroupSummary>, unknown, SelectData>
  > = {},
) {
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...billGroupQueryKeys.list(), config, filters],
    queryFn: () =>
      // This is the correct API, it is more performant than the normal bill group API
      apiGet<ApiListResponse<BillGroupSummary>>('/api/billGroups/billRun', {
        params,
      }).then((res) => res.data),
    ...options,
  });
}

export function useGetAllBillGroups<SelectData = BillGroupSummary[]>(
  {
    config,
    filters,
    onProgress,
  }: {
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
    onProgress?: (progress: number) => void;
  } = {},
  options: Partial<
    UseQueryOptions<BillGroupSummary[], unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...billGroupQueryKeys.list(), config, filters],
    queryFn: () =>
      // This is the correct API, it is more performant than the normal bill group API
      apiGetAllList<BillGroupSummary>('/api/billGroups/billRun', {
        rows: config?.rows,
        config,
        filters,
        onProgress,
      }),
    placeholderData: (previousData, previousQuery) => previousData,
    // Keep in cache for up to 10 minutes after being used
    gcTime: 1000 * 60 * 10,
    ...options,
  });
}

export function useGetBillGroupsForBillRun<SelectData = BillGroupSummary[]>(
  {
    config,
    filters,
    onProgress,
  }: {
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
    onProgress?: (progress: number) => void;
  } = {},
  options: Partial<
    UseQueryOptions<BillGroupSummary[], unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...billGroupQueryKeys.list(), config, filters],
    queryFn: () =>
      apiGetAllList<BillGroupSummary>('/api/billGroups/billRun', {
        rows: config?.rows,
        config,
        filters,
        onProgress,
      }),
    ...options,
  });
}
