import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '../api/queryUtils';
import { IOfferingReqSchema, IOfferingRes } from '../types';

const useOffering = (id: string) => {
  const {
    data: offering,
    isLoading: isOfferingLoading,
    refetch: fetchOffering,
  } = useGetById<IOfferingRes>('productCatalogOfferings', id, {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const {
    mutateAsync: createOffering,
    isPending: isCreateOfferingLoading,
    error: createError,
  } = useCreateEntity<IOfferingRes, IOfferingReqSchema>(
    'productCatalogOfferingsV2',
    { meta: { showErrorToast: true } },
  );
  const {
    mutateAsync: updateOffering,
    isPending: isUpdateOfferingLoading,
    error: updateError,
  } = useUpdateEntity<IOfferingRes, IOfferingReqSchema>(
    'productCatalogOfferingsV2',
    { meta: { showErrorToast: true } },
  );

  return {
    isFetching: isOfferingLoading,
    loading: isCreateOfferingLoading || isUpdateOfferingLoading,
    offering,
    error: createError || updateError,
    fetchOffering,
    createOffering,
    updateOffering,
  };
};

export default useOffering;
