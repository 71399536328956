import type { Identifier, XYCoord } from 'dnd-core';
import { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { FiMenu } from 'react-icons/fi';
import { MBox, MCustomIconButton } from '../../../../../components/Monetize';
import { logger } from '../../../../../services/logger';
import { DraggableItem, IQuoteOfferingRespSchema } from '../../../../../types';

interface DragItem {
  id: string;
  index: number;
  quoteOffering: IQuoteOfferingRespSchema;
}

interface QuoteOfferingDraggableProps {
  index: number;
  quoteOffering: IQuoteOfferingRespSchema;
  moveOffering: (dragIndex: number, hoverIndex: number) => void;
  saveMoveOffering: () => void;
  children: React.ReactNode;
}

// https://codesandbox.io/s/cool-lake-remidj
export const QuoteOfferingDraggable = ({
  index,
  quoteOffering,
  moveOffering,
  saveMoveOffering,
  children,
}: QuoteOfferingDraggableProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: DraggableItem.QUOTE_OFFERING,
    options: {},
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item) {
      saveMoveOffering();
    },
    hover(item: DragItem, monitor) {
      try {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY =
          (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveOffering(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      } catch (err) {
        logger.log(err);
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: DraggableItem.QUOTE_OFFERING,
    item: () => {
      return { id: quoteOffering.id, index, quoteOffering };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const opacity = isDragging ? 0 : 1;

  drop(ref);

  return (
    <MBox
      ref={ref}
      opacity={opacity}
      data-handler-id={handlerId}
      position="relative"
      // Increase size of component to allow for larger drop area
      ml="-40px"
      pl="40px"
    >
      <MBox role="group">
        <MCustomIconButton
          bg="none"
          ref={drag}
          cursor="move"
          btnSize={4}
          ml="-20px"
          _hover={{
            background: 'none',
          }}
          mt="12px"
          position="absolute"
          opacity={0}
          _groupHover={{ opacity: 1 }}
          iconColor="#D9D9D9"
          icon={FiMenu}
        />
        <MBox>{children}</MBox>
      </MBox>
    </MBox>
  );
};
