import { Icon } from '@chakra-ui/react';
import { Identifier } from 'dnd-core';
import { FC, useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { MdClose, MdDragIndicator } from 'react-icons/md';
import {
  MCheckbox,
  MCustomIconButton,
  MFlex,
  MGrid,
  MText,
  MTooltip,
} from '../../../../components/Monetize';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '../../../../constants/products';
import { ProductResWithMandatory } from '../../../../types';
import BodyCell from './BodyCell';

type DragItem = {
  index: number;
  id: string;
  type: string;
};

const ItemTypes = {
  PRODUCT: 'PRODUCT',
};

interface DraggableProductRowProps {
  product: ProductResWithMandatory;
  index: number;
  totalColumns: string;
  isEditable: boolean;
  isReadOnly: boolean;
  allowOptionalProducts: boolean;
  isDisabled: boolean;
  showDragHandle: boolean;
  moveProduct: (dragIndex: number, hoverIndex: number) => void;
  saveProductReorder: () => void;
  onRemove: (product: ProductResWithMandatory, i: number) => void;
  onMandatoryCheckboxChange: (product?: ProductResWithMandatory) => void;
  getRowColor: (index: number) => string;
}

const DraggableProductRow: FC<DraggableProductRowProps> = ({
  product,
  index,
  totalColumns,
  isEditable,
  isReadOnly,
  allowOptionalProducts,
  isDisabled,
  showDragHandle,
  moveProduct,
  saveProductReorder,
  onRemove,
  onMandatoryCheckboxChange,
  getRowColor,
}) => {
  const dropContainerRef = useRef<HTMLDivElement>(null);
  const dragHandleRef = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.PRODUCT,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!dropContainerRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect =
        dropContainerRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveProduct(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: ItemTypes.PRODUCT,
    item: { id: product.id, index, originalIndex: index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item) => {
      if (item && item.originalIndex !== item.index) {
        saveProductReorder();
      }
    },
  });

  drag(dragHandleRef);
  drop(dragPreview(dropContainerRef));

  const bgColor = getRowColor(index);
  const opacity = isDragging ? 0.3 : 1;

  return (
    <MGrid
      ref={dropContainerRef}
      style={{ opacity }}
      gridTemplateColumns={totalColumns}
      data-handler-id={handlerId}
      alignItems="center"
      w="full"
    >
      <BodyCell bgColor={bgColor}>
        {showDragHandle && (
          <MFlex
            ref={dragHandleRef}
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              as={MdDragIndicator}
              boxSize={5}
              color="tPurple.dark"
              cursor="move"
            />
          </MFlex>
        )}
      </BodyCell>

      <BodyCell bgColor={bgColor}>
        <MText pl={4} fontSize="sm" color="tPurple.base" fontWeight="regular">
          {product.name}
        </MText>
      </BodyCell>

      <BodyCell bgColor={bgColor}>
        <MText color="tPurple.base" textTransform="capitalize">
          {PRODUCT_TYPE_DISPLAY_SHORT[product.productType]}
        </MText>
      </BodyCell>

      <BodyCell bgColor={bgColor}>
        <MText color="tPurple.base">{product.description}</MText>
      </BodyCell>

      {allowOptionalProducts && (
        <BodyCell bgColor={bgColor} align="center">
          <MCheckbox
            pl={8}
            isDisabled={isDisabled}
            isChecked={product.isMandatory}
            onChange={() => onMandatoryCheckboxChange(product)}
          />
        </BodyCell>
      )}

      <BodyCell bgColor={bgColor} justify="right">
        {!isReadOnly && isEditable && (
          <MTooltip label="Remove" placement="bottom-end">
            <MCustomIconButton
              mr={4}
              title="Remove"
              variant="icon"
              icon={MdClose}
              onClick={() => onRemove(product, index)}
              cursor="pointer"
            />
          </MTooltip>
        )}
      </BodyCell>
    </MGrid>
  );
};

export default DraggableProductRow;
