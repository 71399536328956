import { UserRoleEnum } from '../types';

export const USER_ROLES_DISPLAY: {
  [key in UserRoleEnum]: string;
} = {
  TENANT_ADMIN: 'Tenant Admin',
  BILLING: 'Billing',
  SALES: 'Sales',
  SALES_ADMIN: 'Sales Admin',
  SALES_MANAGER: 'Sales Manager',
  SALES_FULL: 'Sales Full',
  BILLING_ADMIN: 'Billing Admin',
  BILLING_READ_ONLY: 'Billing Read Only',
  SALES_READ_ONLY: 'Sales Read Only',
  APPROVER_ONLY: 'Approver Only',
  SETTINGS_READ_ONLY: 'Settings Read Only',
  REPORTING: 'Reporting',
};

export const ALL_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES_MANAGER,
  UserRoleEnum.SALES_FULL,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING_READ_ONLY,
  UserRoleEnum.SALES_READ_ONLY,
  UserRoleEnum.APPROVER_ONLY,
  UserRoleEnum.SETTINGS_READ_ONLY,
  UserRoleEnum.REPORTING,
];

export const ADMIN_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];

export const BILLING_ADMIN_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];

// Menu Access Roles
export const DASHBOARD_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.BILLING_READ_ONLY,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES_READ_ONLY,
  UserRoleEnum.SALES_MANAGER,
  UserRoleEnum.APPROVER_ONLY,
  UserRoleEnum.SALES_FULL,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const ACCOUNTS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.BILLING_READ_ONLY,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_READ_ONLY,
];

export const QUOTES_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_READ_ONLY,
  UserRoleEnum.SALES_MANAGER,
  UserRoleEnum.SALES_FULL,
];
export const PROD_CATALOG_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
export const SETTINGS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
// END of Menu Access Roles

// Pages Roles
// Dashboard
export const DASHBOARD_FINANCE_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.BILLING_READ_ONLY,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const DASHBOARD_SALES_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_READ_ONLY,
  UserRoleEnum.SALES_FULL,
];

export const DASHBOARD_APPROVAL_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.APPROVER_ONLY,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
];

export const DASHBOARD_BILLING_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
  UserRoleEnum.BILLING_READ_ONLY,
];

// Accounts

// Invoice
export const INVOICE_DETAIL_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.BILLING,
];

// Route Access Roles
export const PRODUCT_CATALOG_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];
export const QUOTE_ACCESS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SALES_FULL,
];

// Product Catalog ROLES
export const PRODUCT_LIST_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
export const PRODUCT_NEW_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];
export const PRODUCT_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const OFFERING_LIST_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
export const OFFERING_NEW_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];
export const OFFERING_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const ONETIME_LIST_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];
export const ONETIME_NEW_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];
export const ONETIME_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];

export const DISCOUNT_LIST_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
export const DISCOUNT_NEW_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];
export const DISCOUNT_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
export const USAGE_TYPE_LIST_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

// Quotes ROLES
export const QUOTE_LIST_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_READ_ONLY,
  UserRoleEnum.SALES_MANAGER,
  UserRoleEnum.SALES_FULL,
];
export const ACCOUNT_QUOTE_NEW_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SALES_FULL,
];

// SETTINGS
//  Billing menu
export const BILLING_MENU_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_TAX_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_REV_REC_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_BILLING_SETTINGS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_DUNNING_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_SUBSCRIPTIONS_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_PRICING_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_PAYMENT_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_INVOICES_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_INVOICE_TEMPLATES_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
// End of Billing Menu

// Quoting (Settings) menu
export const QUOTING_MENU_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_QUOTE_TEMPLATES_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
export const SETTINGS_QUOTE_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SALES,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_APPROVALS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_CONDITIONAL_TERM_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_VALIDATIONS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_TEAMS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
export const SETTINGS_TEAMS_EDIT_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
];
export const SETTINGS_TEAMS_DETAILS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
];

export const SETTINGS_EMAIL_REMINDERS = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_DEAL_ROOM_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

// End of Quoting (Settings) menu

// Integrations Menu
export const INTEGRATIONS_MENU_ROLE = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_PAYMENT_PROCESSOR_ROLES = [
  // Payment Gateway
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_SLACK_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_CRM_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_E_SIGN_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_WEBHOOK_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_ACCOUNTING_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const SETTINGS_INTEGRATION_LOG_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];
// End of Integrations

// Tenant (Settings) menu
export const TENANT_MENU_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const COMPANY_SETTINGS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const CUSTOM_FIELDS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const API_KEYS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const EMAIL_NOTIFICATIONS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const CURRENCY_SETTINGS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SETTINGS_READ_ONLY,
];

export const DOMAIN_SETTINGS_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
];

export const REPORTING_ROLES = [
  UserRoleEnum.TENANT_ADMIN,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.SALES_ADMIN,
  UserRoleEnum.REPORTING,
];

export const REVENUE_ROLES = [
  UserRoleEnum.BILLING,
  UserRoleEnum.BILLING_ADMIN,
  UserRoleEnum.TENANT_ADMIN,
];
