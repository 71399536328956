import { useACL } from '../services/acl/acl';

export const useSettingsReadOnlyRule = () => {
  const { canDo } = useACL();
  const canCreateSettings = canDo([['settings', 'create']]);
  const canReadSettings = canDo([['settings', 'read']]);
  const isReadOnly = !canCreateSettings && canReadSettings;
  return {
    canCreateSettings,
    isReadOnly,
    inputVariant: isReadOnly ? 'readonly' : 'primary',
  };
};
