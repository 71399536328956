export const SubscriptionsIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2512_1188)">
        <path
          d="M12.3643 11.3889C11.1032 11.0611 10.6976 10.7222 10.6976 10.1944C10.6976 9.58889 11.2587 9.16667 12.1976 9.16667C13.1865 9.16667 13.5532 9.63889 13.5865 10.3333H14.8143C14.7754 9.37778 14.192 8.5 13.0309 8.21667V7H11.3643V8.2C10.2865 8.43333 9.41982 9.13333 9.41982 10.2056C9.41982 11.4889 10.4809 12.1278 12.0309 12.5C13.4198 12.8333 13.6976 13.3222 13.6976 13.8389C13.6976 14.2222 13.4254 14.8333 12.1976 14.8333C11.0532 14.8333 10.6032 14.3222 10.542 13.6667H9.31982C9.38649 14.8833 10.2976 15.5667 11.3643 15.7944V17H13.0309V15.8056C14.1143 15.6 14.9754 14.9722 14.9754 13.8333C14.9754 12.2556 13.6254 11.7167 12.3643 11.3889Z"
          fill="currentColor"
        />
        <path
          d="M16.0002 14.001V16.001H18.9102C17.5302 18.391 14.9502 20.001 12.0002 20.001C8.28024 20.001 5.15024 17.441 4.26024 14.001H2.20024C3.13025 18.561 7.16024 22.001 12.0002 22.001C15.2702 22.001 18.1802 20.421 20.0002 17.991V20.001H22.0002V14.001H16.0002Z"
          fill="currentColor"
        />
        <path
          d="M8.00005 10.001V8.00098H5.09005C6.47005 5.61098 9.05005 4.00098 12 4.00098C15.72 4.00098 18.85 6.56098 19.74 10.001H21.8C20.87 5.44098 16.84 2.00098 12 2.00098C8.73005 2.00098 5.82005 3.58098 4.00005 6.01098V4.00098H2.00005V10.001L8.00005 10.001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2512_1188">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
