import { CardBody } from '@chakra-ui/react';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import {
  MBox,
  MButton,
  MCard,
  MFormField,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '../../../../components/Monetize';
import { useACL } from '../../../../services/acl/acl';
import {
  AddressSourceEnum,
  ContactWithoutEsignTypes,
  IContactAddressDataSchema,
  IContactRespSchema,
  QuoteSettingsDefaultAddressSourceEnum,
} from '../../../../types';
import { LegalEntityAddressFormatEnum } from '../../../../types/legalEntityTypes';
import { sortAlphabetically } from '../../../../utils';
import { ContactItem } from './ContactItem';
import { ContactsSelectInput } from './ContactsSelectInput';
import { OnetimeInvoiceContactForm } from './OnetimeInvoiceContactForm';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { useGetAccountById } from '../../../../api/accountsService';
import { useFlags } from '../../../../services/launchDarkly';
import { appGlobalDataState } from '../../../../store/global.store';

interface ContactListProps {
  isDisabled?: boolean;
  accountId: string;
  accountContacts: IContactRespSchema[];
  contacts: ContactWithoutEsignTypes[];
  isInternal: boolean;
  onToggle: (
    contact: ContactWithoutEsignTypes,
    type: 'primary' | 'billing',
    value: boolean,
    isInternal: boolean,
  ) => void;
  onRemove: (contact: ContactWithoutEsignTypes, isInternal: boolean) => void;
  onAddContact: (contactId: string, isInternal: boolean) => void;
  onUpdateContact: (contact: IContactRespSchema, isInternal: boolean) => void;
  onNewContact: (contact: IContactRespSchema, isInternal: boolean) => void;
  addressFormat?: LegalEntityAddressFormatEnum | null;
  canUpdateContact: boolean;
  canCreateContact: boolean;
  addressDataForm: UseFormReturn<IContactAddressDataSchema>;
  onSaveAddress: () => void;
}

export const ContactList: FC<ContactListProps> = ({
  isDisabled = false,
  accountId,
  accountContacts,
  contacts,
  isInternal,
  onToggle,
  onRemove,
  onAddContact,
  onUpdateContact,
  onNewContact,
  addressFormat,
  canUpdateContact,
  canCreateContact,
  addressDataForm,
}: ContactListProps) => {
  const { canDo } = useACL();

  const { enableAccountBasedAddresses } = useFlags();
  const [availableContacts, setAvailableContacts] = useState(accountContacts);
  const [addContactActive, setAddContactActive] = useState(!contacts.length);
  const [newContactOpen, setNewContactOpen] = useState(false);
  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);
  const addressSource = addressDataForm.watch('addressSource');
  const { data: accountDetails, isLoading: isAccountLoading } =
    useGetAccountById(accountId, {
      enabled: !!accountId && !isInternal,
    });

  useEffect(() => {
    if (contacts && accountContacts) {
      const contactIds = new Set(contacts.map(({ contact }) => contact.id));
      setAvailableContacts(
        accountContacts
          .filter(({ id }) => !contactIds.has(id))
          .sort((a, b) => sortAlphabetically('fullName')(a, b)),
      );
    }
  }, [contacts, accountContacts]);

  function handleShowContactSelectionList() {
    setAddContactActive(false);
    setNewContactOpen(true);
  }

  function handleContactSelected(contactId: string) {
    onAddContact(contactId, isInternal);
    setAddContactActive(false);
    setNewContactOpen(false);
  }

  function handleNewContact(contact?: IContactRespSchema) {
    if (contact) {
      onNewContact(contact, isInternal);
    }
    setNewContactOpen(false);
  }

  let allowAdditionalContact = false;
  if (isInternal) {
    allowAdditionalContact = contacts.length < 1;
  } else {
    allowAdditionalContact = contacts.length < 2;
  }

  return (
    <>
      {enableAccountBasedAddresses && hasCrmConfigured && !isInternal && (
        <MCard variant="borderless">
          <CardBody py="2">
            <MText fontSize="sm" fontWeight="600">
              Address Sources
            </MText>
            <MText my="1" fontSize="sm">
              Select the source of Shipping and Billing Addresses.
            </MText>
            <Controller
              name="addressSource"
              control={addressDataForm.control}
              render={({ field }) => (
                <MRadioGroup {...field} isDisabled={isDisabled}>
                  <MStack
                    spacing={1}
                    direction="column"
                    color="tGray.darkPurple"
                  >
                    <MRadio
                      value={QuoteSettingsDefaultAddressSourceEnum.ACCOUNT}
                      onChange={() => {
                        addressDataForm.setValue(
                          'billingAddressId',
                          accountDetails?.billingAddress?.id,
                          { shouldDirty: true },
                        );
                        addressDataForm.setValue(
                          'shippingAddressId',
                          accountDetails?.shippingAddress?.id,
                          { shouldDirty: true },
                        );
                      }}
                      fontSize="sm"
                    >
                      Source from Account Addresses
                    </MRadio>
                    <MRadio
                      value={QuoteSettingsDefaultAddressSourceEnum.CONTACT}
                      fontSize="sm"
                      onChange={() => {
                        addressDataForm.setValue('billingAddressId', '', {
                          shouldDirty: true,
                        });
                        addressDataForm.setValue('shippingAddressId', '', {
                          shouldDirty: true,
                        });
                      }}
                    >
                      Source from Contacts
                    </MRadio>
                  </MStack>
                </MRadioGroup>
              )}
            />
          </CardBody>
        </MCard>
      )}
      {contacts.map((contact, index) => (
        <ContactItem
          key={contact.contact.id || contact.contact.snapshotId}
          isDisabled={isDisabled}
          isInternal={isInternal}
          contact={contact}
          onToggleType={onToggle}
          onUpdated={({ contact }) => onUpdateContact(contact, isInternal)}
          onRemove={(contact, isInternal) => {
            onRemove(contact, isInternal);
            if (index === 0) {
              setAddContactActive(true);
            }
          }}
          addressFormat={addressFormat}
          canUpdateContact={canUpdateContact}
          showAddress={
            isInternal ||
            (!isInternal && addressSource === AddressSourceEnum.CONTACT)
          }
        />
      ))}

      {!isDisabled && (
        <>
          {addContactActive && (
            <MCard variant="borderless" data-testid="eSign-contact-select">
              <CardBody pt="2">
                <MFormField label="Select Contact">
                  <ContactsSelectInput
                    contacts={availableContacts}
                    onChange={handleContactSelected}
                    onNewContact={handleShowContactSelectionList}
                    canCreateContact={canCreateContact}
                  />
                </MFormField>
              </CardBody>
            </MCard>
          )}

          {newContactOpen && (
            <MCard variant="borderless">
              <CardBody pt={2}>
                <OnetimeInvoiceContactForm
                  isInternal={isInternal}
                  accountId={accountId}
                  onClose={handleNewContact}
                />
              </CardBody>
            </MCard>
          )}

          {allowAdditionalContact && !addContactActive && !newContactOpen && (
            <MBox>
              <MButton
                data-testid={`add-contact-btn-${
                  isInternal ? 'internal' : 'external'
                }`}
                variant="tertiary"
                size="sm"
                onClick={() => setAddContactActive(true)}
              >
                + Add Contact
              </MButton>
            </MBox>
          )}
        </>
      )}
    </>
  );
};
