import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { useGetNewPayingCustomers } from '../../../../api/dashboardsService';
import {
  MBox,
  MChart,
  MSkeleton,
  MText,
} from '../../../../components/Monetize';
import { newPayingCustomerChartOptions } from './chartOptions';

export const NewPayingCustomersChart = () => {
  const { isLoading, data: newPayingCustomers } = useGetNewPayingCustomers({
    select: (data) => ({
      options: newPayingCustomerChartOptions,
      series: [
        {
          data: data?.map(({ yearMonth, total }: any) => ({
            x: formatDate(parseISO(yearMonth), 'MMM'),
            y: total,
          })),
        },
      ],
    }),
  });

  return (
    <MBox>
      <MText fontSize="lg" fontWeight="bold">
        New Paying Customers
      </MText>
      {isLoading ? (
        <MSkeleton height={300} />
      ) : (
        <MChart
          options={newPayingCustomers?.options || {}}
          series={newPayingCustomers?.series || []}
          height={300}
        />
      )}
    </MBox>
  );
};
