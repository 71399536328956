import { forwardRef, useEffect, useState } from 'react';
import { contactSelectRenderItem } from '../../../../../components/Contacts/ContactSelect';
import { MCustomSelect } from '../../../../../components/Monetize';
import { MainSearchInput } from '../../../../../components/Monetize/MCustomSelect/components/MainSearchInput';
import { CONTACTS } from '../../../../../constants';
import { IContactRespSchema } from '../../../../../types';

interface QuoteContactsSelectInputProps {
  isDisabled?: boolean;
  contacts: IContactRespSchema[];
  loading?: boolean;
  onChange: (contactId: string) => void;
  onNewContact: () => void;
  canCreateContact: boolean;
}

export const QuoteContactsSelectInput = forwardRef(
  (
    {
      isDisabled,
      contacts,
      loading,
      onChange,
      onNewContact,
      canCreateContact,
    }: QuoteContactsSelectInputProps,
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const [iContacts, setIContacts] = useState(contacts);
    const onChangeQuery = (q: string): void => {
      if (q !== query) {
        setQuery(q);
      }
    };

    useEffect(() => {
      if (query) {
        setIContacts(
          contacts.filter(
            (c) =>
              (c.fullName &&
                c.fullName.toLowerCase().includes(query.toLowerCase())) ||
              (c.email && c.email.toLowerCase().includes(query.toLowerCase())),
          ),
        );
      } else {
        setIContacts(contacts);
      }
    }, [contacts, query]);
    return (
      <MCustomSelect
        name="contacts"
        placeholder="Select Contact"
        loading={loading}
        items={iContacts}
        itemTitle="fullName"
        itemValue="id"
        onChange={(ev: any) => onChange(ev as string)}
        useMainInputAsSearchInput
        MainInputComponent={MainSearchInput}
        useHighlightedItemList
        showItemDivider
        inputProps={{
          autoComplete: 'off',
        }}
        useExternalQuery
        externalQuery={query}
        onChangeQuery={onChangeQuery}
        skipFilter={true}
        prependItems={
          canCreateContact
            ? [
                {
                  item: CONTACTS.NEW_CONTACT,
                  isAction: true,
                  hasDivider: true,
                  onClick: ({ onClose }) => {
                    onClose && onClose();
                    onNewContact();
                  },
                },
              ]
            : []
        }
        renderItemContent={contactSelectRenderItem}
        isDisabled={isDisabled}
      />
    );
  },
);

export default QuoteContactsSelectInput;
