import type { CreateTypes, Options } from 'canvas-confetti';
import { CSSProperties, useCallback, useEffect, useRef } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useReducedMotion } from '../../../../hooks';

const canvasStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};

interface QuoteConfettiProps {
  active: boolean;
}

export const QuoteConfetti = (props: QuoteConfettiProps) => {
  const { active } = props;

  const hasReducedMotion = useReducedMotion();
  const refAnimationInstance = useRef<CreateTypes | null>(null);

  const getInstance = useCallback((confetti: CreateTypes | null) => {
    refAnimationInstance.current = confetti;
  }, []);

  const makeShot = useCallback((particleRatio: number, opts: Options) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  useEffect(() => {
    if (active && !hasReducedMotion) {
      fire();
    }
  }, [active, hasReducedMotion, fire]);

  return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
};
