import { HStack } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MButton, MFlex, MText } from '../Monetize';

interface IDeleteConfirmCardProps {
  deleteNote: () => void;
  cancelDelete: () => void;
  loading: boolean;
}

export const DeleteConfirmCard: FC<IDeleteConfirmCardProps> = React.forwardRef<
  any,
  IDeleteConfirmCardProps
>((props: IDeleteConfirmCardProps, ref) => {
  const { loading, deleteNote, cancelDelete } = props;

  return (
    <MFlex flex={1} flexDir="column" h="auto">
      <MText textAlign="center" fontSize="md" fontWeight="bold">
        Are you sure you want to delete this note?
      </MText>
      <HStack
        spacing="4"
        justifyContent="center"
        alignItems="center"
        mt="4"
        mb={2}
      >
        <MButton minW="96px" variant="cancel" onClick={cancelDelete}>
          Cancel
        </MButton>
        <MButton
          minW="96px"
          variant="delete"
          onClick={deleteNote}
          isLoading={loading}
        >
          Delete
        </MButton>
      </HStack>
    </MFlex>
  );
});
