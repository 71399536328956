import React, { FunctionComponent, PropsWithChildren } from 'react';
import { MFlex } from '../../../../components/Monetize';

const BodyCell: FunctionComponent<any> = (
  props: PropsWithChildren<any>,
): React.ReactElement => {
  const { children, ...rest } = props;

  return (
    <MFlex align="center" height="100%" py={2} {...rest}>
      {children}
    </MFlex>
  );
};

export default BodyCell;
