import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { MBox, MChart, MSkeleton } from '../../../../../components/Monetize';
import { MonthlySale, SalesCreatedByFilter } from '../../../../../types';
import { formatCurrency, sortDateByAscendingOrder } from '../../../../../utils';
import { salesChartOptions, salesTooltipStyles } from '../chartOptions';

const SalesChart = ({
  createdByFilter,
  series,
  isLoading,
  currency,
}: {
  createdByFilter: SalesCreatedByFilter;
  series: MonthlySale[];
  isLoading: boolean;
  currency: string;
}) => {
  const seriesData = [
    {
      name: 'sales-series',
      data: sortDateByAscendingOrder(series, 'yearMonth')?.map(
        ({ yearMonth, amount, numberOfContracts }: MonthlySale) => ({
          x: formatDate(parseISO(yearMonth), 'MMM'),
          y: amount,
          numberOfContracts,
          currency,
        }),
      ),
    },
  ];
  const chartOptions = {
    ...salesChartOptions(currency),
    tooltip: {
      custom: (data: any) => {
        const { series, seriesIndex, dataPointIndex } = data;
        return `<div class="custom-tooltip" style="${salesTooltipStyles}"> 
          <p>${seriesData[seriesIndex].data[dataPointIndex].x}</p>
          <p>Sales: <b>${formatCurrency(series[seriesIndex][dataPointIndex], {
            currency,
          })}</b>
          <p>Contracts: <b>${
            seriesData[seriesIndex].data[dataPointIndex].numberOfContracts
          }</b></p>
        </div>`;
      },
    },
  };

  return (
    <MBox height="100%" minHeight="310px">
      {isLoading ? (
        <MSkeleton />
      ) : (
        <MChart
          options={chartOptions}
          series={seriesData || []}
          height={'100%'}
          id="sales-by-month"
        />
      )}
    </MBox>
  );
};

export { SalesChart };
