import { Flex } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {
  MBox,
  MCustomSelect,
  MFlex,
  MFormField,
  MInput,
  MText,
} from '../../../../../components/Monetize';
import { MainSearchInputV2 } from '../../../../../components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { CUSTOM_FIELDS } from '../../../../../constants';
import { CUSTOM_FIELD_TYPE_DISPLAY } from '../../../../../constants/customFields';
import {
  QUESTION_TYPE_OPTIONS,
  QuestionCompareFromFields,
  QuestionFilterByDisplayText,
} from '../../../../../constants/guidedQuoting';
import {
  CustomFieldTypeEnum,
  GuidedQuotingReq,
  ICustomFieldResSchema,
  QuestionComparatorEnum,
  QuestionCompareFromCustomFields,
  QuestionFilterByEnum,
  QuestionTypesEnum,
} from '../../../../../types';
import { getFirstValue } from '../../../../../utils';
import { objectToSelectOptions } from '../../../../../utils/misc';
import {
  getComparators,
  getCustomFieldsForEntityAndSelect,
  renderItemContentForItemsWithKeyDisplayLabel,
  showCompare,
} from '../guidedQuoting.utils';
import { GuidedQuotingCompareTo } from './GuidedQuotingCompareTo';

interface GuidedQuotingQuestionProps {
  errors: FieldErrors<GuidedQuotingReq>;
  index: number;
  control: Control<GuidedQuotingReq, any>;
  customFields: ICustomFieldResSchema[];
  setValue: UseFormSetValue<GuidedQuotingReq>;
  clearErrors: UseFormClearErrors<GuidedQuotingReq>;
}

export const GuidedQuotingQuestion = ({
  errors,
  index,
  control,
  customFields,
  setValue,
  clearErrors,
}: GuidedQuotingQuestionProps) => {
  const questionErrors = errors?.questions?.[index] || {};
  // This is from zod and error is for the question as a whole
  const globalErrorMessage = (questionErrors as any)?.message as
    | string
    | undefined;

  const {
    id,
    type: responseType,
    filterBy,
    customFieldEntity,
    compareFrom,
    customField,
    comparator,
  } = useWatch({
    control,
    name: `questions.${index}`,
  });

  const allQuestions = useWatch({
    control,
    name: 'questions',
  });

  const { customFieldsForSelectedEntity, customFieldOptions } = useMemo(() => {
    return getCustomFieldsForEntityAndSelect(
      customFields,
      responseType,
      customFieldEntity,
      compareFrom,
    );
  }, [compareFrom, customFieldEntity, customFields, responseType]);

  const { filterByOptions, showFilterBy } = useMemo(() => {
    const options = objectToSelectOptions(
      QuestionFilterByDisplayText[responseType] ?? {},
    );
    return {
      filterByOptions: options,
      showFilterBy: options.length !== 1 || options[0].value !== 'NONE',
    };
  }, [responseType]);

  useEffect(() => {
    if (!comparator && filterBy !== QuestionFilterByEnum.NONE) {
      const items = getComparators(responseType, compareFrom);

      if (Object.keys(items).length === 1) {
        setValue(`questions.${index}.comparator`, QuestionComparatorEnum.EQUAL);
      } else {
        setValue(`questions.${index}.comparator`, null);
      }
    }
  }, [compareFrom, responseType, filterBy, index, setValue, comparator]);

  const resetFromType = () => {
    const updatedQuestions = allQuestions.map((item) => {
      if (getFirstValue(item.compareTo) === id) {
        return {
          ...item,
          compareTo: [],
        };
      }
      return item;
    });
    setValue('questions', updatedQuestions);

    setValue(`questions.${index}.filterBy`, QuestionFilterByEnum.NONE);
    setValue(`questions.${index}.compareFrom`, null);
    setValue(`questions.${index}.customFieldType`, null);
    setValue(`questions.${index}.customFieldEntity`, null);
    setValue(`questions.${index}.customField`, null);
    setValue(`questions.${index}.comparator`, null);
    setValue(`questions.${index}.compareTo`, []);
  };

  const resetFromCustomFieldEntity = () => {
    setValue(`questions.${index}.customFieldType`, null);
    setValue(`questions.${index}.customField`, null);
  };

  const resetFromFilterBy = () => {
    setValue(`questions.${index}.compareFrom`, null);
    setValue(`questions.${index}.customField`, null);
    setValue(`questions.${index}.comparator`, null);
    setValue(`questions.${index}.compareTo`, []);
  };

  const resetFromCompareFrom = () => {
    setValue(`questions.${index}.customField`, null);
    setValue(`questions.${index}.comparator`, null);
    setValue(`questions.${index}.compareTo`, []);
  };

  return (
    <MBox
      border="1px solid"
      borderColor={globalErrorMessage ? 'tRed.base' : 'tGray.back'}
      p={4}
      borderRadius={4}
      boxSizing="border-box"
      mr={2}
    >
      <MFlex gap={4} flexDirection="column">
        {globalErrorMessage && (
          <MText color="tRed.base">{globalErrorMessage}</MText>
        )}
        <MFormField
          error={questionErrors?.questionText}
          label="Question Text"
          tooltip="This is the question that will be displayed to the user."
          isRequired
        >
          <Controller
            name={`questions.${index}.questionText`}
            control={control}
            render={({ field }) => (
              <MInput placeholder="Enter question text" {...field} />
            )}
          />
        </MFormField>
        <MFormField
          error={questionErrors?.type}
          label="Question Type"
          tooltip="This is the type of content to show within the question."
        >
          <Controller
            name={`questions.${index}.type`}
            control={control}
            render={({ field }) => (
              <MCustomSelect
                useMainInputAsSearchInput
                MainInputComponent={MainSearchInputV2}
                items={QUESTION_TYPE_OPTIONS}
                renderItemContent={({ title, item, isSubtitle }) => {
                  return isSubtitle ? (
                    <MText color="tGray.disabledText">{title}</MText>
                  ) : (
                    <Flex flexDirection="column">
                      <MText fontWeight="bold" noOfLines={1}>
                        {title}
                      </MText>
                      <MText noOfLines={1} fontSize="xs">
                        {item.description}
                      </MText>
                    </Flex>
                  );
                }}
                {...field}
                onChange={(value) => {
                  resetFromType();
                  field.onChange(value);
                }}
              />
            )}
          />
        </MFormField>
        {responseType === QuestionTypesEnum.CUSTOM_FIELD && (
          <>
            <MFormField
              error={questionErrors?.customFieldEntity}
              label="Custom Field Entity"
              tooltip="Which object is your custom field on."
            >
              <Controller
                name={`questions.${index}.customFieldEntity`}
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    useMainInputAsSearchInput
                    MainInputComponent={MainSearchInputV2}
                    items={objectToSelectOptions(
                      CUSTOM_FIELDS.CUSTOM_FIELD_ENTITY_DISPLAY,
                    )}
                    {...field}
                    onChange={(value) => {
                      resetFromCustomFieldEntity();
                      setValue(`questions.${index}.customFieldType`, null);
                      setValue(`questions.${index}.customField`, null);
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </MFormField>
            <MFormField
              error={questionErrors?.customField}
              label="Custom Field"
            >
              <Controller
                name={`questions.${index}.customField`}
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    useMainInputAsSearchInput
                    MainInputComponent={MainSearchInputV2}
                    itemTitle="displayLabel"
                    itemValue="key"
                    items={customFieldsForSelectedEntity}
                    renderItemContent={({ title, item }) => {
                      return (
                        <MFlex
                          direction="column"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          w="100%"
                        >
                          <MText fontWeight="bold" noOfLines={1}>
                            {title}
                          </MText>
                          <MFlex justifyContent="space-between" w="100%">
                            <MText color="tGray.darkPurple" fontSize="xs">
                              {item.key}
                            </MText>
                            <MText color="tGray.darkPurple" fontSize="xs">
                              {
                                CUSTOM_FIELD_TYPE_DISPLAY[
                                  item.type as CustomFieldTypeEnum
                                ]
                              }
                            </MText>
                          </MFlex>
                        </MFlex>
                      );
                    }}
                    {...field}
                    onChange={(value) => {
                      setValue(
                        `questions.${index}.customFieldType`,
                        customFieldsForSelectedEntity.find(
                          ({ key }) => key === (value as any),
                        )?.type ?? null,
                      );
                      field.onChange(value);
                    }}
                  />
                )}
              />
            </MFormField>
          </>
        )}
        {showFilterBy && (
          <MFormField
            error={questionErrors?.filterBy}
            label="Filter By"
            tooltip="If applicable to the question type, choose how the options presented to the user for this option should be limited."
          >
            <Controller
              name={`questions.${index}.filterBy`}
              control={control}
              render={({ field }) => (
                <MCustomSelect
                  items={filterByOptions}
                  {...field}
                  onChange={(value) => {
                    resetFromFilterBy();
                    field.onChange(value);
                  }}
                />
              )}
            />
          </MFormField>
        )}
      </MFlex>
      {responseType && filterBy && filterBy !== 'NONE' && (
        <MFlex mt={4} gap={4} flexDirection="column">
          <>
            {Object.keys(QuestionCompareFromFields).includes(responseType) && (
              <MFormField
                error={questionErrors?.compareFrom}
                label="Compare From Field"
                tooltip="This is the field that will be used to filter the results. For Custom Fields, choose the data type of fields you want to filter by and you can choose the exact field in the next step."
              >
                <Controller
                  name={`questions.${index}.compareFrom`}
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      items={objectToSelectOptions(
                        QuestionCompareFromFields[responseType][filterBy],
                      )}
                      {...field}
                      onChange={(value) => {
                        resetFromCompareFrom();
                        field.onChange(value);
                      }}
                    />
                  )}
                />
              </MFormField>
            )}
            {compareFrom &&
              QuestionCompareFromCustomFields.has(compareFrom) && (
                <MFormField
                  error={questionErrors?.customField}
                  label="Custom Field"
                  tooltip="This is the custom field that will be used to filter the results."
                  isRequired
                >
                  <Controller
                    name={`questions.${index}.customField`}
                    control={control}
                    render={({ field }) => (
                      <MCustomSelect
                        items={customFieldOptions}
                        itemTitle={'displayLabel'}
                        itemValue={'key'}
                        renderItemContent={
                          renderItemContentForItemsWithKeyDisplayLabel
                        }
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              )}
            {showCompare(responseType, compareFrom, customField) && (
              <>
                <MFormField
                  error={questionErrors?.comparator}
                  label="Operator"
                  tooltip="The operator to use when filtering records."
                >
                  <Controller
                    name={`questions.${index}.comparator`}
                    control={control}
                    render={({ field }) => (
                      <MCustomSelect
                        items={getComparators(responseType, compareFrom)}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
                <MFormField
                  error={questionErrors?.compareTo}
                  label="Filter To"
                  tooltip="Choose the question that should be used as the value to filter against."
                  isRequired
                >
                  <Controller
                    name={`questions.${index}.compareTo`}
                    control={control}
                    render={({ field }) => (
                      <GuidedQuotingCompareTo
                        index={index}
                        responseType={responseType}
                        filterBy={filterBy}
                        compareFrom={compareFrom}
                        field={field}
                        questions={allQuestions}
                        customFieldKey={customField}
                        customFieldsForSelectedEntity={
                          customFieldsForSelectedEntity
                        }
                      />
                    )}
                  />
                </MFormField>
              </>
            )}
          </>
        </MFlex>
      )}
    </MBox>
  );
};
