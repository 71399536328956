import {
  FocusLock,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { IconType } from 'react-icons/lib/iconBase';
import { MdSettings } from 'react-icons/md';
import { useGetRecordShareEngagements } from '../../../api/shareService';
import { useFlags } from '../../../services/launchDarkly';
import {
  COLLABORATION_ACCESS_DISPLAY,
  CollaborationAccessEnum,
  CollaborationAccessLevelEnum,
  QuoteStatusEnum,
} from '../../../types';
import { objectToSelectOptions } from '../../../utils/misc';
import { MCustomIconButton, MPopover } from '../../Monetize';
import { QuoteCollaborationExternal } from './QuoteCollaborationExternal';
import { QuoteCollaborationInternal } from './QuoteCollaborationInternal';

interface QuoteCollaborationPopoverV2Props {
  quoteId: string;
  quoteExpirationDate: string;
  quoteStatus: QuoteStatusEnum;
  collaborationAccess: CollaborationAccessEnum;
  accessLevel: CollaborationAccessLevelEnum | null;
  onCollaborationAccessChange: (
    collaborationAccess: CollaborationAccessEnum,
  ) => void;
  renderTrigger?: (props: {
    icon: IconType;
    handleOpen: () => void;
    engagementCount?: number;
  }) => React.ReactNode;
}

export const internalCollaborationOptions = objectToSelectOptions(
  COLLABORATION_ACCESS_DISPLAY,
);

export const QuoteCollaborationPopoverV2 = ({
  quoteId,
  quoteExpirationDate,
  collaborationAccess,
  accessLevel,
  quoteStatus,
  onCollaborationAccessChange,
  renderTrigger,
}: QuoteCollaborationPopoverV2Props) => {
  const { quoteShareConfig } = useFlags();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const { data: engagements } = useGetRecordShareEngagements(quoteId, {
    enabled: quoteShareConfig?.enabled,
  });

  function handleOnOpen() {
    onOpen();
  }

  return (
    <MPopover
      placement="right-start"
      isOpen={isOpen}
      onOpen={handleOnOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      closeOnEsc
    >
      <PopoverTrigger>
        {renderTrigger ? (
          renderTrigger({
            icon: MdSettings,
            handleOpen: onOpen,
            engagementCount: engagements?.length,
          })
        ) : (
          <MCustomIconButton
            btnSize={4}
            minW={6}
            minH={6}
            variant="icon"
            icon={MdSettings}
            iconColor="tIndigo.base"
            onClick={() => {
              onOpen();
            }}
          />
        )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent minW="400px">
          <FocusLock>
            <PopoverArrow />
            <PopoverHeader pl={4} mt={3}>
              Share
            </PopoverHeader>
            <PopoverBody>
              <QuoteCollaborationInternal
                quoteId={quoteId}
                quoteExpirationDate={quoteExpirationDate}
                quoteStatus={quoteStatus}
                collaborationAccess={collaborationAccess}
                accessLevel={accessLevel}
                onCollaborationAccessChange={onCollaborationAccessChange}
              />

              <QuoteCollaborationExternal
                quoteId={quoteId}
                quoteExpirationDate={quoteExpirationDate}
                quoteStatus={quoteStatus}
                accessLevel={accessLevel}
                engagements={engagements}
              />
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Portal>
    </MPopover>
  );
};
