import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  MGrid,
  MGridItem,
  MText,
  MVStack,
} from '../../../../../../components/Monetize';
import { useCurrencies } from '../../../../../../hooks/useCurrencies';
import {
  GuidedQuotingContext,
  IGuidedQuotingRespSchema,
  IQuestion,
  Maybe,
  QuestionFilterByEnum,
} from '../../../../../../types';
import { RateQuestionDropdown } from '../RateQuestionDropdown';

interface QuestionFormRateProps {
  question: IQuestion;
  context: Record<string, any>;
  setContext: Dispatch<SetStateAction<GuidedQuotingContext>>;
  offeringQuestion: IQuestion;
  guidedQuoting: IGuidedQuotingRespSchema | undefined;
  accountCurrency?: string;
  filter?: Maybe<Record<string, any>>;
  value: any;
  onChange: (value: any) => void;
}

export const QuestionFormRate = ({
  question,
  context,
  setContext,
  offeringQuestion,
  guidedQuoting,
  accountCurrency,
  filter,
  value,
  onChange,
}: QuestionFormRateProps) => {
  const { defaultCurrency } = useCurrencies();

  // FIXME: this entire flow is convoluted - the parent should own state management
  // and just pass in the data without ambiguity of what data is used for and where it comes from
  // ideally we can have an array of questions+data with strong types
  const currencyValue = useMemo(
    () =>
      guidedQuoting?.quoteSourceFields?.find(
        ({ quoteField }) => quoteField === 'currency',
      ),
    [guidedQuoting?.quoteSourceFields],
  );

  const currency: string =
    currencyValue?.type === QuestionFilterByEnum.QUESTION
      ? context[currencyValue.value as string]?.value
      : (currencyValue?.value ?? accountCurrency ?? defaultCurrency ?? 'USD');

  return (
    <>
      <MText fontWeight="semibold" mb={2}>
        {question.questionNumber}. {question.questionText}
      </MText>
      <MVStack width="100%" mt="0 !important">
        <MGrid
          templateAreas={`"offr rate"`}
          templateColumns={`1fr 1fr`}
          width="100%"
          mt={0}
        >
          <MGridItem
            area="offr"
            display="flex"
            alignItems="center"
            justifyContent="left"
          >
            <MText fontWeight="semibold">Offering Name</MText>
          </MGridItem>
          <MGridItem
            area="rate"
            display="flex"
            alignItems="center"
            justifyContent="left"
          >
            <MText fontWeight="semibold">Rate</MText>
          </MGridItem>
        </MGrid>
        {Object.keys(context[offeringQuestion.id].value).map(
          (offeringId: string) => (
            <MGrid
              key={offeringId}
              templateAreas={`"offr rate"`}
              templateColumns={`1fr 1fr`}
              width="100%"
              mt={2}
            >
              <MGridItem
                area={`offr`}
                display="flex"
                alignItems="center"
                justifyContent="top"
                overflow="auto"
              >
                <MText key={`offering-${offeringId}`}>
                  {context[offeringQuestion.id].value[offeringId].name}
                </MText>
              </MGridItem>
              <MGridItem
                area={'rate'}
                display="flex"
                alignItems="center"
                justifyContent="top"
                overflow="auto"
              >
                <RateQuestionDropdown
                  filter={filter}
                  value={value ? (value[offeringId] ?? null) : null}
                  setValue={(newValue) =>
                    onChange({ ...value, [offeringId]: newValue })
                  }
                  currency={currency}
                  offeringId={offeringId}
                  setContext={setContext}
                  offeringQuestionId={offeringQuestion.id}
                />
              </MGridItem>
            </MGrid>
          ),
        )}
      </MVStack>
    </>
  );
};
