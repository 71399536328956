import { FunctionComponent as FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetById } from '../../../api/queryUtils';
import { MBox, MCenterModal, MPageLoader } from '../../../components/Monetize';
import { MEntityStatusBadge } from '../../../components/Monetize/MEntityStatusBadge';
import { ROUTES } from '../../../constants';
import { PaymentMethodStatusEnumDisplay } from '../../../constants/paymentMethods';
import { IPaymentMethodResp, PaymentMethodTypeEnum } from '../../../types';
import { PaymentMethodAchCredit } from './PaymentMethodAchCredit';
import { PaymentMethodCreditCard } from './PaymentMethodCreditCard';
import { PaymentMethodDirectDebit } from './PaymentMethodDirectDebit';

interface PaymentMethodModalProps {
  isOpen: boolean;
  onClose: () => void;
  isReadOnly?: boolean;
}

export const PaymentMethodModal: FC<PaymentMethodModalProps> = ({
  isOpen,
  onClose,
}: PaymentMethodModalProps) => {
  const params = useParams();
  const paymentMethodId = params?.paymentMethodId || '';
  const navigate = useNavigate();

  const {
    data: paymentMethod,
    isLoading,
    isError,
  } = useGetById<IPaymentMethodResp>(
    'accountPaymentMethods',
    paymentMethodId!,
    {
      enabled: !!paymentMethodId,
      meta: { showErrorToast: true },
    },
  );

  useEffect(() => {
    if (isError && params?.accountId) {
      navigate(ROUTES.getAccountPaymentMethodRoute(params.accountId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, params?.accountId]);

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={'Payment Method'}
      size="sm"
      modalHeaderProps={{ display: 'flex', alignItems: 'center' }}
      renderModalTitleActions={() =>
        paymentMethod?.status && (
          <MEntityStatusBadge
            status={PaymentMethodStatusEnumDisplay[paymentMethod.status]}
          />
        )
      }
    >
      <MBox>
        {isLoading && <MPageLoader height="350px" />}
        {!!paymentMethod && (
          <>
            {paymentMethod.paymentMethodType ===
              PaymentMethodTypeEnum.CREDIT_CARD && (
              <PaymentMethodCreditCard paymentMethod={paymentMethod} />
            )}
            {paymentMethod.paymentMethodType ===
              PaymentMethodTypeEnum.DIRECT_DEBIT && (
              <PaymentMethodDirectDebit paymentMethod={paymentMethod} />
            )}
            {(paymentMethod.paymentMethodType ===
              PaymentMethodTypeEnum.ACH_CREDIT ||
              paymentMethod.paymentMethodType ===
                PaymentMethodTypeEnum.US_BANK_TRANSFER) && (
              <PaymentMethodAchCredit paymentMethod={paymentMethod} />
            )}
          </>
        )}
      </MBox>
    </MCenterModal>
  );
};
