import { BoxProps, useDisclosure } from '@chakra-ui/react';
import DataTableActions, {
  ActionType,
} from '../../../../../components/Monetize/DataTable/MDataTableActions';
import { RateStatusEnum } from '../../../../../types';
import { CustomRateModal } from '../CustomRate/CustomRateModal';

interface RateActionsProps {
  rateId: string;
  isLocked: boolean;
  isNewRate: boolean;
  handleRemove: () => void;
  handleClone: () => void;
  rateStatus?: RateStatusEnum;
  handleStatusChange?: (status: RateStatusEnum) => void;
  boxProps?: BoxProps;
}
export const RateActions = ({
  rateId,
  isLocked,
  isNewRate,
  handleRemove,
  handleClone,
  rateStatus,
  handleStatusChange,
  boxProps = {},
}: RateActionsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const actions: ActionType[] = [
    {
      title: 'Activate',
      enabled: rateStatus === RateStatusEnum.INACTIVE,
      action: () =>
        handleStatusChange && handleStatusChange(RateStatusEnum.ACTIVE),
    },
    {
      title: 'Clone Rate',
      enabled: !isNewRate,
      action: handleClone,
    },
    {
      title: 'Show Custom Rates',
      enabled: true,
      action: onOpen,
    },
    {
      title: 'Deactivate',
      enabled: rateStatus === RateStatusEnum.ACTIVE,
      action: () =>
        handleStatusChange && handleStatusChange(RateStatusEnum.INACTIVE),
    },
    {
      title: 'Delete',
      color: 'danger',
      enabled: !isLocked,
      action: handleRemove,
    },
  ];

  return (
    <>
      <DataTableActions actions={actions} boxProps={boxProps} />
      {isOpen && (
        <CustomRateModal
          data-testid={`custom-rate-modal-${rateId}`}
          parentRateId={rateId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
};
