import { string, z } from 'zod';
import { AllCurrencyCodesSchema } from '../constants/currencies';
import { getRequiredMessage } from '../utils/messages';
import { BaseResponseSchema } from './miscTypes';
import { PaymentMethodTypeEnumZ } from './paymentMethodTypes';

export enum PaymentGatewayTypeEnum {
  STRIPEV2 = 'STRIPEV2',
  BRAINTREE = 'BRAINTREE',
  WORLDPAY = 'WORLDPAY',
  AUTHNET = 'AUTHNET',
  ADYEN = 'ADYEN',
  VANTIV = 'VANTIV',
  PAYPAL_PRO = 'PAYPAL_PRO',
  PAYMENT_TECH = 'PAYMENT_TECH',
  TEST_PASS = 'TEST_PASS',
  TEST_FAIL = 'TEST_FAIL',
}

export enum PaymentGatewayStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const PaymentGatewayStatusDisplayText: {
  [key in PaymentGatewayStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const PaymentGatewayTypeEnumZ = z.nativeEnum(PaymentGatewayTypeEnum);
export const PaymentGatewayStatusEnumZ = z.nativeEnum(PaymentGatewayStatusEnum);

export const PaymentMethodEnabledSchema = z.object({
  method: PaymentMethodTypeEnumZ,
  enabled: z.boolean(),
});
export type IPaymentMethodEnabledSchema = z.infer<
  typeof PaymentMethodEnabledSchema
>;

export const PaymentGatewayAmountConfigurationSchema = z.object({
  currency: AllCurrencyCodesSchema,
  minimum: z
    .union([z.string(), z.number()])
    .transform((val) => Number(val))
    .nullish(),
  maximum: z
    .union([z.string(), z.number()])
    .transform((val) => (val === '' ? null : Number(val)))
    .nullish(),
});

export const PaymentGatewayConfigurationSchema = z.object({
  type: PaymentMethodTypeEnumZ,
  amountConfigurations: PaymentGatewayAmountConfigurationSchema.array(),
});

export const PaymentGatewayConfigurationsSchema = z.object({
  configurations: PaymentGatewayConfigurationSchema.array(),
});

export type PaymentGatewayConfigurations = z.infer<
  typeof PaymentGatewayConfigurationsSchema
>;

export const PaymentGatewayReqSchema = z.object({
  description: z.string().nullish(),
  status: PaymentGatewayStatusEnumZ,
  stripePublishableKey: z.string(),
  stripeSecretKey: z.string().nullish(),
  defaultGateway: z.boolean(),
  type: PaymentGatewayTypeEnumZ,
  // TODO: add better validation for this - one of them should be required
  allowedPaymentMethods: PaymentMethodEnabledSchema.array(),
  paymentConfiguration: PaymentGatewayConfigurationsSchema.nullish(),
});

export type IPaymentGatewayReqSchema = z.infer<typeof PaymentGatewayReqSchema>;

export const PaymentGatewayReqUISchema = PaymentGatewayReqSchema.omit({
  stripePublishableKey: true, // UI uses different field names
  stripeSecretKey: true, // UI uses different field names
  type: true,
  allowedPaymentMethods: true,
}).extend({
  secretKey: z.string().optional(), // only required for new gateways - otherwise can be omitted to keep what is in DB
  publicKey: z.string().nonempty(getRequiredMessage('Public key')),
  creditCardEnabled: z.boolean().optional(),
  directDebitEnabled: z.boolean().optional(),
  achCreditEnabled: z.boolean().optional(),
  usBankTransferEnabled: z.boolean().optional(),
});
export type IPaymentGatewayReqUISchema = z.infer<
  typeof PaymentGatewayReqUISchema
>;

// Retained prior name to avoid large changes across codebase
// This is the response schema
export const IPaymentGatewaySchema = z.object({
  id: z.string(),
  description: z.string().nullish(),
  type: PaymentGatewayTypeEnumZ,
  status: PaymentGatewayStatusEnumZ,
  publicKey: z.string(),
  testGateway: z.boolean(),
  defaultGateway: z.boolean(),
  locked: z.boolean(),
  allowedPaymentMethods: PaymentMethodEnabledSchema.array(),
});
export type IPaymentGateway = z.infer<typeof IPaymentGatewaySchema>;

export interface IPaymentGatewayAccount {
  id: string;
  accountId: string;
  paymentGatewayId: string;
  gatewayCustomerId: string;
}

export const PaymentGatewayAccountSchema = BaseResponseSchema.extend({
  accountId: string(),
  paymentGatewayId: string(),
  gatewayCustomerId: string(),
});

export type IPaymentGatewayAccountSchema = z.infer<
  typeof PaymentGatewayAccountSchema
>;

// this response is generated by our own endpoints, versus
// Stripe's built-in SetupIntentResult and SetupIntent types
export interface SetupIntentResponse {
  setupIntentId: string;
}

export interface PaymentGatewayClientSecretResponse {
  clientSecret: string;
}
