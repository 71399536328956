import { useBreakpoint } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { FiDownload } from 'react-icons/fi';
import { doPrintInvoicePdfForInvoiceShare } from '../../../api/shareService';
import { MCustomIconButton, MHStack } from '../../../components/Monetize';
import DataTableActions, {
  ActionType,
} from '../../../components/Monetize/DataTable/MDataTableActions';
import { IInvoiceRespSchema } from '../../../types';
import { downloadAndSaveToPDF } from '../../../utils';

interface InvoiceActionProps {
  invoice: IInvoiceRespSchema;
  invoiceSecretId: string;
  tenantId: string;
}

export const ViewInvoiceActions: FC<InvoiceActionProps> = ({
  invoice,
  invoiceSecretId,
  tenantId,
}: InvoiceActionProps) => {
  const breakpoint = useBreakpoint();
  if (!invoice) {
    return null;
  }

  const handlePrintToPdf = async () => {
    downloadAndSaveToPDF(() =>
      doPrintInvoicePdfForInvoiceShare(
        tenantId,
        invoiceSecretId,
        invoice.id,
        invoice.invoiceNumber,
      ),
    );
  };

  const actions: ActionType[] = [
    {
      title: 'Download Invoice',
      enabled: true,
      action: () => handlePrintToPdf(),
    },
  ];

  return (
    <>
      {['base', 'sm'].includes(breakpoint) ? (
        <MHStack ml={0} gap={0}>
          <MCustomIconButton
            variant="icon"
            btnSize={breakpoint === 'base' ? 8 : 6}
            p={3}
            _focus={{ bg: 'none' }}
            icon={FiDownload}
            color="tPurple.dark"
            onClick={() => handlePrintToPdf()}
          />
        </MHStack>
      ) : (
        <DataTableActions actions={actions} />
      )}
    </>
  );
};
