import { MBox, MDivider, MText } from '../../../../../components/Monetize';
import { IQuoteOfferingRespSchema } from '../../../../../types';

export const QuoteOfferingDraggablePreview = ({
  quoteOffering,
}: {
  quoteOffering: IQuoteOfferingRespSchema;
}) => {
  return (
    <MBox
      display="flex"
      alignItems="center"
      bg="white"
      w="372px"
      minH="32px"
      borderRadius="md"
      borderWidth="2px"
      borderColor="gray.300"
      ml="8"
      px="2"
    >
      <MBox display="flex" flex="1">
        <MText noOfLines={1}>{quoteOffering.offeringName}</MText>
      </MBox>
      <MDivider
        borderColor="gray.300"
        orientation="vertical"
        h="20px"
        opacity="1"
        w="2px"
        mx="2"
      />
      <MBox display="flex" flex="1">
        <MText noOfLines={1}>{quoteOffering.rateName}</MText>
      </MBox>
    </MBox>
  );
};
