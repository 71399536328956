import {
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { formatInteger } from '@monetize/utils/core';
import { useVirtualizer } from '@tanstack/react-virtual';
import lodashGet from 'lodash/get';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { MdFilterAlt, MdFilterList } from 'react-icons/md';
import { useNonInitialEffect } from '../../../../hooks/useNonInitialEffect';
import MButton from '../../MButton';
import MCustomIconButton from '../../MCustomIconButton';
import { MSearchInput } from '../../MSearchInput';
import MText from '../../MText';
import { MBox, MCheckbox, MFlex } from '../../chakra';
import { DataTableFilterRef, FilterProps } from '../DataTableTypes';

// Stable value to avoid re-renders
const EMPTY_ARRAY: Array<{ label: string; value: string | number | boolean }> =
  [];

/**
 * Filter function for the Set filter
 * Set filter is a set of values shown to the user, similar to excel
 * This filter is only available if all filter values are known in advance
 * * 100% browser side filtering - available for all text fields
 * * Server side filtering - available for enums where we know the values in advance
 *   - *NOT YET IMPLEMENTED FOR SERVER
 *   - *Should be refactored to use filter facets
 *
 * @param row
 * @param columnId
 * @param filterValue
 * @param addMeta
 * @returns
 */
export const DataTableHeaderSetFilter = forwardRef<
  DataTableFilterRef,
  FilterProps<any>
>(({ column, table }, ref) => {
  useImperativeHandle(
    ref,
    (): DataTableFilterRef => ({
      resetFilters: () => {
        handleResetFilters();
      },
      setFilterValue: ({ columnKey, value }) => {
        if (columnKey === column.id && value instanceof Set) {
          column.setFilterValue(value);
        }
      },
    }),
  );

  const key = lodashGet(column, 'columnDef.accessorKey', column.id) as string;
  const filterValues =
    table.options.meta?.filterValues?.get(key) || EMPTY_ARRAY;

  // used to detect if new values are available when the data changes
  const prevFilterValues = useRef(new Set<string | number | boolean>());
  if (!prevFilterValues.current) {
    prevFilterValues.current = new Set(filterValues.map(({ value }) => value));
  }

  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedFilters, setSelectedFilters] = useState(
    () =>
      new Set<string | number | boolean>(
        filterValues?.map(({ value }) => value),
      ),
  );

  const [visibleFilters, setVisibleFilters] = useState(() => filterValues);

  const [searchTerm, setSearchTerm] = useState('');
  const [allSelected, setAllSelected] = useState(
    () => selectedFilters.size === filterValues.length,
  );
  const [someSelected, setSomeSelected] = useState(
    () => selectedFilters.size === filterValues.length,
  );

  useNonInitialEffect(() => {
    table.options.meta?.selectedFilters.set(key, selectedFilters);
    column.setFilterValue(selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column, key, selectedFilters]);

  useNonInitialEffect(() => {
    // if every item in the list is selected, set all selected to true regardless of what is visible
    if (selectedFilters.size === filterValues.length) {
      setAllSelected(true);
    } else if (visibleFilters.length > selectedFilters.size) {
      // if more visible than are selected, it is not possible for all to be selected
      setAllSelected(false);
    } else {
      // See if every visible filter is selected
      setAllSelected(
        visibleFilters.every(({ value }) => selectedFilters.has(value)),
      );
    }
  }, [filterValues, selectedFilters, visibleFilters]);

  /**
   * Handle case where filters are applied but the data in the table changes
   * Some selections may no longer be valid
   * Other selections may need to be added to the list and auto-selected
   */
  useNonInitialEffect(() => {
    if (selectedFilters.size !== 0) {
      setSelectedFilters((prevValue) => {
        const allValues = new Set(filterValues.map(({ value }) => value));
        // Auto-select any new values that just got added to list
        const newValues = filterValues
          .filter(({ value }) => !prevFilterValues.current.has(value))
          .map(({ value }) => value);
        prevFilterValues.current = new Set(
          filterValues.map(({ value }) => value),
        );
        let newValue = new Set([...prevValue, ...newValues]);
        // if all selected values are in list and there are no new values, keep prior selection
        if (
          newValues.length === 0 &&
          Array.from(prevValue).every((value) => allValues.has(value))
        ) {
          return prevValue;
        }
        // remove any selected values that are no longer in the table
        newValue.forEach((value) => {
          if (!allValues.has(value)) {
            newValue.delete(value);
          }
        });
        // Select all if no values are selected
        if (newValue.size === 0) {
          newValue = new Set<any>(filterValues?.map(({ value }) => value));
        }
        return newValue;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues]);

  useNonInitialEffect(() => {
    // if filters change while modal is closed, update column filter
    // to ensure visible rows are modified (we potentially auto-select some values when data changes)
    // e.x. if data is reloaded and new values are added
    if (!isOpen) {
      column.setFilterValue(selectedFilters);
    }
  }, [selectedFilters, isOpen, column]);

  useEffect(() => {
    if (allSelected) {
      setSomeSelected(false);
    } else {
      // see if any, but not all visible items are selected
      setSomeSelected(
        visibleFilters.some(({ value }) => selectedFilters.has(value)),
      );
    }
  }, [allSelected, filterValues, selectedFilters, visibleFilters]);

  useNonInitialEffect(() => {
    if (filterValues && searchTerm) {
      const searchTermLowercase = searchTerm.toLowerCase();
      setVisibleFilters(
        filterValues.filter(({ label }) =>
          label.toLowerCase().includes(searchTermLowercase),
        ),
      );
    } else {
      setVisibleFilters(filterValues);
    }
  }, [searchTerm, filterValues]);

  const handleFilterChange = (value: any, checked: boolean) => {
    if (checked) {
      setSelectedFilters((prev) => new Set(prev.add(value)));
    } else {
      setSelectedFilters((prev) => {
        const newSet = new Set(prev);
        newSet.delete(String(value));
        return newSet;
      });
    }
  };

  const handleToggleSelectAll = () => {
    if (!allSelected) {
      setSelectedFilters(new Set(visibleFilters.map(({ value }) => value)));
    } else {
      setSelectedFilters(new Set());
    }
  };

  const handleResetFilters = () => {
    const filters = new Set(filterValues.map(({ value }) => value));
    setSelectedFilters(filters);
    column.setFilterValue(filters);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    setSearchTerm('');
  };

  const filtersApplied =
    !!filterValues && selectedFilters.size !== filterValues.length;

  const rowVirtualizer = useVirtualizer({
    count: visibleFilters.length,
    getScrollElement: () => scrollableContainerRef.current,
    estimateSize: () => 21,
    overscan: 15,
  });

  return (
    <Popover
      placement="auto-start"
      trigger="click"
      offset={[0, 0]}
      variant="responsive"
      isOpen={isOpen}
      onClose={handleClose}
      strategy="fixed"
      isLazy
    >
      <PopoverTrigger>
        <MCustomIconButton
          data-testid={`table-column-filter-${key}`}
          btnSize={5}
          onClick={onOpen}
          icon={filtersApplied ? MdFilterAlt : MdFilterList}
          variant="icon"
          ml="2"
          color={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
          iconColor={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
        />
      </PopoverTrigger>
      <PopoverContent>
        {isOpen && (
          <FocusLock>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="semibold">
              <MText pt={4} px={4}>
                Filter
              </MText>
            </PopoverHeader>
            <PopoverBody minW="304px">
              {filterValues && filterValues?.length > 0 && (
                <>
                  <MSearchInput
                    value={searchTerm}
                    omitCount
                    mb={2}
                    onChange={setSearchTerm}
                  />
                  <MBox mb={1}>
                    <MCheckbox
                      isChecked={allSelected}
                      isIndeterminate={someSelected}
                      onChange={handleToggleSelectAll}
                    >
                      <MText fontWeight={500}>
                        Select All ({formatInteger(filterValues.length)})
                      </MText>
                    </MCheckbox>
                  </MBox>
                  <MBox maxH="250px" overflowY="auto" width="100%">
                    <MFlex
                      ref={scrollableContainerRef}
                      height={`${rowVirtualizer.getTotalSize()}px`}
                      position="relative"
                      flexDirection="column"
                    >
                      {rowVirtualizer.getVirtualItems().map((virtualRow, i) => {
                        const { label, value } =
                          visibleFilters[virtualRow.index];
                        return (
                          <MBox
                            key={`${label}-${i}`}
                            position="absolute"
                            top="0"
                            left="0"
                            width="100%"
                            height={`${virtualRow.size}px`}
                            transform={`translateY(${virtualRow.start}px)`}
                          >
                            <MCheckbox
                              isChecked={selectedFilters.has(value)}
                              onChange={(e) => {
                                handleFilterChange(value, e.target.checked);
                              }}
                            >
                              <MText fontWeight={400} mb={1}>
                                {label}
                              </MText>
                            </MCheckbox>
                          </MBox>
                        );
                      })}
                    </MFlex>
                  </MBox>
                </>
              )}
            </PopoverBody>
            <PopoverFooter>
              <MFlex justifyContent="space-between">
                <MButton
                  variant="tertiary"
                  size="sm"
                  onClick={handleResetFilters}
                >
                  Reset
                </MButton>
              </MFlex>
            </PopoverFooter>
          </FocusLock>
        )}
      </PopoverContent>
    </Popover>
  );
});
