import { Control, FormState } from 'react-hook-form';
import { z } from 'zod';

export const QuoteTemplateConfigSchema = z.object({
  showBillingSchedule: z.boolean(),
  showCredits: z.boolean(),
  showDiscount: z.boolean(),
  showProration: z.boolean(),
  showPricePerUnit: z.boolean(),
  showProductDescription: z.boolean(),
  showPricing: z.boolean(),
  showPriorContractOnAmendments: z.boolean().optional(),
  showDeltaViewOnAmendments: z.boolean().optional(),
  showRateDescription: z.boolean().optional(),
  showOfferingDescription: z.boolean().optional(), // need to remove when BE will be fixed
  showSigningPage: z.boolean().optional(),
  showUnusedItems: z.boolean().optional(),
  defaultTemplate: z.string().optional(),
});

export type IQuoteTemplateConfigSchema = z.infer<
  typeof QuoteTemplateConfigSchema
>;

export interface IQuoteTemplateConfigRes {
  showBillingSchedule: boolean;
  showCredits: boolean;
  showDiscount: boolean;
  showProration: boolean;
  showPricePerUnit: boolean;
  showProductDescription: boolean;
  showPricing: boolean;
  showPriorContractOnAmendments?: boolean;
  showDeltaViewOnAmendments?: boolean;
  showRateDescription?: boolean;
  showOfferingDescription?: boolean; // need to remove when BE will be fixed
  showSigningPage?: boolean;
  showUnusedItems?: boolean;
  hideUpdatedContractWhenDeltaViewIsSuccessfulOnAmendments?: boolean;
  defaultTemplate?: string;
}

export interface IQuoteTemplateFormProps {
  source: 'tenant' | 'quote';
  control: Control<IQuoteTemplateConfigRes>;
  errors: FormState<IQuoteTemplateConfigRes>['errors'];
  isDisabled?: boolean;
  requireSigningPage?: boolean;
  isAmendmentV2Quote?: boolean;
}

export interface IQuoteTemplateFormWrapperProps
  extends IQuoteTemplateFormProps {
  handleSubmit?: React.FormEventHandler<HTMLFormElement>;
}
