import React from 'react';
import { Auth0Provider } from './Auth0Provider';
import { Auth0ProviderReact } from './Auth0ProviderReact';

export const Auth0Wrapper = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <Auth0ProviderReact>
      <Auth0Provider>{children}</Auth0Provider>
    </Auth0ProviderReact>
  );
};
