import { MAccordion, MStack } from '../../../../../components/Monetize';
import { IRateResSchema } from '../../../../../types';
import { CustomRateAccordionItem } from './CustomRateAccordionItem';

interface CustomRateItemsAccordionProps {
  customRates: IRateResSchema[];
}

export const CustomRateItemsAccordion = ({
  customRates,
}: CustomRateItemsAccordionProps) => {
  return (
    <MStack w="100%" mt={4} spacing={2}>
      <MAccordion allowToggle variant="default">
        {customRates.map(
          ({ id, name, accountName, accountId, createDate, currency }) => {
            return (
              <CustomRateAccordionItem
                data-testid="custom-rate-accordion-item"
                key={id}
                rateId={id}
                name={name}
                createDate={createDate}
                accountName={accountName ?? ''}
                accountId={accountId ?? ''}
                currency={currency}
              />
            );
          },
        )}
      </MAccordion>
    </MStack>
  );
};
