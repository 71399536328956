import { Accordion, AccordionItem, AccordionPanel } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../api/axios';
import { useUpdateLineItem } from '../../../../../api/refundService';
import {
  MAccordionCustomButton,
  MBox,
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MInput,
  MText,
} from '../../../../../components/Monetize';
import { logger } from '../../../../../services/logger';
import {
  IQuickBook,
  IQuickBookRefundSyncSchema,
  QuickBookRefundSyncSchema,
} from '../../../../../types/accountingTypes';

export const QuickBooksRefundSync = ({
  connection,
}: {
  connection: IQuickBook;
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<IQuickBookRefundSyncSchema>({
    resolver: zodResolver(QuickBookRefundSyncSchema),
    mode: 'onChange',
    defaultValues: {
      productId: connection?.refundLineItemId ?? '',
    },
  });

  useEffect(() => {
    reset({
      productId: connection?.refundLineItemId ?? '',
    });
  }, [reset, connection?.refundLineItemId]);

  const { mutateAsync: doUpdateLineItem, isPending: isLoading } =
    useUpdateLineItem({
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });

  const onSubmitHandler = async (data: IQuickBookRefundSyncSchema) => {
    try {
      await doUpdateLineItem({
        refundLineItemId: data.productId,
      });
    } catch (error) {
      logger.warn('Line update error', error);
    }
  };
  return (
    <MBox mb={6}>
      <Accordion allowMultiple w="100%">
        <AccordionItem
          mt={4}
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.back"
        >
          {({ isExpanded }) => (
            <>
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="Sync Refunds to Quickbooks"
              ></MAccordionCustomButton>
              <AccordionPanel>
                <MText mb={4}>
                  To sync refunds from MonetizeNow to Quickbooks, create a
                  line-item for refunds in Quickbooks and provide that Product
                  ID here.
                </MText>

                <MFormField label="Refund Placeholder Product ID">
                  <Controller
                    name="productId"
                    control={control}
                    render={({ field }) => (
                      <MInput
                        {...field}
                        isDisabled={isLoading}
                        placeholder="Product ID"
                      ></MInput>
                    )}
                  />
                </MFormField>

                <MDivider my={4}></MDivider>
                <MFlex justifyContent="end">
                  <MButton
                    isLoading={isLoading}
                    isDisabled={!isDirty}
                    onClick={handleSubmit(onSubmitHandler)}
                  >
                    Save
                  </MButton>
                </MFlex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </MBox>
  );
};
