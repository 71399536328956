import { matchPath, useLocation } from 'react-router-dom';
import {
  MBox,
  MCustomLinkTab,
  MTabList,
  MTabs,
} from '../../../components/Monetize';
import { ROUTES } from '../../../constants';

const tabRoutes = [
  { title: 'Products', route: ROUTES.PRODUCT_LIST },
  { title: 'Offerings', route: ROUTES.OFFERING_LIST },
  { title: 'Discounts', route: ROUTES.DISCOUNT_LIST },
  { title: 'Usage Types', route: ROUTES.USAGE_TYPE_LIST },
];

const CatalogTabs = () => {
  const location = useLocation();
  const defaultIndex = tabRoutes.findIndex((route) => {
    return matchPath(route.route, location.pathname);
  });

  return (
    <MBox w="100%">
      <MTabs
        data-testid="product-catalog-top-nav"
        size="sm"
        defaultIndex={defaultIndex}
      >
        <MTabList alignItems="flex-end">
          {tabRoutes.map((tabRoute, index) => (
            <MCustomLinkTab to={tabRoute.route} key={index}>
              {tabRoute.title}
            </MCustomLinkTab>
          ))}
        </MTabList>
      </MTabs>
    </MBox>
  );
};

export default CatalogTabs;
