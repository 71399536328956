import {
  IoAlertCircleSharp,
  IoCheckmarkCircleSharp,
  IoInformationCircleSharp,
} from 'react-icons/io5';
import { MText } from './';
import { MBox, MFlex, MIcon } from './chakra';

import { ToastId } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { ToastItem } from '../../types';

const getValues = (severity: string | undefined): any => {
  switch (severity) {
    case 'success':
      return {
        bgColor: 'tGreen.light',
        hoverColor: 'tGreen.light',
        accentColor: 'tGary.acBlack',
        icon: IoCheckmarkCircleSharp,
        iconColor: 'tGreen.mDarkShade',
      };
    case 'error':
      return {
        bgColor: 'tRed.cosmos',
        hoverColor: 'tRed.cosmos',
        accentColor: 'tGary.acBlack',
        icon: IoAlertCircleSharp,
        iconColor: 'tRed.base',
      };
    case 'warning':
      return {
        bgColor: 'tCreamsicle.base',
        hoverColor: 'tCreamsicle.base',
        accentColor: 'tGary.acBlack',
        icon: IoAlertCircleSharp,
        iconColor: 'tOrange.tangerine',
      };

    case 'info':
    default:
      return {
        bgColor: 'tPurple.linkWater',
        hoverColor: 'tPurple.linkWater',
        accentColor: 'tGary.acBlack',
        icon: IoInformationCircleSharp,
        iconColor: 'tIndigo.base',
      };
  }
};
const MToast = ({
  id,
  severity,
  summary,
  summaryProps,
  detail,
  detailsProps,
  onClose,
  customContent,
}: {
  id?: ToastId;
  onClose: (id?: ToastId) => void;
} & ToastItem): JSX.Element => {
  const { bgColor, hoverColor, accentColor, icon, iconColor } =
    getValues(severity);

  return (
    <MBox
      maxW={96}
      p={4}
      bgColor={bgColor}
      borderRadius="3px"
      boxShadow="0px 4px 8px rgba(24, 36, 60, 0.15)"
      transition="all ease 0.3s"
      _hover={{
        bgColor: hoverColor,
      }}
    >
      <MFlex justify="space-between" gap={4}>
        <MFlex align="center">
          <MIcon as={icon} w={6} h={6} color={iconColor} />
        </MFlex>
        <MBox w="full" overflow="auto">
          <MText
            noOfLines={1}
            fontSize="sm"
            fontWeight="bold"
            color={accentColor}
            {...summaryProps}
          >
            {summary || ''}
          </MText>
          {detail && (
            <MText fontSize="xs" color={accentColor} {...detailsProps}>
              {detail}
            </MText>
          )}
          {customContent}
        </MBox>
        <MFlex
          align="flex-start"
          justify="center"
          onClick={() => onClose(id)}
          mt={1}
        >
          <MIcon
            as={MdClose}
            w={4}
            h={4}
            color={accentColor}
            cursor="pointer"
          />
        </MFlex>
      </MFlex>
    </MBox>
  );
};

export default MToast;
