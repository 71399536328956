import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { BsClockFill } from 'react-icons/bs';
import { MdLock } from 'react-icons/md';
import { MIcon, MTooltip } from '../../../../components/Monetize';

export const SubscriptionHeaderTag = (
  isLocked: boolean,
  hasScheduledChanges: boolean,
) => {
  if (isLocked && !hasScheduledChanges) {
    return (
      <MTooltip
        mt={3}
        ml={12}
        label="This subscription is part of a contract"
        shouldWrapChildren
        placement="bottom-start"
      >
        <span>
          <MIcon
            data-testid="subscription-locked-icon"
            w={4}
            h={6}
            ml="3"
            as={MdLock}
            fontSize="xl"
            alignSelf="center"
            style={{
              cursor: 'pointer',
            }}
          />
        </span>
      </MTooltip>
    );
  }
  if (hasScheduledChanges) {
    return (
      <Tag backgroundColor="tGray.back" color="tPurple.dark" fontWeight={400}>
        <TagLeftIcon boxSize="12px" as={BsClockFill} />
        <TagLabel>Scheduled Changes</TagLabel>
      </Tag>
    );
  }
  return null;
};
