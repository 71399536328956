import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import {
  PriceUpliftConfigFormType,
  PriceUpliftConfigResType,
} from '../types/priceUpliftConfig';
import { apiDelete, apiGet, apiPost, apiPut } from './axios';
import { cpqServiceQueryKeys } from './cpqService';
import { priceUpliftServiceQueryKeys } from './queryKeysService';

export const useGetPriceUpliftConfig = (
  priceUpliftConfigurationId = '',
  options: Partial<
    UseQueryOptions<PriceUpliftConfigResType, unknown, PriceUpliftConfigResType>
  > = {},
) => {
  return useQuery({
    queryKey: [
      ...priceUpliftServiceQueryKeys.priceUplift(priceUpliftConfigurationId),
    ],
    queryFn: async () => {
      const { data } = await apiGet(
        `/api/priceUplift/${priceUpliftConfigurationId}`,
      );
      return data;
    },
    select: (data) => {
      if (isEmpty(data.productConfigurations)) {
        return data;
      }
      const [firstItem] = data.productConfigurations ?? [];
      return {
        ...data,
        percentageType: firstItem.percentageType,
        amount: null,
      };
    },
    ...options,
  });
};

export const useCreatePriceUpliftConfig = (
  options: Partial<
    UseMutationOptions<
      PriceUpliftConfigResType,
      unknown,
      {
        quoteId: string;
        quoteOfferingId: string;
        priceUpliftConfig: PriceUpliftConfigFormType;
      }
    >
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ quoteId, quoteOfferingId, priceUpliftConfig }) => {
      const { data } = await apiPost(
        `/api/quotes/${quoteId}/quoteOfferings/${quoteOfferingId}/priceUplift`,
        priceUpliftConfig,
      );
      return data;
    },

    onSuccess: (_, { quoteId }) => {
      queryClient.invalidateQueries({
        queryKey: [...cpqServiceQueryKeys.quotesById(quoteId)],
      });
    },
    ...options,
  });
};

export const useUpdatePriceUpliftConfig = (
  options: Partial<
    UseMutationOptions<
      PriceUpliftConfigResType,
      unknown,
      {
        priceUpliftConfigurationId: string;
        priceUpliftConfig: PriceUpliftConfigFormType;
      }
    >
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ priceUpliftConfigurationId, priceUpliftConfig }) => {
      const { data } = await apiPut(
        `/api/priceUplift/${priceUpliftConfigurationId}`,
        priceUpliftConfig,
      );
      return data;
    },
    onSuccess: (data, { priceUpliftConfigurationId }) => {
      if (data) {
        queryClient.invalidateQueries({
          queryKey: [
            ...priceUpliftServiceQueryKeys.priceUplift(
              priceUpliftConfigurationId,
            ),
          ],
        });
      }
    },
    ...options,
  });
};

export const useDeletePriceUpliftConfig = (
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      {
        quoteId: string;
        quoteOfferingId: string;
        priceUpliftConfigurationId?: string;
      }
    >
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ quoteId, quoteOfferingId }) => {
      const { data } = await apiDelete(
        `/api/quotes/${quoteId}/quoteOfferings/${quoteOfferingId}/priceUplift`,
      );
      return data;
    },
    onSuccess: (_, { quoteId }) => {
      queryClient.invalidateQueries({
        queryKey: [...cpqServiceQueryKeys.quotesById(quoteId)],
      });
    },
    ...options,
  });
};
