import { InputGroup, InputLeftElement } from '@chakra-ui/react';
import { toDateShort } from '@monetize/utils/core';
import { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  CurrencyCodesAll,
  getCurrencySymbol,
} from '../../constants/currencies';
import { InvoiceStatusEnumDisplay } from '../../constants/invoice';
import { getInvoiceDetailRoute } from '../../constants/routes';
import {
  InvoiceApplicationFormData,
  InvoiceApplicationFormDataRow,
  InvoiceStatusEnum,
  SelectionStateEnum,
} from '../../types';
import { roundNumberToDecimal } from '../../utils/numberUtils';
import {
  MCenter,
  MCheckbox,
  MCustomNumberInput,
  MDivider,
  MFlex,
  MFormField,
  MFormLabel,
  MText,
  MTooltip,
} from '../Monetize';
import { ItemWithLinkInNewWindow } from '../Monetize/ItemWithLinkInNewWindow';
import { MCurrency } from '../Monetize/MCurrency';
import { MEntityStatusBadge } from '../Monetize/MEntityStatusBadge';

interface TransactionApplicationTableRowProps {
  index: number;
  formState: UseFormReturn<InvoiceApplicationFormData>;
  row: InvoiceApplicationFormDataRow;
  isDisabled?: boolean;
  /**
   * Currently unapplied amount of payment or credit
   */
  availableAmount: number;
}

export const TransactionApplicationTableRow = ({
  index,
  formState,
  row,
  isDisabled,
  availableAmount,
}: TransactionApplicationTableRowProps) => {
  const { control, setValue } = formState;
  const {
    appliedAmount,
    amountAppliedFromOtherTransactables,
    amountDueWithoutApplied,
    invoice,
    checkedState,
  } = row;
  const { amount, currency, status } = invoice;
  const amountDue = amountDueWithoutApplied - appliedAmount;

  useEffect(() => {
    if (appliedAmount > 0 && amountDue === 0) {
      setValue(`rows.${index}.checkedState`, SelectionStateEnum.ALL);
    } else if (appliedAmount) {
      setValue(`rows.${index}.checkedState`, SelectionStateEnum.SOME);
    } else {
      setValue(`rows.${index}.checkedState`, SelectionStateEnum.NONE);
    }
  }, [amountDue, appliedAmount, index, setValue]);

  function handleToggleCheckAll() {
    let newAppliedAmount = 0;
    if (
      checkedState === SelectionStateEnum.ALL ||
      checkedState === SelectionStateEnum.SOME
    ) {
      newAppliedAmount = 0;
    } else {
      newAppliedAmount = roundNumberToDecimal(
        Math.min(amountDue, availableAmount),
      );
    }
    if (appliedAmount !== newAppliedAmount) {
      setValue(`rows.${index}.appliedAmount`, newAppliedAmount);
    }
  }

  return (
    <>
      <MCenter role="cell">
        <Controller
          name={`rows.${index}.checkedState`}
          control={control}
          render={({ field: { onChange, value, ...rest } }) => (
            <MCheckbox
              id={`rows.${index}.checkedState`}
              isIndeterminate={value === SelectionStateEnum.SOME}
              isChecked={value === SelectionStateEnum.ALL}
              isDisabled={isDisabled}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleToggleCheckAll();
              }}
              {...rest}
            />
          )}
        />
      </MCenter>
      <MFlex role="cell" align="center">
        <MFormLabel
          htmlFor={`rows.${index}.checkedState`}
          fontWeight="400"
          m="0"
        >
          <ItemWithLinkInNewWindow url={getInvoiceDetailRoute(invoice.id)}>
            <MText>{invoice.invoiceNumber ?? invoice.id}</MText>
          </ItemWithLinkInNewWindow>
          <MText fontSize="xs">Due {toDateShort(invoice.dueDate)}</MText>
        </MFormLabel>
      </MFlex>

      <MFlex role="cell" align="center" justify="flex-end">
        <MCurrency amount={amount} options={{ currency }} />
      </MFlex>

      <MFlex role="cell" align="center" justify="center">
        <MCurrency
          amount={roundNumberToDecimal(amountAppliedFromOtherTransactables)}
          options={{ currency }}
        />
      </MFlex>

      <MFormField role="cell" error={`rows.${index}.appliedAmount`}>
        <Controller
          name={`rows.${index}.appliedAmount`}
          control={control}
          render={({ field: { value, onChange, ...rest } }) => (
            <InputGroup>
              <InputLeftElement w="4" pl="2">
                {getCurrencySymbol(invoice.currency as CurrencyCodesAll)}
              </InputLeftElement>
              <MCustomNumberInput
                value={value ?? 0}
                inputMode="decimal"
                placeholder={`0.00`}
                precision={2}
                isDisabled={isDisabled}
                styleProps={{
                  pl: 5,
                  pr: 2,
                  textAlign: 'right',
                }}
                onChange={(valueAsString, valueAsNumber) =>
                  onChange(valueAsString)
                }
                {...rest}
              />
            </InputGroup>
          )}
        />
      </MFormField>

      <MFlex role="cell" align="center" justify="flex-end">
        <MCurrency
          amount={amountDue}
          options={{ currency, maximumFractionDigits: 2 }}
        />
      </MFlex>

      {amountDue < 0 ? (
        <MTooltip label="The applied amount cannot exceed the amount due">
          <MText
            role="cell"
            color="tRed.base"
            alignSelf="center"
            justifySelf="flex-end"
          >
            Error
          </MText>
        </MTooltip>
      ) : (
        <MEntityStatusBadge
          role="cell"
          status={
            amountDue === 0
              ? InvoiceStatusEnumDisplay[InvoiceStatusEnum.PAID]
              : InvoiceStatusEnumDisplay[InvoiceStatusEnum.UNPAID]
          }
          alignSelf="center"
          justifySelf="flex-end"
        />
      )}

      <MDivider gridColumn="1/-1" />
    </>
  );
};
