import { useCallback, useState } from 'react';
import {
  doGetQuoteStorageById,
  doPrintQuoteToPdf,
} from '../../../../api/cpqService';
import { useFlags } from '../../../../services/launchDarkly';
import { QuoteWithPdfFields } from '../../../../types';
import { base64ToArrayBuffer } from '../../../../utils';

const highlightTextPattern = /\/m[0-9a-z]+\//i;

/** Available tabs based on what exists for this quote */
function getTabs(quote: QuoteWithPdfFields) {
  const output: { label: string; value: string }[] = [
    {
      label: 'Quote',
      value: 'QUOTE',
    },
  ];
  if (quote.documentLocation) {
    output.push({
      label: 'MSA',
      value: 'MSA',
    });
  }
  if (quote.sowDocumentStorageId) {
    output.push({
      label: 'SOW',
      value: 'SOW',
    });
  }
  if (quote.signedDocumentStorageId) {
    output.push({
      label: 'Signed Quote',
      value: 'SIGNED_QUOTE',
    });
  }
  return output;
}

/** Prefer Signed Quote if exists, otherwise Quote */
function getInitialActiveTab(tabs: { label: string; value: string }[]) {
  const signedDocumentIdx = tabs.findIndex(
    ({ value }) => value === 'SIGNED_QUOTE',
  );
  return signedDocumentIdx > -1 ? signedDocumentIdx : 0;
}

/** Make API request to get a storage document and convert content to ArrayBuffer */
function getStorageContent(quoteId: string, storageId: string) {
  return doGetQuoteStorageById(quoteId, storageId, {
    attachContent: true,
  }).then((storage) => ({
    filename: storage.filename,
    content: base64ToArrayBuffer(storage.contentBase64Encoded || ''),
  }));
}

/**
 * Hook to contain logic for managing the quote PDF drawer, which can show multiple PDF documents in tabs
 */
export const useQuotePdfDrawer = (quote: QuoteWithPdfFields) => {
  const { useQuotePdfV2 } = useFlags();
  const [tabs, setTabs] = useState(() => getTabs(quote));
  const [filename, setFilename] = useState(
    () => quote.description || 'quote-pdf',
  );
  const [useHighlight, setUseHighlight] = useState(true);
  const [defaultTab, setDefaultTab] = useState(() => getInitialActiveTab(tabs));

  const fetchDocument = useCallback(
    async (tab?: string): Promise<ArrayBuffer | null> => {
      let output: ArrayBuffer | null = null;
      if (quote) {
        let newFilename = '';
        let shouldHighlight = true;
        if (!tab || tab === 'QUOTE') {
          newFilename = quote.description || 'quote-pdf';
          output = await doPrintQuoteToPdf(quote.id, useQuotePdfV2);
        } else if (tab === 'MSA' && quote.documentLocation) {
          const { content, filename: storageFilename } =
            await getStorageContent(quote.id, quote.documentLocation);
          output = content;
          newFilename = storageFilename;
        } else if (tab === 'SOW' && quote.sowDocumentStorageId) {
          const { content, filename: storageFilename } =
            await getStorageContent(quote.id, quote.sowDocumentStorageId);
          output = content;
          newFilename = storageFilename;
        } else if (tab === 'SIGNED_QUOTE' && quote.signedDocumentStorageId) {
          const { content, filename: storageFilename } =
            await getStorageContent(quote.id, quote.signedDocumentStorageId);
          output = content;
          newFilename = storageFilename;
          shouldHighlight = false;
        }
        if (newFilename) {
          setFilename(newFilename);
        }
        setUseHighlight(shouldHighlight);
      }
      return output;
    },
    [quote, useQuotePdfV2],
  );

  const resetTabs = useCallback(() => {
    const newValue = getTabs(quote);
    setTabs((priorValue) => {
      return newValue.length !== priorValue.length ? newValue : priorValue;
    });
    setDefaultTab(getInitialActiveTab(newValue));
  }, [quote]);

  return {
    tabs,
    filename,
    highlightTextPattern: useHighlight ? highlightTextPattern : undefined,
    defaultTab,
    fetchDocument,
    resetTabs,
  };
};
