import { useACL } from '../../../services/acl/acl';
import { useAuth } from '../../../services/auth0';
import { useFlags } from '../../../services/launchDarkly';
import { UserRoleEnum } from '../../../types';

export const useCanCreateQuote = () => {
  const { canDo } = useACL();
  const { quoteCreateAllowedRoles } = useFlags();
  const { currentTenantUserHasRole } = useAuth();

  const canCreateQuote =
    canDo([['sales', 'create']]) &&
    (!quoteCreateAllowedRoles?.length ||
      quoteCreateAllowedRoles[0] === 'ALL' ||
      currentTenantUserHasRole(quoteCreateAllowedRoles as UserRoleEnum[]));

  return canCreateQuote;
};
