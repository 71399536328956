import { InvoicePastDueInfoIcon, InvoicesIcon } from '@monetize/ui/icons';
import { formatCurrency, toDateShort } from '@monetize/utils/core';
import { FC, Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { MdCreditCard, MdOutlineError } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useGetAccountContacts } from '../../../api/contactsService';
import { useGetById } from '../../../api/queryUtils';
import { useGetDunningProcesses } from '../../../api/settingsService';
import {
  MBox,
  MDivider,
  MGrid,
  MLink,
  MPageHeader,
  MText,
} from '../../../components/Monetize';
import { MFlex, MIcon, MTooltip } from '../../../components/Monetize/chakra';
import { ROUTES } from '../../../constants';
import { BILL_GROUP_STATUS_DISPLAY } from '../../../constants/billGroups';
import { PAYMENT_GATEWAY_TYPE_DISPLAY } from '../../../constants/paymentGateWay';
import { getSubscriptionOverviewRoute } from '../../../constants/routes';
import { useACL } from '../../../services/acl/acl';
import {
  AccountStatusEnum,
  BILL_GROUP_INVOICE_FREQUENCY_MAP,
  BillGroupStatusEnum,
  DEFAULT_PAGER,
  IAccountRespSchema,
  IBillGroupResp,
  IBillGroupRespUI,
  IPaymentMethodResp,
  PaymentMethodTypeEnum,
} from '../../../types';
import { IOverviewItem } from '../../../types/overviewDataTypes';
import { getAddress } from '../../../utils/address';
import { addPaymentAndDunningProcessToBillGroup } from '../../../utils/billGroups';
import { BillGroupActions } from '../BillGroups/BillGroupActions';
import { BillGroupGenerateInvoice } from '../BillGroups/BillGroupGenerateInvoice';
import PastDueAmountInfo from '../BillGroups/PastDueAmountInfo';
import { usePaymentMethodsData } from '../PaymentMethods/PaymentMethodList';
import BillGroupPaymentInfoPopover, {
  BillGroupPaymentInfoPopoverItem,
} from './BillGroupInfoPopover';
import { PreviewBillGroupInvoicePdfDrawer } from './PreviewBillGroupInvoicePdfDrawer';

function getPaymentMethodDisplay(paymentMethod: IPaymentMethodResp) {
  switch (paymentMethod.paymentMethodType) {
    case PaymentMethodTypeEnum.CREDIT_CARD:
      return `${paymentMethod.creditCardType} - ${paymentMethod.lastFour}`;
    case PaymentMethodTypeEnum.DIRECT_DEBIT:
      return `Bank Account - ${paymentMethod.lastFour}`;
    default:
      // ACH Credit cannot be attached to a BillGroup, so will never hit this case
      return '';
  }
}

interface OverviewTableProps {
  account: IAccountRespSchema;
  data: IOverviewItem;
  pastDueAmount: number;
}

const SubscriptionOverviewTable: FC<OverviewTableProps> = ({
  data: { subscriptionOverviews, billgroup },
  account: { id: accountId, status },
  pastDueAmount,
}: OverviewTableProps) => {
  const { canDo } = useACL();

  const canUpdate =
    canDo([['billing', 'update']]) && status === AccountStatusEnum.ACTIVE;

  const { data: billGroupWithoutRelated } = useGetById<IBillGroupResp>(
    'billGroups',
    billgroup.id,
    {
      enabled: !!billgroup.id,
    },
  );

  const { data: contacts } = useGetAccountContacts({ accountId });
  const { data: dunningProcesses } = useGetDunningProcesses({
    config: DEFAULT_PAGER,
  });

  const { listData: paymentMethods } = usePaymentMethodsData(accountId);

  const [billGroup, setBillGroupWithRelated] = useState<IBillGroupRespUI>();

  useEffect(() => {
    if (billGroupWithoutRelated) {
      setBillGroupWithRelated(
        addPaymentAndDunningProcessToBillGroup(
          billGroupWithoutRelated,
          paymentMethods?.content,
          dunningProcesses?.content,
        ),
      );
    }
  }, [
    billGroupWithoutRelated,
    contacts,
    dunningProcesses?.content,
    paymentMethods,
  ]);

  const { invoiceInfo, paymentInfo } = useMemo(() => {
    const output: {
      invoiceInfo: BillGroupPaymentInfoPopoverItem[];
      paymentInfo: BillGroupPaymentInfoPopoverItem[];
    } = {
      invoiceInfo: [],
      paymentInfo: [],
    };

    if (billGroup) {
      if (billGroup.billingContact) {
        const address = getAddress(billGroup.billingAddress);
        output.paymentInfo.push({
          label: 'Contact',
          valueOnNewline: true,
          value: (
            <MBox>
              <MText>{billGroup.billingContact.fullName}</MText>
              <MText>{billGroup.billingContact.email}</MText>
              {address.addressLine1 && <MText>{address.addressLine1}</MText>}
              {address.addressLine2 && <MText>{address.addressLine2}</MText>}
              {address.cityStatePCCountry && (
                <MText>{address.cityStatePCCountry}</MText>
              )}
            </MBox>
          ),
        });
      }

      if (billGroup.defaultPaymentGateway) {
        output.paymentInfo.push({
          label: 'Gateway',
          value:
            PAYMENT_GATEWAY_TYPE_DISPLAY[billGroup.defaultPaymentGateway.type],
        });
      }

      if (billGroup.paymentMethod) {
        output.paymentInfo.push({
          label: 'Method',
          value: getPaymentMethodDisplay(billGroup.paymentMethod),
        });
      }

      if (billGroup.invoicingFrequency) {
        output.invoiceInfo.push({
          label: 'Invoice Frequency',
          value: BILL_GROUP_INVOICE_FREQUENCY_MAP[billGroup.invoicingFrequency](
            billGroup.invoicingFrequencyInMonths,
          ).label,
        });
      }
      output.invoiceInfo.push({
        label: 'Email Invoice',
        value: billGroup.autoEmailInvoice ? (
          <MFlex>
            <MText>{'Automatic'}</MText>
            <MTooltip
              shouldWrapChildren
              label={
                'MonetizeNow will email your customers automatically when invoices are created.'
              }
              placement="bottom-end"
            >
              <MIcon
                as={MdOutlineError}
                w={5}
                h={5}
                ml={2}
                color={'tOrange.tangerine'}
              />
            </MTooltip>
          </MFlex>
        ) : (
          'Manually'
        ),
      });

      output.invoiceInfo.push({
        label: 'Billing Cycle Day',
        value: billGroup.billDay,
        bottomDivider: true,
      });

      output.invoiceInfo.push({
        label: 'Net Terms',
        value: billGroup.netTerms.replaceAll(/[^0-9]/g, ''),
      });

      if (billGroup.dunningProcess) {
        output.invoiceInfo.push({
          label: 'Dunning Process',
          value: billGroup.dunningProcess.name,
        });
      }

      if (billGroup.ccEmails) {
        output.paymentInfo.push({
          label: 'CC Emails',
          value: billGroup.ccEmails?.map((email, idx) => (
            <MText key={idx}>{email}</MText>
          )),
          valueOnNewline: true,
        });
      }
    }
    return output;
  }, [billGroup]);

  if (!billGroup) {
    return null;
  }

  const extraSubtitleParts: ReactNode[] = [];

  if (billGroup.nextInvoiceDate) {
    extraSubtitleParts.push(
      <MText size="sm">
        Next Invoice: {toDateShort(billGroup.nextInvoiceDate)}
      </MText>,
    );
  }

  if (canUpdate && billGroup.status === BillGroupStatusEnum.ACTIVE) {
    extraSubtitleParts.push(
      <PreviewBillGroupInvoicePdfDrawer
        accountId={accountId}
        billGroupId={billGroup.id}
      />,
    );
  }

  return (
    <MBox mt={4}>
      {billGroup.nextInvoiceDate && (
        <BillGroupGenerateInvoice
          billGroupId={billGroup.id}
          nextInvoiceDate={billGroup.nextInvoiceDate}
          accountId={accountId}
          billGroupStatus={billGroup.status}
          invoiceDelay={billGroup.invoiceDelay}
          errorStatus={billGroup.errorStatus}
        />
      )}
      <MBox>
        <MPageHeader
          title={
            <MLink
              color="tPurple.dark"
              as={Link}
              to={ROUTES.getBillGroupPageRoute(billGroup.id, 'details')}
              _hover={{
                color: 'tIndigo.base',
                textDecoration: 'underline',
              }}
            >
              {billGroup?.name || 'Bill Group'}
            </MLink>
          }
          status={BILL_GROUP_STATUS_DISPLAY[billGroup?.status]}
          id={billGroup.id}
          extraSubtitleParts={extraSubtitleParts}
        >
          <PastDueAmountInfo
            currency={billgroup.currency}
            pastDueAmount={pastDueAmount}
            mr={2}
          />
          <BillGroupPaymentInfoPopover
            iconButtonProps={{ icon: MdCreditCard }}
            items={paymentInfo}
          />
          <BillGroupPaymentInfoPopover
            iconButtonProps={{
              icon: pastDueAmount > 0 ? InvoicePastDueInfoIcon : InvoicesIcon,
              btnSize: pastDueAmount > 0 ? 8 : 5,
            }}
            items={invoiceInfo}
          />

          <BillGroupActions billGroup={billGroup} source="accountOverview" />
        </MPageHeader>
      </MBox>

      <MGrid gridTemplateColumns="3fr repeat(2, 2fr)" columnGap={4} pb={2}>
        {/* overview table header  */}
        <MText fontWeight="semibold">Subscription Name</MText>
        <MText fontWeight="semibold" textAlign="right">
          Products
        </MText>
        <MText fontWeight="semibold" textAlign="right" pr={2}>
          Next Invoice Estimate
        </MText>
      </MGrid>

      {subscriptionOverviews.map((subscription) => (
        <Fragment key={subscription.id}>
          {/* overview table data  */}
          <MGrid gridTemplateColumns="3fr repeat(2, 2fr)" columnGap={4} py={2}>
            <MBox>
              <MLink
                as={Link}
                _hover={{
                  textDecoration: 'underline',
                }}
                to={`${getSubscriptionOverviewRoute(
                  accountId,
                  subscription.id,
                )}?source=accountOverview`}
              >
                <MText color="tIndigo.500" display="inline">
                  {subscription.name || '-'}
                </MText>
              </MLink>
            </MBox>
            <MText noOfLines={1} textAlign="right">
              {subscription.totalProducts || '-'}
            </MText>
            <MText noOfLines={1} textAlign="right" pr={2}>
              {formatCurrency(subscription.renewalEstimate || 0, {
                currency: billgroup.currency,
              })}
            </MText>
          </MGrid>
          <MDivider w="full" />
        </Fragment>
      ))}
    </MBox>
  );
};

export default SubscriptionOverviewTable;
