import { useEffect, useState } from 'react';
import {
  useGetQuoteSettings,
  useSaveQuoteSettings,
} from '../api/settingsService';
import { IQuoteSettingsConfigSchema } from '../types/quoteSettingsTypes';
export interface QuoteSettingDataTypes {
  fetchLoading: boolean;
  quoteSettings?: IQuoteSettingsConfigSchema;
  doSaveQuoteSettings: (payload: IQuoteSettingsConfigSchema) => Promise<void>;
  submitLoading: boolean;
}

const useQuoteSettings = (): QuoteSettingDataTypes => {
  /**
   * Since we allow the user to make multiple API requests at the same time,
   * there is a chance that a race condition might send a stale payload to the server on a subsequent request.
   * We store the latest payload that was sent to the server and provide it to all components
   * so if they make an API request, the new request includes the changes from other in-flight requests.
   * On error, we reset back to the last known good response from server.
   */
  const { data: quoteSettings, isLoading: fetchLoading } =
    useGetQuoteSettings();

  useEffect(() => {
    setLatestPayload(quoteSettings);
  }, [quoteSettings]);

  const [latestPayload, setLatestPayload] = useState<
    IQuoteSettingsConfigSchema | undefined
  >(quoteSettings);

  const { mutateAsync: doSaveQuoteSettings, isPending: submitLoading } =
    useSaveQuoteSettings();

  const saveQuoteSettings = async (payload: IQuoteSettingsConfigSchema) => {
    setLatestPayload(payload);
    await doSaveQuoteSettings(payload);
  };

  return {
    fetchLoading,
    quoteSettings: latestPayload || quoteSettings,
    doSaveQuoteSettings: saveQuoteSettings,
    submitLoading,
  };
};

export { useQuoteSettings };
