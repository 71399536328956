/* eslint-disable react/destructuring-assignment */
import { chakra, Icon } from '@chakra-ui/react';
import { formatInteger } from '@monetize/utils/core';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { ColumnBodyOptions, ColumnProps } from 'primereact/column';
import {
  DataTable,
  DataTableProps as DataTablePropsReactPrime,
  DataTableRowClickEvent,
  DataTableStateEvent,
} from 'primereact/datatable';
import {
  PaginatorCurrentPageReportOptions,
  PaginatorJumpToPageInputOptions,
  PaginatorNextPageLinkOptions,
  PaginatorPrevPageLinkOptions,
  PaginatorRowsPerPageDropdownOptions,
  PaginatorTemplate,
} from 'primereact/paginator';
import React, { useEffect } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from 'react-icons/hi';
import {
  DEFAULT_ROWS,
  Maybe,
  MDataTableErrorProps,
  MEmptyFilterResultsProps,
  MEmptyPlaceholderProps,
  TDataTablePager,
} from '../../../types';
import { MBox, MIconButton } from '../chakra';
import MEmptyDataPlaceholder from '../MEmptyDataPlaceholder';
import {
  MCustomSelect,
  MDataTableColumn,
  MInlineErrorPage,
  MPageLoader,
  MText,
} from './..';
import './_datatable.scss';
import './_datatable_responsive.scss';
import './styles.scss';

/**
 * pagination and sorting are fully managed by this component
 */

const NavIcon = chakra(MIconButton, {
  baseStyle: {
    variant: 'icon',
    fontSize: 'lg',
    color: 'tPurple.base',
    _disabled: {
      cursor: 'not-allowed',
      color: 'tGray.darkPurple',
      bg: 'none',
    },
  },
});

/**
 * If user is pressing ctrl, meta, or shift - then the click should be open in a new window
 * This is the behavior expected from clicking a normal link
 */
export const shouldOpenInNewWindow = (event: DataTableRowClickEvent) => {
  return (
    event?.originalEvent &&
    (event.originalEvent.metaKey ||
      event.originalEvent.ctrlKey ||
      event.originalEvent.shiftKey ||
      event.originalEvent.button === 1)
  );
};

export const getPaginatorTemplate = (
  page: number,
  omitAllPaginationOption = false,
): PaginatorTemplate => {
  return {
    layout:
      'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
    CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
      const { className } = options;
      const first = formatInteger(options.first);
      const last = formatInteger(options.last);
      const totalRecords = formatInteger(options.totalRecords);
      return (
        <>
          {/* This MBox forces a new row for any remaining items - https://tobiasahlin.com/blog/flexbox-break-to-new-row/ */}
          <MBox flexBasis="100%" height={0} />
          <MText className={className} color="tPurple.base">
            Showing {first}-{last} of {totalRecords} Results
          </MText>
        </>
      );
    },
    FirstPageLink: (options) => {
      const { onClick, className, disabled } = options;
      return (
        <NavIcon
          aria-label="Go to first page"
          className={className}
          onClick={onClick}
          isDisabled={disabled}
          icon={<HiChevronDoubleLeft />}
        />
      );
    },
    PrevPageLink: (options: PaginatorPrevPageLinkOptions) => {
      const { onClick, className, disabled } = options;
      return (
        <MBox display="flex" alignItems="center">
          <NavIcon
            aria-label="Go to previous page"
            className={className}
            onClick={onClick}
            isDisabled={disabled}
            icon={<HiChevronLeft />}
          />
        </MBox>
      );
    },
    NextPageLink: (options: PaginatorNextPageLinkOptions) => {
      const { onClick, className, disabled } = options;
      return (
        <NavIcon
          aria-label="Go to next page"
          className={className}
          onClick={onClick}
          isDisabled={disabled}
          icon={<HiChevronRight />}
        />
      );
    },
    LastPageLink: (options) => {
      const { onClick, className, disabled } = options;
      return (
        <NavIcon
          aria-label="Go to last page"
          className={className}
          onClick={onClick}
          isDisabled={disabled}
          icon={<HiChevronDoubleRight />}
        />
      );
    },
    JumpToPageInput: (options: PaginatorJumpToPageInputOptions) => {
      // using this Paginator component because it gets passed the pageCount (unlike some of the others), even though the name doesn't match our use for it
      const {
        props: { pageCount },
      } = options as any;
      return (
        <MBox display="flex" alignItems="center">
          <MText color="tPurple.base" fontWeight="bold">
            Page {page} of {pageCount === 0 ? 1 : pageCount}
          </MText>
        </MBox>
      );
    },
    PageLinks: (options) => {
      return (
        <>
          {options.view.startPage === options.page &&
            options.view.startPage !== 0 && (
              <span style={{ userSelect: 'none' }}>...</span>
            )}

          <button
            type="button"
            className={options.className}
            onClick={options.onClick}
          >
            {options.page + 1}
          </button>
          {options.view.endPage === options.page &&
            options.page + 1 !== options.totalPages && (
              <span style={{ userSelect: 'none' }}>...</span>
            )}
        </>
      );
    },
    RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
      const { totalRecords, options: nestedOptions, value } = options;
      const dropdownOptions = [...nestedOptions];
      if (!omitAllPaginationOption) {
        dropdownOptions.push({ label: 'All', value: totalRecords });
      }

      return (
        <MBox display="flex" alignItems="center" mr={2}>
          <MCustomSelect
            maxWidth="65px"
            items={dropdownOptions}
            itemTitle="label"
            itemValue="value"
            value={value}
            onChange={(ev: any) => {
              options.onChange({ value: ev } as any);
            }}
          />
        </MBox>
      );
    },
  };
};
const getPageProps = ({
  pager,
  setPager,
  totalRecords,
  rowsPerPageOptions,
  lazy,
  omitAllPaginationOption,
}: Partial<MDataTableProps>) => {
  let pageProps: Partial<MDataTableProps> = {};

  if (pager) {
    const internalOnPager = (event: { first: number; rows: number }) => {
      const newPager = { ...pager, ...event };
      newPager.rows = Number(newPager.rows); // rows is a string from input, ensure number
      setPager && setPager(newPager);
    };
    const internalOnSort = (event: DataTableStateEvent) => {
      const newPager = { ...pager, ...event } as TDataTablePager;
      setPager && setPager(newPager);
    };
    pageProps = {
      lazy,
      rows: Number(pager.rows),
      first: pager.first,
      totalRecords,
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 1) {
          return <Icon as={FaCaretUp} mb="-3px" ml={1} />;
        } else if (sortOrder === -1) {
          return <Icon as={FaCaretDown} mb="-3px" ml={1} />;
        }
        return null;
      },
      onPage: internalOnPager,
      onSort: internalOnSort,
      sortField: pager.sortField,
      sortOrder: pager.sortOrder,
      multiSortMeta: pager.multiSortMeta,

      paginator:
        totalRecords && (totalRecords > DEFAULT_ROWS || pager.page > 0)
          ? true
          : false,
      paginatorTemplate: getPaginatorTemplate(
        pager.page + 1,
        omitAllPaginationOption,
      ),
      rowsPerPageOptions,
    };
  } else {
    pageProps = {
      rowsPerPageOptions,
      totalRecords,
    };
  }

  return pageProps;
};

const renderColumns = (columns: ColumnProps[]) =>
  columns.map((column, index) => {
    const { body, header, field, ...rest } = column;

    const wrappedBody = (row: ColumnProps, option: ColumnBodyOptions) => (
      <>
        {typeof body === 'function' ? (
          body(row, option)
        ) : (
          <MText
            isTruncated
            fontWeight="inherit"
            noOfLines={1}
            display="block"
            as="div"
          >
            {field ? get(row, field) : ''}
          </MText>
        )}
      </>
    );

    return (
      <MDataTableColumn
        footer={null}
        key={index}
        header={header}
        field={field}
        body={wrappedBody}
        {...rest}
      />
    );
  });

export interface MDataTableProps
  extends Omit<DataTablePropsReactPrime<any>, 'onRowClick'> {
  hoverable?: boolean;
  noBackgroundOnHover?: boolean;
  rowsPerPageOptions?: number[];
  /** Used to determine if the current page is outside the bounds of the available pages */
  totalPages?: Maybe<number>;
  ref?: React.ForwardedRef<DataTable<any>>;
  loading?: boolean;
  pager?: TDataTablePager;
  columns: ColumnProps[];
  filtersApplied?: boolean;
  emptyProps?: MEmptyPlaceholderProps;
  loadingContHeight?: string;
  errorProps?: MDataTableErrorProps;
  emptyFilterResultsProps?: MEmptyFilterResultsProps;
  /** If true, the "All" option will not be shown in pagination dropdown */
  omitAllPaginationOption?: boolean;
  singleEmptyPlaceholder?: boolean;
  setPager?: (val: TDataTablePager) => void;
  resetFilter?: () => void;
  onRowClick?(event: DataTableRowClickEvent, openInNewWindow?: boolean): void;
  children?: React.ReactNode;
  onSelectionChange?(event: any): void;
}

const MDataTable = React.forwardRef<DataTable<any>, MDataTableProps>(
  (
    {
      children,
      className,
      hoverable = true,
      noBackgroundOnHover = false,
      pager,
      setPager,
      totalRecords,
      rowsPerPageOptions = [DEFAULT_ROWS, 50, 100],
      totalPages,
      columns,
      filtersApplied = false,
      resetFilter,
      loading = false,
      loadingContHeight = '75vh',
      emptyProps,
      lazy = true,
      onRowClick,
      errorProps,
      emptyFilterResultsProps,
      omitAllPaginationOption = true,
      singleEmptyPlaceholder,
      ...rest
    }: MDataTableProps,
    ref,
  ) => {
    const pageProps = getPageProps({
      pager,
      setPager,
      totalRecords,
      rowsPerPageOptions,
      lazy,
      omitAllPaginationOption,
    });

    /**
     * If the current page is outside the bounds of the returned pages, then reset the pages back to pages 0
     * This ensures that deleting a record on the last page of a table will not result in an empty table
     */
    useEffect(() => {
      if (
        pager &&
        setPager &&
        isNumber(totalPages) &&
        isNumber(pager?.page) &&
        pager.page > totalPages
      ) {
        setPager({ ...pager, page: 0, first: 0 });
      }
    }, [pager, setPager, totalPages]);

    // show inline error page
    if (errorProps?.isFetchingError && errorProps?.error) {
      return (
        <MInlineErrorPage
          error={errorProps?.error}
          reFetchData={errorProps?.onRefetchData!}
        />
      );
    }

    if (loading) {
      // Show loading indicator
      return <MPageLoader height={loadingContHeight as any} />;
    }

    // singleEmptyPlaceholder !== true : use separate logic for case where filters not applied
    if (totalRecords === 0 && !singleEmptyPlaceholder && !filtersApplied) {
      return emptyProps?.renderEmptyPlaceholder ? (
        emptyProps.renderEmptyPlaceholder()
      ) : (
        <MEmptyDataPlaceholder {...emptyProps} />
      );
    }

    // singleEmptyPlaceholder === true : use the same logic whether or not empty results are a due to applied filters or not
    if (totalRecords === 0 && (singleEmptyPlaceholder || filtersApplied)) {
      const { mainMessage, smallMessage, btnLabel, to, secondaryBtnLabel } =
        emptyFilterResultsProps || {};
      return (
        <MEmptyDataPlaceholder
          mainMessage={
            mainMessage || 'The filter you applied did not return any results.'
          }
          smallMessage={
            smallMessage === null
              ? ''
              : 'Try resetting or updating the filter if you expected to see results'
          }
          btnLabel={btnLabel}
          onClick={to ? undefined : resetFilter}
          to={to ? to : undefined}
          secondaryBtnLabel={secondaryBtnLabel}
          onClickSecondaryBtn={resetFilter}
        />
      );
    }

    return (
      <DataTable
        ref={ref}
        responsiveLayout="scroll"
        selectionMode={'single' as any}
        className={`monetize-datatable monetize-pagination ${
          hoverable ? 'p-datatable-hoverable-rows' : ''
        } ${
          noBackgroundOnHover ? 'p-datatable-hoverable-rows-no-bg' : ''
        } ${className}`}
        {...pageProps}
        /** Inject additional openInNewWindow boolean for callers to use */
        onRowClick={(event) => {
          onRowClick?.(event, shouldOpenInNewWindow(event));
        }}
        onRowPointerDown={(event) => {
          // We only register a row pointer down if the middle mouse button is pressed, otherwise we ignore the event
          if (event.originalEvent && event.originalEvent.button === 1) {
            event.originalEvent.preventDefault();
            event.originalEvent.stopPropagation();
            onRowClick?.(event, shouldOpenInNewWindow(event));
          }
        }}
        {...rest}
      >
        {renderColumns(columns)}
      </DataTable>
    );
  },
);

export default MDataTable;
