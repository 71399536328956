import { FunctionComponent as FC, Fragment, useCallback } from 'react';
import {
  MBox,
  MCheckbox,
  MDivider,
  MGrid,
  MText,
} from '../../../../components/Monetize';
import { useFlags } from '../../../../services/launchDarkly';
import { colors } from '../../../../styles/theme';
import { OfferingTypesEnum, ProductResWithMandatory } from '../../../../types';
import DraggableProductRow from './DraggableProductRow';
import HeaderCellText from './HeaderCellText';
import OfferingHeaderCell from './OfferingHeaderCell';
import ProductSearch from './ProductSearch';

export const getRowColor = (index: number) =>
  index % 2 === 0 ? 'tWhite.titanWhite' : 'tWhite.base';

interface ProductsTableProps {
  isValid: boolean;
  products: ProductResWithMandatory[];
  isEditable: boolean;
  offeringType: OfferingTypesEnum;
  isReadOnly: boolean;
  isAllProductMandatory: boolean;
  handleSelect: (product: ProductResWithMandatory) => void;
  handleRemove: (product: ProductResWithMandatory, i: number) => void;
  onMandatoryCheckboxChange: (product?: ProductResWithMandatory) => void;
  onReorder: (updatedProducts: ProductResWithMandatory[]) => void;
  onSaveReorderedProducts: () => Promise<void>;
}

const ProductsTable: FC<ProductsTableProps> = ({
  isValid,
  products,
  isEditable,
  offeringType,
  isReadOnly,
  isAllProductMandatory,
  handleSelect,
  handleRemove,
  onMandatoryCheckboxChange,
  onReorder,
  onSaveReorderedProducts,
}) => {
  const { allowOptionalProducts } = useFlags();

  const isIndeterminate =
    products.some(({ isMandatory }) => isMandatory) && !isAllProductMandatory;

  const isDisabled = !isEditable || isReadOnly;

  const moveProduct = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const updated = [...products];
      const [removed] = updated.splice(dragIndex, 1);
      updated.splice(hoverIndex, 0, removed);
      onReorder(updated);
    },
    [products],
  );

  const saveProductReorder = useCallback(() => {
    onSaveReorderedProducts();
  }, [onSaveReorderedProducts]);

  const rowColumns = allowOptionalProducts
    ? '0.1fr 0.9fr 0.9fr 2fr 0.9fr 0.1fr'
    : '0.1fr 0.9fr 0.9fr 2fr 1fr';

  return (
    <>
      <MText
        width="full"
        alignSelf="start"
        fontSize="lg"
        fontWeight="bold"
        color="tPurple.base"
        mb="3"
        ml={8}
      >
        Products
      </MText>

      <MBox w="full" data-testid="offering-form-product-list" pl={8}>
        <MBox
          border="1px solid #C8C5D4"
          borderRadius={8}
          background={colors.tWhite.base}
          py={4}
          px={6}
        >
          <MGrid
            gridTemplateColumns="120px 1fr"
            columnGap={2}
            w="full"
            alignItems="center"
          >
            <MText fontWeight="bold" fontSize="md">
              Add Products
            </MText>

            <ProductSearch
              isDisabled={isDisabled}
              onSelect={handleSelect}
              selected={products}
              offeringType={offeringType}
              mb={4}
            />
          </MGrid>

          {products.length > 0 && (
            <MBox mt={4}>
              <MGrid
                gridTemplateColumns={rowColumns}
                w="full"
                alignItems="center"
              >
                <MDivider gridColumn="1 / -1" mb={2} />

                <OfferingHeaderCell pr={4} pb={4} pt={4} />

                <OfferingHeaderCell pr={4} pb={4} pt={4}>
                  <HeaderCellText textProps={{ fontSize: 'md' }} pl={4}>
                    Products
                  </HeaderCellText>
                </OfferingHeaderCell>

                <OfferingHeaderCell pr={4} pb={4} pt={4}>
                  <HeaderCellText textProps={{ fontSize: 'md' }}>
                    Product Type
                  </HeaderCellText>
                </OfferingHeaderCell>

                <OfferingHeaderCell pr={4} pb={4} pt={4}>
                  <HeaderCellText textProps={{ fontSize: 'md' }}>
                    Description
                  </HeaderCellText>
                </OfferingHeaderCell>

                {allowOptionalProducts && (
                  <OfferingHeaderCell gridColumn="span" pb={4} pt={4}>
                    <HeaderCellText
                      textProps={{ fontSize: 'md' }}
                      tooltipLabel="Unchecked items are optional on quote."
                      additionalNode={
                        !isDisabled && (
                          <MCheckbox
                            isDisabled={isDisabled}
                            isChecked={isAllProductMandatory}
                            onChange={() => onMandatoryCheckboxChange()}
                            isIndeterminate={isIndeterminate}
                          >
                            <MBox as="span">Select All</MBox>
                          </MCheckbox>
                        )
                      }
                    >
                      Required
                    </HeaderCellText>
                  </OfferingHeaderCell>
                )}

                <MDivider gridColumn="1 / -1" />
              </MGrid>

              <MBox data-testid="offering-product-list">
                {products.map((product, index) => (
                  <Fragment key={product.id}>
                    <DraggableProductRow
                      product={product}
                      index={index}
                      isEditable={isEditable}
                      isReadOnly={isReadOnly}
                      showDragHandle={products.length > 1}
                      totalColumns={rowColumns}
                      allowOptionalProducts={allowOptionalProducts}
                      onMandatoryCheckboxChange={onMandatoryCheckboxChange}
                      isDisabled={isDisabled}
                      moveProduct={moveProduct}
                      saveProductReorder={saveProductReorder}
                      onRemove={handleRemove}
                      getRowColor={getRowColor}
                    />
                    <MDivider
                      bgColor={getRowColor(index)}
                      gridColumn="1 / -1"
                    />
                  </Fragment>
                ))}
              </MBox>
            </MBox>
          )}
        </MBox>
      </MBox>
    </>
  );
};

export default ProductsTable;
