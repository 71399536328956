import React, { FunctionComponent, PropsWithChildren } from 'react';
import { MGrid } from '../../../../components/Monetize';

const HeaderCell: FunctionComponent<any> = (
  props: PropsWithChildren<any>,
): React.ReactElement => {
  const { children, ...rest } = props;

  return (
    <MGrid alignItems="center" {...rest}>
      {children}
    </MGrid>
  );
};

export default HeaderCell;
