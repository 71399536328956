import { FunctionComponent as FC, ReactNode } from 'react';
import { useACL } from './acl';
import { ACLActionItemType } from './actions';
interface ACLCheckProps {
  acls?: ACLActionItemType[];
  children?: ReactNode;
}

export const ACLCheck: FC<ACLCheckProps> = ({
  acls,
  children,
}: ACLCheckProps) => {
  const { canDo } = useACL();
  if (acls && !canDo(acls)) {
    return null;
  }

  return children;
};
