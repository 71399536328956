import { useDisclosure } from '@chakra-ui/react';
import { toDateShort } from '@monetize/utils/core';
import React, { FunctionComponent as FC } from 'react';
import {
  QUOTE_ACCEPTED_STATUSES,
  QUOTE_SENT_STATUSES,
} from '../../../constants/quotes';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '../../../types';
import { MBox } from '../../Monetize';
import { QuoteProgressStep } from './QuoteProgressStep';
import { QuoteStepEsignRecipients } from './QuoteStepEsignRecipients';

interface QuoteStepSignProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData: () => void;
  pauseAtAccepted: boolean;
  stepMessage: {
    stepDate: string;
    stepTitle: string;
    isError: boolean;
    showLightBackground: boolean;
  } | null;
}

export const QuoteStepSign: FC<QuoteStepSignProps> = React.forwardRef<
  any,
  QuoteStepSignProps
>(({ quote, pauseAtAccepted, stepMessage }: QuoteStepSignProps, ref) => {
  const popoverState = useDisclosure();

  let stepTitle = 'Sign Deal with Customer';
  let stepDate = '';

  const isCompleted = pauseAtAccepted
    ? QUOTE_ACCEPTED_STATUSES.has(quote.status)
    : QuoteStatusEnum.PROCESSED === quote.status && !!quote.acceptedAt;

  const showInprogressStepNumber = false;
  const isCurrent = pauseAtAccepted
    ? QuoteStatusEnum.SENT === quote.status
    : QUOTE_SENT_STATUSES.has(quote.status);
  const isDisabled = !isCurrent && !QUOTE_SENT_STATUSES.has(quote.status);

  if (isCompleted) {
    stepDate = toDateShort(quote.acceptedAt);
    if (quote.autoRenewContract && quote.type === QuoteTypeEnum.RENEWAL) {
      stepTitle = 'Automatically Renewed';
    } else if (!quote.requiresEsign && pauseAtAccepted) {
      stepTitle = 'Marked as Accepted';
    } else if (!quote.requiresEsign && !pauseAtAccepted) {
      stepTitle = 'Automatically Processed';
    } else {
      stepTitle = 'Signed by Customer';
    }
  }
  if (stepMessage) {
    stepTitle = stepMessage.stepTitle;
    stepDate = stepMessage.stepDate;
  }

  return (
    <QuoteProgressStep
      key="quote-progress-sign"
      stepTitle={stepTitle}
      stepDate={stepDate}
      stepNumber={4}
      isCompleted={isCompleted}
      showInprogressStepNumber={showInprogressStepNumber}
      isDisabled={isDisabled}
      isCurrent={isCurrent}
      containerProps={{ ml: -8 }}
      isError={stepMessage?.isError}
      showLightBackground={stepMessage?.showLightBackground}
      popoverState={popoverState}
      showStepPopover={!!quote.esignEnvelopeId}
      popoverContent={
        quote.esignEnvelopeId
          ? () => (
              <MBox p="2">
                <MBox
                  maxH="350px"
                  className="custom-scroll-bar-v1"
                  overflowY="auto"
                  pr="2"
                >
                  <QuoteStepEsignRecipients
                    quoteId={quote.id}
                    contacts={quote.contacts}
                  />
                </MBox>
              </MBox>
            )
          : undefined
      }
    />
  );
});
