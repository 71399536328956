import { Accordion } from '@chakra-ui/react';
import { MVStack } from '../../../../../components/Monetize';
import { MSettingAccordionItem } from '../../../../../components/Monetize/MSettingAccordionItem';
import { IDealRoomSettingSchema } from '../../../../../types/dealroomTypes';
import { ContractSettingSection } from '../../QuoteSettings/components/ContractSettingSection';
import { SettingsToggle } from '../../QuoteSettings/components/SettingsToggle';

interface DealRoomSettingSignerProps {
  dealroomConfig: IDealRoomSettingSchema;
  onSubmit: (payload: IDealRoomSettingSchema) => Promise<void>;
  isDisabled: boolean;
}

export const DealRoomSettingSigner = ({
  dealroomConfig,
  onSubmit,
  isDisabled,
}: DealRoomSettingSignerProps) => {
  return (
    <Accordion allowMultiple w="full">
      <MSettingAccordionItem label="Signers">
        <MVStack alignItems="start" my={6} spacing={4}>
          <ContractSettingSection showDivider={false}>
            <SettingsToggle
              name="allowEndCustomerChangeSigningContact"
              heading="Allow end customers to change signing contact"
              subheading="If end customers can change the designated signer, it will automatically update the end contract before it is signed."
              fetchLoading={false}
              value={
                dealroomConfig.canEndCustomerChangeSigningContact.canChange
              }
              onChange={(val) =>
                onSubmit({
                  ...dealroomConfig,
                  canEndCustomerChangeSigningContact: {
                    canCreate:
                      dealroomConfig.canEndCustomerChangeSigningContact
                        .canCreate,
                    canChange: val,
                  },
                })
              }
              isDisabled={isDisabled}
            />
          </ContractSettingSection>
          <ContractSettingSection showDivider={false}>
            <SettingsToggle
              name="allowEndCustomerCreateNewSigningContact"
              heading="Allow end customers to create new signing contacts"
              subheading="If a new contact is created, it will be stored in your MonetizeNow Contact List."
              fetchLoading={false}
              value={
                dealroomConfig.canEndCustomerChangeSigningContact.canCreate
              }
              onChange={(val) =>
                onSubmit({
                  ...dealroomConfig,
                  canEndCustomerChangeSigningContact: {
                    canCreate: val,
                    canChange:
                      dealroomConfig.canEndCustomerChangeSigningContact
                        .canChange,
                  },
                })
              }
              isDisabled={isDisabled}
            />
          </ContractSettingSection>
        </MVStack>
      </MSettingAccordionItem>
    </Accordion>
  );
};
