import isString from 'lodash/isString';
import { object, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { BaseResponseSchema } from './miscTypes';

export enum NoteStatusEnum {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
export const NoteStatusEnumZ = z.nativeEnum(NoteStatusEnum);

// src/main/java/com/monytyz/billing/note/dto/NoteSaveDto.java. Same min and max from this file.
export const NoteReqSchema = object({
  id: string().optional(),
  status: NoteStatusEnumZ.optional(),
  subject: string().min(3).max(100).nonempty(getRequiredMessage('Title')),
  note: string().min(3).max(500).nonempty(getRequiredMessage('Comments')),
});
export type INoteReqSchema = z.infer<typeof NoteReqSchema>;

export const NoteResSchema = BaseResponseSchema.extend({
  note: string().min(3).max(500).nonempty(getRequiredMessage('Comments')),
  status: NoteStatusEnumZ.optional(),
  subject: string().min(3).max(100).nonempty(getRequiredMessage('Title')),
  locked: z.boolean().optional(),
});

export type INoteResSchema = z.infer<typeof NoteResSchema>;

/** Used to show separator between notes */
export interface NoteMilestone {
  label: string;
  date: string;
}

export function isMilestone(value: any): value is NoteMilestone {
  return isString(value.label) && isString(value.date);
}
