import { useDisclosure } from '@chakra-ui/react';
import { formatInteger } from '@monetize/utils/core';
import { useMemo } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
} from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MFormField,
  MSimpleGrid,
  MText,
} from '../../../../components/Monetize';
import { DatePicker } from '../../../../components/Monetize/DatePicker/DatePicker';
import { GRID_PROPS } from '../../../../constants/oneTimeInvoice';
import { useFlags } from '../../../../services/launchDarkly';
import { OfferingTypesEnum } from '../../../../types';
import {
  IOneTimeInvoiceFormSchemaWithOfferings,
  IOneTimeOfferingRequestSchemaUi,
} from '../../../../types/oneTimeInvoiceTypes';
import { arrayToObject } from '../../../../utils/misc';
import { OneTimeInvoiceManageProductsDrawer } from './OneTimeInvoiceManageProductsDrawer';
import { OneTimeInvoiceOfferingDropdown } from './OneTimeInvoiceOfferingDropdown';
import { OneTimeInvoiceOfferingProduct } from './OneTimeInvoiceOfferingProduct';

interface OneTimeInvoiceOfferingProps {
  control: Control<IOneTimeInvoiceFormSchemaWithOfferings>;
  index: number;
  errors: FieldErrors<IOneTimeInvoiceFormSchemaWithOfferings>;
  currency: string;
  values: IOneTimeOfferingRequestSchemaUi;
  handleRemove: (index: number) => void;
  handleUpdate: (index: number, offeringId: string) => void;
}

export const OneTimeInvoiceOffering = ({
  control,
  index,
  errors,
  currency,
  values,
  handleRemove,
  handleUpdate,
}: OneTimeInvoiceOfferingProps) => {
  const { allowOptionalProducts } = useFlags();
  const { fields: productFields, update: updateProduct } = useFieldArray({
    control,
    name: `offerings.${index}.products`,
  });

  const isOneTimeOfferingType =
    values.metadata.offering?.type === OfferingTypesEnum.ONETIME;
  const selectedOffering = values.metadata.offering;

  const productsById = useMemo(
    () => arrayToObject(selectedOffering?.products || [], 'id'),
    [],
  );

  const productsWithOptionalMandatory = useMemo(() => {
    return productFields.map((field) => ({
      ...productsById[field.productId],
      isMandatory: field.isMandatory,
      isSelected: field.isSelected,
      fieldId: field.id,
      productId: field.productId,
    }));
  }, [productFields, productsById]);

  const mandatoryProductCount = productsWithOptionalMandatory.filter(
    ({ isMandatory }) => isMandatory,
  ).length;
  const selectedProductCount = productsWithOptionalMandatory.filter(
    ({ isSelected }) => isSelected,
  ).length;
  const totalCount = productsWithOptionalMandatory.length;
  const isAllMandatory =
    mandatoryProductCount === productsWithOptionalMandatory.length;
  const shouldOpenManageDrawer =
    mandatoryProductCount === 0 && selectedProductCount === 0;

  const {
    isOpen: isManageDrawerOpen,
    onOpen: openManageDrawer,
    onClose: closeManageDrawer,
  } = useDisclosure();

  const handleSelectProduct = (productIndex: number, newMandatory: boolean) => {
    const current = productFields[productIndex];
    updateProduct(productIndex, { ...current, isSelected: newMandatory });
  };

  return (
    <>
      <MSimpleGrid {...GRID_PROPS}>
        <MBox>
          <MFormField
            error={errors?.offerings?.[index]?.offeringId}
            maxW="19rem"
          >
            <Controller
              name={`offerings.${index}.offeringId`}
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <OneTimeInvoiceOfferingDropdown
                  onChange={(value) => handleUpdate(index, value as any)}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MBox>
        <MFormField error={errors?.offerings?.[index]?.startDate} maxW="11rem">
          <Controller
            name={`offerings.${index}.startDate`}
            control={control}
            render={({ field }) => <DatePicker {...field} />}
          />
        </MFormField>
        {isOneTimeOfferingType ? (
          <MText fontStyle="italic" fontWeight="400" color="tGray.darkPurple">
            Not Applicable
          </MText>
        ) : (
          <MFormField error={errors?.offerings?.[index]?.endDate} maxW="11rem">
            <Controller
              name={`offerings.${index}.endDate`}
              control={control}
              render={({ field }) => <DatePicker {...field} />}
            />
          </MFormField>
        )}

        <MFlex gridColumn="4/-1" justify="flex-end" align="center">
          <MCustomIconButton
            variant="icon"
            btnSize={4}
            containerSize={8}
            p={3}
            icon={BsTrash}
            onClick={() => handleRemove(index)}
          />
        </MFlex>
      </MSimpleGrid>

      {selectedOffering?.description && (
        <MBox maxW="19rem" mb="2">
          <MText noOfLines={2} maxWidth="80ch">
            {selectedOffering.description}
          </MText>
        </MBox>
      )}

      {allowOptionalProducts && !isAllMandatory && (
        <MBox mb="2">
          <MButton variant="tertiary" onClick={openManageDrawer}>
            Manage Products {formatInteger(selectedProductCount)}/
            {formatInteger(totalCount)}
          </MButton>
        </MBox>
      )}

      {productsWithOptionalMandatory.map(
        (item, productIndex) =>
          item.isSelected && (
            <OneTimeInvoiceOfferingProduct
              key={item.id}
              control={control}
              errors={errors}
              offeringIndex={index}
              index={productIndex}
              selectedProduct={item}
              currency={currency}
            />
          ),
      )}

      {(isManageDrawerOpen || shouldOpenManageDrawer) && (
        <OneTimeInvoiceManageProductsDrawer
          isOpen
          onClose={closeManageDrawer}
          offeringName={selectedOffering?.name ?? 'Offering'}
          products={productsWithOptionalMandatory}
          onSelectProduct={handleSelectProduct}
        />
      )}
    </>
  );
};
