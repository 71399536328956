import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import { FC } from 'react';
import { AnrokLogo, AvalaraLogo } from '../../../../../assets/images';
import {
  MCloseButton,
  MFlex,
  MImage,
  MText,
} from '../../../../../components/Monetize';
import { TAX_PROVIDER_DISPLAY } from '../../../../../constants/taxes';
import { TaxProviderTypeEnum } from '../../../../../types/taxTypes';

export type CrmCardAction = 'RE-SYNC-CATALOG' | 'VERIFY' | 'DELETE';

interface TaxCardEditProps {
  type: TaxProviderTypeEnum;
  showCloseButton?: boolean;
  handleCancel?: () => void;
  children: React.ReactNode;
}

export const TaxCardEdit: FC<TaxCardEditProps> = ({
  type,
  showCloseButton = false,
  handleCancel = () => {},
  children,
}) => {
  const logo = type === TaxProviderTypeEnum.ANROK ? AnrokLogo : AvalaraLogo;

  return (
    <Card variant="edit" w="37.5rem">
      <CardHeader>
        <MFlex justifyContent="space-between" alignItems="center">
          <MFlex alignItems="center">
            <MImage src={logo} />
            <MText mx={2} fontSize="14px">
              {TAX_PROVIDER_DISPLAY[type]}
            </MText>
          </MFlex>
          {showCloseButton && <MCloseButton onClick={handleCancel} />}
        </MFlex>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
