import { formatInTimeZone } from 'date-fns-tz';
import { fromUnixTime } from 'date-fns/fromUnixTime';
import { IUsageEvent, IUserRole } from '../types';

export type ExportEntity =
  | 'accounts'
  | 'contacts'
  | 'billGroups'
  | 'subscriptions'
  | 'paymentMethods'
  | 'payments'
  | 'credits'
  | 'accountContracts'
  | 'invoice'
  | 'invoices'
  | 'products'
  | 'offerings'
  | 'discounts'
  | 'usageTypes'
  | 'opportunities'
  | 'quotes'
  | 'contracts'
  | 'approvalRules'
  | 'conditionalTerms'
  | 'validationRules'
  | 'usage'
  | 'users'
  | 'webhooks'
  | 'billingSchedule';

export type EntityExportConfiguration = Record<ExportEntity, EntityExportItem>;

export interface EntityExportItem {
  endpoint: string | ((...params: string[]) => string);
  useInfinityCursor?: boolean;
  fields: string[];
  /** Can be provided if any data should be transformed */
  fieldTransformFns?: Record<string, (value: any, record: any) => any>;
}

function combineFieldsWithBase(fields: string[]) {
  return [
    'id',
    ...fields,
    'createdBy',
    'createDate',
    'lastModifiedBy',
    'modifyDate',
  ];
}

export const EXPORT_CONFIGURATION: EntityExportConfiguration = {
  // ACCOUNT
  accounts: {
    endpoint: '/api/accounts',
    fields: combineFieldsWithBase([
      'accountName',
      'currency',
      'customId',
      'isTestAccount',
      'locale',
      'primaryContact.id',
      'primaryContact.fullName',
      'primaryContact.email',
      'status',
    ]),
  },
  contacts: {
    endpoint: (accountId: string) => `/api/accounts/${accountId}/contacts`,
    fields: combineFieldsWithBase([
      'fullName',
      'accountId',
      'address.city',
      'address.country',
      'address.createDate',
      'address.createdBy',
      'address.description',
      'address.id',
      'address.lastModifiedBy',
      'address.line1',
      'address.line2',
      'address.modifyDate',
      'address.postalCode',
      'address.state',
      'customId',
      'defaultESigner',
      'email',
      'esigner',
      'internal',
      'phone',
      'primary',
      'status',
      'title',
    ]),
  },
  billGroups: {
    endpoint: (accountId: string) => `/api/accounts/${accountId}/billGroups`,
    fields: combineFieldsWithBase([
      'name',
      'accountId',
      'activeContract',
      'autoEmailInvoice',
      'billDay',
      'billingContactId',
      'collectInvoiceBalanceAutomatically',
      'contractId',
      'customId',
      'defaultPaymentGateway.description',
      'defaultPaymentGateway.id',
      'discounts',
      'dunningProcessId',
      'dunningStartDate',
      'dunningStatus',
      'dunningStepId',
      'invoiceCreation',
      'invoiceDelay',
      'invoicingFailed',
      'invoicingFrequency',
      'locked',
      'netTerms',
      'nextInvoiceDate',
      'paymentMethodId',
      'previousInvoiceDate',
      'purchaseOrderNumber',
      'status',
      'subscriptionIds',
    ]),
  },
  subscriptions: {
    endpoint: (accountId: string) => `/api/accounts/${accountId}/subscriptions`,
    fields: combineFieldsWithBase([
      'offering.name',
      'offering.id',
      'addOn',
      'billGroupId',
      'billingStatus',
      'customId',
      'description',
      'discounts',
      'locked',
      'mrr',
      'nextBillDate',
      'nextUsageBillDate',
      'periodEndDate',
      'periodStartDate',
      'prorationDate',
      'provisioningStatus',
      'quoteOfferingId',
      'rateId',
      'referenceId',
      'trialEndAction',
      'trialEndDate',
      'trialStartDate',
      'usagePeriodEnd',
      'usagePeriodStart',
    ]),
  },
  paymentMethods: {
    endpoint: (accountId: string) =>
      `/api/accounts/${accountId}/paymentMethods`,
    fields: combineFieldsWithBase([
      'account.id',
      'contact.id',
      'contact.fullName',
      'paymentMethodName',
      'customId',
      'defaultPaymentMethod',
      'paymentGateway.id',
      'paymentGateway.description',
      'creditCardType',
      'creditCardNumber',
      'lastFour',
      'expirationYear',
      'expirationMonth',
      'cvcValidation',
      'addressValidation',
      'postalValidation',
      'paymentMethodType',
      'creditCardGateWayToken',
      'gatewayCustomerId',
      'description',
      'status',
      'oneTime',
      'testPaymentMethod',
      'externalPaymentMethodId',
      'bankName',
      'accountType',
      'accountNumber',
      'routingNumber',
      'swiftCode',
    ]),
  },
  payments: {
    endpoint: (accountId: string) => `/api/accounts/${accountId}/payments`,
    fields: combineFieldsWithBase([
      'amount',
      'appliedAmount',
      'description',
      'fullyApplied',
      'gatewayCode',
      'gatewayId',
      'gatewayMessage',
      'gatewayReference',
      'paymentDate',
      'paymentStatus',
      'paymentType',
      'referenceNo',
      'refundDate',
      'settledDate',
      'unAppliedAmount',
      'voidDate',
    ]),
  },
  credits: {
    endpoint: (accountId: string) => `/api/accounts/${accountId}/credits`,
    fields: [
      'id',
      'name',
      'accountId',
      'amount',
      'amountApplied',
      'amountRemaining',
      'billGroupId',
      'creditNoteId',
      'currency',
      'expirationDate',
      'prepaidInvoiceItemId',
      'reason',
      'refundable',
      'status',
      'type',
    ],
  },
  accountContracts: {
    endpoint: (accountId: string) => `/api/accounts/${accountId}/contracts`,
    fields: [
      'id',
      'accountId',
      'accountName',
      'billGroupId',
      'currency',
      'endAction',
      'startDate',
      'endDate',
      'latestQuoteId',
      'owner',
      'ownerName',
      'renewed',
      'status',
      'totalValue',
      'modifyDate',
    ],
  },
  invoice: {
    endpoint: (invoiceId: string) => `/api/invoices/${invoiceId}`,
    fields: combineFieldsWithBase([
      'invoiceNumber',
      'account.id',
      'fromCompany.name',
      'billingContact.id',
      'billGroupId',
      'invoicingDate',
      'currency',
      'locale',
      'startDate',
      'endDate',
      'orderId',
      'quoteId',
      'status',
      'testAccount',
      'dueDate',
      'amount',
      'amountWithoutTax',
      'amountWithoutDiscount',
      'discount',
      'tax',
      'exchangeRate',
      'amountDue',
      'amountPaid',
      'paidDate',
      'collectionAttempts',
      'netTerms',
      'recreatedInvoice',
      'amountWrittenOff',
      'amountCredited',
      'pastDue',
      'usageStartDate',
      'usageEndDate',
      'contractId',
      'purchaseOrderNumber',
      'invoiceTerms',
      'lastSent',
      'paymentInstruction.accountNumber',
      'paymentInstruction.bankName',
      'paymentInstruction.routingNumber',
      'paymentInstruction.swiftCode',
    ]),
  },
  invoices: {
    endpoint: '/api/invoices',
    fields: combineFieldsWithBase([
      'invoiceNumber',
      'account.id',
      'account.accountName',
      'account.customId',
      'fromCompany.name',
      'billGroupId',
      'invoicingDate',
      'currency',
      'startDate',
      'endDate',
      'quoteId',
      'status',
      'dueDate',
      'amount',
      'amountDue',
      'amountPaid',
      'paidDate',
      'collectionAttempts',
      'netTerms',
      'netTermsDays',
      'amountWrittenOff',
      'amountCredited',
      'pastDue',
      'usageStartDate',
      'usageEndDate',
      'contractId',
      'purchaseOrderNumber',
      'lastSent',
    ]),
  },
  // PRODUCT CATALOG
  discounts: {
    endpoint: '/api/discounts',
    fields: combineFieldsWithBase([
      'name',
      'description',
      'accountId',
      'autoRemove',
      'currency',
      'discountAmount',
      'discountCode',
      'discountType',
      'durationMonths',
      'durationType',
      'endDate',
      'locked',
      'maxUses',
      'quoteId',
      'remainingUses',
      'scopeOfferingId',
      'scopeProductId',
      'startDate',
      'status',
    ]),
  },
  offerings: {
    endpoint: '/api/offerings',
    fields: combineFieldsWithBase([
      'name',
      'description',
      'customId',
      'type',
      'status',
      'startDate',
      'endDate',
      'locked',
    ]),
  },
  products: {
    endpoint: '/api/products',
    fields: combineFieldsWithBase([
      'name',
      'description',
      'customId',
      'financeId',
      'locked',
      'minCommitConfig.type',
      'oneTimePrepaidCreditConfig.type',
      'productType',
      'recognitionMethod',
      'sku',
      'status',
      'usageType.id',
      'usageType.name',
    ]),
  },
  usageTypes: {
    endpoint: '/api/usageTypes',
    fields: combineFieldsWithBase([
      'name',
      'description',
      'decimalPlaces',
      'pluralName',
      'status',
      'unitName',
    ]),
  },
  // SALES
  opportunities: {
    endpoint: '/api/opportunities',
    fields: combineFieldsWithBase([
      'name',
      'amount',
      'customId',
      'primaryQuote.id',
      'primaryQuote.name',
    ]),
  },
  quotes: {
    endpoint: '/api/quotes',
    fields: [
      'id',
      'description',
      'accountName',
      'amount',
      'contractAmount',
      'currency',
      'opportunity.id',
      'opportunity.status',
      'ownerId',
      'ownerName',
      'status',
      'type',
      'modifyDate',
    ],
  },
  contracts: {
    endpoint: '/api/contracts',
    fields: [
      'id',
      'accountId',
      'accountName',
      'billGroupId',
      'currency',
      'endAction',
      'startDate',
      'endDate',
      'latestQuoteId',
      'owner',
      'ownerName',
      'renewed',
      'status',
      'totalValue',
      'modifyDate',
    ],
  },
  // SETTINGS
  approvalRules: {
    endpoint: '/rules-v3/rules',
    fields: [
      'id',
      'name',
      'description',
      'priority',
      'condition',
      'type',
      'target',
      'status',
      'action.actionType',
      'action.approval.mandatory',
      'action.approval.priority',
      'action.approval.teamId',
      'action.approval.teamName',
      'action.approval.userId',
      'action.approval.username',
    ],
  },
  conditionalTerms: {
    endpoint: '/rules-v3/rules',
    fields: [
      'id',
      'name',
      'description',
      'priority',
      'condition',
      'type',
      'target',
      'status',
      'action.actionType',
      'action.actionType',
      'action.conditionalTerms.terms',
      'action.conditionalTerms.displayOrder',
      'action.conditionalTerms.editableOnQuote',
    ],
  },
  validationRules: {
    endpoint: '/rules-v3/rules',
    fields: [
      'id',
      'name',
      'description',
      'priority',
      'condition',
      'type',
      'target',
      'status',
      'action.actionType',
      'action.validation.errorLevel',
      'action.validation.message',
    ],
  },
  usage: {
    endpoint: '/usage/events',
    useInfinityCursor: true,
    fields: [
      'id',
      'accountId',
      'subscriptionId',
      'usageTypeId',
      'unitsConsumed',
      'timestamp',
      'timestampAsDate',
      'ratedPrice',
    ],
    fieldTransformFns: {
      /** This field does not exist in data, including to make export more user friendly */
      timestampAsDate: (timestamp: number, record: IUsageEvent) => {
        return formatInTimeZone(
          fromUnixTime(Number(record.timestamp)),
          'Etc/UTC',
          `yyyy-MM-dd'T'HH:mm:ssXX`,
        );
      },
    },
  },
  users: {
    endpoint: (tenantId: string) => `/api/tenants/${tenantId}/users`,
    fields: ['id', 'name', 'email', 'mobile', 'companyName', 'status', 'roles'],
    fieldTransformFns: {
      roles: (roles: IUserRole[]) => roles.map((role) => role.name).join(', '),
    },
  },
  webhooks: {
    endpoint: '/webhooks/webhooks',
    fields: [
      'webhookId',
      'url',
      'description',
      'updatedTime',
      'createdTime',
      'enabled',
      'secret',
      'locked',
    ],
  },
  billingSchedule: {
    endpoint: (contractId: string) =>
      `/api/contracts/${contractId}/billingSchedule`,
    fields: [
      'contractId',
      'billGroupId',
      'accountId',
      'invoiceDate',
      'invoiceId',
      'status',
      'invoiceStatus',
      'servicePeriodStart',
      'servicePeriodEnd',
      'offeringName',
      'productName',
      'quantity',
      'pricePerUnit',
      'amount',
    ],
  },
};
