import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  color: 'tIndigo.base',
  _focus: {
    textDecoration: 'underline',
  },
  _active: {
    textDecoration: 'underline',
  },
});

const nodecorationStyle = defineStyle({
  color: 'tIndigo.base',
  _focus: {
    textDecoration: 'none',
  },
  _hover: {
    textDecoration: 'none',
  },
  _active: {
    textDecoration: 'none',
  },
});
export const linkTheme = defineStyleConfig({
  baseStyle,
  variants: {
    nodecoration: nodecorationStyle,
  },
});

export default linkTheme;
