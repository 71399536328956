import { FormControl } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import {
  FilterTypeContains,
  RenderTableFilterOptionProps,
} from '../../../../types';
import { MVStack } from '../../chakra';
import { DatePicker } from '../../DatePicker/DatePicker';

interface DateEqTableFilterOptionContentProps
  extends RenderTableFilterOptionProps {
  placeholder: string;
  showTimePicker?: boolean;
}

const DateTableFilter: FC<DateEqTableFilterOptionContentProps> = ({
  placeholder,
  filter,
  filterOption,
  handleFilterChange,
  showTimePicker = false,
}: DateEqTableFilterOptionContentProps) => {
  const internalFilter = filter as FilterTypeContains;

  return (
    <MVStack>
      <FormControl>
        <DatePicker
          includeTime={showTimePicker}
          placeholder={placeholder}
          onChange={(date) => {
            handleFilterChange(date ?? '', filterOption);
          }}
          value={internalFilter?.value}
          clearable
        />
      </FormControl>
    </MVStack>
  );
};

export { DateTableFilter };
