import { ListItem, UnorderedList, useDisclosure } from '@chakra-ui/react';
import { CustomRateIcon } from '@monetize/ui/icons';
import {
  MCustomIconButton,
  MFlex,
  MText,
} from '../../../../../components/Monetize';
import MPopoverWrapper from '../../../../../components/Monetize/MPopoverWrapper';

interface QuoteCustomRateNamePopoverProps {
  customRateNames: { rateName: string; offeringName: string }[];
}
const QuoteCustomRateNamePopover = ({
  customRateNames,
}: QuoteCustomRateNamePopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const customRateTooltipDisplay =
    customRateNames.length <= 0 ? null : (
      <MFlex direction="column">
        <MText fontWeight="bold" color="tPurple.dark">
          This Quote is using{' '}
          {customRateNames.length > 1 ? 'custom rates' : 'a custom rate'}
        </MText>
        <UnorderedList>
          {customRateNames.map(({ rateName, offeringName }, idx) => {
            return (
              <ListItem
                key={`rate-name-${idx}`}
                color="tPurple.dark"
                fontSize={12}
              >
                {`${offeringName} - ${rateName}`}
              </ListItem>
            );
          })}
        </UnorderedList>
      </MFlex>
    );
  return (
    <MPopoverWrapper
      showArrow
      isOpen={isOpen}
      placement="bottom-start"
      onOpen={onOpen}
      onClose={onClose}
      triggerContent={
        <MCustomIconButton
          p={4}
          minH="8"
          variant="icon"
          aria-label="customRates"
          btnSize={5}
          icon={CustomRateIcon}
          iconProps={{
            fill: 'tPurple.base',
          }}
        />
      }
      bodyContent={customRateTooltipDisplay}
    />
  );
};

export default QuoteCustomRateNamePopover;
