import { HTMLChakraProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { MdCheck, MdInfo } from 'react-icons/md';
import { MBox, MFlex, MIcon } from './chakra';

export type BannerAlertType = 'success' | 'error' | 'warning' | 'info';

const getValues = (type: BannerAlertType = 'info') => {
  switch (type) {
    case 'success':
      return {
        bgColor: 'tGreen.light',
        iconColor: 'tGreen.mDarkShade',
        icon: MdCheck,
      };
    case 'error':
      return {
        bgColor: 'tRed.cosmos',
        iconColor: 'tRed.base',
        icon: MdInfo,
      };
    case 'warning':
      return {
        bgColor: 'tCreamsicle.base',
        iconColor: 'tOrange.tangerine',
        icon: MdInfo,
      };
    case 'info':
    default:
      return {
        bgColor: 'tBlue.hover',
        iconColor: 'tIndigo.base',
        icon: MdInfo,
      };
  }
};

interface MAlertTagProps extends HTMLChakraProps<'div'> {
  type?: BannerAlertType;
  children: React.ReactNode;
}

export const MAlertTag: FC<MAlertTagProps> = ({
  type = 'warning',
  children,
  ...rest
}: MAlertTagProps) => {
  const { bgColor, iconColor, icon } = getValues(type);

  return (
    <MFlex
      alignItems="center"
      bgColor={bgColor}
      px={2}
      py={1}
      borderRadius="3"
      {...rest}
    >
      <MIcon as={icon} w={5} h={5} mr={2} color={iconColor} />
      <MBox as="span" fontSize="md" flexGrow={1}>
        {children}
      </MBox>
    </MFlex>
  );
};
