import { replaceUserTimezoneWithUtc } from '@monetize/utils/core';
import { formatISO } from 'date-fns/formatISO';
import { isValid } from 'date-fns/isValid';
import { parseISO } from 'date-fns/parseISO';
import { z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import {
  BaseResponseSchema,
  MCustomNumberTypeNullish,
  MCustomNumberTypeRequired,
} from './miscTypes';

export enum UsageTypeStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export const UsageTypeStatusEnumZ = z.nativeEnum(UsageTypeStatusEnum);
export interface IUsageType {
  id: string;
  name: string;
  pluralName: string;
  description: string;
  unitName: string;
  decimalPlaces: string | number;
  status: UsageTypeStatusEnum;
}

export const UsageTypeSchema = z.object({
  name: z.string().max(40).nonempty(getRequiredMessage('Name')),
  pluralName: z.string().max(40).nonempty(getRequiredMessage('Plural Name')),
  description: z.string().max(80).nonempty(getRequiredMessage('Description')),
  unitName: z.string().max(40).nonempty(getRequiredMessage('Unit Name')),
  decimalPlaces: MCustomNumberTypeNullish,
  status: UsageTypeStatusEnumZ,
});

export type IUsageTypeSchema = z.infer<typeof UsageTypeSchema>;

export const UsageTypeResSchema = BaseResponseSchema.extend({
  name: z.string(),
  pluralName: z.string(),
  description: z.string(),
  unitName: z.string(),
  decimalPlaces: z.number(),
  status: UsageTypeStatusEnumZ,
});

export type IUsageTypeResSchema = z.infer<typeof UsageTypeResSchema>;

export enum UsageResponseStatusEnum {
  SUCCESS = 'SUCCESS',
  PARTIAL = 'PARTIAL',
  FAILED = 'FAILED',
}
export const UsageResponseStatusEnumZ = z.nativeEnum(UsageResponseStatusEnum);

export const UsageEvent = z.object({
  id: z.string(),
  accountId: z.string(),
  subscriptionId: z.string(),
  usageTypeId: z.string(),
  unitsConsumed: MCustomNumberTypeRequired('Units Consumed'),
  timestamp: z.number(),
  ratedPrice: MCustomNumberTypeNullish,
});
export type IUsageEvent = z.infer<typeof UsageEvent>;

// POST/PUT /usage/events
export const UsageEventRequestSchema = UsageEvent.extend({
  id: z.string().max(50),
  timestamp: z.union([
    z
      .string()
      // User enters date in UTC, so we remove the running user's timezone offset
      .transform((val) => replaceUserTimezoneWithUtc(val))
      .refine((val) => isValid(val)),
    z.date().transform((val) => formatISO(val)),
  ]),
});
export type IUsageEventRequestSchema = z.infer<typeof UsageEventRequestSchema>;

// GET /usage/events
export const UsageEventListRequestSchema = z.object({
  subscriptionId: z.string(),
  usageTypeIds: z.array(z.string()),
  startTime: z
    .string()
    .refine((val) => isValid(parseISO(val)))
    // Remove user timezone offset - date user chooses is in UTC
    .transform((val) =>
      val ? formatISO(replaceUserTimezoneWithUtc(val)) : '',
    ),
  endTime: z
    .string()
    .refine((val) => isValid(parseISO(val)))
    // Remove user timezone offset - date user chooses is in UTC
    .transform((val) =>
      val ? formatISO(replaceUserTimezoneWithUtc(val)) : '',
    ),
});
export type IUsageEventListRequestSchema = z.infer<
  typeof UsageEventListRequestSchema
>;

export const UsageEventResSchema = z.object({
  status: UsageResponseStatusEnumZ,
  failedEvents: z
    .object({
      event: UsageEventRequestSchema.array(),
      reason: z.string(),
    })
    .array(),
});
export type IUsageEventResSchema = z.infer<typeof UsageEventResSchema>;

export const UsageEventUpdateReqSchema = UsageEvent.pick({
  unitsConsumed: true,
  ratedPrice: true,
});
export type IUsageEventUpdateReqSchema = z.infer<
  typeof UsageEventUpdateReqSchema
>;
