import { FunctionComponent as FC } from 'react';
import { useUpdateOpportunityQuote } from '../../api/cpqService';
import { CAN_SET_PRIMARY_QUOTE_STATUSES } from '../../constants/quotes';
import { useACL } from '../../services/acl/acl';
import {
  IOpportunityQuote,
  IOpportunityWithQuotes,
  OpportunityStatusEnum,
} from '../../types';
import DataTableActions, {
  ActionType,
} from '../Monetize/DataTable/MDataTableActions';

type OpportunityQuoteActionProps = {
  opportunityQuote: IOpportunityQuote;
  opportunity: IOpportunityWithQuotes;
  isDisabled?: boolean;
};

export const OpportunityQuoteActions: FC<OpportunityQuoteActionProps> = ({
  opportunityQuote,
  opportunity,
  isDisabled,
}: OpportunityQuoteActionProps) => {
  const setQuoteAsPrimary = useUpdateOpportunityQuote();
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);

  const isSetPrimaryQuoteActionEnabled =
    !isDisabled &&
    opportunity.primaryQuoteId !== opportunityQuote.id &&
    opportunity.status === OpportunityStatusEnum.ACTIVE &&
    CAN_SET_PRIMARY_QUOTE_STATUSES.has(opportunityQuote.status) &&
    canUpdateSales;

  const actions: ActionType[] = [
    {
      title: 'Set as primary quote',
      enabled: isSetPrimaryQuoteActionEnabled,
      action: () =>
        setQuoteAsPrimary.mutateAsync({
          opportunityId: opportunity.id,
          quoteId: opportunityQuote.id,
        }),
    },
  ];
  return <DataTableActions actions={actions} />;
};
