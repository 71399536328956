import { useEffect } from 'react';
import AppLoading from '../../components/App/AppLoading';
import { LogoutMetabase } from '../../components/Metabase/MetabaseLogout';
import { useAuth } from '../../services/auth0';
import { onHeapLogout } from '../../services/heap';
import { clearLocalStorage } from '../../store/store.utils';

/**
 * this route will clean localstorage and logout auth0
 */
const Logout = () => {
  const { logoutAuth0 } = useAuth();

  useEffect(() => {
    clearLocalStorage();
    sessionStorage.clear();
    onHeapLogout();
    logoutAuth0();
  }, []);

  return (
    <>
      <AppLoading />
      <LogoutMetabase />
    </>
  );
};

export default Logout;
