import * as z from 'zod';
import { getRequiredMessage } from '../utils/messages';

export const ProfileSchema = z.object({
  name: z.string().nonempty(getRequiredMessage('Name')),
});

export type IProfileSchema = z.infer<typeof ProfileSchema>;

export interface IProfile {
  name: string;
}
