import { AxiosError } from 'axios';
import isString from 'lodash/isString';
import { useCallback } from 'react';
import { Location, useLocation, useNavigate } from 'react-router';
import { isAxiosError } from '../../api/axios';
import {
  MBox,
  MButton,
  MHeading,
  MPageContainer,
  MText,
} from '../../components/Monetize';
import { ROUTES } from '../../constants';
import { useDashboardTab } from '../../hooks';

export const useNavigateToErrorPage = () => {
  const navigate = useNavigate();

  const navigateToErrorPage = useCallback(
    (error?: string | Error | AxiosError | any, isFatal?: boolean) => {
      let errMessage = isString(error) ? error : error?.message;
      if (isAxiosError(error) && (error.response?.data as any)?.message) {
        errMessage = (error.response?.data as any)?.message;
      }
      navigate(ROUTES.ERROR_PAGE, {
        state: { message: errMessage, isFatal },
      });
    },
    [navigate],
  );

  return navigateToErrorPage;
};

type ErrorPageProps = {
  inlineErrMessage?: string;
  reFetchData?: () => void;
};

interface ErrorPageLocation extends Location {
  state: { message?: string; isFatal?: boolean };
}

const ErrorPage = ({ inlineErrMessage, reFetchData }: ErrorPageProps) => {
  const navigate = useNavigate();
  const { state: errorState = {} } = useLocation() as ErrorPageLocation;
  const { getDashboardTabRouteByRole } = useDashboardTab();

  let errorMessage = '';
  if (inlineErrMessage) {
    errorMessage = inlineErrMessage;
  } else if (errorState?.message) {
    errorMessage = errorState.message;
  } else {
    errorMessage = 'There was a problem processing your request.';
  }

  const isFatal = Boolean(errorState?.isFatal);

  const goDashboard = () => {
    navigate(getDashboardTabRouteByRole());
  };

  return (
    <MPageContainer>
      <MBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <MHeading fontSize="2xl" mb="1">
          Error
        </MHeading>
        <MText fontSize="lg" mb={2}>
          {errorMessage}
        </MText>
        {/* If fatal, going back to dashboard will result in the error happening again */}
        {isFatal && (
          <MText fontSize="lg" mb={2}>
            Contact your administrator for assistance.
          </MText>
        )}
        {/* Allow user an action they can take to recover from the error state */}
        {!isFatal && (
          <>
            {!inlineErrMessage ? (
              <MButton onClick={goDashboard} variant="secondary" my="4">
                Return to Dashboard
              </MButton>
            ) : reFetchData !== undefined ? (
              <MButton onClick={reFetchData} variant="secondary" my="4">
                Retry
              </MButton>
            ) : null}
          </>
        )}
      </MBox>
    </MPageContainer>
  );
};

export default ErrorPage;
