import { ReactElement } from 'react';
import { MFlex, MSkeleton, MText } from '../../../../components/Monetize';

const SalesCard = ({
  title,
  value,
  actions,
  loading,
}: {
  title: string;
  value: string;
  actions: ReactElement;
  loading: boolean;
}) => (
  <MFlex flexDir="column" justifyContent="center">
    <MFlex align="center">
      <MText color="tPurple.base" fontSize="24px" mr="4" fontWeight="bold">
        {title}
      </MText>
      {actions}
    </MFlex>

    {loading ? (
      <MSkeleton height="20px" minW="100px" mt="3" />
    ) : (
      <MText color="tPurple.base" fontWeight="700" fontSize="50px">
        {value}
      </MText>
    )}
  </MFlex>
);

export { SalesCard };
