import {
  Box,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MCustomSelectProps } from '../../types/mCustomSelectTypes';
import MCustomSelect from './MCustomSelect/MCustomSelect';

type MCustomSelectPropsT = Omit<Omit<MCustomSelectProps, 'value'>, 'onChange'>;

interface MCustomTagSelectProps extends MCustomSelectPropsT {
  value: any[];
  onChange?: null | ((val: any[]) => void);
  selectionLimit?: number;
  inputWidth?: string;
  showTags?: boolean;
  // Show tags inside the input or outside of the input
  showTagsInInput?: boolean;
  handleRemove?: null | ((val: any) => void);
  handleAdd?: null | ((val: any) => void);
  // Transforms label text
  transformLabel?: null | ((title: string) => string);
}

const MCustomTagSelect: FC<MCustomTagSelectProps> = React.forwardRef<
  any,
  MCustomTagSelectProps
>(
  (
    {
      value: propValue,
      onChange,
      selectionLimit = -1,
      returnItem,
      itemTitle,
      itemValue,
      inputWidth = 'full',
      showTags,
      showTagsInInput,
      handleRemove,
      handleAdd,
      transformLabel,
      ...selectProps
    }: MCustomTagSelectProps,
    ref,
  ) => {
    const value = propValue || [];

    const onSelectValChange = (val: any) => {
      if (returnItem) {
        // check if it's already existing
        const existing = value.find(
          (item) => item[itemValue as string] === val[itemValue as string],
        );

        if (!existing) {
          const newVal = value.concat(val);
          onChange && onChange(newVal);

          handleAdd && handleAdd(val);
        } else {
          internalHandleRemove(val);
        }
      } else {
        // TODO
      }
    };

    const internalHandleRemove = (val: any) => {
      if (returnItem) {
        handleRemove && handleRemove(val);
        // check if it's already existing
        const existing = value.findIndex(
          (item) => item[itemValue as string] === val[itemValue as string],
        );
        if (existing !== -1) {
          const newVal = [...value];
          newVal.splice(existing, 1);
          onChange && onChange(newVal);
        }
      } else {
        // TODO
      }
    };

    const renderWrapContent = (dense?: boolean) => {
      return value.map((item, index) => {
        const title = () => {
          if (returnItem) {
            const labelTitle = item[itemTitle as string] as string;
            if (transformLabel) {
              return transformLabel(labelTitle);
            }
            return labelTitle;
          }
          return item;
        };

        return (
          <WrapItem key={index}>
            <Tag
              size="md"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="tGray.lightPurple"
              borderRadius="3"
              variant="solid"
              colorScheme="tGray.support"
              color="tPurple.base"
              fontWeight="bold"
              my={dense ? 1.5 : 1.5}
            >
              <TagLabel>{title()}</TagLabel>
              <TagCloseButton onClick={() => internalHandleRemove(item)} />
            </Tag>
          </WrapItem>
        );
      });
    };

    const showContentInInput = !!showTagsInInput;
    const renderContentInInput = showTagsInInput
      ? () => renderWrapContent()
      : null;

    return (
      <Box w="full" position="relative">
        <MCustomSelect
          checkboxDisplay
          returnItem={returnItem}
          itemTitle={itemTitle}
          itemValue={itemValue}
          w={inputWidth}
          {...selectProps}
          value={value}
          onChange={onSelectValChange}
          showContentInInput={showContentInInput}
          renderContentInInput={renderContentInInput}
          // disabledItems={value}
        />
        {showTags && !showTagsInInput && (
          <Wrap spacing={0.5}>{renderWrapContent(true)}</Wrap>
        )}
      </Box>
    );
  },
);

export default MCustomTagSelect;
