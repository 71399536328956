import { ToggleState } from '@monetize/types/app';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  useGetQuoteDisplayConfig,
  useGetQuoteStorageById,
  useUpdateQuoteDisplayConfig,
} from '../../../../api/cpqService';
import {
  MAccordion,
  MBox,
  MGrid,
  MGridItem,
  MPageLoader,
  MText,
} from '../../../../components/Monetize';
import { DeltaviewFailureExplanation } from '../../../../components/Quotes/DeltaviewFailureExplanation';
import { QuoteTemplateForm } from '../../../../components/Quotes/QuoteTemplates/QuoteTemplateForm';
import { useDocumentHead } from '../../../../services/documentHead';
import {
  IQuoteTemplateConfigSchema,
  QuoteAmendmentVersionEnum,
} from '../../../../types';
import { ensureBoolean } from '../../../../utils';
import { ReviewQuoteHtml } from '../../Quote/ReviewQuoteHtml';
import { QuoteConfetti } from '../../Quote/components/QuoteConfetti';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteBasic } from './QuoteBasic';
import { QuotePageCustomerBar } from './QuotePageCustomerBar';

interface QuotePageCustomerProps {
  setContactDrawerOpen: (val: boolean) => void;
  showQuoteInformation: boolean;
  showDownloadButton: boolean;
  isExternalView: boolean;
  customizePdfFormDisabled: boolean;
}
export const QuotePageCustomer = ({
  setContactDrawerOpen,
  showQuoteInformation,
  showDownloadButton,
  isExternalView,
  customizePdfFormDisabled,
}: QuotePageCustomerProps) => {
  const {
    quoteData: { quote, displayConfig },
    setReviewQuoteDisplayWidth,
  } = useQuoteContext();

  const { data: quoteDisplayConfig } = useGetQuoteDisplayConfig(quote?.id!, {
    enabled: !!quote?.id,
  });

  const reviewQuoteRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const isCelebrate = searchParams.get('celebrate');
  const { data: document } = useGetQuoteStorageById(
    {
      quoteId: quote?.id!,
      storageId: quote?.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote?.id && !!quote?.documentLocation },
  );
  const [isSettingsUpdated, setIsSettingsUpdated] = useState(false);

  const { mutate: doUpdateQuoteDisplayConfig } = useUpdateQuoteDisplayConfig({
    onSuccess: () => {
      setIsSettingsUpdated(false);
    },
    onError: (err) => {
      handleApiErrorToast(err);
      setIsSettingsUpdated(false);
    },
  });

  const [externalPdfDrawer, setExternalPdfDrawer] = useState<ToggleState>(
    ToggleState.OPEN,
  );

  useEffect(() => {
    if (isExternalView) {
      setExternalPdfDrawer(ToggleState.CLOSED);
    }
  }, [isExternalView]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IQuoteTemplateConfigSchema>({
    mode: 'onChange',
    defaultValues: {
      ...quoteDisplayConfig,
    },
  });

  const onSubmit = useCallback(
    async (requestData: IQuoteTemplateConfigSchema) => {
      doUpdateQuoteDisplayConfig({ quoteId: quote?.id!, payload: requestData });
    },
    [doUpdateQuoteDisplayConfig, quote?.id],
  );

  const allValues = watch();

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      setIsSettingsUpdated(true);
      handleSubmit(onSubmit)();
    }, 1500);

    if (!isEqual(quoteDisplayConfig, allValues)) {
      setIsSettingsUpdated(true);
      debouncedUpdate();
    }

    // Cleanup debounced function
    return () => {
      debouncedUpdate.cancel();
    };
  }, [allValues, handleSubmit, onSubmit, quoteDisplayConfig]);

  // NOTE: Leave these setDocTitle within the index.tsx and QuotePageCustomer.tsx
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle(
      `Review Quote${quote?.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description, setDocTitle]);

  useEffect(() => {
    if (reviewQuoteRef.current) {
      // Calculating review quote display width and removing padding
      setReviewQuoteDisplayWidth(reviewQuoteRef.current.clientWidth - 64);
    }
  }, [
    reviewQuoteRef,
    document?.contentBase64Encoded,
    setReviewQuoteDisplayWidth,
  ]);

  if (!quote || !displayConfig) {
    return <MPageLoader />;
  }

  const isExternalPDFDrawerOpen = externalPdfDrawer === ToggleState.OPEN;

  return (
    <>
      {showQuoteInformation && (
        <>
          <QuoteConfetti active={ensureBoolean(isCelebrate)} />
          <MAccordion
            w="100%"
            my={4}
            allowToggle
            variant="outline"
            defaultIndex={0}
          >
            <QuoteBasic
              onOpenContactDrawer={() => {
                setContactDrawerOpen(true);
              }}
            />
          </MAccordion>
        </>
      )}

      {!!quote && (
        <QuotePageCustomerBar
          quote={quote}
          pdfDrawerConfig={{
            externalPdfDrawer,
            setExternalPdfDrawer,
          }}
          showDownloadButton={showDownloadButton}
        />
      )}

      <DeltaviewFailureExplanation
        quote={quote}
        displayConfig={displayConfig}
      />

      {!!quote && !!displayConfig && (
        <MGrid templateColumns="repeat(6, 1fr)" gap={4}>
          <MGridItem colSpan={externalPdfDrawer === ToggleState.OPEN ? 4 : 6}>
            <MBox
              w="full"
              h="full"
              borderWidth={0.5}
              borderColor="tBlue.hover"
              background="tWhite.titanWhite"
              ref={reviewQuoteRef}
            >
              <ReviewQuoteHtml
                quote={quote}
                displayConfig={displayConfig}
                isSettingsUpdated={isSettingsUpdated}
              />
            </MBox>
          </MGridItem>
          {isExternalPDFDrawerOpen && (
            <MGridItem colSpan={isExternalPDFDrawerOpen ? 2 : 0}>
              <MText color="tGray.darkPurple">
                These settings only apply to the review page and the PDF file.
                Download the PDF to preview the settings.
              </MText>
              <QuoteTemplateForm
                source="quote"
                control={control}
                errors={errors}
                isDisabled={customizePdfFormDisabled}
                requireSigningPage={quote.requiresEsign}
                isAmendmentV2Quote={
                  quote?.amendmentVersion === QuoteAmendmentVersionEnum.v2
                }
              />
            </MGridItem>
          )}
        </MGrid>
      )}
    </>
  );
};
