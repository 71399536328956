import { Link } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import { useGetAccountingConnections } from '../../../../api/settingsService';
import {
  MBox,
  MDivider,
  MGrid,
  MGridItem,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MText,
} from '../../../../components/Monetize';
import { SettingCardEdit } from '../../../../components/Settings/SettingCardEdit';
import { SettingCardEmpty } from '../../../../components/Settings/SettingCardEmpty';
import { useFlags } from '../../../../services/launchDarkly';
import { SettingCardType } from '../../../../types';
import { NetSuiteConnection } from './components/NetSuiteConnection';
import { NetsuiteForm } from './components/NetsuiteForm';
import { QuickBooksConnection } from './components/QuickBooksConnection';
import { QuickBooksForm } from './components/QuickBooksForm';

type AccountingType = 'QUICKBOOKS' | 'NETSUITE';

const QuickbooksDescription = (
  <MText mb="2">
    Connect your Quickbooks to sync your accounting data from MonetizeNow.
  </MText>
);

const NetsuiteDescription = (
  <MText mb="2">
    Connect to NetSuite to sync your accounting data from MonetizeNow. For more
    guidance, reference our documentation{' '}
    <Link
      href="https://docs.monetizenow.io/docs/authentication-setup"
      target="_blank"
      isExternal
      textDecoration="underline"
    >
      here.
    </Link>
  </MText>
);

export function Accounting() {
  const { netsuite: netsuiteFeatureFlag } = useFlags();
  const { data: connections, isLoading: isLoadingConnections } =
    useGetAccountingConnections();

  const [newAccounting, setNewAccounting] = useState<AccountingType | null>(
    null,
  );

  let providers: {
    key: AccountingType;
    type: SettingCardType;
    description: ReactNode;
    onClick: () => void;
    renderForm: () => ReactNode;
  }[] = [
    {
      key: 'QUICKBOOKS',
      type: 'QuickBooks',
      description: QuickbooksDescription,
      onClick: () => setNewAccounting('QUICKBOOKS'),
      renderForm: () => (
        <QuickBooksForm
          connection={connections?.quickbooks}
          onClose={() => setNewAccounting(null)}
        />
      ),
    },
    {
      key: 'NETSUITE',
      type: 'NetSuite',
      description: NetsuiteDescription,
      onClick: () => setNewAccounting('NETSUITE'),
      renderForm: () => (
        <NetsuiteForm
          connection={connections?.quickbooks as any}
          onClose={() => setNewAccounting(null)}
        />
      ),
    },
  ];

  providers = providers.filter((provider) => {
    if (provider.key === 'QUICKBOOKS') {
      return !connections?.quickbooks;
    }
    if (provider.key === 'NETSUITE') {
      if (!netsuiteFeatureFlag) {
        return false;
      }
      return !connections?.netsuite;
    }
    return true;
  });

  const providerConfigured =
    !!connections?.quickbooks || !!connections?.netsuite;

  const newProvider = newAccounting
    ? providers.find(({ key }) => key === newAccounting)
    : null;

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Accounting" />

      {isLoadingConnections && <MPageLoader />}

      {!isLoadingConnections && (
        <MBox maxW="600px">
          {!providerConfigured && !newAccounting && (
            <MText color="tGray.darkPurple" mb="4">
              Connect to an Accounting software to manage your accounting data
              from MonetizeNow.
            </MText>
          )}

          {newProvider && (
            <>
              {newProvider.description}
              <SettingCardEdit type={newProvider.type}>
                {newProvider.renderForm()}
              </SettingCardEdit>
            </>
          )}

          {connections?.quickbooks && (
            <MBox mb={6}>
              <QuickBooksConnection
                connection={connections.quickbooks}
                description={QuickbooksDescription}
              />
            </MBox>
          )}
          {netsuiteFeatureFlag && connections?.netsuite && (
            <MBox mb={6}>
              <NetSuiteConnection
                connection={connections.netsuite}
                description={NetsuiteDescription}
              />
            </MBox>
          )}

          {providerConfigured && providers.length > 0 && (
            <>
              <MDivider my={6} />
              <MBox mt={4}>
                <MText fontWeight="600" fontSize="md">
                  Other Providers
                </MText>
                <MText>
                  In order to connect to another provider, you must disable your
                  current connection.
                </MText>
              </MBox>
            </>
          )}

          {!isLoadingConnections && !newAccounting && (
            <MGrid templateColumns="repeat(12, 1fr)" gap={10} mt={6}>
              {providers.map((provider) => (
                <MGridItem key={provider.key} colSpan={6}>
                  <SettingCardEmpty
                    isDisabled={providerConfigured}
                    type={provider.type}
                    onClick={provider.onClick}
                  />
                </MGridItem>
              ))}
            </MGrid>
          )}
        </MBox>
      )}
    </MPageContainer>
  );
}
