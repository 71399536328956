import { ENVIRONMENT } from '@monetize/ui/config';
import axios from 'axios';

export const sendResetPassword = ({ email }: { email: string }) => {
  axios.post(
    `https://${ENVIRONMENT.AUTH0_DOMAIN}/dbconnections/change_password`,
    {
      client_id: ENVIRONMENT.AUTH0_CLIENT_ID,
      email,
      connection: 'Username-Password-Authentication',
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};
