import { FunctionComponent } from 'react';
import { useSetRecoilState } from 'recoil';
import { useAuth } from '../../services/auth0';
import { switchTenantVisibleState } from '../../store/global.store';
import { MBox, MFlex, MText, MTooltip } from '../Monetize';

const AppMenuTenantSwitch: FunctionComponent<any> = ({
  isOverlay,
  user,
}: any) => {
  const { currentTenant } = useAuth();
  const toggleSwitchTenantModal = useSetRecoilState(switchTenantVisibleState);

  const onOpen = () => {
    toggleSwitchTenantModal(true);
  };

  const colorProps = currentTenant?.testTenant
    ? {
        _groupHover: {
          backgroundColor: 'tOrange.base',
          color: 'white',
        },

        color: 'tOrange.base',
        backgroundColor: 'tOrange.input',
      }
    : {
        _groupHover: {
          backgroundColor: 'tBlue.magenta',
          color: 'white',
        },

        color: 'tBlue.magenta',
        backgroundColor: 'tBlue.vLShadeMagenta',
      };
  return (
    <MTooltip
      label={currentTenant?.name || 'Switch Tenant'}
      placement="bottom-end"
    >
      <MFlex
        position="relative"
        cursor="pointer"
        alignItems="center"
        justifyContent="center"
        marginBottom={4}
        p={2}
        maxW="inherit"
        pl={isOverlay ? 0 : 4}
        pr={isOverlay ? 0 : 6}
        role="group"
        onClick={onOpen}
        data-testid="tenant-modal-btn"
      >
        {currentTenant && (
          <MFlex
            borderRadius={2}
            w="8"
            h="8"
            alignItems="center"
            justifyContent="center"
            {...colorProps}
          >
            <MText color="inherit" fontSize="10" fontWeight="bold">
              {currentTenant.testTenant ? 'Test' : 'Live'}
            </MText>
          </MFlex>
        )}

        {!isOverlay && (
          <>
            <MBox marginLeft={4} flex="1" overflow="hidden">
              <MText
                fontSize="12px"
                isTruncated
                marginBottom={0.5}
                _groupHover={{
                  color: 'tIndigo.base',
                }}
              >
                {currentTenant?.name}
              </MText>
              <MText
                fontSize="12px"
                fontWeight={700}
                isTruncated
                _groupHover={{
                  color: 'tIndigo.base',
                }}
              >
                {currentTenant?.description}
              </MText>
            </MBox>
          </>
        )}
      </MFlex>
    </MTooltip>
  );
};

export default AppMenuTenantSwitch;
