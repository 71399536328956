import { zodResolver } from '@hookform/resolvers/zod';
import { nullifyEmptyStrings } from '@monetize/utils/core';
import { AxiosHeaders } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApiErrorToast } from '../../../api/axios';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '../../../api/queryUtils';
import {
  MBox,
  MButton,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
} from '../../../components/Monetize';
import { LEGAL_ENTITIES } from '../../../constants';
import { useLegalEntityLogo } from '../../../hooks/useLegalEntityLogo';
import {
  BLANK_TEMPLATE_VALUE,
  useQuoteTemplatesList,
} from '../../../hooks/useQuoteTemplatesList';
import { useSettingsReadOnlyRule } from '../../../hooks/useSettingsReadOnlyRule';
import {
  countriesWithStates,
  statesByCountry,
} from '../../../services/countryState';
import { useDocumentHead } from '../../../services/documentHead';
import { logger } from '../../../services/logger';
import {
  ILegalEntityRequestSchema,
  ILegalEntityResponseSchema,
  LegalEntityAddressFormatEnum,
  LegalEntityRequestSchema,
} from '../../../types/legalEntityTypes';
import { dataUrlToFile } from '../../../utils/download';
import { LegalEntityLogo } from './LegalEntityLogo';
import { LegalEntityLogoDropzone } from './components/LegalEntityLogoDropzone';

export const LegalEntityForm = () => {
  const { legalEntityId } = useParams();
  const { setDocTitle } = useDocumentHead();
  const navigate = useNavigate();
  const { quoteTemplates } = useQuoteTemplatesList(true);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const { data: legalEntityData, isLoading: isLegalEntityDataLoading } =
    useGetById<ILegalEntityResponseSchema>('legalEntities', legalEntityId!, {
      enabled: !!legalEntityId,
      refetchOnWindowFocus: false,
    });

  const {
    mutateAsync: createLegalEntity,
    isPending: isCreateLegalEntityLoading,
  } = useCreateEntity<ILegalEntityResponseSchema, FormData>('legalEntities', {
    onSuccess: () => {
      navigate(-1);
    },
    onError: (err) => {
      handleApiErrorToast(err);
    },
    axiosRequestConfig: {
      headers: new AxiosHeaders({
        'Content-Type': 'multipart/form-data',
      }),
    },
  });

  const { isLoading: isLogoLoading, base64: base64String } = useLegalEntityLogo(
    { id: legalEntityId },
  );

  const {
    mutateAsync: updateLegalEntity,
    isPending: isUpdateLegalEntityLoading,
    isSuccess: isFormUpdateSuccess,
  } = useUpdateEntity<ILegalEntityResponseSchema, ILegalEntityRequestSchema>(
    'legalEntities',
  );

  const defaultValues = {
    file: base64String ? dataUrlToFile(base64String, 'logo') : undefined,
    name: legalEntityData?.name || '',
    customId: legalEntityData?.customId || '',
    email: legalEntityData?.email,
    line1: legalEntityData?.line1 || '',
    line2: legalEntityData?.line2 || '',
    city: legalEntityData?.city || '',
    state: legalEntityData?.state || '',
    postalCode: legalEntityData?.postalCode || '',
    country: legalEntityData?.country || '',
    addressFormat:
      legalEntityData?.addressFormat || LegalEntityAddressFormatEnum.US_FORMAT,
    quoteTemplate: legalEntityData?.quoteTemplate || BLANK_TEMPLATE_VALUE,
  };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    watch,
  } = useForm<ILegalEntityRequestSchema>({
    resolver: zodResolver(LegalEntityRequestSchema),
    mode: 'onSubmit',
    defaultValues,
    values: defaultValues,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const formCountry = watch('country');
  const states = formCountry ? statesByCountry[formCountry] : [];

  const onError = (errs: any, event: any) => {
    logger.warn(`ERRORS:`, errs);
  };

  const onSubmit = async ({
    file,
    ...finalData
  }: ILegalEntityRequestSchema) => {
    finalData.quoteTemplate =
      finalData.quoteTemplate === BLANK_TEMPLATE_VALUE
        ? null
        : finalData.quoteTemplate;

    if (legalEntityId) {
      updateLegalEntity({ id: legalEntityId, payload: finalData });
    } else {
      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      }
      formData.append(
        'data',
        new Blob([JSON.stringify(nullifyEmptyStrings(finalData))], {
          type: 'application/json',
        }),
      );
      createLegalEntity(formData);
    }
  };

  useEffect(() => {
    setDocTitle('Settings', `Company Settings`);
  }, []);

  const isSaving = isCreateLegalEntityLoading || isUpdateLegalEntityLoading;

  const { isReadOnly, inputVariant } = useSettingsReadOnlyRule();

  if (!!legalEntityId && (isLegalEntityDataLoading || isLogoLoading)) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer alignItems="stretch" data-testid="companySettings-form">
      <MSettingsPageHeader
        hasBackButton
        title={!legalEntityId ? 'New Entity' : 'Edit Entity'}
      >
        {!isReadOnly && (
          <MButton
            type="submit"
            variant="primary"
            isLoading={isSaving || isFileUploading}
            isDisabled={
              !isDirty ||
              isCreateLegalEntityLoading ||
              isUpdateLegalEntityLoading
            }
            onClick={handleSubmit(onSubmit, onError)}
          >
            Save
          </MButton>
        )}
      </MSettingsPageHeader>

      <MBox maxW="30.5rem">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <MGrid templateColumns="repeat(12, 1fr)" columnGap={4} rowGap={4}>
            <MGridItem colSpan={12}>
              {!legalEntityId && (
                <MFormField error={errors.file} label="Upload Logo">
                  <Controller
                    name="file"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <LegalEntityLogoDropzone
                        value={value}
                        onChange={onChange}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              )}
              {!!legalEntityId && (
                <LegalEntityLogo
                  isReadOnly={isReadOnly}
                  legalEntityId={legalEntityId}
                  resetErrorState={isFormUpdateSuccess}
                />
              )}
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField error={errors.name} label="Company Name" isRequired>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      maxLength={120}
                      value={value || ''}
                      placeholder="Enter Company Name"
                      {...rest}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MFormField error={errors.customId} label="Custom ID">
                <Controller
                  name="customId"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      value={value || ''}
                      placeholder="Enter Custom ID"
                      {...rest}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MFormField error={errors.email} label="Email">
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      value={value || ''}
                      placeholder="Enter Email"
                      {...rest}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={6}>
              <MFormField
                error={errors.addressFormat}
                label="Address Format"
                isRequired
              >
                <Controller
                  name="addressFormat"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      items={LEGAL_ENTITIES.LEGAL_ENTITY_ADDRESS_FORMATS}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={6}>
              <MFormField label="Quote Template">
                <Controller
                  name="quoteTemplate"
                  control={control}
                  render={({ field: { ...rest } }) => (
                    <MCustomSelect items={quoteTemplates} {...rest} />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MFormField error={errors.line1} label="Address Line 1">
                <Controller
                  name="line1"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      value={value || ''}
                      placeholder="Enter Address Line 1"
                      {...rest}
                      maxLength={40}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MFormField error={errors.line2} label="Address Line 2">
                <Controller
                  name="line2"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      value={value || ''}
                      placeholder="Enter Address Line 2"
                      {...rest}
                      maxLength={40}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={4}>
              <MFormField error={errors.city} label="City">
                <Controller
                  name="city"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      value={value || ''}
                      placeholder="Enter City"
                      {...rest}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={4}>
              <MFormField error={errors.postalCode} label="Postal Code">
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      value={value || ''}
                      placeholder="Enter City"
                      {...rest}
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={4}>
              <MFormField error={errors.state} label="State">
                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      showQueryInput
                      items={states}
                      itemTitle="name"
                      itemValue="isoCode"
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                      isDisabled={!formCountry}
                      clearable
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MFormField error={errors.country} label="Country">
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      showQueryInput
                      items={countriesWithStates}
                      itemTitle="name"
                      itemValue="isoCode"
                      isReadOnly={isReadOnly}
                      variant={inputVariant}
                      clearable
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
    </MPageContainer>
  );
};
