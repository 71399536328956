import { ISlackConnectionActive } from '@monetize/types/app';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  doGetSlackAuthorizeRedirectUrl,
  useGetSlackConnection,
} from '../../../../api/settingsService';
import {
  MBox,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
} from '../../../../components/Monetize';
import { SettingCardEmpty } from '../../../../components/Settings/SettingCardEmpty';
import SlackConnection from './components/SlackConnection';

const Slack = () => {
  const { data: connection, isLoading } = useGetSlackConnection({
    meta: { showErrorToast: true },
  });

  const initiateSlackAuth = async () => {
    try {
      const { url } = await doGetSlackAuthorizeRedirectUrl();
      window.location.href = url;
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Slack" />
      {isLoading && <MPageLoader />}
      {!isLoading && connection && (
        <MBox maxW="600px">
          {!connection.teamId && (
            <SettingCardEmpty
              type="Slack"
              onClick={() => initiateSlackAuth()}
            />
          )}
          {connection.teamId && (
            <SlackConnection
              connection={connection as ISlackConnectionActive}
              reAuthorize={initiateSlackAuth}
            />
          )}
        </MBox>
      )}
    </MPageContainer>
  );
};

export default Slack;
