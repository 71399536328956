import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import { DocusignIcon } from '@monetize/ui/icons';
import { useEffect, useState } from 'react';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  doDeleteConfigurationEsign,
  doGetConfigurationEsign,
} from '../../../../api/settingsService';
import {
  MBox,
  MButton,
  MCenter,
  MFlex,
  MHStack,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MText,
} from '../../../../components/Monetize';
import { ButtonCard } from '../../../../components/Monetize/Cards/ButtonCard';
import { MDataTableActions } from '../../../../components/Monetize/DataTable';
import { MEntityStatusBadge } from '../../../../components/Monetize/MEntityStatusBadge';
import { DOCUSIGN_ACCOUNT_BASE_URL_TO_ENVIRONMENT_MAPPING } from '../../../../constants/esign';
import { useDocumentHead } from '../../../../services/documentHead';
import { IEsign } from '../../../../types';
import EsignForm from './components/EsignForm';

const Esign = () => {
  const [esign, setEsign] = useState<IEsign | null>(null);
  const [formVisible, setFormVisible] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Settings', 'eSign');
  }, []);

  const loadData = async () => {
    try {
      const data = await doGetConfigurationEsign();
      setEsign(data);
    } catch (error) {
      // pass
    }
    setLoading(false);
  };

  const handleFormVisible = (val: boolean) => {
    setFormVisible(val);
  };

  const renderEsign = () => {
    if (!esign) {
      return null;
    }

    const confirmProps = {
      title: `Are you sure you want to delete this DocuSign Configuration?`,
      description:
        'Once removed, this configuration will not longer be available.',
    };

    const actions = [
      {
        title: 'Archive',
        enabled: !esign.locked,
        color: 'danger',
        confirmProps,
        action: async () => {
          try {
            await doDeleteConfigurationEsign();
            setEsign(null);
          } catch (error) {
            handleApiErrorToast(error);
          }
        },
      },
    ];

    return (
      <>
        <CardHeader>
          <MFlex justifyContent="space-between" alignItems="center">
            <MFlex>
              <MHStack>
                <MCenter w="12">
                  <DocusignIcon />
                </MCenter>
              </MHStack>

              <MFlex alignItems="center">
                <MText ml="2">
                  {esign ? 'DocuSign' : 'Connect to DocuSign'}
                </MText>
                <MEntityStatusBadge status="Active" />
              </MFlex>
            </MFlex>
            <MFlex>
              <MButton
                variant="tertiary"
                size="sm"
                onClick={() => handleFormVisible(true)}
              >
                Edit
              </MButton>
              <MDataTableActions actions={actions} />
            </MFlex>
          </MFlex>
        </CardHeader>
        <CardBody>
          <MFlex gap={8}>
            <MFlex direction="column">
              <MText fontWeight="bold" color="tPurple.dark">
                User ID
              </MText>
              <MText>{esign.userId}</MText>
            </MFlex>
            <MFlex direction="column">
              <MText fontWeight="bold" color="tPurple.dark">
                Environment
              </MText>
              <MText>
                {
                  DOCUSIGN_ACCOUNT_BASE_URL_TO_ENVIRONMENT_MAPPING[
                    esign.accountBaseURI
                  ]
                }
              </MText>
            </MFlex>
          </MFlex>
        </CardBody>
      </>
    );
  };

  const renderEmptyHolder = () => {
    return (
      <ButtonCard
        h="82px"
        titleLeftContent={<DocusignIcon />}
        title={'Docusign'}
        description={`Connect to Docusign`}
        onClick={() => handleFormVisible(true)}
        mt="4"
      />
    );
  };

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Electronic Signature (eSign)" />
      <MBox maxW="600px">
        {loading ? (
          <MPageLoader />
        ) : (
          <MBox>
            <MText color="tGray.darkPurple">
              Add eSign integrations here to allow you to send out quotes to be
              electronically signed
            </MText>

            {!esign && !formVisible && renderEmptyHolder()}
            {(esign || formVisible) && (
              <Card variant="edit" maxW="37.5rem" mt="4">
                {esign && !formVisible && renderEsign()}

                {formVisible && (
                  <EsignForm
                    esign={esign}
                    handleFormVisible={handleFormVisible}
                  />
                )}
              </Card>
            )}
          </MBox>
        )}
      </MBox>
    </MPageContainer>
  );
};

export default Esign;
