// eslint-disable-next-line no-restricted-imports
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { ENABLE_LAUNCHDARKLY, LDProviderConfig } from './services/launchDarkly';

const container = document.getElementById('root');
const root = createRoot(container!);

(async () => {
  const LDProvider = await asyncWithLDProvider(LDProviderConfig);

  const WrappedApp = ENABLE_LAUNCHDARKLY ? (
    <LDProvider>
      <App />
    </LDProvider>
  ) : (
    <App />
  );

  root.render(WrappedApp);
})();
