import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import {
  useDeleteEntity,
  usePerformEntityAction,
} from '../../../../../../api/queryUtils';
import { settingsQueryKeys } from '../../../../../../api/settingsService';
import { TAX_IDENTIFIER_DISPLAY } from '../../../../../../constants/taxes';
import {
  ITaxConnectionSchema,
  TaxProviderTypeEnum,
  TaxStatusEnum,
} from '../../../../../../types/taxTypes';
import { TaxCardEdit } from '../TaxCardEdit';
import { TaxCardAction, TaxCardView } from '../TaxCardView';
import { AnrokForm } from './AnrokForm';

interface AnrokTaxConnectionProps {
  connection: ITaxConnectionSchema;
  verifyConnection: (provider: TaxProviderTypeEnum) => Promise<void>;
}

export const AnrokTaxConnection: FC<AnrokTaxConnectionProps> = ({
  connection,
  verifyConnection,
}) => {
  const queryClient = useQueryClient();

  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const { mutateAsync: doUpdateStatus } =
    usePerformEntityAction<ITaxConnectionSchema>('tax', {
      meta: { showErrorToast: true },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: settingsQueryKeys.tax() });
      },
    });
  const { mutateAsync: doDeleteConnection } = useDeleteEntity('tax', {
    meta: { showErrorToast: true },
  });
  const isActive = connection.status === TaxStatusEnum.ACTIVE;
  const handleAction = async (action: TaxCardAction) => {
    switch (action) {
      case 'STATUS_UPDATE':
        return doUpdateStatus({
          id: TaxProviderTypeEnum.ANROK,
          action: isActive ? TaxStatusEnum.INACTIVE : TaxStatusEnum.ACTIVE,
        });
      case 'VERIFY':
        await verifyConnection(TaxProviderTypeEnum.ANROK);
        break;
      case 'DELETE':
        await doDeleteConnection({ id: TaxProviderTypeEnum.ANROK });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {mode === 'VIEW' && (
        <TaxCardView
          status={connection.status}
          actions={[
            {
              action: 'STATUS_UPDATE',
              label: isActive ? 'Disable Connection' : 'Enable Connection',
              isDanger: false,
            },
            { action: 'VERIFY', label: 'Verify Connection' },
            { action: 'DELETE', label: 'Delete', isDanger: true },
          ]}
          tags={[
            {
              label: 'Tax Calculation Field',
              value:
                TAX_IDENTIFIER_DISPLAY[connection.configurations.identifier!],
            },
            { label: 'API Key', value: connection.credentials!.apiKey! },
          ]}
          type={TaxProviderTypeEnum.ANROK}
          onEdit={() => setMode('EDIT')}
          onAction={handleAction}
        />
      )}
      {mode === 'EDIT' && (
        <TaxCardEdit type={TaxProviderTypeEnum.ANROK}>
          <AnrokForm
            connection={connection}
            handleCancel={() => setMode('VIEW')}
          />
        </TaxCardEdit>
      )}
    </>
  );
};
