import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdLock } from 'react-icons/md';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetAccountById } from '../../../api/accountsService';
import { handleApiErrorToast } from '../../../api/axios';
import { useGetById, useGetListData } from '../../../api/queryUtils';
import { upsertSubscriptionService } from '../../../api/subscriptionsService';
import {
  MButton,
  MHStack,
  MIcon,
  MLink,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MTooltip,
} from '../../../components/Monetize';
import { ROUTES } from '../../../constants';
import {
  getAccountSubscriptionsRoute,
  getBillGroupPageRoute,
  getSubscriptionOverviewRoute,
} from '../../../constants/routes';
import {
  getBlankSubscriptionOffering,
  SubscriptionBillingStatusDisplayText,
} from '../../../constants/subscriptions';
import { useCtrlEnterHotkey } from '../../../hooks/useHotkeys';
import useProductOfferings from '../../../hooks/useProductOfferings';
import { logger } from '../../../services/logger';
import Sentry from '../../../services/sentry';
import {
  BillGroupStatusEnum,
  DEFAULT_PAGER,
  IBillGroupResp,
  IGetSubscriptionSchema,
  ISubscriptionsFormReq,
  ISubscriptionsFormReqUI,
  ProductTypeEnum,
  SubscriptionBillingStatusEnum,
  SubscriptionsFormReqSchema,
  SubscriptionsFormReqSchemaUI,
} from '../../../types';
import { buildBillGroupOptions } from '../../../utils/billGroups';
import {
  convertSubscriptionsFormRespToRequest,
  convertSubscriptionsFormToAPISchema,
  getCompletedSubOfferings,
} from '../../../utils/subscriptions';
import { ReviewInvoice } from './components/ReviewInvoice';
import { SubscriptionsForm } from './components/SubscriptionsForm';

// NOTE: this page allows the creation of multiple subscriptions at once when they are all new,
// where each subscription contains just one offering (which each can have multiple products)
// When editing a saved record, however, this page handles just one subscription (hence one offering) at a time and the user cannot add new subscriptions
export const SubscriptionsPage = () => {
  const xhrController: AbortController = new AbortController();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const accountId = params.accountId || '';
  const subscriptionId = params.subscriptionId || '';

  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);
  const [isFormLoading, setFormIsLoading] = useState<boolean>(true); // loading after pricing change or save
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isInReview, setIsInReview] = useState<boolean>(false);
  const [newBillGroupId, setNewBillGroupId] = useState<string | null>(null);
  // const [billGroups, setBillGroups] = useState<any[]>([]);
  // const [disabledBillGroupIds, setDisabledBillGroupIds] = useState<string[]>(
  //   [],
  // );
  const [savedSubscriptionsForm, setSavedSubscriptionsForm] =
    useState<ISubscriptionsFormReqUI | null>(null);
  const [preparedPayload, setPreparedPayload] =
    useState<ISubscriptionsFormReq | null>(null); // prepared to POST/PUT to endpoint

  const blankSubscriptionsForm: ISubscriptionsFormReqUI = useMemo(
    () => ({
      prorate: true,
      collectNow: false,
      billGroupId: searchParams.get('billGroupId') || '',
      resetBillGroup: false,
      invoiceNow: true,
      subscriptions: [getBlankSubscriptionOffering()],
    }),
    [searchParams],
  );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors, isDirty },
  } = useForm<ISubscriptionsFormReqUI>({
    resolver: zodResolver(SubscriptionsFormReqSchemaUI),
    mode: 'onChange',
    defaultValues: blankSubscriptionsForm,
  });

  const watchBillGroup = watch('billGroupId');
  const isReadyToSaveForm =
    (isInReview && preparedPayload) || !watch('prorate');
  const { data: accountDetails } = useGetAccountById(accountId, {
    enabled: !!accountId,
    meta: { navigateToErrorPage: true },
  });

  const orderablesObj = useProductOfferings(
    accountDetails?.defaultCurrency,
    accountId,
    undefined, // eventually we'll pass the subscription effective date here
    false, // do not limit results to only quotable rates
  );

  const { data: subscription, isFetching: isLoadingSubscription } =
    useGetById<IGetSubscriptionSchema>('accountSubscriptions', subscriptionId, {
      enabled: !!subscriptionId,
      refetchOnWindowFocus: false,
      // Refer to -> https://monetizenow.atlassian.net/browse/BP-6339
      select: (data) => {
        return {
          ...data,
          subscriptionItems: data.subscriptionItems.filter(
            ({ product }) =>
              product.productType !== ProductTypeEnum.ONETIME &&
              product.productType !== ProductTypeEnum.ONETIME_PREPAID_CREDIT,
          ),
        };
      },
    });

  useEffect(() => {
    if (subscription) {
      setIsLocked(subscription.locked);
      const savedSubscriptionsData = convertSubscriptionsFormRespToRequest(
        subscription,
        blankSubscriptionsForm,
      );
      setSavedSubscriptionsForm(savedSubscriptionsData);
      reset(savedSubscriptionsData);
    }
  }, [subscription]);

  const { isLoading: billGroupsLoading, data: billGroupData } = useGetListData<
    IBillGroupResp,
    ReturnType<typeof buildBillGroupOptions>
  >(
    'billGroups',
    {
      config: { ...DEFAULT_PAGER, rows: 50 },
    },
    {
      enabled: !!accountId,
      endpointArgs: { accountId },
      meta: { showErrorToast: true },
      select: (billGroupList) =>
        buildBillGroupOptions(
          billGroupList.content?.filter(
            (bg) => bg.status === BillGroupStatusEnum.ACTIVE,
          ),
        ),
    },
  );

  const {
    billGroups = [],
    noContractBillGroupIds: enabledBillGroupIds,
    existingContractBillGroupIds: disabledBillGroupIds = [],
  } = billGroupData || {};

  const onNewBillGroup = (billGroupId?: string) => {
    if (billGroupId) {
      setNewBillGroupId(billGroupId);
    }
  };

  useEffect(() => {
    if (!subscriptionId && enabledBillGroupIds) {
      if (enabledBillGroupIds.length === 1) {
        setValue('billGroupId', enabledBillGroupIds[0]);
      } else if (newBillGroupId) {
        setValue('billGroupId', newBillGroupId); // if user created a bill group that's not selectable, this will have no effect
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billGroupData, newBillGroupId]);

  const handleEdit = () => {
    setIsInReview(false);
  };

  const handleReview = () => {
    setIsInReview(true);
    setPreparedPayload(convertSubscriptionsFormToAPISchema(getValues()));
  };

  const onSubmit = async () => {
    let subscriptionAPISchema: ISubscriptionsFormReq =
      convertSubscriptionsFormToAPISchema(getValues());
    const parsed = SubscriptionsFormReqSchema.safeParse(subscriptionAPISchema);
    if (parsed.success) {
      subscriptionAPISchema = parsed.data;
    } else {
      Sentry.captureException(parsed.error.issues, {
        tags: {
          type: 'SUBSCRIPTION_FORM',
        },
      });
    }
    // we submit even if it fails parsing since it may still be accepted by API
    try {
      setIsFormSaving(true);
      subscriptionAPISchema.preview = false;
      await upsertSubscriptionService(
        xhrController.signal,
        subscriptionAPISchema,
      );
      navigate(ROUTES.getAccountSubscriptionsRoute(accountId));
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setIsFormSaving(false);
    }
  };

  const onError = (err: any) => logger.error(err);

  const title = () => {
    if (isInReview) {
      return 'Review Invoice';
    }
    if (isLocked) {
      return 'Subscription';
    }
    return subscriptionId ? 'Edit Subscription' : 'New Subscription';
  };

  const submitForm = isReadyToSaveForm
    ? handleSubmit(onSubmit, onError)
    : handleReview;

  useCtrlEnterHotkey(() => submitForm(), [submitForm]);

  const handleSubscriptionBackBtnLink = () => {
    const billGroupId = searchParams.get('billGroupId');

    if (billGroupId) {
      return getBillGroupPageRoute(billGroupId, 'subscriptions');
    } else if (isInReview) {
      return '';
    } else if (!isInReview && isLocked) {
      return getSubscriptionOverviewRoute(accountId, subscriptionId);
    }
    return getAccountSubscriptionsRoute(accountId);
  };

  const firstSubscriptionOffering = savedSubscriptionsForm?.subscriptions[0];

  const firstOrderable =
    firstSubscriptionOffering && firstSubscriptionOffering.offeringId
      ? orderablesObj.productOfferingsObj!.byId[
          firstSubscriptionOffering.offeringId
        ]
      : null;

  if (isLoadingSubscription || !accountDetails) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer height="auto">
      <form data-testid="subscription-form" style={{ width: '100%' }}>
        <MPageHeader
          hasBackButton
          parentLink={getSubscriptionOverviewRoute(accountId, subscriptionId)}
          parentLinkTitle={firstOrderable?.name}
          backButtonLink={handleSubscriptionBackBtnLink()}
          backButtonCallback={isInReview ? handleEdit : undefined}
          title={title()}
          id={subscriptionId}
          status={
            firstSubscriptionOffering?.billingStatus
              ? SubscriptionBillingStatusDisplayText[
                  firstSubscriptionOffering.billingStatus
                ]
              : undefined
          }
          tag={
            isLocked && (
              <MTooltip
                mt={3}
                ml={12}
                label="This subscription is part of a contract"
                shouldWrapChildren
                placement="bottom-start"
              >
                <MIcon
                  w={4}
                  ml="3"
                  pt="4px"
                  as={MdLock}
                  fontSize="xl"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </MTooltip>
            )
          }
        >
          <MHStack spacing={6}>
            {isInReview && (
              <MLink
                color="tIndigo.base"
                textDecor="underline"
                fontWeight="bold"
                fontSize="sm"
                onClick={handleEdit}
              >
                Edit Subscription
              </MLink>
            )}

            <MButton
              variant="primary"
              aria-label="review-or-save"
              onClick={submitForm}
              isLoading={isFormLoading || isFormSaving}
              isDisabled={
                savedSubscriptionsForm?.subscriptions[0].billingStatus ===
                  SubscriptionBillingStatusEnum.CANCELED ||
                Object.keys(errors).length !== 0 ||
                !isDirty ||
                !watchBillGroup ||
                getCompletedSubOfferings(getValues()).length === 0 ||
                isLocked ||
                isFormLoading ||
                isFormSaving
              }
            >
              {isReadyToSaveForm ? 'Save' : 'Review'}
            </MButton>
          </MHStack>
        </MPageHeader>

        <SubscriptionsForm
          accountId={accountId}
          subscriptionId={subscriptionId}
          billGroups={billGroups}
          disabledBillGroupIds={disabledBillGroupIds}
          watchBillGroup={watchBillGroup}
          control={control}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          isLocked={isLocked}
          billGroupsLoading={billGroupsLoading}
          setFormIsLoading={setFormIsLoading}
          savedSubscriptionsForm={savedSubscriptionsForm}
          onNewBillGroup={onNewBillGroup}
          orderablesObj={orderablesObj}
          hide={isInReview}
          currency={accountDetails.defaultCurrency}
        />
      </form>
      {isInReview && preparedPayload && (
        <ReviewInvoice formValues={preparedPayload} isInReview={isInReview} />
      )}
    </MPageContainer>
  );
};
