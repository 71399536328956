import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useConfigurationSalesApprovalMessage } from '../../../api/settingsService';
import {
  MBox,
  MButton,
  MCenterModal,
  MFormField,
  MStack,
  MText,
  MTextarea,
} from '../../../components/Monetize';
import { QuoteDataTypes } from '../../../hooks/useQuote';
import {
  IQuoteReviewReq,
  IQuoteReviewSubmitSchema,
  QuoteReviewSubmitSchema,
} from '../../../types';
import { IQuoteApprovalMessageSchema } from '../../../types/quoteSettingsTypes';

export interface QuoteModalDataTypes {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleReviewOrOpenModal: () => void;
  approvalMessage?: IQuoteApprovalMessageSchema;
  approvalMessageLoading: boolean;
}
export const useQuoteReviewModalData = ({
  handleReview,
  quoteFirstApprovalUser,
}: {
  handleReview: () => void;
  quoteFirstApprovalUser: string;
}) => {
  const { isLoading: approvalMessageLoading, data: approvalMessage } =
    useConfigurationSalesApprovalMessage();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleReviewOrOpenModal = () => {
    if (!approvalMessage) {
      return;
    }

    if (quoteFirstApprovalUser) {
      // If there is first quote approval, and approval setting is enabled, then show the review modal
      onOpen();
    } else {
      // else just review as usual
      handleReview();
    }
  };

  return {
    isOpen,
    onOpen,
    onClose,
    handleReviewOrOpenModal,
    approvalMessage,
    approvalMessageLoading,
  };
};

interface QuoteReviewModalProps {
  quoteData: QuoteDataTypes;
  reviewQuoteModalData: QuoteModalDataTypes;
  handleReview: (extraData?: IQuoteReviewReq) => void;
}

const QuoteReviewModal = ({
  quoteData,
  reviewQuoteModalData,
  handleReview,
}: QuoteReviewModalProps) => {
  const [saving, setSaving] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm<IQuoteReviewSubmitSchema>({
    resolver: zodResolver(QuoteReviewSubmitSchema),
    mode: 'onChange',
    defaultValues: {
      notes: '',
    },
  });

  const onSubmit = async (data: IQuoteReviewSubmitSchema) => {
    setSaving(true);
    try {
      await handleReview(data.notes ? { note: data.notes } : undefined);
      reviewQuoteModalData.onClose();
    } catch (err: any) {
      // already handled within handleReview
    }
    setSaving(false);
  };
  const { approvalMessage } = reviewQuoteModalData;

  return (
    <MCenterModal
      showCloseButton={false}
      size="md"
      isOpen={reviewQuoteModalData.isOpen}
      onClose={reviewQuoteModalData.onClose}
      modalHeaderProps={{ mr: '12' }}
      modalTitle="Send quote for approval"
      modalBodyProps={{ pt: 0 }}
      modalContentProps={{ 'data-testid': 'quote-review-modal' } as any}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
        >
          <MButton
            onClick={reviewQuoteModalData.onClose}
            variant="cancel"
            minW="auto"
          >
            Cancel
          </MButton>
          <MButton
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty || !isValid || saving}
            isLoading={saving}
            type="submit"
            minW="auto"
          >
            {approvalMessage?.status ? 'Save' : 'Send'}
          </MButton>
        </MStack>
      )}
    >
      <MText>
        An email will be sent to {quoteData.quoteFirstApprovalUser} for an
        approval. We will notify you once the quote has been approved.
      </MText>
      <MText mb="8" fontStyle="italic" color="tGray.darkPurple">
        (This will not send the quote to the customer.)
      </MText>
      <MBox>
        {approvalMessage?.status && (
          <MBox
            ml="5"
            mb="8"
            className="rich-text"
            dangerouslySetInnerHTML={{
              __html: reviewQuoteModalData.approvalMessage?.approvalNote || '',
            }}
          />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <MFormField error={errors.notes} label="Approval Notes" isRequired>
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MTextarea placeholder="Approval notes" {...field} />
              )}
            />
          </MFormField>
        </form>
      </MBox>
    </MCenterModal>
  );
};

export default QuoteReviewModal;
