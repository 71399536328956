import { useDisclosure } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MdChevronRight, MdExpandMore } from 'react-icons/md';
import {
  MAccordion,
  MAccordionButton,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MAccordionItem,
  MAccordionPanel,
  MAlert,
  MBox,
  MButton,
  MDivider,
  MFlex,
  MGrid,
  MIcon,
  MSkeleton,
  MTag,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import { RATE_BILLING_FREQUENCY_MAP } from '../../../../../constants/offerings';
import { useOfferingRate } from '../../../../../hooks';
import { useCurrencies } from '../../../../../hooks/useCurrencies';
import { useFlags } from '../../../../../services/launchDarkly';
import { logger } from '../../../../../services/logger';
import Sentry from '../../../../../services/sentry';
import {
  AdditionalFrequency,
  IOfferingRes,
  IProduct,
  IRateReqSchema,
  IRateReqSchemaUI,
  IRateResSchema,
  OfferingTypesEnum,
  PercentOfTotalConfigTypeEnum,
  ProductTypeEnum,
  RateReqSchema,
  RateStatusEnum,
  RateTypeEnum,
  RateUsageBillingFrequencyEnum,
} from '../../../../../types';
import { convertRateResToReq } from '../../../../../utils/rates';
import { AdditionalFrequencyModal } from './AdditionalFrequencyModal';
import LockedStatus from './LockedStatus';
import { RateActions } from './RateActions';
import { RateFormHeader } from './RateFormHeader';
import RatePrices from './RatePrices';
import {
  getAdditionalFrequencyData,
  getOptionsFromProductObject,
  getPricesFromProductObject,
} from './RatePriceUtils';
import { RateSettingsPopover } from './RateSettingsPopover';

const gridTemplateExpanded =
  '0.625rem 18.75rem repeat(2, 8.875rem) repeat(3, 10fr) 2.6rem';

const gridTemplateCollapsed = '1fr';

export interface RateFormProps {
  id: string;
  products: IProduct[];
  type: OfferingTypesEnum;
  typeId: string;
  archivedOffering?: boolean;
  isClonedRate: boolean;
  handleCloneRate: (data: IRateReqSchemaUI) => void;
  handleDirtyRateIds: (rateId: string, type: 'add' | 'remove') => void;
  onChange?: (rateId: string) => void;
  onRemove?: () => void;
  removeRateTempUUId: (savedTempRateId: string) => void;
  isReadOnly?: boolean;
  isSubscriptionProductTypeExist: boolean;
  isSubscription: boolean;
  isExpandedAll?: boolean;
  offering?: IOfferingRes;
}

const RateForm: FC<RateFormProps> = ({
  id,
  products,
  type,
  typeId,
  archivedOffering,
  isClonedRate,
  handleCloneRate,
  handleDirtyRateIds,
  onChange,
  removeRateTempUUId,
  onRemove,
  isReadOnly,
  isSubscriptionProductTypeExist,
  isSubscription,
  isExpandedAll = false,
  offering,
}: RateFormProps) => {
  const {
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    reset,
    resetField,
    trigger,
    formState: { isSubmitted, dirtyFields, errors },
  } = useFormContext<IRateReqSchemaUI>();
  const { showPricingLevelConfigure } = useFlags();
  const [
    isAdditionalFrequencyRateLoading,
    setIsAdditionalFrequencyRateLoading,
  ] = useState<boolean>(false);
  const additionalFrequencyModal = useDisclosure();
  const isDirty = Object.keys(dirtyFields).length > 0; // using this since isDirty is not always updated
  const { defaultCurrency } = useCurrencies();

  const values = getValues();
  const {
    id: rateId,
    name,
    billingFrequency,
    billingFrequencyInMonths,
    usageBillingFrequency,
    subscriptionTiming,
    currency,
  } = values;
  const nameRef = useRef<HTMLInputElement | null>(null);
  const firstRenderRef = useRef<boolean>(true);
  const [pricesToCopyFromProductId, setPricesTopCopyFromProductId] = useState<
    string | undefined
  >();

  const {
    createOfferingRate,
    updateOfferingRate,
    fetchOfferingRate,
    removeOfferingRate,
    setOfferingRate,
    offeringRate,
    loading,
    isFetching,
  } = useOfferingRate(typeId);

  const isLocked = Boolean(offeringRate?.locked);
  const isRateInactive = watch('status') === RateStatusEnum.INACTIVE;
  const isNewRate = id?.match('new-') !== null;
  // Keeps track of the expandedIndex
  const [expandedIndex, setExpandedIndex] = useState<number>(-1);

  const handleAdditionalFrequency = async (
    frequency: AdditionalFrequency,
    finalData: IRateReqSchema,
  ) => {
    const additionalFrequencyData = getAdditionalFrequencyData(
      products,
      frequency,
      finalData,
    );
    const parsedAdditionalRate = RateReqSchema.safeParse(
      additionalFrequencyData,
    );

    const additionalOfferRateRes = await createOfferingRate(
      parsedAdditionalRate.success
        ? parsedAdditionalRate.data
        : additionalFrequencyData,
    );

    if (additionalOfferRateRes) {
      setOfferingRate(additionalOfferRateRes);
      onChange && onChange(additionalOfferRateRes.id);
    }
  };

  const onSubmit = async (formData: IRateReqSchemaUI) => {
    setIsAdditionalFrequencyRateLoading(true);
    const { products: datProducts, ...rest } = formData;
    const isAnyUsageProductExist = products.some(
      ({ productType }) => productType === ProductTypeEnum.USAGE,
    );

    const finalData: IRateReqSchema = {
      ...rest,
      prices: getPricesFromProductObject(formData.products),
      options: {
        priceDisplay: rest.options?.priceDisplay,
        productOptions: isAnyUsageProductExist
          ? getOptionsFromProductObject(formData.products)
          : [],
      },
      usageBillingFrequency: formData.usageBillingFrequency ?? null,
      percentOfTotalConfig:
        type === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL
          ? formData.percentOfTotalConfig?.type ===
            PercentOfTotalConfigTypeEnum.SPECIFIED_PRODUCTS
            ? {
                ...formData.percentOfTotalConfig,
                productIds: formData.percentOfTotalConfig.productIds || [],
              }
            : {
                type: PercentOfTotalConfigTypeEnum.ALL_ELIGIBLE,
              }
          : null,
      billingFrequencyInMonths: formData.billingFrequencyInMonths
        ? formData.billingFrequencyInMonths
        : formData.billingFrequency
          ? RATE_BILLING_FREQUENCY_MAP[formData.billingFrequency]().month
          : null,
    };
    if (!finalData.rateType) {
      finalData.rateType = RateTypeEnum.CATALOG;
    }

    const parsed = RateReqSchema.safeParse(finalData);
    if (!parsed.success) {
      Sentry.captureException(parsed.error.issues, {
        tags: {
          type: 'RATE_FORM',
        },
      });
    }
    const payload = parsed.success ? parsed.data : finalData;
    const createNewOfferRateRes = offeringRate
      ? await updateOfferingRate(payload, offeringRate.id)
      : await createOfferingRate(payload);

    // handles newly saved rate
    if (!offeringRate && createNewOfferRateRes) {
      setOfferingRate(createNewOfferRateRes);
      onChange && onChange(createNewOfferRateRes.id);
      removeRateTempUUId(id);
      // Since we are creating a new rate and we are using UUID
      // we should also remove the id from the dirtyRateIds array
      handleDirtyRateIds(id, 'remove');
    }

    if (offeringRate && createNewOfferRateRes) {
      // Since we are updating an existing rate we should also remove the
      // offering rate id from the dirtyRateIds array
      handleDirtyRateIds(offeringRate.id, 'remove');
    }

    if (Array.isArray(formData.additionalFrequencies)) {
      for (const frequency of formData.additionalFrequencies) {
        await handleAdditionalFrequency(frequency, finalData);
      }
    }

    if (additionalFrequencyModal.isOpen) {
      additionalFrequencyModal.onClose();
    }

    setIsAdditionalFrequencyRateLoading(false);
    return createNewOfferRateRes;
  };

  const onError = (error: any) => {
    logger.error(error);
  };

  const handleReset = () => {
    if (offeringRate?.id) {
      reset();
    }
  };

  const handleClone = () => {
    handleCloneRate(values);
  };

  const handleSaveOrUpdate = () => {
    handleSubmit(onSubmit, onError)();
  };

  const handleSavePlusCreateOtherFrequencies = async () => {
    const result = await trigger();

    if (result) {
      additionalFrequencyModal.onOpen();
    } else {
      onError(errors);
    }
  };

  const handleRemove = async () => {
    if (offeringRate?.id) {
      const res = await removeOfferingRate(offeringRate.id);
      if (res && onRemove) {
        onRemove();
        // Since we are removing an existing rate we should also remove the
        // offering rate id from the dirtyRateIds array
        handleDirtyRateIds(offeringRate.id, 'remove');
      }
    }

    if (onRemove && !offeringRate?.id) {
      onRemove();
      // Since we are removing a new rate and we are using UUID
      // we should also remove the id from the dirtyRateIds array
      handleDirtyRateIds(id, 'remove');
    }
  };

  const handleStatusChange = async (status: RateStatusEnum) => {
    if (offeringRate) {
      const { products: datProducts, ...rest } = values;
      const isAnyUsageProductExist = products.some(
        ({ productType }) => productType === ProductTypeEnum.USAGE,
      );

      const reqData: IRateReqSchema = {
        ...rest,
        status,
        prices: getPricesFromProductObject(values.products),
        options: isAnyUsageProductExist
          ? {
              productOptions: getOptionsFromProductObject(values.products),
            }
          : undefined,
        usageBillingFrequency:
          values.usageBillingFrequency === RateUsageBillingFrequencyEnum.MONTHLY
            ? RateUsageBillingFrequencyEnum.MONTHLY
            : null,
        percentOfTotalConfig:
          type === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL
            ? values.percentOfTotalConfig?.type ===
              PercentOfTotalConfigTypeEnum.SPECIFIED_PRODUCTS
              ? {
                  ...values.percentOfTotalConfig,
                  productIds: values.percentOfTotalConfig.productIds || [],
                }
              : {
                  type: PercentOfTotalConfigTypeEnum.ALL_ELIGIBLE,
                }
            : null,
      };
      if (!reqData.rateType) {
        reqData.rateType = RateTypeEnum.CATALOG;
      }

      const parsed = RateReqSchema.safeParse(reqData);
      if (!parsed.success) {
        Sentry.captureException(parsed.error.issues, {
          tags: {
            type: 'RATE_STATUS_UPDATE',
          },
        });
      }
      const payload = parsed.success ? parsed.data : reqData;
      await updateOfferingRate(payload, offeringRate.id);
    }
  };

  const resetRate = (r: IRateResSchema) => {
    reset(convertRateResToReq(r, products, defaultCurrency, type));
  };

  const handleCloseAdditionalFrequencyModal = () => {
    resetField('additionalFrequencies');
    additionalFrequencyModal.onClose();
  };

  useEffect(() => {
    if (id && !isFetching) {
      if (!(isNewRate || isClonedRate)) {
        fetchOfferingRate(id);
      }
      setValue('customId', id);
      setValue('id', id);

      if (isNewRate) {
        setValue('quotable', true);
      }
    }
  }, [id]);

  useEffect(() => {
    if (offeringRate) {
      resetRate(offeringRate);
    }
  }, [offeringRate]);

  useEffect(() => {
    if (rateId) {
      handleDirtyRateIds(rateId, isDirty ? 'add' : 'remove');
    }
  }, [rateId, isDirty]);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    }

    setTimeout(() => {
      /** Handles expansion of accordion */
      if (isClonedRate || !!isNewRate) {
        setExpandedIndex(0);
      }
    });
    if (isNewRate && products.length) {
      products.map(({ id: productId, productType }) => {
        setValue(`products.${productId}.productType`, productType);
      });
    }
  }, []);

  useEffect(() => {
    setExpandedIndex(isExpandedAll ? 0 : -1);
  }, [isExpandedAll]);

  useEffect(() => {
    if (expandedIndex === 0 && !isExpandedAll) {
      nameRef.current?.focus();
    }
  }, [expandedIndex, isExpandedAll]);

  const showUnsavedAlert = !isSubmitted && !loading && isDirty;
  if ((firstRenderRef.current && (!isNewRate || !isClonedRate)) || isFetching) {
    return (
      <MBox pl={8} mb={4}>
        <MSkeleton h={14} p={4} />
      </MBox>
    );
  }

  return (
    <>
      <MAccordion
        reduceMotion
        allowToggle
        key={id}
        pl={8}
        mb={4}
        index={expandedIndex}
        onChange={(val) => setExpandedIndex(val as number)}
      >
        <MAccordionItem
          id={id}
          w="100%"
          minW="fit-content"
          data-testid="rate-form"
        >
          {({ isExpanded }) => {
            return (
              <>
                <MGrid
                  gridTemplateColumns={
                    isExpanded ? gridTemplateExpanded : gridTemplateCollapsed
                  }
                  gap={isExpanded ? 4 : 0}
                  py={isExpanded ? 4 : 1}
                  pl={isExpanded ? 2.5 : 2}
                  pr={isExpanded ? 4 : 2}
                >
                  {!isExpanded && (
                    <MAccordionCustomButton
                      isExpanded={isExpanded}
                      label={name}
                      customLabel={
                        <MFlex alignItems="center">
                          <MText fontWeight="600" fontSize="lg" ml={1}>
                            {name}
                          </MText>
                          {isRateInactive && (
                            <MTag ml={2} px={2} py={1} h={5}>
                              Inactive
                            </MTag>
                          )}
                        </MFlex>
                      }
                      buttonProps={{
                        _hover: {
                          bg: 'tWhite.base',
                        },
                      }}
                      testId="toggle-rate-button"
                    >
                      <MFlex justify="space-between">
                        {showUnsavedAlert && (
                          <MAlert
                            type="warning"
                            alertProps={{ px: 2, py: 1 }}
                            alertIconProps={{ width: 4, height: 4 }}
                            alertDescriptionProps={{ fontSize: 'sm' }}
                            alignItems="center"
                          >
                            Unsaved Changes
                          </MAlert>
                        )}
                        <MFlex gap={2}>
                          <MAccordionCustomButtonItem
                            label="Currency"
                            value={currency}
                          />
                          <MAccordionCustomButtonItem
                            label={
                              type === OfferingTypesEnum.MIN_COMMIT
                                ? 'Minimum Commit Frequency'
                                : 'Subscription Frequency'
                            }
                            value={
                              billingFrequency
                                ? RATE_BILLING_FREQUENCY_MAP[billingFrequency](
                                    billingFrequencyInMonths,
                                  ).label
                                : ''
                            }
                          />
                        </MFlex>
                        <MFlex>
                          {isLocked && (
                            <LockedStatus containerProps={{ ml: 4 }} />
                          )}
                          {!isReadOnly && (
                            <RateActions
                              boxProps={{
                                display: 'flex',
                                alignSelf: 'center',
                                ml: isLocked ? 0 : 4,
                              }}
                              isLocked={isLocked}
                              isNewRate={id?.match('new-') !== null}
                              rateId={offeringRate?.id || ''}
                              handleClone={handleClone}
                              handleRemove={handleRemove}
                              rateStatus={offeringRate?.status}
                              handleStatusChange={handleStatusChange}
                            />
                          )}
                        </MFlex>
                      </MFlex>
                    </MAccordionCustomButton>
                  )}
                  {isExpanded && (
                    <>
                      <MTooltip label="Toggle" placement="top-start" mt={4}>
                        <MAccordionButton // rate accordion
                          p={0}
                          alignItems={isExpanded ? 'flex-start' : 'center'}
                          _hover={{ bg: 'white' }}
                          data-testid="toggle-rate-button"
                        >
                          <MIcon
                            as={isExpanded ? MdExpandMore : MdChevronRight}
                            boxSize="1.5rem"
                          />
                        </MAccordionButton>
                      </MTooltip>
                      <RateFormHeader
                        rateId={offeringRate?.id || ''}
                        loading={loading}
                        archivedOffering={archivedOffering}
                        nameRef={nameRef}
                        currency={currency}
                        isLocked={isLocked}
                        type={type}
                        billingFrequency={billingFrequency}
                        billingFrequencyInMonths={billingFrequencyInMonths}
                        subscriptionTiming={subscriptionTiming}
                        usageBillingFrequency={usageBillingFrequency}
                        handleReset={handleReset}
                        handleClone={handleClone}
                        handleRemove={handleRemove}
                        isReadOnly={isReadOnly}
                        isSubscriptionProductTypeExist={
                          isSubscriptionProductTypeExist
                        }
                        isSubscription={isSubscription}
                      />
                    </>
                  )}
                </MGrid>

                {isExpanded && (
                  <>
                    <MBox px="4">
                      <MDivider borderColor="tBlue.hover" />
                    </MBox>
                    <MBox
                      px="4"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt="4"
                    >
                      <MText fontSize="lg" fontWeight="bold">
                        Product Prices
                      </MText>
                      {showPricingLevelConfigure && (
                        <RateSettingsPopover
                          offering={offering}
                          products={products}
                          handleSave={handleSaveOrUpdate}
                        />
                      )}
                    </MBox>
                  </>
                )}
                <MAccordionPanel
                  py={0}
                  pl={8}
                  motionProps={{ animateOpacity: false }}
                >
                  {isExpanded &&
                    products.map((product, index: number) => (
                      <MFlex flexDir="column" key={`${product.id}-index`}>
                        <React.Fragment key={`${product.id}-index`}>
                          <RatePrices
                            ref={nameRef}
                            product={product}
                            key={product.id}
                            isLocked={isLocked || archivedOffering!}
                            loading={loading}
                            isLastRow={products.length - 1 === index}
                            currency={offeringRate?.currency || defaultCurrency}
                            pricesLengthFromApi={offeringRate?.prices?.length}
                            isExistingRate={!!offeringRate?.id}
                            isReadOnly={isReadOnly}
                            offeringType={type}
                            pricesToCopyFromProductId={
                              pricesToCopyFromProductId
                            }
                            isMultipleProduct={products.length > 1}
                            setPricesTopCopyFromProductId={
                              setPricesTopCopyFromProductId
                            }
                            isProductExpanded={isExpanded}
                          />
                        </React.Fragment>
                        {products.length - 1 !== index && (
                          <MDivider borderColor="tBlue.hover" opacity="1" />
                        )}
                      </MFlex>
                    ))}

                  <MDivider borderColor="tBlue.hover" opacity="1" />
                  {!isReadOnly && (
                    <MFlex align="center" justify="flex-end" gap={4} py={4}>
                      {showUnsavedAlert && (
                        <MAlert
                          type="warning"
                          alertProps={{ px: 2, py: 1 }}
                          alertIconProps={{ width: 4, height: 4 }}
                          alertDescriptionProps={{ fontSize: 'sm' }}
                        >
                          Unsaved Changes
                        </MAlert>
                      )}
                      <MButton
                        variant="primary"
                        isLoading={loading}
                        isDisabled={loading || !isDirty || archivedOffering}
                        onClick={handleSaveOrUpdate}
                        type="submit"
                        data-testid="rate-save-btn"
                      >
                        Save
                      </MButton>
                      {!offeringRate?.id &&
                        type !== OfferingTypesEnum.ONETIME && (
                          <MButton
                            variant="primary"
                            isLoading={loading}
                            isDisabled={loading || !isDirty || archivedOffering}
                            onClick={handleSavePlusCreateOtherFrequencies}
                          >
                            Save + Create Other Frequencies
                          </MButton>
                        )}
                    </MFlex>
                  )}
                </MAccordionPanel>
              </>
            );
          }}
        </MAccordionItem>
      </MAccordion>
      {type !== OfferingTypesEnum.ONETIME && (
        <AdditionalFrequencyModal
          billingFrequency={billingFrequency}
          billingFrequencyInMonths={billingFrequencyInMonths}
          control={control}
          isOpen={additionalFrequencyModal.isOpen}
          onClose={handleCloseAdditionalFrequencyModal}
          errors={errors}
          handleSaveOrUpdate={handleSaveOrUpdate}
          isAdditionalFrequencyRateLoading={isAdditionalFrequencyRateLoading}
        />
      )}
    </>
  );
};

export default RateForm;
