import { formatInteger } from '@monetize/utils/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  MBox,
  MButton,
  MCheckbox,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
} from '../../../../components/Monetize';
import MSearchInput from '../../../../components/Monetize/MSearchInput';
import MText from '../../../../components/Monetize/MText';
import { PRODUCT_TYPE_DISPLAY } from '../../../../constants/products';

export interface ProductItem {
  fieldId: string;
  productId: string;
  name: string;
  description?: string;
  productType: string;
  isMandatory: boolean;
  isSelected?: boolean | null;
}

interface OneTimeInvoiceManageProductsDrawerProps {
  isOpen: boolean;
  offeringName: string;
  products: ProductItem[];
  onClose: () => void;
  onSelectProduct: (productIndex: number, newVal: boolean) => void;
}

export const OneTimeInvoiceManageProductsDrawer = ({
  isOpen,
  offeringName,
  products,
  onClose,
  onSelectProduct,
}: OneTimeInvoiceManageProductsDrawerProps) => {
  const [drawerProducts, setDrawerProducts] = useState<ProductItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setDrawerProducts(products);
  }, [products]);

  const productIndexMap = useMemo(() => {
    const map: Record<string, number> = {};
    drawerProducts.forEach(({ fieldId }, idx) => {
      map[fieldId] = idx;
    });

    return map;
  }, [drawerProducts]);

  const filteredProducts = useMemo(() => {
    if (!searchTerm.trim()) {
      return drawerProducts;
    }
    return drawerProducts.filter(({ name }) =>
      name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [drawerProducts, searchTerm]);

  const allSelected = useMemo(() => {
    if (filteredProducts.length === 0) {
      return false;
    }
    return filteredProducts.every(({ isSelected }) => isSelected);
  }, [filteredProducts]);

  const hasChanges = useMemo(() => {
    if (drawerProducts.length !== products.length) {
      return true;
    }
    return drawerProducts.some(
      (drawerProduct, idx) =>
        drawerProduct.isSelected !== products[idx].isSelected,
    );
  }, [drawerProducts, products]);

  const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = !allSelected;

    const optionalIndexes = filteredProducts
      .filter(({ isMandatory }) => !isMandatory)
      .map(({ fieldId }) => productIndexMap[fieldId]);

    setDrawerProducts((prev) => {
      const updated = [...prev];
      optionalIndexes.forEach((idx) => {
        updated[idx] = {
          ...updated[idx],
          isSelected: newVal,
        };
      });
      return updated;
    });
  };

  const handleToggleProduct = (fieldId: string, checked: boolean) => {
    setDrawerProducts((prev) => {
      const updated = [...prev];
      const idx = productIndexMap[fieldId];
      updated[idx] = {
        ...updated[idx],
        isSelected: checked,
      };
      return updated;
    });
  };

  const handleSave = () => {
    drawerProducts.forEach((drawerProduct, idx) => {
      const original = products[idx];

      if (drawerProduct.isSelected !== original.isSelected) {
        onSelectProduct(idx, !!drawerProduct.isSelected);
      }
    });

    onClose();
  };

  const selectedCount = drawerProducts.filter(
    ({ isSelected }) => isSelected,
  ).length;
  const totalCount = drawerProducts.length;

  return (
    <MDrawer
      placement="right"
      onClose={onClose}
      isOpen={isOpen}
      size="md"
      closeOnOverlayClick={false}
    >
      <MDrawerOverlay />
      <MDrawerContent>
        <MDrawerCloseButton />
        <MDrawerHeader borderBottomWidth="1px">{offeringName}</MDrawerHeader>
        <MDrawerBody>
          <MBox mb={4}>
            <MSearchInput
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder="Search products..."
              count={filteredProducts.length}
            />
          </MBox>

          <MFlex align="center" mb={2}>
            <MCheckbox
              isChecked={allSelected}
              isDisabled={filteredProducts.length === 0}
              isIndeterminate={!allSelected}
              onChange={handleSelectAllChange}
              mr={3}
            >
              <MText fontSize="sm">
                Select All ({formatInteger(selectedCount)}/
                {formatInteger(totalCount)})
              </MText>
            </MCheckbox>
          </MFlex>
          <MDivider mb={2} />

          {filteredProducts.map((product) => {
            return (
              <MFlex key={product.fieldId} align="center" py={4}>
                <MCheckbox
                  isChecked={!!product.isSelected || product.isMandatory}
                  isDisabled={product.isMandatory}
                  isReadOnly={product.isMandatory}
                  onChange={(e) =>
                    handleToggleProduct(product.fieldId, e.target.checked)
                  }
                  mr={3}
                >
                  <MText ml={1}>
                    <MText display="inline" fontWeight="bold">
                      {product.name}
                    </MText>{' '}
                    {`(${PRODUCT_TYPE_DISPLAY[product.productType]})`}
                  </MText>
                  <MText ml={1} mt={2} fontSize="sm" color="gray.500">
                    {product.description}
                  </MText>
                </MCheckbox>
              </MFlex>
            );
          })}
        </MDrawerBody>

        <MDrawerFooter borderTopWidth="1px">
          <MFlex w="full" justifyContent="flex-end">
            <MButton variant="outline" mr={3} onClick={onClose}>
              Cancel
            </MButton>
            <MButton
              variant="primary"
              onClick={handleSave}
              disabled={!hasChanges}
            >
              Save
            </MButton>
          </MFlex>
        </MDrawerFooter>
      </MDrawerContent>
    </MDrawer>
  );
};
