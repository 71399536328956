import { useDisclosure } from '@chakra-ui/react';
import hasValue from 'lodash/has';
import isString from 'lodash/isString';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { getAccountById } from '../../../../../api/accountsService';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  doAmendContract,
  doRenewContract,
  useGetOrCreateOpportunityWithCrmLink,
} from '../../../../../api/cpqService';
import {
  MBox,
  MButton,
  MCenterModal,
  MCurrencySelect,
  MCustomSelect,
  MFlex,
  MFormField,
  MHStack,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  MTooltip,
  MVStack,
} from '../../../../../components/Monetize';
import { RenewalModal } from '../../../../../components/RenewalModal/RenewalModal';
import { NEW_CONTRACT_SLUG } from '../../../../../constants/quotes';
import { getQuoteEditRoute } from '../../../../../constants/routes';
import { useBackNavigate } from '../../../../../hooks/useBackNavigate';
import { useACL } from '../../../../../services/acl/acl';
import { useFlags } from '../../../../../services/launchDarkly';
import { appGlobalDataState } from '../../../../../store/global.store';
import {
  ContractStatusEnum,
  CrmOpportunityForInput,
  IAccount,
  IAccountDetails,
  IAccountSchema,
  IContractWithQuotes,
  ICustomFieldRecordSchema,
  IQuoteRequestSchemaExtended,
  IQuoteRespSchema,
  Maybe,
  QuoteTypeEnum,
} from '../../../../../types';
import { ILegalEntityResponseSchema } from '../../../../../types/legalEntityTypes';
import {
  canAmendContract,
  canRenewContract,
} from '../../../../../utils/contracts';
import { getQuoteDescription } from '../../quoteUtils';
import { ContractSelectField } from './ContractSelectField';
import NewAccountForm from './NewAccountForm';
import { NewQuoteFormAccountSelect } from './NewQuoteFormAccountSelect';
import { OpportunitySearchInput } from './OpportunitySearchInput';

export interface NewQuoteFormDataTypes {
  isOpen: boolean;
  loading: boolean;
  accountById: IAccountDetails | null;
  fetchAccountById: (accountId: string) => Promise<IAccountDetails | null>;
  onOpen: () => void;
  onClose: () => void;
}

export const useNewQuoteFormData = (): NewQuoteFormDataTypes => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [accountById, setAccountById] = useState<IAccountDetails | null>(null);

  const fetchAccountById = async (
    accountId: string,
  ): Promise<IAccountDetails | null> => {
    setLoading(true);
    try {
      const res = await getAccountById(accountId);

      setAccountById(res);
      return res;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    isOpen,
    loading,
    accountById,
    onOpen,
    onClose,
    fetchAccountById,
  };
};

interface NewQuoteFormProps {
  isOpen: boolean;
  hasGuidedSellingProcess?: boolean;
  fixedAccount: IAccountDetails | null;
  legalEntities: ILegalEntityResponseSchema[];
  existingOpportunityId?: Maybe<string>;
  onClose: () => void;
  closeAndContinue: ({
    crmOpportunity,
    customFields,
  }: {
    crmOpportunity?: Maybe<CrmOpportunityForInput>;
    customFields?: ICustomFieldRecordSchema;
    useGuidedSelling: boolean;
  }) => Promise<void>;
  onCreateNewAccount?: (account: IAccountSchema) => Promise<IAccount>;
  crmCustomFields?: Record<string, any>;
}

export const NewQuoteForm: FC<NewQuoteFormProps> = ({
  isOpen,
  hasGuidedSellingProcess = false,
  onClose,
  closeAndContinue,
  existingOpportunityId,
  fixedAccount: internalFixedAccount,
  legalEntities,
  onCreateNewAccount,
  crmCustomFields = {},
}: NewQuoteFormProps) => {
  const { hasMultipleCurrency, hasMultipleLegalEntity, hasCrmConfigured } =
    useRecoilValue(appGlobalDataState);
  const { control, watch, formState, getValues, setValue } =
    useFormContext<IQuoteRequestSchemaExtended>();
  const [isNewAccount, setIsNewAccount] = useState<boolean>(false);
  const [newAccount, setNewAccount] = useState<IAccountSchema>();
  const [crmOpportunity, setCrmOpportunity] =
    useState<CrmOpportunityForInput | null>(null);
  const [customFields, setCustomFields] =
    useState<ICustomFieldRecordSchema>(crmCustomFields);
  const [fixedAccount, setFixedAccount] = useState<IAccountDetails | null>(
    internalFixedAccount,
  );
  const [contracts, setContracts] = useState<IContractWithQuotes[]>([]);
  const [isContractLoading, setIsContractLoading] = useState(false);
  const [selectedContract, setSelectedContract] =
    useState<IContractWithQuotes>();
  const [isExistingContractSelected, setIsExistingContractSelected] =
    useState<boolean>(false);
  const [isNewQuoteLoading, setIsNewQuoteLoading] = useState<boolean>(false);
  const [isNewAccountLoading, setIsNewAccountLoading] =
    useState<boolean>(false);
  const { navigate, navigateBack } = useBackNavigate(1);
  const { mutateAsync: doCreateOrGetOpportunity } =
    useGetOrCreateOpportunityWithCrmLink();

  const { enableEarlyRenewal } = useFlags();

  const {
    isOpen: isOpenRenewalModal,
    onOpen: onOpenRenewalModal,
    onClose: onCloseRenewalModal,
  } = useDisclosure();

  const selectedAccountOption = watch('account');
  const selectedContractId = watch('contractId');
  const selectedQuoteType = watch('quoteType');

  const isAccountSelected = !!(selectedAccountOption || internalFixedAccount);

  const isNewContractSelected = selectedContractId === NEW_CONTRACT_SLUG;
  const isContractSelected = !!selectedContractId;
  const isRenewal = selectedQuoteType === QuoteTypeEnum.RENEWAL;

  const { canDo } = useACL();

  useEffect(() => {
    setIsExistingContractSelected(!!selectedContract && !isNewContractSelected);
  }, [selectedContract, isNewContractSelected]);

  useEffect(() => {
    !selectedContract && setValue('quoteType', '');
  }, [selectedContract, setValue]);

  useEffect(() => {
    if (fixedAccount) {
      // account passed in props, user cannot change the selected account
      setValue('account', fixedAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedAccount]);

  const setDescription = (accountName: string) => {
    setValue('description', getQuoteDescription(accountName));
  };

  const setNewContractValues = () => {
    const allValues = getValues();
    if (!hasValue(allValues, 'account')) {
      return;
    }
    setValue('accountId', (allValues?.account as IAccountDetails)?.id);
    setDescription((allValues?.account as IAccountDetails)?.accountName || '');
  };

  const handleNewAccountChange = useCallback((account: IAccountSchema) => {
    setNewAccount(account);
    setValue('currency', account.defaultCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearAccountValue = () => {
    setValue('account', '');
    setValue('billGroupId', '');
  };

  const submitModal = useCallback(
    async (useGuidedSelling: boolean) => {
      if (isRenewal && enableEarlyRenewal) {
        onOpenRenewalModal();
        return;
      }

      try {
        if (isNewAccount && newAccount && onCreateNewAccount) {
          setIsNewAccountLoading(true);
          const account = await onCreateNewAccount(newAccount);
          setValue('legalEntityId', account.defaultLegalEntityId);
          setIsNewAccount(false);
          setFixedAccount(account as unknown as IAccountDetails);
          setIsNewAccountLoading(false);
          setNewContractValues();
          return;
        }

        setIsNewQuoteLoading(true);
        if (!isNewAccount && !isExistingContractSelected) {
          setNewContractValues();
          await closeAndContinue({
            crmOpportunity,
            customFields,
            useGuidedSelling,
          });
          setIsNewQuoteLoading(false);
          return;
        }

        // instead of setting values on the underlying new quote form, user is amending or renewing an existing quote,
        // so we call the endpoint that creates the new quote, then open it:
        const { account, quoteType } = getValues();
        const contractId = selectedContract?.id;
        if (!contractId) {
          throw new Error('Cannot find contract id');
        }

        let newQuote: IQuoteRespSchema | null = null;

        let opportunityId = existingOpportunityId;

        /**
         * If the user has selected a CRM opportunity, then use that opportunity
         */
        if (crmOpportunity && account && !isString(account)) {
          const opportunity = await doCreateOrGetOpportunity({
            customId: crmOpportunity.id,
            createPayload: {
              accountId: account.id,
              name: crmOpportunity.name,
              customId: crmOpportunity.id,
            },
          });
          opportunityId = opportunity.id;
        }

        switch (quoteType) {
          case QuoteTypeEnum.AMENDMENT:
            newQuote = await doAmendContract(
              contractId,
              opportunityId ? { id: opportunityId } : undefined,
            );
            break;
          case QuoteTypeEnum.RENEWAL:
            newQuote = await doRenewContract(
              contractId,
              opportunityId
                ? { opportunity: { id: opportunityId } }
                : undefined,
            );
            break;
          default:
            throw new Error('Invalid quote type');
        }
        if (newQuote?.id) {
          navigate(getQuoteEditRoute(newQuote.id));
        }
      } catch (error) {
        handleApiErrorToast(error);
      } finally {
        setIsNewQuoteLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      isRenewal,
      enableEarlyRenewal,
      onOpenRenewalModal,
      isNewAccount,
      newAccount,
      onCreateNewAccount,
      isExistingContractSelected,
      getValues,
      selectedContract?.id,
      existingOpportunityId,
      crmOpportunity,
      setValue,
      setNewContractValues,
      closeAndContinue,
      customFields,
      doCreateOrGetOpportunity,
      navigate,
    ],
  );

  const handleModalCancel = () => {
    if (isNewAccount) {
      setIsNewAccount(false);
      return;
    }
    return navigateBack();
  };

  // if New Account created from New Quote modal must have accountName otherwise button disable
  const isNewAccountWithoutName = isNewAccount && !newAccount?.accountName;
  // if existing contract selected you must choose Amend/Renew otherwise button disable
  const isExistingContractWithoutQuoteType =
    isExistingContractSelected && !selectedQuoteType;
  // if New Account created from New Quote modal must have accountName otherwise button disable
  const isNeitherNewAccountNorContractSelected =
    !isExistingContractSelected && !isNewAccount && !isNewContractSelected;

  const isSubmitButtonDisable =
    isNewQuoteLoading ||
    isNewAccountWithoutName ||
    isExistingContractWithoutQuoteType ||
    isNeitherNewAccountNorContractSelected;

  if (selectedContract && isOpenRenewalModal) {
    return (
      <RenewalModal
        contract={selectedContract}
        crmOpportunity={crmOpportunity}
        existingOpportunityId={existingOpportunityId}
        account={getValues('account')}
        onClose={onCloseRenewalModal}
      />
    );
  }

  return (
    <MCenterModal
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="New Quote"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton
            variant="cancel"
            isDisabled={isNewQuoteLoading}
            onClick={handleModalCancel}
            data-testid="new-quote-cancel-new-account"
            minW="auto"
          >
            Cancel
          </MButton>

          {!!selectedAccountOption &&
            hasGuidedSellingProcess &&
            selectedContractId === NEW_CONTRACT_SLUG && (
              <MButton
                data-testid="new-quote-guided-selling-modal-btn"
                variant="secondary"
                onClick={() => submitModal(true)}
                isDisabled={isSubmitButtonDisable}
                minW="auto"
              >
                New Guided Quote
              </MButton>
            )}

          <MButton
            variant="primary"
            isLoading={isNewQuoteLoading || isNewAccountLoading}
            onClick={() => submitModal(false)}
            isDisabled={isSubmitButtonDisable}
            data-testid="new-quote-modal-btn"
            minW="auto"
          >
            {isNewAccount || isRenewal ? 'Next' : 'Create'}
          </MButton>
        </MStack>
      )}
    >
      <MBox>
        {isNewAccount ? (
          <NewAccountForm onChange={handleNewAccountChange} />
        ) : (
          <MVStack spacing="3" align="flex-start">
            {!internalFixedAccount && (
              <MFormField label="Existing Account" isRequired>
                <Controller
                  name="account"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NewQuoteFormAccountSelect
                      setValue={setValue}
                      isExistingContractSelected={isExistingContractSelected}
                      onClearAccountValue={onClearAccountValue}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            )}

            {!!internalFixedAccount && (
              <MVStack spacing="1" align="flex-start">
                <MText fontWeight="bold">Account</MText>
                <MText>{internalFixedAccount.accountName}</MText>
              </MVStack>
            )}

            {!isAccountSelected && canDo([['accounts', 'create']]) && (
              <MHStack>
                <MText>Can&apos;t find the right account?</MText>
                <MButton
                  variant="tertiary"
                  onClick={() => setIsNewAccount(true)}
                  data-testid="new-quote-with-new-account-btn"
                >
                  Create New Account
                </MButton>
              </MHStack>
            )}

            {isAccountSelected && (
              <ContractSelectField
                accountId={
                  (!isString(selectedAccountOption) &&
                    selectedAccountOption?.id) ||
                  internalFixedAccount?.id ||
                  ''
                }
                contracts={contracts}
                setContracts={setContracts}
                setIsContractLoading={setIsContractLoading}
                isContractLoading={isContractLoading}
                selectedContract={selectedContract}
                setSelectedContract={setSelectedContract}
                accountCurrency={
                  (!isString(selectedAccountOption) &&
                    selectedAccountOption?.defaultCurrency) ||
                  internalFixedAccount?.defaultCurrency
                }
              />
            )}
            {isAccountSelected &&
              isExistingContractSelected &&
              !isContractLoading && (
                <MFormField
                  label="Do you want to amend or renew this contract?"
                  isRequired
                >
                  <Controller
                    name="quoteType"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <MRadioGroup onChange={onChange}>
                        <MStack direction="row" spacing={3}>
                          <MRadio
                            value={QuoteTypeEnum.AMENDMENT}
                            isDisabled={!canAmendContract(selectedContract!)}
                          >
                            Amend
                          </MRadio>
                          <MRadio
                            value={QuoteTypeEnum.RENEWAL}
                            isDisabled={!canRenewContract(selectedContract!)}
                          >
                            <MTooltip
                              label={
                                'Canceled Contracts cannot be renewed.  Instead, create a New Quote.'
                              }
                              placement="bottom-end"
                              isDisabled={
                                selectedContract?.status !==
                                ContractStatusEnum.CANCELED
                              }
                            >
                              Renew
                            </MTooltip>
                          </MRadio>
                        </MStack>
                      </MRadioGroup>
                    )}
                  />
                </MFormField>
              )}
            {isContractSelected && (
              <MFlex w="full" gap="4">
                {hasMultipleLegalEntity && (
                  <MFormField
                    error={formState.errors.legalEntityId}
                    label="Legal Entity"
                    isRequired
                  >
                    <Controller
                      name="legalEntityId"
                      control={control}
                      render={({ field }) => (
                        <MCustomSelect
                          isDisabled={isExistingContractSelected}
                          itemTitle="name"
                          itemValue="id"
                          items={legalEntities}
                          {...field}
                        />
                      )}
                    />
                  </MFormField>
                )}

                {hasMultipleCurrency && (
                  <MFormField
                    error={formState.errors.currency}
                    label="Currency"
                    isRequired
                  >
                    <Controller
                      name="currency"
                      control={control}
                      render={({ field }) => (
                        <MCurrencySelect
                          isDisabled={isExistingContractSelected}
                          {...field}
                        />
                      )}
                    />
                  </MFormField>
                )}
              </MFlex>
            )}
            {isContractSelected &&
              !existingOpportunityId &&
              hasCrmConfigured && (
                <OpportunitySearchInput
                  accountId={
                    isString(selectedAccountOption)
                      ? selectedAccountOption
                      : selectedAccountOption?.id ||
                        internalFixedAccount?.id ||
                        ''
                  }
                  crmOpportunityId={crmOpportunity?.id}
                  onChange={setCrmOpportunity}
                  showDivider
                />
              )}
          </MVStack>
        )}
      </MBox>
    </MCenterModal>
  );
};
