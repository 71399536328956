import { Navigate, useLocation } from 'react-router-dom';
import {
  getAccountDetailRoute,
  getDiscountEditRoute,
  getInvoiceDetailRoute,
  getOfferingEditRoute,
  getProductEditRoute,
  getQuoteBaseRoute,
  getSalesContractEditRoute,
  getSalesOpportunityEditRoute,
  getUsageTypeEditRoute,
} from '../constants/routes';
import NotFound from './NotFound';

// ID Prefix Wiki: https://monetizenow.atlassian.net/wiki/spaces/PE/pages/1029439691/Id+Prefix
type IdPrefix =
  | 'acct'
  | 'ctrct'
  | 'disc'
  | 'disct'
  | 'invc'
  | 'offer'
  | 'offr'
  | 'opp'
  | 'prod'
  | 'quot'
  | 'usgt';
type IdPrefixRouteFunc = (id: string) => string;

const IdPrefixToRouteMap: Record<IdPrefix, IdPrefixRouteFunc> = {
  acct: getAccountDetailRoute,
  ctrct: getSalesContractEditRoute,
  disc: getDiscountEditRoute,
  disct: getDiscountEditRoute,
  invc: getInvoiceDetailRoute,
  offer: getOfferingEditRoute,
  offr: getOfferingEditRoute,
  opp: getSalesOpportunityEditRoute,
  prod: getProductEditRoute,
  quot: getQuoteBaseRoute,
  usgt: getUsageTypeEditRoute,
};

const getIdPrefix = (pathname: string) => {
  return pathname.replace('/', '').split('_')[0] as IdPrefix;
};

/*
 * we want to be able to visit https://app.monetizeplatform.com/offer_TSDNkMIiKL2KGSRR and this component automatically
 * redirected to https://app.monetizeplatform.com/offerings/offer_TSDNkMIiKL2KGSRR/edit
 * TICKET: https://monetizenow.atlassian.net/browse/BP-7119
 */
const IdPrefixNavigator = () => {
  const { pathname } = useLocation();
  const prefix = getIdPrefix(pathname);
  if (prefix in IdPrefixToRouteMap) {
    const routeFunction = IdPrefixToRouteMap[prefix];
    if (typeof routeFunction === 'function') {
      return <Navigate to={routeFunction(pathname.replace('/', ''))} />;
    }
  }
  return <NotFound />;
};

export default IdPrefixNavigator;
