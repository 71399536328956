import { ColumnProps } from 'primereact/column';
import { DataTableValue } from 'primereact/datatable';
import { useNavigate } from 'react-router';
import { MBox, MDataTable, MText } from '../../../../components/Monetize';
import DataTableActions from '../../../../components/Monetize/DataTable/MDataTableActions';
import { ROUTES } from '../../../../constants';
import { IGetSubscriptionItemSchema, Maybe } from '../../../../types';
import { UsageTypeIDName } from './UsageTypeIDName';
import { UsageUnit } from './UsageUnit';

type UsageListTableProps = {
  accountId: string;
  subscriptionId: string;
  subscriptionUsageProducts: IGetSubscriptionItemSchema[];
  unitsConsumed: Maybe<
    Record<string, { unitsConsumed: number; error: boolean }>
  >;
  billGroupId?: string;
  handleUsageUpload: (
    subscriptionId: string,
    subscriptionItem: IGetSubscriptionItemSchema,
  ) => void;
  handleViewTrendSingleProduct: (
    subscriptionId: string,
    productId: string,
  ) => void;
};

export const UsageListTable = ({
  accountId,
  subscriptionId,
  subscriptionUsageProducts,
  unitsConsumed,
  billGroupId,
  handleUsageUpload,
  handleViewTrendSingleProduct,
}: UsageListTableProps) => {
  const navigate = useNavigate();
  const goToUsageEventsPage = (rowData: DataTableValue) => {
    const generatedRoute = ROUTES.getUsageEventsPageRoute(
      accountId,
      subscriptionId,
      rowData.product.id,
    );
    navigate(generatedRoute);
  };

  const columns: ColumnProps[] = [
    {
      header: 'Name',
      style: { width: '12rem' },
      body: (data) => (
        <MBox pl={7}>
          <MText fontWeight="500">{data.product.name}</MText>
        </MBox>
      ),
    },
    ...(!billGroupId
      ? [
          {
            header: 'Bill Group',
            style: { width: '10rem' },
          },
        ]
      : []),
    {
      header: 'ID',
      style: { width: '7rem' },
      body: (data) => {
        return (
          <UsageTypeIDName
            usageTypes={data.product.usageTypes}
            target="usageTypeId"
          />
        );
      },
    },
    {
      header: 'Description',
      style: { width: '10rem' },
      body: (data) => <MText>{data.product.description}</MText>,
    },
    {
      header: 'Units',
      style: { width: '5rem' },
      body: (data) => {
        const unitConsumedByPorduct =
          unitsConsumed && unitsConsumed[data.product.id]?.unitsConsumed;
        const consumptionFetchError =
          (unitsConsumed && unitsConsumed[data.product.id]?.error) ?? false;

        return (
          <UsageUnit
            consumptionLoading={!unitsConsumed![data.product.id]}
            hasError={consumptionFetchError}
            usageTypes={data.product.usageTypes}
            unitsConsumed={unitConsumedByPorduct}
          />
        );
      },
    },
    {
      header: 'Type',
      style: { width: '5rem' },
      body: (data) => (
        <UsageTypeIDName
          usageTypes={data.product.usageTypes}
          target="usageTypeName"
        />
      ),
    },
    {
      style: { textAlign: 'left', width: '2rem' },
      body: (data) => {
        const actions = [
          {
            title: 'Add Usage',
            enabled: true,
            action: () => {
              handleUsageUpload(subscriptionId, data);
            },
          },
          {
            title: 'View Trend',
            enabled: true,
            action: () => {
              handleViewTrendSingleProduct(subscriptionId, data.product.id);
            },
          },
          {
            title: 'Review Events',
            enabled: true,
            action: () => goToUsageEventsPage(data),
          },
        ];
        return <DataTableActions actions={actions} />;
      },
    },
  ];

  return (
    <MDataTable
      rowHover
      dataKey="id"
      showHeaders={false}
      columns={columns}
      value={subscriptionUsageProducts}
      className="p-datatable-noscrollbar"
      tableStyle={{ tableLayout: 'fixed' }}
      onRowClick={({ data }) => goToUsageEventsPage(data)}
    />
  );
};
