import React, { useEffect, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { useGetAccountList } from '../../../../../api/accountsService';
import { MCustomSelect, MFlex } from '../../../../../components/Monetize';
import { MainSearchInput } from '../../../../../components/Monetize/MCustomSelect/components/MainSearchInput';
import { Highlighter } from '../../../../../components/Monetize/MPageSearchInput/Highlighter';
import { useDebounce } from '../../../../../hooks/useDebounce';
import {
  AccountStatusEnum,
  DEFAULT_PAGER,
  GetListApiFilter,
  IAccount,
  IAccountDetails,
  IQuoteRequestSchemaExtended,
} from '../../../../../types';
import { MCustomSelectProps } from '../../../../../types/mCustomSelectTypes';

interface NewQuoteFormAccountSelectProps extends MCustomSelectProps {
  setValue: UseFormSetValue<IQuoteRequestSchemaExtended>;
  isExistingContractSelected: boolean;
  onClearAccountValue: () => void;
  onChange: (account: IAccount) => void;
}

const renderItemContentForAccount = ({ item, query }) => {
  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      w="100%"
    >
      <MFlex flexDir="column" w="100%">
        <Highlighter
          title={item.accountName}
          color="tPurple.dark"
          fontSize="sm"
          fontWeight="medium"
          textToHighlight={item.accountName}
          searchWords={[query]}
        />
        <Highlighter
          title={item.id}
          color="tGray.darkPurple"
          fontSize="xs"
          textToHighlight={item.id}
          searchWords={[query]}
        />
        <Highlighter
          title={item.customId}
          color="tGray.darkPurple"
          fontSize="xs"
          textToHighlight={item.customId}
          searchWords={[query]}
        />
      </MFlex>
    </MFlex>
  );
};

export const NewQuoteFormAccountSelect = React.forwardRef<
  any,
  NewQuoteFormAccountSelectProps
>(
  (
    {
      setValue,
      isExistingContractSelected,
      onClearAccountValue,
      onChange,
      ...rest
    }: NewQuoteFormAccountSelectProps,
    ref,
  ) => {
    const [accountNameFilter, setAccountNameFilter] = useState<string>('');
    const [accountFilterParams, setAccountFilterParams] =
      useState<GetListApiFilter>();
    const [previousAccountList, setPreviousAccountList] = useState<
      IAccountDetails[]
    >([]);
    const debouncedAccountNameFilter = useDebounce(accountNameFilter);

    useEffect(() => {
      if (debouncedAccountNameFilter) {
        setAccountFilterParams({
          accountName: { contains: debouncedAccountNameFilter },
        });
        // Reset previousAccountList when searching
        setPreviousAccountList([]);
      } else {
        setAccountFilterParams(undefined);
        // Reset previousAccountList when clearing search
        setPreviousAccountList([]);
      }
    }, [debouncedAccountNameFilter]);

    const onChangeAccountNameFilter = (newAccountFilter: string) => {
      setAccountNameFilter(newAccountFilter);
    };

    const { isFetching: isLoadingAccounts, data: accountList } =
      useGetAccountList({
        config: {
          ...DEFAULT_PAGER,
          sortField: 'accountName',
          sortOrder: 1,
          rows: 50,
        },
        filters: { ...accountFilterParams, status: AccountStatusEnum.ACTIVE },
        options: {
          staleTime: 60 * 1000 * 1,
          placeholderData: (previousData, previousQuery) => previousData,
        },
      });

    const hasMoreDataToCall =
      accountList &&
      accountList?.pageable?.pageNumber! < accountList?.totalPages - 1;

    function onNextCall() {
      if (hasMoreDataToCall) {
        // Only add new accounts during pagination
        setPreviousAccountList((prevState) => {
          const newAccounts = accountList.content.filter(
            (newAccount) =>
              !prevState.some(
                (prevAccount) => prevAccount.id === newAccount.id,
              ),
          );
          return [...prevState, ...newAccounts];
        });
        setAccountFilterParams({
          ...accountFilterParams,
          currentPage: accountList?.pageable?.pageNumber! + 1,
        });
      }
    }

    return (
      <MCustomSelect
        ref={ref}
        clearable
        onClear={onClearAccountValue}
        itemTitle="accountName"
        itemValue="id"
        useExternalQuery
        externalQuery={accountNameFilter}
        onChangeQuery={onChangeAccountNameFilter}
        items={[...previousAccountList, ...(accountList?.content || [])]}
        onNextCall={onNextCall}
        hasMoreDataToCall={hasMoreDataToCall}
        useMainInputAsSearchInput
        MainInputComponent={MainSearchInput}
        returnItem
        loading={isLoadingAccounts}
        onChange={(event) => {
          const account = event as unknown as IAccount;
          onChange(account);
          setValue('currency', account.defaultCurrency);
          if (!isExistingContractSelected) {
            setValue('legalEntityId', account.defaultLegalEntityId);
            setValue('currency', account.defaultCurrency);
          }
        }}
        renderItemContent={renderItemContentForAccount}
        {...rest}
        isSelectOpen={false}
      />
    );
  },
);
