import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  thead: {
    th: {
      fontWeight: 600,
      textTransform: 'none',
      letterSpacing: 'normal',
      fontSize: 'sm',
    },
  },
  tr: {
    borderBottom: '1px solid transparent',
    _selected: {
      backgroundColor: 'tWhite.titanWhite',
    },
    _hover: {
      backgroundColor: 'tWhite.titanWhite',
    },
  },
});
const previewStyle = definePartsStyle({
  table: {
    background: 'tGray.lightBack',
    borderRadius: '4px',
  },
  thead: {
    th: {
      fontWeight: 600,
      textTransform: 'none',
      letterSpacing: 'normal',
      fontSize: '10px',
      color: 'tPurple.base',
      padding: 1,
    },
    tr: {
      borderBottom: '1px solid',
      borderColor: 'tGray.light',
      _hover: {
        background: 'transparent',
      },
    },
  },
  tbody: {
    td: {
      fontWeight: 'normal',
      textTransform: 'none',
      letterSpacing: 'normal',
      fontSize: '9px',
      color: 'tPurple.base',
      padding: 1,
    },
  },
  tr: {
    borderBottom: '1px solid transparent',
    _hover: {
      background: 'transparent',
    },
  },
});

export const tableTheme = defineMultiStyleConfig({
  variants: {
    preview: previewStyle,
    default: baseStyle,
  },
  baseStyle,
  defaultProps: {
    variant: 'default',
  },
});

export default tableTheme;
