import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '../../api/axios';
import {
  doArchiveQuoteService,
  doCancelQuoteService,
  doCloneQuote,
  doPrintQuoteToPdf,
  doRetractQuoteService,
} from '../../api/cpqService';
import { ROLES, ROUTES } from '../../constants';
import {
  QUOTE_ARCHIVE_ACTION_STATUSES,
  QUOTE_COPYABLE_STATUSES,
  QUOTE_READONLY_STATUSES,
} from '../../constants/quotes';
import { useQuote } from '../../hooks';
import { PreviewQuotePdfDrawerWrapper } from '../../routes/Quotes/Quote/components/PreviewQuotePdfDrawerWrapper';
import { ManualQuoteAcceptanceModal } from '../../routes/Quotes/Quote/ManualQuoteAcceptanceModal';
import ManualRenewalModal from '../../routes/Quotes/Quote/ManualRenewalModal';
import { useACL } from '../../services/acl/acl';
import { useAuth } from '../../services/auth0';
import { useConfirmModal } from '../../services/confirmModal';
import { useFlags } from '../../services/launchDarkly';
import { logger } from '../../services/logger';
import { useToast } from '../../services/toast';
import {
  INewQuoteTypeReqSchema,
  IQuoteBasicRespSchema,
  IQuoteItem,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  NewQuoteTypeEnum,
  NoteMilestone,
  QuoteCancelationReasonEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
  UserRoleEnum,
} from '../../types';
import { downloadBlobAsFile } from '../../utils/download';
import DataTableActions, {
  ActionType,
} from '../Monetize/DataTable/MDataTableActions';
import { NotesDrawerV2 } from '../Notes/NotesDrawerV2';

const getNotesMilestones = (
  quote: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>,
): NoteMilestone[] => {
  const output: NoteMilestone[] = [];

  if (quote.approvalSubmittedAt) {
    output.push({
      date: quote.approvalSubmittedAt,
      label: 'Approval Submitted',
    });
  }

  if (quote.declinedDate) {
    output.push({
      date: quote.declinedDate,
      label: 'Approval Declined',
    });
  }

  if (quote.approvalGrantedAt) {
    output.push({
      date: quote.approvalGrantedAt,
      label: 'Approval Granted',
    });
  }

  if (quote.acceptedAt) {
    output.push({ date: quote.acceptedAt, label: 'Accepted' });
  }

  if (quote.processedAt) {
    output.push({ date: quote.processedAt, label: 'Processed' });
  }
  return output;
};

type QuoteActionProps = {
  quote: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>;
  isQuotesList?: boolean;
  manualRenewalFormMethods?: UseFormReturn<INewQuoteTypeReqSchema, object>;
  loadData: () => void;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  isReadOnly?: boolean;
  handleEditQuoteClick?: () => void;
  canEditQuote?: boolean;
  editQuoteLoading?: boolean;
};

export const QuoteActionsV2: FC<QuoteActionProps> = ({
  quote,
  isQuotesList,
  manualRenewalFormMethods,
  loadData,
  setQuote,
  isReadOnly,
  handleEditQuoteClick,
  canEditQuote,
  editQuoteLoading,
}: QuoteActionProps) => {
  const { hideMarkAsRenewal, useQuotePdfV2, quoteAcceptanceAllowedRoles } =
    useFlags();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { transitionQuoteStatus } = useQuote();
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();
  const {
    onOpen: onOpenManualAcceptanceModal,
    onClose: onCloseManualAcceptanceModal,
    isOpen: isOpenManualAcceptanceModal,
  } = useDisclosure();
  const {
    onOpen: onOpenPreviewPdfDrawer,
    onClose: onClosePreviewPdfDrawer,
    isOpen: isPreviewPdfDrawerOpen,
  } = useDisclosure();
  const {
    onOpen: onOpenManualRenewalModal,
    onClose: onCloseManualRenewalModal,
    isOpen: isOpenManualRenewalModal,
  } = useDisclosure();
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);
  const canReadSales = canDo([['sales', 'read']]);
  const { currentTenantUser, currentTenantUserHasRole } = useAuth();

  const canAcceptQuote =
    !quoteAcceptanceAllowedRoles?.length ||
    quoteAcceptanceAllowedRoles[0] === 'ALL' ||
    currentTenantUserHasRole(quoteAcceptanceAllowedRoles as UserRoleEnum[]);

  if (!quote) {
    return null;
  }

  const isAmendment = quote.type === QuoteTypeEnum.AMENDMENT;
  const { requiresEsign } = quote;

  // Check if the quote Item amendStatus is NO_CHANGE
  const ifAmendStatusIsNoChange = quote?.quoteOfferings
    ?.reduce(
      (acc: IQuoteItem[], curr: IQuoteOfferingRespSchema) =>
        curr.items ? [...acc, ...curr.items] : acc,
      [] as IQuoteItem[],
    )
    .every(
      (x: IQuoteItem) =>
        x.amendmentStatus === QuoteItemAmendmentStatusEnum.NO_CHANGE,
    );

  const handleCancelQuote = (
    data: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>,
  ) => {
    const onYes = async () => {
      setModalLoading(true);
      try {
        data?.id &&
          (await doCancelQuoteService(
            data,
            QuoteCancelationReasonEnum.BY_USER,
          ));
      } catch (err) {
        logger.error(err);
      }
      setModalLoading(false);
      loadData();
      closeModal();
    };

    showConfirmModal({
      title: `Are you sure you want to cancel "${data.description}"?`,
      description:
        'A Quote cancelation cannot be undone, you can always create a new quote if needed.',
      onYes,
      yesBtnProps: {
        variant: 'delete' as any,
      },
      noBtnProps: {
        variant: 'cancel' as any,
      },
      yesButton: 'Confirm',
      noButton: 'Close',
    });
  };

  const handleArchiveQuote = (
    data: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>,
  ) => {
    const onYes = async () => {
      try {
        setModalLoading(true);
        data?.id && (await doArchiveQuoteService(data.id));
      } catch (error) {
        handleApiErrorToast(error);
      }
      setModalLoading(false);
      loadData();
      closeModal();
    };

    showConfirmModal({
      title: `Are you sure you want to archive Quote for "${data.description}"?`,
      description:
        'This cannot be undone and this Quote will not be returned in a list of results going forward.',
      onYes,
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
      yesButton: 'Archive',
      noButton: 'Cancel',
    });
  };

  const handleAcceptQuote = () => {
    onOpenManualAcceptanceModal();
  };

  const handleManualRenewal = () => {
    onOpenManualRenewalModal();
  };

  const handleProvisionQuote = async () => {
    const onYes = async () => {
      setModalLoading(true);
      try {
        await transitionQuoteStatus(quote.id, {
          newState: QuoteStatusEnum.ACCEPTED_ADMIN_EDITABLE,
        });
      } catch (err) {
        // pass
      }
      setModalLoading(false);
      loadData();
      closeModal();
    };

    showConfirmModal({
      title: 'Are you sure you want to edit this quote?',
      description:
        'Proceed with Caution: You are updating this information at your own risk. Please double-check all changes to ensure accuracy before submission.',
      onYes,
      noBtnProps: {
        variant: 'cancel',
      },
      yesButton: 'Edit',
      noButton: 'Close',
    });
  };

  const handleRetractQuote = async () => {
    const onYes = async () => {
      setModalLoading(true);
      try {
        quote.id && (await doRetractQuoteService(quote.id));
      } catch (err) {
        // pass
      }
      setModalLoading(false);
      loadData();
      closeModal();
    };

    showConfirmModal({
      title: 'Are you sure you want to retract this quote?',
      description:
        'Retracting a Quote will invalidate the eSign Document, you can always resend the quote for signature',
      onYes,
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
      yesButton: 'Retract',
      noButton: 'Close',
    });
  };

  const handlePrintToPdf = async () => {
    try {
      const resp = await doPrintQuoteToPdf(quote.id, useQuotePdfV2);

      downloadBlobAsFile(resp, `${quote.description}.pdf`);
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  const handleCloneQuote = async (quoteId: string) => {
    if (quoteId) {
      try {
        const quoteResponse = await doCloneQuote(quoteId);

        if (quoteResponse?.id) {
          navigate(ROUTES.getQuoteEditRoute(quoteResponse.id));

          addToast({
            summary: 'New Quote Created',
            detail: `${quoteResponse.description} has successfully been created.`,
            severity: 'success',
          });
        }
      } catch (err) {
        handleApiErrorToast(err);
      }
    }
  };

  const hasValidRole = currentTenantUserHasRole(ROLES.QUOTES_ROLES);

  if (!hasValidRole) {
    return null;
  }

  const canCancel =
    !isQuotesList &&
    !QUOTE_READONLY_STATUSES.has(quote.status) &&
    canUpdateSales;

  const actions: ActionType[] = [
    {
      title: 'Copy Quote',
      enabled: QUOTE_COPYABLE_STATUSES.has(quote.status) && canUpdateSales,
      action: () => handleCloneQuote(quote.id),
    },
    {
      title: 'Preview PDF',
      enabled: canReadSales,
      action: () => onOpenPreviewPdfDrawer(),
    },
    {
      title: 'Download PDF',
      enabled: canReadSales,
      action: () => handlePrintToPdf(),
    },
    {
      actionElement: (
        <NotesDrawerV2
          id={quote.id}
          entity="quotes"
          milestones={getNotesMilestones(quote)}
          disabled={!quote}
          key={'Notes'}
        />
      ),
      enabled: !isQuotesList && !!quote.id,
    },
    {
      title: 'Retract E-sign',
      enabled:
        !isQuotesList &&
        requiresEsign &&
        quote.status === QuoteStatusEnum.SENT &&
        canUpdateSales,
      action: () => handleRetractQuote(),
    },
    {
      title: 'Manually Accept',
      enabled:
        !isQuotesList &&
        quote.status === QuoteStatusEnum.SENT &&
        !(isAmendment && ifAmendStatusIsNoChange) &&
        !requiresEsign &&
        canAcceptQuote &&
        canUpdateSales,
      action: () => handleAcceptQuote(),
    },
    // TODO: Need resend email api from BE
    // {
    //   title: 'Resend Email',
    //   enabled:
    //     quote.status === QuoteStatusEnum.SENT &&
    //     requiresEsign &&
    //     canUpdateSales,
    //   action: () => handleResendQuote(),
    // },
    // {
    //   title: 'Resend E-sign',
    //   loading: itemLoading === 'esign',
    //   enabled:
    //     !isQuotesList &&
    //     requiresEsign &&
    //     quote.status === QuoteStatusEnum.SENT,
    //   action: () => handleResendQuote(),
    // },
    {
      title: 'Mark as Renewal',
      enabled:
        !isQuotesList &&
        !hideMarkAsRenewal &&
        quote.type === QuoteTypeEnum.NEW &&
        quote.status === QuoteStatusEnum.DRAFT &&
        quote.newQuoteType !== NewQuoteTypeEnum.MANUAL_RENEWAL &&
        canUpdateSales,
      action: () => handleManualRenewal(),
    },
    {
      title: 'Edit Quote (Admin Only)',
      enabled:
        !isQuotesList &&
        quote.status === QuoteStatusEnum.ACCEPTED &&
        canUpdateSales,
      action: () => handleProvisionQuote(),
    },
    // Danger items should always remain at the bottom
    {
      title: 'Cancel',
      color: 'danger',
      enabled: canCancel,
      hasTopDivider: true,
      action: () => handleCancelQuote(quote),
    },
    {
      title: 'Archive',
      enabled:
        QUOTE_ARCHIVE_ACTION_STATUSES.has(quote.status) &&
        canUpdateSales &&
        quote.ownerId === currentTenantUser.id,
      hasTopDivider: !canCancel,
      action: () => handleArchiveQuote(quote),
    },
  ];

  return (
    <>
      <DataTableActions
        actions={actions}
        iconContainerProps={{ minH: '8', minW: '8', btnSize: 6 }}
      />
      <ManualQuoteAcceptanceModal
        isOpen={isOpenManualAcceptanceModal}
        quoteId={quote.id}
        onClose={() => {
          onCloseManualAcceptanceModal();
          loadData();
        }}
      />

      {isPreviewPdfDrawerOpen && (
        <PreviewQuotePdfDrawerWrapper
          quote={quote}
          onClose={() => onClosePreviewPdfDrawer()}
        />
      )}

      {manualRenewalFormMethods && (
        <ManualRenewalModal
          isOpen={isOpenManualRenewalModal}
          quoteId={quote.id}
          manualRenewalForm={manualRenewalFormMethods}
          setQuote={setQuote}
          onClose={onCloseManualRenewalModal}
        />
      )}
    </>
  );
};
