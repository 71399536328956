import { BoxProps } from '@chakra-ui/react';
import isNumber from 'lodash/isNumber';
import uniqueId from 'lodash/uniqueId';
import {
  forwardRef,
  KeyboardEvent,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useNonInitialEffect } from '../../../hooks/useNonInitialEffect';
import { isMac } from '../../../utils/misc';
import { MBox, MFlex } from '../chakra';
import {
  getTimPickerInitialState,
  SegmentType,
  timePickerReducer,
} from './date-picker.utils';

interface TimePickerInputProps {
  isDisabled?: boolean;
  initialValue?: Date | null; // TODO: is date the right initial value?
  onChange: (hour: number, minute: number, amPm: 'AM' | 'PM') => void;
  onEnter?: () => void;
}

export const TimePickerInput = forwardRef(
  (
    { isDisabled, initialValue, onChange, onEnter }: TimePickerInputProps,
    ref,
  ) => {
    const [id] = useState(() => uniqueId('time-picker-'));
    const [{ isValid, hour, minute, amPm }, dispatch] = useReducer(
      timePickerReducer,
      getTimPickerInitialState(initialValue),
    );

    useEffect(() => {
      if (initialValue) {
        dispatch({ type: 'INIT', payload: { value: initialValue } });
      }
    }, [initialValue]);

    useNonInitialEffect(() => {
      if (onChange && isValid) {
        onChange(hour.value, minute.value, amPm.textValue as 'AM' | 'PM');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid, hour, minute, amPm]);

    const handleChange = (type: SegmentType, value: number | null) => {
      if (value === null) {
        dispatch({ type: 'CLEAR', payload: { type } });
      } else {
        dispatch({ type: 'UPDATE', payload: { type, value } });
      }
    };

    return (
      <MFlex
        id={id}
        // aria-labelledby="react-aria8565130468-46" // TODO:
        role="group"
        display="inline-flex"
        border="1px solid"
        borderColor="tGray.lightPurple"
        borderRadius={3}
        _focusWithin={{
          borderColor: 'tBlue.lightShade',
        }}
        mt={2}
        py={1}
        px={2}
      >
        <TimePickerSegment
          isDisabled={isDisabled}
          isEditable
          type={hour.type}
          minValue={hour.min}
          maxValue={hour.max}
          value={hour.value}
          textValue={hour.textValue}
          placeholder={hour.placeholder}
          showPlaceholder={hour.showPlaceholder}
          onChange={handleChange}
          onEnter={onEnter}
        />
        <TimePickerSegment
          isDisabled={isDisabled}
          textValue=":"
          type="placeholder"
        />
        <TimePickerSegment
          isDisabled={isDisabled}
          isEditable
          type={minute.type}
          minValue={minute.min}
          maxValue={minute.max}
          value={minute.value}
          textValue={minute.textValue}
          placeholder={minute.placeholder}
          showPlaceholder={minute.showPlaceholder}
          onChange={handleChange}
          onEnter={onEnter}
        />
        <TimePickerSegment
          isDisabled={isDisabled}
          textValue=" "
          type="placeholder"
        />
        <TimePickerSegment
          isDisabled={isDisabled}
          isEditable
          type={amPm.type}
          minValue={amPm.min}
          maxValue={amPm.max}
          value={amPm.value}
          textValue={amPm.textValue}
          placeholder={amPm.placeholder}
          showPlaceholder={amPm.showPlaceholder}
          onChange={handleChange}
          onEnter={onEnter}
        />
      </MFlex>
    );
  },
);

interface TimePickerSegmentEditableProps {
  type: SegmentType;
  isEditable: true;
  showPlaceholder: boolean;
  minValue: number;
  maxValue: number;
  value: number;
  textValue: string;
  placeholder: string;
  isDisabled?: boolean;
  onChange?: (type: SegmentType, value: number | null) => void;
  onEnter?: () => void;
}

interface TimePickerSegmentPlaceholderProps {
  type: 'placeholder';
  isEditable?: false;
  showPlaceholder?: false;
  minValue?: never;
  maxValue?: never;
  value?: never;
  textValue: string;
  placeholder?: never;
  isDisabled?: boolean; // TODO:
  onKeyDown?: never;
  onChange?: never;
  onEnter?: never;
}

type TimePickerSegmentProps =
  | TimePickerSegmentEditableProps
  | TimePickerSegmentPlaceholderProps;

/**
 * TODO: disabled state
 * AM/PM
 */
const TimePickerSegment = ({
  type,
  isEditable = false,
  placeholder,
  minValue,
  maxValue,
  showPlaceholder,
  value,
  textValue,
  isDisabled,
  onChange,
  onEnter,
}: TimePickerSegmentProps) => {
  const ref = useRef<any>(null);
  const enteredKeys = useRef('');
  const compositionRef = useRef('');
  let boxProps: BoxProps = {};

  const handleKeydown = (ev: KeyboardEvent) => {
    if (ev.key === 'a' && (isMac() ? ev.metaKey : ev.ctrlKey)) {
      ev.preventDefault();
    }

    if (ev.ctrlKey || ev.metaKey || ev.shiftKey || ev.altKey) {
      return;
    }

    switch (ev.key) {
      case 'ArrowUp': {
        if (type === 'amPm') {
          onChange && onChange(type, value === 0 ? 12 : 0);
        } else {
          let newValue = value;
          if (!isNumber(value)) {
            newValue = minValue;
          } else {
            newValue = value + 1;
          }
          if (isNumber(maxValue) && isNumber(newValue) && newValue > maxValue) {
            newValue = minValue;
          }
          onChange && onChange(type, newValue!);
        }
        break;
      }
      case 'ArrowDown': {
        if (type === 'amPm') {
          onChange && onChange(type, value === 0 ? 12 : 0);
        } else {
          let newValue = value;
          if (!isNumber(value)) {
            newValue = maxValue;
          } else {
            newValue = value - 1;
          }
          if (isNumber(minValue) && isNumber(newValue) && newValue < minValue) {
            newValue = maxValue;
          }
          onChange && onChange(type, newValue!);
        }
        break;
      }
      case 'Enter': {
        ev.preventDefault();
        ev.stopPropagation();
        onEnter && onEnter();
        break;
      }
      case 'Backspace':
      case 'Delete': {
        // Safari on iOS does not fire beforeinput for the backspace key because the cursor is at the start.
        ev.preventDefault();
        ev.stopPropagation();
        onBackspace();
        break;
      }
    }
  };

  const onInput = (key: string) => {
    if (isDisabled) {
      return;
    }
    const newValue = (enteredKeys.current + key).trim();
    switch (type) {
      case 'amPm': {
        if (key.toLowerCase().startsWith('a')) {
          onChange && onChange(type, 0);
        } else if (key.toLowerCase().startsWith('p')) {
          onChange && onChange(type, 12);
        }
        break;
      }
      case 'hour':
      case 'minute': {
        const numberValue = Number(newValue);
        let segmentValue = numberValue;

        if (numberValue > maxValue) {
          segmentValue = Number(key);
        }

        if (isNaN(numberValue)) {
          return;
        }

        if (segmentValue >= minValue && segmentValue <= maxValue) {
          enteredKeys.current = newValue;
          onChange && onChange(type, segmentValue);
        }

        if (
          numberValue > maxValue ||
          newValue.length > String(maxValue).length
        ) {
          enteredKeys.current = '';
        }

        break;
      }
      default:
        break;
    }
  };

  const onBackspace = () => {
    if (isDisabled) {
      return;
    }
    if (/^[0-9]+$/.test(textValue)) {
      const newValue = textValue.slice(0, -1);
      const parsedValue = Number(newValue);
      if (newValue.length === 0 || parsedValue === 0) {
        onChange && onChange(type, null);
      } else {
        onChange && onChange(type, parsedValue);
      }
      enteredKeys.current = newValue;
    } else if (type === 'amPm') {
      onChange && onChange(type, null);
    }
  };

  const handleBeforeInput = (ev: any) => {
    ev.preventDefault();
    switch (ev.inputType) {
      case 'deleteContentBackward':
      case 'deleteContentForward':
        if (!isDisabled && /^[0-9]+$/.test(textValue)) {
          onBackspace();
        }
        break;
      case 'insertCompositionText':
        // insertCompositionText cannot be canceled.
        // Record the current state of the element so we can restore it in the `input` event below.
        compositionRef.current = ref.current.textContent;

        // Safari gets stuck in a composition state unless we also assign to the value here.
        // eslint-disable-next-line no-self-assign
        ref.current.textContent = ref.current.textContent;
        break;
      default:
        if (ev.data != null && ev.data !== ' ' && ev.data !== '\n') {
          onInput(ev.data);
        }
        break;
    }
  };

  const handleInput = (ev: any) => {
    const { inputType, data } = ev;
    switch (inputType) {
      case 'insertCompositionText':
        // Reset the DOM to how it was in the beforeinput event.
        ref.current.textContent = compositionRef.current;

        // Android sometimes fires key presses of letters as composition events. Need to handle am/pm keys here too.
        if (
          data.toLowerCase().startsWith('a') ||
          data.toLowerCase().startsWith('p')
        ) {
          onInput(data);
        }
        break;
    }
  };

  if (isEditable) {
    boxProps = {
      onKeyDown: handleKeydown,
      onInput: handleInput,
      onBeforeInput: handleBeforeInput,
      onFocus: () => {
        enteredKeys.current = '';
        // Collapse selection to start or Chrome won't fire input events.
        const selection = window.getSelection();
        selection?.collapse(ref.current);
      },
      contentEditable: true,
      spellCheck: 'false',
      autoCapitalize: 'off',
      autoCorrect: 'off',
      inputMode: 'numeric',
      tabIndex: 0,
      minW: `${(placeholder || textValue).length}ch`,
    };
  }

  return (
    <MBox
      ref={ref}
      role="spinbutton"
      aria-valuenow={isEditable ? value : undefined}
      aria-valuetext="Empty"
      aria-valuemin={minValue ?? undefined}
      aria-valuemax={maxValue ?? undefined}
      // id="react-aria8565130468-49" // TODO:
      aria-label="hour"
      suppressContentEditableWarning
      {...boxProps}
      px={0.5}
      boxSizing="content-box"
      textAlign="right"
      outline="none"
      borderRadius={3}
      color="tPurple.dark"
      _focus={{
        bg: 'tIndigo.base',
        color: 'white',
      }}
      style={{
        fontVariantNumeric: 'tabular-nums',
        caretColor: 'transparent',
      }}
    >
      <MBox
        as="span"
        aria-hidden="true"
        pointerEvents="none"
        display="block"
        w="100%"
        textAlign="center"
        fontStyle="italic"
        height={showPlaceholder ? undefined : '0px'}
        visibility={showPlaceholder ? 'visible' : 'hidden'}
        _groupFocus={{
          color: 'white',
        }}
      >
        {placeholder}
      </MBox>
      {showPlaceholder ? '' : textValue}
    </MBox>
  );
};
