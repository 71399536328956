import { object, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { AddressSchema } from './addressTypes';
import { ContactRespSchema } from './contactTypes';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { BaseResponseSchema, EmailTextAreaTypeSchema } from './miscTypes';

export enum AccountStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  SUSPENDED = 'SUSPENDED',
  INACTIVE = 'INACTIVE',
}
export const AccountStatusEnumZ = z.nativeEnum(AccountStatusEnum);

export const AccountSchema = object({
  customId: z.string().max(50).nullish(),
  status: AccountStatusEnumZ,
  isTestAccount: z.boolean(),
  // locale: z.string().max(15).nonempty("Locale can't be empty."),
  accountName: z
    .string({
      required_error: getRequiredMessage('Account Name'),
    })
    .min(1, getRequiredMessage('Account Name')),
  defaultLegalEntityId: z.string({
    required_error: getRequiredMessage('Legal Entity'),
  }),
  defaultCurrency: z.string({
    required_error: getRequiredMessage('Default Currency'),
  }),
  defaultDunningProcessId: z.string().nullish(),
  taxEntityUseCode: z.string().nullish(),
  taxExemptionNumber: z.string().nullish(),
  customFields: CustomFieldRecordSchema,
  shippingAddress: AddressSchema.nullish(),
  billingAddress: AddressSchema.nullish(),
  ccEmails: EmailTextAreaTypeSchema,
});

export type IAccountSchema = z.infer<typeof AccountSchema>;

export const AccountSummarySchema = object({
  id: z.string(),
  customId: z.string().nullish(),
  isTestAccount: z.boolean(),
  accountName: z.string(),
  defaultCurrency: z.string(),
  status: AccountStatusEnumZ,
  defaultLegalEntityId: z.string(),
});

export type AccountSummary = z.infer<typeof AccountSummarySchema>;

// there's a good chance these 2 enums are actually shared across schemas and can then be moved miscTypes file:
export enum AccountHistoryObjectTypeEnum {
  ACCOUNT = 'ACCOUNT',
  BILL_GROUP = 'BILL_GROUP',
  CONTACT = 'CONTACT',
  TEAM = 'TEAM',
  SUBSCRIPTION = 'SUBSCRIPTION',
}
export enum AccountHistoryOperationEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface IAccountHistoryResp {
  id: string;
  createDate: string;
  createdBy: string;
  message: string;
  objectId: string;
  operation: AccountHistoryOperationEnum;
  objectType: AccountHistoryObjectTypeEnum;
}

export const AccountRespSchema = BaseResponseSchema.extend({
  customId: string().nullish(),
  isTestAccount: z.boolean(),
  locale: string().nullish(),
  accountName: string(),
  /** @deprecated */
  currency: string(),

  status: AccountStatusEnumZ,
  primaryContact: ContactRespSchema.nullish(),

  defaultLegalEntityId: z.string(),
  defaultCurrency: z.string(),
  taxEntityUseCode: z.string().nullish(),
  taxExemptionNumber: z.string().nullish(),

  customFields: CustomFieldRecordSchema,

  shippingAddress: AddressSchema.nullish(),
  billingAddress: AddressSchema.nullish(),
});

export type IAccountRespSchema = z.infer<typeof AccountRespSchema>;
export type IAccount = IAccountRespSchema;
