import { UseFormReturn } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import {
  MBox,
  MButton,
  MCenter,
  MCircularProgress,
  MCustomIconButton,
  MFlex,
} from '../../../../../components/Monetize';
import { QuoteOfferingConfigurationDrawer } from '../../../../../components/Quotes/QuoteOfferingConfigurationDrawer';
import { logger } from '../../../../../services/logger';
import {
  IOfferingRes,
  IQuoteOfferingRemoveReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResSchema,
  OfferingTypesEnum,
  PriceDisplayEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteOfferingProps,
  QuoteOfferingRemovalScopeEnum,
  QuoteOfferingState,
  RateBillingFrequencyEnum,
} from '../../../../../types';
import { useQuoteContext } from '../../quoteContext';
import { QuoteOfferingAmount } from './QuoteOfferingAmount';
import { prepareQuoteOfferingRequestDataFromResponse } from './quoteOfferingUtils';

export interface QuoteOfferingActionsProps
  extends Pick<
    QuoteOfferingProps,
    | 'quoteOffering'
    | 'isChildOffering'
    | 'childQuoteOfferings'
    | 'onChange'
    | 'parentQuoteOffering'
  > {
  quoteOfferingState: QuoteOfferingState;
  offering?: IOfferingRes | null;
  quoteOfferingLoading: boolean;
  isOfferingOpen: boolean;
  updateQuoteOffering: (
    id: string,
    data: IQuoteOfferingReqSchema,
  ) => Promise<IQuoteOfferingRespSchema | null>;
  deleteQuoteOffering: (id: string) => Promise<void>;
  removeQuoteOffering: (
    data: IQuoteOfferingRemoveReqSchema,
  ) => Promise<IQuoteRespSchema | null>;
  quoteOfferingFormState: UseFormReturn<IQuoteOfferingReqSchema>;
  handleQuoteOfferingUpdate: ({
    priceDisplay,
  }: {
    priceDisplay?: PriceDisplayEnum;
  }) => void;
  offeringRate: IRateResSchema | null;
}

/**
 * Manages the quote offering menu based on the state of the quote offering
 */
export const QuoteOfferingActions = ({
  quoteOffering,
  isChildOffering,
  childQuoteOfferings = [],
  onChange: onOfferingChange,
  parentQuoteOffering,
  quoteOfferingState,
  offering,
  quoteOfferingLoading,
  isOfferingOpen,
  updateQuoteOffering,
  deleteQuoteOffering,
  removeQuoteOffering,
  quoteOfferingFormState,
  handleQuoteOfferingUpdate,
  offeringRate,
}: QuoteOfferingActionsProps) => {
  const { isNewQuoteOfferingForm, isRemoved } = quoteOfferingState;

  const {
    productOfferingsData,
    quoteData,
    quoteStateData,
    isLoading,
    isReadOnly,
    useAmendmentV2,
  } = useQuoteContext();
  const { quote, displayConfig, setQuote } = quoteData;

  const { offeringLoadingState, setOfferingLoadingState } = quoteStateData;

  const isOfferingDeleting =
    offeringLoadingState[quoteOffering?.id || '']?.isDeleting ||
    offeringLoadingState[parentQuoteOffering?.id || '']?.isDeleting ||
    false;
  const isOfferingLoading =
    offeringLoadingState[quoteOffering?.id || '']?.isLoading ||
    offeringLoadingState[parentQuoteOffering?.id || '']?.isLoading ||
    false;
  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  const showQuoteOfferingConfDrawer =
    quoteOffering &&
    quoteOffering.billingFrequency !== RateBillingFrequencyEnum.ONETIME &&
    offering &&
    ![
      OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL,
      OfferingTypesEnum.MIN_COMMIT,
    ].includes(offering.type);

  const { watch, setValue, getValues } = quoteOfferingFormState;
  const watchOffering = watch('offeringId');

  /**
   * handles deletion of quote offering with child offerings
   */
  const handleDelete = async () => {
    try {
      if (quoteOffering?.id) {
        setOfferingLoadingState((prevValue) => ({
          ...prevValue,
          [quoteOffering.id]: {
            id: quoteOffering.id,
            isLoading: false,
            isDeleting: true,
          },
        }));
        if (useAmendmentV2) {
          await removeQuoteOffering({
            removal_scope: QuoteOfferingRemovalScopeEnum.TARGET,
            target_id: quoteOffering.id,
          });
        } else {
          await deleteQuoteOffering(quoteOffering.id);
        }
        /**
         * Making sure to manually update the quote data after
         * deleting quote offering successfully
         */
        if (quote) {
          const quoteOfferingsToDelete = quote.quoteOfferings.filter(
            ({ id, parentQuoteOfferingId, items }) =>
              (id === quoteOffering.id ||
                parentQuoteOfferingId === quoteOffering.id) &&
              items.find(
                ({ amendmentStatus }) =>
                  amendmentStatus === QuoteItemAmendmentStatusEnum.ADDED,
              ),
          );
          await onOfferingChange('DELETE', quoteOfferingsToDelete);
        }

        setOfferingLoadingState((prevValue) => {
          const value = { ...prevValue };
          delete value[quoteOffering.id];
          return value;
        });
      }
    } catch (error) {
      logger.error('Error while deleting quote offering ====>', error);
    }
  };

  /**
   * handles restoration of quote offering with child offerings
   */
  const handleRestore = async () => {
    try {
      if (quoteOffering?.id) {
        setOfferingLoadingState((prevValue) => ({
          ...prevValue,
          [quoteOffering.id]: {
            id: quoteOffering.id,
            isLoading: true,
            isDeleting: true,
          },
        }));

        for (const childQuoteOffering of childQuoteOfferings) {
          const quoteOfferingReq =
            prepareQuoteOfferingRequestDataFromResponse(childQuoteOffering);
          await updateQuoteOffering(childQuoteOffering.id, quoteOfferingReq);
        }

        const newOfferingResponse = await updateQuoteOffering(
          quoteOffering.id,
          getValues(),
        );

        if (newOfferingResponse) {
          await onOfferingChange('UPDATE', newOfferingResponse);
        }

        setOfferingLoadingState((prevValue) => {
          const value = { ...prevValue };
          delete value[quoteOffering.id];
          return value;
        });
      }
    } catch (error) {
      logger.error('Error while restoring quote offering ====>', error);
    }
  };

  return (
    <>
      {!isChildOffering && !isNewQuoteOfferingForm && (
        <>
          {!isRemoved ? (
            <>
              {!isOfferingOpen && (
                <QuoteOfferingAmount
                  childQuoteOfferings={childQuoteOfferings}
                  quoteOffering={quoteOffering}
                />
              )}
              {showQuoteOfferingConfDrawer && (
                <QuoteOfferingConfigurationDrawer
                  isDisabled={quoteOfferingLoading || anyOfferingLoading}
                  onOfferingChange={onOfferingChange}
                  quoteOffering={quoteOffering}
                  isReadOnly={isReadOnly}
                  handleQuoteOfferingUpdate={handleQuoteOfferingUpdate}
                  offering={offering}
                  offeringRate={offeringRate}
                  quote={quote}
                  isOfferingLoading={isOfferingLoading}
                />
              )}

              <MCustomIconButton
                variant="icon"
                btnSize={4}
                containerSize={6}
                p={3}
                icon={BsTrash}
                onClick={handleDelete}
                isDisabled={quoteOfferingLoading || isReadOnly}
              />

              {isOfferingDeleting && (
                <MBox
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  backgroundColor="white"
                  opacity={0.75}
                >
                  <MCenter height="100%" justifyContent="flex-end" pr="2">
                    <MCircularProgress isIndeterminate size={4} />
                  </MCenter>
                </MBox>
              )}
            </>
          ) : (
            <>
              {!isChildOffering && !quoteOfferingLoading && (
                <MFlex
                  alignItems="center"
                  alignSelf="flex-start"
                  gridColumn="3/-1"
                  justifySelf="flex-end"
                >
                  <MButton variant="tertiary" size="sm" onClick={handleRestore}>
                    Restore
                  </MButton>
                </MFlex>
              )}
            </>
          )}
        </>
      )}
      {isNewQuoteOfferingForm && watchOffering && (
        <MCustomIconButton
          variant="icon"
          btnSize={4}
          containerSize={6}
          p={3}
          icon={BsTrash}
          onClick={() => setValue('offeringId', '')}
          isDisabled={isLoading || quoteOfferingLoading || isReadOnly}
          mr="1"
        />
      )}
    </>
  );
};
