import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Control, Controller, useForm } from 'react-hook-form';
import {
  MButton,
  MCustomNumberInput,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MText,
  RadioStyleButtonGroup,
} from '../../../../../components/Monetize';
import {
  RATE_BILLING_FREQUENCY_MAP,
  RATE_BILLING_FREQUENCY_NUMBER_MAP,
} from '../../../../../constants/offerings';
import { useFlags } from '../../../../../services/launchDarkly';
import { logger } from '../../../../../services/logger';
import {
  IRateReqSchemaUI,
  IRateSubscriptionFrequencyReqSchema,
  RateBillingFrequencyEnum,
  RateSubscriptionFrequencyReqSchema,
} from '../../../../../types';

export const frequencyOptions = [
  RateBillingFrequencyEnum.MONTHLY,
  RateBillingFrequencyEnum.QUARTERLY,
  RateBillingFrequencyEnum.SEMIANNUALLY,
  RateBillingFrequencyEnum.ANNUALLY,
].map((frequency) => {
  const { btnLabel, month } = RATE_BILLING_FREQUENCY_MAP[frequency]();
  return { label: btnLabel, value: `${month || ''}` };
});

interface RateSubscriptionFrequencyProps {
  data: {
    billingFrequency?: RateBillingFrequencyEnum | null;
    billingFrequencyInMonths?: number | null;
  };
  showContractTerms?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  isDirty?: boolean;
  subscriptionFrequencyItems: Record<'value' | 'title', string>[];
  control: Control<IRateReqSchemaUI>;
  onChange: ({
    billingFrequency,
    billingFrequencyInMonths,
  }: {
    billingFrequency?: RateBillingFrequencyEnum | null;
    billingFrequencyInMonths?: number | null;
  }) => void;
  disableCustomFrequency?: boolean;
}
export const RateSubscriptionFrequency = ({
  data = {},
  onChange: onFormSubmit,
  isDisabled = false,
  isReadOnly = false,
  showContractTerms = false,
  isDirty = false,
  subscriptionFrequencyItems = [],
  control: parentControl,
  disableCustomFrequency = false,
}: RateSubscriptionFrequencyProps) => {
  const { subscriptionFrequencyAdditionalOptions } = useFlags();
  const billingFrequencyMap = data.billingFrequency
    ? RATE_BILLING_FREQUENCY_MAP[data.billingFrequency](
        data.billingFrequencyInMonths,
      )
    : null;
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IRateSubscriptionFrequencyReqSchema>({
    resolver: zodResolver(RateSubscriptionFrequencyReqSchema),
    defaultValues: {
      ...data,
      billingFrequencyInMonths: data.billingFrequencyInMonths
        ? data.billingFrequencyInMonths
        : billingFrequencyMap?.month,
    },
  });
  const billingFrequencyInMonths = watch('billingFrequencyInMonths');

  const { onOpen, onClose, isOpen } = useDisclosure();

  const onSubmit = (formData: IRateSubscriptionFrequencyReqSchema) => {
    onFormSubmit?.(formData);
    onClose();
  };

  const onError = (error: any) =>
    logger.warn('Error while submitting subscription frequency', error);
  const frequencyLabel = billingFrequencyMap?.label;

  const handleFrequency = (frequency: number) => {
    if ([0, 1, 3, 6, 12].includes(frequency)) {
      setValue(
        'billingFrequency',
        RATE_BILLING_FREQUENCY_NUMBER_MAP[frequency]
          .value as RateBillingFrequencyEnum,
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
      setValue('billingFrequencyInMonths', frequency, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('billingFrequency', RateBillingFrequencyEnum.CUSTOM, {
        shouldDirty: true,
        shouldValidate: true,
      });
      setValue('billingFrequencyInMonths', frequency, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  if (isReadOnly || isDisabled) {
    return (
      <MFlex align="center" minH={8}>
        <MText>{frequencyLabel}</MText>
      </MFlex>
    );
  }

  if (!subscriptionFrequencyAdditionalOptions) {
    return (
      <Controller
        name="billingFrequency"
        control={parentControl}
        render={({ field: { onChange, ...field } }) => (
          <MCustomSelect
            inputProps={{
              variant: isReadOnly
                ? 'readonly'
                : isDirty
                  ? 'unsaved'
                  : 'primary',
            }}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            bgColor="tWhite.base"
            placeholder="Billing Frequency"
            items={subscriptionFrequencyItems}
            onChange={(e) => {
              onChange(e);
              onFormSubmit({
                billingFrequency: e as unknown as RateBillingFrequencyEnum,
                billingFrequencyInMonths:
                  RATE_BILLING_FREQUENCY_MAP[
                    e as unknown as RateBillingFrequencyEnum
                  ]().month,
              });
            }}
            {...field}
          />
        )}
      />
    );
  }

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      size={'smaller'}
      arrowSize={20}
      gutter={16}
    >
      <PopoverTrigger>
        <MButton
          isDisabled={isDisabled}
          size="sm"
          variant="tertiary"
          aria-label="billingFrequency"
          ml={-3}
        >
          {frequencyLabel}
        </MButton>
      </PopoverTrigger>

      <Portal>
        <PopoverContent minW="300px">
          <PopoverArrow />
          <PopoverBody px={4} pt={4} pb={2}>
            <MFormField error={errors?.billingFrequencyInMonths}>
              <Controller
                name="billingFrequencyInMonths"
                control={control}
                render={({ field: { value, onChange, onBlur, ...rest } }) => (
                  <MFlex gap="2" align="center">
                    <MText>Every</MText>
                    <MCustomNumberInput
                      w="3.25rem"
                      value={value || ''}
                      onChange={(
                        valueAsString: string,
                        valueAsNumber: number,
                      ) => onChange(valueAsNumber)}
                      onBlur={() => {
                        onBlur();
                        if (value) {
                          handleFrequency(value as number);
                        }
                      }}
                      isDisabled={disableCustomFrequency}
                      {...rest}
                    />
                    <MText>Month(s)</MText>
                  </MFlex>
                )}
              />
            </MFormField>
            {billingFrequencyInMonths === 18 && (
              <MText fontSize="xs" mt="2">
                Future changes to the contract length won't update frequency
              </MText>
            )}
            <MDivider my="2" />
            <RadioStyleButtonGroup
              name="renewalLength"
              containerProps={{ columns: 2 }}
              options={frequencyOptions}
              isDisabled={disableCustomFrequency}
              value={`${billingFrequencyInMonths || ''}` || undefined}
              defaultValue={`${billingFrequencyInMonths || ''}` || undefined}
              onChange={(value: string) => {
                handleFrequency(+value);
              }}
            />
            <MDivider my="2" />
            <MFlex justifyContent="flex-end">
              <MButton
                minWidth={20}
                onClick={handleSubmit(onSubmit, onError)}
                aria-label="billingFrequencySaveBtn"
              >
                Save
              </MButton>
            </MFlex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
