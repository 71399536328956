import {
  IAccount,
  IAccountRateCreateReqSchemaUI,
  IAccountRateUpdateReqSchemaUI,
  IPriceResSchema,
  IPriceUnderProductSchemaUI,
  IPricesAndOptionsReqSchema,
  IRateResSchema,
  RateTypeEnum,
} from '../../../types';

export function prepareRateRequestData(
  account: IAccount | Pick<IAccount, 'id' | 'accountName'>,
  productsWithPrices: IPriceUnderProductSchemaUI[],
  offeringRate: IRateResSchema,
): IAccountRateCreateReqSchemaUI | IAccountRateUpdateReqSchemaUI {
  const rateProducts: Record<string, IPricesAndOptionsReqSchema> =
    productsWithPrices.reduce((acc, curr) => {
      const prices = curr.prices || [];
      const currentOption = offeringRate.options?.productOptions?.find(
        ({ productId }) => productId === curr.id,
      );

      return {
        ...acc,
        [curr.id]: {
          prices: prices.map((product: IPriceResSchema) => ({
            productId: curr.id,
            from: product.from,
            to:
              product.to === null || product.to === undefined
                ? ''
                : String(product.to),
            amount: product.amount,
            priceModel: product.priceModel,
            description: null, // product.description ? String(product.description) : '',
          })),
          productType: curr.productType,
          aggregationModel: currentOption?.aggregationModel,
        },
      };
    }, {});

  return {
    name: (offeringRate.name || '').substring(0, 50),
    rateType: RateTypeEnum.ACCOUNT as RateTypeEnum.ACCOUNT,
    accountId: account.id,
    products: rateProducts,
    parentRateId: offeringRate.parentRateId || offeringRate.id,
  };
}
