import {
  HubSpotIcon,
  NetsuiteIcon,
  QuickbooksIcon,
  SalesforceIcon,
} from '@monetize/ui/icons';
import { FaSlack } from 'react-icons/fa';
import { BillPricingProrationModelEnum } from '../types';
import {
  FirstInvoiceCreationStrategyEnum,
  InvoiceCreationEnum,
} from '../types/InvoiceSettingsTypes';
import { objectToSelectOptions } from '../utils/misc';

export const SETTING_BILLING_PRICING_PRORATION_MODEL: {
  [key in BillPricingProrationModelEnum]: string;
} = {
  MONTH_BASED: 'Month Based',
  CALENDAR_MONTH_BASED: 'Calendar Month Based',
  DAY_BASED: 'Day Based',
};

export const SETTING_BILLING_PRICING_PRORATION_MODEL_DISPLAY_STRING =
  objectToSelectOptions(SETTING_BILLING_PRICING_PRORATION_MODEL);

export const SETTING_INVOICE_CREATION_DISPLAY: {
  [key in InvoiceCreationEnum]: string;
} = {
  AUTOMATIC: 'Automatically according to Billing Schedule',
  MANUAL: 'Manually',
};

export const FIRST_INVOICE_CRATION_STRATEGY: {
  [key in FirstInvoiceCreationStrategyEnum]: string;
} = {
  [FirstInvoiceCreationStrategyEnum.FOLLOW_EXISTING_SETTINGS]: 'Month Based',
  [FirstInvoiceCreationStrategyEnum.GENERATE_ON_QUOTE_PROCESSED]:
    'Calendar Month Based',
};

export const FIRST_INVOICE_CRATION_STRATEGY_DISPLAY_STRING =
  objectToSelectOptions(FIRST_INVOICE_CRATION_STRATEGY);

export const SETTING_INVOICE_CREATION_OPTIONS = objectToSelectOptions(
  SETTING_INVOICE_CREATION_DISPLAY,
);

export const SETTING_INVOICE_DELAY_DISPLAY_FUTURE: {
  [key in number]: string;
} = {
  0: 'Keep as scheduled',
  1: '1 day after scheduled',
  2: '2 days after scheduled',
};

// https://monetizenow.atlassian.net/browse/BP-8621
export const SETTING_INVOICE_DELAY_DISPLAY: {
  [key in number]: string;
} = {
  [-30]: '30 days earlier',
  ...SETTING_INVOICE_DELAY_DISPLAY_FUTURE,
};

export const SETTING_INVOICE_DELAY_OPTIONS_PAST_AND_FUTURE =
  objectToSelectOptions(SETTING_INVOICE_DELAY_DISPLAY).sort(
    (a, b) => (a.value as unknown as number) - (b.value as unknown as number),
  );

export const SETTING_INVOICE_DELAY_OPTIONS_IN_FUTURE = objectToSelectOptions(
  SETTING_INVOICE_DELAY_DISPLAY_FUTURE,
);

export const SETTING_CARD_LOGOS = {
  Salesforce: SalesforceIcon,
  HubSpot: HubSpotIcon,
  QuickBooks: QuickbooksIcon,
  NetSuite: NetsuiteIcon,
  Slack: FaSlack,
};
