import { Card, CardBody, CardHeader, useDisclosure } from '@chakra-ui/react';
import { formatCurrency } from '@monetize/utils/core';
import { useNavigate } from 'react-router';
import { MChart, MFlex, MText } from '../../../../components/Monetize';
import DataTableActions from '../../../../components/Monetize/DataTable/MDataTableActions';
import { getSubscriptionUsageRoute } from '../../../../constants/routes';
import { useACL } from '../../../../services/acl/acl';
import {
  IGetSubscriptionSchema,
  ISubscriptionUsageChartData,
  SubscriptionBillingStatusEnum,
} from '../../../../types';
import { ManualUsageForm } from './ManualUsageForm';
import { UploadUsageModal } from './usageUpload/UploadUsageModal';

interface UsageOverviewChartProps {
  accountId: string;
  subscription: IGetSubscriptionSchema;
  subscriptionUsage: ISubscriptionUsageChartData;
  onNewUsageRecord: () => void;
}

export const UsageOverviewChart = ({
  accountId,
  subscription,
  subscriptionUsage,
  onNewUsageRecord,
}: UsageOverviewChartProps) => {
  const navigate = useNavigate();
  const { canDo } = useACL();
  const canUpdateUsage = canDo([['billing', 'update']]);

  const {
    isOpen: isManualUsageModalOpen,
    onClose: onCloseUsageUpload,
    onOpen: onOpenUsageUpload,
  } = useDisclosure();

  const {
    isOpen: isUsageFormOpen,
    onOpen: onOpenUsageForm,
    onClose: onCloseUsageForm,
  } = useDisclosure();

  const usageType =
    subscriptionUsage.usageTypes && subscriptionUsage.usageTypes.length > 0
      ? subscriptionUsage.usageTypes[0]
      : null;
  return (
    <>
      {canUpdateUsage &&
        subscriptionUsage.usageTypes &&
        subscriptionUsage.usageTypes.length > 0 && (
          <ManualUsageForm
            isOpen={isUsageFormOpen}
            onClose={(didSave) => {
              onCloseUsageForm();
              didSave && onNewUsageRecord();
            }}
            subscriptionId={subscription.id}
            periodStartDate={subscription.periodStartDate}
            usageTypes={subscriptionUsage.usageTypes}
            accountId={accountId}
          />
        )}
      {canUpdateUsage && isManualUsageModalOpen && (
        <UploadUsageModal
          accountId={accountId}
          subscriptionId={subscription.id}
          onClose={(didSave) => {
            onCloseUsageUpload();
            didSave && onNewUsageRecord();
          }}
        />
      )}
      <Card variant="edit" mb="4">
        <CardHeader>
          <MFlex alignItems="center" justifyContent="space-between">
            <MText fontSize="lg" fontWeight="bold">
              {subscriptionUsage.product?.name}
              {usageType ? `(${usageType.unitName})` : ''}
            </MText>
            <MFlex alignItems="center" justifyContent="end">
              <MText fontSize="md" fontWeight="bold" textAlign="right">
                {formatCurrency(subscriptionUsage.totalRatedAmount)}
              </MText>
              {canUpdateUsage && (
                <DataTableActions
                  boxProps={{ ml: 1 }}
                  actions={[
                    {
                      title: 'New Usage',
                      enabled:
                        subscription.billingStatus !==
                        SubscriptionBillingStatusEnum.CANCELED,
                      action: () => onOpenUsageForm(),
                    },
                    {
                      title: 'Import from File',
                      enabled:
                        subscription.billingStatus !==
                        SubscriptionBillingStatusEnum.CANCELED,
                      action: () => onOpenUsageUpload(),
                    },
                    {
                      title: 'View Usage Records',
                      enabled: true,
                      action: () =>
                        navigate(
                          getSubscriptionUsageRoute(
                            accountId,
                            subscription.id,
                            subscriptionUsage.product.id,
                          ),
                        ),
                    },
                  ]}
                />
              )}
            </MFlex>
          </MFlex>
        </CardHeader>
        <CardBody pt="0">
          <MChart
            pl={2}
            pr={2}
            options={subscriptionUsage.options || {}}
            series={subscriptionUsage.series || []}
            height={300}
          />
        </CardBody>
      </Card>
    </>
  );
};
