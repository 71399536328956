import { CardBody } from '@chakra-ui/react';
import { OfferingTypesEnum } from '@monetize/types/app';
import { IOrderedObj } from '@monetize/types/common';
import { sortByProductPosition } from '@monetize/utils/core';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '../../../constants/products';
import {
  IPriceTierSettingsReq,
  IPriceUnderProductSchemaUI,
  IQuoteItemRespSchema,
  IQuoteOfferingRespSchema,
  PriceTiersEnum,
} from '../../../types';
import {
  MBox,
  MButton,
  MCard,
  MDivider,
  MFlex,
  MHStack,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '../../Monetize';
import { SortedProductsWithPricesRow } from './SortedProductsWithPricesRow';

interface PricesDrawerBodyViewProps {
  isSaving: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  offeringType: OfferingTypesEnum;
  productsWithPrices: IPriceUnderProductSchemaUI[];
  productsObj: IOrderedObj<IQuoteItemRespSchema>;
  currency: string;
  isPriceTierUpdated: boolean;
  savePriceTiers: ({
    priceDisplayMode,
    displayedPriceIds,
  }: IPriceTierSettingsReq) => void;
  setIsPriceTierUpdated: (val: boolean) => void;
}

export const QuotePriceTierDisplaySettings = ({
  isSaving,
  quoteOffering,
  productsWithPrices,
  productsObj,
  offeringType,
  currency,
  savePriceTiers,
  isPriceTierUpdated,
  setIsPriceTierUpdated,
}: PricesDrawerBodyViewProps) => {
  const isPoTOfferingType =
    offeringType === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL;

  const [tiersValue, setTiersValue] = useState<PriceTiersEnum>(
    (quoteOffering?.items[0]?.options?.priceDisplayMode as PriceTiersEnum) ??
      PriceTiersEnum.ALL_PRICES,
  );

  const [displayedPriceIds, setDisplayedPriceIds] = useState<
    Record<string, string[]>
  >({});

  const computedDisplayedPriceIds = useMemo(
    () =>
      quoteOffering?.items.reduce(
        (acc, { productId, options }) => {
          acc[productId] = options?.displayedPriceIds ?? [];
          return acc;
        },
        {} as Record<string, string[]>,
      ) ?? {},
    [quoteOffering?.items],
  );

  const allPricesSelected = useMemo(() => {
    return productsWithPrices.every(({ id, prices }) => {
      return displayedPriceIds[id]?.length === prices.length;
    });
  }, [displayedPriceIds, productsWithPrices]);

  useEffect(() => {
    if (quoteOffering?.items && tiersValue === PriceTiersEnum.CUSTOM_PRICES) {
      quoteOffering.items.forEach((item) => {
        setDisplayedPriceIds((prev) => {
          return {
            ...prev,
            [item.productId]: item.options?.displayedPriceIds ?? [],
          };
        });
      });
    }
    setIsPriceTierUpdated(false);
  }, [quoteOffering?.items, setIsPriceTierUpdated, tiersValue]);

  useEffect(() => {
    const priceDisplayMode = quoteOffering?.items[0]?.options
      ?.priceDisplayMode as PriceTiersEnum;
    const isTierUpdated = tiersValue !== priceDisplayMode;

    if (tiersValue === PriceTiersEnum.CUSTOM_PRICES) {
      setIsPriceTierUpdated(
        JSON.stringify(displayedPriceIds) !==
          JSON.stringify(computedDisplayedPriceIds),
      );
    } else {
      setIsPriceTierUpdated(isTierUpdated);
    }
  }, [
    tiersValue,
    quoteOffering?.items,
    displayedPriceIds,
    computedDisplayedPriceIds,
    setIsPriceTierUpdated,
  ]);

  const handleCheckboxChange = useCallback(
    (id: string, priceId: string, checked: boolean) => {
      setDisplayedPriceIds((prev) => {
        const updatedPrices = new Set(prev[id] ?? []);

        if (checked) {
          updatedPrices.add(priceId);
        } else {
          updatedPrices.delete(priceId);
        }

        return { ...prev, [id]: Array.from(updatedPrices) };
      });
    },
    [],
  );

  const isChecked = useMemo(
    () => (id: string, priceId: string) =>
      displayedPriceIds[id]?.includes(priceId) ?? false,
    [displayedPriceIds],
  );

  const isSubmitDisabled = useMemo(() => {
    const hasDisplayedPrices = Object.values(displayedPriceIds).some(
      (prices) => prices.length > 0,
    );

    if (tiersValue === PriceTiersEnum.CUSTOM_PRICES) {
      return !isPriceTierUpdated || !hasDisplayedPrices;
    }

    return !isPriceTierUpdated;
  }, [displayedPriceIds, tiersValue, isPriceTierUpdated]);

  const sortedProductsWithPrices = sortByProductPosition(
    productsWithPrices.map((item) => ({
      ...item,
      position: productsObj.byId[item.id]?.position ?? 0,
    })),
  );

  const handlePriceTiersSubmit = () => {
    savePriceTiers({
      priceDisplayMode: tiersValue,
      displayedPriceIds: displayedPriceIds,
    });
  };

  const updateAllPricesSelection = () => {
    setDisplayedPriceIds((prev) => {
      if (allPricesSelected) {
        return {}; // Reset state
      }

      return productsWithPrices.reduce(
        (acc, { id, prices }) => {
          acc[id] = prices.map(({ id }) => id);
          return acc;
        },
        {} as Record<string, string[]>,
      );
    });
  };

  const resetPricesSelection = () => {
    setDisplayedPriceIds({});
  };

  return (
    <MCard variant="borderless" mb="2">
      <CardBody>
        <MText as="h5" fontSize="md" fontWeight="bold" isTruncated>
          Show Price Tiers
        </MText>

        <MDivider my={4} />
        <MBox
          style={{
            height: 'calc(100vh - 360px)',
            overflowY: 'auto',
          }}
        >
          <MText mb={4}>
            Customize which pricing tiers are shared to the end customer on the
            Quote:
          </MText>
          <MRadioGroup
            value={tiersValue}
            onChange={(value: string) => {
              setTiersValue(value as PriceTiersEnum);
              if (value !== PriceTiersEnum.CUSTOM_PRICES) {
                resetPricesSelection();
              }
            }}
          >
            <MStack spacing={4} direction="column" color="tGray.darkPurple">
              <MRadio value={PriceTiersEnum.ALL_PRICES}>Show All Tiers</MRadio>
              <MRadio value={PriceTiersEnum.USED_PRICES}>
                Show used Tiers
              </MRadio>
              <MRadio value={PriceTiersEnum.CUSTOM_PRICES}>
                Customize Visible Tiers
              </MRadio>
            </MStack>
          </MRadioGroup>

          {tiersValue === PriceTiersEnum.CUSTOM_PRICES && (
            <>
              <MFlex mt={4}>
                <MButton
                  size={'sm'}
                  variant="tertiary"
                  onClick={updateAllPricesSelection}
                >
                  {allPricesSelected ? 'Deselect' : 'Select'} All
                </MButton>
              </MFlex>
              <CardBody px={0}>
                {sortedProductsWithPrices.map(
                  ({ id, prices, name, productType }, index) => {
                    const isLastRow = productsWithPrices.length - 1 === index;
                    return (
                      <Fragment key={id}>
                        <MBox>
                          <MText mb="4" as="h5" fontSize="md" fontWeight="bold">
                            {name}{' '}
                            <MText as="span" fontWeight="normal">
                              ({PRODUCT_TYPE_DISPLAY_SHORT[productType]})
                            </MText>
                          </MText>

                          <MHStack spacing={4} mb="2">
                            <MBox minW="4" />
                            <MText minW="40" fontWeight="bold">
                              Pricing Model
                            </MText>
                            <MText minW="6.25rem" fontWeight="bold">
                              From
                            </MText>
                            <MText minW="6.25rem" fontWeight="bold">
                              Up to
                            </MText>
                            <MText minW="32" fontWeight="bold">
                              {isPoTOfferingType ? 'Percentage' : 'Amount'}
                            </MText>
                            <MBox minW="8" />
                          </MHStack>
                          <SortedProductsWithPricesRow
                            prices={prices}
                            id={id}
                            isPoTOfferingType={isPoTOfferingType}
                            productsObj={productsObj}
                            currency={currency}
                            handleCheckboxChange={handleCheckboxChange}
                            isChecked={isChecked}
                          />
                        </MBox>
                        {!isLastRow && <MDivider my="2" />}
                      </Fragment>
                    );
                  },
                )}
              </CardBody>
            </>
          )}
        </MBox>
        <MDivider my={4} />
        <MFlex justify="flex-end" mt={4}>
          <MButton
            size="sm"
            isLoading={isSaving}
            onClick={handlePriceTiersSubmit}
            disabled={isSubmitDisabled || isSaving}
          >
            Save
          </MButton>
        </MFlex>
      </CardBody>
    </MCard>
  );
};
