import { AccordionItemProps, AccordionProps } from '@chakra-ui/react';
import { formatCurrency } from '@monetize/utils/core';
import React, { FC } from 'react';
import { useGetQuoteDiscounts } from '../../../../api/cpqService';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MAccordionItem,
  MAccordionPanel,
} from '../../../../components/Monetize';
import { IQuoteRespSchema } from '../../../../types';
import { QuoteDiscounts } from './QuoteDiscounts';

interface QuoteFormDiscountsProps extends AccordionProps {
  quote: IQuoteRespSchema;
  itemProps?: AccordionItemProps;
}

export const QuoteFormDiscounts: FC<QuoteFormDiscountsProps> = React.forwardRef<
  any,
  QuoteFormDiscountsProps
>(({ quote, itemProps = {}, ...rest }: QuoteFormDiscountsProps, ref) => {
  const { isLoading, data } = useGetQuoteDiscounts({
    quoteId: quote.id,
    // expensive calculation, but BE API is expensive for some customers, so doing work here to query only when required
    queryKeys: [
      quote.discountAmount,
      quote.discountPercent,
      ...quote.quoteOfferings.flatMap(
        ({ id, discounts, discountAmount, discountPercent }) => [
          id,
          discountAmount,
          discountPercent,
          discounts
            .map((discount) => discount.id)
            .sort() // most likely there are 0 or 1 standard discounts, queryKey order matters
            .join(),
        ],
      ),
    ],
  });

  if (
    isLoading ||
    !data ||
    !Array.isArray(data.discounts) ||
    data.discounts.length === 0
  ) {
    return null;
  }

  return (
    <MAccordion allowToggle {...rest}>
      <MAccordionItem {...itemProps}>
        {({ isExpanded }) => (
          <>
            <MAccordionCustomButton isExpanded={isExpanded} label="Discounts">
              <MAccordionCustomButtonItem
                hideWhenExpanded
                isExpanded={isExpanded}
                label="Applied Discounts"
                value={data.discounts.length}
              />
              <MAccordionCustomButtonItem
                hideWhenExpanded
                isExpanded={isExpanded}
                label="Total Discounts"
                value={`(${formatCurrency(data.total, {
                  currency: quote?.currency,
                })})`}
              />
            </MAccordionCustomButton>

            <MAccordionPanel>
              <QuoteDiscounts
                currency={quote?.currency}
                data={data}
                lineProps={{ background: 'tGray.lightPurple' }}
              />
            </MAccordionPanel>
          </>
        )}
      </MAccordionItem>
    </MAccordion>
  );
});
