import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdInfo } from 'react-icons/md';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  MAccordion,
  MBox,
  MButton,
  MCheckbox,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MStack,
  MText,
  MTooltip,
} from '../../../../components/Monetize';
import { MSettingAccordionItem } from '../../../../components/Monetize/MSettingAccordionItem';
import {
  SETTING_INVOICE_CREATION_OPTIONS,
  SETTING_INVOICE_DELAY_OPTIONS_IN_FUTURE,
  SETTING_INVOICE_DELAY_OPTIONS_PAST_AND_FUTURE,
} from '../../../../constants/settings';
import { useFlags } from '../../../../services/launchDarkly';
import {
  FirstInvoiceCreationStrategyEnum,
  IInvoiceAutomationSettings,
  InvoiceAutomationSettingsSchema,
  InvoiceCreationEnum,
} from '../../../../types/InvoiceSettingsTypes';

export type InvoiceAutomationProps = {
  invoiceAutomation: IInvoiceAutomationSettings;
  onSubmit: (data: IInvoiceAutomationSettings) => Promise<void>;
};

export const renderInvoiceDelayItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
}) => {
  return (
    <MBox
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      // Prevent jitter when hovering and icon becomes visible
      minW="160px"
      w="100%"
      h="22px"
      position="relative"
      role="group"
    >
      <MText
        color="inherit"
        noOfLines={1}
        fontSize={isSubtitle ? '12px' : 'sm'}
        width="90%"
      >
        {title}
      </MText>
      {item.value === '-30' && (
        <MTooltip
          label="If Usage products are on Quote, Bill Group Invoice Creation Timing is set to ‘As Scheduled’ to ensure all usage data is calculated before invoice generation."
          placement="bottom-start"
        >
          <MBox position="relative" ml="4">
            <MIcon
              as={MdInfo}
              color="tPurple.base"
              display="none"
              sx={{
                '[role=group]:not(.chakra-form-control):hover &': {
                  display: 'block',
                },
              }}
            />
          </MBox>
        </MTooltip>
      )}
    </MBox>
  );
};

export const InvoiceAutomation = ({
  invoiceAutomation,
  onSubmit,
}: InvoiceAutomationProps) => {
  const { allowInvoiceDelayInPast, showSettingFirstInvoiceCheckbox } =
    useFlags();
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = useForm<IInvoiceAutomationSettings>({
    resolver: zodResolver(InvoiceAutomationSettingsSchema),
    mode: 'onChange',
    defaultValues: {
      invoiceCreation: InvoiceCreationEnum.AUTOMATIC,
    },
  });

  useEffect(() => {
    if (invoiceAutomation) {
      reset({ ...invoiceAutomation });
    }
  }, [invoiceAutomation, reset]);

  const handleSubmitAutomationConfig = async (
    data: IInvoiceAutomationSettings,
  ) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: unknown) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  const invoiceCreation = watch('invoiceCreation');

  return (
    <MStack w="100%" data-testid="invoice-automation-config">
      <form
        onSubmit={handleSubmit(handleSubmitAutomationConfig)}
        data-testid="invoice-automation-settings-form"
      >
        <MAccordion allowToggle reduceMotion>
          <MSettingAccordionItem
            description="Define Invoice creation settings for newly created Bill Groups."
            label="Invoice Creation"
          >
            <MBox mt={2}>
              <MGrid templateColumns="repeat(12, 1fr)" gap={2} rowGap={2}>
                <MGridItem colStart={1} colSpan={10}>
                  <MFormField
                    error={errors?.invoiceCreation}
                    label="Create Invoices:"
                    onClick={(ev) => ev.stopPropagation()}
                    labelProps={{ mb: 1 }}
                  >
                    <Controller
                      name="invoiceCreation"
                      control={control}
                      render={({ field: { ...rest } }) => (
                        <MCustomSelect
                          items={SETTING_INVOICE_CREATION_OPTIONS}
                          isDisabled={isLoading}
                          {...rest}
                        />
                      )}
                    />
                  </MFormField>
                </MGridItem>
                {showSettingFirstInvoiceCheckbox && (
                  <MGridItem colStart={1} colSpan={12}>
                    <MFormField
                      error={errors?.firstInvoiceCreationStrategy}
                      onClick={(ev) => ev.stopPropagation()}
                    >
                      <Controller
                        name="firstInvoiceCreationStrategy"
                        control={control}
                        render={({ field: { onChange, value, ...rest } }) => (
                          <MCheckbox
                            isChecked={
                              value ===
                              FirstInvoiceCreationStrategyEnum.GENERATE_ON_QUOTE_PROCESSED
                            }
                            {...rest}
                            onChange={(ev) => {
                              if (ev.target.checked) {
                                onChange(
                                  FirstInvoiceCreationStrategyEnum.GENERATE_ON_QUOTE_PROCESSED,
                                );
                              } else {
                                onChange(
                                  FirstInvoiceCreationStrategyEnum.FOLLOW_EXISTING_SETTINGS,
                                );
                              }
                            }}
                            isDisabled={isLoading}
                          >
                            <MTooltip
                              label="Applies to New and Renewal Quotes with Offerings billed in Advance"
                              placement="bottom-end"
                              shouldWrapChildren
                            >
                              <MFlex alignItems="center">
                                <MText>
                                  Only for first invoices, create automatically
                                  upon quote processing.
                                  <MIcon
                                    as={MdInfo}
                                    color="tPurple.base"
                                    ml="1"
                                    w="3"
                                    h="3"
                                    mb="-0.5"
                                  />
                                </MText>
                              </MFlex>
                            </MTooltip>
                          </MCheckbox>
                        )}
                      />
                    </MFormField>
                  </MGridItem>
                )}
                {[InvoiceCreationEnum.AUTOMATIC].includes(invoiceCreation) && (
                  <MGridItem colStart={1} colSpan={10} mt={2}>
                    <MFormField
                      error={errors?.invoiceDelay}
                      label="Offset from Billing Schedule:"
                      onClick={(ev) => ev.stopPropagation()}
                    >
                      <Controller
                        name="invoiceDelay"
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <MCustomSelect
                            items={
                              allowInvoiceDelayInPast
                                ? SETTING_INVOICE_DELAY_OPTIONS_PAST_AND_FUTURE
                                : SETTING_INVOICE_DELAY_OPTIONS_IN_FUTURE
                            }
                            value={String(value)}
                            onChange={(value) => {
                              onChange && onChange(Number(value));
                            }}
                            renderItemContent={renderInvoiceDelayItemContent}
                            isDisabled={isLoading}
                            {...rest}
                          />
                        )}
                      />
                    </MFormField>
                  </MGridItem>
                )}
                <MGridItem colSpan={12} my={3}>
                  <MDivider />
                </MGridItem>

                <MGridItem colSpan={12}>
                  <MFlex justify="end">
                    <MButton
                      variant="primary"
                      onClick={handleSubmit(handleSubmitAutomationConfig)}
                      isDisabled={isLoading || !isDirty || !isValid}
                      isLoading={isLoading}
                    >
                      Save
                    </MButton>
                  </MFlex>
                </MGridItem>
              </MGrid>
            </MBox>
          </MSettingAccordionItem>
        </MAccordion>
      </form>
    </MStack>
  );
};
