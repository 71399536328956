import { addMonthsCustom } from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { differenceInDays } from 'date-fns/differenceInDays';
import { format as formatDate } from 'date-fns/format';
import { isAfter } from 'date-fns/isAfter';
import { isSameMonth } from 'date-fns/isSameMonth';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { startOfMonth } from 'date-fns/startOfMonth';
import lodashGet from 'lodash/get';
import has from 'lodash/has';
import isBoolean from 'lodash/isBoolean';
import isDate from 'lodash/isDate';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import sortBy from 'lodash/sortBy';
import { handleApiErrorToast } from '../api/axios';
import { SAFE_FILENAME } from '../constants/common';
import { ISO8601FormatWithMilliSecond } from '../constants/dates';
import { OFFERING_TYPES_ORDER } from '../constants/offerings';
import {
  PRODUCT_TYPES_SUPPORTING_PRORATION,
  PRODUCT_TYPE_ORDER,
} from '../constants/products';
import { DEFAULT_QUOTE_COLORS } from '../constants/quotes';
import { logger } from '../services/logger';
import {
  AmountUnitTypeEnum,
  ApiListResponse,
  ContactStatusEnum,
  FilterType,
  FilterTypeOperator,
  FormatNumberOptions,
  GetListApiFilter,
  IContactRespSchema,
  IInvoiceItemRespSchema,
  IOfferingRes,
  IOfferingResUI,
  IQuoteItem,
  IQuoteItemRespSchema,
  IQuoteOffering,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResBaseSchema,
  Maybe,
  NewQuoteTypeEnum,
  NumberStyleEnum,
  OfferingTypesEnum,
  ProductTypeEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
  RateStatusEnum,
  RateTypeEnum,
} from '../types';
import { getFormattedDate } from './dates';
import { downloadBlobAsFile } from './download';

export function shortenUuids(uid?: string | null): string {
  if (!uid) {
    return '';
  }
  if (uid.length <= 11) {
    return uid;
  }
  const first4Char = uid.slice(0, 4);
  const last4Char = uid.slice(-4);
  return `${first4Char}...${last4Char}`;
}

/**
 *
 * @param value - value in string
 * @param maxChars - number of max chars default is 18
 * @returns truncated middle part of the value
 */
export function truncateMiddle(value: Maybe<string>, maxChars = 18): string {
  if (!value) {
    return '';
  }

  if (value.length <= maxChars) {
    return value;
  }

  const first8Char = value.slice(0, 8);
  const last10Char = value.slice(-10); // Using last 10 chars since 2022-09-27 is exactly 10 chars long

  return `${first8Char}...${last10Char}`;
}

export function shortenText(
  type: 'last',
  minLength: number,
  text?: string | null,
  limit?: number,
): string {
  if (!text) {
    return '';
  }

  const textLength = text.length;
  if (textLength <= minLength) {
    return text;
  }

  // last shorten
  let lastShortenText = '';
  if (limit && textLength > limit) {
    // if text length more then limit (show half as last shorten)
    const limitShortenChar = text.slice(0, textLength / 2);
    lastShortenText = `${limitShortenChar}...`;
  } else {
    // last shorten
    const splicedText = text.slice(0, minLength);
    lastShortenText = `${splicedText}...`;
  }

  return lastShortenText;
}

export const toRate = (value: string | null | number, decimals: number = 0) => {
  if (!value && value !== 0) {
    return '';
  }

  return `${value} %`;
};

// NOTE: Intl.NumberFormat should handle any formatting we want, so consider adding the appropriate option to this function
// before building our own strings (ie. setting currencySign = 'accounting' gives us parantheses instead of '-' to indicate negative number)
// For details on Intl.NumberFormat options (ie. currencySign), see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
// SEE TESTS at /src/utils/__tests__/index.test.ts for examples of how to call this function
export const formatNumber = (
  input: number | string | null | undefined,
  {
    style = NumberStyleEnum.DECIMAL,
    zeroStr,
    currency,
    currencySign,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  }: FormatNumberOptions = {},
): string => {
  let value;
  if (input || input === 0) {
    value = +input;
  } else {
    return '';
  }
  if (zeroStr && value === 0) {
    return zeroStr;
  }

  if (maximumFractionDigits === 'max' || maximumFractionDigits > 16) {
    maximumFractionDigits = 16; // max supported by JS is documented as 20 but in practice seems to be 16
  }
  if (minimumFractionDigits > maximumFractionDigits) {
    minimumFractionDigits = maximumFractionDigits;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    currency,
    currencySign,
    signDisplay: value === 0 ? 'never' : 'auto',
  } as Intl.ResolvedNumberFormatOptions);
  const formatted = formatter.format(value);
  return formatted;
};

export const formatInteger = (
  input: number | string | null | undefined,
  {
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    ...rest
  }: FormatNumberOptions = {},
): string => {
  return formatNumber(input, {
    minimumFractionDigits,
    maximumFractionDigits,
    ...rest,
  });
};

export const formatCurrency = (
  input: number | string | null | undefined,
  {
    style = NumberStyleEnum.CURRENCY,
    currency = 'USD',
    currencySign = 'accounting', // parentheses for negative number
    ...rest
  }: // }: Omit<FormatNumberOptions, 'currency'> & { currency: string },
  FormatNumberOptions = {},
): string => {
  return formatNumber(input, {
    style,
    currency,
    currencySign,
    maximumFractionDigits: 'max',
    ...rest,
  });
};

// for use with real number values, not values that are the numerator of a percent
// ie. the value .0918, which would be displayed as 9.18%
export const formatPercent = (
  input: number | string | null | undefined,
  { style = NumberStyleEnum.PERCENT, ...rest }: FormatNumberOptions = {},
): string => {
  return formatNumber(input, { style, ...rest });
};

// handles a value that is expressed as the numerator only and needs to be divided by denominator 100 to find the true value
// ie. the value 9.18 which is intended to mean 9.18/100 and would be displayed as 9.18%
export const formatPercentNumerator = (
  input: number | string | null | undefined,
  style: FormatNumberOptions = {},
): string => {
  return formatPercent(input ? Number(input) / 100 : input, style);
};

/**
 * Formats digits with preceeding asterisks to show just the end of an account number
 */
export const formatAccountNumber = (digits?: string): string =>
  digits ? `**** **** **** **** ${digits}` : '';

/**
 * Formats month and year as for credit card expiration dates
 */
export const formatExpirationDate = (
  month?: string | number,
  year?: string | number,
): string => {
  if (month && year) {
    month = `${month}`.padStart(2, '0');
    return `${month} / ${year}`;
  }
  return '';
};

export const toTitleCase = (value: string | null) => {
  if (!value) {
    return '';
  }

  const str = `${value}`;
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export function toNumber(num: number) {
  const formatter = new Intl.NumberFormat('en-US');
  return formatter.format(num);
}

// returns null if none of the discounts have a value ($ or %) associated with them or if none of the discountTypes are recognized
// otherwise returns FLAT if all discounts with a value are of that type, otherwise returns PERCENTAGE if there is at least one discount with value
export const multipleDiscountType = (
  discounts?: any[] | null,
): AmountUnitTypeEnum | null => {
  if (!discounts) {
    return null;
  }
  let result: Maybe<AmountUnitTypeEnum> = null;
  for (const discount of discounts) {
    if (discount.discountAmount) {
      // ignoring discount.discountAmountOrPercent as the API can send that with a value even when there is no discount value applied to the item
      if (discount.discountType === AmountUnitTypeEnum.PERCENTAGE) {
        return AmountUnitTypeEnum.PERCENTAGE;
      } else if (discount.discountType === AmountUnitTypeEnum.FLAT) {
        result = AmountUnitTypeEnum.FLAT;
      }
    }
  }
  return result;
};

export const getDiscountText = (
  amount: number | null | undefined,
  type?: AmountUnitTypeEnum,
  currency: string = 'USD',
): string => {
  if (!amount) {
    return ''; // never display discount values of 0
  }
  switch (type) {
    case AmountUnitTypeEnum.FLAT:
      return formatCurrency(amount, { currency, maximumFractionDigits: 2 });
    case AmountUnitTypeEnum.PERCENTAGE:
      return formatPercentNumerator(amount, { maximumFractionDigits: 2 });
    default:
      return '';
  }
};

export const getTotalDiscountAmount = (invoice: any) => {
  let totalAmount = 0;
  const totalDiscountAmount = 0;

  if (invoice) {
    const amount = invoice?.amountWithoutTax ? invoice.amountWithoutTax : 0;
    const taxAmount = invoice?.tax ? invoice.tax : 0;
    totalAmount = +amount + taxAmount;
  } else {
    totalAmount = 0;
  }

  return {
    totalAmount,
    totalDiscountAmount,
  };
};

/**
 * Gets the total count of filters that are applied
 */
export const getFiltersApplied = (
  filters?: FilterType | FilterType[] | null,
): number => {
  let appliedFilters = 0;
  if (!filters || isEmpty(filters)) {
    return appliedFilters;
  }

  if (Array.isArray(filters)) {
    if (filters.length > 0) {
      filters.forEach(({ value }: FilterType) => {
        if (Array.isArray(value)) {
          appliedFilters += value.filter((v) => !!v).length;
        } else if (typeof value === 'object') {
          Object.values(value).forEach((objValue) => {
            if (!isEmpty(objValue)) {
              appliedFilters += 1;
            }
          });
        } else if (
          typeof value === 'string' &&
          !isEmpty(value.trim()) &&
          value
        ) {
          appliedFilters += 1;
        } else if (typeof value === 'boolean' && value) {
          appliedFilters += 1;
        }
      });
    }
  } else {
    Object.values(filters).forEach((value) => {
      if (!isEmpty(value)) {
        appliedFilters += 1;
      }
    });
  }

  return appliedFilters;
};

export const buildFilterParamsRequestObject = (
  filters: FilterType[] | null,
  searchTerm?: string | null,
  searchKey?: string,
  /** If a searchKey and searchTerm are provided, this is the operator to use when searching */
  searchOperator: 'contains' | 'equals' = 'contains',
): GetListApiFilter => {
  const params: GetListApiFilter = {};
  if (searchKey && searchTerm) {
    Object.assign(params, {
      [searchKey]:
        searchOperator === 'contains' ? { contains: searchTerm } : searchTerm,
    });
  }

  if (filters) {
    filters.forEach((f: FilterType) => {
      const { key, value, operator } = f;
      let filterValues = value;
      switch (operator) {
        case FilterTypeOperator.GLTE:
          // Use between operator
          if (value.max && value.min) {
            Object.assign(params, {
              [key]: { between: { bt: `${value.min},${value.max}` } },
            });
          } else if (value.max) {
            Object.assign(params, {
              [key]: { between: { lte: value.max } },
            });
          } else if (value.min) {
            Object.assign(params, {
              [key]: { between: { gte: value.min } },
            });
          }
          break;
        case FilterTypeOperator.BETWEEN:
        case FilterTypeOperator.IN:
          if (filterValues instanceof Array) {
            // if filter includes All (''), send empty array
            filterValues = filterValues.includes('') ? [] : filterValues;
          }
          Object.assign(params, { [key]: { [operator]: filterValues } });
          break;
        case FilterTypeOperator.CONTAINS:
          Object.assign(params, { [key]: { [operator]: filterValues } });
          break;
        case FilterTypeOperator.TOGGLE:
          if (filterValues && f.options?.trueValue) {
            Object.assign(params, {
              [key]: value,
            });
          } else if (!filterValues && f.options?.falseValue) {
            Object.assign(params, {
              [key]: f.options?.falseValue,
            });
          }
          break;
        case FilterTypeOperator.NOT_EQUAL:
          Object.assign(params, { [key]: { ne: filterValues } });
          break;
        default:
          Object.assign(params, { [key]: filterValues });
      }
    });
  }

  return params;
};

export const transformTableFilterValue = (
  filters: FilterType[],
): FilterType[] => {
  const parseFilters = filters.map((item) => {
    if (item.key === 'createDate' || item.key === 'modifyDate') {
      return {
        ...item,
        value: {
          min: getFormattedDate(
            (item.value as any).min,
            ISO8601FormatWithMilliSecond,
          ),
          max: getFormattedDate(
            (item.value as any).max,
            ISO8601FormatWithMilliSecond,
          ),
        },
      };
    }
    return item;
  });
  return parseFilters as FilterType[];
};

export const getQuoteColors = (quoteType?: string) => {
  switch (quoteType) {
    case 'AMENDMENT':
    case 'RENEWAL':
      return {
        color: 'tBlue.magenta',
        bgColor: 'tWhite.base',
        borderColor: 'tBlue.magenta',
      };
    default:
      return DEFAULT_QUOTE_COLORS;
  }
};

export const sortByCreateDate = (a: any, b: any) =>
  new Date(b?.createDate).valueOf() - new Date(a?.createDate).valueOf();

export const sortByAlphabetically =
  (key: string = 'name') =>
  (a: any, b: any) => {
    if (
      !a ||
      !has(a, key) ||
      isUndefined(a[key]) ||
      !b ||
      !has(b, key) ||
      isUndefined(b[key])
    ) {
      return 0;
    }

    const left = a[key].toString() as string;
    const right = b[key].toString() as string;
    return left.localeCompare(right);
  };

export const sortAlphabetically =
  (key = 'name') =>
  (a: { [x: string]: any }, b: { [x: string]: any }): number => {
    if (
      key in a &&
      typeof a[key] === 'string' &&
      key in b &&
      typeof b[key] === 'string'
    ) {
      return a[key].localeCompare(b[key], 'en');
    }
    return 0;
  };

export const sortOfferingsByTypeArr = (
  arr: IOfferingRes[],
  sortingArr: OfferingTypesEnum[] = [
    OfferingTypesEnum.SUBSCRIPTION,
    OfferingTypesEnum.ONETIME,
  ],
) =>
  arr.sort(
    (a, b) =>
      sortingArr.indexOf(a.type) - sortingArr.indexOf(b.type) ||
      sortByAlphabetically()(a, b),
  );

export const getOfferingWithSortedRate = (
  offering: IOfferingRes,
  {
    accountId,
    currency,
    contractStartDate,
    rateId,
  }: {
    accountId?: string;
    currency?: string;
    contractStartDate?: string | null;
    rateId?: string | null;
  } = {},
) =>
  ({
    ...offering,

    rates: offering.rates
      ? offering.rates
          ?.filter(
            ({
              id,
              status,
              accountId: rateAccountId,
              currency: rateCurrency,
              endDate,
              rateType,
              quotable,
            }) => {
              if (rateId && rateId === id) {
                // If rate is already selected we should not go through
                // other comparisons below
                return true;
              }

              // If accountId is provided then compare against rate accountId
              // If not then it should be always true
              const isSameAccount =
                rateType === RateTypeEnum.ACCOUNT && accountId
                  ? accountId === rateAccountId
                  : true;
              // If currency is provided then compare against rate currency
              // If not then it should be always true
              const isSameCurrency = currency
                ? currency === rateCurrency
                : true;
              const parsedContractStartDate = contractStartDate
                ? parseISO(contractStartDate)
                : undefined;
              const parsedRateEndDate = endDate ? parseISO(endDate) : undefined;

              // If contractStartDate is provided then compare against rate endDate
              // If not then it should be always true
              const isRateEndDateIsBeforeContractStartDate =
                parsedRateEndDate && parsedContractStartDate
                  ? differenceInDays(
                      parsedRateEndDate,
                      parsedContractStartDate,
                    ) <= 0
                  : false;

              return (
                status === RateStatusEnum.ACTIVE &&
                quotable &&
                isSameAccount &&
                isSameCurrency &&
                !isRateEndDateIsBeforeContractStartDate
              );
            },
          )
          .sort(sortByAlphabetically())
      : [],
  }) as IOfferingRes;

export const sortRate = (rates: IRateResBaseSchema[]) => {
  return rates.sort(
    (r1, r2) =>
      (r1.rateType && r2.rateType && r1.rateType.localeCompare(r2.rateType)) ||
      r1.name.localeCompare(r2.name),
  );
};

export const getQuoteType = (
  quote?: IQuoteRespSchema | null | undefined,
): {
  isAmendment: boolean;
  isRenewal: boolean;
  isNew: boolean;
  isEarlyRenewal: boolean;
} => {
  if (quote) {
    return {
      isAmendment: quote.type === QuoteTypeEnum.AMENDMENT,
      isRenewal: quote.type === QuoteTypeEnum.RENEWAL,
      isNew: quote.type === QuoteTypeEnum.NEW,
      isEarlyRenewal: quote.newQuoteType === NewQuoteTypeEnum.EARLY_RENEWAL,
    };
  }

  return {
    isAmendment: false,
    isRenewal: false,
    isNew: true,
    isEarlyRenewal: false,
  };
};

export const getQuoteStatus = (
  quote?: IQuoteRespSchema | { status: QuoteStatusEnum } | null | undefined,
): {
  isDraft: boolean;
  isReview: boolean;
  isApproved: boolean;
  isDenied: boolean;
  isSent: boolean;
  isAccepted: boolean;
  isProcessed: boolean;
  isCanceled: boolean;
  isArchived: boolean;
  isAcceptedAdminEditable: boolean;
} => {
  if (quote) {
    return {
      isDraft: quote.status === QuoteStatusEnum.DRAFT,
      isReview: quote.status === QuoteStatusEnum.REVIEW,
      isApproved: quote.status === QuoteStatusEnum.APPROVED,
      isDenied: quote.status === QuoteStatusEnum.DENIED,
      isSent: quote.status === QuoteStatusEnum.SENT,
      isAccepted: quote.status === QuoteStatusEnum.ACCEPTED,
      isProcessed: quote.status === QuoteStatusEnum.PROCESSED,
      isCanceled: quote.status === QuoteStatusEnum.CANCELED,
      isArchived: quote.status === QuoteStatusEnum.ARCHIVED,
      isAcceptedAdminEditable:
        quote.status === QuoteStatusEnum.ACCEPTED_ADMIN_EDITABLE,
    };
  }

  return {
    isDraft: true,
    isReview: false,
    isApproved: false,
    isDenied: false,
    isSent: false,
    isAccepted: false,
    isProcessed: false,
    isCanceled: false,
    isArchived: false,
    isAcceptedAdminEditable: false,
  };
};

export const getQuoteItemAmendStatus = (
  quoteItem?: IQuoteItemRespSchema | null | undefined,
): {
  isAdded: boolean;
  isNoChange: boolean;
  isRemoved: boolean;
  isUpdated: boolean;
} => {
  if (quoteItem) {
    return {
      isAdded: quoteItem.amendmentStatus === QuoteItemAmendmentStatusEnum.ADDED,
      isNoChange:
        quoteItem.amendmentStatus === QuoteItemAmendmentStatusEnum.NO_CHANGE,
      isRemoved:
        quoteItem.amendmentStatus === QuoteItemAmendmentStatusEnum.REMOVED,
      isUpdated:
        quoteItem.amendmentStatus === QuoteItemAmendmentStatusEnum.UPDATED,
    };
  }

  return {
    isAdded: false,
    isNoChange: true,
    isRemoved: false,
    isUpdated: false,
  };
};

export const getQuoteOfferingAmendStatusIsRemoved = (
  quoteOffering?: IQuoteOfferingRespSchema | null | undefined,
): boolean => {
  if (quoteOffering?.items?.length) {
    return quoteOffering.items.every(
      (x: IQuoteItemRespSchema) =>
        x.amendmentStatus === QuoteItemAmendmentStatusEnum.REMOVED,
    );
  }

  return false;
};

/**
 * Displays rate dropdown text in purple if the rate has been changed on an Amendment/renewal
 * Sets input border radius for rate dropdown unless it is a scheduled change
 */
export const getQuoteOfferingRateInputProps = (
  quoteOffering: IQuoteOfferingRespSchema | null | undefined,
  isAmendmentOrRenewal: boolean,
  isChildOffering: boolean,
) => {
  if (
    isAmendmentOrRenewal &&
    quoteOffering &&
    !!quoteOffering.previousRateId &&
    quoteOffering.rateId !== quoteOffering.previousRateId
  ) {
    return { color: 'tPurple.safety' };
  }

  return undefined;
};

export const getQuotePageTitle = (
  quote?: IQuoteRespSchema | null | undefined,
) => (quote ? 'Edit Quote' : 'New Quote');

export const hasQuotePreviousQty = (
  item: IQuoteItem,
  scheduledChangePriorQty?: number,
) => {
  return Boolean(
    isNumber(item?.previousQuantity) || isNumber(scheduledChangePriorQty),
  );
};

export const isQuoteQtyChanged = (
  item: IQuoteItem,
  value: number,
  priorValue?: number,
  isScheduledChange?: boolean,
) => {
  if (
    item?.amendmentStatus !== QuoteItemAmendmentStatusEnum.UPDATED &&
    isScheduledChange
  ) {
    return false;
  }

  return +value !== +priorValue!;
};

export const getQuoteItemDiscountText = (
  item: IQuoteItem,
  currency: string = 'USD',
): string => {
  const {
    customDiscountAmountOrPercent,
    customDiscountType,
    discountPercent,
    discountAmount,
    discounts,
  } = item;
  // discounts created from within the quote are always single (1-per line item, no multiple discounts)
  if (customDiscountAmountOrPercent) {
    return getDiscountText(
      customDiscountAmountOrPercent,
      customDiscountType || AmountUnitTypeEnum.PERCENTAGE,
      currency,
    );
  }
  const discountType =
    multipleDiscountType(discounts) || AmountUnitTypeEnum.PERCENTAGE;
  const value =
    discountType === AmountUnitTypeEnum.FLAT ? discountAmount : discountPercent;
  return getDiscountText(value, discountType, currency);
};

// Checks if the quote type isAmendment and amount is > 0 to show the offering or item lines
export const filterQuoteOfferingsOrQuoteItems =
  (quote: IQuoteRespSchema) => (item: IQuoteItem | IQuoteOffering) => {
    const { isAmendment } = getQuoteType(quote);

    if (isAmendment) {
      if (isQuoteOffering(item)) {
        return item.items?.some(
          ({ amendmentStatus }) =>
            amendmentStatus !== QuoteItemAmendmentStatusEnum.NO_CHANGE,
        );
      }
      return item.amendmentStatus !== QuoteItemAmendmentStatusEnum.NO_CHANGE;
    }

    return true;
  };

export const getFirstLetter = (value: string, isUpperCase: boolean = true) => {
  if (!value || !isString(value)) {
    return '';
  }
  const firstLetter = value.charAt(0).toUpperCase();
  if (!isUpperCase) {
    return firstLetter.toLowerCase();
  }
  return firstLetter;
};

export const getColorFromLetter = (firstLetter: string) => {
  const letterIndex = firstLetter.charCodeAt(0) % 4;

  switch (letterIndex) {
    case 0:
      return '#18A800';

    case 1:
      return '#8C32FF';

    case 2:
      return '#FC6C22';

    default:
      return '#09C4F9';
  }
};

// colors shared by various components for displaying status property
const GreenBadgeStyle = {
  color: 'tGreen.mDarkShade',
  bgColor: 'tGreen.light',
};
const GrayBadge = {
  color: 'tGray.darkPurple',
  bgColor: 'tGray.back',
};
export const NeutralStyle = {
  color: 'tGray.darkPurple',
  bgColor: 'transparent',
};

const RedTextStyle = {
  color: 'tRed.base',
  bgColor: 'transparent',
};

export const getStatusStyle = (status?: string) => {
  if (!status) {
    return {};
  }

  switch (status.toLowerCase()) {
    case 'active':
    case 'paid':
    case 'won':
    case 'processed':
    case 'accepted':
    case 'applied':
      return GreenBadgeStyle;
    case 'denied':
    case 'lost':
    case 'error':
      return RedTextStyle;
    case 'inactive':
    case 'unpaid':
      return GrayBadge;
    default:
      return NeutralStyle;
  }
};

export const getVariantByStatus = (status?: string) => {
  if (!status) {
    return 'neutral';
  }

  switch (status.toLocaleLowerCase()) {
    case 'draft':
    case 'review':
    case 'approved':
    case 'canceled':
    case 'sent':
      return 'neutral';
    case 'processed':
    case 'active':
    case 'paid':
    case 'won':
    case 'success':
    case 'invoiced':
    case 'succeeded':
      return 'green';
    case 'accepted':
      return 'greenText';
    case 'denied':
    case 'lost':
    case 'failed':
    case 'voided':
      return 'red';
    case 'archived':
    case 'expired':
      return 'neutralItalic';
    case 'primary':
    case 'current tenant':
    case 'migrated':
    case 'applied':
      return 'blue';
    case 'test':
    case 'rebilled invoice':
      return 'orange';
    case 'amendment':
    case 'manual renewal':
    case 'renewal':
    case 'live':
      return 'purple';
    case 'unpaid':
    case 'not invoiced':
      return 'gray';
    default:
      return 'neutral';
  }
};

/**
 * Determines if a word should be shown in plural or singular form.
 *
 * Used when showing text such as like `1,024 accounts` or `1 account`.
 *
 * Examples:
 * * `getPluralOrSingular('result', 1) => 'result'`
 * * `getPluralOrSingular('result', 0) => 'results'`
 * * `getPluralOrSingular('result', 42) => 'results'`
 *
 * @param word String that will be turned into plural based on number of items
 * @param length number of items
 * @param plural defaults to `s` but can be changed based on the base word
 * @returns singular or plural version of the word
 */
export function pluralize(word: string, length: number, plural = 's'): string {
  if (length !== 1) {
    return `${word}${plural}`;
  }
  return word;
}
/**
 * Allows type narrowing from IQuoteItem to IQuoteOffering
 *
 * @param offeringOrItem
 * @returns
 */
export const isQuoteOffering = (
  offeringOrQuoteItem: IQuoteItem | IQuoteOffering,
): offeringOrQuoteItem is IQuoteOffering => {
  return Array.isArray((offeringOrQuoteItem as any)?.items);
};

export const sortByProductType = <T extends { productType: ProductTypeEnum }>(
  items: T[],
  secondSortKey = 'productName',
  thirdSortKey = 'name',
  sortingArr = PRODUCT_TYPE_ORDER,
): T[] => {
  if (Array.isArray(items)) {
    return items.sort(
      (a, b) =>
        sortingArr.indexOf(a.productType) - sortingArr.indexOf(b.productType) ||
        sortAlphabetically(secondSortKey)(a, b) ||
        sortAlphabetically(thirdSortKey)(a, b) ||
        sortByCreateDate(a, b),
    );
  }
  return [];
};

export const sortInvoiceItems = (items: IInvoiceItemRespSchema[]) => {
  if (Array.isArray(items)) {
    return items.sort(
      (a, b) =>
        PRODUCT_TYPE_ORDER.indexOf(a.productType) -
          PRODUCT_TYPE_ORDER.indexOf(b.productType) ||
        sortAlphabetically('productName')(a, b) ||
        sortAlphabetically('name')(a, b) ||
        differenceInDays(
          new Date(a.periodStartDate),
          new Date(b.periodStartDate),
        ),
    );
  }
  return [];
};

export const sortByProductObjType = <
  T extends {
    product?: {
      name?: string;
      productType: ProductTypeEnum;
      createDate?: string;
    };
  },
>(
  items: T[],
  secondSortKey = 'name',
  sortingArr = PRODUCT_TYPE_ORDER,
): T[] => {
  if (Array.isArray(items)) {
    return items.sort((a, b) => {
      if ('product' in a && 'product' in b) {
        const sortObjA = a.product!;
        const sortObjB = b.product!;
        return (
          sortingArr.indexOf(sortObjA.productType) -
            sortingArr.indexOf(sortObjB.productType) ||
          sortAlphabetically(secondSortKey)(sortObjA, sortObjB) ||
          sortByCreateDate(sortObjA, sortObjB)
        );
      } else {
        return 0;
      }
    });
  }
  return [];
};

/**
 * Groups flat list of products by their subscription id.
 * Sorts the groupings by offering name, rate name, subscription id.
 * Sorts each product list by standard product type order.
 * This grouping and sorting on the front-end is meant to be temporary -- it should be served sorted by the API.
 *
 * @param allProducts - array of products or product-like objects (ie. invoice line items)
 * @returns Map of product object arrays keyed by subscription ID
 */
export const groupProductsBySubscription = (
  allProducts: IInvoiceItemRespSchema[],
) => {
  const sortedBySubscription = allProducts.sort(
    (a, b) =>
      sortAlphabetically('offeringName')(a, b) ||
      sortAlphabetically('rateName')(a, b) ||
      sortAlphabetically('subscriptionItemId')(a, b),
  );

  const groupedBySubscription: Map<string, IInvoiceItemRespSchema[]> =
    sortedBySubscription.reduce(
      (
        res: Map<string, IInvoiceItemRespSchema[]>,
        invoiceItem,
      ): Map<string, IInvoiceItemRespSchema[]> => {
        let key = invoiceItem.subscriptionId;
        // Never group min commit lines, since the credit for prior period and charge in advance should never be shown together
        if (invoiceItem.offeringType === OfferingTypesEnum.MIN_COMMIT) {
          key = invoiceItem.id;
        }
        const mappedProducts = res.get(key);
        if (mappedProducts) {
          res.set(key, [...mappedProducts, invoiceItem]);
        } else {
          res.set(key, [invoiceItem]);
        }
        return res;
      },
      new Map(),
    );

  // sort products within each group
  groupedBySubscription.forEach((products, subscriptionId, subscriptionMap) => {
    const sortedProducts = sortInvoiceItems(products);
    subscriptionMap.set(subscriptionId, sortedProducts);
  });
  return [...groupedBySubscription.values()];
};

export const filterInactiveContacts = (
  contacts: IContactRespSchema[],
  alwaysIncludeContactId?: string, //if it's already saved inactive contactId, should return that contact
) => {
  return (contacts || []).filter(
    ({ id, status }) =>
      status !== ContactStatusEnum.CANCELED || id === alwaysIncludeContactId,
  );
};

export function getSafeFilename(filename: string) {
  filename = filename || '';
  return filename.replace(SAFE_FILENAME, '_');
}
export const isProrationDisplayable = (
  proration: number | null | undefined,
  productType: ProductTypeEnum,
): boolean =>
  !!proration &&
  PRODUCT_TYPES_SUPPORTING_PRORATION.includes(productType) &&
  proration > 0 &&
  proration !== 1;

export const prorationLabel = (proration: number): string =>
  // NOTE: assumes that for decimal values we display singluar, ie. Period: 0.34
  pluralize('Period', proration);

export const sortDateByAscendingOrder = (
  unOrderData: any,
  sortByField: string,
) => {
  if (Array.isArray(unOrderData)) {
    return sortBy(unOrderData, [sortByField]);
  }
  return [];
};

/**
 * Given a value, returns a boolean.
 * Generally used when a boolean value is stored in a URL as a string.
 * The following strings are considered true: 'true', 'TRUE', 'T', 't'
 */
export function ensureBoolean(value: string | boolean | null | undefined) {
  if (isBoolean(value)) {
    return value;
  } else if (isString(value)) {
    return value.toLowerCase().startsWith('t');
  }
  return false;
}

/**
 * {@link https://stackoverflow.com/questions/21797299/convert-base64-string-to-arraybuffer}
 */
export function base64ToArrayBuffer(base64Str: string) {
  const binaryStr = window.atob(base64Str);
  const bytes = new Uint8Array(binaryStr.length);
  for (let i = 0; i < binaryStr.length; i++) {
    bytes[i] = binaryStr.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * {@link https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string}
 */
export async function arrayBufferToBase64(array: ArrayBuffer): Promise<string> {
  return new Promise((resolve) => {
    const blob = new Blob([array]);
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result) {
        const dataUrl = event.target.result as string;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, base64] = dataUrl.split?.(',') || [];
        return resolve(base64);
      }
      resolve('');
    };

    reader.readAsDataURL(blob);
  });
}

/**
 * Returns array of email from string of comma, space, newline separated string
 *
 * getEmailsFromString(`tawsif+11@monetizenow.io, abir@abir.com
 * sabbir@sabbir.com
 * asss@sdd.com    ssas+@ss.com  assssa222@122.com,`) // =>  [
 * 'tawsif+11@monetizenow.io',
 * 'abir@abir.com',
 * 'sabbir@sabbir.com',
 * 'asss@sdd.com',
 * 'ssas+@ss.com',
 * 'assssa222@122.com',
 *]
 *
 */
export function getEmailsFromString(value: string) {
  return value
    .trim()
    .replace(/[,\r\n\s]/g, ',')
    .split(',')
    .filter(Boolean);
}

/**
 * Returns the plural or singular form of a word based on the number of items.
 *
 * @example
 * ```ts
 * inflect({ singular: 'person', plural: 'people', count: 1 }); // => 'person'
 * inflect({ singular: 'person', plural: 'people', count: 2 }); // => 'people'
 * ```
 */
export function inflect({
  singular,
  plural,
  count,
}: {
  singular: string;
  plural: string;
  count: number;
}): string {
  return count === 1 ? singular : plural;
}

async function canWriteClipboard() {
  const write = await navigator.permissions.query({
    name: 'clipboard-write' as any,
  });
  return write.state === 'granted';
}

export async function copyToClipboard(content: string): Promise<boolean> {
  try {
    const hasWritePermission = await canWriteClipboard();

    if (!hasWritePermission) {
      return false;
    }

    await navigator.clipboard.writeText(content);
    return true;
  } catch (error) {
    logger.error(error);
    return false;
  }
}

/**
 * Based on the totalElements, filters length, search input and page number it determines true empty list state
 *
 * @example
 * ```ts
 * getIsTrulyEmptyList({ loading: false, totalElements: 0, filters: [], searchTerm: '', page: 0}) => true
 * getIsTrulyEmptyList({ loading: false, totalElements: 10, filters: [], searchTerm: '', page: 0}) => false
 * ```
 */
export function getIsTrulyEmptyList({
  loading,
  totalElements,
  filters,
  searchTerm = '',
  page,
}: {
  loading: boolean;
  totalElements: number;
  filters?: FilterType | FilterType[] | null;
  searchTerm?: string;
  page: number;
}) {
  return (
    !loading &&
    page === 0 &&
    totalElements === 0 &&
    getFiltersApplied(filters) === 0 &&
    searchTerm.trim().length === 0
  );
}

/**
 * Get a list of months between two dates in a format that can be used in a dropdown
 *
 * @param startDate
 * @param numMonthsOrEndDate
 * @param options
 * @returns
 */
export const getMonthlyDropdownItems = (
  startDate: Date,
  numMonthsOrEndDate: Date | number,
  options: {
    order?: 'asc' | 'desc';
    labelDateFormat?: string;
    valueDateFormat?: string;
  } = {},
): { label: string; value: string }[] => {
  const {
    order = 'desc',
    labelDateFormat = 'MMM yyyy',
    valueDateFormat = 'yyyy-MM',
  } = options;
  let currentDate = startOfMonth(startDate);
  const endDate = isDate(numMonthsOrEndDate)
    ? startOfMonth(numMonthsOrEndDate)
    : addMonthsCustom(currentDate, numMonthsOrEndDate);
  const output: {
    label: string;
    value: string;
  }[] = [];

  if (isAfter(currentDate, endDate)) {
    throw new Error('The start date must be before the end date.');
  }

  while (!isSameMonth(currentDate, endDate)) {
    output.push({
      label: formatDate(currentDate, labelDateFormat),
      value: formatDate(currentDate, valueDateFormat),
    });
    currentDate = addMonthsCustom(currentDate, 1);
  }
  return order === 'asc' ? output : output.reverse();
};

/**
 * Calculate all the periods between two dates and return as strings in a given format
 * Useful for filling in data for a chart that should span between two date ranges
 */
export function getPeriodsForRange(
  startDate: Date,
  endDate: Date,
  interval: 'DAY' | 'MONTH',
  format: string,
) {
  const startOfFn = interval === 'DAY' ? startOfDay : startOfMonth;
  const periods: string[] = [];
  startDate = startOfFn(startDate);
  endDate = startOfFn(endDate);
  let currentDate = startDate;
  const intervalFn = interval === 'DAY' ? addDays : addMonthsCustom;

  if (isAfter(startDate, endDate)) {
    throw new Error('The start date must be before the end date.');
  }

  while (!isAfter(currentDate, endDate)) {
    periods.push(formatDate(currentDate, format));
    currentDate = intervalFn(currentDate, 1);
  }
  return periods;
}

/**
 * For a list of fields, flatten the object to only contain those fields and flatten nested objects
 * Fields can included nested entities using dot notation
 *
 * @param items
 * @param fields
 * @param transformFn optional function to transform the value of the field, called after lodashGet
 * @returns
 */
export function flattenObj(
  items: any[],
  fields: string[],
  transformFn?: Record<string, (fieldValue: any, record: any) => any>,
): any[] {
  return items.map((item) => {
    const obj: any = {};
    fields.forEach((field) => {
      if (transformFn && transformFn[field]) {
        try {
          obj[field] = transformFn[field](lodashGet(item, field), item);
        } catch (ex) {
          logger.warn('Error transforming field, skipping', ex);
        }
        return;
      } else {
        obj[field] = lodashGet(item, field);
      }
    });
    return obj;
  });
}

/**
 * For a list of objects, return a predicate function to search across multiple fields
 * If the search term is multiple words, then each word will be matched individually and all must match to return a value
 * This is a basic form of fuzzy searching, but does not account for typos
 *
 * @param props Array of keys from item
 * @param value search term
 * @returns a predecate function that can be used in filter function
 */
export function multiWordObjectFilter<T>(
  props: Array<keyof T>,
  value: string,
): (value: T, index: number, array: T[]) => boolean {
  value = value || '';
  const search = value.toLocaleLowerCase().split(' ');
  const hasValue = search.length > 0;
  return (item: T) => {
    if (!hasValue || !item) {
      return true;
    }
    const normalizedValue = props
      .map((prop) => (item[prop] ?? '').toString())
      .join()
      .toLocaleLowerCase();
    return search.every((word) => normalizedValue.includes(word)) || false;
  };
}

/**
 * @param value Label Name of Custom Fields Form. (ex. Test Name Label)
 * @returns  value with underscore, all space replaced by_. (ex: test_name_label)
 */
export const getKeyFromLabel = (value: string) => {
  if (!value) {
    return '';
  }
  return value
    .trim()
    .toLowerCase()
    .replace(/[^a-z]+/g, '_') // replace all non-alphabetic characters with underscores
    .replace(/__+/g, '_') // don't allow multiple underscores
    .replace(/(^_+)|(_+$)/g, '') // don't allow leading or trailing underscores
    .substring(0, 40);
};

/**
 * Converts a label to a value that can be used as a key in an object
 */
export const getValueFromLabel = (value: string) => {
  if (!value) {
    return '';
  }
  return value
    .trim()
    .replace(/\s+/g, '_') // replace all spaces with underscores
    .replace(/__+/g, '_') // don't allow multiple underscores
    .replace(/(^_+)|(_+$)/g, '') // don't allow leading or trailing underscores
    .substring(0, 40);
};

/**
 * Examples
 * `countDecimals(2) => 0`
 * `countDecimals(2.1) => 1`
 * `countDecimals(2.12) => 2`
 * @param value {number}
 * @returns total number of decimals
 */
export const countDecimals = function (value: number) {
  if (value % 1 != 0) {
    return value.toString().split('.')[1].length;
  }
  return 0;
};

/**
 * Examples:
 * * `transformValueToMinimumTwoDecimalPlaces(2) => '2.00'`
 * * `transformValueToMinimumTwoDecimalPlaces(2.1) => '2.10'`
 * * `transformValueToMinimumTwoDecimalPlaces(2.12) => '2.12'`
 * * `transformValueToMinimumTwoDecimalPlaces(2.12312312) => '2.12312312'`
 * * `transformValueToMinimumTwoDecimalPlaces('2') => '2.00'`
 * * `transformValueToMinimumTwoDecimalPlaces('2.1') => '2.10'`
 * * `transformValueToMinimumTwoDecimalPlaces('2.12') => '2.12'`
 * * `transformValueToMinimumTwoDecimalPlaces('2.12312312') => '2.12312312'`
 * * `transformValueToMinimumTwoDecimalPlaces('asd') => ''`
 * @param value {number | string}
 * @returns string with a minimum 2 decimal places if the value is not a fraction
 */

export const transformValueToMinimumTwoDecimalPlaces = (
  value: string | number,
) => {
  let valueToTransform: number;

  if (typeof value === 'string') {
    valueToTransform = Number(value);
  } else {
    valueToTransform = value;
  }

  if (isNaN(valueToTransform)) {
    return '';
  }

  if (Number.isInteger(valueToTransform)) {
    return valueToTransform.toFixed(2);
  }

  if (countDecimals(valueToTransform) < 2) {
    return valueToTransform.toFixed(2);
  }

  return valueToTransform.toString();
};

export const sortByOfferingType = (
  items: IOfferingResUI[],
  secondSortKey = 'name',
  sortingArr = OFFERING_TYPES_ORDER,
) => {
  if (Array.isArray(items)) {
    return items.sort(
      (a, b) =>
        sortAlphabetically(secondSortKey)(a, b) ||
        sortingArr[a.type] - sortingArr[b.type],
    );
  }
  return [];
};

export const filterAndSortOfferings = (
  offerings: IOfferingResUI[],
  type: OfferingTypesEnum,
): IOfferingRes[] => {
  return offerings
    .filter(
      (offering: IOfferingResUI) =>
        offering.type === type && !!offering.rates?.length,
    )
    .sort(sortByAlphabetically()); // Adjust sorting function as needed
};

export const deDuplicateRecords = <T>(
  records: T[],
  key: keyof T,
  /**
   * Which value should "win" when there are duplicates
   */
  keep: 'first' | 'last' = 'last',
): T[] => {
  return Array.from(
    records
      .reduce((acc, record) => {
        if (!acc.has(record[key] as string) || keep === 'last') {
          acc.set(record[key] as string, record);
        }
        return acc;
      }, new Map<string, T>())
      .values(),
  );
};

/**
 * Wrapper function to save a file from an API that returns an ArrayBuffer
 *
 * @param dataFetchFn Callback to allow caller to manage the API that is called to fetch the data
 */
export const downloadAndSaveToPDF = async (
  dataFetchFn: () => Promise<{ data: ArrayBuffer; fileName: string }>,
) => {
  try {
    const { data, fileName } = await dataFetchFn();
    downloadBlobAsFile(data, fileName);
  } catch (err) {
    handleApiErrorToast(err);
  }
};

export function shortenID(
  text: string,
  firstChars: number,
  lastChars: number,
): string {
  if (!text) {
    return '';
  }
  const textLength = text.length;
  if (textLength <= firstChars + lastChars) {
    return text;
  }
  return `${text.slice(0, firstChars)}...${text.slice(-lastChars)}`;
}

/**
 *
 * @param inputNumber number with decimal point
 * @returns length of the decimal points
 */
export const getDecimalPointsLength = (inputNumber: number) => {
  return `${inputNumber}`.split('.')[1]?.length;
};

export const getFirstValue = <T>(compareTo: T | T[]) => {
  if (Array.isArray(compareTo)) {
    return compareTo[0];
  } else {
    return compareTo;
  }
};

export const isApiListResponse = <T = unknown>(
  value: unknown,
): value is ApiListResponse<T> => {
  if (!value || typeof value !== 'object') {
    return false;
  }
  return (
    'content' in value &&
    Array.isArray(value.content) &&
    'totalElements' in value &&
    'totalPages' in value
  );
};
