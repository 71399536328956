import { Divider } from '@chakra-ui/react';
import { FC } from 'react';
import { MPageLoader } from '../../../../../components/Monetize';
import { useFlags } from '../../../../../services/launchDarkly';
import { IQuoteRequestSchema, IQuoteRespSchema } from '../../../../../types';
import { QuoteDocumentsUploadItem } from './QuoteDocumentsUploadItem';
import { useQuoteDocuments } from './useQuoteDocuments';

interface QuoteDocumentsBodyProps {
  quote: IQuoteRespSchema;
  saving: boolean;
  isReadOnly: boolean;
  showDivider?: boolean;
  setSaving: (value: boolean) => void;
  onUpdate: (data: IQuoteRequestSchema, requestType: string) => void;
  handleClose: () => void;
}

export const QuoteDocumentsBody: FC<QuoteDocumentsBodyProps> = ({
  quote,
  saving,
  isReadOnly,
  showDivider = false,
  onUpdate,
  setSaving,
  handleClose,
}: QuoteDocumentsBodyProps) => {
  const featureFlags = useFlags();
  const {
    quoteDocument,
    msaData,
    sowData,
    pdfCoverData,
    pdfFooterData,
    isLoadingInitialData,

    handleSave,
    handleActiveItemChange,
    handleAddItem,
    handleRemoveItem,
    handleGoToItem,
  } = useQuoteDocuments({
    quote,
    setSaving,
    saving,
    onUpdate,
    handleClose,
  });

  return (
    <>
      {isLoadingInitialData && <MPageLoader maxH={100} />}

      {!isLoadingInitialData && (
        <form id="quote-documents-form" onSubmit={handleSave}>
          {featureFlags.pdfCoverFooter && (
            <>
              <QuoteDocumentsUploadItem
                label="Cover"
                showDivider={showDivider}
                activeItem={pdfCoverData.activeItem}
                linkUrl={pdfCoverData.linkUrl}
                savedItemText={pdfCoverData.savedItemText}
                disabled={saving}
                isReadOnly={isReadOnly}
                onSetActiveItem={(item) =>
                  handleActiveItemChange('cover', item)
                }
                onAddItem={(data) => handleAddItem('cover', data)}
                onRemoveItem={() => handleRemoveItem('cover')}
                onGotoSavedItem={() => handleGoToItem('cover')}
              />

              {showDivider && (
                <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />
              )}

              <QuoteDocumentsUploadItem
                label="Footer"
                showDivider={showDivider}
                activeItem={pdfFooterData.activeItem}
                linkUrl={pdfFooterData.linkUrl}
                savedItemText={pdfFooterData.savedItemText}
                disabled={saving}
                isReadOnly={isReadOnly}
                onSetActiveItem={(item) =>
                  handleActiveItemChange('footer', item)
                }
                onAddItem={(data) => handleAddItem('footer', data)}
                onRemoveItem={() => handleRemoveItem('footer')}
                onGotoSavedItem={() => handleGoToItem('footer')}
              />

              {showDivider && (
                <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />
              )}
            </>
          )}

          <QuoteDocumentsUploadItem
            label="Statement of Work (SOW)"
            showDivider={showDivider}
            activeItem={sowData.activeItem}
            linkUrl={sowData.linkUrl}
            savedItemText={sowData.savedItemText}
            disabled={saving}
            isReadOnly={isReadOnly}
            onSetActiveItem={(item) => handleActiveItemChange('sow', item)}
            onAddItem={(data) => handleAddItem('sow', data)}
            onRemoveItem={() => handleRemoveItem('sow')}
            onGotoSavedItem={() => handleGoToItem('sow')}
          />

          {showDivider && (
            <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />
          )}

          <QuoteDocumentsUploadItem
            label="Master Service Agreement (MSA)"
            allowLink
            showDivider={showDivider}
            activeItem={msaData.activeItem}
            linkUrl={msaData.linkUrl}
            savedItemText={msaData.savedItemText}
            disabled={saving}
            isReadOnly={isReadOnly}
            onSetActiveItem={(item) => handleActiveItemChange('msa', item)}
            onAddItem={(data) => handleAddItem('msa', data)}
            onRemoveItem={() => handleRemoveItem('msa')}
            onGotoSavedItem={() => handleGoToItem('msa')}
          />

          {showDivider && (
            <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />
          )}

          {quoteDocument.data && (
            <>
              {showDivider && (
                <Divider my={6} borderWidth="1px" borderColor="tBlue.hover" />
              )}

              <QuoteDocumentsUploadItem
                label="Signed Quote"
                activeItem="none"
                linkUrl=""
                showDivider={showDivider}
                savedItemText={quoteDocument.data.filename}
                disabled
                isReadOnly
                onGotoSavedItem={() => handleGoToItem('signedQuote')}
              />
            </>
          )}
        </form>
      )}
    </>
  );
};
