import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ApprovalQuoteItem from '../../../components/ApprovalSteps/ApprovalQuoteItem';
import {
  MBox,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MText,
} from '../../../components/Monetize';
import { ChangeOwner } from '../../../components/Monetize/ChangeOwner';
import { MAutoSizeInput } from '../../../components/Monetize/MAutoSizeInput';
import { ROLES } from '../../../constants';
import { QUOTE_EDIT_ACTIONABLE_STATUSES } from '../../../constants/quotes';
import { getAccountQuotesRoute } from '../../../constants/routes';
import { useACL } from '../../../services/acl/acl';
import { useAuth } from '../../../services/auth0';
import { useDocumentHead } from '../../../services/documentHead';
import { useFlags } from '../../../services/launchDarkly';
import {
  CollaborationAccessEnum,
  CollaborationAccessLevelEnum,
  IQuoteRequestSchema,
} from '../../../types';
import { getQuotePageTitle, getQuoteStatus } from '../../../utils';
import { ManualQuoteAcceptanceModal } from './ManualQuoteAcceptanceModal';
import QuoteFormBody from './QuoteFormBody';
import QuoteFormHeader from './QuoteFormHeader';
import { QuoteCollaborationPopover } from './components/QuoteCollaborationPopover';
import { QuotePageActions } from './components/QuotePageActions';
import { QuotePrimaryBadge } from './components/QuotePrimaryBadge';
import { QuoteStickyMenu } from './components/QuoteStickyMenu';
import { QuoteTags } from './components/QuoteTags';
import QuoteContactsDrawer from './components/contacts/QuoteContactsDrawer';
import { QuoteBanners } from './quoteBanners/QuoteBanners';
import { useQuoteContext } from './quoteContext';

export const QuoteForm = () => {
  const { canDo } = useACL();
  const {
    isReadOnly,
    isError,
    quoteData,
    quoteStateData,
    methods,
    opportunityLinkData,
    reviewQuoteModalData,
    manualRenewalFormMethods,
    showPrimaryContactRequiredBg,
    isInternalView,
    actionRunning,
    isEsignEnabled,
    setIsInternalView,
    handleChangeOwner,
    handleSubmitButton,
    handleBackButton,
    handleSubmitButtonWithoutDirtyCheck,
    handleEdit,
    handleSendQuote,
    handleRecreateQuote,
  } = useQuoteContext();

  const {
    onOpen: onOpenManualAcceptanceModal,
    onClose: onCloseManualAcceptanceModal,
    isOpen: isOpenManualAcceptanceModal,
  } = useDisclosure();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IQuoteRequestSchema>();

  const {
    loading: quoteLoading,
    quote,
    setQuote,
    fetchQuote,
    evaluateQuoteRules,
    updateQuoteSigningOrder,
    signingOrderUI,
  } = quoteData;

  const isActionLoading = actionRunning || quoteLoading;

  // NOTE: Leave these setDocTitle within the index.tsx and ReviewQuote.tsx
  const { setDocTitle } = useDocumentHead();
  const [contactDrawerOpen, setContactDrawerOpen] = useState(false);

  const { quoteInternalExternalView } = useFlags();
  const { currentTenantUserHasRole } = useAuth();
  const hasValidRole = currentTenantUserHasRole(ROLES.QUOTES_ROLES);

  useEffect(() => {
    setDocTitle(
      quote?.id &&
        `Edit Quote${quote.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description, quote?.id, setDocTitle]);

  const handleOpenContactDrawer = () => {
    setContactDrawerOpen(true);
  };

  const handleCloseContactDrawer = (didChange?: boolean) => {
    setContactDrawerOpen(false);
    didChange && quote?.id && evaluateQuoteRules(quote.id);
    didChange && quote?.id && fetchQuote(quote.id, true);
  };

  const title = getQuotePageTitle(quote);

  if (!quote) {
    return <MPageLoader />;
  }

  const { isProcessed, isCanceled, isArchived, isDraft } =
    getQuoteStatus(quote);

  const canUpdateSales = canDo([['sales', 'update']]);
  const hasEditAccess =
    canUpdateSales || quote.accessLevel === CollaborationAccessLevelEnum.READ;

  const canEditQuote =
    hasEditAccess &&
    QUOTE_EDIT_ACTIONABLE_STATUSES.has(quote.status) &&
    hasValidRole;

  return (
    <MPageContainer data-testid="quote-form">
      <form onSubmit={handleSubmitButton} style={{ width: '100%' }}>
        <MPageHeader
          title={
            <Controller
              defaultValue=""
              name="description"
              control={control}
              render={({ field: { onBlur, value, ref, ...rest } }) => (
                <MAutoSizeInput
                  aria-label="description"
                  fontSize="2xl"
                  fontWeight="700"
                  value={value || ''}
                  isDisabled={isReadOnly}
                  isReadOnly={isReadOnly}
                  onBlur={() => {
                    onBlur();
                    value && handleSubmitButton();
                  }}
                  {...rest}
                />
              )}
            />
          }
          firstRowPlaceholder={
            !!errors.description?.message && (
              <MText color="tRed.base" fontSize="sm">
                {errors.description.message}
              </MText>
            )
          }
          tag={
            <QuoteTags
              quote={quote}
              setQuote={setQuote}
              manualRenewalFormMethods={manualRenewalFormMethods}
            />
          }
          hasBackButton
          backButtonTitle="Go Back"
          backButtonCallback={handleBackButton}
          parentLink={getAccountQuotesRoute(quote.accountId)}
          parentLinkTitle={quote.accountName}
          copyUrl
          id={quote?.id}
          customIdCopyBox={
            <QuoteCollaborationPopover
              quoteId={quote.id}
              quoteStatus={quote.status}
              collaborationAccess={quote.collaborationAccess}
              accessLevel={quote.accessLevel}
              onChange={(collaborationAccess: CollaborationAccessEnum) => {
                setValue('collaborationAccess', collaborationAccess, {
                  shouldDirty: true,
                });
                handleSubmitButtonWithoutDirtyCheck();
              }}
            />
          }
          subTitle={
            <MBox maxWidth="17.5rem">
              <ChangeOwner
                isReadOnly={isReadOnly}
                value={quote?.owner}
                ownerName={quote?.ownerName}
                onChange={(e) => handleChangeOwner(String(e))}
              />
            </MBox>
          }
          extraSubtitleParts={
            quote.id === quote.opportunity?.primaryQuoteId
              ? [<QuotePrimaryBadge />]
              : []
          }
        >
          <QuotePageActions
            isEsignEnabled={isEsignEnabled}
            hasValidRole={hasValidRole}
            fetchQuote={fetchQuote}
            isError={isError}
            isLoading={quoteData.saving}
            isReadOnly={isReadOnly}
            manualRenewalFormMethods={manualRenewalFormMethods}
            opportunityLinkData={opportunityLinkData}
            quote={quote}
            quoteInternalExternalView={quoteInternalExternalView}
            reviewQuoteModalData={reviewQuoteModalData}
            setContactDrawerOpen={setContactDrawerOpen}
            setQuote={setQuote}
            showPrimaryContactRequiredBg={showPrimaryContactRequiredBg}
            isInternalView={isInternalView}
            setIsInternalView={setIsInternalView}
            handleEditQuoteClick={handleEdit}
            canEditQuote={canEditQuote}
            isActionLoading={isActionLoading}
            handleRecreateQuote={handleRecreateQuote}
            handleSendQuote={handleSendQuote}
            onOpenManualAcceptanceModal={onOpenManualAcceptanceModal}
            quoteFormMethods={methods}
            quoteStateData={quoteStateData}
          />
          <QuoteStickyMenu
            title={title}
            quote={quote}
            manualRenewalFormMethods={manualRenewalFormMethods}
            showPrimaryContactRequiredBg={showPrimaryContactRequiredBg}
            opportunityLinkData={opportunityLinkData}
            handleBackButton={handleBackButton}
            fetchQuote={fetchQuote}
            setContactDrawerOpen={setContactDrawerOpen}
            setQuote={setQuote}
            isReadOnly={isReadOnly}
            isInternalView={isInternalView}
            setIsInternalView={setIsInternalView}
            hasValidRole={hasValidRole}
            handleRecreateQuote={handleRecreateQuote}
            handleSendQuote={handleSendQuote}
            isActionLoading={isActionLoading}
            onOpenManualAcceptanceModal={onOpenManualAcceptanceModal}
            quoteFormMethods={methods}
            quoteStateData={quoteStateData}
            reviewQuoteModalData={reviewQuoteModalData}
          />
        </MPageHeader>

        <MBox flex="1" pl={8} pr={2}>
          <QuoteBanners />

          {quote && (
            <ApprovalQuoteItem
              quote={quote}
              approvals={quote.approvals}
              reloadData={() => {
                quote?.id && fetchQuote(quote.id);
              }}
            />
          )}
          <MBox mt={12}>
            <QuoteFormHeader onOpenContactDrawer={handleOpenContactDrawer} />
            <QuoteFormBody />
          </MBox>
        </MBox>
      </form>
      {quote?.contacts && contactDrawerOpen && (
        <QuoteContactsDrawer
          quote={quote}
          accountId={quote.accountId!}
          quoteContacts={quote.contacts}
          onClose={handleCloseContactDrawer}
          updateQuoteSigningOrder={updateQuoteSigningOrder}
          signingOrderUI={signingOrderUI}
          isReadOnly={isReadOnly}
        />
      )}
      <ManualQuoteAcceptanceModal
        isOpen={isOpenManualAcceptanceModal}
        quoteId={quote.id}
        onClose={() => {
          onCloseManualAcceptanceModal();
          fetchQuote(quote.id);
        }}
      />
    </MPageContainer>
  );
};
