import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import {
  MButton,
  MCustomIconButton,
  MDivider,
  MFlex,
  MFormField,
  MInput,
} from '../../../../../components/Monetize';
import {
  IQuoteItemRespSchema,
  IQuoteOfferingReqSchema,
} from '../../../../../types';

export interface QuoteItemDiscountEditPopoverProps {
  control: Control<IQuoteOfferingReqSchema>;
  errors: any;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
  index: number;
  disabled?: boolean;
  itemFromServer: IQuoteItemRespSchema;
  handleQuoteOffering: () => void;
}

/**
 * Popover to allow editing the name and description for a custom discount
 */
export const QuoteItemDiscountEditPopover: FC<
  QuoteItemDiscountEditPopoverProps
> = ({
  control,
  setValue,
  errors,
  index,
  disabled,
  itemFromServer,
  handleQuoteOffering,
}: QuoteItemDiscountEditPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef<HTMLInputElement | null>(null);

  function handleClose() {
    handleQuoteOffering();
    onClose();
  }

  useEffect(() => {
    if (isOpen) {
      setValue(
        `items.${index}.customDiscountName`,
        itemFromServer.customDiscountName,
      );

      setValue(
        `items.${index}.customDiscountDescription`,
        itemFromServer.customDiscountDescription,
      );
    }
  }, [onOpen, isOpen]);

  return (
    <Popover
      initialFocusRef={firstFieldRef}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      placement="right-start"
      size={'sm'}
      isLazy
    >
      <PopoverTrigger>
        <MCustomIconButton
          icon={MdEdit}
          bg="none"
          color="tPurple.dark"
          iconColorHover="tPurple.base"
          btnSize={4}
          padding={0}
          ml={1}
          containerSize={5}
          isDisabled={disabled}
        />
      </PopoverTrigger>
      <PopoverContent p={4} border="0" boxShadow="popover" w={'297px'}>
        <FocusLock returnFocus persistentFocus={false}>
          <PopoverArrow mt="1" />
          <MFormField
            error={errors?.items?.[index]?.customDiscountName}
            label="Discount Name"
          >
            <Controller
              name={`items.${index}.customDiscountName`}
              control={control}
              defaultValue=""
              render={({ field: { value, ref, ...rest } }) => (
                <MInput
                  ref={(el) => {
                    ref(el);
                    firstFieldRef.current = el;
                  }}
                  value={value || ''}
                  isDisabled={disabled}
                  maxLength={45}
                  {...rest}
                />
              )}
            />
          </MFormField>
          <MFormField
            error={errors?.items?.[index]?.customDiscountDescription}
            label="Description"
            mt={2}
          >
            <Controller
              name={`items.${index}.customDiscountDescription`}
              control={control}
              defaultValue=""
              render={({ field: { value, ...rest } }) => (
                <MInput
                  value={value || ''}
                  isDisabled={disabled}
                  placeholder="Add description to the custom discount"
                  maxLength={100}
                  {...rest}
                />
              )}
            />
          </MFormField>
          <MDivider mt={3} mb={2} />
          <MFlex justify="flex-end">
            <MButton
              variant="primary"
              isDisabled={disabled}
              onClick={handleClose}
            >
              OK
            </MButton>
          </MFlex>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
