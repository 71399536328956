import { toDateShort } from '@monetize/utils/core';
import {
  MAlert,
  MButton,
  MFlex,
  MPopover,
  MPopoverArrow,
  MPopoverBody,
  MPopoverContent,
  MPopoverFooter,
  MPopoverHeader,
  MPopoverTrigger,
  MText,
} from '../../../../components/Monetize';
import { BillGroupErrorStatusEnum, Maybe } from '../../../../types';
import { usePastInvoice } from '../hooks/usePastInvoice';

export interface BillGroupGenerateInvoicePopoverProps {
  billGroupId: string;
  nextInvoiceDate: string;
  billGroupStatus: string;
  accountId: string;
  invoiceDelayDirty?: Maybe<boolean>;
  invoiceDelay?: Maybe<number>;
  errorStatus?: BillGroupErrorStatusEnum;
}
export const BillGroupGenerateInvoicePopover = ({
  billGroupId,
  nextInvoiceDate,
  billGroupStatus,
  accountId,
  invoiceDelay,
  invoiceDelayDirty,
  errorStatus,
}: BillGroupGenerateInvoicePopoverProps) => {
  const { canUpdate, hasPastInvoices, isLoading, generatePreviousInvoice } =
    usePastInvoice({
      accountId,
      billGroupId,
      billGroupStatus,
      nextInvoiceDate,
      invoiceDelay,
      invoiceDelayDirty,
    });

  if (!canUpdate || !hasPastInvoices) {
    return null;
  }

  if (errorStatus === BillGroupErrorStatusEnum.TAX_CALCULATION_ERROR) {
    return (
      <MAlert type="error" mt="2">
        <MText>
          There is a tax calculation error on your most recent invoice.
        </MText>
      </MAlert>
    );
  }

  return (
    <MPopover placement="bottom-end">
      <MPopoverTrigger>
        <MFlex cursor="pointer" gap="1" ml="auto" h="8" alignItems="center">
          <MText textAlign="right">Pending Invoice</MText>
          <MFlex
            align="center"
            justify="center"
            w="5"
            h="5"
            borderRadius="50%"
            bg="tIndigo.base"
            color="tWhite.base"
            fontSize="xs"
            fontWeight="700"
          >
            1
          </MFlex>
        </MFlex>
      </MPopoverTrigger>
      <MPopoverContent>
        <MPopoverArrow />
        <MPopoverHeader px="4" mt="4">
          Pending Invoice
        </MPopoverHeader>
        <MPopoverBody>
          This Bill Group has a pending invoice. Next Invoice Date is{' '}
          {toDateShort(nextInvoiceDate)}.
        </MPopoverBody>
        <MPopoverFooter display="flex" justifyContent="flex-end">
          <MButton
            size="sm"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => generatePreviousInvoice({ accountId, billGroupId })}
          >
            Generate
          </MButton>
        </MPopoverFooter>
      </MPopoverContent>
    </MPopover>
  );
};
