import { GridItem, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { formatCurrency, toDateShort } from '@monetize/utils/core';
import { Fragment } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  MCustomNumberInput,
  MFormField,
  MText,
  MTooltip,
} from '../../../../components/Monetize';
import { ItemWithLinkInNewWindow } from '../../../../components/Monetize/ItemWithLinkInNewWindow';
import { MEntityStatusBadge } from '../../../../components/Monetize/MEntityStatusBadge';
import {
  CurrencyCodesAll,
  getCurrencySymbol,
} from '../../../../constants/currencies';
import { InvoiceStatusEnumDisplay } from '../../../../constants/invoice';
import {
  getAccountDetailRoute,
  getInvoiceDetailRoute,
} from '../../../../constants/routes';
import {
  InvoiceStatusEnum,
  InvoiceSummaryResp,
  MassCreditInvoice,
} from '../../../../types';

interface MassCreditModalFormRowProps {
  index: number;
  row: InvoiceSummaryResp;
  isLoading: boolean;
  hasSubmitted: boolean;
  isLastRow: boolean;
  control: Control<MassCreditInvoice>;
  errors: FieldErrors<MassCreditInvoice>;
  setValue: UseFormSetValue<MassCreditInvoice>;
  watch: UseFormWatch<MassCreditInvoice>;
}

export const MassCreditModalFormRow = ({
  index,
  row,
  isLoading,
  isLastRow,
  control,
  errors,
  hasSubmitted,
  watch,
}: MassCreditModalFormRowProps) => {
  const value = watch(`rows.${index}.amount`);
  const amountDue = row.amountDue - (value ?? 0);

  return (
    <Fragment>
      <GridItem>
        <ItemWithLinkInNewWindow url={getInvoiceDetailRoute(row.id)}>
          <MText>{row.invoiceNumber || row.id}</MText>
        </ItemWithLinkInNewWindow>
        <MText fontSize="sm">
          {<MText fontSize="xs">Due {toDateShort(row.dueDate)}</MText>}
        </MText>
      </GridItem>
      <GridItem>
        <ItemWithLinkInNewWindow url={getAccountDetailRoute(row.account.id)}>
          <MText noOfLines={2}>{row.account.accountName}</MText>
        </ItemWithLinkInNewWindow>
        <MText fontSize="xs">{row.account.id}</MText>
      </GridItem>
      <GridItem alignContent="center">
        <MText textAlign="right">
          {formatCurrency(row.amount, {
            currency: row.currency,
          })}
        </MText>
      </GridItem>
      <GridItem alignContent="center">
        <MText textAlign="right">
          {formatCurrency(row.amountDue, {
            currency: row.currency,
          })}
        </MText>
      </GridItem>
      <GridItem>
        <MFormField error={errors.rows?.[index]?.amount}>
          <Controller
            name={`rows.${index}.amount`}
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <InputGroup>
                <InputLeftElement w="4" pl="2">
                  {getCurrencySymbol(row.currency as CurrencyCodesAll)}
                </InputLeftElement>
                <MCustomNumberInput
                  inputMode="decimal"
                  placeholder={`0.00`}
                  isReadOnly={isLoading || hasSubmitted}
                  isDisabled={isLoading || hasSubmitted}
                  styleProps={{
                    pl: 5,
                    pr: 2,
                    textAlign: 'right',
                  }}
                  onChange={(value) => onChange(value)}
                  {...rest}
                />
              </InputGroup>
            )}
          />
        </MFormField>
      </GridItem>
      <GridItem alignContent="center">
        <MText textAlign="right">
          {formatCurrency(amountDue, { currency: row.currency })}
        </MText>
      </GridItem>
      <GridItem alignContent="center" justifySelf="end">
        <NewInvoiceStatusOrError amount={value} amountDue={amountDue} />
      </GridItem>
      {!isLastRow && (
        <GridItem
          gridColumn="1 / -1"
          height="1px"
          backgroundColor="tGray.back"
        />
      )}
    </Fragment>
  );
};

const NewInvoiceStatusOrError = ({
  amount,
  amountDue,
}: {
  amount: number;
  amountDue: number;
}) => {
  let toolTipText = '';
  if (Number(amount) === 0) {
    toolTipText = 'The amount must be greater than 0';
  } else if (amountDue < 0) {
    toolTipText = 'The applied amount cannot exceed the amount due';
  }
  if (toolTipText) {
    return (
      <MTooltip label={toolTipText}>
        <MText
          role="cell"
          color="tRed.base"
          alignSelf="center"
          justifySelf="flex-end"
        >
          Error
        </MText>
      </MTooltip>
    );
  }
  return (
    <MEntityStatusBadge
      role="cell"
      status={
        amountDue === 0
          ? InvoiceStatusEnumDisplay[InvoiceStatusEnum.PAID]
          : InvoiceStatusEnumDisplay[InvoiceStatusEnum.UNPAID]
      }
      alignSelf="center"
      justifySelf="flex-end"
    />
  );
};
