/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Icon,
  IconButton,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  NumberInputProps,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC, FocusEvent, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { AMOUNT_UNITS } from '../../constants';
import { CurrencyCodes, getCurrencySymbol } from '../../constants/currencies';
import { useToast } from '../../services/toast';
import { AmountUnitTypeEnum } from '../../types';
import { MBox, MFlex, MTooltip } from './chakra';
import DiscountTargetPriceModal, {
  DiscountTargetPriceModalAmountOptions,
  SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION,
} from './DiscountTargetPriceModal';
import { MCustomNumberInput } from './MCustomNumberInput';

export interface MAmountWithUnitTypeInputProps extends NumberInputProps {
  amountUnitType?: AmountUnitTypeEnum | null;
  amountCeiling?: number;
  containerColors?: {
    color: string;
    bgColor: string;
    borderColor: string;
  };
  currency?: string;
  onChangeAmountUnitType?: (amountUnitType: AmountUnitTypeEnum) => void;
  // https://www.figma.com/proto/37LoEpFHHM90nSL6uNgrmh/Discount-on-Quotes?page-id=13%3A52&node-id=219%3A22382&viewport=722%2C604%2C0.15&scaling=scale-down&starting-point-node-id=219%3A23861
  targetAmountOptions?: DiscountTargetPriceModalAmountOptions;
  disableUnitTypeSelect?: boolean;
  disableUnitTypeMessage?: string;
  usePortal?: boolean;
  maxWidth?: number | string;
  placeholder?: string;
}

const MAmountWithUnitTypeInput: FC<MAmountWithUnitTypeInputProps> =
  React.forwardRef<any, MAmountWithUnitTypeInputProps>(
    (
      {
        amountUnitType = AmountUnitTypeEnum.FLAT,
        amountCeiling = Infinity,
        currency,
        onChangeAmountUnitType = () => null,
        isDisabled,
        containerColors = {
          color: 'tIndigo.base',
          bgColor: 'tWhite.base',
          borderColor: 'tBlue.lightShade',
        },
        onFocus,
        onBlur,
        value,
        targetAmountOptions,
        usePortal = false,
        onChange,
        disableUnitTypeSelect = false,
        disableUnitTypeMessage = '',
        isReadOnly,
        maxWidth = '7rem',
        ...rest
      }: MAmountWithUnitTypeInputProps,
      ref: any,
    ) => {
      const { addToast } = useToast();

      const {
        isOpen: isTargetAmountModalOpen,
        onOpen: onOpenTargetAmountModal,
        onClose: onCloseTargetAmountModal,
      } = useDisclosure();

      // To support amendment and renewals colors for quote
      const containerColorsWithDisabledState = isReadOnly
        ? containerColors
        : {
            ...containerColors,
            bgColor: isDisabled ? 'tGray.back' : containerColors?.bgColor,
          };
      const [isFocused, setIsFocused] = useState(false);

      const handleInputFocus = (ev: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        onFocus && onFocus(ev);
      };
      const handleInputBlur = (ev: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur && onBlur(ev);
      };

      const onInternalChangeAmountUnitType = (unitType: string) => {
        if (unitType === SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION.value) {
          if (!targetAmountOptions) {
            return;
          }

          if (targetAmountOptions?.amountWithoutDiscount <= 0) {
            addToast({
              summary: 'Total Amount',
              detail: `Total amount should be greater than 0.`,
              severity: 'warning',
            });
          } else {
            onOpenTargetAmountModal();
          }
        } else {
          onChangeAmountUnitType &&
            onChangeAmountUnitType(unitType as AmountUnitTypeEnum);
        }
      };

      const onChangeDiscountTargetPrice = (val: number) => {
        onChangeAmountUnitType &&
          onChangeAmountUnitType(AmountUnitTypeEnum.PERCENTAGE);
        onChange && onChange(String(val), val);
      };

      const isPercent = amountUnitType === AmountUnitTypeEnum.PERCENTAGE;
      const isFlat = amountUnitType === AmountUnitTypeEnum.FLAT;

      const FLAT_PERCENT_STYLE = {
        fontSize: 14,
        fontWeight: 700,
        minW: isFlat ? 2 : 4,
        mb: '-2px',
      };

      return (
        <InputGroup
          maxWidth={maxWidth}
          {...containerColorsWithDisabledState}
          color={isFocused ? 'tIndigo.base' : 'tPurple.base'}
        >
          {isFlat && (
            <InputLeftElement w={4} justifyContent="flex-end">
              <Box {...FLAT_PERCENT_STYLE}>
                {getCurrencySymbol(currency as CurrencyCodes)}
              </Box>
            </InputLeftElement>
          )}
          <MCustomNumberInput
            inputMode="decimal"
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            styleProps={{
              paddingRight: isPercent ? 12 : 8,
              paddingLeft: isFlat ? 4 : 2,
              textOverflow: 'ellipsis',
            }}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            value={value}
            max={amountCeiling}
            onChange={onChange}
            {...rest}
          />

          <InputRightElement
            w={isReadOnly ? 16 : isPercent ? 12 : 8}
            zIndex="unset"
          >
            {isPercent && (
              <Box {...FLAT_PERCENT_STYLE}>
                {
                  AMOUNT_UNITS.AMOUNT_UNIT_DISPLAY_SYMBOL[
                    AmountUnitTypeEnum.PERCENTAGE
                  ]
                }
              </Box>
            )}
            {targetAmountOptions && !isDisabled && !isReadOnly && (
              <MBox width="2px" bg="gray.200" height="100%" />
            )}
            {targetAmountOptions && !isDisabled && !isReadOnly && (
              <>
                {!disableUnitTypeSelect ? (
                  <Menu isLazy autoSelect={false}>
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<MdArrowDropDown />}
                      variant="outline"
                      maxW={6}
                      minW={6}
                      borderStyle="none"
                      _hover={{
                        bg: 'transparent',
                      }}
                      sx={{
                        '&[data-active]': {
                          background: 'transparent !important',
                        },
                      }}
                    />
                    <Portal>
                      <MenuList>
                        <MenuItem
                          key={SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION.value}
                          value={SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION.value}
                          onClick={() =>
                            onInternalChangeAmountUnitType(
                              SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION.value,
                            )
                          }
                        >
                          {SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION.title}
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </Menu>
                ) : (
                  <MTooltip
                    label={disableUnitTypeMessage}
                    placement="bottom-end"
                  >
                    <MFlex
                      h="full"
                      alignItems="center"
                      w="8"
                      justifyContent="center"
                      bg="tGray.support"
                      borderTopWidth={1}
                      borderRightWidth={1}
                      borderBottomWidth={1}
                      borderTopRightRadius="base"
                      borderBottomRightRadius="base"
                      borderColor="gray.200"
                    >
                      <Icon
                        as={MdArrowDropDown}
                        fontSize="20"
                        zIndex="1"
                        color={'tGray.lightPurple'}
                      />
                    </MFlex>
                  </MTooltip>
                )}
              </>
            )}
          </InputRightElement>

          {!!targetAmountOptions && isTargetAmountModalOpen && (
            <DiscountTargetPriceModal
              isOpen
              onClose={onCloseTargetAmountModal}
              targetAmountOptions={targetAmountOptions}
              onChangeValue={onChangeDiscountTargetPrice}
            />
          )}
        </InputGroup>
      );
    },
  );

export default MAmountWithUnitTypeInput;
