import { FunctionComponent as FC } from 'react';
import {
  MFormField,
  MGrid,
  MGridItem,
  MText,
} from '../../../components/Monetize';
import { DateDisplay } from '../../../components/Monetize/DateDisplay';
import { BillingInfo } from '../../../components/Quotes/BillingInfo';
import { PaymentMethodTypeEnumDisplay } from '../../../constants/paymentMethods';
import { IPaymentMethodResp } from '../../../types';
import { getAddress } from '../../../utils/address';

interface PaymentMethodAchCreditProps {
  paymentMethod: IPaymentMethodResp;
}

export const PaymentMethodAchCredit: FC<PaymentMethodAchCreditProps> = ({
  paymentMethod,
}: PaymentMethodAchCreditProps) => {
  const address = getAddress(paymentMethod.billingDetails);

  return (
    <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
      <MGridItem colSpan={12}>
        <MFormField label="Payment Method Type" isReadOnly>
          <MText mt=".3rem">
            {PaymentMethodTypeEnumDisplay[paymentMethod.paymentMethodType]}
          </MText>
        </MFormField>
      </MGridItem>

      <MGridItem colSpan={12}>
        <MFormField label="Name" isReadOnly>
          <MText mt=".3rem">{paymentMethod.paymentMethodName}</MText>
        </MFormField>
      </MGridItem>

      {paymentMethod.description && (
        <MGridItem colSpan={12}>
          <MFormField label="Description" isReadOnly>
            <MText mt=".3rem">{paymentMethod.description}</MText>
          </MFormField>
        </MGridItem>
      )}
      <MGridItem colSpan={6}>
        <MFormField label="Account Number" isReadOnly>
          <MText mt=".3rem">{paymentMethod.accountNumber}</MText>
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={6}>
        <MFormField label="Bank Name" isReadOnly>
          <MText mt=".3rem">{paymentMethod.bankName}</MText>
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={6}>
        <MFormField label="Routing Number" isReadOnly>
          <MText mt=".3rem">{paymentMethod.routingNumber}</MText>
        </MFormField>
      </MGridItem>
      <MGridItem colSpan={6}>
        <MFormField label="SWIFT Code" isReadOnly>
          <MText mt=".3rem">{paymentMethod.swiftCode}</MText>
        </MFormField>
      </MGridItem>
      {paymentMethod.createDate && (
        <MGridItem colSpan={6}>
          <MFormField label="Creation date" isReadOnly>
            <DateDisplay
              date={paymentMethod.createDate}
              textProps={{ mt: '.3rem' }}
            />
          </MFormField>
        </MGridItem>
      )}
      {!!paymentMethod.gatewayCustomerId && (
        <MGridItem colSpan={6}>
          <MFormField label="Gateway Customer ID" isReadOnly>
            <MText mt=".3rem">{paymentMethod.gatewayCustomerId}</MText>
          </MFormField>
        </MGridItem>
      )}
      <MGridItem colSpan={6}>
        <MFormField label="Gateway ID" isReadOnly>
          <MText mt=".3rem">{paymentMethod.paymentGateway.id}</MText>
        </MFormField>
      </MGridItem>
      {(paymentMethod.billingDetails?.fullName ||
        address.addressLine1 ||
        address.addressLine2 ||
        address.otherAddress) && (
        <MGridItem colSpan={12}>
          <MFormField label="Billing Address" isReadOnly>
            <BillingInfo
              title=""
              accountName=""
              fullName={paymentMethod.billingDetails?.fullName || ''}
              addressLine1={address.addressLine1}
              addressLine2={address.addressLine2}
              otherAddress={address.otherAddress}
              email=""
            />
          </MFormField>
        </MGridItem>
      )}
    </MGrid>
  );
};
