import {
  FocusLock,
  FormControl,
  FormLabel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../api/axios';
import { CONTRACT_END_ACTION_UI_DISPLAY } from '../../constants/contracts';
import { QUOTE_CUSTOM_CONTRACT_LENGTHS } from '../../constants/quotes';
import { logger } from '../../services/logger';
import {
  ContractEndActionEnum,
  IQuoteContractRenewalTerms,
  QuoteContractRenewalTerms,
  RenewalTermLengthEnum,
} from '../../types';
import {
  MBox,
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MInput,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  RadioStyleButtonGroup,
} from '../Monetize';
import { MTertiaryArrowButton } from '../Monetize/MTertiaryArrowButton';

const customContractOptions = QUOTE_CUSTOM_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));

interface QuoteContractRenewalTermsActionV2Props {
  contractRenewalTerms?: IQuoteContractRenewalTerms;
  defaultContractRenewalTerms?: IQuoteContractRenewalTerms;
  onSubmit: (data: IQuoteContractRenewalTerms) => Promise<void>;
  isReadOnly?: boolean;
}
export const QuoteContractRenewalTermsActionV2 = ({
  contractRenewalTerms,
  defaultContractRenewalTerms,
  onSubmit,
  isReadOnly = false,
}: QuoteContractRenewalTermsActionV2Props) => {
  const {
    watch,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    reset,
    formState: { errors, isDirty },
  } = useForm<IQuoteContractRenewalTerms>({
    resolver: zodResolver(QuoteContractRenewalTerms),
    mode: 'onChange',
    defaultValues: contractRenewalTerms,
    values: contractRenewalTerms,
    resetOptions: {
      keepDirty: true,
      keepErrors: true,
    },
  });
  const { onOpen, onClose, isOpen } = useDisclosure({
    onOpen: () => {
      reset(contractRenewalTerms);
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const contractEndAction = watch('contractEndAction');
  const renewalTermLengthType = watch('renewalTermLength.type');
  const renewalTermLengthMonths = watch('renewalTermLength.months');

  const onContractRenewalTermsSubmit = async (
    data: IQuoteContractRenewalTerms,
  ) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const onError = (err: any) => logger.error(err);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      returnFocusOnClose={true}
      strategy="fixed"
      placement="bottom-end"
      offset={[0, 0]}
    >
      <PopoverTrigger>
        <MTertiaryArrowButton
          isOpen={isOpen}
          setIsOpen={(val) => (val ? onOpen() : onClose())}
          mr="-3"
        >
          {
            CONTRACT_END_ACTION_UI_DISPLAY[
              contractRenewalTerms?.contractEndAction ||
                ContractEndActionEnum.CANCEL
            ]
          }
        </MTertiaryArrowButton>
      </PopoverTrigger>
      <Portal>
        <FocusLock>
          <PopoverContent p={4}>
            <MFormField mt="2" mb="4" error={errors.contractEndAction}>
              <Controller
                name="contractEndAction"
                control={control}
                render={({ field: { name, value, onChange, ...rest } }) => (
                  <FormControl
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    px="2"
                  >
                    <FormLabel htmlFor={name} fontWeight="400" mb="0">
                      Auto Renew Contract
                    </FormLabel>

                    <Switch
                      size="md"
                      id={name}
                      aria-describedby={`${name}-subheading`}
                      isChecked={value === ContractEndActionEnum.RENEW}
                      isDisabled={isReadOnly}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onChange(ContractEndActionEnum.RENEW);
                          setValue(
                            'renewalTermLength.type',
                            contractRenewalTerms?.renewalTermLength?.type ||
                              defaultContractRenewalTerms?.renewalTermLength
                                ?.type ||
                              RenewalTermLengthEnum.ContractLength,
                          );
                          setValue(
                            'renewalTermLength.months',
                            contractRenewalTerms?.renewalTermLength?.months ||
                              defaultContractRenewalTerms?.renewalTermLength
                                ?.months ||
                              null,
                          );
                          setValue(
                            'autoRenewalNoticePeriod',
                            contractRenewalTerms?.autoRenewalNoticePeriod ||
                              defaultContractRenewalTerms?.autoRenewalNoticePeriod ||
                              null,
                          );
                        } else {
                          onChange(ContractEndActionEnum.CANCEL);
                          setValue('renewalTermLength', null);
                          setValue('autoRenewalNoticePeriod', null);
                        }
                      }}
                    />
                  </FormControl>
                )}
              />
            </MFormField>

            {contractEndAction === ContractEndActionEnum.RENEW && (
              <>
                <MDivider />
                <MBox ml="2.5">
                  <MText mt="4" fontSize="md" fontWeight="600">
                    Renewal Configuration
                  </MText>

                  <MFormField
                    mt="2"
                    error={
                      errors.renewalTermLength?.type ||
                      errors.renewalTermLength?.months
                    }
                  >
                    <Controller
                      name="renewalTermLength.type"
                      control={control}
                      render={({ field: { value, onChange, ...rest } }) => (
                        <MRadioGroup
                          value={value || RenewalTermLengthEnum.ContractLength}
                          onChange={(val) => {
                            onChange(val);
                            if (val === RenewalTermLengthEnum.ContractLength) {
                              setValue('renewalTermLength.months', null, {
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                              if (errors.renewalTermLength?.months) {
                                clearErrors('renewalTermLength.months');
                              }
                            }
                          }}
                          {...rest}
                        >
                          <MStack>
                            <MRadio
                              isDisabled={isReadOnly}
                              value={RenewalTermLengthEnum.ContractLength}
                              fontWeight="bold"
                            >
                              Same as Prior Contract Length
                            </MRadio>

                            <MRadio
                              isDisabled={isReadOnly}
                              value={RenewalTermLengthEnum.FixedMonths}
                              fontWeight="bold"
                            >
                              Custom Length in Months
                            </MRadio>
                          </MStack>
                        </MRadioGroup>
                      )}
                    />
                    {renewalTermLengthType ===
                      RenewalTermLengthEnum.FixedMonths && (
                      <MBox pl="5">
                        <Controller
                          control={control}
                          name="renewalTermLength.months"
                          render={({ field: { value, ...rest } }) => (
                            <MInput
                              w="10rem"
                              value={value || ''}
                              {...rest}
                              isDisabled={isReadOnly}
                            />
                          )}
                        />

                        <RadioStyleButtonGroup
                          name="renewalTermLengthMonths"
                          containerProps={{ maxW: '160px', mt: 2 }}
                          options={customContractOptions}
                          value={
                            `${renewalTermLengthMonths || ''}` || undefined
                          }
                          defaultValue={
                            `${renewalTermLengthMonths || ''}` || undefined
                          }
                          onChange={(value: string) => {
                            setValue('renewalTermLength.months', +value, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                        />
                      </MBox>
                    )}
                  </MFormField>

                  <MFormField
                    mt="4"
                    label="Auto-Renewal Cancellation Deadline"
                    isRequired
                    labelProps={{
                      display: 'block',
                    }}
                    tooltip="This field sets how many days before the contract end date the contract will automatically renew if no cancellation is received. If you or the customer do not cancel or disable auto-renewal within this window, the contract renews automatically."
                    error={errors.autoRenewalNoticePeriod}
                  >
                    <Controller
                      control={control}
                      name="autoRenewalNoticePeriod"
                      render={({ field: { value, ...rest } }) => (
                        <MInput
                          value={value || ''}
                          {...rest}
                          isDisabled={isReadOnly}
                        />
                      )}
                    />
                  </MFormField>
                  <MText mt="2" fontWeight="400" color="tGray.acGray">
                    Example: If the contract ends on July 15 with this field set
                    to 10 days, the cancellation notice must be received by July
                    5. If not, the contract automatically renews on July 5.
                  </MText>
                </MBox>
              </>
            )}
            {!isReadOnly && (
              <>
                <MDivider my="4" />
                <MFlex justify="end">
                  <MButton
                    variant="primary"
                    onClick={handleSubmit(
                      onContractRenewalTermsSubmit,
                      onError,
                    )}
                    isDisabled={!isDirty || isLoading}
                    isLoading={isLoading}
                  >
                    Save
                  </MButton>
                </MFlex>
              </>
            )}
          </PopoverContent>
        </FocusLock>
      </Portal>
    </Popover>
  );
};
