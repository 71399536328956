import { useDisclosure } from '@chakra-ui/react';
import { UseFormSetValue } from 'react-hook-form';
import { IOfferingRes, IQuoteOfferingReqSchema } from '../../../../../types';

export const useManageProduct = ({
  setOffering,
  setValue,
}: {
  setOffering: (data: IOfferingRes | undefined) => void;
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
}) => {
  const {
    isOpen: isManageProductModalOpen,
    onOpen: onManageProductModalOpen,
    onClose: onManageProductModalClose,
  } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onOpen: onDrawerOpen,
    onClose: onDrawerClose,
  } = useDisclosure();

  const handleOnModalClose = () => {
    onManageProductModalClose();
    setOffering(undefined);
    setValue('offeringId', '');
  };

  return {
    handleOnModalClose,
    isManageProductModalOpen,
    onManageProductModalOpen,
    onManageProductModalClose,
    //drawer
    isDrawerOpen,
    onDrawerOpen,
    onDrawerClose,
  };
};
