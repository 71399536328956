import React, { FunctionComponent as FC } from 'react';
import { Control, Controller, useFormState } from 'react-hook-form';
import { MdClose, MdEdit } from 'react-icons/md';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MFormField,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '../../../../components/Monetize';
import MAlert from '../../../../components/Monetize/MAlert';
import {
  OFFERING_TYPES_ENUM_DISPLAY,
  OFFERING_TYPES_INPUT_MESSAGES,
} from '../../../../constants/offerings';
import { useFlags } from '../../../../services/launchDarkly';
import {
  IOfferingReqSchema,
  IProductResSchema,
  OfferingTypesEnum,
} from '../../../../types';
import { getOfferingType } from '../offeringUtils';

interface OfferingTypeInputProps {
  control: Control<IOfferingReqSchema>;
  products: IProductResSchema[];
  rateIds: string[];
  offeringType: OfferingTypesEnum;
  editable: boolean;
  setEditable: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveButtonPress: (nextProducts: IProductResSchema[]) => void;
  isReadOnly: boolean;
}

const OfferingTypeInput: FC<OfferingTypeInputProps> = ({
  control,
  products,
  rateIds,
  offeringType,
  editable,
  setEditable,
  isReadOnly,
}: OfferingTypeInputProps) => {
  const featureFlags = useFlags();
  const { errors } = useFormState({
    control,
  });

  const { isSubscription, isMinCommit, isOneTime, isPercentOfTotal } =
    getOfferingType(offeringType);

  const handleEdit = () => {
    if (rateIds.length === 0) {
      setEditable(true);
    }
  };
  const handleClose = () => {
    setEditable(false);
  };

  const handleOnChange = (onChange: (e: string) => void) => (e: string) => {
    onChange(e);
    handleClose();
  };

  if (!editable || isReadOnly) {
    return (
      <MText
        width="full"
        fontSize="md"
        fontWeight="bold"
        color="tPurple.base"
        mb="4"
        alignSelf="start"
        pb="2"
        borderBottom="1px solid"
        borderBottomColor="tGray.support"
        pl={8}
      >
        Offering Type:
        <MText as="span" fontWeight="normal" ml={2}>
          {OFFERING_TYPES_ENUM_DISPLAY[offeringType]}
          {rateIds.length === 0 && !isReadOnly && (
            <MCustomIconButton
              data-testid="edit-offering-type"
              position="relative"
              icon={MdEdit}
              bg="none"
              color="tPurple.dark"
              _hover={{
                bg: 'none',
              }}
              btnSize={4}
              padding={0}
              ml={1}
              containerSize={5}
              onClick={handleEdit}
            />
          )}
        </MText>
      </MText>
    );
  }

  return (
    <MBox
      width="full"
      bg="white"
      alignSelf="start"
      mb="4"
      pb="3"
      pl={8}
      borderBottom="1px solid"
      borderBottomColor="tGray.support"
    >
      <MFlex justifyContent="space-between">
        <MText fontSize="lg" fontWeight="bold" color="tPurple.base" mb="3">
          Choose Offering Type
        </MText>
        <MCustomIconButton
          position="relative"
          icon={MdClose}
          bg="none"
          color="tPurple.dark"
          _hover={{
            bg: 'none',
          }}
          btnSize={4}
          padding={0}
          containerSize={5}
          onClick={handleClose}
        />
      </MFlex>
      <MFormField error={errors.type} pl="3" mb="2">
        <Controller
          name="type"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <MRadioGroup onChange={handleOnChange(onChange)} {...rest}>
              <MStack direction="row" spacing={3}>
                <MRadio
                  data-testid="subscription-offering-type"
                  isDisabled={!isSubscription && !!products.length}
                  value={OfferingTypesEnum.SUBSCRIPTION}
                >
                  {OFFERING_TYPES_ENUM_DISPLAY[OfferingTypesEnum.SUBSCRIPTION]}
                </MRadio>
                <MRadio
                  data-testid="onetime-offering-type"
                  isDisabled={!isOneTime && !!products.length}
                  value={OfferingTypesEnum.ONETIME}
                >
                  {OFFERING_TYPES_ENUM_DISPLAY[OfferingTypesEnum.ONETIME]}
                </MRadio>
                <MRadio
                  data-testid="min-commit-offering-type"
                  isDisabled={!isMinCommit && !!products.length}
                  value={OfferingTypesEnum.MIN_COMMIT}
                >
                  {OFFERING_TYPES_ENUM_DISPLAY[OfferingTypesEnum.MIN_COMMIT]}
                </MRadio>
                {featureFlags.percentOfTotal && (
                  <MRadio
                    data-testid="precent-of-total-offering-type"
                    isDisabled={!isPercentOfTotal && !!products.length}
                    value={OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL}
                  >
                    {
                      OFFERING_TYPES_ENUM_DISPLAY[
                        OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL
                      ]
                    }
                  </MRadio>
                )}
              </MStack>
            </MRadioGroup>
          )}
        />
      </MFormField>
      {products.length === 0 && offeringType && (
        <MText color="tGray.darkPurple" pl="3">
          {OFFERING_TYPES_INPUT_MESSAGES[offeringType]}
        </MText>
      )}
      {!!products.length && (
        <MAlert type="warning" maxW="40rem">
          The offering type cannot be changed because you have already added
          products.
        </MAlert>
      )}
    </MBox>
  );
};

export default OfferingTypeInput;
