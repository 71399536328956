import { CustomSelectStateProps } from '../../../types/mCustomSelectTypes';
import { MBox, MFlex } from '../chakra';
import MText from '../MText';
import CheckboxItemList from './components/CheckboxItemList';
import { ItemList } from './components/ItemList';

export type MCustomSelectItemListRenderProps = Pick<
  CustomSelectStateProps,
  | 'isSubtitleItem'
  | 'isDisabledItem'
  | 'isSelectedItem'
  | 'onClose'
  | 'itemRefs'
  | 'getTitle'
  | 'cursor'
  | 'items'
  | 'handleChange'
  | 'renderItemContent'
  | 'setCursorCurrent'
  | 'displayAvatar'
  | 'query'
  | 'useHighlightedItemList'
  | 'showItemDivider'
> & {
  indexOffset: number;
  checkboxDisplay?: boolean;
  rightLabel?: boolean;
};

const MCustomSelectItemListRender = ({
  cursor,
  displayAvatar,
  getTitle,
  handleChange,
  indexOffset,
  isDisabledItem,
  isSelectedItem,
  isSubtitleItem,
  itemRefs,
  items,
  onClose,
  renderItemContent,
  setCursorCurrent,
  query,
  checkboxDisplay = false,
  useHighlightedItemList = false,
  showItemDivider,
  rightLabel,
}: MCustomSelectItemListRenderProps) => {
  if (checkboxDisplay) {
    return (
      <CheckboxItemList
        indexOffset={indexOffset}
        isSelectedItem={isSelectedItem}
        isDisabledItem={isDisabledItem}
        isSubtitleItem={isSubtitleItem}
        itemRefs={itemRefs}
        getTitle={getTitle}
        cursor={cursor}
        items={items}
        handleChange={handleChange}
        renderItemContent={renderItemContent}
        query={query}
        rightLabel={rightLabel}
      />
    );
  }

  return (
    <>
      <ItemList
        indexOffset={indexOffset}
        items={items}
        isSubtitleItem={isSubtitleItem}
        isDisabledItem={isDisabledItem}
        isSelectedItem={isSelectedItem}
        onClose={onClose}
        itemRefs={itemRefs}
        getTitle={getTitle}
        cursor={cursor}
        handleChange={handleChange}
        renderItemContent={renderItemContent}
        setCursorCurrent={setCursorCurrent}
        displayAvatar={displayAvatar}
        query={query}
        useHighlightedItemList={useHighlightedItemList}
        showItemDivider={showItemDivider}
      />

      {!useHighlightedItemList && items.length === 0 && (
        <MBox p="2">No Results</MBox>
      )}

      {useHighlightedItemList && items.length === 0 && (
        <MFlex p={1} justify="center" align="center">
          <MText color="tGray.darkPurple">
            {query ? 'No search results found' : 'No results found'}
          </MText>
        </MFlex>
      )}
    </>
  );
};

export default MCustomSelectItemListRender;
