import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { doCreateUser } from '../../../api/usersService';
import AppLoading from '../../../components/App/AppLoading';
import { MLink, MText } from '../../../components/Monetize';
import { ROUTES } from '../../../constants';
import { useAuth } from '../../../services/auth0';

const CreateUser = () => {
  const { getDecodedToken, getAndHandleAccessTokenSilently, userId } =
    useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      if (userId) {
        // If userId is set, dont have to create again.
        navigate(ROUTES.ROOT);
        return;
      }

      const { updatedAccessToken, updatedUserId } =
        await getAndHandleAccessTokenSilently();

      if (updatedUserId) {
        // if user id is there from new token, do not create a user
        navigate(ROUTES.ROOT);
        return;
      }

      const tokenDecoded = getDecodedToken(updatedAccessToken);

      if (tokenDecoded) {
        await doCreateUser(tokenDecoded.sub);
        const { updatedUserId: updatedUserId1 } =
          await getAndHandleAccessTokenSilently();
        if (updatedUserId1) {
          navigate(ROUTES.ROOT);
        }
      }
    };

    run();
  }, []);

  return (
    <AppLoading loadingText="Creating a new user...">
      <MText my="4" fontSize="sm">
        You can also{' '}
        <MLink fontSize="sm" as={Link} to={ROUTES.AUTH_LOGOUT}>
          log out
        </MLink>
        .
      </MText>
    </AppLoading>
  );
};

export default CreateUser;
