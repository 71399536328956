import { Control, UseFormSetError, UseFormSetValue } from 'react-hook-form';
import {
  IOfferingRes,
  IPriceUnderProductSchemaUI,
  IQuoteItemReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuotePrice,
  IQuoteTemplateConfigSchema,
  IRateResSchema,
} from '../../../../../types';
import { arrayToObject } from '../../../../../utils/misc';
import QuoteItem from './QuoteItem';

export interface QuoteItemsProps {
  offering?: IOfferingRes;
  parentQuoteOffering?: IQuoteOfferingRespSchema | null;
  offeringRate: IRateResSchema | null;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  priorScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  rateId: string | null | undefined;
  quoteItems?: IQuoteItemReqSchema[];
  control: Control<IQuoteOfferingReqSchema>;
  enableCustomDiscounts: boolean;
  isOpen: boolean;
  isRemoved?: boolean;
  isReadOnly?: boolean;
  quotePrices: IQuotePrice[];
  displayConfig?: IQuoteTemplateConfigSchema | null;
  isQOAddedToAmendment?: boolean;
  isDisabled: boolean;
  orderedPricesUnderProduct: IPriceUnderProductSchemaUI[];
  setValue: UseFormSetValue<IQuoteOfferingReqSchema>;
  setError: UseFormSetError<IQuoteOfferingReqSchema>;
  handleQuoteOffering: () => void;
  handleQuoteOfferingWithoutDirtyCheck: () => void;
}

/**
 * Render all quote items for a given offering
 */
export const QuoteItems = ({
  offering,
  parentQuoteOffering,
  offeringRate,
  quoteOffering,
  priorScheduledQuoteOffering,
  rateId,
  quoteItems,
  control,
  enableCustomDiscounts,
  isOpen,
  isRemoved,
  isReadOnly,
  quotePrices,
  displayConfig,
  isQOAddedToAmendment,
  isDisabled,
  orderedPricesUnderProduct,
  setValue,
  setError,
  handleQuoteOffering,
  handleQuoteOfferingWithoutDirtyCheck,
}: QuoteItemsProps) => {
  if (
    !offering ||
    !quoteOffering ||
    !rateId ||
    !quoteItems?.length ||
    !isOpen
  ) {
    return null;
  }

  const quoteItemsById = arrayToObject(quoteOffering.items, 'id');
  const priorScheduledQuoteItemsByProductId = arrayToObject(
    priorScheduledQuoteOffering?.items || [],
    'productId',
  );

  return (
    <>
      {quoteItems.map((quoteItem, index) => {
        if (!quoteItem.id) {
          return null;
        }

        return (
          <QuoteItem
            key={`${quoteOffering.id}-${index}-${quoteItem.id}`}
            id={quoteItem.id}
            index={index}
            quoteOfferingId={quoteOffering.id}
            parentQuoteOfferingId={parentQuoteOffering?.id}
            item={quoteItem}
            itemFromServer={quoteItemsById[quoteItem.id]}
            priorScheduledQuoteItem={
              priorScheduledQuoteItemsByProductId[quoteItem.productId!]
            }
            offering={offering}
            offeringRate={offeringRate}
            control={control}
            isRemoved={isRemoved}
            isReadOnly={isReadOnly}
            setValue={setValue}
            setError={setError}
            watchRateId={rateId}
            quotePrices={quotePrices}
            enableCustomDiscounts={enableCustomDiscounts}
            handleQuoteOffering={handleQuoteOffering}
            handleQuoteOfferingWithoutDirtyCheck={
              handleQuoteOfferingWithoutDirtyCheck
            }
            displayConfig={displayConfig}
            isQOAddedToAmendment={isQOAddedToAmendment}
            isDisabled={isDisabled}
            orderedPricesUnderProduct={orderedPricesUnderProduct}
          />
        );
      })}
    </>
  );
};

export default QuoteItems;
