import { Switch } from '@chakra-ui/react';
import {
  MBox,
  MCustomNumberInput,
  MFlex,
  MFormControl,
  MFormLabel,
  MText,
  RadioStyleButtonGroup,
} from '../../../../../components/Monetize';
import { FREQUENCY_ITEMS } from '../../../../../constants/quotes';
import { getShortCodeForUnitPriceFrequency } from '../../../../../utils/quotes';

interface DisplayUnitPriceFrequencyProps {
  isLoading?: boolean;
  value?: null | number;
  onChange: (value: null | number) => void | Promise<void>;
}

const displayFrequencyOptions = FREQUENCY_ITEMS.map(({ title, value }) => ({
  label: value,
  value: `${value}`,
}));
export const DisplayUnitPriceFrequency = ({
  isLoading = false,
  value,
  onChange,
}: DisplayUnitPriceFrequencyProps) => {
  return (
    <MBox>
      <MFormControl
        display="flex"
        width="full"
        justifyContent="space-between"
        alignItems="center"
        gap="4"
        mb="2"
      >
        <MFormLabel
          htmlFor={'default-display-frequency'}
          fontSize="md"
          fontWeight="600"
          mb="0"
        >
          Override Prices Display on Quotes
        </MFormLabel>
        <Switch
          id={'default-display-frequency'}
          aria-describedby={`default-display-frequency-subheading`}
          isDisabled={isLoading}
          isChecked={!!value}
          onChange={({ target }) => onChange(target.checked ? 1 : null)}
        />
      </MFormControl>

      <MFlex direction="column" gap={2}>
        <MText>
          Set a default Display Frequency (i.e. monthly, annually, etc) for all
          products on Quote PDFs. This does not impact invoicing.
        </MText>

        <MFlex align="center">
          <MText mr="2">Per</MText>
          <MCustomNumberInput
            onChange={(valueAsString: string, valueAsNumber: number) =>
              onChange(valueAsNumber)
            }
            value={value || ''}
            min={1}
            max={60}
            maxW="50px"
            isDisabled={!value}
            mr="2"
          />
          <MText>Month(s)</MText>
        </MFlex>
        <MBox maxW="300px">
          <RadioStyleButtonGroup
            name="displayUnitPriceFrequencyOptions"
            options={displayFrequencyOptions}
            value={`${value || ''}` || undefined}
            defaultValue={`${value || ''}` || undefined}
            onChange={(value: string) => {
              onChange(+value);
            }}
            isDisabled={!value}
          />
        </MBox>
        {!!value && (
          <MText mt="2">
            How it will appear on Quote: $5.00/
            {getShortCodeForUnitPriceFrequency(value)}
          </MText>
        )}
      </MFlex>
    </MBox>
  );
};
