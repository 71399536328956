import { GridProps } from '@chakra-ui/react';
import { MDivider, MGrid } from '../../../components/Monetize';
import { HeaderCell } from '../../../components/Quotes';
import {
  HEADER_ROW_CELLS,
  HEADER_ROW_CELLS_WITH_ADDITIONAL_INFO,
  QUOTE_GRID_TEMPLATE_COLUMNS,
  QUOTE_GRID_TEMPLATE_COLUMNS_WITH_ADDITIONAL_INFO,
} from '../../../constants/quotes';
import { QuoteTypeEnum } from '../../../types';
import { useQuoteContext } from './quoteContext';

interface QuoteFormOfferingTableHeaderProps extends GridProps {
  offeringTitle?: string;
}
export const QuoteFormOfferingTableHeader = ({
  offeringTitle,
  ...rest
}: QuoteFormOfferingTableHeaderProps) => {
  const { quoteData, useAmendmentV2 } = useQuoteContext();
  const { quote } = quoteData;

  const headerCells =
    quote?.type !== QuoteTypeEnum.NEW && !useAmendmentV2
      ? [...HEADER_ROW_CELLS_WITH_ADDITIONAL_INFO]
      : [...HEADER_ROW_CELLS];

  if (offeringTitle) {
    headerCells[0] = offeringTitle;
  }

  return (
    <MGrid
      gridTemplateColumns={
        quote?.type !== QuoteTypeEnum.NEW && !useAmendmentV2
          ? QUOTE_GRID_TEMPLATE_COLUMNS_WITH_ADDITIONAL_INFO
          : QUOTE_GRID_TEMPLATE_COLUMNS
      }
      columnGap={6}
      rowGap={2}
      {...rest}
    >
      {headerCells.map((cell: string, index: number) => (
        <HeaderCell value={cell} key={`${index}-${cell}`}>
          {cell}
        </HeaderCell>
      ))}
      <MDivider gridColumn="1/-1" />
    </MGrid>
  );
};
