import { chakra } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';

import { MBox, MStack } from './chakra';

interface MStatusToggleButtonsProps {
  options: [label: string, value: string][]; // Options : array of button name and value
  onChange: (selectedValue: string) => void;
  value: string;
}

const MStatusToggleButtons: FC<MStatusToggleButtonsProps> = (
  props: MStatusToggleButtonsProps,
) => {
  const { options, onChange, value, ...rest } = props;

  const buttons = options.map((option: any) => {
    const [label, butValue] = option;

    const isActive = value === butValue;
    const activeColor = 'tIndigo.base';
    const activeBorderColor = 'tIndigo.base';

    const activeHover = {
      fontWeight: 'semibold',
      mb: 'auto',
    };
    const regularHover = {
      fontWeight: 'semibold',
      mt: '2px',
      mr: '30px',
      cursor: 'pointer',
    };

    return (
      <MBox
        ms={0}
        key={label}
        onClick={() => onChange(butValue)}
        color={isActive ? activeColor : 'tPurple.base'}
        fontSize="sm"
        fontWeight={isActive ? 'semibold' : 'normal'}
        borderBottom={isActive ? '4px solid' : 'none'}
        borderBottomColor={activeBorderColor}
        mt={isActive ? '2px' : 'auto'}
        mr="8"
        _hover={isActive ? activeHover : regularHover}
      >
        {label}
      </MBox>
    );
  });

  return (
    <MStack
      spacing={0}
      shouldWrapChildren
      direction="row"
      {...rest}
      borderBottom="1px solid"
      borderBottomColor="tGray.support"
      pb="2px"
      ms={0}
    >
      {buttons}
    </MStack>
  );
};

export default chakra(MStatusToggleButtons);
