import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../../api/axios';
import { useCreateEntity } from '../../../../../../api/queryUtils';
import {
  doTestTaxConnection,
  settingsQueryKeys,
} from '../../../../../../api/settingsService';
import {
  MAlert,
  MButton,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MHStack,
  MInput,
} from '../../../../../../components/Monetize';
import {
  TAX_ENVIRONMENTS_SELECT_OPTIONS,
  TAX_IDENTIFIER_SELECT_OPTIONS,
} from '../../../../../../constants/taxes';
import { logger } from '../../../../../../services/logger';
import { Maybe } from '../../../../../../types';
import {
  ITaxConnectionSchema,
  TaxConnectionSchema,
  TaxEnvironments,
  TaxIdentifierEnum,
  TaxProviderTypeEnum,
  TaxStatusEnum,
} from '../../../../../../types/taxTypes';

interface AvalaraFormProps {
  connection?: ITaxConnectionSchema | null;
  handleCancel: () => void;
}

export const AvalaraForm = ({ connection, handleCancel }: AvalaraFormProps) => {
  const queryClient = useQueryClient();
  const [isStatusLoading, setStatusLoading] = useState<boolean>(false);
  const [testStatus, setTestStatus] = useState<{
    provider: TaxProviderTypeEnum;
    accountNumber?: Maybe<string>;
    licenseKey?: Maybe<string>;
    environment?: Maybe<string>;
    companyCode?: Maybe<string>;
  } | null>(() =>
    connection?.credentials
      ? {
          provider: TaxProviderTypeEnum.AVALARA,
          accountNumber: connection.credentials.accountNumber,
          licenseKey: connection.credentials.licenseKey,
          environment: connection.credentials.environment,
          companyCode: connection.credentials.companyCode,
        }
      : null,
  );
  const [testStatusError, setTestStatusError] = useState<any>(null);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<ITaxConnectionSchema>({
    resolver: zodResolver(TaxConnectionSchema),
    mode: 'onSubmit',
    values: connection ? connection : undefined,
    defaultValues: {
      provider: TaxProviderTypeEnum.AVALARA,
      configurations: {
        identifier: TaxIdentifierEnum.TAX_ITEM_CODE,
        postAndCommitTransactions: true,
        validateAddress: false,
        enableLogging: false,
      },
      credentials: {
        environment: TaxEnvironments.SANDBOX,
        companyCode: 'DEFAULT',
      },
      status: TaxStatusEnum.ACTIVE,
    },
  });

  const isValid = Object.keys(errors).length === 0;

  const { mutateAsync: createTaxConfiguration, isPending: isLoading } =
    useCreateEntity('tax', {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [...settingsQueryKeys.taxByProvider(TaxProviderTypeEnum.AVALARA)],
          data,
        );
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    });

  const watchProvider = watch('provider');
  const watchAccountNumber = watch('credentials.accountNumber');
  const watchCompanyCode = watch('credentials.companyCode');
  const watchLicenseKey = watch('credentials.licenseKey');
  const watchEnvironment = watch('credentials.environment');

  const isTestStatusValid =
    !testStatusError &&
    testStatus &&
    testStatus.provider === watchProvider &&
    testStatus.accountNumber === watchAccountNumber &&
    testStatus.companyCode === watchCompanyCode &&
    testStatus.licenseKey === watchLicenseKey &&
    testStatus.environment === watchEnvironment;

  const isEmptyRequiredFields =
    !watchAccountNumber ||
    !watchCompanyCode ||
    !watchLicenseKey ||
    !watchEnvironment;

  const onSubmitHandler = async (data: ITaxConnectionSchema) => {
    // FIXME: `identifier` was missing from `data` for some reason
    await createTaxConfiguration(getValues());
    handleCancel();
  };

  const onTestConnection = async () => {
    try {
      setStatusLoading(true);
      const { provider, credentials } = getValues();
      await doTestTaxConnection({
        provider,
        credentials: credentials
          ? {
              accountNumber: credentials.accountNumber!,
              companyCode: credentials.companyCode!,
              licenseKey: credentials.licenseKey!,
              environment: credentials.environment!,
            }
          : null,
      });

      setTestStatus({
        provider,
        accountNumber: credentials?.accountNumber,
        companyCode: credentials?.companyCode,
        licenseKey: credentials?.licenseKey,
        environment: credentials?.environment,
      });

      setTestStatusError(null);
    } catch (error) {
      logger.error('Error while testing tax connection =======>', error);
      setTestStatusError(error);
    } finally {
      setStatusLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} style={{ width: '100%' }}>
      <MFlex flexDirection="column">
        <MFlex mb="4" gap="4">
          <MFormField
            error={errors.credentials?.accountNumber}
            label="Account Number"
            isRequired
          >
            <Controller
              name="credentials.accountNumber"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <MInput
                  value={value || ''}
                  onChange={(value) => {
                    onChange(value);
                    if (testStatusError) {
                      setTestStatusError(null);
                    }
                  }}
                  {...rest}
                />
              )}
            />
          </MFormField>
          <MFormField
            error={errors.credentials?.environment}
            label="Environment"
            isRequired
          >
            <Controller
              name="credentials.environment"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <MCustomSelect
                  placeholder="Select Environment"
                  items={TAX_ENVIRONMENTS_SELECT_OPTIONS}
                  onChange={(value) => {
                    onChange(value);
                    if (testStatusError) {
                      setTestStatusError(null);
                    }
                  }}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MFlex>
        <MFlex mb="4" gap="4">
          <MFormField
            error={errors.configurations?.identifier}
            label="Tax Calculation Field"
            isRequired
            tooltip="Choose which field on the product you would like us to send to Avalara as the Item Code."
          >
            <Controller
              name="configurations.identifier"
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <MCustomSelect
                  placeholder="Select Tax Calculation Field"
                  items={TAX_IDENTIFIER_SELECT_OPTIONS}
                  onChange={(value) => {
                    onChange(value);
                    if (testStatusError) {
                      setTestStatusError(null);
                    }
                  }}
                  {...rest}
                />
              )}
            />
          </MFormField>
          <MFormField
            error={errors.credentials?.companyCode}
            label="Company Code"
            isRequired
          >
            <Controller
              name="credentials.companyCode"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <MInput
                  value={value || ''}
                  onChange={(value) => {
                    onChange(value);
                    if (testStatusError) {
                      setTestStatusError(null);
                    }
                  }}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MFlex>
        <MFormField
          mb="4"
          error={errors.credentials?.licenseKey}
          label="License Key"
          isRequired
        >
          <Controller
            name="credentials.licenseKey"
            control={control}
            render={({ field: { value, onChange, ...rest } }) => (
              <MInput
                value={value || ''}
                onChange={(value) => {
                  onChange(value);
                  if (testStatusError) {
                    setTestStatusError(null);
                  }
                }}
                {...rest}
              />
            )}
          />
        </MFormField>

        <MDivider my={4} />
        <MFlex justify="space-between" align="center">
          {!isTestStatusValid && !testStatusError && (
            <MButton
              type="submit"
              variant="tertiary"
              fontSize="xs"
              onClick={() => onTestConnection()}
              isLoading={isStatusLoading}
              isDisabled={!isValid || isStatusLoading || isEmptyRequiredFields}
            >
              Test Connection
            </MButton>
          )}

          {testStatus && isTestStatusValid && (
            <MAlert
              type="success"
              alertProps={{ px: 2, py: 1 }}
              alertIconProps={{ width: 4, height: 4 }}
              alertDescriptionProps={{ fontSize: 'sm' }}
            >
              Connection Valid
            </MAlert>
          )}
          {testStatusError && (
            <MAlert
              type="error"
              alertProps={{ px: 2, py: 1 }}
              alertIconProps={{ width: 4, height: 4 }}
              alertDescriptionProps={{ fontSize: 'sm' }}
            >
              Connection invalid
            </MAlert>
          )}
          <MHStack spacing="4" justify="end" align="center">
            <MButton variant="cancel" onClick={handleCancel}>
              Cancel
            </MButton>
            <MButton
              type="submit"
              variant="primary"
              isLoading={isLoading}
              isDisabled={!isTestStatusValid || isLoading}
            >
              Save
            </MButton>
          </MHStack>
        </MFlex>
      </MFlex>
    </form>
  );
};
