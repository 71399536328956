import { zodResolver } from '@hookform/resolvers/zod';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { parseISO } from 'date-fns/parseISO';
import { ResolverOptions } from 'react-hook-form';
import { z } from 'zod';
import { InvoiceStatusEnumZ } from './invoiceTypes';
import { IOpportunity } from './opportunityTypes';
import { ProductTypeEnumZ } from './productTypes';
import type { IQuoteBasicRespSchema } from './quoteTypes';

export enum ContractStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  FINISHED = 'FINISHED',
}
export const ContractStatusEnumZ = z.nativeEnum(ContractStatusEnum);

export enum ContractEndActionEnum {
  RENEW = 'RENEW',
  MANUAL_RENEW = 'MANUAL_RENEW',
  EARLY_RENEWAL = 'EARLY_RENEWAL',
  CANCEL = 'CANCEL',
}
export const ContractEndActionEnumZ = z.nativeEnum(ContractEndActionEnum);

export enum RenewalTermLengthEnum {
  ContractLength = 'ContractLength',
  FixedMonths = 'FixedMonths',
}
export const RenewalTermLengthEnumZ = z.nativeEnum(RenewalTermLengthEnum);

export enum ContractCancelTypeEnum {
  SPECIFIC_DATE = 'SPECIFIC_DATE',
  ENTIRE_CONTRACT = 'ENTIRE_CONTRACT',
}

export const ContractCancelTypeEnumZ = z.nativeEnum(ContractCancelTypeEnum);

// from /api/accounts/{accountId}/contracts and /api/contracts
// Swagger may show it returning quotes property, but that has been removed
export const ContractRespSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  accountName: z.string(),
  billGroupId: z.string(),
  latestQuoteId: z.string(),
  legalEntityId: z.string(),
  status: ContractStatusEnumZ,
  startDate: z.string(),
  endDate: z.string(),
  endAction: ContractEndActionEnumZ,
  modifyDate: z.string(),
  totalValue: z.number(),
  owner: z.string(), // this is userId
  ownerName: z.string(),
  renewed: z.boolean(),
  currency: z.string(),
  contractRenewalTerms: z
    .object({
      contractEndAction: ContractEndActionEnumZ,
      renewalTermLength: z
        .object({
          type: RenewalTermLengthEnumZ,
          months: z.number().nullish(),
        })
        .nullish(),
      autoRenewalNoticePeriod: z.number(),
    })
    .nullish(),
  newQuoteType: ContractEndActionEnumZ.nullish(),
});
export type IContract = z.infer<typeof ContractRespSchema>;

// FIXME: this should be a ZOD schema, but there is a circular import that needs to be resolved
// from /api/contracts/{contractId}
export type IContractWithQuotes = IContract & {
  quotes: IQuoteBasicRespSchema[];
};

export const CancelContractUISchema = z.object({
  type: ContractCancelTypeEnumZ,
  date: z.string().nullish(),
});

export type ICancelContractUISchema = z.infer<typeof CancelContractUISchema>;

export const getContractUIRequestSchema =
  (contract: IContract) =>
  (
    data: ICancelContractUISchema,
    context: object | undefined,
    options: ResolverOptions<ICancelContractUISchema>,
  ) => {
    const modifiedReqSchema = CancelContractUISchema.superRefine(
      ({ type, date }, ctx) => {
        if (
          type === ContractCancelTypeEnum.SPECIFIC_DATE &&
          date &&
          !isWithinInterval(parseISO(date), {
            start: parseISO(contract.startDate),
            end: parseISO(contract.endDate),
          })
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Date must be between Contract start and end date`,
            path: ['date'],
          });
        }
      },
    );
    return zodResolver(modifiedReqSchema)(data, context, options);
  };

export const ServicePeriod = z.object({
  start: z.string(),
  end: z.string(),
  lengthInDays: z.number(),
});

export const BillingSchedulePeriodItemsSchema = z.object({
  servicePeriod: ServicePeriod,
  offeringName: z.string(),
  productName: z.string(),
  productType: ProductTypeEnumZ,
  quantity: z.number(),
  pricePerUnit: z.number(),
  amount: z.number(),
  lineNumber: z.number(),
});

export type IBillingSchedulePeriodItemsSchema = z.infer<
  typeof BillingSchedulePeriodItemsSchema
>;

export const BillingSchedulePeriodRespSchema = z.object({
  invoiceDate: z.string(),
  servicePeriod: ServicePeriod,
  amount: z.number(),
  status: z.string(),
  invoiceId: z.string(),
  invoiceStatus: InvoiceStatusEnumZ,
  items: z.array(BillingSchedulePeriodItemsSchema),
});

export const BillingSchedulePeriodRespSchemaUI = z.object({
  uid: z.string(),
  invoiceDate: z.string(),
  servicePeriod: ServicePeriod,
  amount: z.number(),
  status: z.string(),
  invoiceId: z.string(),
  invoiceStatus: InvoiceStatusEnumZ,
  usageProductExists: z.boolean(),
  items: z.array(
    z.object({
      uid: z.string(),
      servicePeriod: ServicePeriod,
      offeringName: z.string(),
      productName: z.string(),
      productType: ProductTypeEnumZ,
      quantity: z.number(),
      pricePerUnit: z.number(),
      amount: z.number(),
    }),
  ),
});

export type IBillingSchedulePeriodRespSchema = z.infer<
  typeof BillingSchedulePeriodRespSchema
>;

export enum BillingScheduleRespStatusEnum {
  INVOICED = 'INVOICED',
  NOT_INVOICED = 'NOT_INVOICED',
}
export const BillingScheduleRespStatusEnumZ = z.nativeEnum(
  BillingScheduleRespStatusEnum,
);

export const BillingScheduleRespSchema = z.object({
  servicePeriod: ServicePeriod,
  amount: z.number(),
  status: BillingScheduleRespStatusEnumZ,
  periods: z.array(BillingSchedulePeriodRespSchema),
});

export type IBillingScheduleResSchema = z.infer<
  typeof BillingScheduleRespSchema
>;

const BillingScheduleRespSchemaUI = BillingScheduleRespSchema.extend({
  periodsWithUid: z.array(BillingSchedulePeriodRespSchemaUI),
  usageProductExists: z.boolean(),
});

export type IBillingScheduleRespSchemaUI = z.infer<
  typeof BillingScheduleRespSchemaUI
>;

export enum ProductInclusionEnum {
  AS_OF_RENEWAL_DATE = 'AS_OF_RENEWAL_DATE',
  END_OF_CONTRACT_DATE = 'END_OF_CONTRACT_DATE',
}

export const ProductInclusionEnumZ = z.nativeEnum(ProductInclusionEnum);

const ContractRenewalReqSchema = z.object({
  newQuoteType: ContractEndActionEnumZ.nullish(),
  renewalDate: z.string().nullish(),
  includeSubscriptionsAsOfRenewalDate: z.boolean(),
  contractLength: z.number().nullish(),
});

export type IContractRenewalReqSchema = Partial<
  z.infer<typeof ContractRenewalReqSchema>
> & {
  opportunity?: Partial<Pick<IOpportunity, 'id' | 'name' | 'customId'>>;
};

export const ContractRenewalUiSchema = z.object({
  newQuoteType: ContractEndActionEnumZ,
  renewalDate: z.string().nullish(),
  productInclusion: ProductInclusionEnumZ,
  contractLength: z.number(),
});
export type ContractRenewalUi = z.infer<typeof ContractRenewalUiSchema>;
