import { boolean, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { AccountSummarySchema } from './accountTypes';
import { AddressSchema } from './addressTypes';
import { ContactRespSchema } from './contactTypes';
import { GetCreditSchema } from './creditTypes';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { InvoiceCreditNoteSummarySchema } from './invoiceTypes';
import { BaseResponseSchema } from './miscTypes';

export enum CreditNoteTypeEnum {
  REFUND = 'REFUND',
  ADJUSTMENT = 'ADJUSTMENT',
  WRITE_OFF = 'WRITE_OFF',
}
export const CreditNoteTypeEnumZ = z.nativeEnum(CreditNoteTypeEnum);

export enum CreditNoteStatusEnum {
  CANCELED = 'CANCELED',
  APPLIED = 'APPLIED',
  VOID = 'VOID',
}
export const CreditNoteStatusEnumZ = z.nativeEnum(CreditNoteStatusEnum);

export enum CreditNoteRefundReasonEnum {
  BILLING_ERROR = 'BILLING_ERROR',
  SERVICE_ISSUE = 'SERVICE_ISSUE',
  CANCELLATION = 'CANCELLATION',
  ADJUSTMENT = 'ADJUSTMENT',
  OTHER = 'OTHER',
}
export const CreditNoteRefundReasonEnumZ = z.nativeEnum(
  CreditNoteRefundReasonEnum,
);

export const CreditNoteItemSchema = z.object({
  id: z.string(),
  creditNoteId: z.string().nullable(),
  invoiceItemId: z.string().nullable(),
  description: z.string(),
  periodStartDate: z.string(),
  periodEndDate: z.string(),
  quantity: z.string(),
  amount: z.number(),
});

export type ICreditNoteItemSchema = z.infer<typeof CreditNoteItemSchema>;

export const CreditNoteSchema = BaseResponseSchema.extend({
  accountId: z.string(),
  accountSummary: AccountSummarySchema,
  billGroupId: z.string(),
  currency: z.string(),
  invoiceId: z.string().nullish(),
  invoice: InvoiceCreditNoteSummarySchema.nullish(),
  creditId: z.string().nullable(),
  credit: GetCreditSchema,
  description: z.string().nullish(),
  type: CreditNoteTypeEnumZ,
  status: CreditNoteStatusEnumZ,
  amount: z.number(),
  amountWithoutTax: z.number(),
  tax: z.number(),
  taxCalculationStatus: z.string().nullish(),
  taxCalculationError: z.string().nullish(),
  refundable: boolean(),
  creditNoteItems: CreditNoteItemSchema,
  customFields: z.record(z.any()),
  invoiceIncomeAccountSyncType: z.string().nullish(),
  billingContact: ContactRespSchema.nullish(),
  shippingContact: ContactRespSchema.nullish(),
  billingAddress: AddressSchema.nullish(),
  shippingAddress: AddressSchema.nullish(),
  voidReason: string().optional(),
});

export type ICreditNoteSchema = z.infer<typeof CreditNoteSchema>;

export const CreditNoteReqSchema = z.object({
  type: CreditNoteTypeEnumZ,
  amount: z
    .union([z.string(), z.number()])
    .refine(
      (val) => val !== null && val !== undefined && val !== '',
      getRequiredMessage('Amount'),
    )
    .transform((val) => Number(val)),
  refundable: boolean(),
  refundReason: CreditNoteRefundReasonEnumZ.optional(),
  otherReason: string().optional(),
  customFields: CustomFieldRecordSchema.nullable().optional(),
});

export type ICreditNoteReqSchema = z.infer<typeof CreditNoteReqSchema>;
