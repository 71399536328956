import { BoxProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { forwardRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useCreateContact,
  useUpdateContact,
} from '../../../../api/contactsService';
import { useGetById } from '../../../../api/queryUtils';
import { ContactForm } from '../../../../components/Contacts/ContactForm';
import {
  MBox,
  MButton,
  MCloseButton,
  MFlex,
} from '../../../../components/Monetize';
import { DEFAULT_COUNTRY_US } from '../../../../constants/contacts';
import { logger } from '../../../../services/logger';
import {
  ContactRequestSchema,
  ContactStatusEnum,
  IContactRequestSchema,
  IContactRespSchema,
} from '../../../../types';

export interface OnetimeInvoiceContactFormProps extends BoxProps {
  accountId: string;
  isInternal?: boolean;
  isCcContact?: boolean;
  isCompact?: boolean;
  existingContact?: IContactRespSchema;
  onClose: (contact?: IContactRespSchema) => void;
}

export const OnetimeInvoiceContactForm = forwardRef<
  any,
  OnetimeInvoiceContactFormProps
>(
  (
    {
      accountId,
      isInternal = false,
      isCcContact = false,
      isCompact = true,
      existingContact,
      onClose,
      ...rest
    }: OnetimeInvoiceContactFormProps,
    ref,
  ) => {
    const formDefault: Partial<IContactRequestSchema> = {
      snapshotId: existingContact?.snapshotId ?? null,
      title: existingContact?.title ?? '',
      fullName: existingContact?.fullName || '',
      email: existingContact?.email || '',
      phone: existingContact?.phone || null,
      customId: existingContact?.customId || null,
      address: existingContact?.address || {
        city: null,
        country: DEFAULT_COUNTRY_US,
        line1: null,
        line2: null,
        state: null,
        postalCode: null,
      },
      status: existingContact?.status || ContactStatusEnum.ACTIVE,
      internal: existingContact?.internal ?? isInternal,
      primary: existingContact?.primary ?? false,
      customFields: {},
    };

    const { isLoading: isContactFetchLoading, data: contact } =
      useGetById<IContactRespSchema>('contacts', existingContact?.id!, {
        enabled: !!existingContact?.id,
        refetchOnWindowFocus: false,
        select: (data) => {
          if (data.address && !data.address.country) {
            data.address.country = DEFAULT_COUNTRY_US;
          }
          return data;
        },
      });

    useEffect(() => {
      if (contact) {
        reset(Object.assign({}, formDefault, contact));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact]);

    const {
      handleSubmit,
      control,
      formState: { errors },
      trigger,
      setValue,
      watch,
      reset,
    } = useForm<IContactRequestSchema>({
      resolver: zodResolver(ContactRequestSchema),
      mode: 'onBlur',
      defaultValues: contact || formDefault,
    });

    const isValid = Object.keys(errors).length === 0;

    const { mutate: doCreateContact, isPending: saveLoading } =
      useCreateContact({
        onSuccess: (response) => onClose(response),
        meta: { showErrorToast: true },
      });

    const { mutate: doUpdateContact, isPending: updateLoading } =
      useUpdateContact({
        onSuccess: (response) => onClose(response),
        meta: { showErrorToast: true },
      });

    const isLoading =
      saveLoading ||
      updateLoading ||
      (existingContact && isContactFetchLoading);

    const onSubmit = async (payload: IContactRequestSchema) => {
      if (!isCcContact && payload?.internal) {
        payload.esigner = true;
      } else if (!isCcContact && !payload?.internal) {
        payload.esigner = false;
      }
      if (existingContact?.id) {
        doUpdateContact({
          contactId: existingContact.id,
          payload,
        });
      } else {
        doCreateContact({
          payload,
          accountId,
        });
      }
    };

    const onError = (errs: any, event: any) => {
      logger.warn(`ERRORS:`, errs);
    };

    return (
      <MBox {...rest}>
        <MFlex justifyContent="end" alignItems="center">
          <MCloseButton onClick={() => onClose()} />
        </MFlex>
        <ContactForm
          isCompact={isCompact}
          isCompactForCcContact={isCcContact}
          omitPrimary
          formId="contact-form"
          isDisabled={isLoading}
          control={control}
          errors={errors}
          trigger={trigger}
          watch={watch}
          setValue={setValue}
          handleSubmit={handleSubmit(onSubmit, onError)}
          contactId={existingContact?.id}
        >
          <MButton
            data-testid="contact-form-submit"
            variant="primary"
            form="contact-form"
            type="submit"
            size={'sm'}
            isLoading={isLoading}
            isDisabled={!isValid || isLoading}
          >
            {existingContact?.id ? 'Update' : 'Add'}
          </MButton>
        </ContactForm>
      </MBox>
    );
  },
);
