import { Popover, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import { DocumentIcon } from '@monetize/ui/icons';
import { FC } from 'react';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MText,
} from '../../../../../components/Monetize';
import { QUOTE_READONLY_STATUSES } from '../../../../../constants/quotes';
import { IQuoteRequestSchema, IQuoteRespSchema } from '../../../../../types';
import { getNumberOfDocuments } from './QuoteDocuments';
import { QuoteDocumentsPopoverContent } from './QuoteDocumentsPopoverContent';

interface QuoteDocumentsPopoverProps {
  quote?: IQuoteRespSchema | null;
  isReadOnly?: boolean;
  onUpdate: (data: IQuoteRequestSchema, requestType: string) => void;
}

export const QuoteDocumentsPopover: FC<QuoteDocumentsPopoverProps> = ({
  quote,
  isReadOnly,
  onUpdate,
}: QuoteDocumentsPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  if (!quote) {
    return null;
  }
  const disabled = isReadOnly || QUOTE_READONLY_STATUSES.has(quote.status);
  const numDocuments = getNumberOfDocuments(quote!);
  const hasDocuments = !!numDocuments;

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <MBox position="relative" alignSelf="flex-end" cursor="pointer">
          <MCustomIconButton
            p={4}
            minH="8"
            variant="icon"
            aria-label="Documents"
            btnSize={5}
            icon={DocumentIcon}
          />
          {hasDocuments && (
            <MFlex
              position="absolute"
              justifyContent="center"
              alignItems="center"
              top={0}
              right={1}
              width={4}
              height={4}
              borderRadius={8}
              bgColor="tIndigo.base"
              border="1px solid white"
            >
              <MText fontWeight="bold" color="tWhite.base" fontSize="xxs">
                {numDocuments}
              </MText>
            </MFlex>
          )}
        </MBox>
      </PopoverTrigger>
      <QuoteDocumentsPopoverContent
        quote={quote}
        disabled={disabled}
        onUpdate={onUpdate}
        onClose={onClose}
      />
    </Popover>
  );
};
