import * as z from 'zod';
import { TenantStatusEnum } from '../constants/tenants';
import { getRequiredMessage } from '../utils/messages';
import { IUser, UserStatusEnum } from './userTypes';

export const TenantSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  timezone: z.string(),
  testTenant: z.boolean(),
  isAdmin: z.boolean(),
  status: z.nativeEnum(TenantStatusEnum),
});

export const TenantRequestSchema = TenantSchema.pick({
  description: true,
  timezone: true,
  testTenant: true, // BE ignores this on update requests
}).extend({
  name: z.string().min(1, getRequiredMessage('Name')),
});

export type TenantRequest = z.infer<typeof TenantRequestSchema>;

export type ITenant = z.infer<typeof TenantSchema>;

export interface TenantUserMap {
  tenant: ITenant;
  user: Omit<IUser, 'roles' | 'teams' | 'status'>;
  status: UserStatusEnum;
}
