import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatCurrency, sortByProductPosition } from '@monetize/utils/core';
import { formatISO } from 'date-fns/formatISO';
import { Fragment, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { handleApiErrorToast } from '../../../api/axios';
import { useGetAccountContacts } from '../../../api/contactsService';
import { useCreateOneTimeInvoice } from '../../../api/invoiceService';
import { doGetOfferingById } from '../../../api/productCatalogService';
import { useGetById } from '../../../api/queryUtils';
import { HeaderCell } from '../../../components/Invoices';
import {
  MBox,
  MButton,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MInput,
  MLink,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MSimpleGrid,
  MText,
} from '../../../components/Monetize';
import {
  GRID_PROPS,
  HEADER_ROW_CELLS,
} from '../../../constants/oneTimeInvoice';
import { getInvoiceDetailRoute } from '../../../constants/routes';
import { useNetTerms } from '../../../hooks';
import { logger } from '../../../services/logger';
import {
  AddressSourceEnum,
  DEFAULT_PAGER,
  IBillGroupResp,
  IContactAddressDataSchema,
  IContactRespSchema,
  ICustomFieldRecordSchema,
  NetTermsEnum,
  TDataTablePager,
} from '../../../types';
import { ILegalEntityResponseSchema } from '../../../types/legalEntityTypes';
import {
  IOneTimeInvoiceFormSchemaWithOfferings,
  OneTimeInvoiceFormSchema,
  OneTimeInvoiceRequestSchema,
} from '../../../types/oneTimeInvoiceTypes';
import { arrayToObject } from '../../../utils/misc';
import { roundNumberToDecimal } from '../../../utils/numberUtils';
import { ContactsDrawer } from './components/ContactsDrawer';
import { OneTimeInvoiceContactIcon } from './components/OneTimeInvoiceContactIcon';
import { OneTimeInvoiceCustomFieldRow } from './components/OneTimeInvoiceCustomFieldRow';
import { OneTimeInvoiceOffering } from './components/OneTimeInvoiceOffering';
import { OneTimeInvoiceOfferingDropdown } from './components/OneTimeInvoiceOfferingDropdown';
import { TotalRow } from './components/TotalRow';
import { getProductObj } from './oneTimeInvoice.utils';

const accountContactsPager: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'fullName',
  sortOrder: 1,
  rows: 100,
};

export const OneTimeInvoiceForm = () => {
  const navigate = useNavigate();
  // TODO: handle case where any of these are missing - probably error state
  const { accountId = '' } = useParams();

  // We could re-fetch bill group instead of using URL, but this works.
  const [searchParams] = useSearchParams();
  const billGroupId = searchParams.get('billGroupId') || '';
  const currency = searchParams.get('currency') || 'USD';
  const legalEntityId = searchParams.get('legalEntityId') || '';
  const addressSource = (searchParams.get('addressSource') ||
    AddressSourceEnum.CONTACT) as AddressSourceEnum;
  const billingContactId = searchParams.get('billingContactId') || '';
  const shippingContactId = searchParams.get('shippingContactId') || '';
  const billingAddressId = searchParams.get('billingAddressId');
  const shippingAddressId = searchParams.get('shippingAddressId');
  const netTerms = (searchParams.get('netTerms') ||
    NetTermsEnum.NET_30) as NetTermsEnum;
  const purchaseOrderNumber = searchParams.get('purchaseOrderNumber');

  const {
    isOpen: isContactsDrawerOpen,
    onOpen: onContactsDrawerOpen,
    onClose: onContactsDrawerClose,
  } = useDisclosure();

  const {
    mutateAsync: createOneTimeInvoice,
    isPending: isCreatingOneTimeInvoice,
  } = useCreateOneTimeInvoice();

  const { data: billGroup, isLoading: billGroupLoading } =
    useGetById<IBillGroupResp>('billGroups', billGroupId!, {
      enabled: !!billGroupId,
      refetchOnWindowFocus: false,
    });

  useEffect(() => {
    if (billGroup) {
      const values = getValues();
      if (!values.netTerms && billGroup.netTerms) {
        setValue('netTerms', billGroup.netTerms);
      }
      if (!values.purchaseOrderNumber && billGroup.netTerms) {
        setValue('purchaseOrderNumber', billGroup.purchaseOrderNumber);
      }
      if (!values.vatNumber && billGroup.vatNumber) {
        setValue('vatNumber', billGroup.vatNumber);
      }
      if (!values.registrationNumber && billGroup.registrationNumber) {
        setValue('registrationNumber', billGroup.registrationNumber);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billGroup]);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm<IOneTimeInvoiceFormSchemaWithOfferings>({
    resolver: zodResolver(OneTimeInvoiceFormSchema),
    defaultValues: {
      accountId,
      billGroupId,
      currency,
      legalEntityId,
      addressInformation: {
        addressSource,
        billingAddressId,
        shippingAddressId,
      },
      billingContactId,
      shippingContactId,
      netTerms: netTerms ?? billGroup?.netTerms ?? NetTermsEnum.NET_30,
      purchaseOrderNumber:
        purchaseOrderNumber ?? billGroup?.purchaseOrderNumber ?? '',
      vatNumber: billGroup?.vatNumber ?? '',
      registrationNumber: billGroup?.registrationNumber ?? '',
      customFields: {},
    },
  });

  const selectedNetTerm = getValues('netTerms');
  const watchedOfferings = watch('offerings') || [];
  const selectedBillingContactId = getValues('billingContactId');
  const selectedShippingContactId = getValues('shippingContactId');
  const selectedAddressInformation = getValues('addressInformation');
  const watchCustomFields = getValues('customFields');

  const { activeNetTerms: netTermsOptions, isLoading: isLoadingNetTerms } =
    useNetTerms(selectedNetTerm);

  const { isLoading: legalEntityLoading } =
    useGetById<ILegalEntityResponseSchema>('legalEntities', legalEntityId!, {
      enabled: !!legalEntityId,
    });

  const { data: accountContacts, isLoading: isAccountContactsLoading } =
    useGetAccountContacts<{
      contacts: IContactRespSchema[];
      contactsById: Record<string, IContactRespSchema>;
    }>(
      {
        accountId,
        config: accountContactsPager,
        isGetAll: true,
      },
      {
        enabled: !!accountId,
        select: (data) => {
          return {
            contacts: data.content,
            contactsById: arrayToObject(data.content, 'id'),
          };
        },
      },
    );

  const selectedShippingContact =
    accountContacts?.contactsById?.[selectedShippingContactId];

  const {
    fields: offeringFields,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'offerings',
  });

  const handleAddOffering = async (offeringId: string) => {
    try {
      const offering = await doGetOfferingById(offeringId);
      const sortedOfferingProducts = sortByProductPosition(
        offering.offeringProducts,
      );

      if (offering.products.length <= 0) {
        handleApiErrorToast(
          new Error('The selected Offering does not have any products.'),
        );
        return;
      }
      setValue('offerings', [
        ...(getValues().offerings || []),
        {
          offeringId: offering.id,
          offeringDescription: offering.name,
          startDate: formatISO(new Date(), { representation: 'date' }),
          endDate: null,
          products: sortedOfferingProducts.map((offeringProduct) =>
            getProductObj({
              ...offeringProduct.product,
              isMandatory: offeringProduct.isMandatory,
              isSelected: offeringProduct.isMandatory,
              position: offeringProduct.position,
            }),
          ),
          metadata: { loading: false, offering },
        },
      ]);
    } catch (ex) {
      logger.error('Error while adding offering', ex);
      handleApiErrorToast(
        new Error(
          'There was an error updating your offering, please try again.',
        ),
      );
    }
  };

  const handleUpdateOffering = async (index: number, offeringId: string) => {
    try {
      const formOffering = getValues(`offerings.${index}`);
      update(index, {
        ...formOffering,
        metadata: { ...formOffering.metadata, loading: true },
      });
      const offering = await doGetOfferingById(offeringId);
      const sortedOfferingProducts = sortByProductPosition(
        offering.offeringProducts,
      );
      // Revert back if offering has no products
      if (offering.products.length <= 0) {
        update(index, formOffering);
        handleApiErrorToast(
          new Error('The selected Offering does not have any products.'),
        );
        return;
      }
      update(index, {
        ...formOffering,
        offeringId: offering.id,
        offeringDescription: offering.name,
        products: sortedOfferingProducts.map((offeringProduct) =>
          getProductObj({
            ...offeringProduct.product,
            isMandatory: offeringProduct.isMandatory,
            isSelected: offeringProduct.isMandatory,
            position: offeringProduct.position,
          }),
        ),
        metadata: { loading: false, offering },
      });
    } catch (ex) {
      logger.error('Error while updating offering', ex);
      handleApiErrorToast(
        new Error(
          'There was an error updating your offering, please try again.',
        ),
      );
    }
  };

  const handleSaveContacts = ({
    primaryId,
    billingId,
  }: {
    primaryId?: string;
    billingId?: string;
  }) => {
    setValue('billingContactId', billingId as any, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue('shippingContactId', primaryId as any, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const handleSaveAddressData = (payload: IContactAddressDataSchema) => {
    setValue('addressInformation', payload, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useEffect(() => {
    // TODO: should show toast error message
    if (!accountId || !billGroupId || !currency || !legalEntityId) {
      navigate(-1);
    }
  }, [accountId, billGroupId, currency, legalEntityId]);

  const total = watchedOfferings
    .flatMap(({ products }) =>
      products.map(({ quantity, unitPrice }) => quantity * unitPrice),
    )
    .reduce((acc, curr) => (curr += acc), 0);

  const formattedTotal = formatCurrency(roundNumberToDecimal(total), {
    currency,
  });

  async function handleSave(data: IOneTimeInvoiceFormSchemaWithOfferings) {
    try {
      let i = 1;
      const invoice = OneTimeInvoiceRequestSchema.parse({
        ...data,
        invoiceItems: data.offerings.flatMap(
          ({ offeringId, offeringDescription, startDate, endDate, products }) =>
            products
              .filter(({ isSelected }) => isSelected)
              .map((product) => ({
                offeringId,
                offeringDescription,
                startDate,
                endDate: endDate ?? startDate,
                position: i++,
                ...product,
              })),
        ),
      });

      const createdInvoice = await createOneTimeInvoice({
        billGroupId,
        invoice,
      });
      // TODO: redirect to invoice page
      navigate(getInvoiceDetailRoute(createdInvoice.id));
    } catch (ex) {
      handleApiErrorToast(ex);
      logger.error('Error while creating one time invoice', ex);
    }
  }

  function handleError(err: unknown) {
    console.warn('Error saving invoice', err);
  }

  if (billGroupLoading || legalEntityLoading || isAccountContactsLoading) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer data-testid="one-time-invoice-form">
      <form
        style={{ width: '100%' }}
        onSubmit={handleSubmit(handleSave, handleError)}
      >
        <MPageHeader
          hasBackButton
          backButtonTitle="Go Back"
          title="Create Invoice"
          status="Draft"
          backButtonCallback={() => navigate(-1)}
          subTitle={
            <MText>
              This is a one-time invoice. Changes made here will not affect any
              default settings within this Bill Group.
              <MLink
                pl={0.5}
                href="https://docs.monetizenow.io/docs/one-time-invoices"
                target="_blank"
                textDecoration="underline"
              >
                Learn more
              </MLink>
            </MText>
          }
          childrenProps={{ alignSelf: 'flex-start' }}
        >
          <MFlex align="center" gap="2">
            <OneTimeInvoiceContactIcon
              selectedShippingContactId={selectedShippingContactId}
              onOpen={onContactsDrawerOpen}
            />
            <MButton
              type="submit"
              variant="primary"
              isLoading={isCreatingOneTimeInvoice}
              isDisabled={!isValid || isCreatingOneTimeInvoice}
            >
              Submit
            </MButton>
            {/* TODO: This should not show up on unsaved invoices */}
            {/* <OneTimeInvoiceActions /> */}
          </MFlex>
        </MPageHeader>
        <MBox pl={8} pr={2} overflowX="auto">
          {/** Form Heading Section */}
          <MFlex justifyContent="space-between" align="flex-start" mb="6">
            <MFlex gap="6">
              <MFormField label="Contacts" isRequired w="40">
                <MButton
                  variant="tertiary"
                  size="sm"
                  ml={-2.5}
                  onClick={onContactsDrawerOpen}
                  w="full"
                  display="block"
                  overflow="hidden"
                  textAlign="left"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {selectedShippingContact
                    ? selectedShippingContact.fullName
                    : 'Set Contacts'}
                </MButton>
              </MFormField>
              <MFormField
                label="Net Terms"
                isRequired
                w="5.7rem"
                error={errors?.netTerms}
              >
                <Controller
                  name="netTerms"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      itemTitle="name"
                      itemValue="value"
                      items={netTermsOptions}
                      placeholder="Select Net Terms"
                      loading={!field.value && isLoadingNetTerms}
                      {...field}
                    />
                  )}
                />
              </MFormField>
              <MFormField
                label="PO Number"
                w="36"
                error={errors?.purchaseOrderNumber}
              >
                <Controller
                  name="purchaseOrderNumber"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput value={value || ''} {...rest} />
                  )}
                />
              </MFormField>
              <MFormField label="VAT Number" w="36" error={errors?.vatNumber}>
                <Controller
                  name="vatNumber"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput value={value || ''} {...rest} />
                  )}
                />
              </MFormField>
              <MFormField
                label="Registration Number"
                w="36"
                error={errors?.registrationNumber}
              >
                <Controller
                  name="registrationNumber"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput value={value || ''} {...rest} />
                  )}
                />
              </MFormField>
            </MFlex>
          </MFlex>

          <MSimpleGrid {...GRID_PROPS}>
            {/** Form Table Heading Section */}
            {HEADER_ROW_CELLS.map(({ label, value, isRequired }) => {
              return (
                <HeaderCell
                  key={value}
                  value={value}
                  isRequired={isRequired}
                  pr={label === 'Amount' ? 4 : undefined}
                >
                  {label}
                </HeaderCell>
              );
            })}
          </MSimpleGrid>
          <MDivider gridColumn="1/-1" />

          {offeringFields.map((item, index) => (
            <Fragment key={item.id}>
              <OneTimeInvoiceOffering
                control={control}
                errors={errors}
                index={index}
                values={item}
                handleRemove={(index) => {
                  remove(index);
                }}
                handleUpdate={handleUpdateOffering}
                currency={currency}
              />
              <MDivider />
            </Fragment>
          ))}

          {/** Form Table Body Section */}
          <MFormField py="2" maxW="12.5rem">
            <OneTimeInvoiceOfferingDropdown onChange={handleAddOffering} />
          </MFormField>
          <MDivider gridColumn="1/-1" />

          {/** Form Total Section */}
          <MBox ml="auto" w="22rem">
            <TotalRow leftColumn="SubTotal" rightColumn={formattedTotal} />
            <MDivider />
            <TotalRow
              leftColumn="Tax"
              rightColumn={
                <MText
                  fontStyle="italic"
                  fontSize="md"
                  textAlign="right"
                  fontWeight="400"
                  color="tGray.darkPurple"
                  pr="2"
                >
                  Calculated on Review
                </MText>
              }
            />
            <MDivider />
            <TotalRow
              leftColumn="Total"
              containerProps={{
                background: 'tGray.support',
                borderRadius: '7px',
              }}
              textProps={{ fontSize: 'lg' }}
              rightColumn={formattedTotal}
            />
          </MBox>
          <OneTimeInvoiceCustomFieldRow
            customFields={watchCustomFields as ICustomFieldRecordSchema}
            updateValue={(val) => {
              setValue('customFields', val, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
          />
        </MBox>
      </form>
      {isContactsDrawerOpen && (
        <ContactsDrawer
          accountId={accountId}
          onClose={onContactsDrawerClose}
          contacts={accountContacts?.contacts || []}
          contactsById={accountContacts?.contactsById || {}}
          onSaveAddressData={handleSaveAddressData}
          onSaveContacts={handleSaveContacts}
          initialContactsWithAddressInformation={{
            billingContactId: selectedBillingContactId,
            shippingContactId: selectedShippingContactId,
            addressInformation: selectedAddressInformation,
          }}
        />
      )}
    </MPageContainer>
  );
};
