import { MCustomSelect, MFlex, MText } from '../../components/Monetize';

import { ALL_CURRENCIES, CurrencyCodes } from '../../constants/currencies';
import { useDashboardCurrencyFilter } from './components/useDashboardCurrencyFilter';

export const DashboardCurrencyFilter = () => {
  const { currency, setCurrency } = useDashboardCurrencyFilter();

  return (
    <MCustomSelect
      maxW="9.125rem" // 146px
      useMainInputAsSearchInput
      itemTitle="code"
      itemValue="code"
      displayAvatar
      endpoint={`/api/currencies`}
      popOverProps={{
        matchWidth: false,
      }}
      popOverContentProps={{
        minW: '13rem',
      }}
      renderItemContent={({ item, isSelected }) => {
        return (
          <MFlex alignItems="center">
            <MText fontWeight="700" mr="2">
              {item.code}
            </MText>
            <MText fontWeight="normal">
              {ALL_CURRENCIES[item.code as CurrencyCodes].name}
            </MText>
          </MFlex>
        );
      }}
      value={currency}
      onChange={(val: any) => {
        setCurrency(val);
      }}
    />
  );
};
