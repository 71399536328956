import { ContactStatusEnum } from '../types';

export const CONTACT_STATUS_DISPLAY: {
  [key in ContactStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
};

export const NEW_CONTACT = {
  id: 'NEW_CONTACT',
  fullName: '+ New Contact',
};

export const DEFAULT_COUNTRY_US = 'US';
