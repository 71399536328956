import React, { FunctionComponent as FC, useState } from 'react';

import {
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

interface MPasswordInputProps extends InputProps {}
const MPasswordInput: FC<MPasswordInputProps> = React.forwardRef<
  any,
  MPasswordInputProps
>((props: MPasswordInputProps, ref) => {
  const { ...rest } = props;
  const [keyShow, setKeyShow] = useState(false);
  return (
    <InputGroup size="sm">
      <Input pr="2rem" type={keyShow ? 'text' : 'password'} {...rest} />
      <InputRightElement width="2rem">
        <Icon
          cursor="pointer"
          color="tGray.lightPurple"
          as={keyShow ? MdVisibility : MdVisibilityOff}
          onClick={() => setKeyShow(!keyShow)}
        />
      </InputRightElement>
    </InputGroup>
  );
});

export default MPasswordInput;
