import { LinkProps } from '@chakra-ui/react';
import { FunctionComponent as FC } from 'react';
import { Link } from 'react-router-dom';
import { MLink } from '../../../components/Monetize';
import { getQuoteReviewRoute } from '../../../constants/routes';
import { IQuoteRespSchema, QuoteTypeEnum } from '../../../types';

interface ViewOriginalLinkProps extends LinkProps {
  quote: IQuoteRespSchema;
}

const ViewOriginalLink: FC<ViewOriginalLinkProps> = ({
  quote,
  ...rest
}: ViewOriginalLinkProps) => {
  if (
    quote?.type === QuoteTypeEnum.AMENDMENT ||
    quote?.type === QuoteTypeEnum.RENEWAL
  ) {
    return (
      <MLink
        to={getQuoteReviewRoute(quote.originalQuoteId!)}
        as={Link}
        px={4}
        fontSize="sm"
        fontWeight="semibold"
        _hover={{ textDecoration: 'underline' }}
        {...rest}
      >
        View Original
      </MLink>
    );
  }
  return null;
};

export default ViewOriginalLink;
