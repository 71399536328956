import { ListItem, UnorderedList } from '@chakra-ui/react';
import { FC } from 'react';
import { MdInfo } from 'react-icons/md';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionItem,
  MAccordionPanel,
  MBox,
  MFlex,
  MIcon,
  MPageLoader,
  MText,
  MTooltip,
} from '../../../../components/Monetize';
import { QuoteStatusEnum } from '../../../../types';
import { QuoteDocumentsView } from '../../Quote/components/quoteDocuments/QuoteDocumentsView';
import { useQuoteContext } from '../../Quote/quoteContext';

interface QuoteUploadDocumentsProps {}
export const QuoteUploadDocuments: FC<
  QuoteUploadDocumentsProps
> = ({}: QuoteUploadDocumentsProps) => {
  const { isReadOnlyQuote, quoteData, handleUpdateQuoteWithFormReset } =
    useQuoteContext();

  const { quote } = quoteData;

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <MAccordion allowToggle defaultIndex={0}>
      <MAccordionItem borderColor="tGray.lightGray">
        {({ isExpanded }) => (
          <>
            <MAccordionCustomButton
              isExpanded={isExpanded}
              customLabel={
                <>
                  <MText ml="2" fontSize="lg" fontWeight="bold">
                    Documents
                  </MText>
                  <MTooltip
                    usePopover={true}
                    placement="end-start"
                    label={
                      <MBox fontSize="xs">
                        The Documents in this section will appear on the PDF in
                        the following order:
                        <UnorderedList pl="2">
                          <ListItem>Cover</ListItem>
                          <ListItem>Order Form</ListItem>
                          <ListItem>Footer</ListItem>
                          <ListItem>Statement of Work (SOW)</ListItem>
                          <ListItem>Master Service Agreement (MSA)</ListItem>
                        </UnorderedList>
                      </MBox>
                    }
                  >
                    <MFlex alignItems="center">
                      <MIcon as={MdInfo} ml="2" />
                    </MFlex>
                  </MTooltip>
                </>
              }
              label="Documents"
            ></MAccordionCustomButton>
            <MAccordionPanel p="4" m="0">
              <QuoteDocumentsView
                isReadOnly={
                  isReadOnlyQuote || quote?.status !== QuoteStatusEnum.DRAFT
                }
                quote={quote}
                onUpdate={handleUpdateQuoteWithFormReset}
              />
            </MAccordionPanel>
          </>
        )}
      </MAccordionItem>
    </MAccordion>
  );
};
