import { formatISO } from 'date-fns/formatISO';
import { isValid } from 'date-fns/isValid';
import { parseISO } from 'date-fns/parseISO';
import { boolean, nativeEnum, number, object, string, z } from 'zod';
import { replaceUserTimezoneWithUtc } from '../utils/dates';

export enum EntityNameEnum {
  INVOICE = 'Invoice',
  PAYMENT = 'Payment',
  CREDIT_NOTE = 'Credit Note',
  CREDIT = 'Credit',
  REFUND = 'Refund',
}
export const EntityNameEnumZ = nativeEnum(EntityNameEnum);

export enum EventTypeEnum {
  CREATE = 'Create',
  UPDATE = 'Update',
}
export const EvenyTypeEnumZ = nativeEnum(EventTypeEnum);

export enum IntegrationLogStatusEnum {
  FAILED = 'Failed',
  SUCCESS = 'Successful',
}
export const IntegrationStatusEnumZ = nativeEnum(IntegrationLogStatusEnum);

export const IntegrationLogRequestSchema = z.object({
  limit: z.number(),
  next: z.string().nullish(),
  entityName: z.string().nullish(),
  eventType: z.string().nullish(),
  status: z.string().nullish(),
  fromLastUpdatedAt: z
    .string()
    .refine((val) => isValid(parseISO(val)))
    .transform((val) => (val ? formatISO(replaceUserTimezoneWithUtc(val)) : ''))
    .nullish(),
  toLastUpdatedAt: z
    .string()
    .refine((val) => isValid(parseISO(val)))
    .transform((val) => (val ? formatISO(replaceUserTimezoneWithUtc(val)) : ''))
    .nullish(),
});
export type TIntegrationLogRequestSchema = z.infer<
  typeof IntegrationLogRequestSchema
>;

export const IntegrationLogResSchema = object({
  id: number(),
  entityName: EntityNameEnumZ,
  sourceId: string(),
  sourceSystemId: string(),
  sourceSystemType: string(),
  targetId: number().nullish(),
  targetSystemType: string(),
  eventType: EvenyTypeEnumZ,
  eventTimestamp: string(),
  lastUpdatedAt: string(),
  createdAt: string(),
  status: IntegrationStatusEnumZ,
  log: string(),
  label: string(),
  isUnknownError: boolean(),
});
export type TIntegrationLogResSchema = z.infer<typeof IntegrationLogResSchema>;

export type IntegrationLogTimeStampFilter = {
  fromLastUpdatedAt?: string;
  toLastUpdatedAt?: string;
};
