import { Switch } from '@chakra-ui/react';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MBox, MFlex } from '../../../../components/Monetize';
import { QuoteBasicContactSelect } from '../../../../components/Quotes/QuoteBasicContactSelect';
import { QuoteBasicInfoLine } from '../../../../components/Quotes/QuoteBasicInfoLine';
import { useIsTenantEsignConfigured } from '../../../../hooks/useIsTenantEsignConfigured';
import {
  ContactTypes,
  IContactRespSchema,
  IQuoteRequestSchema,
  QuoteStatusEnum,
  SigningOrderEnum,
} from '../../../../types';
import { useQuoteContext } from '../../Quote/quoteContext';

interface QuoteBasicContactsProps {}

export const QuoteBasicContacts: FC<QuoteBasicContactsProps> = () => {
  const {
    quoteContacts,
    quoteData: { quote },

    handleSubmitButtonWithoutDirtyCheck,
    isReadOnlyQuote,
  } = useQuoteContext();
  const isTenantEsignConfigured = useIsTenantEsignConfigured();

  const {
    externalContacts,
    internalContacts,
    externalAccountContacts,
    internalAccountContacts,
    handleChangeContact,
    isDisabledEsign,
    isDisabledPrimaryBilling,
  } = quoteContacts;

  const billingContact = externalContacts.find((contact) => contact.billing);
  const primaryContact = externalContacts.find((contact) => contact.primary);
  const esignContact = externalContacts.find((contact) => contact.esign);
  const esignInternalContact = internalContacts.find(
    (contact) => contact.esign,
  );

  const { control, watch } = useFormContext<IQuoteRequestSchema>();
  const requiresEsignWatch = watch('requiresEsign');

  const handleChangeContactAndEvaluate = async (
    contact: IContactRespSchema,
    type: ContactTypes,
    isInternal: boolean,
  ) => {
    handleChangeContact(contact, type, isInternal);
  };

  return (
    <MBox>
      <QuoteBasicInfoLine
        title="Primary Contact"
        showRequired={!primaryContact}
      >
        <MBox mr={isDisabledPrimaryBilling ? 0 : '-2'}>
          <QuoteBasicContactSelect
            value={primaryContact?.contact}
            onChange={(contact) =>
              handleChangeContactAndEvaluate(contact, 'primary', false)
            }
            contacts={externalAccountContacts}
            isDisabled={isDisabledPrimaryBilling}
            isReadOnly={isDisabledPrimaryBilling}
            accountId={quote?.accountId}
          />
        </MBox>
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine
        title="Billing Contact"
        showRequired={!billingContact}
      >
        <MBox mr={isDisabledPrimaryBilling ? 0 : '-2'}>
          <QuoteBasicContactSelect
            value={billingContact?.contact}
            onChange={(contact) =>
              handleChangeContactAndEvaluate(contact, 'billing', false)
            }
            contacts={externalAccountContacts}
            isDisabled={isDisabledPrimaryBilling}
            isReadOnly={isDisabledPrimaryBilling}
            accountId={quote?.accountId}
          />
        </MBox>
      </QuoteBasicInfoLine>

      {isTenantEsignConfigured && (
        <QuoteBasicInfoLine title="eSign">
          <MFlex>
            <Controller
              name="requiresEsign"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <Switch
                  isReadOnly={
                    isReadOnlyQuote || quote?.status !== QuoteStatusEnum.DRAFT
                  }
                  isDisabled={
                    isReadOnlyQuote || quote?.status !== QuoteStatusEnum.DRAFT
                  }
                  isChecked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleSubmitButtonWithoutDirtyCheck();
                  }}
                  {...rest}
                />
              )}
            />
          </MFlex>
        </QuoteBasicInfoLine>
      )}
      {requiresEsignWatch && (
        <>
          {quote?.signingOrder !== SigningOrderEnum.EXTERNAL_ONLY && (
            <QuoteBasicInfoLine
              title="eSign Internal Contact"
              showRequired={!esignContact}
            >
              <MBox mr={isDisabledEsign ? 0 : '-2'}>
                <QuoteBasicContactSelect
                  value={esignInternalContact?.contact}
                  onChange={(contact) =>
                    handleChangeContactAndEvaluate(contact, 'esign', true)
                  }
                  contacts={internalAccountContacts}
                  isDisabled={isDisabledEsign}
                  isReadOnly={isDisabledEsign}
                  accountId={quote?.accountId}
                  defaultValues={{ internal: true, esigner: true }}
                />
              </MBox>
            </QuoteBasicInfoLine>
          )}
          <QuoteBasicInfoLine
            title="eSign Customer Contact"
            showRequired={!esignContact}
          >
            <MBox mr={isDisabledEsign ? 0 : '-2'}>
              <QuoteBasicContactSelect
                value={esignContact?.contact}
                onChange={(contact) =>
                  handleChangeContactAndEvaluate(contact, 'esign', false)
                }
                contacts={externalAccountContacts}
                isDisabled={isDisabledEsign}
                isReadOnly={isDisabledEsign}
                accountId={quote?.accountId}
              />
            </MBox>
          </QuoteBasicInfoLine>
        </>
      )}
    </MBox>
  );
};
