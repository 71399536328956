import { BoxProps, StyleProps } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { PreviewQuotePdfDrawerWrapper } from '../../routes/Quotes/Quote/components/PreviewQuotePdfDrawerWrapper';
import { IQuoteRespSchema } from '../../types';
import { MBox, MButton, MIcon } from '../Monetize';

interface PreviewQuotePdfDrawerV2Props extends BoxProps {
  quote: IQuoteRespSchema;
  iconButtonStyles?: StyleProps;
  renderTrigger?: (data: { handleOpen: () => void }) => React.ReactNode;
}

/**
 * Allow user to click a button to open a drawer and view quote PDF documents
 */
export const PreviewQuotePdfDrawerV2 = ({
  quote,
  iconButtonStyles,
  renderTrigger,
  ...rest
}: PreviewQuotePdfDrawerV2Props) => {
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(true);
  }

  return (
    <MBox>
      <MButton
        ref={buttonRef}
        variant="tertiary"
        size="sm"
        onClick={handleOpen}
      >
        <MIcon mr={2} boxSize={5} as={AiOutlineFilePdf}></MIcon>
        Preview PDF
      </MButton>
      {isOpen && (
        <PreviewQuotePdfDrawerWrapper
          quote={quote}
          onCloseFocusRef={buttonRef}
          onClose={() => setIsOpen(false)}
        />
      )}
    </MBox>
  );
};
