import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import {
  MBox,
  MButton,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MIcon,
  MText,
  MVStack,
} from '../../../components/Monetize';
import { BannerAlertType } from '../../../components/Monetize/MAlertTag';
import { IQuoteValidation, RuleValidationErrorLevelEnum } from '../../../types';

interface ValidationGroup {
  title: string;
  color: string;
  backColor: string;
  alertType: BannerAlertType;
  validations: IQuoteValidation[];
  errorLevel: RuleValidationErrorLevelEnum;
}

const ValidationPopover = ({
  validation,
  onOpen,
}: {
  validation: ValidationGroup;
  onOpen: () => void;
}) => {
  if (validation.validations.length === 0) {
    return null;
  }
  return (
    <MBox
      minW="5"
      minH="5"
      w="5"
      h="5"
      background={validation.color}
      borderRadius="50%"
      mr="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      data-testid="validation-popover-trigger"
      onClick={onOpen}
    >
      <MText color="white" fontSize="xs">
        {validation.validations.length}
      </MText>
    </MBox>
  );
};

interface QuoteAlertsProps {
  validations: IQuoteValidation[];
  showDivider?: boolean;
}

const QuoteAlerts: FC<QuoteAlertsProps> = ({
  validations,
  showDivider = true,
}: QuoteAlertsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {},
  });

  const errorValidations = {
    title: 'Error',
    color: 'tRed.base',
    backColor: 'tRed.paleRed',
    alertType: 'error' as BannerAlertType,
    validations: [] as IQuoteValidation[],
    errorLevel: RuleValidationErrorLevelEnum.ERROR,
  };
  const warnValidations = {
    title: 'Warning',
    color: 'tOrange.tangerine',
    backColor: 'tOrange.input',
    alertType: 'warning' as BannerAlertType,
    validations: [] as IQuoteValidation[],
    errorLevel: RuleValidationErrorLevelEnum.WARN,
  };
  const infoValidations = {
    title: 'Information',
    color: 'tIndigo.base',
    backColor: 'tBlue.hover',
    alertType: 'info' as BannerAlertType,
    validations: [] as IQuoteValidation[],
    errorLevel: RuleValidationErrorLevelEnum.INFO,
  };

  validations.forEach((validation) => {
    if (validation.errorLevel === RuleValidationErrorLevelEnum.ERROR) {
      errorValidations.validations.push(validation);
    } else if (validation.errorLevel === RuleValidationErrorLevelEnum.WARN) {
      warnValidations.validations.push(validation);
    } else if (validation.errorLevel === RuleValidationErrorLevelEnum.INFO) {
      infoValidations.validations.push(validation);
    }
  });

  const onCloseDrawer = () => {
    onClose();
  };

  if (!validations || validations.length === 0) {
    return null;
  }

  return (
    <MFlex>
      <MText
        whiteSpace="nowrap"
        mr="1.5"
        color={
          errorValidations.validations.length > 0 ? 'tRed.base' : 'tPurple.base'
        }
      >
        {errorValidations.validations.length > 0
          ? 'Action Required'
          : 'Quote Alerts'}
      </MText>

      <ValidationPopover validation={errorValidations} onOpen={onOpen} />
      <ValidationPopover validation={warnValidations} onOpen={onOpen} />
      <ValidationPopover validation={infoValidations} onOpen={onOpen} />

      {showDivider && (
        <MDivider
          borderColor="tPurple.base"
          height="5"
          w="1"
          mx="2"
          orientation="vertical"
        />
      )}

      <MDrawer
        isOpen={isOpen}
        placement="right"
        onClose={onCloseDrawer}
        size="sm"
        blockScrollOnMount={false}
      >
        <MDrawerOverlay />
        <MDrawerContent>
          <MDrawerCloseButton color="tPurple.base" mt={-0.5} />
          <MDrawerHeader py="2.5" pl={4} mr={7}>
            <MFlex justifyContent="space-between" alignItems="center" minH={8}>
              <MText fontSize="inherit" fontWeight="inherit">
                Quote Alerts ({validations.length})
              </MText>
            </MFlex>
          </MDrawerHeader>
          <MDrawerBody px="4" bgColor="tGray.sidebarDark">
            <MBox w="full">
              {[errorValidations, warnValidations, infoValidations].map(
                (validationGroup, index) => (
                  <MVStack key={`validationGroup-${index}`} w="full">
                    {validationGroup.validations.map((validation, valIndex) => (
                      <MBox
                        w="full"
                        p={4}
                        borderRadius="3px"
                        key={valIndex}
                        bg="white"
                        display="flex"
                        mb="2"
                      >
                        <MIcon
                          width={4}
                          height={4}
                          as={BsExclamationCircleFill}
                          color={validationGroup.color}
                        />
                        <MBox ml="4">
                          <MText
                            color="tGray.acBlack"
                            fontWeight="bold"
                            lineHeight="1"
                            mb="1"
                          >
                            {validationGroup.title}
                          </MText>
                          <MText color="tGray.acBlack" fontSize="xs">
                            {validation.message}
                          </MText>
                        </MBox>
                      </MBox>
                    ))}
                  </MVStack>
                ),
              )}
            </MBox>
          </MDrawerBody>

          <MDivider />

          <MDrawerFooter>
            <MBox w="full">
              <MFlex justifyContent="center">
                <MButton
                  mt={5}
                  variant="cancel"
                  justifySelf="center"
                  onClick={onCloseDrawer}
                >
                  Close
                </MButton>
              </MFlex>
            </MBox>
          </MDrawerFooter>
        </MDrawerContent>
      </MDrawer>
    </MFlex>
  );
};

export { QuoteAlerts };
