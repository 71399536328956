import { FunctionComponent as FC, useEffect, useState } from 'react';
import {
  MAccordion,
  MFlex,
  MHeading,
} from '../../../../../components/Monetize';
import {
  IGetSubscriptionItemSchema,
  ISubscriptionScheduledChange,
} from '../../../../../types';
import { ScheduledChangeItem } from './ScheduledChangeItem';

interface ScheduledChangesProps {
  scheduledChanges: ISubscriptionScheduledChange[];
  subscriptionItems?: IGetSubscriptionItemSchema[];
}
const sortByDate = (
  scheduledChanges: ISubscriptionScheduledChange[],
): ISubscriptionScheduledChange[] => {
  return scheduledChanges.sort((change1, change2) => {
    return change1.effectiveDate > change2.effectiveDate ? 1 : -1;
  });
};

export const ScheduledChanges: FC<ScheduledChangesProps> = ({
  scheduledChanges,
  subscriptionItems,
}: ScheduledChangesProps) => {
  const [sortedScheduledChanges, setSortedScheduledChanges] = useState<
    ISubscriptionScheduledChange[]
  >([]);
  useEffect(() => {
    setSortedScheduledChanges(sortByDate(scheduledChanges));
  }, [scheduledChanges]);
  const orignalSubscriptionItems = (subscriptionItems || []).map((item) => ({
    units: item.units,
    productId: item.product?.id,
  }));

  return (
    <>
      <MFlex flexDir="column" alignItems="start" mt={4} mb={4}>
        <MHeading fontSize="18">Scheduled Changes</MHeading>
      </MFlex>

      <MAccordion defaultIndex={[0]} allowMultiple>
        {sortedScheduledChanges.map((item, index) => (
          <ScheduledChangeItem
            previousChange={
              index === 0
                ? orignalSubscriptionItems
                : sortedScheduledChanges[index - 1].itemChanges
            }
            item={item}
            key={index}
          />
        ))}
      </MAccordion>
    </>
  );
};
