import { useDisclosure } from '@chakra-ui/react';
import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useGetContracts } from '../../api/cpqService';
import { ContractRenewalIndicator } from '../../components/Contracts/ContractRenewalIndicator';
import {
  MDataTable,
  MFlex,
  MHStack,
  MIcon,
  MPageContentContainer,
  MPageHeader,
  MPageLoader,
  MTag,
  MText,
  MTooltip,
} from '../../components/Monetize';
import {
  AccountIdTableFilterOptionContent,
  AmountTableFilterOptionContent,
  DateGlteTableFilterOptionContent,
  MDataTableFilter,
} from '../../components/Monetize/DataTable';
import { BillGroupIdTableFilterOptionContent } from '../../components/Monetize/DataTable/FilterOptions/BillGroupIdTableFilterContent';
import { CurrencyTableFilterOptionContent } from '../../components/Monetize/DataTable/FilterOptions/CurrencyTableFilterOptionContent';
import { OwnerTableFilterOptionContent } from '../../components/Monetize/DataTable/FilterOptions/OwnerTableFilterOptionContent';
import { ExportTableButton } from '../../components/Monetize/ExportEntityButton';
import { RenewalModal } from '../../components/RenewalModal/RenewalModal';
import { CONTRACTS, ROUTES } from '../../constants';
import { getAccountDetailRoute } from '../../constants/routes';
import { IContractWithBillGroup } from '../../hooks/AccountDetail';
import { useDocumentHead } from '../../services/documentHead';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '../../store/global.store';
import {
  FilterStateKeys,
  getDefaultFilter,
  getDefaultPager,
} from '../../store/store.types';
import {
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  RenderTableFilterOptionProps,
} from '../../types';
import {
  ContractEndActionEnum,
  ContractStatusEnum,
  IContract,
} from '../../types/contractTypes';
import {
  buildFilterParamsRequestObject,
  getFiltersApplied,
  getIsTrulyEmptyList,
} from '../../utils';
import {
  currencyBodyTemplate,
  dateBodyTemplate,
  getFiltersFromState,
  headerAndIdTemplate,
  idWithExtraBodyTemplate,
  statusBodyTemplate,
} from '../../utils/tableUtils';
import ContractActions from '../Quotes/Quote/ContractActions';

const ACCOUNT_FILTER_OPTION = {
  title: 'Account',
  key: 'accountId',
  operator: FilterTypeOperator.EQUAL,
  renderOptionContent: (props: RenderTableFilterOptionProps) => (
    <AccountIdTableFilterOptionContent {...props} />
  ),
};

const AMOUNT_FILTER_OPTION = {
  title: 'Amount',
  key: 'totalValue',
  operator: FilterTypeOperator.GLTE,
  renderOptionContent: (props: RenderTableFilterOptionProps) => (
    <AmountTableFilterOptionContent {...props} />
  ),
};

const OWNER_FILTER_OPTION = {
  title: 'Owner',
  key: 'owner',
  operator: FilterTypeOperator.EQUAL,
  renderOptionContent: OwnerTableFilterOptionContent,
};

const CURRENCY_FILTER_OPTION = {
  title: 'Currency',
  key: 'currency',
  operator: FilterTypeOperator.EQUAL,
  renderOptionContent: (optionsProps: RenderTableFilterOptionProps) => (
    <CurrencyTableFilterOptionContent {...optionsProps} />
  ),
};

const BILL_GROUP_ID_FILTER_OPTION = {
  title: 'Bill Group',
  key: 'billGroupId',
  operator: FilterTypeOperator.EQUAL,
  renderOptionContent: (props: RenderTableFilterOptionProps) => (
    <BillGroupIdTableFilterOptionContent {...props} />
  ),
};

const START_DATE_FILTER_OPTION = {
  title: 'Start Date',
  key: 'startDate',
  operator: FilterTypeOperator.GLTE,
  renderOptionContent: (optionsProps: RenderTableFilterOptionProps) => (
    <DateGlteTableFilterOptionContent {...optionsProps} />
  ),
};

export const CONTRACT_FILTER_OPTIONS = [
  ACCOUNT_FILTER_OPTION,
  AMOUNT_FILTER_OPTION,
  CURRENCY_FILTER_OPTION,
  CONTRACTS.CONTRACT_END_ACTION_FILTER_OPTION,
  OWNER_FILTER_OPTION,
  START_DATE_FILTER_OPTION,
  CONTRACTS.CONTRACT_STATUS_FILTER_OPTION,
];

export const CONTRACT_FILTER_OPTIONS_FOR_ACCOUNT_DETAIL = [
  AMOUNT_FILTER_OPTION,
  CONTRACTS.CONTRACT_END_ACTION_FILTER_OPTION,
  CONTRACTS.CONTRACT_STATUS_FILTER_OPTION,
];

type FilterKey =
  | FilterStateKeys.CONTRACT_LIST
  | FilterStateKeys.CONTRACT_DASHBOARD_LIST;

export const useContractsTableData = (
  filterKey: FilterKey = FilterStateKeys.CONTRACT_LIST,
) => {
  const navigate = useNavigate();
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(filterKey),
  );
  const { state: locationState } = useLocation();

  const [pager, setPager] = useRecoilState(tablePagerSelector(filterKey));
  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [searchKey] = useState<string>('description');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Contracts');
  }, []);

  useEffect(() => {
    setFilters(
      getFiltersFromState(
        (locationState || {}) as Record<string, any>,
        filters,
        CONTRACT_FILTER_OPTIONS,
      ),
    );
  }, []);

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, searchTerm, searchKey),
    );
  }, [pager, searchTerm, filters, searchKey]);

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const [selectedContract, setSelectedContract] =
    useState<IContractWithBillGroup | null>(null);

  const {
    isOpen: isOpenRenewalModal,
    onOpen: onOpenRenewalModal,
    onClose: onCloseRenewalModal,
  } = useDisclosure();

  const {
    isLoading,
    isRefetching,
    isFetched,
    isError,
    data: contractList,
  } = useGetContracts(
    {
      config: pager,
      filters: filterParams,
    },
    {
      meta: { showErrorToast: true },
    },
  );

  useEffect(() => {
    if (isError) {
      setPager(getDefaultPager(filterKey));
      setFilters(getDefaultFilter(filterKey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey, isError]);

  const handleOnRowClick = (e: DataTableRowClickEvent) => {
    handleEditContract(e.data?.id);
  };

  const handleEarlyRenewal = (data: IContractWithBillGroup) => {
    setSelectedContract(data);
    onOpenRenewalModal();
  };

  const actionBodyTemplate = (data: IContract) => (
    <ContractActions contract={data} handleEarlyRenewal={handleEarlyRenewal} />
  );

  const onResetFilter = () => {
    setFilters([]);
  };

  const handleEditContract = (contractId: string) => {
    navigate(ROUTES.getSalesContractEditRoute(contractId));
  };
  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'id',
      header: 'ID',
      body: idWithExtraBodyTemplate<IContract>(
        'id',
        (contract) => <ContractRenewalIndicator contract={contract} />,
        {
          extraProps: {
            justifyContent: 'space-between',
          },
        },
      ),
      style: { width: '3rem' },
    },
    {
      field: 'accountName',
      sortField: 'account.accountName',
      header: 'Account',
      body: headerAndIdTemplate<IContract>('accountName', 'accountId', {
        idLinkFn: (id) => getAccountDetailRoute(id),
      }),
      className: 'table-cell-lg',
      sortable: true,
    },
    {
      field: 'startDate',
      header: 'Start Date',
      body: dateBodyTemplate<IContract>('startDate'),
      sortable: true,
    },
    {
      field: 'endDate',
      header: 'End Date',
      body: dateBodyTemplate<IContract>('endDate'),
      sortable: true,
    },
    {
      field: 'endAction',
      header: 'End Action',
      body: statusBodyTemplate<IContract, ContractEndActionEnum>(
        'endAction',
        CONTRACTS.CONTRACT_END_ACTION_UI_DISPLAY,
      ),
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      className: 'table-cell-sm',
      body: (data: IContractWithBillGroup) => {
        if (data.renewed) {
          return <MTag variant="purple">Renewed</MTag>;
        } else if (data.status === ContractStatusEnum.PENDING) {
          return (
            <MTooltip
              label={
                'Your contract is pending because the first invoice of the renewal contract has not yet been generated, or invoice generation is set to manual. Progress invoicing if you want to move the contract to an active status.'
              }
            >
              <MFlex alignItems="center">
                <MText
                  noOfLines={1}
                  maxW="fit-content"
                  h="18px"
                  mr={1}
                  lineHeight="18px"
                >
                  Pending
                </MText>
                <MIcon as={MdInfo} />
              </MFlex>
            </MTooltip>
          );
        } else {
          return statusBodyTemplate<IContractWithBillGroup, ContractStatusEnum>(
            'status',
            CONTRACTS.CONTRACT_STATUS_DISPLAY,
          )(data);
        }
      },
    },
    {
      field: 'totalValue',
      header: 'Total',
      sortable: true,
      body: currencyBodyTemplate<IContract>('totalValue', 'currency'),
      style: { width: '10.68rem', textAlign: 'right' },
    },
    {
      field: 'action',
      header: '',
      sortable: false,
      body: actionBodyTemplate,
      style: { width: '2rem' },
    },
  ];

  return {
    contractList: contractList,
    loading: isLoading || (isRefetching && !isFetched),
    searchKey,
    searchTerm,
    setSearchTerm,
    pager,
    setPager,
    statusBodyTemplate,
    actionBodyTemplate,
    handleEditContract,
    filters,
    setFilters,
    onResetFilter,
    handleOnRowClick,
    columns,
    selectedContract,
    isOpenRenewalModal,
    onCloseRenewalModal,
  };
};

export const ContractListMain = () => {
  const {
    contractList,
    loading,
    searchTerm,
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    handleOnRowClick,
    columns,
    selectedContract,
    isOpenRenewalModal,
    onCloseRenewalModal,
  } = useContractsTableData();

  const filterComponentReset = React.useRef<any>(null);

  if (loading || !contractList) {
    return <MPageLoader />;
  }

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: contractList.totalElements,
    filters,
    searchTerm,
    page: pager.page,
  });

  return (
    <MPageContentContainer>
      <MPageHeader size="md" title="Contracts">
        {!isTrulyEmptyList && (
          <MFlex>
            <MHStack spacing="2">
              <MDataTableFilter
                filters={filters}
                filterOptions={CONTRACT_FILTER_OPTIONS}
                setFilters={setFilters}
                onResetFilter={onResetFilter}
                resetFilter={filterComponentReset}
              />
              <ExportTableButton
                entity="contracts"
                filters={filters}
                sortField={pager.sortField}
                sortOrder={pager.sortOrder}
              />
            </MHStack>
          </MFlex>
        )}
      </MPageHeader>

      <MDataTable
        value={contractList.content}
        totalRecords={contractList.totalElements}
        totalPages={contractList?.totalPages}
        pager={pager}
        setPager={setPager}
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no contracts here.',
          btnLabel: 'View Quotes',
          to: ROUTES.SALES_QUOTES_ROUTE,
        }}
        loading={loading}
        columns={columns}
        onRowClick={handleOnRowClick}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
        }}
      />

      {selectedContract && isOpenRenewalModal && (
        <RenewalModal
          contract={selectedContract}
          onClose={onCloseRenewalModal}
        />
      )}
      <Outlet />
    </MPageContentContainer>
  );
};
