import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MPageContainer } from '../../../components/Monetize';
import { TenantForm } from '../../../components/Tenants/TenantForm';
import { ROUTES } from '../../../constants';
import { useAuth } from '../../../services/auth0';
import { ITenant } from '../../../types';

export const CreateTenant = () => {
  const { tenantId } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (tenantId) {
      navigate(ROUTES.getSwitchTenantRoute(tenantId));
    }
  }, [tenantId]);

  const onAfterSave = (data: ITenant) => {
    navigate(ROUTES.getSwitchTenantRoute(data.id));
  };

  return (
    <MPageContainer bg="black">
      <TenantForm
        isOpen
        showCloseButton={false}
        onSaved={onAfterSave}
        showLogoutBtn
      />
    </MPageContainer>
  );
};
