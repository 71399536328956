import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import { logger } from '../../../../../services/logger';
import {
  IQuoteRespSchema,
  QuoteItemAmendmentStatusEnum,
  QuoteOfferingProps,
  UseQuoteOfferingAmendV2Return,
} from '../../../../../types';
import {
  getQuoteOfferingsFromParentOfferings,
  getUpdatedQuoteOfferingGroup,
  isQuoteOfferingAddedToAmendment,
} from '../../../../../utils/quotes';
import { useQuoteContext } from '../../quoteContext';
import { isQuoteOfferingChanged } from './quoteOfferingUtils';

export interface UseQuoteOfferingAmendV2Props
  extends Pick<
    QuoteOfferingProps,
    | 'quoteOffering'
    | 'isChildOffering'
    | 'childQuoteOfferings'
    | 'onChange'
    | 'parentQuoteOffering'
    | 'index'
    | 'nextScheduledQuoteOffering'
    | 'quoteOfferings'
  > {
  revertQuoteOffering: (id: string) => Promise<IQuoteRespSchema | null>;
  isRemoved: boolean;
}

export const useQuoteOfferingAmendV2 = ({
  quoteOffering,
  isChildOffering,
  childQuoteOfferings = [],
  revertQuoteOffering,
  onChange: onOfferingChange,
  parentQuoteOffering,
  index,
  nextScheduledQuoteOffering,
  isRemoved,
  quoteOfferings,
}: UseQuoteOfferingAmendV2Props): UseQuoteOfferingAmendV2Return => {
  const {
    productOfferingsData,
    quoteData: {
      quote,
      quoteDataOnInitialLoad,
      updateInitialQuoteData,
      setQuote,
    },
    quoteStateData,
    isLoading,
    isReadOnly,
    useAmendmentV2,
  } = useQuoteContext();

  const { offeringLoadingState, setOfferingLoadingState } = quoteStateData;

  const isQOUpdated =
    !!quoteOffering &&
    isQuoteOfferingChanged(quoteOffering, quoteDataOnInitialLoad);
  const isQOAddedToAmendment =
    !!quoteOffering && isQuoteOfferingAddedToAmendment(quoteOffering);

  const isEndingBeforeContractEnd =
    useAmendmentV2 &&
    !isRemoved &&
    (isChildOffering
      ? !nextScheduledQuoteOffering
      : childQuoteOfferings.length === 0) &&
    !!quoteOffering?.endDate &&
    !!quote?.contractEndDate &&
    isBefore(parseISO(quoteOffering?.endDate), parseISO(quote.contractEndDate));

  const offeringEndDate =
    !isRemoved && childQuoteOfferings.length > 0
      ? childQuoteOfferings[childQuoteOfferings.length - 1].endDate
      : quoteOffering?.endDate!;

  const isOfferingEndBeforeContract =
    useAmendmentV2 &&
    !isRemoved &&
    !!quoteOffering?.endDate &&
    !!quote?.contractEndDate &&
    isBefore(parseISO(offeringEndDate), parseISO(quote.contractEndDate));

  // Before the user makes an actual change, the BE flags as NO_CHANGE after adding new segment until a change is made
  const newlyAddedOffering =
    quoteOffering?.id &&
    !quoteDataOnInitialLoad.quoteOfferings[quoteOffering.id];

  const showRevertToOriginal =
    newlyAddedOffering ||
    isQOUpdated ||
    !!childQuoteOfferings?.some((childQuoteOffering) =>
      isQuoteOfferingChanged(childQuoteOffering, quoteDataOnInitialLoad),
    );

  const isAmendmentStatusOverwritten = quoteOffering?.items.some(
    (item) => !!item.amendmentStatusOverwrite,
  );

  const getLockedStatus = () => {
    // Calculate lock status only when it's amend v2
    if (!useAmendmentV2) {
      return false;
    }

    // If offering is added or updated, then it's unlocked
    if (newlyAddedOffering || isQOUpdated) {
      return false;
    }

    if (isAmendmentStatusOverwritten) {
      return !!quoteOffering?.items.some(
        (item) =>
          item.amendmentStatusOverwrite ===
          QuoteItemAmendmentStatusEnum.NO_CHANGE,
      );
    }
    // For parent offering, if quote offering is updated, it's unlocked
    if (!isChildOffering) {
      return !isQOUpdated;
    }

    // For child offering, if parent is updated, the it's unlocked
    if (
      parentQuoteOffering &&
      isQuoteOfferingChanged(parentQuoteOffering, quoteDataOnInitialLoad)
    ) {
      return false;
    }

    // Find first updated child offering and if current one is after the first updated one, then it's unlocked
    const firstModifiedChildOfferingIndex = childQuoteOfferings.findIndex(
      (childQO) => isQuoteOfferingChanged(childQO, quoteDataOnInitialLoad),
    );

    const currentIndex = childQuoteOfferings.findIndex(
      (childQO) => childQO.id === quoteOffering?.id,
    );

    if (firstModifiedChildOfferingIndex !== -1) {
      return !(firstModifiedChildOfferingIndex <= currentIndex);
    }

    return true;
  };

  const handleRevertOriginal = async () => {
    try {
      if (quoteOffering?.id) {
        setOfferingLoadingState((prevValue) => ({
          ...prevValue,
          [quoteOffering.id]: {
            id: quoteOffering.id,
            isLoading: true,
            isDeleting: true,
          },
        }));

        const newQuote = await revertQuoteOffering(quoteOffering.id);

        if (newQuote) {
          // Reverting produces a new quote offering, so we need to store the initial state to know it should be considered "original"
          updateInitialQuoteData('MERGE', newQuote);

          // Update quote offering group that was reverted and keep all other offerings from FE state
          setQuote({
            ...newQuote,
            quoteOfferings: getUpdatedQuoteOfferingGroup(
              getQuoteOfferingsFromParentOfferings(quoteOfferings || []),
              newQuote.quoteOfferings,
              quoteOffering,
            ),
          });
        }
        setOfferingLoadingState((prevValue) => {
          const value = { ...prevValue };
          delete value[quoteOffering.id];
          return value;
        });
      }
    } catch (error) {
      logger.error('Error while reverting quote offering ====>', error);
    }
  };

  const handleUnlock = async () => {
    if (quoteOffering) {
      const newOfferingResponse = {
        ...quoteOffering,
        items: quoteOffering?.items.map((item) => ({
          ...item,
          amendmentStatus: QuoteItemAmendmentStatusEnum.UPDATED,
          amendmentStatusOverwrite: undefined,
        })),
      };
      // update stored data so that if quote is updated we don't get our current state wiped out
      quoteDataOnInitialLoad.quoteOfferings[quoteOffering.id] =
        newOfferingResponse;
      newOfferingResponse.items.forEach((item) => {
        quoteDataOnInitialLoad.quoteItems[item.id] = item;
      });
      await onOfferingChange('UPDATE', newOfferingResponse, undefined, true);
    }
  };

  return {
    isQOUpdated,
    isQOAddedToAmendment,
    isAmendV2Locked: getLockedStatus(),
    showRevertToOriginal,
    handleRevertOriginal,
    handleUnlock,
    isEndingBeforeContractEnd,
    offeringEndDate,
    isOfferingEndBeforeContract,
  };
};
