import { FC } from 'react';
import {
  CustomSelectItem,
  CustomSelectStateProps,
} from '../../../../types/mCustomSelectTypes';
import MText from '../../MText';
import { MCustomSelectItem } from './MCustomSelectItem';

interface CustomItemListProps
  extends Pick<
    CustomSelectStateProps,
    'isSubtitleItem' | 'isDisabledItem' | 'onClose' | 'itemRefs' | 'getTitle'
  > {
  customItems: CustomSelectItem[];
  cursor: number;
}

const CustomItemList: FC<CustomItemListProps> = ({
  customItems,
  cursor,
  isSubtitleItem,
  isDisabledItem,
  onClose,
  itemRefs,
  getTitle,
}: CustomItemListProps) => {
  const elements = customItems.map(
    (customItem: CustomSelectItem, index: number) => {
      const { item, isAction, hasDivider, isHeading, onClick } = customItem;
      const isSelected = false;
      const isSubtitle = isSubtitleItem(item);
      const isDisabled = isDisabledItem(item);
      const isHighlight = cursor === index;

      const getFontWeight = () => {
        if (isAction) {
          return '600';
        }

        return '400';
      };

      const getColor = () => {
        if (isAction) {
          return 'tIndigo.base';
        }

        if (isHeading) {
          return 'tGray.darkPurple';
        }

        return 'tPurple.base';
      };

      return (
        <MCustomSelectItem
          key={index}
          ref={(element: HTMLDivElement) => (itemRefs.current[index] = element)}
          isSubtitle={isSubtitle}
          onClick={() => {
            onClick && onClick({ onClose });
          }}
          isHighlight={isHighlight}
          rightLabel={item.rightLabel}
          isSelected={isSelected}
          isDisabled={isDisabled}
          _hover={isSubtitle ? undefined : { background: 'tGray.support' }}
          cursor={isHeading ? 'initial' : 'pointer'}
          hasDivider={hasDivider}
          color={getColor()}
        >
          {customItem.render ? (
            customItem.render(customItem)
          ) : (
            <MText
              color={getColor()}
              fontWeight={getFontWeight()}
              isTruncated
              noOfLines={1}
              fontSize={isSubtitle ? 'xs' : 'sm'}
              ml={1.5}
            >
              {getTitle(item)}
            </MText>
          )}
        </MCustomSelectItem>
      );
    },
  );
  return <>{elements}</>;
};

export default CustomItemList;
