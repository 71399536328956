import difference from 'lodash/difference';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  FilterOptionType,
  FilterType,
  FilterTypeContains,
  FilterTypeOperator,
  Maybe,
} from '../../../types';
import { getFiltersApplied } from '../../../utils';
import { MBox, MInput } from '../chakra';
import {
  MFilterCard,
  MFilterCardAccordionItem,
  MFilterCardCheckGroup,
} from './..';

interface DataTableFilterProps {
  filters?: FilterType[] | null;
  filterOptions: FilterOptionType[];
  setFilters?: (filters: FilterType[]) => void;
  resetFilter?: any;
  onResetFilter?: () => void;
}

const amountFilterKeys = new Set(['totalAmount', 'amount', 'totalValue']);

const isFilterApplied = (internalFilter: FilterType) => {
  if (!internalFilter?.value) {
    return false;
  }

  const isStringWithValue =
    isString(internalFilter.value) && !isEmpty(internalFilter.value.trim());

  const isBooleanWithValue =
    isBoolean(internalFilter.value) && internalFilter.value;

  const isArrayWithValues =
    isArray(internalFilter.value) && internalFilter.value.length > 0;

  const isObjectWithValues =
    isObject(internalFilter.value) &&
    Object.values(internalFilter.value).some((value) => !isEmpty(value));

  return (
    isStringWithValue ||
    isBooleanWithValue ||
    isArrayWithValues ||
    isObjectWithValues
  );
};

const DataTableFilter = ({
  filters,
  filterOptions,
  setFilters,
  resetFilter,
  onResetFilter,
}: DataTableFilterProps) => {
  const [internalFilters, setInternalFilters] = useState<FilterType[]>(
    filters || [],
  );

  /**
   * If a filter is closed without being applied, the internal filters are reset
   * Otherwise apply and close
   */
  const handleFilterClose = (applyFilters: boolean) => {
    if (applyFilters) {
      setFilters && setFilters(internalFilters);
    } else {
      setInternalFilters(filters || []);
    }
  };

  const reset = () => {
    setInternalFilters([]);
    onResetFilter && onResetFilter(); // notifies parents after reset
  };

  useEffect(() => {
    if (resetFilter) {
      const ref = resetFilter;
      ref.current = reset;
    }
  }, []);

  useEffect(() => {
    if (filters?.length) {
      setInternalFilters(filters);
    }
  }, [filters]);

  const getFilter = (array: FilterType[], key: string) => {
    const [filter] = array.filter((a) => a.key === key);
    return filter;
  };

  const handleFilterChange = (
    val: FilterType['value'],
    filterObject: FilterOptionType,
  ) => {
    const { key, operator } = filterObject;

    const updatedFilters = internalFilters.filter((f) => f.key !== key);
    updatedFilters.push({
      key,
      value: val,
      operator,
      options: filterObject.options,
    } as FilterType);
    setInternalFilters(updatedFilters);
  };

  const handleClearFilter = (key: string) => {
    const updatedFilters = internalFilters.filter((f) => f.key !== key);
    setInternalFilters(updatedFilters);

    const changesInFilters = difference(filters, updatedFilters);

    if (changesInFilters.length > 0 && setFilters) {
      setFilters(updatedFilters);
    }
  };

  const renderFilterOption = (option: FilterOptionType, index: number) => {
    const { key, title, items } = option;
    const uniqueKey = `${key}-${index}`;
    // used for filtering Quotes by amount ($)
    const isAmountFilter = amountFilterKeys.has(key);
    const internalFilter = getFilter(internalFilters, key);

    let content: Maybe<ReactNode> = null;
    if (option.renderOptionContent) {
      content = option.renderOptionContent({
        filter: internalFilter,
        filterOption: option,
        handleFilterChange,
      });
    } else if (option.operator === FilterTypeOperator.CONTAINS) {
      content = (
        <MBox>
          <MInput
            value={(internalFilter as FilterTypeContains)?.value || ''}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              handleFilterChange(ev.target.value, option);
            }}
          />
        </MBox>
      );
    } else if (option.items) {
      content = (
        <MBox pl="2">
          <MFilterCardCheckGroup
            items={items}
            value={internalFilter?.value}
            isRadio={isAmountFilter}
            returnObject={isAmountFilter}
            objectId={isAmountFilter ? 'id' : 'label'}
            onChange={(val) => handleFilterChange(val, option)}
          />
        </MBox>
      );
    }

    // If it's without title, then render without accordion
    if (!title) {
      return <MBox key={uniqueKey}>{content}</MBox>;
    }

    return (
      <MFilterCardAccordionItem
        key={uniqueKey}
        title={title}
        filterApplied={isFilterApplied(internalFilter)}
        handleClearFilter={() => handleClearFilter(key)}
      >
        <MBox>{content}</MBox>
      </MFilterCardAccordionItem>
    );
  };

  return (
    <MFilterCard
      onClose={handleFilterClose}
      onResetFilter={() => {
        onResetFilter && onResetFilter();
        setInternalFilters([]);
      }}
      filtersApplied={getFiltersApplied(internalFilters || [])}
      isInternalFilterUnchanged={isEqual(filters, internalFilters)}
      popoverProps={{
        closeOnBlur: false,
      }}
    >
      {filterOptions.map(renderFilterOption)}
    </MFilterCard>
  );
};

export default DataTableFilter;
