import {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Wrap,
} from '@chakra-ui/react';

import React, { useMemo } from 'react';
import { MdArrowDropDown, MdClose } from 'react-icons/md';
import { CustomSelectStateProps } from '../../../../types/mCustomSelectTypes';
import MCustomIconButton from '../../MCustomIconButton';
import { useCustomSelectInput } from './useCustomSelectInput';

export interface MainInputProps
  extends Pick<
    CustomSelectStateProps,
    | 'getTitleFromValue'
    | 'disabled'
    | 'isReadOnly'
    | 'onOpen'
    | 'onClose'
    | 'loading'
    | 'loadingRenderer'
    | 'value'
    | 'onToggleOpen'
    | 'externalLoading'
    | 'placeholder'
    | 'isOpen'
    | 'items'
    | 'cursor'
    | 'setCursor'
    | 'handleChange'
    | 'showContentInInput'
    | 'renderContentInInput'
    | 'inputProps'
    | 'renderRightElement'
    | 'clearable'
    | 'onClear'
    | 'onChange'
    | 'queryResultCount'
    | 'query'
    | 'onQueryKeyDown'
    | 'onQueryChange'
    | 'displayAvatar'
    | 'renderInputLeftElement'
    | 'inputLeftElementContent'
    | 'isSubtitleItem'
    | 'isTruncatedInputTitle'
    | 'showQueryInput'
    | 'useMainInputAsSearchInput'
    | 'containerId'
  > {
  ref: React.LegacyRef<HTMLInputElement>;
}

export const MainInput = React.forwardRef<HTMLInputElement, MainInputProps>(
  (props, ref) => {
    const {
      clearable,
      disabled,
      externalLoading,
      inputLeftElementContent,
      inputProps,
      isOpen,
      isReadOnly,
      loading,
      loadingRenderer,
      onOpen,
      placeholder,
      renderContentInInput,
      showContentInInput,
      value,
      getTitleFromValue,
    } = props;

    const {
      bgColor,
      borderColor,
      handleClickInput,
      handleFocusInput,
      handleOnClear,
      handleToggleOpen,
      inputValue,
      rightElement,
      rightMargin,
      styles,
    } = useCustomSelectInput(props, ref);

    const tooltipMessage = useMemo(() => {
      if (Array.isArray(value) && value.length > 1) {
        return value.map(getTitleFromValue).join(', ');
      }
      return '';
    }, [value]);

    if (!showContentInInput) {
      return (
        <InputGroup onClick={handleToggleOpen}>
          {inputLeftElementContent && (
            <InputLeftElement>{inputLeftElementContent}</InputLeftElement>
          )}
          <Input
            type="text"
            ref={ref}
            placeholder={placeholder}
            readOnly
            value={inputValue}
            isDisabled={disabled}
            isReadOnly={isReadOnly}
            variant={isReadOnly ? 'readonly' : 'primary'}
            bg={bgColor}
            color={isOpen ? 'tIndigo.base' : 'tPurple.dark'}
            tabIndex={isOpen ? -1 : 0}
            pr={rightMargin}
            _placeholder={{
              color: 'tGray.lightPurple',
            }}
            title={tooltipMessage || inputValue}
            {...inputProps}
          />

          {!isReadOnly && (
            <InputRightElement width={rightMargin}>
              {rightElement?.content}
              {clearable && value && !disabled && (
                <MCustomIconButton
                  icon={MdClose}
                  bg="none"
                  color="tPurple.dark"
                  _hover={{
                    bg: 'none',
                  }}
                  ml="2"
                  btnSize={4}
                  padding={0}
                  containerSize={4}
                  onClick={handleOnClear}
                />
              )}
              {loading || externalLoading ? (
                <>{loadingRenderer ? loadingRenderer : <Spinner size="xs" />}</>
              ) : (
                <Button
                  variant="icon"
                  padding="0"
                  minWidth="initial"
                  _hover={{ backgroundColor: 'none' }}
                  _focus={{ background: 'none', boxShadow: 'none' }}
                  tabIndex={-1}
                >
                  <Icon
                    as={MdArrowDropDown}
                    fontSize="20"
                    zIndex="1"
                    color={disabled ? 'tGray.acGray' : 'tPurple.dark'}
                    opacity={disabled ? 0.25 : 'unset'}
                  />
                </Button>
              )}
            </InputRightElement>
          )}
        </InputGroup>
      );
    }

    return (
      <InputGroup
        __css={(styles as any).field}
        bg={bgColor}
        borderColor={borderColor}
        display="flex"
        flexWrap="wrap"
        position="relative"
        alignItems="center"
      >
        <Wrap spacing={0.5}>
          {showContentInInput &&
            renderContentInInput &&
            renderContentInInput({ onToggleOpen: handleToggleOpen })}
          <Flex alignItems="center" flex="1" display="grid">
            <Input
              type="text"
              ref={ref}
              placeholder={placeholder}
              onChange={() => {}}
              value={!showContentInInput ? inputValue : ''}
              onFocus={handleFocusInput}
              isDisabled={disabled}
              isReadOnly={isReadOnly}
              tabIndex={isOpen ? -1 : 0}
              border="none"
              _focus={{ border: 'none' }}
              width="auto"
              bg="none"
              _placeholder={{
                color: 'tGray.darkGrayPurple',
              }}
              title={tooltipMessage || inputValue}
              {...inputProps}
            />
          </Flex>
        </Wrap>
        <Box
          onClick={handleToggleOpen}
          position="absolute"
          right="0"
          display="flex"
          alignItems="center"
        >
          {loading || externalLoading ? (
            <>{loadingRenderer ? loadingRenderer : <Spinner size="xs" />}</>
          ) : (
            <Button
              variant="icon"
              padding="0"
              minWidth="initial"
              _hover={{ backgroundColor: 'none' }}
              _focus={{ background: 'none', boxShadow: 'none' }}
              tabIndex={-1}
            >
              <Icon as={MdArrowDropDown} fontSize="20" zIndex="1" />
            </Button>
          )}
        </Box>
      </InputGroup>
    );
  },
);
