import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  MCurrencySelect,
  MCustomNumberInput,
  MHStack,
  MInput,
  MText,
  MVStack,
} from '../../../../../../components/Monetize';
import { DatePicker } from '../../../../../../components/Monetize/DatePicker/DatePicker';
import BillingFrequencyDropdownSelect from '../../../../../../components/Monetize/Dropdowns/BillingFrequencyDropdownSelect';
import ProductDropdownMultiSelect from '../../../../../../components/Monetize/Dropdowns/ProductDropdownMultiSelect';
import { MLegalEntitySelect } from '../../../../../../components/Monetize/MLegalEntitySelect';
import { CustomFieldsAllData } from '../../../../../../hooks/useCustomFieldsAll';
import { useFlags } from '../../../../../../services/launchDarkly';
import {
  ContextProduct,
  GuidedQuotingContext,
  IGuidedQuotingRespSchema,
  IOfferingRes,
  IQuestion,
  Maybe,
  QuestionTypesEnum,
} from '../../../../../../types';
import {
  sortByProductObjType,
  sortByProductType,
} from '../../../../../../utils';
import { orderObjectsBy } from '../../../../../../utils/misc';
import { questionFilter, validDateQuestionValue } from './../guidedQuote.utils';
import { QuestionFormCustomField } from './QuestionFormCustomField';
import { QuestionFormOffering } from './QuestionFormOffering';
import { QuestionFormRate } from './QuestionFormRate';

interface QuestionFormProps {
  question: IQuestion;
  context: Record<string, any>;
  setContext: Dispatch<SetStateAction<GuidedQuotingContext>>;
  productQuestion: Maybe<IQuestion>;
  offeringQuestion: Maybe<IQuestion>;
  guidedQuoting: IGuidedQuotingRespSchema | undefined;
  accountCurrency?: string;
  customFieldsData: CustomFieldsAllData;
}

export const QuestionForm = ({
  question,
  context,
  setContext,
  productQuestion,
  offeringQuestion,
  guidedQuoting,
  accountCurrency,
  customFieldsData,
}: QuestionFormProps) => {
  const { salesDemoMedinsightTempGuidedQuotingSpecialConfig } = useFlags();
  const [value, setValue] = useState<any>();
  const [filter, setFilter] = useState<Record<string, any>>();
  const [offeringData, setOfferingData] = useState<any>();

  useEffect(() => {
    if (value && question) {
      let transformedValue = value;

      if (
        question.type === QuestionTypesEnum.OFFERING &&
        Array.isArray(value)
      ) {
        // FIXME: can we sort these at the service layer so that everywhere benefits from it?
        const offerings = orderObjectsBy(value as IOfferingRes[], ['name']).map(
          (offering) => ({
            ...offering,
            products: sortByProductType(offering.products),
            offeringProducts: sortByProductObjType(offering.offeringProducts),
          }),
        );
        setOfferingData(offerings);
        transformedValue = offerings.reduce((acc: any, item) => {
          acc[item.id] = {
            name: item.name,
            rate: '',
            products: sortByProductObjType(item.offeringProducts).map(
              ({ isMandatory, product }): ContextProduct => ({
                id: product.id,
                name: product.name,
                type: product.productType,
                isMandatory,
                isSelected:
                  isMandatory ||
                  !!salesDemoMedinsightTempGuidedQuotingSpecialConfig?.preSelectOptionalProducts?.productIds?.includes(
                    product.id,
                  ),
                qty:
                  product.productType === 'USAGE'
                    ? '1'
                    : (context[question.id]?.value?.[item.id]?.products?.find(
                        (p: any) => p.id === product.id,
                      )?.qty ?? ''),
              }),
            ),
          };
          return acc;
        }, {});
      }

      setContext((prevState: GuidedQuotingContext) => ({
        ...prevState,
        [question.id]: { value: transformedValue, type: question.type } as any,
      }));
    }
  }, [value]);

  useEffect(() => {
    if (context[question.id]) {
      if (question.type == QuestionTypesEnum.OFFERING) {
        setValue(offeringData);
      } else {
        setValue(context[question.id].value);
      }
    } else {
      setValue(null as unknown as string);
    }

    if (question) {
      let newFilter: Record<string, any> = {};
      if (
        question.type === QuestionTypesEnum.OFFERING &&
        productQuestion &&
        Array.isArray(context[productQuestion.id])
      ) {
        newFilter.productId = {
          in: context[productQuestion.id].value.map(
            (product: any) => product.id,
          ),
        };
      }
      const additionalFilter = questionFilter(question, context);
      newFilter = { ...newFilter, ...additionalFilter };
      setFilter(newFilter);
    }
  }, [question]);

  return (
    <MVStack justifyContent="left" alignItems="flex-start" mt={2}>
      {question.type === QuestionTypesEnum.PRODUCT && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <ProductDropdownMultiSelect
            filters={filter}
            value={value}
            setValue={setValue}
          />
        </>
      )}
      {question.type === QuestionTypesEnum.OFFERING && (
        <QuestionFormOffering
          question={question}
          context={context}
          setContext={setContext}
          filter={filter}
          value={value}
          onChange={setValue}
        />
      )}
      {question.type === QuestionTypesEnum.RATE &&
        offeringQuestion &&
        context[offeringQuestion.id] && (
          <QuestionFormRate
            question={question}
            context={context}
            setContext={setContext}
            offeringQuestion={offeringQuestion}
            guidedQuoting={guidedQuoting}
            accountCurrency={accountCurrency}
            filter={filter}
            value={value}
            onChange={setValue}
          />
        )}
      {question.type === QuestionTypesEnum.DATE &&
        validDateQuestionValue(value) && (
          <>
            <MText fontWeight="semibold" mb={2}>
              {question.questionNumber}. {question.questionText}
            </MText>
            <DatePicker
              value={value}
              onChange={(value: any) => setValue(value)}
              minDate={filter?.min}
              maxDate={filter?.max}
              boxProps={{ mt: '0 !important', width: '100%' }}
            />
          </>
        )}
      {question.type === QuestionTypesEnum.NUMBER && !isNaN(Number(value)) && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MHStack width="100%" mt="0 !important">
            <MCustomNumberInput
              width="100%"
              value={value ?? ''}
              onChange={(value: any) => setValue(value)}
              min={filter?.min ?? 1}
              max={filter?.max ?? Number.MAX_SAFE_INTEGER}
            />
            {guidedQuoting?.contractLengthSource === question.id && (
              <MText>months.</MText>
            )}
          </MHStack>
        </>
      )}
      {question.type === QuestionTypesEnum.CURRENCY && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MCurrencySelect value={value} onChange={setValue} />
        </>
      )}
      {question.type === QuestionTypesEnum.CUSTOM_FIELD && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <QuestionFormCustomField
            question={question}
            filter={filter}
            customFieldsData={customFieldsData}
            value={value}
            onChange={setValue}
          />
        </>
      )}
      {question.type === QuestionTypesEnum.LEGAL_ENTITY && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MLegalEntitySelect value={value} onChange={setValue} />
        </>
      )}
      {question.type === QuestionTypesEnum.BILLING_FREQUENCY && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <BillingFrequencyDropdownSelect
            filters={filter}
            value={value}
            setValue={setValue}
          />
        </>
      )}
      {question.type === QuestionTypesEnum.TEXT && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MInput
            mt="0 !important"
            value={value ?? ''}
            onChange={(e) => setValue(e.target.value)}
          />
        </>
      )}
    </MVStack>
  );
};
