import { UseQueryOptions } from '@tanstack/react-query';
import { usePaymentGatewaysByTenant } from '../api/tenantService';
import { useAuth } from '../services/auth0';
import {
  ApiListResponse,
  IPaymentGateway,
  PaymentGatewayStatusEnum,
} from '../types';
import { groupBy } from '../utils/misc';

interface SelectData {
  paymentGateways: IPaymentGateway[];
  activeGateways: IPaymentGateway[];
  gatewaysById: Record<string, IPaymentGateway>;
  currentPaymentGateway: IPaymentGateway | null;
}

const useCurrentPaymentGateways = (
  options?: Partial<
    UseQueryOptions<ApiListResponse<IPaymentGateway>, unknown, SelectData>
  >,
) => {
  const { tenantId } = useAuth();

  const { isLoading, data, isFetched, isFetching } =
    usePaymentGatewaysByTenant<SelectData>(tenantId, {
      ...options,
      select: ({ content: paymentGateways }) => {
        paymentGateways = paymentGateways || [];
        return {
          paymentGateways,
          activeGateways: paymentGateways.filter(
            (item) => item.status === PaymentGatewayStatusEnum.ACTIVE,
          ),
          gatewaysById: groupBy(paymentGateways, 'id'),
          currentPaymentGateway:
            paymentGateways.find(
              (item) =>
                item.defaultGateway &&
                item.status === PaymentGatewayStatusEnum.ACTIVE,
            ) || null,
        };
      },
    });

  return {
    isLoading,
    /** All gateways */
    paymentGateways: data?.paymentGateways || [],
    /** All active gateways */
    activeGateways: data?.activeGateways || [],
    /** All gateways by Id - use this for records that have a known id, like BillGroups */
    gatewaysById: data?.gatewaysById || {},
    /** Default payment gateway */
    currentPaymentGateway: data?.currentPaymentGateway || null,
    isFetched,
    isFetching,
  };
};

export default useCurrentPaymentGateways;
