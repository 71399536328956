import { MBox, MText } from '../../../components/Monetize';

interface BillGroupDetailsCardProps {
  label: string;
  value: string;
}

export const BillGroupDetailsCard = ({
  label,
  value,
}: BillGroupDetailsCardProps) => {
  return (
    <MBox>
      <MText fontWeight="400" fontSize="md">
        {label}
      </MText>
      <MText fontWeight="700" fontSize="2.5xl">
        {value}
      </MText>
    </MBox>
  );
};
