import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { apiGetAllList } from '../../../../../api/axios';
import { useGetListData } from '../../../../../api/queryUtils';
import {
  MBox,
  MButton,
  MCheckbox,
  MCustomMultiSelect,
  MFlex,
  MFormField,
  MText,
} from '../../../../../components/Monetize';
import { MCustomMultiSelectRef } from '../../../../../components/Monetize/MCustomSelect/MCustomMultiSelect';
import { useCustomSelectValues } from '../../../../../components/Monetize/MCustomSelect/useCustomSelectValue';
import {
  IProduct,
  IProductResSchema,
  MinCommitConfigTypeEnum,
  ProductStatusEnum,
  ProductTypeEnum,
} from '../../../../../types';
import { PRODUCTS_ITEM_DEFAULT_PAGER } from './RatePriceUtils';

interface RateMinimumCommitConfigProps {
  product: IProduct;
  isLoading?: boolean;
  isLocked?: boolean;
  isDisabled?: boolean;
  /** True if the rate is already saved in database */
  isExistingRate?: boolean;
}
const RateMinimumCommitConfig = React.forwardRef<
  HTMLInputElement | null,
  RateMinimumCommitConfigProps
>(
  (
    {
      product,
      isLoading,
      isLocked,
      isDisabled = false,
      isExistingRate = false,
    }: RateMinimumCommitConfigProps,
    forwardedRef: React.ForwardedRef<HTMLInputElement | null>,
  ) => {
    const {
      control,
      formState: { errors, dirtyFields },
      watch,
      setValue,
    } = useFormContext();
    const watchMinCommitConfig = watch(`minCommitConfig`);

    const isSpecificProductsTypeSelected =
      watchMinCommitConfig?.type ===
      MinCommitConfigTypeEnum.SPECIFIC_USAGE_PRODUCTS;

    const [query, setQuery] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { data: productList, isLoading: productListLoading } =
      useGetListData<IProduct>(
        'productCatalogProducts',
        {
          config: PRODUCTS_ITEM_DEFAULT_PAGER,
          filters: {
            name: { contains: searchTerm },
            status: ProductStatusEnum.ACTIVE,
            productType: {
              in: [ProductTypeEnum.USAGE],
            },
            sort: 'name',
          },
        },
        {
          placeholderData: (previousData, previousQuery) => previousData,
          refetchOnWindowFocus: false,
          enabled: isSpecificProductsTypeSelected,
        },
      );

    const setQueryDebounced = useMemo(() => debounce(setSearchTerm, 300), []);

    const setFormValue = (value: string[]) => {
      setValue(`minCommitConfig.productIds`, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    };

    const customSelectRef = useRef<MCustomMultiSelectRef>();

    const {
      internalValue,
      isLoading: isLoadingSavedValue,
      placeholder,
      onInternalValueChange,
    } = useCustomSelectValues<IProductResSchema>({
      value: watchMinCommitConfig?.productIds,
      setValue: setFormValue,
      getByIds: async (ids: string[]) =>
        ids.length > 0
          ? apiGetAllList<IProductResSchema>('/api/products', {
              filters: { id: { in: ids } },
            })
          : [],
      label: 'Products',
    });

    useEffect(() => {
      // This ensures that MCustomMultiSelect knows about the date change
      // otherwise it had stale data when opened the first time
      customSelectRef.current?.updateItems(internalValue);
    }, [internalValue]);

    const onChangeQuery = (q: string): void => {
      if (q !== query) {
        setQuery(q);
        setQueryDebounced(q);
      }
    };

    const onClearAll = () => {
      customSelectRef.current?.clearAll();
      onInternalValueChange([]);
    };

    if (isLocked && isSpecificProductsTypeSelected) {
      const sortedProducts = internalValue.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      return (
        <Popover placement="bottom-end" trigger="hover" isLazy>
          <PopoverTrigger>
            <MButton variant="tertiary" ml="auto">
              Specific Products
            </MButton>
          </PopoverTrigger>
          <PopoverContent maxWidth="260px" p="2">
            <PopoverArrow />
            <PopoverBody p="2">
              <MBox
                maxHeight="200px"
                overflow="auto"
                className="custom-scroll-bar-v1"
              >
                {sortedProducts.map(({ name }, index) => (
                  <MText
                    title={name}
                    noOfLines={1}
                    isTruncated
                    mb={internalValue.length - 1 == index ? '0' : '2'}
                    display="block"
                    mr="2"
                  >
                    {name}
                  </MText>
                ))}
              </MBox>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    }

    return (
      <MFlex gap="4">
        <MFormField
          error={errors.minCommitConfig?.type}
          display="flex"
          justifyContent="flex-end"
        >
          <Controller
            name="minCommitConfig.type"
            defaultValue={false}
            control={control}
            render={({ field: { value, onChange, ...rest } }) => (
              <MCheckbox
                isDisabled={isLoading || isLocked}
                isChecked={
                  value === MinCommitConfigTypeEnum.SPECIFIC_USAGE_PRODUCTS
                }
                variant={
                  isExistingRate && dirtyFields?.minCommitConfig?.type
                    ? 'unsaved'
                    : 'select-events'
                }
                onChange={(event) => {
                  onChange(
                    event.target.checked
                      ? MinCommitConfigTypeEnum.SPECIFIC_USAGE_PRODUCTS
                      : MinCommitConfigTypeEnum.USAGE_PRODUCTS,
                  );
                  // When unchecked clear all selected productIds
                  if (!event.target.checked) {
                    onClearAll();
                    setValue('minCommitConfig.productIds', undefined, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }
                }}
                {...rest}
              >
                Specific Products
              </MCheckbox>
            )}
          />
        </MFormField>
        {isSpecificProductsTypeSelected && (
          <MFormField
            minW="8rem"
            isHorizontal
            isRequired={!isLocked}
            error={(errors.minCommitConfig as any)?.productIds}
            justifyContent="flex-end"
          >
            <Controller
              name="minCommitConfig.productIds"
              control={control}
              render={({ field: { onChange, ref, ...rest } }) => (
                <MCustomMultiSelect
                  ref={(refEvent: any) => {
                    ref(refEvent);
                    customSelectRef.current = refEvent;
                  }}
                  isLazy
                  items={productList?.content}
                  itemTitle="name"
                  itemValue="id"
                  showQueryInput
                  multiple
                  onChange={(val) => {
                    onInternalValueChange(val as IProductResSchema[]);
                  }}
                  useExternalQuery
                  returnItem
                  externalQuery={query}
                  onChangeQuery={onChangeQuery}
                  {...rest}
                  value={internalValue}
                  isDisabled={isDisabled}
                  loading={productListLoading || isLoadingSavedValue}
                  closeButtonText="Apply"
                  placeholder={placeholder}
                  inputProps={{
                    variant:
                      isExistingRate && dirtyFields?.minCommitConfig?.productIds
                        ? 'unsaved'
                        : 'primary',
                    _placeholder: { color: 'tPurple.dark' },
                    _disabled: {
                      // By default we hide placeholder when disabled, but here we want it visible
                      _placeholder: {
                        color: 'tGray.darkPurple',
                      },
                    },
                  }}
                  renderPreContent={() => (
                    <MFlex justifyContent="space-between" mx="2" mt="2">
                      <MText fontStyle="italic" fontSize="xs"></MText>
                      <MButton
                        variant="tertiary"
                        size="xs"
                        role="button"
                        onClick={onClearAll}
                      >
                        Clear All
                      </MButton>
                    </MFlex>
                  )}
                />
              )}
            />
          </MFormField>
        )}
      </MFlex>
    );
  },
);

export default RateMinimumCommitConfig;
