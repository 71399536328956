import { Divider, useDisclosure } from '@chakra-ui/react';
import { toDateShort } from '@monetize/utils/core';
import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import {
  Location,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  useChangeContractOwner,
  useGetContractById,
} from '../../api/cpqService';
import {
  CONTRACT_END_ACTION_UI_DISPLAY,
  CONTRACT_STATUS_DISPLAY,
} from '../../constants/contracts';
import {
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
  QuoteTypeEnumDisplay,
} from '../../constants/quotes';
import { getAccountContractsRoute } from '../../constants/routes';
import ContractActions from '../../routes/Quotes/Quote/ContractActions';
import { IQuoteRespSchema, QuoteStatusEnum, QuoteTypeEnum } from '../../types';
import { openQuote } from '../../utils/quotes';
import {
  currencyBodyTemplate,
  dateTimeBodyTemplate,
  headerAndIdTemplate,
  statusBodyTemplate,
  userAvatarBodyTemplate,
} from '../../utils/tableUtils';
import {
  MBox,
  MCenterModal,
  MDataTable,
  MGrid,
  MGridItem,
  MHStack,
  MPageHeader,
  MSkeleton,
  MText,
  MVStack,
} from '../Monetize';
import { ChangeOwner } from '../Monetize/ChangeOwner';
import { MCurrency } from '../Monetize/MCurrency';
import { TitleValuePair } from '../Monetize/TitleValuePair';
import { RenewalModal } from '../RenewalModal/RenewalModal';

interface ContractModalProps {
  isOpen: boolean;
  onClose: (
    params?: Readonly<Params<string>>,
    source?: 'accounts' | 'dashboard',
  ) => void;
}
interface ContractModalLocation extends Location {
  state: { source?: 'accounts' | 'dashboard' };
}

const ContractModal = React.forwardRef(
  ({ isOpen, onClose }: ContractModalProps, ref) => {
    const {
      isOpen: isOpenRenewalModal,
      onOpen: onOpenRenewalModal,
      onClose: onCloseRenewalModal,
    } = useDisclosure();

    const navigate = useNavigate();
    const params = useParams();
    const [contractId, setContractId] = useState(params?.contractId || '');
    const { state } = useLocation() as ContractModalLocation;

    useEffect(() => {
      if (params?.contractId) {
        setContractId(params?.contractId);
      }
    }, [params]);

    const {
      data: contract,
      isLoading: isContractLoading,
      isError,
    } = useGetContractById(contractId, {
      enabled: isOpen && !!contractId,
      meta: { showErrorToast: true },
    });

    useEffect(() => {
      if (isError) {
        onClose(params, state?.source);
      }
    }, [isError, onClose, params, state?.source]);

    const { mutate: changeContractOwner } = useChangeContractOwner();

    const handleChangeOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
      changeContractOwner({ contractId, userId: String(e) });
    };

    const quoteColumns: ColumnProps[] = [
      {
        className: 'overflow-hidden',
        field: 'id',
        header: 'Name',
        body: headerAndIdTemplate<IQuoteRespSchema>('description', 'id'),
      },
      {
        className: 'overflow-hidden table-cell-sm',
        field: 'owner',
        header: 'Owner',
        body: userAvatarBodyTemplate<IQuoteRespSchema>('ownerName', {
          extraProps: {
            mr: '21px',
          },
        }),
        style: {
          width: '3.125rem',
        },
      },
      {
        field: 'modifyDate',
        header: 'Updated',
        body: dateTimeBodyTemplate<IQuoteRespSchema>('modifyDate'),
        className: 'table-cell-md',
      },
      {
        field: 'type',
        header: 'Type',
        body: statusBodyTemplate<IQuoteRespSchema, QuoteTypeEnum>(
          'type',
          QuoteTypeEnumDisplay,
        ),
        className: 'table-cell-md',
      },
      {
        field: 'status',
        header: 'Status',
        body: statusBodyTemplate<IQuoteRespSchema, QuoteStatusEnum>(
          'status',
          QuoteStatusEnumDisplay,
          QuoteStatusTagStyle,
        ),
        className: 'table-cell-sm',
      },
      {
        field: 'total',
        header: 'Total',
        body: currencyBodyTemplate<IQuoteRespSchema>('amount', 'currency'),
        className: 'text-right table-cell-md',
        style: { textAlign: 'right' },
      },
    ];

    const customTagStyle = {
      fontWeight: 'normal',
      color: 'tPurple.safety',
      paddingLeft: '9px',
      paddingRight: '9px',
      backgroundColor: 'tPurple.inputBox',
      borderRadius: '3px',
    };

    const title = contract && (
      <MVStack alignItems="flex-start">
        <MPageHeader
          title="Contract Details"
          status={CONTRACT_STATUS_DISPLAY[contract.status]}
          tag={contract.renewed && 'Renewed'}
          tagStyle={contract.renewed ? customTagStyle : {}}
          parentLink={getAccountContractsRoute(contract.accountId)}
          parentLinkTitle={contract.accountName}
          copyUrl
          id={contract?.id}
          subTitle={
            <MBox>
              <MHStack>
                <ChangeOwner
                  value={contract.owner}
                  ownerName={contract.ownerName}
                  onChange={handleChangeOwner}
                />
              </MHStack>
            </MBox>
          }
          containerProps={{ mb: 0 }}
        ></MPageHeader>
      </MVStack>
    );

    const contractOverviewValues = [
      {
        title: 'Contract Period',
        value: `${
          contract?.startDate ? toDateShort(contract.startDate) : ''
        } - ${contract?.endDate ? toDateShort(contract.endDate) : ''}`,
        width: 2,
      },
      {
        title: 'Status',
        value: contract?.status ? CONTRACT_STATUS_DISPLAY[contract.status] : '',
        width: 1,
      },
      {
        title: 'End Action',
        value: contract?.endAction
          ? CONTRACT_END_ACTION_UI_DISPLAY[contract.endAction]
          : '',
        width: 2,
      },
      {
        title: 'Currency',
        value: contract?.currency ? contract.currency : '',
        width: 1,
      },
      {
        title: 'Contract Value',
        value: contract?.currency ? (
          <MCurrency
            amount={contract?.totalValue}
            fontWeight="bold"
            fontSize="inherit"
            options={{
              currency: contract.currency,
            }}
          />
        ) : null,
        width: 1,
      },
    ];

    const handleEarlyRenewal = async () => {
      onOpenRenewalModal();
    };

    if (contract && isOpenRenewalModal) {
      return <RenewalModal contract={contract} onClose={onCloseRenewalModal} />;
    }

    return (
      <MCenterModal
        isOpen={isOpen}
        onClose={() => onClose(params, state?.source)}
        size="6xl"
        modalContentProps={{ 'data-testid': 'contract-form' } as any}
        renderModalTitleActions={() => title}
      >
        <MBox>
          {isContractLoading ? (
            <MSkeleton height={10} w="full" mt={4} />
          ) : (
            <MGrid templateColumns="repeat(8, 1fr)" columnGap={4} fontSize="md">
              {contractOverviewValues.map((item, index) => {
                return (
                  <MGridItem key={index} colSpan={item.width}>
                    <TitleValuePair title={item.title} value={item.value} />
                  </MGridItem>
                );
              })}
              <MGridItem justifySelf="end">
                {contract && !contract.renewed && (
                  <ContractActions
                    contract={contract}
                    handleEarlyRenewal={handleEarlyRenewal}
                  />
                )}
              </MGridItem>
            </MGrid>
          )}
        </MBox>
        <Divider mt={6} mb={5} borderWidth="2px" borderColor="tBlue.hover" />
        <MText fontSize="md" fontWeight="bold">
          Quotes
        </MText>
        {isContractLoading && <MSkeleton height={100} width="full" mt={8} />}
        {!isContractLoading && (
          <MDataTable
            value={contract?.quotes}
            className="p-datatable-responsive"
            emptyProps={{
              mainMessage:
                'Looks like there is a problem displaying the quotes here.',
            }}
            columns={quoteColumns}
            onRowClick={(e: DataTableRowClickEvent, openInNewWindow) =>
              openQuote(navigate, e.data?.id, e.data?.status, openInNewWindow)
            }
          />
        )}
      </MCenterModal>
    );
  },
);

export default ContractModal;
