import {
  BoxProps,
  ButtonProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { FC, FocusEvent, useEffect } from 'react';
import {
  Controller,
  FieldErrors,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import { MdClear } from 'react-icons/md';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MCustomNumberInput,
  MCustomSelect,
  MFlex,
  MFormField,
  MHStack,
  MLockedTextOrContent,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import { OFFERINGS } from '../../../../../constants';
import { getCurrencySymbol } from '../../../../../constants/currencies';
import {
  IPrice,
  IProduct,
  OfferingTypesEnum,
  ProductTypeEnum,
} from '../../../../../types';
import { PriceModelEnum } from '../../../../../types/priceTypes';
import { transformValueToMinimumTwoDecimalPlaces } from '../../../../../utils';
import { DisabledCustomPriceModelLabel } from './components/DisabledCustomPriceModelLable';
import { PriceModelItemContent } from './components/PriceModelItemContent';
import { TooltipOrChildren } from './components/TooltipOrChildren';
import { RatePercentOfTotalConfig } from './RatePercentOfTotalConfig';

interface RatePriceProps {
  id: string;
  index: number;
  product: IProduct;
  offeringType: OfferingTypesEnum;
  loading?: boolean;
  isLastRow?: boolean;
  lastRowIndex: number;
  isOpen: boolean;
  isLocked?: boolean;
  omitTierLabel?: boolean;
  omitRowDivider?: boolean;
  addPriceTierProps?: BoxProps;
  clearButtonProps?: ButtonProps;
  keyPrefix?: string;
  isPriceDrawer?: boolean;
  handleRemovePrice: (index: number) => void;
  generateNewPrice: (
    previousFrom: null | number,
    previousTo: null | number,
    previousPriceModel: PriceModelEnum,
    previousAmount: null | number,
  ) => void;
  currency: string;
  pricesLengthFromApi?: number;
  isReadOnly?: boolean;
  isPriceModelDisabled?: boolean;
}

export const RatePrice: FC<RatePriceProps> = ({
  index,
  product,
  offeringType,
  loading = false,
  isLastRow = false,
  lastRowIndex,
  isOpen,
  isLocked = false,
  omitTierLabel = false,
  omitRowDivider = false,
  isPriceDrawer = false,
  addPriceTierProps,
  clearButtonProps,
  keyPrefix = 'products.',
  currency,
  pricesLengthFromApi,
  generateNewPrice,
  handleRemovePrice,
  isReadOnly,
  isPriceModelDisabled,
}: RatePriceProps) => {
  const productId = product.id!;

  const {
    watch,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useFormContext();

  const dirtyFieldsWithKeyPrefix = keyPrefix
    ? dirtyFields?.[keyPrefix.substring(0, keyPrefix.length - 1)]
    : dirtyFields;

  const fieldKeyPrefix = `${keyPrefix}${productId}.prices`;
  const errorKeyPrefix = `${fieldKeyPrefix}.${index}`;
  const productError = get(errors, errorKeyPrefix) as FieldErrors<FieldValues>;
  const values = getValues(fieldKeyPrefix);

  const watchedCurrency = watch('currency') || currency;
  const watchedFrom = watch(`${fieldKeyPrefix}.${index}.from`);
  const watchedTo = watch(`${fieldKeyPrefix}.${index}.to`);
  const watchedPriceModel = watch(`${fieldKeyPrefix}.${index}.priceModel`);
  const watchedAmount = watch(`${fieldKeyPrefix}.${index}.amount`);

  const isCustomPriceModel = watchedPriceModel === PriceModelEnum.CUSTOM;
  const isUsageProductRate = product.productType === ProductTypeEnum.USAGE;
  const isMinCommitProductRate =
    product.productType === ProductTypeEnum.MIN_COMMIT;
  const isOneTimePrePaidCreditProductRate =
    product.productType === ProductTypeEnum.ONETIME_PREPAID_CREDIT;
  const isPoTOfferingType =
    offeringType === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL;

  const currencySymbol = getCurrencySymbol(watchedCurrency);
  const shouldValidateCurrentIndexForDirty =
    pricesLengthFromApi && !(index + 1 > pricesLengthFromApi);
  const errorMessageProps = { mt: 0 };

  useEffect(() => {
    const isMinCommitRateUpdated =
      values[0].priceModel === PriceModelEnum.VOLUME &&
      values[0].from === 0 &&
      values[0].to === '' &&
      values[0].amount === 1;

    const isOneTimePrePaidCreditRateUpdated =
      values[0].priceModel === PriceModelEnum.VOLUME &&
      values[0].from === 0 &&
      values[0].to === '' &&
      values[0].amount === 1;

    // When product's is min commitment, then set From 0, To: null, Amount: 1 and save
    // When product is one time pre-paid credit, then set From 0, To: null, Amount: 1 and save
    if (
      (!isMinCommitRateUpdated && isMinCommitProductRate) ||
      (!isOneTimePrePaidCreditRateUpdated && isOneTimePrePaidCreditProductRate)
    ) {
      setValue(fieldKeyPrefix, [
        {
          ...values[0],
          priceModel: PriceModelEnum.VOLUME,
          from: 0,
          to: '',
          amount: 1,
        },
      ]);
    }
  }, [
    fieldKeyPrefix,
    isMinCommitProductRate,
    isOneTimePrePaidCreditProductRate,
    values,
    setValue,
  ]);

  useEffect(() => {
    if (values && values.length - 1 === index && isUsageProductRate) {
      setValue(`${fieldKeyPrefix}.${index}.to`, '');
    }
  }, [index, isUsageProductRate, values, fieldKeyPrefix, setValue]);

  const handlePriceModel = (onChange: (e: any) => void) => (e: any) => {
    onChange(e);
    const value = String(e);

    if (value === PriceModelEnum.TIERED) {
      const nextPrices = values.map((x: IPrice, idx: number) => ({
        ...x,
        priceModel: idx === index ? x.priceModel : PriceModelEnum.TIERED,
      }));
      setValue(fieldKeyPrefix, nextPrices, {
        shouldDirty: true,
      });
    }

    if (value !== PriceModelEnum.TIERED) {
      const tieredExistsOnValue = values.some(
        (x: IPrice) => x.priceModel === PriceModelEnum.TIERED,
      );
      if (tieredExistsOnValue) {
        const nextPrices = values.map((x: IPrice, idx: number) => ({
          ...x,
          priceModel:
            x.priceModel === PriceModelEnum.TIERED ? null : x.priceModel,
        }));
        setValue(fieldKeyPrefix, nextPrices, {
          shouldDirty: true,
        });
      }
    }

    // Handles logic for CUSTOM Price model
    if (value === PriceModelEnum.CUSTOM) {
      // get the current row
      // check undefined e.g.,
      // arr = [0, , 2] i.e. [0, <1 empty item>, 2]
      // arr[1] === undefined
      const fieldToUpdate = values[index];
      if (fieldToUpdate) {
        setValue(
          fieldKeyPrefix,
          [
            {
              // override the current row with from: 0, to: '' which will later be sent as null, amount: 0
              ...fieldToUpdate,
              from: 0,
              to: '',
              amount: 0,
            },
          ],
          {
            shouldDirty: true,
          },
        );
      }
      /** It removes amount field errors if any */
      if (productError?.amount) {
        clearErrors(`${errorKeyPrefix}.amount`);
      }
    }

    // Handles logic for PERCENT_OF_TOTAL Price model
    if (value === PriceModelEnum.PERCENT_OF_TOTAL) {
      const fieldToUpdate = values[index];
      if (fieldToUpdate) {
        setValue(
          fieldKeyPrefix,
          [
            {
              // override the current row with from: 0, to: 1, amount: 1
              ...fieldToUpdate,
              from: 0,
              to: 1,
              amount: 1,
            },
          ],
          {
            shouldDirty: true,
          },
        );
      }
    }
  };

  const handleTo =
    (onChange: (e: any) => void) =>
    (valueAsString: string, valueAsNumber: number) => {
      onChange(valueAsString);
      if (
        values.priceModel !== PriceModelEnum.CUSTOM &&
        index < values.length - 1
      ) {
        setValue(`${fieldKeyPrefix}.${index + 1}.from`, `${valueAsNumber + 1}`);
      }
    };

  /** Determines disabled price model items */
  let disabledPriceModelItems: PriceModelEnum[] = [];

  if (index !== 0) {
    if (values[0].priceModel === PriceModelEnum.TIERED) {
      disabledPriceModelItems = OFFERINGS.DISABLED_TIERED_PRICE_MODEL_OPTIONS;
    } else if (values[0].priceModel === PriceModelEnum.CUSTOM) {
      disabledPriceModelItems = OFFERINGS.DISABLED_CUSTOM_PRICE_MODEL_OPTIONS;
    } else if (
      values[0].priceModel === PriceModelEnum.FLAT ||
      values[0].priceModel === PriceModelEnum.VOLUME
    ) {
      disabledPriceModelItems =
        OFFERINGS.DISABLED_FLAT_VOLUME_PRICE_MODEL_OPTIONS;
    }
  }

  return (
    <>
      <MFlex align="center" gap="4">
        <MHStack
          align="flex-start"
          spacing={4}
          mb={!isLastRow ? 2 : 0}
          data-testid="rate-price-item-form"
        >
          {!omitTierLabel && (
            <MFlex align="center" minW={4} minH={8}>
              <MText color="tPurple.base">{`${index + 1}.`}</MText>
            </MFlex>
          )}
          <MBox w={isPriceDrawer ? '40' : '12.625rem'}>
            <MFormField
              error={productError?.priceModel}
              formErrorMessageProps={errorMessageProps}
            >
              <MLockedTextOrContent
                isLocked={Boolean(isLocked)}
                text={
                  OFFERINGS.PRICE_MODEL_OPTIONS[
                    watchedPriceModel as PriceModelEnum
                  ]
                }
              >
                <Controller
                  name={`${fieldKeyPrefix}.${index}.priceModel`}
                  defaultValue=""
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <MCustomSelect
                      aria-label="Price model"
                      isDisabled={
                        loading ||
                        isMinCommitProductRate ||
                        isOneTimePrePaidCreditProductRate ||
                        isPoTOfferingType ||
                        isPriceModelDisabled
                      }
                      isReadOnly={isReadOnly}
                      bgColor="tWhite.base"
                      placeholder="Select Pricing Model"
                      items={
                        isPoTOfferingType
                          ? OFFERINGS.PRICE_MODEL_OPTIONS_DISPLAY_STRING_POT
                          : OFFERINGS.PRICE_MODEL_OPTIONS_DISPLAY_STRING
                      }
                      disabledItems={disabledPriceModelItems}
                      onChange={handlePriceModel(onChange)}
                      renderItemContent={({
                        title,
                        item,
                        isSubtitle,
                        isSelected,
                        isHighlight,
                        isDisabled,
                      }) => (
                        <PriceModelItemContent
                          title={title}
                          item={item}
                          isSubtitle={isSubtitle}
                          isSelected={isSelected}
                          isHighlight={isHighlight}
                          isDisabled={isDisabled}
                          selectedPriceModel={
                            OFFERINGS.PRICE_MODEL_OPTIONS[
                              values[0].priceModel as PriceModelEnum
                            ]
                          }
                        />
                      )}
                      inputProps={{
                        variant: isReadOnly
                          ? 'readonly'
                          : shouldValidateCurrentIndexForDirty &&
                              dirtyFieldsWithKeyPrefix?.[productId]?.prices?.[
                                index
                              ]?.priceModel
                            ? 'unsaved'
                            : 'primary',
                      }}
                      {...rest}
                    />
                  )}
                />
              </MLockedTextOrContent>
            </MFormField>
          </MBox>
          <MBox w="6.25rem">
            <TooltipOrChildren
              isDisabled={isCustomPriceModel}
              label={<DisabledCustomPriceModelLabel />}
            >
              <MFormField
                error={productError?.from}
                formErrorMessageProps={errorMessageProps}
              >
                <MLockedTextOrContent
                  text={watchedFrom}
                  textType="QUANTITY"
                  isLocked={
                    Boolean(isLocked) ||
                    index > 0 ||
                    (isUsageProductRate && index === 0)
                  }
                >
                  <Controller
                    name={`${fieldKeyPrefix}.${index}.from`}
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                      <MCustomNumberInput
                        variant={
                          isReadOnly
                            ? 'readonly'
                            : shouldValidateCurrentIndexForDirty &&
                                dirtyFieldsWithKeyPrefix?.[productId]?.prices?.[
                                  index
                                ]?.from
                              ? 'unsaved'
                              : 'primary'
                        }
                        data-testid={`${fieldKeyPrefix}.${index}.from`}
                        aria-label="From"
                        isDisabled={
                          loading ||
                          isCustomPriceModel ||
                          isMinCommitProductRate ||
                          isOneTimePrePaidCreditProductRate ||
                          (isUsageProductRate && index === 0) ||
                          isPoTOfferingType
                        }
                        isReadOnly={isReadOnly}
                        placeholder="0"
                        onChange={(
                          valueAsString: string,
                          valueAsNumber: number,
                        ) => onChange(valueAsString)}
                        styleProps={
                          isReadOnly
                            ? {
                                _readOnly: {
                                  border: 'none',
                                  outline: 'none',
                                  resize: 'none',
                                  px: 0,
                                  background: 'transparent',
                                  color: 'tPurple.dark',
                                  _placeholder: {
                                    color: 'transparent',
                                  },
                                },
                              }
                            : {
                                fontFamily: 'sans-serif',
                                _disabled: {
                                  cursor: 'not-allowed',
                                  color: 'tGray.acGray',
                                  background: 'tGray.support',
                                  _placeholder: {
                                    color: 'tGray.lightPurple',
                                  },
                                },
                              }
                        }
                        {...rest}
                      />
                    )}
                  />
                </MLockedTextOrContent>
              </MFormField>
            </TooltipOrChildren>
          </MBox>
          <MBox w="6.25rem">
            <TooltipOrChildren
              isDisabled={isCustomPriceModel}
              label={<DisabledCustomPriceModelLabel />}
            >
              <MFormField
                error={productError?.to}
                formErrorMessageProps={errorMessageProps}
              >
                <MLockedTextOrContent
                  text={watchedTo || Infinity}
                  textType="QUANTITY"
                  isLocked={
                    Boolean(isLocked) ||
                    (isUsageProductRate && index === values.length - 1)
                  }
                >
                  <Controller
                    name={`${fieldKeyPrefix}.${index}.to`}
                    defaultValue=""
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                      <MCustomNumberInput
                        variant={
                          isReadOnly
                            ? 'readonly'
                            : shouldValidateCurrentIndexForDirty &&
                                dirtyFieldsWithKeyPrefix?.[productId]?.prices?.[
                                  index
                                ]?.to
                              ? 'unsaved'
                              : 'primary'
                        }
                        data-testid={`${fieldKeyPrefix}.${index}.to`}
                        aria-label="Up to"
                        isDisabled={
                          loading ||
                          isCustomPriceModel ||
                          isMinCommitProductRate ||
                          isOneTimePrePaidCreditProductRate ||
                          isPoTOfferingType
                        }
                        isReadOnly={isReadOnly}
                        placeholder="∞"
                        styleProps={
                          isReadOnly
                            ? {
                                _readOnly: {
                                  border: 'none',
                                  outline: 'none',
                                  resize: 'none',
                                  px: 0,
                                  background: 'transparent',
                                  color: 'tPurple.dark',
                                  _placeholder: {
                                    color: 'tPurple.dark',
                                  },
                                },
                              }
                            : {
                                fontFamily: 'sans-serif',
                                _disabled: {
                                  cursor: 'not-allowed',
                                  color: 'tGray.acGray',
                                  background: 'tGray.support',
                                  _placeholder: {
                                    color: 'tGray.lightPurple',
                                  },
                                },
                              }
                        }
                        onChange={handleTo(onChange)}
                        {...rest}
                      />
                    )}
                  />
                </MLockedTextOrContent>
              </MFormField>
            </TooltipOrChildren>
          </MBox>

          <MBox w="32">
            <TooltipOrChildren
              isDisabled={isCustomPriceModel}
              label={<DisabledCustomPriceModelLabel />}
            >
              <MFormField
                error={productError?.amount}
                formErrorMessageProps={errorMessageProps}
              >
                <MLockedTextOrContent
                  isLocked={!!isLocked}
                  textType={isPoTOfferingType ? 'PERCENTAGE' : 'CURRENCY'}
                  currency={currency}
                  text={
                    isPoTOfferingType && watchedAmount
                      ? watchedAmount / 100
                      : watchedAmount
                  }
                >
                  <Controller
                    name={`${fieldKeyPrefix}.${index}.amount`}
                    control={control}
                    render={({ field: { onChange, onBlur, ...rest } }) => (
                      <InputGroup>
                        {!isPoTOfferingType && (
                          <InputLeftElement w="4" pl="2">
                            {currencySymbol}
                          </InputLeftElement>
                        )}
                        <MCustomNumberInput
                          variant={
                            isReadOnly
                              ? 'readonly'
                              : shouldValidateCurrentIndexForDirty &&
                                  dirtyFieldsWithKeyPrefix?.[productId]
                                    ?.prices?.[index]?.amount
                                ? 'unsaved'
                                : 'primary'
                          }
                          aria-label="Amount"
                          data-testid={`${fieldKeyPrefix}.${index}.amount`}
                          inputMode="decimal"
                          isDisabled={
                            loading ||
                            isCustomPriceModel ||
                            isMinCommitProductRate ||
                            isOneTimePrePaidCreditProductRate
                          }
                          isReadOnly={isReadOnly}
                          placeholder={`0.00`}
                          styleProps={
                            isReadOnly
                              ? {
                                  pl: isPoTOfferingType ? 0 : 5,
                                  pr: isPoTOfferingType ? 5 : 0,
                                  textAlign: isPoTOfferingType
                                    ? 'right'
                                    : 'left',
                                  _readOnly: {
                                    border: 'none',
                                    outline: 'none',
                                    resize: 'none',
                                    px: 0,
                                    background: 'transparent',
                                    color: 'tPurple.dark',
                                    _placeholder: {
                                      color: 'tPurple.dark',
                                    },
                                  },
                                }
                              : {
                                  pl: isPoTOfferingType ? 0 : 5,
                                  pr: isPoTOfferingType ? 5 : 0,
                                  textAlign: isPoTOfferingType
                                    ? 'right'
                                    : 'left',
                                }
                          }
                          onBlur={(e: FocusEvent<HTMLInputElement>) => {
                            onBlur();
                            const { value } = e.target;
                            if (value) {
                              const transFormedValue =
                                transformValueToMinimumTwoDecimalPlaces(value);
                              // using setTimeout to not get overwritten by zod transformer
                              setTimeout(() => onChange(transFormedValue));
                            }
                          }}
                          onChange={(
                            valueAsString: string,
                            valueAsNumber: number,
                          ) => onChange(valueAsString)}
                          {...rest}
                        />
                        {isPoTOfferingType && (
                          <InputRightElement w="4" pr="2">
                            %
                          </InputRightElement>
                        )}
                      </InputGroup>
                    )}
                  />
                </MLockedTextOrContent>
              </MFormField>
            </TooltipOrChildren>
          </MBox>
          {!isPoTOfferingType && (
            <MBox w="8">
              {!isLocked && !isReadOnly && index > 0 && (
                <MTooltip label="Delete" placement="bottom-start">
                  <MCustomIconButton
                    justifySelf="center"
                    variant="icon"
                    icon={MdClear}
                    containerSize={8}
                    onClick={() => handleRemovePrice(index)}
                    {...clearButtonProps}
                  />
                </MTooltip>
              )}
            </MBox>
          )}
        </MHStack>
        {isPoTOfferingType && index === 0 && !isPriceDrawer && (
          <RatePercentOfTotalConfig
            product={product}
            isLoading={loading}
            isLocked={isLocked || isReadOnly}
          />
        )}
      </MFlex>

      {!isLocked &&
        !isCustomPriceModel &&
        !isPoTOfferingType &&
        isOpen &&
        isLastRow &&
        !isOneTimePrePaidCreditProductRate &&
        !isMinCommitProductRate &&
        !isReadOnly && (
          <MFlex mt={2} pl={4} alignItems="center" {...addPriceTierProps}>
            {isUsageProductRate &&
              isLastRow &&
              getValues(`${fieldKeyPrefix}.${lastRowIndex}.to`) && (
                <MText mr="2">
                  {'The final tier must have infinity as the "Up to" value.'}
                </MText>
              )}
            <MButton
              size="sm"
              variant="tertiary"
              fontWeight="600"
              isDisabled={isLocked}
              onClick={() => {
                let newToValue = 1;
                if (isNumber(Number(watchedFrom)) && !isNaN(watchedFrom)) {
                  newToValue = Number(watchedFrom) + 1;
                }

                if (!watchedTo) {
                  setValue(`${fieldKeyPrefix}.${index}.to`, newToValue, {
                    shouldDirty: true,
                  });
                  if (productError?.to) {
                    clearErrors(`${errorKeyPrefix}.to`);
                  }
                }
                generateNewPrice(
                  parseInt(watchedFrom, 10),
                  parseInt(!watchedTo ? newToValue : watchedTo, 10),
                  watchedPriceModel,
                  watchedAmount,
                );
              }}
            >
              + Add Tier
            </MButton>
          </MFlex>
        )}
    </>
  );
};

export default RatePrice;
