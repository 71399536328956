import {
  IBillGroupResp,
  IBillGroupRespUI,
  IDunnings,
  IPaymentMethodResp,
} from '../types';
import { arrayToObject } from './misc';

export const buildBillGroupOptions = (billGroupResps: IBillGroupResp[]) => {
  const existingContractBillGroupIds: string[] = [];
  const noContractBillGroupIds: string[] = [];
  const billGroups = billGroupResps.map((bg: IBillGroupRespUI) => {
    const billGroup = bg;
    if (billGroup.activeContract) {
      billGroup.rightLabel = 'Existing Contract';
      existingContractBillGroupIds.push(billGroup.id!);
    } else {
      noContractBillGroupIds.push(billGroup.id!);
    }
    return billGroup;
  });
  return { billGroups, noContractBillGroupIds, existingContractBillGroupIds };
};

/**
 * Enrich list of Bill groups with related data so they can be easily displayed in the UI
 */
export const addContactsAndPaymentsToBillGroupList = (
  billGroups: IBillGroupResp[] = [],
  paymentMethods: IPaymentMethodResp[] = [],
  dunningProcesses: IDunnings[] = [],
): IBillGroupRespUI[] => {
  return (
    billGroups?.map((billGroup) =>
      addPaymentAndDunningProcessToBillGroup(
        billGroup,
        paymentMethods,
        dunningProcesses,
      ),
    ) || []
  );
};

/**
 * Enrich a of Bill groups with related data so it can be easily displayed in the UI
 */
export const addPaymentAndDunningProcessToBillGroup = (
  billGroup: IBillGroupResp,
  paymentMethods: IPaymentMethodResp[] = [],
  dunningProcesses: IDunnings[] = [],
): IBillGroupRespUI => {
  const paymentMethodsById = arrayToObject(paymentMethods || [], 'id');
  const dunningProcessesById = arrayToObject(dunningProcesses || [], 'id');
  return {
    ...billGroup,
    paymentMethod: billGroup.paymentMethodId
      ? paymentMethodsById[billGroup.paymentMethodId]
      : undefined,
    dunningProcess: billGroup.dunningProcessId
      ? dunningProcessesById[billGroup.dunningProcessId]
      : undefined,
  };
};
