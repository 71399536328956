import { FunctionComponent as FC, useState } from 'react';
import { IPaymentMethodResp } from '../../../types';
import {
  MBox,
  MButton,
  MCenterModal,
  MCustomRadioGroup,
  MGrid,
  MGridItem,
  MStack,
} from '../../Monetize';
import { ImportPaymentMethodModal } from './ImportPaymentMethodModal';
import { NewStripePaymentMethodModal } from './NewStripePaymentMethodModal';

interface AddPaymentMethodFormProps {
  isOpen: boolean;
  onClose: (paymentMethod?: IPaymentMethodResp) => void;
  accountId: string;
}

export const AddPaymentMethodForm: FC<AddPaymentMethodFormProps> = ({
  isOpen,
  onClose,
  accountId,
}: AddPaymentMethodFormProps) => {
  const [value, setValue] = useState<'import' | 'stripe' | ''>('');
  const [valueConfirmed, setValueConfirmed] = useState(false);

  const OPTIONS = [
    {
      title: 'Add New Payment Method',
      value: 'stripe',
      description: 'For new customers',
    },
    {
      title: 'Import Payment Method',
      value: 'import',
      description: 'For customers on Stripe with existing payment methods',
    },
  ];

  const onContinue = () => {
    setValueConfirmed(true);
  };

  return (
    <>
      {!valueConfirmed && (
        <MCenterModal
          isOpen={isOpen}
          onClose={onClose}
          modalTitle="Add Payment Method"
          size="md"
          modalContentProps={{ 'data-testid': 'add-method-form' } as any}
          renderFooter={() => (
            <MStack
              spacing={4}
              direction="row"
              align="center"
              justify="right"
              flex={1}
            >
              <MButton onClick={() => onClose()} variant="cancel" minW="auto">
                Cancel
              </MButton>
              <MButton
                variant="primary"
                isDisabled={!value}
                onClick={onContinue}
                minW="auto"
              >
                Continue
              </MButton>
            </MStack>
          )}
        >
          <MBox>
            <MGrid templateColumns="repeat(12, 1fr)" gap={4} mb={3}>
              <MGridItem colSpan={12}>
                <MCustomRadioGroup
                  value={value}
                  onChange={(val) => setValue(val as any)}
                  itemDescription="description"
                  items={OPTIONS}
                />
              </MGridItem>
            </MGrid>
          </MBox>
        </MCenterModal>
      )}
      {valueConfirmed && value === 'import' && (
        <ImportPaymentMethodModal
          isOpen={isOpen}
          onClose={onClose}
          accountId={accountId}
        />
      )}
      {valueConfirmed && value === 'stripe' && (
        <NewStripePaymentMethodModal
          isOpen={isOpen}
          onClose={onClose}
          accountId={accountId}
        />
      )}
    </>
  );
};
