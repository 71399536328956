import { NumberInputProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ONE_TIME_PRODUCT_TYPES, ProductTypeEnum } from '@monetize/types/app';
import { Maybe } from '@monetize/types/common';
import { formatCurrency, roundNumberToDecimal } from '@monetize/utils/core';
import isNaN from 'lodash/isNaN';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { getLongCodeForUnitPriceFrequency } from '../../utils/quotes';
import {
  MBox,
  MFlex,
  MFormLabel,
  MHStack,
  MRadio,
  MRadioGroup,
  MStack,
} from './chakra';
import MButton from './MButton';
import MCenterModal from './MCenterModal';
import { MCurrencyInput } from './MCurrencyInput';
import MFormField from './MFormField';
import MText from './MText';

export interface DiscountTargetPriceModalAmountOptions {
  amountWithoutDiscount: number;
  billingFrequencyInMonths: number;
  contractLength: number;
  currency: string;
  discountPercent: number;
  offeringPeriod: string;
  productName: string;
  targetAmount: number;
  productType: ProductTypeEnum;
  /**
   * Used for PPU target amount
   */
  unitPrice: number;
  // This is the display price before discount
  displayUnitPrice: number;
  displayUnitPriceFrequency?: Maybe<number>;
}

export interface DiscountTargetPriceModalProps extends NumberInputProps {
  targetAmountOptions: DiscountTargetPriceModalAmountOptions;
  isOpen: boolean;
  onClose: () => void;
  onChangeValue?: (value: number) => void;
}

export const SET_TARGET_VALUE_DISCOUNT_TYPE_OPTION = {
  title: 'Target Amount',
  value: 'SET_TARGET_VALUE',
};

export const DiscountSetTargetModalSchema = z.object({
  scope: z.enum(['UNIT_PRICE', 'TOTAL_AMOUNT']),
  unitPrice: z.number().min(1),
  amount: z.number().min(1),
});

export type DiscountSetTargetModal = z.infer<
  typeof DiscountSetTargetModalSchema
>;

const DiscountTargetPriceModal = React.forwardRef<
  any,
  DiscountTargetPriceModalProps
>(
  (
    {
      targetAmountOptions: {
        amountWithoutDiscount,
        billingFrequencyInMonths,
        contractLength,
        currency,
        discountPercent,
        offeringPeriod,
        productName,
        targetAmount,
        productType,
        unitPrice,
        displayUnitPrice,
        displayUnitPriceFrequency,
      },
      isOpen,
      onClose,
      onChangeValue,
    }: DiscountTargetPriceModalProps,
    ref: any,
  ) => {
    const showTargetAmountWarning =
      contractLength > (displayUnitPriceFrequency || billingFrequencyInMonths);

    const priceFrequency = useMemo(() => {
      return `per ${getLongCodeForUnitPriceFrequency(
        displayUnitPriceFrequency || billingFrequencyInMonths,
      )}`;
    }, [billingFrequencyInMonths, displayUnitPriceFrequency]);

    const {
      handleSubmit,
      control,
      formState: { errors, isValid },
      reset,
      watch,
      setValue,
    } = useForm<DiscountSetTargetModal>({
      resolver: zodResolver(
        DiscountSetTargetModalSchema.extend({
          amount: z.union([z.string(), z.number()]).transform((value, ctx) => {
            const floatNum = parseFloat(value.toString());
            if (isNaN(floatNum)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Enter a number',
              });
              return z.NEVER;
            }

            if (floatNum < 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Number must be greater than or equal to 0',
              });
            }
            if (floatNum > amountWithoutDiscount) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Target Amount must be less than or equal to un-discounted Amount of ${formatCurrency(
                  amountWithoutDiscount.toFixed(2),
                  { currency },
                )}`,
              });
            }
            return floatNum;
          }),
          unitPrice: z
            .union([z.string(), z.number()])
            .transform((value, ctx) => {
              const floatNum = parseFloat(value.toString());
              if (isNaN(floatNum)) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Enter a number',
                });
                return z.NEVER;
              }
              if (floatNum < 0) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Number must be greater than or equal to 0',
                });
              }
              if (floatNum > displayUnitPrice) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `Price Per Unit must be less than or equal to un-discounted Amount of ${formatCurrency(
                    displayUnitPrice.toFixed(2),
                    { currency },
                  )}`,
                });
              }
              return floatNum;
            }),
        }),
      ),
      mode: 'onChange',
      defaultValues: {
        scope: 'TOTAL_AMOUNT',
        amount: roundNumberToDecimal(targetAmount),
        unitPrice: roundNumberToDecimal(
          displayUnitPrice * (1 - discountPercent / 100),
        ),
      },
    });

    useEffect(() => {
      if (isOpen) {
        reset({
          scope: 'TOTAL_AMOUNT',
          amount: roundNumberToDecimal(targetAmount),
          unitPrice: roundNumberToDecimal(
            displayUnitPrice * (1 - discountPercent / 100),
          ),
        });
        setDiscount(discountPercent);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, reset]);

    const [discount, setDiscount] = useState(0);
    const watchScope = watch('scope');

    function calculateAmount(
      newValue: number,
      scope: DiscountSetTargetModal['scope'],
    ) {
      let newDiscount = 0;
      if (scope === 'TOTAL_AMOUNT') {
        if (newValue && newValue > 0 && newValue < amountWithoutDiscount) {
          newDiscount =
            ((amountWithoutDiscount - newValue) / amountWithoutDiscount) * 100;
        }
        if (newValue === amountWithoutDiscount) {
          newDiscount = 0;
        }
        setValue(
          'unitPrice',
          roundNumberToDecimal(displayUnitPrice * (1 - newDiscount / 100)),
        );
      } else {
        if (newValue && unitPrice > 0 && newValue < displayUnitPrice) {
          newDiscount =
            ((displayUnitPrice - newValue) / displayUnitPrice) * 100;
        }
        if (newValue === displayUnitPrice) {
          newDiscount = 0;
        }
        setValue(
          'amount',
          roundNumberToDecimal(amountWithoutDiscount * (1 - newDiscount / 100)),
        );
      }
      setDiscount(newDiscount);
    }

    const onSubmit = async (requestData: DiscountSetTargetModal) => {
      try {
        onChangeValue && onChangeValue(discount);
        onClose();
      } catch (err) {
        // handleApiErrorToast(err);
      }
    };

    if (!productName) {
      return null;
    }

    return (
      <MCenterModal
        size="lg"
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={'Set Target Amount'}
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton onClick={onClose} variant="cancel" minW="auto">
              Cancel
            </MButton>
            <MButton
              variant="primary"
              onClick={handleSubmit(onSubmit)}
              isDisabled={!isValid}
              type="submit"
              minW="auto"
            >
              Save
            </MButton>
          </MStack>
        )}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MFlex flexDir="column" gap={4}>
            <MText>
              Set the amount to be charged for {offeringPeriod}. <br /> The
              discount will be automatically calculated.
            </MText>

            <MBox>
              <MFormLabel>Product Name</MFormLabel>
              <MText>{productName}</MText>
            </MBox>

            <Controller
              control={control}
              name="scope"
              render={({ field: { value, onChange, ...rest } }) => (
                <MRadioGroup onChange={onChange} value={value} {...rest}>
                  <MStack
                    spacing={4}
                    direction="column"
                    color="tGray.darkPurple"
                  >
                    <MRadio value="TOTAL_AMOUNT">
                      <MText display="inline-flex" alignItems="center">
                        Target Total Amount
                      </MText>
                    </MRadio>
                    <MRadio value="UNIT_PRICE">Target Price Per Unit</MRadio>
                  </MStack>
                </MRadioGroup>
              )}
            />

            <MHStack alignItems="flex-start" gap={6}>
              {watchScope === 'UNIT_PRICE' && (
                <MFormField
                  label="Target Price Per Unit"
                  maxWidth={'135px'}
                  isInvalid={!!errors.unitPrice?.message}
                >
                  <Controller
                    name="unitPrice"
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                      <MCurrencyInput
                        inputMode="decimal"
                        precision={2}
                        onChange={(valueAsString, valueAsNumber) => {
                          if (valueAsNumber > targetAmount) {
                            setDiscount(0.0);
                          }
                          onChange(valueAsString);
                          calculateAmount(valueAsNumber, 'UNIT_PRICE');
                        }}
                        {...rest}
                      />
                    )}
                  />
                  {!ONE_TIME_PRODUCT_TYPES.has(productType) && (
                    <MText>{priceFrequency}</MText>
                  )}
                </MFormField>
              )}
              {watchScope === 'TOTAL_AMOUNT' && (
                <MFormField
                  label="Target Amount"
                  maxWidth={'120px'}
                  isInvalid={!!errors.amount?.message}
                >
                  <Controller
                    name="amount"
                    control={control}
                    render={({ field: { onChange, ...rest } }) => (
                      <MCurrencyInput
                        inputMode="decimal"
                        precision={2}
                        onChange={(
                          valueAsString: string,
                          valueAsNumber: number,
                        ) => {
                          if (valueAsNumber > targetAmount) {
                            setDiscount(0.0);
                          }
                          onChange(valueAsString);
                          calculateAmount(valueAsNumber, 'TOTAL_AMOUNT');
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              )}
              <MFormField label="Discount">
                <MText mt={1}>{discount.toFixed(2)}%</MText>
              </MFormField>
            </MHStack>
            <MBox minH="42px" mt={-2}>
              <MText color="tRed.base" fontSize="sm">
                {watchScope === 'TOTAL_AMOUNT' && errors?.amount?.message}
              </MText>
              <MText color="tRed.base" fontSize="sm">
                {watchScope === 'UNIT_PRICE' && errors?.unitPrice?.message}
              </MText>
              {showTargetAmountWarning && (
                <MText color="tOrange.tangerine" fontSize="sm">
                  Upon Save, the Target Amount may be adjusted slightly to
                  create a billing schedule with equal payments.
                </MText>
              )}
            </MBox>
          </MFlex>
        </form>
      </MCenterModal>
    );
  },
);

export default DiscountTargetPriceModal;
