import { ButtonProps, FlexboxProps, TextProps } from '@chakra-ui/react';
import { MButton, MFlex, MText } from '../../../../components/Monetize';

interface SectionHeaderProps extends FlexboxProps {
  label: string;
  textProps?: TextProps;
  editButtonProps?: ButtonProps;
  saveButtonProps?: ButtonProps;
  isEditMode?: boolean;
  isLoading?: boolean;
  canEdit?: boolean;
  onSave?: () => void;
  onEdit?: () => void;
}
export const SectionHeader = ({
  label,
  onSave,
  onEdit,
  textProps,
  editButtonProps,
  saveButtonProps,
  isEditMode,
  isLoading,
  canEdit,
  ...rest
}: SectionHeaderProps) => {
  return (
    <MFlex justify="space-between" align="center" my="6" {...rest}>
      <MText fontSize="lg" fontWeight="600" {...textProps}>
        {label}
      </MText>

      {canEdit && (
        <MFlex gap={4}>
          {onEdit && (
            <MButton
              minW={20}
              size="sm"
              isDisabled={isLoading}
              variant={'secondary'}
              onClick={onEdit}
              {...editButtonProps}
            >
              {isEditMode ? 'Cancel' : 'Edit'}
            </MButton>
          )}
          {onSave && isEditMode && (
            <MButton
              minW={20}
              size="sm"
              isDisabled={isLoading}
              isLoading={isLoading}
              variant={'primary'}
              onClick={onSave}
              {...saveButtonProps}
            >
              Save
            </MButton>
          )}
        </MFlex>
      )}
    </MFlex>
  );
};
