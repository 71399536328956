import { ToggleState } from '@monetize/types/app';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';
import { MButton, MIcon } from '../../../../components/Monetize';

interface QuotePDFCustomizationToggleProps {
  state: ToggleState;
  onClick: (state: ToggleState) => void;
}

export const QuotePDFCustomizationToggle = ({
  state,
  onClick,
}: Readonly<QuotePDFCustomizationToggleProps>) => {
  const isOpen = state === 'OPEN';
  const Icon = isOpen ? BiArrowToRight : BiArrowToLeft;

  return (
    <MButton
      variant="tertiary"
      size="sm"
      onClick={() => onClick(isOpen ? ToggleState.CLOSED : ToggleState.OPEN)}
    >
      Customize Settings
      <MIcon ml={2} boxSize={5} as={Icon} />
    </MButton>
  );
};
