import { RefObject, useEffect } from 'react';
import PreviewPdfDrawer from '../../../../components/Monetize/PreviewPdfDrawer/PreviewPdfDrawer';
import { QuoteWithPdfFields } from '../../../../types';
import { useQuotePdfDrawer } from '../hooks/useQuotePdfDrawer';

interface PreviewQuotePdfDrawerWrapperProps {
  quote: QuoteWithPdfFields;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCloseFocusRef?: RefObject<any>;
  onClose: () => void;
}

/**
 * Wrapper for PDF drawer with additional logic
 */
export const PreviewQuotePdfDrawerWrapper = ({
  quote,
  onCloseFocusRef,
  onClose,
}: PreviewQuotePdfDrawerWrapperProps) => {
  const {
    tabs,
    filename,
    highlightTextPattern,
    defaultTab,
    fetchDocument,
    resetTabs,
  } = useQuotePdfDrawer(quote);

  useEffect(() => {
    resetTabs();
  }, [resetTabs]);

  return (
    <PreviewPdfDrawer
      filename={filename}
      highlightTextPattern={highlightTextPattern}
      isOpen
      tabs={tabs}
      defaultTab={defaultTab}
      onCloseFocusRef={onCloseFocusRef}
      fetchDocument={fetchDocument}
      onClose={onClose}
    />
  );
};
