import { useCallback, useRef, useState } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { doPrintCreditToPdf } from '../../../../api/accountsService';
import { MBox, MCustomIconButton } from '../../../../components/Monetize';
import PreviewPdfDrawer from '../../../../components/Monetize/PreviewPdfDrawer/PreviewPdfDrawer';

/**
 * Allow user to click a button to open a drawer and view credit PDF documents
 */
export const PreviewCreditPdfDrawer = ({ creditId }: { creditId: string }) => {
  const buttonRef = useRef<typeof MCustomIconButton>(null);
  const [isOpen, setIsOpen] = useState(false);

  const fetchDocument = useCallback(async (): Promise<ArrayBuffer | null> => {
    const { data } = await doPrintCreditToPdf(creditId);
    return data;
  }, [creditId]);

  function handleOpen() {
    setIsOpen(true);
  }

  return (
    <MBox position="relative">
      <MCustomIconButton
        ref={buttonRef}
        btnSize={5}
        variant="icon"
        p={3}
        icon={AiOutlineFilePdf}
        iconColor="tPurple.base"
        onClick={handleOpen}
      />
      {isOpen && (
        <PreviewPdfDrawer
          filename={`credit-${creditId}`}
          isOpen={isOpen}
          onCloseFocusRef={buttonRef}
          fetchDocument={fetchDocument}
          onClose={() => setIsOpen(false)}
        />
      )}
    </MBox>
  );
};
