import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { MdContentCopy, MdContentPaste, MdFileCopy } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MAccordionItem,
  MAccordionPanel,
  MCustomIconButton,
  MCustomSelect,
  MFlex,
  MFormField,
  MHStack,
  MLockedTextOrContent,
  MTooltip,
} from '../../../../../components/Monetize';
import { OFFERINGS } from '../../../../../constants';
import {
  AggregationModelEnum,
  IPriceReqSchema,
  IProduct,
  OfferingTypesEnum,
  PriceModelEnum,
  ProductTypeEnum,
} from '../../../../../types';
import { getNextTierPrice } from '../../../../../utils/product';
import HeaderCellText from '../HeaderCellText';
import OfferingHeaderCell from '../OfferingHeaderCell';
import RateMinimumCommitConfig from './RateMinimumCommitConfig';
import RatePrice from './RatePrice';

interface RatePricesProps {
  product: IProduct;
  offeringType: OfferingTypesEnum;
  pricesLengthFromApi?: number;
  loading?: boolean;
  isLocked?: boolean;
  isLastRow?: boolean;
  currency: string;
  isExistingRate?: boolean;
  isReadOnly?: boolean;
  pricesToCopyFromProductId?: string;
  isMultipleProduct?: boolean;
  isProductExpanded: boolean;
  setPricesTopCopyFromProductId: (id: string) => void;
}
const RatePrices = React.forwardRef<HTMLInputElement | null, RatePricesProps>(
  (
    {
      loading,
      product,
      isLocked,
      isLastRow,
      currency,
      pricesLengthFromApi,
      isExistingRate = false,
      isReadOnly,
      offeringType,
      pricesToCopyFromProductId,
      isMultipleProduct = false,
      isProductExpanded,
      setPricesTopCopyFromProductId,
    }: RatePricesProps,
    ref: React.ForwardedRef<HTMLInputElement | null>,
  ) => {
    const {
      setValue,
      control,
      formState: { errors, dirtyFields },
      getValues,
      clearErrors,
    } = useFormContext();

    const aggregationModelError = (errors?.products as any)?.[product.id!]
      ?.aggregationModel;
    const isUsageTypeProduct = product.productType === ProductTypeEnum.USAGE;
    const isMinCommitProduct =
      product.productType === ProductTypeEnum.MIN_COMMIT;
    const aggregationModelFieldKey = `products.${product.id}.aggregationModel`;
    const isPoTOfferingType =
      offeringType === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL;

    const { fields, append, remove } = useFieldArray({
      control,
      name: `products.${product.id}.prices`,
      keyName: 'pId',
    });

    const handleRemovePrice = (index: number) => {
      // Make sure to clear any errors while removing a price tier
      const priceErrors = (errors?.products as Record<string, any>)?.[
        product.id!
      ]?.prices;

      if (priceErrors) {
        clearErrors(`products.${product.id}.prices`);
      }

      const rowToRemove = getValues(
        `products.${product.id}.prices.${index}`,
      ) as IPriceReqSchema;

      if (index !== fields.length - 1) {
        /** adjust next row from value with current row from value */
        setValue(
          `products.${product.id}.prices.${index + 1}.from`,
          rowToRemove.from,
        );
      }

      remove(index);
    };

    const handlePastePricesFromProductId = () => {
      if (pricesToCopyFromProductId) {
        const prices = getValues(
          `products.${pricesToCopyFromProductId}.prices`,
        );
        const currentProductPrices = getValues(`products.${product.id}.prices`);
        if (prices && Array.isArray(prices)) {
          const preparedPricesToPaste = prices.map(
            ({ productId, ...rest }: IPriceReqSchema, index: number) => {
              const amount = currentProductPrices?.[index]?.amount
                ? currentProductPrices[index].amount
                : '';
              if (isUsageTypeProduct && index === 0) {
                return {
                  ...rest,
                  from: rest.from !== 0 ? 0 : rest.from,
                  productId: product.id,
                  amount,
                };
              }

              if (isUsageTypeProduct && index === prices.length - 1) {
                return {
                  ...rest,
                  to: rest.to !== null ? null : rest.to,
                  productId: product.id,
                  amount,
                };
              }

              return {
                ...rest,
                productId: product.id,
                amount,
              };
            },
          );
          setValue(`products.${product.id}.prices`, preparedPricesToPaste);
        }
      }
    };

    const handleCopyPricesFromProductId = () => {
      if (product.id) {
        setPricesTopCopyFromProductId(product.id);
      }
    };

    useEffect(() => {
      if (fields.length === 0) {
        if (isPoTOfferingType) {
          const uid = uuidv4();
          append({
            id: uid,
            productId: product.id,
            description: '',
            priceModel: PriceModelEnum.PERCENT_OF_TOTAL,
            from: 1,
            to: 1,
            amount: 1,
          });
        } else {
          append(
            getNextTierPrice({
              productId: product.id!,
              previousFrom: null,
              previousTo: null,
              previousAmount: null,
            }),
          );
        }
      }
    }, [append, fields.length, isPoTOfferingType, product.id]);

    return (
      isProductExpanded && (
        <MAccordion
          variant="default"
          allowToggle
          defaultIndex={fields.length <= 25 ? 0 : -1}
        >
          <MAccordionItem
            w="100%"
            border="0"
            data-testid="product-under-rate-form"
          >
            {({ isExpanded }) => {
              return (
                <MFlex flexDir="column" py={2}>
                  <MFlex
                    alignItems="center"
                    maxW={isExpanded ? '44.125rem' : '100%'}
                    justify="space-between"
                    pt={0}
                    pb={0}
                  >
                    <MAccordionCustomButton // product price accordion
                      buttonProps={{
                        paddingLeft: 0,
                        paddingBottom: '0',
                        _hover: {
                          bg: 'white',
                        },
                      }}
                      labelProps={{
                        fontSize: 'sm',
                        paddingBottom: '0',
                      }}
                      isExpanded={isExpanded}
                      label={product.name || ''}
                      data-testid="toggle-product-icon"
                    />

                    {!isExpanded && (
                      <MAccordionCustomButtonItem
                        justifySelf="flex-end"
                        paddingRight="20"
                        pb={2}
                        labelTextProps={{ fontSize: 'sm' }}
                        label="Tiers"
                        valueTextProps={{ color: 'tPurple.safety' }}
                        value={fields.length}
                      />
                    )}
                    <MFlex alignItems="center">
                      {isExpanded && isMinCommitProduct && (
                        <RateMinimumCommitConfig
                          product={product}
                          isLoading={loading}
                          isLocked={isLocked || isReadOnly}
                          isExistingRate={isExistingRate}
                        />
                      )}

                      {isExpanded && isUsageTypeProduct && (
                        <MFormField
                          isHorizontal
                          isRequired={!isLocked}
                          label="Aggregation Model"
                          error={aggregationModelError}
                          justifyContent="flex-end"
                          w="initial"
                          mr="4"
                        >
                          <MLockedTextOrContent
                            isLocked={Boolean(isLocked)}
                            text={
                              OFFERINGS.AGGREGATION_MODEL_OPTIONS[
                                getValues(
                                  aggregationModelFieldKey,
                                ) as AggregationModelEnum
                              ]
                            }
                          >
                            <Controller
                              name={aggregationModelFieldKey}
                              defaultValue={AggregationModelEnum.SUM}
                              control={control}
                              render={({ field }) => (
                                <MCustomSelect
                                  w="32"
                                  isDisabled={loading}
                                  isReadOnly={isReadOnly}
                                  bgColor="tWhite.base"
                                  placeholder="Select"
                                  items={OFFERINGS.AGGREGATION_MODEL_UI_OPTIONS}
                                  variant={
                                    isReadOnly
                                      ? 'readonly'
                                      : !!product.id &&
                                          dirtyFields?.products?.[product.id]
                                            ?.aggregationModel
                                        ? 'unsaved'
                                        : 'primary'
                                  }
                                  {...field}
                                />
                              )}
                            />
                          </MLockedTextOrContent>
                        </MFormField>
                      )}

                      {isMultipleProduct &&
                        !isLocked &&
                        !isMinCommitProduct &&
                        isExpanded && (
                          <MFlex minW="14">
                            <MTooltip
                              label={'Copy Pricing Model, Up to and From'}
                              placement="bottom-start"
                              isDisabled={
                                pricesToCopyFromProductId === product.id
                              }
                            >
                              <MCustomIconButton
                                containerSize={7}
                                btnSize={4}
                                variant="icon"
                                icon={
                                  pricesToCopyFromProductId === product.id
                                    ? MdFileCopy
                                    : MdContentCopy
                                }
                                iconColor="tPurple.base"
                                onClick={handleCopyPricesFromProductId}
                              />
                            </MTooltip>
                            {!!pricesToCopyFromProductId &&
                              pricesToCopyFromProductId !== product.id && (
                                <MTooltip
                                  label={'Paste Pricing Model, Up to and From'}
                                  placement="bottom-start"
                                  isDisabled={
                                    pricesToCopyFromProductId === product.id
                                  }
                                >
                                  <MCustomIconButton
                                    containerSize={7}
                                    btnSize={4}
                                    variant="icon"
                                    icon={MdContentPaste}
                                    iconColor="tPurple.base"
                                    onClick={handlePastePricesFromProductId}
                                  />
                                </MTooltip>
                              )}
                          </MFlex>
                        )}
                    </MFlex>
                  </MFlex>

                  {isExpanded && (
                    <MAccordionPanel px={6} pt={0} pr={0} pb={2}>
                      <MHStack spacing={4} mb="2">
                        <OfferingHeaderCell w={4} />
                        <OfferingHeaderCell w="12.625rem">
                          <HeaderCellText isRequired={!isLocked}>
                            Pricing Model
                          </HeaderCellText>
                        </OfferingHeaderCell>
                        <OfferingHeaderCell w="6.25rem">
                          <HeaderCellText isRequired={!isLocked}>
                            From
                          </HeaderCellText>
                        </OfferingHeaderCell>
                        <OfferingHeaderCell w="6.25rem">
                          <HeaderCellText>Up to</HeaderCellText>
                        </OfferingHeaderCell>
                        <OfferingHeaderCell w="32">
                          <HeaderCellText
                            isRequired={!isLocked}
                            tooltipLabel={
                              isPoTOfferingType &&
                              'Price determined on Quote based on this percentage applied to all or specific Products'
                            }
                          >
                            {isPoTOfferingType ? 'Percentage' : 'Amount'}
                          </HeaderCellText>
                        </OfferingHeaderCell>
                        <OfferingHeaderCell
                          pl="3"
                          w={isPoTOfferingType ? 'auto' : '8'}
                        >
                          {isPoTOfferingType && (
                            <HeaderCellText textProps={{ marginBottom: 0.5 }}>
                              Scoped Products
                            </HeaderCellText>
                          )}
                        </OfferingHeaderCell>
                      </MHStack>

                      {fields.map(
                        (field, i) =>
                          product.id && (
                            <RatePrice
                              index={i}
                              key={field.pId}
                              id={field.pId}
                              isOpen={isExpanded}
                              product={product}
                              isLastRow={fields.length - 1 === i}
                              lastRowIndex={fields.length - 1}
                              offeringType={offeringType}
                              handleRemovePrice={handleRemovePrice}
                              generateNewPrice={(
                                previousFrom,
                                previousTo,
                                previousPriceModel,
                                previousAmount,
                              ) =>
                                append(
                                  getNextTierPrice({
                                    productId: product.id!,
                                    previousFrom,
                                    previousTo,
                                    previousPriceModel,
                                    previousAmount,
                                  }),
                                )
                              }
                              isLocked={isLocked}
                              loading={loading}
                              addPriceTierProps={{
                                ml: 4,
                              }}
                              currency={currency}
                              pricesLengthFromApi={pricesLengthFromApi}
                              isReadOnly={isReadOnly}
                            />
                          ),
                      )}
                    </MAccordionPanel>
                  )}
                </MFlex>
              );
            }}
          </MAccordionItem>
        </MAccordion>
      )
    );
  },
);

export default RatePrices;
