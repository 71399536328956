import { useEffect, useState } from 'react';
import { useGetIntegrationLogInfiniteLoad } from '../api/settingsService';
import { composeFiltersQuery } from '../api/utils';
import { FilterType, FilterTypeOperator, GetListApiFilter } from '../types';
import { buildFilterParamsRequestObject } from '../utils';

export function useIntegrationLogData(limit = 25) {
  const [filters, setFilters] = useState<FilterType[]>([
    {
      key: 'status',
      value: ['Failed'],
      operator: FilterTypeOperator.IN,
    },
  ]);

  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters),
  );

  useEffect(() => {
    setFilterParams(buildFilterParamsRequestObject(filters));
  }, [filters]);

  const payload = {
    limit,
    ...composeFiltersQuery(filterParams),
  };

  const {
    data,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isError,
    fetchNextPage,
  } = useGetIntegrationLogInfiniteLoad(payload, {
    meta: { showErrorToast: true },
  });

  const logData = data?.pages.flatMap((page) => page.content) || [];

  return {
    filters,
    filterParams,
    logData,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    isError,
    setFilters,
    fetchNextPage,
  };
}
