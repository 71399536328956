import { z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { AddressSourceEnumZ } from './addressTypes';
import { MCustomNumberTypeRequired } from './miscTypes';
import { NetTermsEnumZ } from './netTermsType';
import { IOfferingRes } from './offeringTypes';

export const OneTimeOfferingProductRequestSchema = z.object({
  productId: z.string({ required_error: 'Product' }),
  productDescription: z.string({ required_error: 'Product' }),
  quantity: MCustomNumberTypeRequired(getRequiredMessage('Quantity')),
  unitPrice: MCustomNumberTypeRequired(getRequiredMessage('Price Per Unit')),
  isMandatory: z.boolean(),
  isSelected: z.boolean().nullish(),
});

export type IOneTimeOfferingProductRequestSchema = z.infer<
  typeof OneTimeOfferingProductRequestSchema
>;

export const OneTimeOfferingRequestSchema = z.object({
  offeringId: z.string({
    required_error: getRequiredMessage('Offering'),
  }),
  offeringDescription: z.string({
    required_error: getRequiredMessage('Offering'),
  }),
  startDate: z.string({
    required_error: getRequiredMessage('Start Date'),
  }),
  endDate: z.string().nullish(),
  products: z.array(OneTimeOfferingProductRequestSchema),
  /** UI only data point to store additional form state - never validated for form validity */
  metadata: z.any().nullish(),
});

export type IOneTimeOfferingRequestSchema = z.infer<
  typeof OneTimeOfferingRequestSchema
>;

export const OneTimeInvoiceFormSchema = z.object({
  billingContactId: z.string({
    required_error: getRequiredMessage('Billing Contact'),
  }),
  shippingContactId: z.string({
    required_error: getRequiredMessage('Shipping Contact'),
  }),
  netTerms: NetTermsEnumZ,
  accountId: z.string({ required_error: getRequiredMessage('Account') }),
  currency: z.string({ required_error: getRequiredMessage('Currency') }),
  legalEntityId: z.string({
    required_error: getRequiredMessage('Legal Entity'),
  }),
  addressInformation: z.object({
    addressSource: AddressSourceEnumZ,
    // FIXME: should be discriminated union based on addressSource these are required in ACCOUNT
    billingAddressId: z.string().nullish(),
    shippingAddressId: z.string().nullish(),
  }),
  billGroupId: z.string({ required_error: getRequiredMessage('Bill Group') }),
  purchaseOrderNumber: z.string().nullish(),
  vatNumber: z.string().nullish(),
  registrationNumber: z.string().nullish(),
  customFields: z.record(z.any()).nullish(),
  offerings: z
    .array(OneTimeOfferingRequestSchema)
    .nonempty('At least one offering is required'),
});

export type IOneTimeInvoiceFormSchema = z.infer<
  typeof OneTimeInvoiceFormSchema
>;

export interface IOneTimeOfferingRequestSchemaUi
  extends IOneTimeOfferingRequestSchema {
  metadata: {
    loading: boolean;
    offering?: IOfferingRes | null;
  };
}

/**
 * The form state has additional data to store the offering and products so
 * that it does not need to be passed around to child components and so that
 * the loading state can be stored with each row.
 */
export interface IOneTimeInvoiceFormSchemaWithOfferings
  extends Omit<IOneTimeInvoiceFormSchema, 'offerings'> {
  offerings: Array<IOneTimeOfferingRequestSchemaUi>;
}

const InvoiceItemsRequest = z
  .array(
    z.object({
      offeringId: z.string({
        required_error: getRequiredMessage('Offering'),
      }),
      offeringDescription: z.string({
        required_error: getRequiredMessage('Offering'),
      }),
      startDate: z.string({
        required_error: getRequiredMessage('Start Date'),
      }),
      endDate: z.string().nullish(),
      productId: z.string({ required_error: 'Product' }),
      productDescription: z.string({ required_error: 'Product' }),
      quantity: MCustomNumberTypeRequired(getRequiredMessage('Quantity')),
      unitPrice: MCustomNumberTypeRequired(
        getRequiredMessage('Price Per Unit'),
      ),
      position: z.number(),
    }),
  )
  .nonempty('At least one offering is required');

export type IInvoiceItemsRequest = z.infer<typeof InvoiceItemsRequest>;

export const OneTimeInvoiceRequestSchema = OneTimeInvoiceFormSchema.omit({
  offerings: true,
}).extend({
  invoiceItems: InvoiceItemsRequest,
});

export type IOneTimeInvoiceRequestSchema = z.infer<
  typeof OneTimeInvoiceRequestSchema
>;
