import { useEffect } from 'react';
import {
  useConfigurePaymentCollectAutoConfig,
  useGetPaymentCollectAutoConfig,
} from '../../../api/settingsService';
import {
  MPageContainer,
  MSettingsPageHeader,
  MVStack,
} from '../../../components/Monetize';
import { useDocumentHead } from '../../../services/documentHead';
import { useFlags } from '../../../services/launchDarkly';
import { SettingsToggle } from '../Quoting/QuoteSettings/components/SettingsToggle';

export const Payments = () => {
  const { setDocTitle } = useDocumentHead();
  const { allowPartialPayment } = useFlags();
  const { data: paymentCollectConfig, isLoading: fetchLoading } =
    useGetPaymentCollectAutoConfig();
  const { mutateAsync: updatePaymentCollectAutoConfig } =
    useConfigurePaymentCollectAutoConfig();

  useEffect(() => {
    setDocTitle('Settings', 'Payments');
  }, []);

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Payments" />

      <MVStack maxW="600px" spacing={4}>
        <SettingsToggle
          name="collectAutomatically"
          heading="Collect Automatically"
          subheading="This will set the default for the payment collection behavior for new Bill Groups"
          fetchLoading={fetchLoading}
          value={paymentCollectConfig?.collectAutomatically!}
          onChange={(collectAutomatically) =>
            updatePaymentCollectAutoConfig({
              ...paymentCollectConfig!,
              collectAutomatically,
            })
          }
        />

        {allowPartialPayment && (
          <SettingsToggle
            name="allowPartialPayment"
            heading="Allow Partial Payments"
            subheading="Allow customers to make partial payments for individual Invoices"
            fetchLoading={fetchLoading}
            value={paymentCollectConfig?.allowPartialPayment!}
            onChange={(allowPartialPayment) =>
              updatePaymentCollectAutoConfig({
                ...paymentCollectConfig!,
                allowPartialPayment,
              })
            }
          />
        )}
      </MVStack>
    </MPageContainer>
  );
};
