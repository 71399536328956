import { Link } from '@chakra-ui/react';
import { Fragment } from 'react/jsx-runtime';
import { MBox, MHeading, MText } from '../Monetize';
import { SearchResultV2 } from './AppSupport.types';

interface AppDocsResultItemProps {
  item: SearchResultV2;
}

const SearchResultText = ({
  value,
  type,
}: {
  value: string;
  type: 'hit' | 'text';
}) => {
  if (type === 'hit') {
    return (
      <MBox key={value} bg="tYellow.base" as="span">
        {value}
      </MBox>
    );
  }
  return <Fragment key={value}>{value}</Fragment>;
};

export const AppDocsResultItem = ({ item }: AppDocsResultItemProps) => {
  const { url, highlights, title } = item;
  const titleContent =
    highlights
      .find((highlight) => highlight.path === 'title')
      ?.texts.map(({ type, value }) => (
        <SearchResultText key={value} type={type} value={value} />
      )) ?? title;
  const content = highlights
    .filter((highlight) => highlight.path !== 'title')
    .flatMap(({ texts, path }) => (
      <MBox key={path} as="span">
        {texts.map(({ type, value }) => (
          <SearchResultText key={value} type={type} value={value} />
        ))}
      </MBox>
    ));

  return (
    <Link
      display="flex"
      flexDirection="column"
      href={url.full}
      target="_blank"
      p={2}
      mb={2}
      borderBottom="1px"
      borderBottomColor="tGray.back"
      _focus={{
        backgroundColor: 'tBlue.hover',
      }}
      _hover={{
        backgroundColor: 'tBlue.hover',
      }}
    >
      <MHeading as="h2" size="md" overflow="hidden">
        {titleContent}
      </MHeading>
      <MText color="tPurple.base" fontSize="sm">
        {content}
      </MText>
    </Link>
  );
};
