import { ApiListResponse } from '../types';
import { ILegalEntityResponseSchema } from '../types/legalEntityTypes';
import { arrayToObject } from '../utils/misc';

export type ILegalEntitiesSelector = {
  legalEntities: ILegalEntityResponseSchema[];
  defaultLegalEntity?: ILegalEntityResponseSchema;
  legalEntitiesById: Record<string, ILegalEntityResponseSchema>;
};

export const selectLegalEntities = ({
  content,
}: ApiListResponse<ILegalEntityResponseSchema>) => {
  const legalEntities = content.map((legalEntity) =>
    legalEntity.name
      ? legalEntity
      : { ...legalEntity, name: '[Your Company Name]' },
  );

  const legalEntitiesById = arrayToObject(legalEntities, 'id');

  return {
    legalEntities,
    defaultLegalEntity:
      legalEntities.find(({ defaultEntity }) => defaultEntity) ||
      legalEntities[0],
    legalEntitiesById,
  };
};
