import { MBox, MText, MTooltip } from '../../../../../components/Monetize';
import { QUOTES } from '../../../../../constants';
import {
  IRateResBaseSchema,
  RateBillingFrequencyEnum,
  RateTypeEnum,
} from '../../../../../types';

function getColors(rateType?: RateTypeEnum | null) {
  if (!rateType || rateType === RateTypeEnum.CATALOG) {
    return {
      borderColor: 'tGray.lightPurple',
      color: 'tPurple.dark',
    };
  }
  // For custom rates
  return {
    borderColor: 'tPurple.safety',
    color: 'tPurple.safety',
    bg: 'tPurple.inputBox',
  };
}

export const QuoteOfferingRateRightEl = ({
  rate,
}: {
  rate: IRateResBaseSchema;
}) => {
  let customFrequencyLabel = '';
  let customFrequencySymbol = '';
  const isCustomBillingFrequency =
    rate.billingFrequency === RateBillingFrequencyEnum.CUSTOM;
  const extraStyles = getColors(rate.rateType);

  if (rate.billingFrequencyInMonths) {
    if (rate.billingFrequencyInMonths % 12 === 0) {
      customFrequencyLabel = `Every ${
        rate.billingFrequencyInMonths / 12
      } Year(s)`;
      customFrequencySymbol = `${rate.billingFrequencyInMonths / 12}Y`;
    } else {
      customFrequencyLabel = `Every ${rate.billingFrequencyInMonths} Month(s)`;
      customFrequencySymbol = `${rate.billingFrequencyInMonths}`;
    }
  }
  const tooltip = (
    <>
      <MText color="inherit">
        Billed{' '}
        {isCustomBillingFrequency
          ? customFrequencyLabel
          : QUOTES.RATE_BILLING_FREQUENCY_DISPLAY[
              rate.billingFrequency
            ].toLowerCase()}
      </MText>
      {rate.rateType === RateTypeEnum.ACCOUNT && (
        <MText color="inherit">
          This rate has been customized for this account.
        </MText>
      )}
    </>
  );

  return (
    <MTooltip label={tooltip} placement="bottom-start">
      <MBox
        borderWidth="1px"
        p="2px"
        rounded="full"
        borderStyle="dashed"
        display="flex"
        alignItems="center"
        justifyContent="center"
        w="5"
        h="5"
        minW="5"
        minH="5"
        cursor="pointer"
        {...extraStyles}
      >
        {(rate.billingFrequency || rate.billingFrequencyInMonths) && (
          <MText
            fontSize="xxs"
            fontWeight="bold"
            color="inherit"
            textTransform="uppercase"
          >
            {isCustomBillingFrequency
              ? customFrequencySymbol
              : rate.billingFrequency.slice(0, 1)}
          </MText>
        )}
      </MBox>
    </MTooltip>
  );
};

export default QuoteOfferingRateRightEl;
