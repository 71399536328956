export const ApprovalRuleIcon = (props: any) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.45 8.03484L11.91 4.49484L13.32 3.08484L15.44 5.20484L19.68 0.964844L21.09 2.37484L15.45 8.03484ZM9.90997 4.03484H0.909973V6.03484H9.90997V4.03484ZM19.91 10.4448L18.5 9.03484L15.91 11.6248L13.32 9.03484L11.91 10.4448L14.5 13.0348L11.91 15.6248L13.32 17.0348L15.91 14.4448L18.5 17.0348L19.91 15.6248L17.32 13.0348L19.91 10.4448ZM9.90997 12.0348H0.909973V14.0348H9.90997V12.0348Z"
        fill="currentColor"
      />
    </svg>
  );
};
