import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect } from 'react';
import { MdSettings } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useAcceptInvite, useGetTenants } from '../../api/tenantService';
import { ROUTES } from '../../constants';
import { TenantCard } from '../../routes/Tenants/components/TenantCard';
import { TenantTabs } from '../../routes/Tenants/components/TenantTabs';
import { useAuth } from '../../services/auth0';
import { tempRedirectInfoState } from '../../store/global.store';
import {
  DEFAULT_PAGER,
  ITenant,
  TDataTablePager,
  UserRoleEnum,
} from '../../types';
import {
  MButton,
  MFlex,
  MIcon,
  MLink,
  MPageLoader,
  MText,
  MVStack,
} from '../Monetize';

const pager: TDataTablePager = {
  ...DEFAULT_PAGER,
  rows: 100000,
  sortField: 'name',
  sortOrder: 1,
};

interface AppTenantSwitchModalProps {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  persistentModal?: boolean;
  showLogout?: boolean;
  shouldSelectFirstOnlyTenant?: boolean;
}

const AppTenantSwitchModal: FC<AppTenantSwitchModalProps> = ({
  onClose,
  onOpen,
  isOpen,
  persistentModal,
  showLogout,
  shouldSelectFirstOnlyTenant,
}: AppTenantSwitchModalProps) => {
  const {
    tenantId,
    currentTenantUserHasSpecificRole,
    currentTenant,
    error: currentTenantError,
  } = useAuth();
  const [redirectInfo, setRedirectInfo] = useRecoilState(tempRedirectInfoState);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { mutateAsync: doAcceptInvite, isPending: isAcceptInviteLoading } =
    useAcceptInvite();
  const {
    data: tenants = [],
    isLoading,
    error: fetchTenantsError,
  } = useGetTenants({ config: pager });

  useEffect(() => {
    // If there is only 1 tenant, simply navigate to it.
    if (tenants && tenants.length === 1 && shouldSelectFirstOnlyTenant) {
      onSwitchTenant(tenants[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSelectFirstOnlyTenant, tenants]);

  const onSwitchTenant = (tenant: ITenant) => {
    if (tenant && tenant.id === tenantId) {
      onClose();
      if (location.pathname === ROUTES.ONBOARD_SELECT_TENANT) {
        navigate(ROUTES.DASHBOARD);
      }
    } else {
      onClose();

      // consume redirectInfo and set null
      setRedirectInfo(null);
      navigate(
        ROUTES.getSwitchTenantRoute(tenant.id, redirectInfo?.redirectUrl || ''),
      );
      // If tenant is modified, clear all query cache for all queries
      queryClient.clear();
    }
  };

  const onManageTenants = () => {
    navigate(ROUTES.TENANT_LIST);
    onClose();
  };

  return (
    <Modal
      blockScrollOnMount
      closeOnOverlayClick={!persistentModal}
      isCentered
      motionPreset="slideInBottom"
      size="md"
      variant="primary"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay style={{ opacity: 0.5 }} />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="600" py={3}>
          <MFlex justifyContent="space-between" alignItems="center">
            Switch Tenant
            <MFlex alignItems="center">
              {!fetchTenantsError &&
                !currentTenantError &&
                !showLogout &&
                currentTenantUserHasSpecificRole(UserRoleEnum.TENANT_ADMIN) && (
                  <MButton
                    variant="variantCancel"
                    onClick={onManageTenants}
                    data-testid="tenant-manage-btn"
                    size="sm"
                    fontWeight="normal"
                  >
                    <MIcon as={MdSettings} mr="1" boxSize={4} />
                    Manage
                  </MButton>
                )}
              {(showLogout || currentTenantError) && (
                <MLink
                  fontSize="sm"
                  as={Link}
                  to={ROUTES.AUTH_LOGOUT}
                  textDecor="underline"
                >
                  Log out
                </MLink>
              )}

              {!persistentModal && !currentTenantError && (
                <ModalCloseButton position="relative" top="0" right="0" />
              )}
            </MFlex>
          </MFlex>
        </ModalHeader>

        <ModalBody
          maxHeight="500px"
          overflow="auto"
          className="custom-scroll-bar-v1"
          pt={0}
          pb={4}
        >
          {!isLoading && (currentTenantError || fetchTenantsError) && (
            <>
              <MText fontSize="md" textAlign="center" fontWeight="600" mb="2">
                Error
              </MText>
              <MText textAlign="center">
                There was a problem to finding your tenants.
              </MText>
            </>
          )}
          {isLoading || isAcceptInviteLoading ? (
            <MPageLoader height="150px" />
          ) : (
            <>
              {!currentTenantError && currentTenant && (
                <MVStack align="stretch" pt={2} mb={4}>
                  <TenantCard // current tenant card
                    tenant={currentTenant}
                    isCurrentTenant
                    onClick={() => onSwitchTenant(currentTenant)}
                    onAccept={() => doAcceptInvite({ id: currentTenant.id })}
                  />
                </MVStack>
              )}

              {!fetchTenantsError && (
                <TenantTabs // test and live tenants
                  tenants={tenants}
                  onSwitchTenant={onSwitchTenant}
                  onAcceptInvite={({ id }) => doAcceptInvite({ id })}
                />
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AppTenantSwitchModal;
