import {
  Box,
  CloseButton,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverFooter,
  PopoverTrigger,
  ScaleFade,
  Spinner,
  Text,
  Tooltip,
  useBoolean,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import React, {
  FunctionComponent as FC,
  FormEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdSearch } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiDataMapKey, useGetListData } from '../../../api/queryUtils';
import { useDebounce } from '../../../hooks/useDebounce';
import inputTheme from '../../../styles/chakra/components/Input';
import { DEFAULT_PAGER, TDataTablePager } from '../../../types';
import { pluralize } from '../../../utils';
import { MBackButton } from '../MBackButton';
import { useCursor } from '../MCustomSelect/components/useCursor';
import { SearchedList } from './SearchedList';
import { useKeyPress } from './useKeyPress';

export type SearchedItemType = {
  id: string;
  customId: string;
  [key: string]: string;
  status: 'ACTIVE' | 'INACTIVE';
};
interface MSearchInputProps {
  apiKey: ApiDataMapKey;
  /** Query parameter to search by */
  searchKey: string;
  searchOperator?: 'contains' | 'equals';
  /** Field to use as the name property in the list, defaults to searchKey */
  nameField?: string;
  /** Sort Field to use, defaults to nameField */
  sortField?: string;
  value: string;
  popoverContentProps?: PopoverContentProps;
  searchTooltip?: string;
  placeholder?: string;
  /** If true, the input will not collapse to an icon */
  alwaysExpanded?: boolean;
  borderRadius?: InputProps['borderRadius'];
  size?: InputProps['size'];
  leftElementIcon?: number;
  border?: InputProps['border'];
  backgroundColor?: string;
  focusBackgroundColor?: string;
  clearable?: boolean;
  fontSize?: InputProps['fontSize'];
  focusColor?: string;
  borderTop?: InputProps['borderTop'];
  containerClicked?: boolean;
  generateRoute: (id: string) => string;
  onChange: (val: string, isSubmitted?: boolean) => void;
  renderViewHeader?: ({
    onToggle,
  }: {
    onToggle: () => void;
  }) => React.ReactNode;
  onReset?: () => void;
}

export const MSearchInput: FC<MSearchInputProps> = React.forwardRef<
  any,
  MSearchInputProps
>(
  (
    {
      value,
      apiKey,
      searchKey,
      searchOperator = 'contains',
      nameField = searchKey,
      sortField = nameField,
      searchTooltip = 'Search',
      placeholder = 'Search Accounts',
      popoverContentProps = {},
      alwaysExpanded = false,
      borderRadius = 4,
      size = 'sm',
      border,
      fontSize = 'sm',
      borderTop,
      backgroundColor = inputTheme.baseStyle?.field.bg,
      focusBackgroundColor = inputTheme.baseStyle?.field._focus.bg,
      clearable = false,
      focusColor = inputTheme.baseStyle?.field._focus.color,
      containerClicked = false,
      renderViewHeader,
      generateRoute,
      onChange,
      onReset,
    }: MSearchInputProps,
    ref,
  ) => {
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const itemRefs = useRef<{ [index: number]: HTMLAnchorElement }>({});
    const escapePressed = useKeyPress('Escape');
    const [searchParams, setSearchParams] = useSearchParams();

    const [pager] = useState<TDataTablePager>({
      ...DEFAULT_PAGER,
      sortField,
      sortOrder: 1,
    });
    const [internalValue, setInternalValue] = useState<string>(value);
    const debouncedValue = useDebounce(internalValue);
    const [isSubmitted, setIsSubmitted] = useBoolean(!!value);
    const { isOpen, onClose, onToggle } = useDisclosure({
      defaultIsOpen: !!value || !!alwaysExpanded,
      onOpen: () => {
        setCursor(-1);
        // Using timeout to make sure the animation is complete
        setTimeout(() => inputRef.current?.focus());
      },
    });
    const { data, isFetching } = useGetListData<SearchedItemType>(
      apiKey,
      {
        config: pager,
        filters: {
          [searchKey]:
            searchOperator === 'contains'
              ? { contains: debouncedValue }
              : debouncedValue,
        },
      },
      {
        enabled: isOpen && !!debouncedValue,
        refetchOnWindowFocus: false,
      },
    );

    // Keeps track of the searched list item current cursor index
    const { cursor, setCursor, setCursorAndScroll } = useCursor({
      isItemSelectable: () => true,
      items: data?.content || [],
      itemRefs,
      isDisabledItem: () => false,
      resultListRef: null,
    });

    useOutsideClick({
      ref: containerRef,
      handler: () => {
        if (isOpen && !internalValue) {
          !alwaysExpanded && onClose();
          setIsSubmitted.off();
          handleReset();
        }
        if (!isSubmitted && isOpen && !!internalValue) {
          !alwaysExpanded && onClose();
          setInternalValue('');
        }
      },
    });

    const onSubmit = (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      const isSingleItem: boolean = data?.totalElements === 1; // if single matched

      if (isSingleItem) {
        setIsSubmitted.on();
        navigate(generateRoute(data?.content[0].id!));
        handleReset();
        return;
      }
      setIsSubmitted.on();
      onChange(internalValue, true);
    };

    const handleReset = () => {
      onReset?.();
      setInternalValue('');
      onChange('');

      !alwaysExpanded && onToggle();
      if (searchParams.has('search')) {
        searchParams.delete('search');
        setSearchParams(searchParams);
      }
    };

    useEffect(() => {
      if (escapePressed && !isSubmitted && isOpen) {
        handleReset();
      }
    }, [escapePressed, isOpen, isSubmitted]);

    useEffect(() => {
      if (containerClicked) {
        inputRef.current?.focus();
      }
    }, [containerClicked]);

    const resultsTemplate =
      data?.totalElements && data.totalElements > 20
        ? `of ${data.totalElements}`
        : pluralize('result', data?.totalElements || 0);
    const isDataFound = !!data?.content.length;

    return (
      <>
        {!isOpen && !renderViewHeader && (
          <Tooltip label={searchTooltip} placement="bottom-end">
            <IconButton
              variant="icon"
              minW={8}
              aria-label={placeholder}
              icon={<Icon as={MdSearch} w={5} h={5} />}
              onClick={onToggle}
            />
          </Tooltip>
        )}
        {!isOpen && renderViewHeader?.({ onToggle })}
        {isOpen && (
          <Box
            tabIndex={0}
            w="100%"
            h="100%"
            ref={containerRef}
            position="relative"
            alignContent={'center'}
          >
            <WithOptionalScaleFade
              isOpen={isOpen}
              enableAnimation={!alwaysExpanded}
            >
              <form onSubmit={onSubmit}>
                <Flex align="center">
                  {renderViewHeader && <MBackButton onClick={handleReset} />}
                  <InputGroup
                    borderColor="tBlue.lightShade"
                    minWidth="21.25rem"
                    size={size}
                    alignItems="center"
                  >
                    <InputLeftElement height="100%">
                      <Icon as={MdSearch} color="tPurple.base" />
                    </InputLeftElement>
                    <Input
                      borderRadius={borderRadius}
                      size={size}
                      {...(border ? { border } : {})}
                      fontSize={fontSize}
                      value={internalValue}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        if (isSubmitted) {
                          setIsSubmitted.off();
                        }
                        setInternalValue(event.target.value);
                      }}
                      ref={inputRef}
                      backgroundColor={backgroundColor}
                      color="tPurple.base"
                      placeholder={placeholder}
                      _focus={{
                        ...inputTheme.baseStyle?.field._focus,
                        backgroundColor: focusBackgroundColor,
                        color: focusColor,
                      }}
                      onFocus={() => {
                        if (isSubmitted && debouncedValue) {
                          setIsSubmitted.off();
                        }
                      }}
                    />

                    <InputRightElement>
                      {clearable && !!internalValue && (
                        <CloseButton
                          size="sm"
                          color="tPurple.base"
                          onClick={handleReset}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              </form>
            </WithOptionalScaleFade>
            <Popover
              offset={[0, 0]}
              isOpen={isOpen && !!debouncedValue && !isSubmitted}
              onClose={() => {
                !alwaysExpanded && onClose();
              }}
              placement={'bottom-start'}
              autoFocus={false}
            >
              <PopoverTrigger>
                <Box />
              </PopoverTrigger>
              <PopoverContent
                maxW="40rem"
                minW="21.25rem" // minW is equal to inputSearch width
                width="fit-content"
                filter="drop-shadow(0px 4px 8px rgba(24, 36, 60, 0.15))"
                border="0"
                borderTop={borderTop}
                borderRadius={borderRadius}
                {...popoverContentProps}
              >
                <PopoverBody p={2}>
                  {!isFetching && !isDataFound && (
                    <Text textAlign="center">No search results found</Text>
                  )}
                  {isFetching && (
                    <Flex align="center" justify="center">
                      <Spinner />
                    </Flex>
                  )}
                  {data && (
                    <SearchedList
                      itemRefs={itemRefs}
                      debouncedValue={debouncedValue}
                      nameField={nameField}
                      isFetching={isFetching}
                      data={data}
                      cursor={cursor}
                      setCursorAndScroll={setCursorAndScroll}
                      handleReset={handleReset}
                      generateRoute={generateRoute}
                    />
                  )}
                </PopoverBody>
                {isDataFound && (
                  <PopoverFooter mx="0" py="2" px="4">
                    <Text
                      fontSize="xs"
                      fontWeight="400"
                      textAlign="center"
                    >{`Showing ${data?.content.length} ${resultsTemplate}`}</Text>
                  </PopoverFooter>
                )}
              </PopoverContent>
            </Popover>
          </Box>
        )}
      </>
    );
  },
);

const WithOptionalScaleFade = ({
  enableAnimation,
  isOpen,
  children,
}: {
  enableAnimation: boolean;
  isOpen: boolean;
  children: ReactNode;
}) => {
  if (enableAnimation) {
    return (
      <ScaleFade initialScale={0.9} in={isOpen}>
        {children}
      </ScaleFade>
    );
  }

  return <>{children}</>;
};
