import { useDisclosure } from '@chakra-ui/react';
import { ColumnProps } from 'primereact/column';
import React, { useState } from 'react';
import { MdLock } from 'react-icons/md';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MHStack,
  MIcon,
  MPageHeader,
  MPageSearchInput,
  shouldOpenInNewWindow,
} from '../../../components/Monetize';
import {
  MDataTableActions,
  MDataTableFilter,
} from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { ROUTES } from '../../../constants';
import {
  getBillGroupPageRoute,
  getSubscriptionNewRoute,
} from '../../../constants/routes';
import { SubscriptionBillingStatusDisplayText } from '../../../constants/subscriptions';
import {
  ISubscriptionWithBillGroup,
  useSubscriptions,
} from '../../../hooks/AccountDetail/useSubscriptions';
import { ACLCheck } from '../../../services/acl/ACLCheck';
import { useACL } from '../../../services/acl/acl';
import {
  FilterTypeOperator,
  IAccountRespSchema,
  IBillGroupResp,
  IGetSubscriptionSchema,
  IOfferingOnSubscription,
  SubscriptionBillingStatusEnum,
} from '../../../types';
import { getFiltersApplied, getIsTrulyEmptyList } from '../../../utils';
import { objectToObjArray } from '../../../utils/misc';
import {
  dateBodyTemplate,
  idBodyTemplate,
  nameWithIdBodyTemplate,
  nestedTextBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '../../../utils/tableUtils';
import { CancelSubscription } from './components/SubscriptionOverview/CancelSubscription';

export const SubscriptionList = ({
  account,
  billGroupId,
}: {
  account: IAccountRespSchema;
  billGroupId?: string;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id: accountId } = account;
  const [selectedRowData, setSelectedRowData] =
    useState<IGetSubscriptionSchema>();
  const {
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    listData,
    pager,
    setPager,
    loading,
    totalRecords,
  } = useSubscriptions(accountId, billGroupId);
  const { canDo } = useACL();

  const {
    isOpen: isCancelModalOpen,
    onOpen: onOpenCancelModal,
    onClose: onCloseCancelModal,
  } = useDisclosure();

  const redirectToEdit = (id: string) => {
    navigate(ROUTES.getSubscriptionEditRoute(accountId, id));
  };

  const handleSubscriptionCancel = (rowData: IGetSubscriptionSchema) => {
    setSelectedRowData(rowData);
    onOpenCancelModal();
  };

  const lockedBodyTemplate = (rowData: IGetSubscriptionSchema) => {
    return rowData.locked ? (
      <MIcon as={MdLock} w={3} h={3} mt={1} color="tPurple.dark" />
    ) : null;
  };

  const actionBodyTemplate = (rowData: IGetSubscriptionSchema) => {
    const enabled =
      rowData.billingStatus !== SubscriptionBillingStatusEnum.CANCELED &&
      !rowData.quoteOfferingId &&
      canDo([['billing', 'update']]);

    const actions = [
      {
        title: 'Edit',
        enabled,
        action: () => redirectToEdit(rowData.id),
      },
      {
        title: 'Cancel',
        color: 'danger',
        enabled,
        action: () => handleSubscriptionCancel(rowData),
      },
    ];

    return rowData.locked ? null : <MDataTableActions actions={actions} />;
  };

  const columns: ColumnProps[] = [
    {
      field: 'locked',
      header: '',
      body: lockedBodyTemplate,
      style: {
        width: '1rem',
        paddingLeft: '6px',
        paddingRight: '0px',
      },
    },
    {
      className: 'table-cell-md',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<ISubscriptionWithBillGroup>('id'),
      style: {
        padding: '0 1rem 0 .625rem',
      },
    },
    {
      className: 'overflow-hidden',
      field: 'billGroupId',
      header: 'Bill Group',
      body: nameWithIdBodyTemplate<ISubscriptionWithBillGroup, IBillGroupResp>(
        'billGroup',
        { idLinkFn: (id) => getBillGroupPageRoute(id), fallbackProperty: 'billGroupId' },
      ),
      style: {
        width: '12.5rem',
      },
    },
    {
      field: 'offering',
      header: 'Name',
      sortable: true,
      body: nestedTextBodyTemplate<
        ISubscriptionWithBillGroup,
        IOfferingOnSubscription
      >('offering', 'name'),
      style: {
        width: '8em',
      },
    },
    {
      field: 'description',
      header: 'Description',
      body: textBodyTemplate<ISubscriptionWithBillGroup>('description'),
      className: 'table-cell-lg',
    },
    {
      field: 'periodStartDate',
      header: 'Period Start',
      sortable: true,
      body: dateBodyTemplate<ISubscriptionWithBillGroup>('periodStartDate'),
      style: {
        width: '9em',
      },
    },
    {
      field: 'periodEndDate',
      header: 'Period End',
      sortable: true,
      body: dateBodyTemplate<ISubscriptionWithBillGroup>('periodEndDate'),
      style: {
        width: '9em',
      },
    },
    {
      field: 'billingStatus',
      header: 'Status',
      body: statusBodyTemplate<
        ISubscriptionWithBillGroup,
        SubscriptionBillingStatusEnum
      >('billingStatus', SubscriptionBillingStatusDisplayText),
      sortable: true,
      style: { width: '7em' },
    },
    {
      field: 'actions',
      header: '',
      style: {
        width: '3em',
      },
      body: actionBodyTemplate,
    },
  ];

  const statusItems = objectToObjArray(
    SubscriptionBillingStatusDisplayText,
    'value',
    'label',
  );

  const filterOptions = [
    {
      title: 'Status',
      key: 'billingStatus',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: listData?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MBox>
      <MPageHeader title="Subscriptions" hideContent={isTrulyEmptyList}>
        <MFlex>
          <MHStack spacing="2">
            <MPageSearchInput
              placeholderKey="Description"
              value={searchTerm || ''}
              onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
              count={totalRecords}
              resetSearch={searchComponentReset}
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="subscriptions"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              getFilename={() => `subscriptions-${accountId}.csv`}
            />
            <ACLCheck acls={[['billing', 'create']]}>
              <MButton
                variant="secondary"
                as={Link}
                to={
                  billGroupId
                    ? `${getSubscriptionNewRoute(
                        accountId,
                      )}?billGroupId=${billGroupId}`
                    : getSubscriptionNewRoute(accountId)
                }
              >
                New Subscription
              </MButton>
            </ACLCheck>
          </MHStack>
        </MFlex>
      </MPageHeader>

      <MDataTable
        value={listData?.content}
        totalRecords={listData?.totalElements} // total records reported by the db (ignoring the page qty we've asked for), ie. not the total we're showing on this page
        totalPages={listData?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no subscriptions here.',
          btnLabel: canDo([['billing', 'create']]) ? 'New Subscription' : '',
          to: billGroupId
            ? `${getSubscriptionNewRoute(accountId)}?billGroupId=${billGroupId}`
            : getSubscriptionNewRoute(accountId),
        }}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        loading={loading}
        columns={columns}
        onRowClick={(e) => {
          const generatedRoute = ROUTES.getSubscriptionOverviewRoute(
            accountId,
            e.data.id,
          );
          const routeToNavigate = billGroupId
            ? `${generatedRoute}?billGroupId=${billGroupId}`
            : generatedRoute;
          shouldOpenInNewWindow(e)
            ? window.open(routeToNavigate, '_blank')
            : navigate(routeToNavigate, { state: accountId });
        }}
      />

      <CancelSubscription
        isOpen={isCancelModalOpen}
        onClose={(redirect = true) => {
          onCloseCancelModal();
          setSelectedRowData(undefined);
          redirect && navigate(ROUTES.getAccountSubscriptionsRoute(accountId));
        }}
        accountId={accountId}
        billGroupId={selectedRowData?.billGroupId || ''}
        subscriptionId={selectedRowData?.id!}
      />
    </MBox>
  );
};
