import { ToggleState } from '@monetize/types/app';
import { useCallback, useState } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { handleApiErrorToast } from '../../../../api/axios';
import { doPrintQuoteToPdf } from '../../../../api/cpqService';
import {
  MButton,
  MDivider,
  MFlex,
  MIcon,
} from '../../../../components/Monetize';
import { PreviewQuotePdfDrawerV2 } from '../../../../components/Quotes/PreviewQuotePdfDrawerV2';
import { useFlags } from '../../../../services/launchDarkly';
import { IQuoteRespSchema } from '../../../../types';
import { getSafeFilename } from '../../../../utils';
import { downloadBlobAsFile } from '../../../../utils/download';
import { QuotePDFCustomizationToggle } from './QuotePDFCustomizationToggle';

export const QuotePageCustomerBar = ({
  quote,
  pdfDrawerConfig,
  showDownloadButton,
}: {
  quote: IQuoteRespSchema;
  pdfDrawerConfig: {
    externalPdfDrawer: ToggleState;
    setExternalPdfDrawer: (val: ToggleState) => void;
  };
  showDownloadButton: boolean;
}) => {
  const { useQuotePdfV2 } = useFlags();
  const [loading, setLoading] = useState(false);

  const fetchDocument = useCallback(async (): Promise<ArrayBuffer | null> => {
    if (!quote) {
      return null;
    }

    return doPrintQuoteToPdf(quote.id, useQuotePdfV2);
  }, [quote, useQuotePdfV2]);

  const downloadPdf = async () => {
    setLoading(true);
    try {
      const pdfDocument = await fetchDocument();
      if (!pdfDocument) return;

      downloadBlobAsFile(
        pdfDocument,
        `${getSafeFilename(quote.description || 'quote-pdf')}.pdf`,
      );
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MFlex alignItems="center" justifyContent="end" my={4}>
      {showDownloadButton && (
        <>
          <PreviewQuotePdfDrawerV2 quote={quote} key={'Preview PDF'} />
          <MDivider
            orientation="vertical"
            variant={'solid'}
            background={'tBlack.200'}
            w="1px"
            h="24px"
            mx={1}
          ></MDivider>
          <MButton
            variant="tertiary"
            size="sm"
            onClick={downloadPdf}
            isLoading={loading}
          >
            <MIcon mr={2} boxSize={5} as={MdOutlineFileDownload}></MIcon>
            Download PDF
          </MButton>
          <MDivider
            orientation="vertical"
            variant={'solid'}
            background={'tBlack.200'}
            w="1px"
            h="24px"
            mx={1}
          ></MDivider>
        </>
      )}
      <QuotePDFCustomizationToggle
        state={pdfDrawerConfig.externalPdfDrawer}
        onClick={pdfDrawerConfig.setExternalPdfDrawer}
      ></QuotePDFCustomizationToggle>
    </MFlex>
  );
};
