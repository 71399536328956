import { getRequiredMessage } from '@monetize/utils/core';
import { z } from 'zod';
import { BaseResponseSchema } from './_app.types';
import { AddressSchema } from './address.types';
import { CustomFieldRecordSchema } from './customFields.types';
import { ContactStatusEnumZ } from './enums.types';

export const ContactAddressSchema = z.object({
  description: z.string().nullish(),
  line1: z.string().max(255).nullish(),
  line2: z.string().max(255).nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  postalCode: z.string().max(50).nullish(),
  country: z.string().nullish(),
});
export type IContactAddressSchema = z.infer<typeof ContactAddressSchema>;

// from /api/accounts/{accountId}/contacts and /api/quotes/{quoteId}->.contact[type]
export const ContactRequestSchema = z.object({
  snapshotId: z.string().nullish(),
  fullName: z.string().max(60).nonempty(getRequiredMessage('Full Name')),
  email: z.string().max(70).email().nonempty(getRequiredMessage('Email')),
  phone: z.string().max(50).nullish(),
  address: AddressSchema.nullish(),
  customId: z.string().nullish(),
  status: ContactStatusEnumZ,
  title: z.string().max(40).nullish(),
  internal: z.boolean().optional(),
  primary: z.boolean().optional(),
  esigner: z.boolean().optional(),
  defaultESigner: z.boolean().optional(),
  customFields: CustomFieldRecordSchema,
});
export type IContactRequestSchema = z.infer<typeof ContactRequestSchema>;

export const ContactRespSchema = BaseResponseSchema.extend({
  snapshotId: z.string().nullish(),
  accountId: z.string(),
  address: ContactAddressSchema,
  customId: z.string().nullable(),
  email: z.string(),
  fullName: z.string(),
  internal: z.boolean(),
  phone: z.string().nullable(),
  primary: z.boolean(),
  status: ContactStatusEnumZ,
  title: z.string().nullable(),
  esigner: z.boolean().optional(),
  defaultESigner: z.boolean().optional(),
});
export type IContactRespSchema = z.infer<typeof ContactRespSchema>;

export const QuoteContacts = z.object({
  billing: ContactRespSchema.nullable(),
  esign: z.array(ContactRespSchema),
  primary: ContactRespSchema.nullable(),
});

export type IQuoteContacts = z.infer<typeof QuoteContacts>;
