import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { MdInfo, MdOutlineDesktopWindows } from 'react-icons/md';
import {
  MButton,
  MCustomIconButton,
  MCustomNumberInput,
  MFlex,
  MFormField,
  MIcon,
  MSwitch,
  MText,
  MTooltip,
  RadioStyleButtonGroup,
} from '../../../../../components/Monetize';
import MPopoverWrapper from '../../../../../components/Monetize/MPopoverWrapper';
import {
  DISPLAY_FREQUENCY_ALLOWED_PRODUCT_TYPE,
  FREQUENCY_ITEMS,
} from '../../../../../constants/quotes';
import {
  IQuoteItemOptionsSchema,
  IQuoteItemRespSchema,
  QuoteItemOptionsSchema,
  RateBillingFrequencyEnum,
} from '../../../../../types';
import { getDisplayUnitPriceFrequencyDefaultValue } from '../../../../../utils/quotes';

const displayFrequencyOptions = FREQUENCY_ITEMS.map(({ title, value }) => ({
  label: title,
  value: `${value}`,
}));

export interface QuotePriceConfigPopoverProps {
  index: number;
  item: IQuoteItemRespSchema;
  setValue: UseFormSetValue<any>;
  handleQuoteOfferingWithoutDirtyCheck: () => void;
  isLoading: boolean;
  isRemoved?: boolean;
  billingFrequency?: RateBillingFrequencyEnum;
  triggerVisibility: 'hidden' | 'visible';
  isReadOnly: boolean;
}

const QuotePriceConfigPopover = ({
  index,
  item,
  setValue: setValueOnQuoteOffering,
  handleQuoteOfferingWithoutDirtyCheck,
  isLoading,
  isRemoved = false,
  billingFrequency = RateBillingFrequencyEnum.MONTHLY,
  triggerVisibility,
  isReadOnly,
}: QuotePriceConfigPopoverProps) => {
  const displayUnitPriceFrequencyDefaultValue =
    getDisplayUnitPriceFrequencyDefaultValue(
      billingFrequency,
      item.options?.displayUnitPriceFrequency,
    );

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IQuoteItemOptionsSchema>({
    mode: 'onChange',
    resolver: zodResolver(QuoteItemOptionsSchema),
    defaultValues: {
      displayUnitPriceFrequency: displayUnitPriceFrequencyDefaultValue,
      showPrices: !!item.options?.showPrices,
    },
  });

  useEffect(() => {
    reset({
      displayUnitPriceFrequency: getDisplayUnitPriceFrequencyDefaultValue(
        billingFrequency,
        item.options?.displayUnitPriceFrequency,
      ),
      showPrices: !!item.options?.showPrices,
    });
  }, [billingFrequency, item.options]);

  const hasError = Object.keys(errors).length > 0;
  const [displayFrequency, setDisplayFrequency] = useState<boolean>(
    !!item.options?.displayUnitPriceFrequency,
  );

  const displayUnitPriceFrequency = watch('displayUnitPriceFrequency');
  const { onOpen, onClose, isOpen } = useDisclosure();

  const onModalOpen = () => {
    setDisplayFrequency(!!item.options?.displayUnitPriceFrequency);
    onOpen();
  };
  const onCloseModal = () => {
    reset({
      displayUnitPriceFrequency: displayUnitPriceFrequencyDefaultValue,
      showPrices: !!item.options?.showPrices,
    });

    onClose();
  };

  const onSubmit = async ({
    showPrices,
    displayUnitPriceFrequency,
  }: IQuoteItemOptionsSchema) => {
    const fieldName = `items.${index}.options`;
    setValueOnQuoteOffering(
      fieldName,
      {
        showPrices,
        displayUnitPriceFrequency: displayFrequency
          ? displayUnitPriceFrequency
          : null,
      },
      {
        shouldDirty: true,
        shouldValidate: true,
      },
    );
    handleQuoteOfferingWithoutDirtyCheck();
    onClose();
  };

  return (
    <MPopoverWrapper
      isOpen={isOpen}
      showArrow
      placement="left-start"
      onOpen={onModalOpen}
      onClose={onCloseModal}
      popoverContentProps={{
        minW: '300px',
      }}
      triggerContent={
        <MCustomIconButton
          isDisabled={isRemoved}
          icon={MdOutlineDesktopWindows}
          iconColor="tGray.darkPurple"
          btnSize={4}
          padding={0}
          containerSize={5}
          onClick={onOpen}
          ml={-5}
          visibility={isOpen ? 'visible' : triggerVisibility}
        />
      }
      bodyContent={
        <MFlex direction="column" gap={4}>
          <MFlex gap={4} direction="inherit">
            <MFlex justify="space-between" align="center">
              <MText color="tPurple.dark" size="md">
                Show in Pricing Section on PDF
              </MText>
              <MFormField position="unset" w="auto">
                <Controller
                  name="showPrices"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MSwitch
                      size="md"
                      isChecked={Boolean(value)}
                      {...rest}
                      isDisabled={isReadOnly}
                    />
                  )}
                />
              </MFormField>
            </MFlex>
          </MFlex>
          {DISPLAY_FREQUENCY_ALLOWED_PRODUCT_TYPE.has(item.productType) && (
            <>
              <MFlex gap={2} direction="inherit">
                <MFlex w="full" justify="space-between" align="center">
                  <MFlex align="center">
                    <MText color="tPurple.dark" size="md">
                      Customize Display Frequency
                    </MText>
                    <MTooltip
                      shouldWrapChildren
                      label="Frequency (e.g., monthly, annually) shown on Quote PDF. Does not impact billing schedule."
                      placement="bottom-start"
                    >
                      <MIcon as={MdInfo} color="tPurple.base" ml="1" mt="2" />
                    </MTooltip>
                  </MFlex>
                  <MSwitch
                    size="md"
                    isChecked={displayFrequency}
                    onChange={({ target }) =>
                      setDisplayFrequency(target.checked)
                    }
                    isDisabled={isReadOnly}
                  />
                </MFlex>
              </MFlex>
              {displayFrequency && (
                <MFlex direction="column" gap={2}>
                  <MFlex align="center" gap={2}>
                    <MText>Per</MText>
                    <MFormField
                      w={14}
                      error={errors?.displayUnitPriceFrequency}
                      formErrorMessageProps={{
                        display: 'none',
                      }}
                    >
                      <Controller
                        name="displayUnitPriceFrequency"
                        control={control}
                        render={({ field: { value, onChange, ...rest } }) => (
                          <MCustomNumberInput
                            onChange={(
                              valueAsString: string,
                              valueAsNumber: number,
                            ) => onChange(valueAsNumber)}
                            value={value || ''}
                            min={1}
                            max={60}
                            {...rest}
                            isDisabled={isReadOnly}
                          />
                        )}
                      />
                    </MFormField>
                    <MText>Month(s)</MText>
                  </MFlex>
                  <MText color="tRed.base" fontSize="sm">
                    {errors?.displayUnitPriceFrequency?.message}
                  </MText>
                  <RadioStyleButtonGroup
                    name="displayUnitPriceFrequencyOptions"
                    containerProps={{ columns: 2 }}
                    options={displayFrequencyOptions}
                    value={`${displayUnitPriceFrequency || ''}` || undefined}
                    defaultValue={
                      `${displayUnitPriceFrequency || ''}` || undefined
                    }
                    isDisabled={isReadOnly}
                    onChange={(value: string) => {
                      setValue('displayUnitPriceFrequency', +value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                  />
                </MFlex>
              )}
            </>
          )}
        </MFlex>
      }
      footerContent={
        <MFlex direction="row" align="center" justify="right">
          <MButton
            variant="primary"
            isDisabled={hasError || isReadOnly}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </MButton>
        </MFlex>
      }
    />
  );
};

export default QuotePriceConfigPopover;
