import { QuoteAmendmentVersionEnum } from '@monetize/types/app';
import { sortByProductPosition, toDateShort } from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { parseISO } from 'date-fns/parseISO';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdInfo, MdLock } from 'react-icons/md';
import { handleApiErrorToast } from '../../../../../api/axios';
import { useGetDiscountsByOfferingId } from '../../../../../api/productCatalogService';
import { useGetById } from '../../../../../api/queryUtils';
import {
  MBox,
  MCircularProgress,
  MCloseButton,
  MCustomIconButton,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MIcon,
  MStack,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import MEditableInput from '../../../../../components/Monetize/MEditableInput';
import { QuoteDrawer } from '../../../../../components/Quotes/QuotesDrawer/QuoteDrawer';
import { quoteOfferingInitial } from '../../../../../constants/quotes';
import { useOfferingRate, useQuoteOffering } from '../../../../../hooks';
import { useFlags } from '../../../../../services/launchDarkly';
import { logger } from '../../../../../services/logger';
import Sentry from '../../../../../services/sentry';
import { useToast } from '../../../../../services/toast';
import {
  AmountUnitTypeEnum,
  IOfferingRes,
  IPriceResSchema,
  IPriceTierSettingsReq,
  IQuoteDiscountRespSchema,
  IQuoteItemReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IRateResBaseSchema,
  IRateResSchema,
  Maybe,
  OfferingTypesEnum,
  OnQuoteOfferingChangeAction,
  PriceModelEnum,
  ProductTypeEnum,
  QuoteOfferingProps,
  QuoteOfferingRemovalScopeEnum,
  QuoteOfferingReqSchema,
  QuoteTypeEnum,
  RateBillingFrequencyEnum,
  RateStatusEnum,
  SelectedProductsWithinOffering,
  SelectedProductsWithinOfferingForm,
  getQuoteOfferingReqSchema,
} from '../../../../../types';
import {
  CUSTOMER_AUTOMATION_QuoteOffering_AcuityMdQuantityAutomation,
  TEMP_SALES_DEMO_MEDINSIGHT_calculateCustomPriceFormula,
  TEMP_SALES_DEMO_MEDINSIGHT_checkIfSecondUpdateIsRequired,
  TEMP_SALES_DEMO_TELEPORT_calculateQuantityOfDerivedProduct,
} from '../../../../../types/sales-demo.utils';
import {
  getOfferingWithSortedRate,
  getQuoteOfferingRateInputProps,
  sortRate,
} from '../../../../../utils';
import { arrayToObject } from '../../../../../utils/misc';
import {
  calculateModifiedQuoteItemFields,
  getRelevantOfferingStartDate,
  isOfferingRateChangeAllowed,
} from '../../../../../utils/quotes';
import { useQuoteContext } from '../../quoteContext';
import { getOfferingList } from '../../quoteUtils';
import {
  BulkOfferingFlow,
  BulkOfferingItemQuantityHandlerProps,
} from '../BulkOfferingFlow/BulkOfferingFlow';
import { ImportQuoteOfferingsModal } from '../ImportQuoteOfferingsModal';
import { ManageProducts } from './manageProducts/ManageProducts';
import QuoteItems from './QuoteItems';
import { QuoteOfferingActions } from './QuoteOfferingActions';
import { QuoteOfferingActionsAmendV2 } from './QuoteOfferingActionsAmendV2';
import { QuoteOfferingAmount } from './QuoteOfferingAmount';
import QuoteOfferingDate from './QuoteOfferingDate';
import QuoteOfferingDiscount from './QuoteOfferingDiscount';
import {
  QuoteOfferingInactiveItem,
  QuoteOfferingInactiveRateItem,
  getIsRateEndDateIsBeforeContractStartDate,
} from './QuoteOfferingInactiveItem';
import QuoteOfferingRateRightEl from './QuoteOfferingRateRightEl';
import { QuoteOfferingScheduleChange } from './QuoteOfferingScheduleChange';
import { QuoteOfferingScheduledEffectiveDate } from './QuoteOfferingScheduledEffectiveDate';
import { QuoteOfferingScheduledEndDate } from './QuoteOfferingScheduledEndDate';
import {
  OfferingToggleButton,
  QuoteOfferingCollapsibleRate,
  RateToggleButton,
  clampValueToRange,
  getQuoteOfferingState,
  getRateRightElement,
  getScheduleChangeState,
} from './quoteOfferingUtils';
import { RateItemContent } from './RateItemContent';
import { useManageProduct } from './useManageProduct';
import { useQuoteOfferingAmendV2 } from './useQuoteOfferingAmendV2';

const QuoteOffering = React.forwardRef<any, QuoteOfferingProps>(
  (props: QuoteOfferingProps, ref) => {
    let { quoteOffering } = props;

    const {
      parentQuoteOffering,
      priorScheduledQuoteOffering,
      nextScheduledQuoteOffering,
      index: offeringIndex,
      childQuoteOfferings = [],
      isChildOffering = false,
      isRootOfferingOpen = false,
      quotePrices,
      onChange: onOfferingChange,
      quoteOfferings,
      setParentScheduleChangeActiveProp,
    } = props;

    const {
      teleportSalesDemoTempDependentPricingConfig,
      salesDemoMedinsightTempFormulaCustomPricing,
      customerAutomationAcuityMd,
      quoteOfferingDateAndScheduledChangeRate,
      showQuoteOfferingDescription,
      disableInactiveProductBanner,
      allowChangingQuoteOfferingStartAndEndDate,
      useQuoteEditV2,
      allowOptionalProducts,
      guidedQuoteOfferingSelection,
    } = useFlags();

    const { addToast } = useToast();
    const hasChildOfferings = !!childQuoteOfferings.length;
    const hasNoScheduleChange =
      !isChildOffering && childQuoteOfferings?.length == 0;
    const {
      productOfferingsData,
      quoteData,
      quoteStateData,
      isLoading,
      isReadOnly: isReadOnlyQuote,
      useAmendmentV2,
    } = useQuoteContext();

    const {
      quote,
      displayConfig,
      quoteDataOnInitialLoad,
      loading: quoteLoading,
      fetchQuote,
    } = quoteData;
    const { offeringLoadingState, setOfferingLoadingState } = quoteStateData;
    const isOfferingLoading =
      offeringLoadingState[quoteOffering?.id || '']?.isLoading || false;
    /** Offering form becomes locked if a rate is being modified */
    const isRateLoading =
      offeringLoadingState[quoteOffering?.id || '']?.isLoading &&
      offeringLoadingState[quoteOffering?.id || '']?.modifiedItems
        ?.get?.('header')
        ?.has('rateId');
    const isOfferingDeleting =
      offeringLoadingState[quoteOffering?.id || '']?.isDeleting ||
      offeringLoadingState[parentQuoteOffering?.id || '']?.isDeleting ||
      false;

    const {
      loading: isLoadingOfferings,
      productOfferings,
      addOrUpdateOfferingRate,
    } = productOfferingsData;

    const quoteOfferingState = getQuoteOfferingState({
      ...props,
      quote,
      quoteDataOnInitialLoad,
    });

    const {
      newlyAddedOffering,
      isNewQuoteOfferingForm,
      isLastSegment,
      isRemoved,
      isAmendment,
      isRenewal,
      isLocked,
      isReadOnlyAndNotEmptyOffering,
      allowPriceCustomization,
      gridColumns,
      isQuoteOfferingDiscountAvailable,
      bgColor,
      bgColorV2,
      isOfferingUnselectable,
      isOfferingOnetime,
      isUsingDelayedBilling,
    } = quoteOfferingState;

    const [scheduleChangeActive, setScheduleChangeActive] = useState(false);
    const [parentScheduleChangeActive, setParentScheduleChangeActive] =
      useState(false); // Parent quote offering should know if child schedule change is being created
    const [scheduleChangeLoading, setScheduleChangeLoading] = useState(false);

    const [enableCustomDiscounts, setEnableCustomDiscounts] =
      useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isOfferingOpen, setIsOfferingOpen] = useState<boolean>(true);
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    const [offering, setOffering] = useState<IOfferingRes>();

    const [offeringRatesById, setOfferingRatesById] = useState<
      Record<string, IRateResBaseSchema>
    >({});
    const [showUnlockTooltip, setShowUnlockTooltip] = useState<boolean>(false);

    const [isBulkOfferingLoading, setIsBulkOfferingLoading] = useState(false);
    const [isProductsLoading, setProductsLoading] = useState(false);

    const {
      createQuoteOffering,
      updateQuoteOffering,
      deleteQuoteOffering,
      revertQuoteOffering,
      removeQuoteOffering,
      loading: isQuoteOfferingProcessing,
    } = useQuoteOffering(quote?.id!);

    const {
      offeringRate,
      orderedPricesUnderProduct,
      setOrderedPricesUnderProduct,
      setOfferingRate,
      fetchOfferingRate,
      loading: rateLoading,
    } = useOfferingRate(quoteOffering?.id);

    const quoteOfferingFormState = useForm<IQuoteOfferingReqSchema>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: getQuoteOfferingReqSchema(offeringRate),
      defaultValues: quoteOffering || {
        ...quoteOfferingInitial,
        options: {},
        endDate: quote?.contractEndDate,
      },
    });

    const {
      reset,
      watch,
      control,
      setValue,
      setError,
      handleSubmit,
      getValues,
      formState: { errors, isDirty, isValid },
    } = quoteOfferingFormState;

    useEffect(() => {
      if (quote && isNewQuoteOfferingForm) {
        setValue('endDate', quote?.contractEndDate);
      }
    }, [quote, isNewQuoteOfferingForm, setValue]);

    const {
      isManageProductModalOpen,
      onManageProductModalOpen,
      onManageProductModalClose,
      handleOnModalClose,
      isDrawerOpen,
      onDrawerOpen,
      onDrawerClose,
    } = useManageProduct({
      setOffering,
      setValue,
    });

    const scheduleChangeState = getScheduleChangeState({
      isChildOffering,
      quoteOffering,
      quote,
      offering,
      allowOptionalProducts,
    });
    const { monthInterval } = scheduleChangeState;

    const watchOffering = watch('offeringId');
    const watchItems = watch('items');
    const watchDiscountIds = watch('discountIds');
    const watchRateId = watch('rateId');
    const contractStartDate =
      quote?.type === QuoteTypeEnum.AMENDMENT
        ? quote?.contractAmendmentDate
        : quote?.contractStartDate;

    // /api/offerings/{offeringId}
    // Checks for inactive offering
    const isSelectedOfferingActive = useMemo(() => {
      if (!watchOffering || !productOfferings.length) {
        return true;
      }
      return Boolean(
        watchOffering &&
          productOfferings.find(({ id }) => id === watchOffering),
      );
    }, [watchOffering, productOfferings]);

    const showDuplicatedOfferingWarn = useMemo(() => {
      // if offering is not selected, return false
      if (
        !watchOffering ||
        !quoteOfferings?.length ||
        isRateLoading ||
        isOfferingLoading ||
        isLoadingOfferings
      ) {
        return false;
      }
      return !!quoteOfferings.find((offering, i) =>
        offeringIndex >= 0
          ? offeringIndex !== i &&
            offering.quoteOffering.offeringId === watchOffering
          : offering.quoteOffering.offeringId === watchOffering,
      );
    }, [watchOffering, quoteOfferings]);

    // Fetch inactive offering
    const { data: inactiveOffering, refetch: refetchInactiveOffering } =
      useGetById<IOfferingRes>('productCatalogOfferings', watchOffering!, {
        enabled:
          !isNewQuoteOfferingForm &&
          !!watchOffering &&
          !isSelectedOfferingActive &&
          !drawerOpen, // should not try to fetch inactive offering when prices drawer is open
        select: (data) =>
          getOfferingWithSortedRate(data, {
            accountId: quote?.accountId,
            currency: quote?.currency,
            contractStartDate,
            rateId: watchRateId,
          }),
      });

    useEffect(() => {
      if (inactiveOffering) {
        setOffering(inactiveOffering);

        if (quoteOffering?.items) {
          setValue(
            'items',
            sortByProductPosition(
              quoteOffering.items.map((item) => ({
                ...item,
                customPrice: item.customPriceId
                  ? {
                      id: item.customPriceId,
                      unitAmount: item.customPrice?.amount || 0,
                    }
                  : undefined,
              })),
            ),
          );
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inactiveOffering]);

    // /api/rates/{rateId}
    // Check for inactive rate
    const isSelectedRateActive = useMemo(() => {
      if (!watchRateId || !offering?.rates?.length) {
        return true;
      }
      return Boolean(
        watchRateId &&
          offering &&
          offering?.rates.find(({ id }) => id === watchRateId),
      );
    }, [watchRateId, offering]);

    // Fetch inactive rate
    const { data: inactiveRate } = useGetById<IRateResSchema>(
      'productCatalogRates',
      watchRateId!,
      {
        enabled:
          !isNewQuoteOfferingForm &&
          !!watchRateId &&
          !isSelectedRateActive &&
          !drawerOpen, // should not try to fetch inactive rate when prices drawer is open
      },
    );

    useEffect(() => {
      if (inactiveRate) {
        // Parse contract start date
        const parsedContractStartDate = quote
          ? parseISO(quote.contractStartDate)
          : undefined;
        const isRateEndDateIsBeforeContractStartDate =
          getIsRateEndDateIsBeforeContractStartDate(
            inactiveRate,
            parsedContractStartDate,
          );
        if (
          offering &&
          inactiveRate.status === RateStatusEnum.ACTIVE &&
          inactiveRate.quotable &&
          !isRateEndDateIsBeforeContractStartDate
        ) {
          // By any chance active rate is not synced with the product
          // offerings it should manually update
          addOrUpdateOfferingRate(offering.id, inactiveRate);
        }
      }
      // TODO: validate this works without the other deps
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inactiveRate]);

    useEffect(() => {
      let offeringRatesByIdTmp: Record<string, IRateResBaseSchema> = {};
      if (offering && Array.isArray(offering.rates)) {
        offeringRatesByIdTmp = arrayToObject(offering.rates, 'id');
      }
      if (inactiveRate) {
        offeringRatesByIdTmp[inactiveRate.id] = inactiveRate;
      }
      setOfferingRatesById(offeringRatesByIdTmp);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offering?.rates, inactiveRate]);

    const rateSelects: [IRateResBaseSchema[], IRateResBaseSchema[]] =
      useMemo(() => {
        let list: IRateResBaseSchema[] = [];
        let disabledList: IRateResBaseSchema[] = [];
        const allOfferings = inactiveOffering
          ? [...productOfferings, inactiveOffering]
          : productOfferings;
        const selectedOffering = allOfferings.find(
          ({ id }) => id === watchOffering,
        );

        if (selectedOffering) {
          if (selectedOffering?.rates) {
            if (inactiveRate) {
              const isInactiveRateExistInOffering = selectedOffering.rates.find(
                ({ id }) => id === inactiveRate.id,
              );
              if (isInactiveRateExistInOffering) {
                list = selectedOffering.rates;
              } else {
                list = [...selectedOffering.rates, inactiveRate];
              }
            } else {
              list = selectedOffering.rates;
            }
          }
        }

        const parentRate = quoteOffering?.rateId
          ? offeringRatesById[quoteOffering.rateId]
          : null;
        // Making sure if there is a parentRate
        if (parentRate) {
          disabledList = list.filter(
            ({ billingFrequencyInMonths, subscriptionTiming }) =>
              !(
                billingFrequencyInMonths ===
                  parentRate.billingFrequencyInMonths &&
                subscriptionTiming === parentRate.subscriptionTiming
              ),
          );
        }

        if (
          inactiveRate &&
          quoteOffering?.rateId &&
          quoteOffering.rateId === inactiveRate?.id
        ) {
          disabledList = [inactiveRate];
        }

        return [sortRate(list), disabledList];
      }, [
        quoteOffering,
        inactiveOffering,
        productOfferings,
        offeringRatesById,
        inactiveRate,
        watchOffering,
      ]);

    const { data: discounts } = useGetDiscountsByOfferingId({
      offeringId: watchOffering!,
      filters: quote?.accountId
        ? { accountId: quote?.accountId || '' }
        : undefined,
      options: { enabled: !!watchOffering, refetchOnWindowFocus: false },
    });

    const displayImportOffering =
      !watchOffering && isNewQuoteOfferingForm && !quote?.quoteOfferings.length;
    // load prices for this offering and rate
    useEffect(() => {
      if (watchRateId && !offeringRate && !rateLoading) {
        fetchOfferingRate(watchRateId);
      }
    }, [watchRateId, offeringRate]);

    /**
     * When quoteOffering changes, reset for state
     * Quantity and Discount are always retained from form to ensure not overwritten
     * There are other places in component that may correct quantity and discount if they are invalid
     */
    useEffect(() => {
      if (quoteOffering) {
        const {
          description,
          offeringId,
          rateId,
          items,
          discountAmount,
          discounts: offeringDiscounts,
          startDate,
          endDate,
        } = quoteOffering;
        let discountIds: string[] = [];
        const sortedQuoteItems = sortByProductPosition(items);
        if (offeringDiscounts && offeringDiscounts.length > 0) {
          discountIds = offeringDiscounts.map(
            (discount: IQuoteDiscountRespSchema) => discount.id,
          );
        } else if (
          discountAmount ||
          items.some(
            ({ customDiscountAmountOrPercent }) =>
              !!customDiscountAmountOrPercent,
          )
        ) {
          setEnableCustomDiscounts(true);
        }

        const selectedOffering = productOfferings.find(
          ({ id }) => id === offeringId,
        );

        if (selectedOffering) {
          const OfferingWithSortedRate = getOfferingWithSortedRate(
            selectedOffering,
            {
              accountId: quote?.accountId,
              currency: quote?.currency,
              contractStartDate,
              rateId: watchRateId,
            },
          );
          setOffering(OfferingWithSortedRate);
        }

        const formValues = getValues();

        const formItemsById = arrayToObject(formValues.items, 'id');

        reset({
          description,
          offeringId,
          rateId,
          discountIds,
          startDate,
          endDate,
          // This retains the form values quantity and discount to ensure that user's changes are not overwritten
          items: sortedQuoteItems.map((item) => ({
            ...item,
            customPrice: item.customPriceId
              ? {
                  id: item.customPriceId,
                  unitAmount: item.customPrice?.amount || 0,
                }
              : undefined,
            quantity: formItemsById[item.id]?.quantity ?? item.quantity,
            // TODO: might need more sophisticated logic here
            customDiscountAmountOrPercent:
              formItemsById[item.id]?.customDiscountAmountOrPercent ??
              item.customDiscountAmountOrPercent,
            customDiscountType:
              formItemsById[item.id]?.customDiscountType ??
              item.customDiscountType,
          })),
          schedule: quoteOffering.parentQuoteOfferingId
            ? {
                startDate: quoteOffering.startDate,
                endDate: quoteOffering.endDate,
                parentQuoteOfferingId: quoteOffering.parentQuoteOfferingId,
              }
            : undefined,
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteOffering]);

    // ensure that if the rates change, the list is updated to reflect the rates
    useEffect(() => {
      const selectedOffering = productOfferings.find(
        ({ id }) => id === quoteOffering?.offeringId,
      );
      if (selectedOffering) {
        const offeringWithSortedRate = getOfferingWithSortedRate(
          selectedOffering,
          {
            accountId: quote?.accountId,
            currency: quote?.currency,
            contractStartDate,
            rateId: watchRateId,
          },
        );
        setOffering(offeringWithSortedRate);
      }
    }, [productOfferings]);

    const areAllItemsInOfferingMandatory = (selectedOffering: IOfferingRes) => {
      if (!allowOptionalProducts) {
        return true;
      }

      const allItems = selectedOffering?.offeringProducts || [];
      return allItems.every(
        (item: { isMandatory: boolean }) => item.isMandatory,
      );
    };

    const handleOfferingChange = async (offeringId: string) => {
      const selectedOffering = productOfferings.find(
        ({ id }) => id === offeringId,
      );

      if (isAmendment) {
        setIsOfferingOpen(true);
        const newRelevantOfferingStartDate =
          quote?.amendmentVersion === QuoteAmendmentVersionEnum.v1
            ? (quote?.contractAmendmentDate ??
              getRelevantOfferingStartDate(quote, selectedOffering))
            : getRelevantOfferingStartDate(quote, selectedOffering);

        setValue('startDate', newRelevantOfferingStartDate); // set start date to todays date if newly amending or amendment date if not new
      }
      // On offering change we should set rateId as null
      setValue('rateId', null);

      if (selectedOffering) {
        const OfferingWithSortedRate = getOfferingWithSortedRate(
          selectedOffering,
          {
            accountId: quote?.accountId,
            currency: quote?.currency,
            contractStartDate,
            rateId: watchRateId,
          },
        );
        setOffering(OfferingWithSortedRate);
        const quoteItems = OfferingWithSortedRate.offeringProducts?.map(
          ({ product, position }) =>
            ({
              productId: product.id,
              quantity: 0,
              position,
            }) as IQuoteItemReqSchema,
        );

        if (quoteItems) {
          setValue('items', quoteItems);
        }

        if (
          allowOptionalProducts &&
          !areAllItemsInOfferingMandatory(selectedOffering)
        ) {
          onManageProductModalOpen(); // open manage product modal
        }
      } else {
        setOffering(undefined);
      }
    };

    const handleScheduleChangeInitiated = () => {
      setScheduleChangeActive(true);
    };

    const handleAddScheduledChange = async ({
      startDate,
      endDate,
      rateId,
    }: {
      startDate: string;
      endDate?: string;
      rateId: string;
    }) => {
      // Create offering, show loading, then remove the temp scheduled offering
      // set scheduled change loading
      if (!quoteOffering) {
        return null;
      }

      try {
        setScheduleChangeLoading(true);
        const newOffering: IQuoteOfferingReqSchema = {
          offeringId: quoteOffering.offeringId,
          rateId: rateId,
          subscriptionId: quoteOffering.subscriptionId,
          description: quoteOffering.description,
          items: quoteOffering.items.map((item) => ({
            ...item,
            customPrice: item.customPriceId
              ? {
                  id: item.customPriceId,
                  unitAmount: item.customPrice?.amount || 0,
                }
              : undefined,
          })),
          discountIds: quoteOffering.discounts.map(({ id }) => id),
          schedule: {
            parentQuoteOfferingId: quoteOffering.parentQuoteOfferingId
              ? quoteOffering.parentQuoteOfferingId
              : quoteOffering.id,
            startDate,
            endDate,
          },
        };

        const parsedNewOffering = QuoteOfferingReqSchema.safeParse(newOffering);
        if (!parsedNewOffering.success) {
          Sentry.captureException(parsedNewOffering.error.issues, {
            tags: {
              type: 'QUOTE_OFFERING_FORM',
            },
          });
        }
        const newOfferingResponse = await createQuoteOffering(
          parsedNewOffering.success ? parsedNewOffering.data : newOffering,
        );
        setScheduleChangeActive(false);
        // returns null on failure
        if (!newOfferingResponse) {
          return null;
        }
        return newOfferingResponse;
      } catch (ex) {
        handleApiErrorToast(ex);
      } finally {
        setScheduleChangeLoading(false);
      }
      return null;
    };

    const handleRemoveScheduledChange = async () => {
      try {
        if (quoteOffering) {
          if (useAmendmentV2) {
            setScheduleChangeLoading(true);
            await removeQuoteOffering({
              removal_scope: QuoteOfferingRemovalScopeEnum.TARGET,
              target_id: quoteOffering?.id,
            });
            await onOfferingChange('DELETE', quoteOffering);
          } else {
            setScheduleChangeLoading(true);
            await deleteQuoteOffering(quoteOffering?.id);
            await onOfferingChange('DELETE', quoteOffering);
          }
        }
      } catch (ex) {
        onError(ex);
      } finally {
        setScheduleChangeLoading(false);
      }
    };

    /**
     * Submit quote offering
     * All values sent to API are checked to see if they are actually modified
     * If nothing is modified, the API call is skipped
     *
     * The form dirty state is not reliable in this case because of a race condition for resetting the form while user is entering data
     * And string to number conversion
     *
     * @param overrideOfferingDirtyFields Offering fields that should be marked as dirty even if not modified (e.x. related rate fields)
     */
    const onSubmit = async ({
      data,
      overrideOfferingDirtyFields,
      isSecondUpdate = false,
      quoteOfferingOverride,
      doUpdateParent = true,
    }: {
      data: IQuoteOfferingReqSchema;
      overrideOfferingDirtyFields?: string[];
      isSecondUpdate?: boolean;
      // Hack to allow this to be called twice (recursively) without relying on parent state which is stale before quote is updated
      quoteOfferingOverride?: Maybe<IQuoteOfferingRespSchema>;
      doUpdateParent?: boolean;
    }) => {
      quoteOffering = quoteOfferingOverride || quoteOffering;
      const {
        offeringId,
        rateId,
        items,
        discountIds,
        startDate,
        endDate,
        ...rest
      } = data;

      if (!rateId) {
        return;
      }

      TEMP_SALES_DEMO_TELEPORT_calculateQuantityOfDerivedProduct({
        teleportSalesDemoTempDependentPricingConfig,
        quoteItemsToModify: data,
        setValue,
      });

      if (quoteOffering) {
        TEMP_SALES_DEMO_MEDINSIGHT_calculateCustomPriceFormula({
          salesDemoMedinsightTempFormulaCustomPricing,
          quoteOffering,
          quoteItemsToModify: data,
          setValue,
        });
        CUSTOMER_AUTOMATION_QuoteOffering_AcuityMdQuantityAutomation({
          customerAutomationAcuityMd,
          quoteOffering,
          quoteItemsToModify: data,
          setValue,
        });
      }

      if (quoteOffering?.options) {
        data.options = data.options ?? quoteOffering.options;
      }

      if (quoteOffering?.id) {
        const modifiedItems = calculateModifiedQuoteItemFields(
          data,
          quoteOffering,
          overrideOfferingDirtyFields,
          allowOptionalProducts,
        );
        // Ensure data is truly dirty before making API call
        if (
          Array.from(modifiedItems.values()).every(
            (dirtyFields) => dirtyFields.size === 0,
          )
        ) {
          logger.info('No changes detected, skipping quote offering update');
          return;
        } else if (
          // BP-6928 - don't allow billing frequency changes for scheduled offerings
          modifiedItems.get('header')?.has('rateId') &&
          !isOfferingRateChangeAllowed({
            ratesById: offeringRatesById,
            oldRateId: quoteOffering.rateId,
            newRateId: rateId!,
            isPartOfScheduledChange:
              isChildOffering || !!childQuoteOfferings.length,
          })
        ) {
          addToast({
            summary: 'Rate change not allowed',
            detail: `Changing frequency (e.g., Monthly, Annually) or timing (Advance, Arrears) at scheduled change is not allowed.`,
            severity: 'warning',
          });
          setValue('rateId', quoteOffering.rateId);
          return;
        }
        logger.info('Modified quote item fields', modifiedItems);
        setOfferingLoadingState((prevValue) => {
          if (!quoteOffering) {
            return prevValue;
          }
          return {
            ...prevValue,
            [quoteOffering.id]: {
              id: quoteOffering.id,
              isLoading: true,
              isDeleting: false,
              modifiedItems,
            },
          };
        });
      }

      const rateDescription = offeringRatesById[rateId!]?.description;

      // if the rate changes, default the description to the new rate's description - otherwise retain the existing description
      const currDescription =
        rateId !== quoteOffering?.rateId ? rateDescription : data.description;
      const description =
        currDescription || rateDescription || offering?.description;

      if (description !== data.description) {
        setValue('description', description);
      }

      const productsById = arrayToObject(offering?.products || [], 'id');
      const quoteOfferingReq = {
        offeringId,
        rateId,
        discountIds: discountIds && discountIds.length > 0 ? discountIds : null, // if there is no discountIds it will always be customDiscount
        ...rest,
        description,
        items:
          items &&
          items.length > 0 &&
          items.map((quoteItem: IQuoteItemReqSchema) => {
            const product = productsById[quoteItem.productId];
            const isCustomPrice =
              offeringRate?.prices.find(
                (price: IPriceResSchema) =>
                  price?.product?.id === quoteItem.productId,
              )?.priceModel === PriceModelEnum.CUSTOM;

            const discountFields: Pick<
              Partial<IQuoteItemReqSchema>,
              | 'customDiscountType'
              | 'customDiscountAmountOrPercent'
              | 'customDiscountDescription'
              | 'customDiscountName'
            > = {};
            if (!enableCustomDiscounts) {
              discountFields.customDiscountType = null;
              discountFields.customDiscountAmountOrPercent = null;
              discountFields.customDiscountDescription = null;
              discountFields.customDiscountName = null;
            }

            return {
              ...quoteItem,
              ...discountFields,
              description: quoteItem.description || product?.description,
              sku: quoteItem.sku || product?.sku,
              customPrice: isCustomPrice
                ? {
                    ...quoteItem.customPrice,
                    unitAmount: quoteItem.customPrice?.unitAmount || 0,
                  }
                : undefined,
              // Give default custom discount type when amount is greater than 0. It's not selected for optional product. https://monetizenow.atlassian.net/browse/BP-12377
              customDiscountType:
                quoteItem.customDiscountAmountOrPercent &&
                quoteItem.customDiscountAmountOrPercent > 0
                  ? AmountUnitTypeEnum.PERCENTAGE
                  : null,
            };
          }),
      } as IQuoteOfferingReqSchema;

      if (useAmendmentV2 || allowChangingQuoteOfferingStartAndEndDate) {
        quoteOfferingReq.startDate = startDate;
        quoteOfferingReq.endDate = endDate;
      }
      if (
        quoteOffering &&
        isChildOffering &&
        data.schedule?.startDate &&
        quoteOffering.startDate !== data.schedule?.startDate
      ) {
        quoteOfferingReq.startDate = undefined;
        quoteOfferingReq.schedule = {
          startDate: data.schedule.startDate,
          parentQuoteOfferingId: quoteOffering?.parentQuoteOfferingId!,
        };
      } else if (quoteOffering && isChildOffering) {
        quoteOfferingReq.startDate = undefined;
      }

      if (isAmendment && !startDate) {
        quoteOfferingReq.startDate = getRelevantOfferingStartDate();
      }

      let offeringRes: IQuoteOfferingRespSchema | null = null;
      let action: OnQuoteOfferingChangeAction;
      // Check if previous offering id and current offering id is equal
      if (quoteOffering && quoteOffering.id) {
        action = 'UPDATE';
        if (offeringId === quoteOffering.offeringId) {
          offeringRes = await updateQuoteOffering(
            quoteOffering.id,
            quoteOfferingReq,
          );
        }
      } else {
        action = 'ADD';
        offeringRes = await createQuoteOffering(quoteOfferingReq);
      }

      const requiresSecondUpdate =
        !isSecondUpdate &&
        offeringRes &&
        TEMP_SALES_DEMO_MEDINSIGHT_checkIfSecondUpdateIsRequired({
          salesDemoMedinsightTempFormulaCustomPricing,
          priorQuoteOffering: quoteOffering,
          quoteOfferingResponse: offeringRes,
        });

      // For newly added offering, reset the form state of the placeholder to add a new offering
      if (isNewQuoteOfferingForm) {
        reset({
          ...quoteOfferingInitial,
          endDate: quote?.contractEndDate,
        });
        setOffering(undefined);
      }

      setOfferingLoadingState((prevValue) => {
        if (!quoteOffering) {
          return prevValue;
        }
        return {
          ...prevValue,
          [quoteOffering.id]: {
            ...prevValue[quoteOffering.id],
            isLoading: false,
            isDeleting: false,
          },
        };
      });

      // Tell parent that data changes, this will re-fetch quote and update UI with price updates
      doUpdateParent && offeringRes && onOfferingChange(action!, offeringRes);

      // THIS IS A TEMPORARY HACK FOR MEDINSIGHT DEMO
      if (requiresSecondUpdate) {
        onSubmit({
          data: getValues(),
          overrideOfferingDirtyFields,
          isSecondUpdate: true,
          quoteOfferingOverride: offeringRes,
        });
      }
      doUpdateParent && setIsBulkOfferingLoading(false);

      setProductsLoading(false);
    };

    const handleInitialQuoteItemQuantityForBulkOffering = async ({
      rateId,
      items,
    }: BulkOfferingItemQuantityHandlerProps) => {
      try {
        const isQuoteItemQuantityChangeAllowed = quoteOffering
          ? isOfferingRateChangeAllowed({
              ratesById: offeringRatesById,
              oldRateId: quoteOffering.rateId,
              newRateId: rateId!,
              isPartOfScheduledChange:
                isChildOffering || !!childQuoteOfferings.length,
            })
          : true;

        if (isQuoteItemQuantityChangeAllowed) {
          const rateRes = await fetchOfferingRate(rateId);
          if (rateRes) {
            return items.map(({ productId }: { productId: string }) => {
              const quantity = rateRes.productPriceRanges[productId!]?.min ?? 0; // Default quantity to 0 if not found
              return {
                productId,
                quantity,
              };
            });
          }
        }

        // return items as is if rate change is not allowed
        return items.map(
          ({
            productId,
            quantity,
          }: {
            productId: string;
            quantity: number;
          }) => {
            return {
              productId,
              quantity,
            };
          },
        );
      } catch (error) {
        handleApiErrorToast(error);
        Sentry.captureException(error, {
          tags: {
            type: 'RATE_API',
          },
        });
        return [];
      }
    };

    // Based on the latest rateId it gets minimum quantity from each quoteItem and sets the default quantity
    const handleInitialQuoteItemQuantity = async (
      rateId: string,
      updatedProductItems?: IQuoteItemReqSchema[],
    ) => {
      try {
        const isQuoteItemQuantityChangeAllowed = quoteOffering
          ? isOfferingRateChangeAllowed({
              ratesById: offeringRatesById,
              oldRateId: quoteOffering.rateId,
              newRateId: rateId!,
              isPartOfScheduledChange:
                isChildOffering || !!childQuoteOfferings.length,
            })
          : true;

        if (isQuoteItemQuantityChangeAllowed) {
          const rateRes = await fetchOfferingRate(rateId);
          if (rateRes) {
            (updatedProductItems ?? watchItems).forEach(
              ({ id, productId }, index) => {
                const quoteItem = quoteOffering?.items?.find(
                  ({ id: quoteItemId }) => quoteItemId === id,
                );
                const previousQuantity = quoteItem?.quantity ?? 0;

                // Calculate initial quantity from rateRes
                const min = rateRes.productPriceRanges[productId]?.min;
                const max = rateRes.productPriceRanges[productId]?.max;

                const quantity = clampValueToRange(
                  previousQuantity,
                  min,
                  max ?? Infinity,
                );

                const inputName =
                  `items.${index}.quantity` as keyof IQuoteOfferingReqSchema;
                setValue(inputName, `${quantity}`);
              },
            );
          }
        }
      } catch (error) {
        handleApiErrorToast(error);
        Sentry.captureException(error, {
          tags: {
            type: 'RATE_API',
          },
        });
      }
    };

    const onError = (error: any) => {
      logger.warn('[FORM ERROR]', error);
      // TODO: should we notify user of error?
      // handleApiErrorToast(error); // this is not valid
    };

    const handleQuoteOffering = () => {
      if (isDirty && isValid) {
        handleSubmit((data) => onSubmit({ data }), onError)();
      }
    };

    const handleQuoteOfferingWithoutDirtyCheck = debounce(
      (overrideOfferingDirtyFields?: string[]) => {
        handleSubmit(
          (data) =>
            onSubmit({
              data,
              overrideOfferingDirtyFields,
            }),
          onError,
        )();
      },
      300,
    );

    /**
     * Rate was saved or updated
     */
    const handleSaveCustomRate = async (rate: IRateResSchema) => {
      if (offering) {
        // add rate to offering (in memory, not api)
        await handleInitialQuoteItemQuantity(rate.id);
        if (offering.id === inactiveOffering?.id) {
          // Since the offering is inactive we should refetch the offering
          // instead of updating the offering list
          refetchInactiveOffering();
        } else {
          addOrUpdateOfferingRate(offering.id, rate);
        }
        setValue('rateId', rate.id);
        // Ensure rate is marked as modified so that pricing is re-calculated
        handleQuoteOfferingWithoutDirtyCheck(['rateId']);
      }
    };

    const useQOAmendV2State = useQuoteOfferingAmendV2({
      quoteOffering,
      isChildOffering,
      childQuoteOfferings,
      revertQuoteOffering,
      onChange: onOfferingChange,
      parentQuoteOffering,
      index: offeringIndex,
      nextScheduledQuoteOffering,
      isRemoved,
      quoteOfferings,
    });
    const {
      isAmendV2Locked,
      isQOAddedToAmendment,
      handleUnlock: handleUnlockAmendV2,
      showRevertToOriginal,
      isEndingBeforeContractEnd,
      offeringEndDate,
      isOfferingEndBeforeContract,
    } = useQOAmendV2State;

    useEffect(() => {
      setParentScheduleChangeActiveProp &&
        setParentScheduleChangeActiveProp(scheduleChangeActive);
    }, [scheduleChangeActive, setParentScheduleChangeActiveProp]);

    useEffect(() => {
      (async () => {
        if (
          isNewQuoteOfferingForm &&
          !watchRateId &&
          rateSelects[0].length === 1 &&
          offering &&
          areAllItemsInOfferingMandatory(offering)
        ) {
          // If there is only one rate when new offering is created, select that rate by default
          setValue('rateId', rateSelects[0][0].id);
          await handleInitialQuoteItemQuantity(rateSelects[0][0].id);
          handleQuoteOfferingWithoutDirtyCheck();
        }
      })();
    }, [isNewQuoteOfferingForm, rateSelects, watchRateId, setValue, offering]);

    if (!(quote && quote.id)) {
      return null;
    }

    const quoteOfferingLoading =
      isLoading ||
      isOfferingLoading ||
      isRateLoading ||
      isOfferingDeleting ||
      scheduleChangeLoading;

    // If Quote is readonly or useAmendV2 and isAmendV2Locked, then it should be readonly
    const isReadOnly =
      isReadOnlyQuote ||
      (!isNewQuoteOfferingForm && useAmendmentV2 && isAmendV2Locked);
    const showUnlockIcon =
      useAmendmentV2 &&
      isAmendV2Locked &&
      !isQOAddedToAmendment &&
      showRevertToOriginal;

    /** When schedule change is being created, and start date of the new segment is updated
     * We need to update the previous schedule change segment end date = new segment start date - 1 day
     * This does not update backend until schedule change is actually created.
     */
    const updatePrevScheduleChange = ({ endDate }: { endDate: string }) => {
      if (quoteOffering) {
        onOfferingChange(
          'UPDATE',
          {
            ...quoteOffering,
            endDate,
          },
          undefined,
          true,
        );
      }
    };

    const filterProductsByIds = (
      selectedItems: SelectedProductsWithinOfferingForm,
      withId: boolean,
    ): IQuoteItemReqSchema[] => {
      const productIdList = selectedItems.products.map(
        (product: SelectedProductsWithinOffering) =>
          product.isSelected ? product.id : null,
      );

      let currentItems =
        offering?.offeringProducts.filter(({ product }) =>
          productIdList.includes(product.id),
        ) || [];

      if (isChildOffering) {
        currentItems = currentItems.filter(({ product }) => {
          return (
            product.productType !== ProductTypeEnum.ONETIME &&
            product.productType !== ProductTypeEnum.ONETIME_PREPAID_CREDIT
          );
        });
      }

      const existingItems = getValues('items');

      return currentItems?.map(({ product, position }) => {
        const existingItem = existingItems.find(
          (item) => item.productId === product.id,
        );

        const newProductData = {
          productId: product.id,
          description: product.description,
          sku: product.sku,
          customPrice: existingItem?.customPrice || undefined,
          customDiscountAmountOrPercent:
            existingItem?.customDiscountAmountOrPercent || 0,
          quantity: Number(existingItem?.quantity) || 0,
          position: position,
          ...existingItem,
        };

        if (withId) {
          return {
            ...newProductData,
            id: existingItem?.id,
          };
        }

        return newProductData;
      });
    };

    const addOfferingItems = async (
      products: SelectedProductsWithinOfferingForm,
    ) => {
      if (!offering) {
        return;
      }

      const rateId = watchRateId ?? offering.rates[0]?.id;

      if (offering.rates.length === 1) {
        setValue('rateId', rateId);
        await handleInitialQuoteItemQuantity(rateId);
      }

      // Filter and set the updated product items
      const updatedProductItems = filterProductsByIds(products, false);
      setValue('items', updatedProductItems);

      // Handle quote offering and close relevant UI components
      handleQuoteOfferingWithoutDirtyCheck();
      onManageProductModalClose();
    };

    const updateOfferingItems = async (
      products: SelectedProductsWithinOfferingForm,
    ) => {
      if (!offering) {
        return;
      }

      const rateId = watchRateId ?? offering.rates[0]?.id;

      // Filter and set the updated product items
      const updatedProductItems = filterProductsByIds(products, true);
      setValue('items', updatedProductItems);

      await handleInitialQuoteItemQuantity(rateId, updatedProductItems);

      // Handle quote offering and close relevant UI components
      handleQuoteOfferingWithoutDirtyCheck();
    };

    const savePriceTiers = async (priceTiers: IPriceTierSettingsReq) => {
      const updatedOfferingItems = watchItems.map((item) => ({
        ...item,
        options: {
          ...item.options,
          priceDisplayMode: priceTiers.priceDisplayMode,
          displayedPriceIds: priceTiers.displayedPriceIds[item.productId],
        },
      }));
      setValue('items', updatedOfferingItems);

      handleQuoteOfferingWithoutDirtyCheck();
    };

    // If new offering, it should check offering. For quote offering (not new), should check quoteOffering
    const isCurrentOfferingOneTime =
      (!isNewQuoteOfferingForm && isOfferingOnetime) ||
      (isNewQuoteOfferingForm && offering?.type === OfferingTypesEnum.ONETIME);

    if (
      guidedQuoteOfferingSelection &&
      !watchOffering &&
      !(isChildOffering || isReadOnlyAndNotEmptyOffering)
    ) {
      return (
        <MBox py={2}>
          <MStack pl={2}>
            {quoteOfferings && quoteOfferings?.length > 0 && (
              <MText fontSize="md" fontWeight="medium">
                Add Offerings
              </MText>
            )}
            <BulkOfferingFlow
              disabled={
                isOfferingUnselectable ||
                isOfferingDeleting ||
                isRemoved ||
                isReadOnly
              }
              items={
                getOfferingList(
                  productOfferings,
                  inactiveOffering,
                ) as IOfferingRes[]
              }
              isBulkOfferingLoading={isBulkOfferingLoading}
              quote={quote}
              quoteData={quoteData}
              displayImportOffering={displayImportOffering}
              setIsBulkOfferingLoading={setIsBulkOfferingLoading}
              onSubmit={onSubmit}
              handleInitialQuoteItemQuantityForBulkOffering={
                handleInitialQuoteItemQuantityForBulkOffering
              }
              fetchQuote={fetchQuote}
            />
          </MStack>
        </MBox>
      );
    }

    return (
      <>
        <MBox
          data-testid="offering-select-form"
          bgColor={useQuoteEditV2 ? bgColorV2 : bgColor}
          opacity={isRemoved ? 0.75 : 1}
        >
          {!isChildOffering && (
            <MGrid
              gridTemplateColumns={gridColumns}
              columnGap={6}
              py={2}
              alignItems="center"
              bg={
                useQuoteEditV2
                  ? !isNewQuoteOfferingForm
                    ? 'tWhite.300'
                    : 'inherit'
                  : 'tWhite.base'
              }
              px="1"
            >
              <MFlex w="full">
                {watchOffering && (
                  <OfferingToggleButton
                    isOpen={isOfferingOpen}
                    offeringIndex={offeringIndex}
                    setIsOpen={(value) => {
                      setIsOfferingOpen(value);
                      if (!isOpen) {
                        setIsOpen(true);
                      }
                    }}
                  />
                )}
                <MFlex w="full" alignItems="start">
                  <MFlex w="full" direction="column">
                    <MFlex w={'full'}>
                      <QuoteOfferingCollapsibleRate
                        isOpen={!isChildOffering || isOfferingOpen}
                        rateName={
                          offeringRatesById?.[quoteOffering?.rateId!]?.name
                        }
                        quoteOffering={quoteOffering}
                        offeringEndDate={offeringEndDate}
                        isOfferingEndBeforeContract={
                          isOfferingEndBeforeContract &&
                          !parentScheduleChangeActive &&
                          !scheduleChangeActive
                        }
                        isRemoved={isRemoved}
                        useQuoteEditV2={useQuoteEditV2}
                      >
                        {!guidedQuoteOfferingSelection && (
                          <>
                            {!(
                              isChildOffering || isReadOnlyAndNotEmptyOffering
                            ) && (
                              <MFlex w="full" alignItems="center">
                                <MFormField w="full" maxW="22.125rem">
                                  <Controller
                                    control={control}
                                    name="offeringId"
                                    render={({
                                      field: { onChange, ...rest },
                                    }) => {
                                      const disabled =
                                        isOfferingUnselectable ||
                                        isOfferingDeleting ||
                                        isRateLoading ||
                                        isRemoved ||
                                        isReadOnly;

                                      return (
                                        <MCustomSelect
                                          popOverProps={{ matchWidth: false }}
                                          isDisabled={disabled}
                                          isReadOnly={disabled}
                                          variant={'readonly'}
                                          alignSelf="center"
                                          placeholder="Select Offering"
                                          items={getOfferingList(
                                            productOfferings,
                                            inactiveOffering,
                                          )}
                                          itemTitle="name"
                                          itemValue="id"
                                          onChange={(offeringId) => {
                                            onChange(offeringId);
                                            offeringId &&
                                              handleOfferingChange(offeringId);
                                          }}
                                          flex="1"
                                          popOverContentProps={{
                                            minW: '200px',
                                          }}
                                          showQueryInput
                                          loading={isLoadingOfferings}
                                          cursor={'default'}
                                          inputProps={{
                                            sx: {
                                              '&[disabled]': {
                                                cursor: 'default',
                                              },
                                            },
                                          }}
                                          {...rest}
                                        />
                                      );
                                    }}
                                  />
                                </MFormField>
                                {showDuplicatedOfferingWarn && (
                                  <MTooltip
                                    label="This offering already exists on this quote. Consider modifying the existing offering."
                                    placement="bottom-start"
                                  >
                                    <MFlex alignItems="center" ml="2">
                                      <MIcon
                                        as={MdInfo}
                                        color="tOrange.tangerine"
                                      />
                                    </MFlex>
                                  </MTooltip>
                                )}
                              </MFlex>
                            )}
                            {!isChildOffering &&
                              isReadOnlyAndNotEmptyOffering && (
                                <MBox alignSelf="center">
                                  <MFlex mt={1} align="center" justify="center">
                                    <MText
                                      fontWeight="bold"
                                      mr={1}
                                      noOfLines={1}
                                    >
                                      {quoteOffering?.offeringName}
                                      {quoteOffering?.rateName ? ' - ' : ''}
                                      {quoteOffering?.rateName}
                                    </MText>
                                    {isLocked && (
                                      <MTooltip
                                        label="Changes cannot be made to this offering."
                                        placement="bottom-start"
                                        shouldWrapChildren
                                      >
                                        <MIcon
                                          color="tPurple.dark"
                                          as={MdLock}
                                          w={4}
                                          h={4}
                                          cursor="pointer"
                                        />
                                      </MTooltip>
                                    )}
                                  </MFlex>
                                  <MText>{quoteOffering?.description}</MText>
                                </MBox>
                              )}
                            {displayImportOffering && (
                              <MBox ml={3}>
                                <ImportQuoteOfferingsModal
                                  quote={quote}
                                  onImported={quoteData.setQuote}
                                />
                              </MBox>
                            )}
                            {!disableInactiveProductBanner && (
                              <QuoteOfferingInactiveItem
                                offeringIndex={offeringIndex}
                                selectedOffering={offering}
                                selectedRateId={watchRateId}
                                contractStartDate={quote.contractStartDate}
                                quoteType={quote.type}
                                isNewlyAddedOffering={newlyAddedOffering}
                              />
                            )}
                          </>
                        )}
                      </QuoteOfferingCollapsibleRate>
                    </MFlex>
                    {!isReadOnlyAndNotEmptyOffering &&
                      isOfferingOpen &&
                      !isChildOffering &&
                      !isLoadingOfferings && (
                        <MFormField w="full" mt={1} pr="8">
                          <Controller
                            control={control}
                            name="description"
                            render={({
                              field: { onChange, value, ...rest },
                            }) => {
                              const isEditDisabled =
                                isRemoved ||
                                isOfferingLoading ||
                                !!isLocked ||
                                !showQuoteOfferingDescription ||
                                isReadOnly;
                              if (isNewQuoteOfferingForm) {
                                return (
                                  <MBox>
                                    {offering?.description && (
                                      <MText lineHeight="24px">
                                        {offering?.description}
                                      </MText>
                                    )}
                                  </MBox>
                                );
                              }
                              return (
                                <MEditableInput
                                  isDisabled={isEditDisabled}
                                  value={value || ''}
                                  showCancel
                                  showSave
                                  onSubmit={(currValue) => {
                                    currValue = (currValue || '').trim();
                                    onChange(currValue);
                                    handleQuoteOfferingWithoutDirtyCheck();
                                  }}
                                  onChange={onChange}
                                  onCancel={onChange}
                                  previewProps={{
                                    fontSize: 'sm',
                                    px: 0,
                                    py: 0,
                                    minH: '1.5rem',
                                    _hover: !isEditDisabled
                                      ? {
                                          background: 'tGray.support',
                                          border: '1px solid',
                                          borderColor: 'tGray.lightPurple',
                                          borderRadius: '3px',
                                          px: 2,
                                        }
                                      : {},
                                  }}
                                  inputProps={{
                                    value: value || '',
                                    py: 0,
                                    height: '1.5rem',
                                  }}
                                  {...rest}
                                />
                              );
                            }}
                          />
                        </MFormField>
                      )}
                  </MFlex>
                </MFlex>
              </MFlex>
              {!isOfferingOpen && (
                <QuoteOfferingDate
                  isOfferingOneTime={
                    offeringRate?.billingFrequency ===
                    RateBillingFrequencyEnum.ONETIME
                  }
                  isOpen={isOfferingOpen}
                  childOfferingCount={childQuoteOfferings?.length || 0}
                  isChildOffering={isChildOffering}
                  quoteStartDate={
                    quote.contractAmendmentDate || quote.contractStartDate
                  }
                  quoteEndDate={quote.contractEndDate}
                  startDate={quoteOffering?.startDate}
                  endDate={quoteOffering?.endDate}
                  isLastSegment={isLastSegment}
                  childQuoteOfferings={childQuoteOfferings}
                  offeringEndDate={offeringEndDate}
                  isOfferingEndBeforeContract={
                    isOfferingEndBeforeContract &&
                    !parentScheduleChangeActive &&
                    !scheduleChangeActive
                  }
                />
              )}

              <MFlex
                alignItems="center"
                alignSelf="flex-start"
                gridColumn="3/-1"
                justifySelf="flex-end"
                position="relative"
              >
                {!useAmendmentV2 ||
                isQOAddedToAmendment ||
                isNewQuoteOfferingForm ? (
                  <QuoteOfferingActions
                    quoteOffering={quoteOffering}
                    isChildOffering={isChildOffering}
                    childQuoteOfferings={childQuoteOfferings}
                    onChange={onOfferingChange}
                    parentQuoteOffering={parentQuoteOffering}
                    quoteOfferingState={quoteOfferingState}
                    offering={offering}
                    quoteOfferingLoading={quoteOfferingLoading}
                    isOfferingOpen={isOfferingOpen}
                    updateQuoteOffering={updateQuoteOffering}
                    deleteQuoteOffering={deleteQuoteOffering}
                    removeQuoteOffering={removeQuoteOffering}
                    quoteOfferingFormState={quoteOfferingFormState}
                    handleQuoteOfferingUpdate={({ priceDisplay }) => {
                      if (priceDisplay) {
                        setValue(
                          'options',
                          {
                            ...quoteOffering?.options,
                            priceDisplay,
                          },
                          { shouldDirty: true },
                        );
                        handleSubmit((data) => onSubmit({ data }), onError)();
                      }
                    }}
                    offeringRate={offeringRate}
                  />
                ) : (
                  <QuoteOfferingActionsAmendV2
                    quoteOffering={quoteOffering}
                    isChildOffering={isChildOffering}
                    quoteOfferingLoading={quoteOfferingLoading}
                    isOfferingOpen={isOfferingOpen}
                    quoteOfferingState={quoteOfferingState}
                    onChange={onOfferingChange}
                    updateQuoteOffering={updateQuoteOffering}
                    deleteQuoteOffering={deleteQuoteOffering}
                    quoteOfferingFormState={quoteOfferingFormState}
                    useQuoteOfferingAmendV2State={useQOAmendV2State}
                    removeQuoteOffering={removeQuoteOffering}
                    offering={offering}
                    handleAddScheduledChange={handleAddScheduledChange}
                    childQuoteOfferings={childQuoteOfferings}
                    quoteOfferings={quoteOfferings || []}
                    handleQuoteOfferingUpdate={({ priceDisplay }) => {
                      if (priceDisplay) {
                        setValue(
                          'options',
                          {
                            ...quoteOffering?.options,
                            priceDisplay,
                          },
                          { shouldDirty: true },
                        );
                        handleSubmit((data) => onSubmit({ data }), onError)();
                      }
                    }}
                    offeringRate={offeringRate}
                    monthInterval={monthInterval}
                  />
                )}
              </MFlex>
            </MGrid>
          )}
          {isOfferingOpen && <MDivider />}

          {offering &&
            watchOffering &&
            (isOfferingOpen || isRootOfferingOpen) && (
              <MBox
                onMouseOver={() => setShowUnlockTooltip(true)}
                onMouseOut={() => setShowUnlockTooltip(false)}
              >
                <MGrid
                  gridTemplateColumns={gridColumns}
                  columnGap={6}
                  py={2}
                  alignItems={isOpen ? 'flex-end' : 'center'}
                >
                  {offering && watchOffering && (
                    <MFlex gridColumn={'1 / 3'} direction="column">
                      <MFlex alignItems={isOpen ? 'start' : 'center'} pl={'7'}>
                        <RateToggleButton
                          isOpen={isOpen}
                          offeringIndex={offeringIndex}
                          setIsOpen={setIsOpen}
                        />
                        {offering && watchOffering && isOpen && (
                          <MFlex flexDirection="column" gap={2}>
                            <MFormField
                              label="Rate"
                              error={errors.rateId}
                              alignSelf="center"
                              maxW="10.25rem"
                            >
                              <Controller
                                control={control}
                                name="rateId"
                                render={({
                                  field: { onChange, value, ...rest },
                                }) => {
                                  const disabled =
                                    isRateLoading ||
                                    isOfferingDeleting ||
                                    isRemoved ||
                                    !watchOffering ||
                                    isReadOnly ||
                                    scheduleChangeLoading;

                                  return (
                                    <MCustomSelect
                                      isDisabled={disabled}
                                      isReadOnly={disabled}
                                      variant={
                                        disabled ? 'readonly' : 'primary'
                                      }
                                      placeholder="Select Rate"
                                      items={rateSelects[0]}
                                      disabledItems={
                                        isNewQuoteOfferingForm ||
                                        (hasNoScheduleChange &&
                                          !scheduleChangeActive)
                                          ? []
                                          : rateSelects[1].map(
                                              (rate) => rate.id,
                                            )
                                      }
                                      itemTitle="name"
                                      itemValue="id"
                                      onChange={async (e) => {
                                        await handleInitialQuoteItemQuantity(
                                          `${e}`,
                                        );
                                        onChange(e);
                                        handleQuoteOfferingWithoutDirtyCheck();
                                      }}
                                      flex={isChildOffering ? 'unset' : '1'}
                                      renderRightElement={({
                                        value: rateValue,
                                      }) =>
                                        getRateRightElement({
                                          value: rateValue,
                                          offeringRatesById,
                                        })
                                      }
                                      inputProps={getQuoteOfferingRateInputProps(
                                        quoteOffering,
                                        isAmendment || isRenewal,
                                        isChildOffering,
                                      )}
                                      value={value}
                                      renderItemContent={RateItemContent}
                                      showQueryInput
                                      {...rest}
                                    />
                                  );
                                }}
                              />
                            </MFormField>
                          </MFlex>
                        )}
                        <MBox alignSelf="end">
                          <QuoteOfferingInactiveRateItem
                            offeringIndex={offeringIndex}
                            selectedOffering={offering}
                            selectedRateId={watchRateId}
                            contractStartDate={quote.contractStartDate}
                            rates={rateSelects[0]}
                            quoteType={quote.type}
                            isNewlyAddedOffering={newlyAddedOffering}
                          />
                        </MBox>
                        {isOpen && (
                          <MFormField
                            label={
                              isCurrentOfferingOneTime ? 'Date' : 'Start Date'
                            }
                            alignSelf="center"
                            maxW="10.25rem"
                            ml="4"
                          >
                            <QuoteOfferingScheduledEffectiveDate
                              isChildOffering={isChildOffering}
                              isDisabled={
                                (!quoteOfferingDateAndScheduledChangeRate &&
                                  isChildOffering &&
                                  // Amend and !AmendV2 or AmendV2 and isAmendV2Locked, then lock
                                  // We need to unlock useAmendV2 and !isAmendV2Locked
                                  ((isAmendment && !useAmendmentV2) ||
                                    (useAmendmentV2 && isAmendV2Locked))) ||
                                isReadOnly ||
                                isRemoved ||
                                (isNewQuoteOfferingForm &&
                                  !allowChangingQuoteOfferingStartAndEndDate &&
                                  !useAmendmentV2) ||
                                isOfferingDeleting ||
                                (!isChildOffering &&
                                  !allowChangingQuoteOfferingStartAndEndDate &&
                                  !useAmendmentV2) ||
                                scheduleChangeLoading
                              }
                              isReadOnly={
                                isReadOnly ||
                                (!isChildOffering &&
                                  !useAmendmentV2 &&
                                  !allowChangingQuoteOfferingStartAndEndDate) ||
                                isRemoved
                              }
                              isOfferingLoading={isOfferingLoading}
                              quote={quote}
                              control={control}
                              priorScheduledQuoteOffering={
                                priorScheduledQuoteOffering
                              }
                              nextScheduledQuoteOffering={
                                nextScheduledQuoteOffering
                              }
                              onChange={(date) => {
                                if (isChildOffering) {
                                  setValue(
                                    'schedule.parentQuoteOfferingId',
                                    quoteOffering?.parentQuoteOfferingId!,
                                  );
                                }
                                if (isCurrentOfferingOneTime) {
                                  setValue(
                                    isChildOffering
                                      ? 'schedule.endDate'
                                      : 'endDate',
                                    formatISO(addDays(parseISO(date), 1), {
                                      representation: 'date',
                                    }),
                                  );
                                }
                                handleQuoteOfferingWithoutDirtyCheck();
                              }}
                              parentQuoteOffering={quoteOffering}
                              offering={offering}
                              monthInterval={monthInterval}
                              isUsingDelayedBilling={isUsingDelayedBilling}
                              isAmendment={isAmendment}
                            />
                          </MFormField>
                        )}
                        {/* Do not show end date when it's one time offering https://monetizenow.atlassian.net/browse/BP-11186 */}
                        {isOpen && !isCurrentOfferingOneTime && (
                          <MFormField
                            label="End Date"
                            alignSelf="center"
                            maxW="10.25rem"
                            ml="4"
                            tooltip={
                              isEndingBeforeContractEnd &&
                              !scheduleChangeActive &&
                              !isOfferingOnetime
                                ? 'Offering ends before end of Contract'
                                : ''
                            }
                            tooltipColor="tOrange.tangerine"
                            onMouseOver={(ev) => {
                              ev.stopPropagation();
                            }}
                          >
                            <QuoteOfferingScheduledEndDate
                              isChildOffering={isChildOffering}
                              isDisabled={
                                (!quoteOfferingDateAndScheduledChangeRate &&
                                  isChildOffering &&
                                  // Amend and !AmendV2 or AmendV2 and isAmendV2Locked, then lock
                                  // We need to unlock useAmendV2 and !isAmendV2Locked
                                  ((isAmendment && !useAmendmentV2) ||
                                    (useAmendmentV2 && isAmendV2Locked))) ||
                                isReadOnly ||
                                isRemoved ||
                                (isNewQuoteOfferingForm &&
                                  !allowChangingQuoteOfferingStartAndEndDate &&
                                  !useAmendmentV2) ||
                                isOfferingDeleting ||
                                (!isChildOffering &&
                                  !allowChangingQuoteOfferingStartAndEndDate &&
                                  !useAmendmentV2) ||
                                scheduleChangeLoading
                              }
                              isReadOnly={
                                isReadOnly ||
                                (!useAmendmentV2 &&
                                  !allowChangingQuoteOfferingStartAndEndDate) ||
                                isRemoved
                              }
                              isOfferingLoading={isOfferingLoading}
                              quote={quote}
                              control={control}
                              priorScheduledQuoteOffering={
                                priorScheduledQuoteOffering
                              }
                              nextScheduledQuoteOffering={
                                nextScheduledQuoteOffering
                              }
                              onChange={() => {
                                if (isChildOffering) {
                                  setValue(
                                    'schedule.parentQuoteOfferingId',
                                    quoteOffering?.parentQuoteOfferingId!,
                                  );
                                }
                                handleQuoteOfferingWithoutDirtyCheck();
                              }}
                              parentQuoteOffering={quoteOffering}
                              offering={offering}
                              monthInterval={monthInterval}
                              isUsingDelayedBilling={isUsingDelayedBilling}
                            />
                          </MFormField>
                        )}
                        {!isOpen && offering && (
                          <MFlex gap="2" alignItems="center">
                            <MText noOfLines={1}>
                              {quoteOffering?.startDate
                                ? toDateShort(quoteOffering.startDate)
                                : ''}
                            </MText>
                            {watchRateId && (
                              <MFlex gap="2" alignItems="center">
                                <MText fontWeight="600" noOfLines={1}>
                                  Rate{' '}
                                  <MText fontWeight="normal" as="span">
                                    {offeringRatesById?.[watchRateId]?.name ||
                                      inactiveRate?.name}
                                  </MText>
                                </MText>
                                {offeringRatesById?.[watchRateId] && (
                                  <QuoteOfferingRateRightEl
                                    rate={offeringRatesById[watchRateId]}
                                  />
                                )}
                              </MFlex>
                            )}
                          </MFlex>
                        )}
                      </MFlex>
                    </MFlex>
                  )}

                  <MBox />

                  {isQuoteOfferingDiscountAvailable ? (
                    <QuoteOfferingDiscount
                      isOpen={isOpen}
                      quoteOffering={quoteOffering}
                      quoteItems={watchItems}
                      errors={errors}
                      control={control}
                      offeringId={watchOffering}
                      rateId={watchRateId}
                      discounts={discounts || null}
                      discountIds={watchDiscountIds}
                      disabled={
                        (isAmendment && !useAmendmentV2 && hasChildOfferings) ||
                        isRateLoading ||
                        isOfferingDeleting ||
                        scheduleChangeLoading
                      }
                      enableCustomDiscounts={enableCustomDiscounts}
                      handleQuoteOfferingWithoutDirtyCheck={
                        handleQuoteOfferingWithoutDirtyCheck
                      }
                      isRemoved={isRemoved}
                      isReadOnly={isLocked || isReadOnly}
                      setEnableCustomDiscounts={setEnableCustomDiscounts}
                      setValue={setValue}
                    />
                  ) : (
                    <MBox /> // we still need this because we use grid system and need placeholder.
                  )}

                  {!(quote?.type === QuoteTypeEnum.NEW || useAmendmentV2) && (
                    <MBox className={quote?.type} />
                  )}

                  <MFlex
                    alignItems="center"
                    justifyContent="end"
                    gridColumn="span 3"
                    alignSelf="end"
                  >
                    {quoteOffering?.id &&
                      offering &&
                      watchRateId &&
                      !isNewQuoteOfferingForm && (
                        <MFlex alignItems="center" justifyContent="end">
                          {!isRemoved && !isAmendV2Locked && (
                            <QuoteDrawer
                              offering={offering}
                              allowPriceCustomization={allowPriceCustomization}
                              account={{
                                id: quote.accountId,
                                accountName: quote.accountName,
                              }}
                              isOfferingOpen={isOpen}
                              quoteOffering={quoteOffering}
                              offeringRate={offeringRate}
                              orderedPricesUnderProduct={
                                orderedPricesUnderProduct
                              }
                              disabled={isOfferingLoading || isOfferingDeleting}
                              onRateSaved={handleSaveCustomRate}
                              setOfferingRate={setOfferingRate}
                              setOrderedPricesUnderProduct={
                                setOrderedPricesUnderProduct
                              }
                              onDrawerToggle={(isDrawerOpen) => {
                                setDrawerOpen(isDrawerOpen);
                              }}
                              offeringType={offering.type}
                              modalConfig={{
                                isOpen: isDrawerOpen,
                                onClose: onDrawerClose,
                                onOpen: onDrawerOpen,
                              }}
                              updateOfferingItems={updateOfferingItems}
                              isQuoteOfferingProcessing={
                                isQuoteOfferingProcessing
                              }
                              areProductsOptional={
                                !areAllItemsInOfferingMandatory(offering)
                              }
                              setProductsLoading={setProductsLoading}
                              isProductsLoading={isProductsLoading}
                              savePriceTiers={savePriceTiers}
                            />
                          )}
                        </MFlex>
                      )}
                    {!isOpen && (
                      <QuoteOfferingAmount quoteOffering={quoteOffering} />
                    )}
                    <MBox alignSelf="center" justifySelf="end">
                      {(isOfferingLoading || scheduleChangeLoading) && (
                        <MFlex
                          justifyContent="center"
                          alignItems="center"
                          minW="6"
                        >
                          <MCircularProgress isIndeterminate size={4} />
                        </MFlex>
                      )}
                      {isChildOffering &&
                      // if not amend, or amendV2 and newly added offerign, or amendV2 and unlocked
                      // then we show X button to delete schedule change
                      (!isAmendment ||
                        (isAmendment && isQOAddedToAmendment) ||
                        (useAmendmentV2 && !isAmendV2Locked)) &&
                      !isOfferingLoading &&
                      !isReadOnly &&
                      !scheduleChangeLoading &&
                      !isOfferingDeleting &&
                      !isRemoved ? (
                        <MCloseButton
                          minH="6"
                          minW="6"
                          mr="1"
                          onClick={() => handleRemoveScheduledChange()}
                        />
                      ) : (
                        <MBox minW="6" />
                      )}

                      {showUnlockIcon &&
                        !isOfferingLoading &&
                        !scheduleChangeLoading &&
                        !isOfferingDeleting &&
                        !isReadOnly && (
                          <MTooltip
                            label="Click to make this segment editable"
                            placement="bottom-start"
                            isOpen={showUnlockTooltip}
                            onOpen={() => setShowUnlockTooltip(true)}
                          >
                            <MCustomIconButton
                              variant="icon"
                              btnSize={4}
                              containerSize={6}
                              p={3}
                              icon={MdLock}
                              onClick={() => handleUnlockAmendV2()}
                            />
                          </MTooltip>
                        )}
                    </MBox>
                  </MFlex>

                  {isOpen &&
                    allowOptionalProducts &&
                    !areAllItemsInOfferingMandatory(offering) && (
                      <MFlex ml={8} pl={1} mt={2}>
                        <ManageProducts
                          isManageProductDisabled={isAmendV2Locked || isRemoved}
                          quoteOffering={quoteOffering}
                          watchRateId={watchRateId}
                          offering={offering}
                          quoteItems={watchItems}
                          updateOfferingItems={addOfferingItems}
                          modalConfig={{
                            isOpen: isManageProductModalOpen,
                            onClose: handleOnModalClose,
                            onDrawerClose,
                            onDrawerOpen,
                          }}
                          setDrawerOpen={setDrawerOpen}
                        />
                      </MFlex>
                    )}
                </MGrid>

                <MDivider />

                <QuoteItems
                  offering={offering}
                  parentQuoteOffering={parentQuoteOffering}
                  offeringRate={offeringRate}
                  quoteOffering={quoteOffering}
                  priorScheduledQuoteOffering={priorScheduledQuoteOffering}
                  rateId={watchRateId}
                  quoteItems={watchItems}
                  control={control}
                  enableCustomDiscounts={enableCustomDiscounts}
                  isOpen={isOpen}
                  handleQuoteOffering={handleQuoteOffering}
                  handleQuoteOfferingWithoutDirtyCheck={
                    handleQuoteOfferingWithoutDirtyCheck
                  }
                  isRemoved={isRemoved}
                  isReadOnly={isLocked || isReadOnly}
                  setValue={setValue}
                  setError={setError}
                  quotePrices={quotePrices}
                  displayConfig={displayConfig}
                  isQOAddedToAmendment={isQOAddedToAmendment}
                  isDisabled={scheduleChangeLoading}
                  orderedPricesUnderProduct={orderedPricesUnderProduct}
                />
              </MBox>
            )}
        </MBox>
        {!isOfferingOpen && <MDivider />}
        <QuoteOfferingScheduleChange
          quoteOffering={quoteOffering}
          isChildOffering={isChildOffering}
          childQuoteOfferings={childQuoteOfferings}
          parentQuoteOffering={parentQuoteOffering}
          nextScheduledQuoteOffering={nextScheduledQuoteOffering}
          priorScheduledQuoteOffering={priorScheduledQuoteOffering}
          quoteOfferingState={quoteOfferingState}
          offering={offering}
          isOfferingOpen={isOfferingOpen}
          scheduleChangeActive={scheduleChangeActive}
          isOpen={isOpen}
          isLoadingOfferings={isLoadingOfferings}
          offeringRatesById={offeringRatesById}
          handleScheduleChangeInitiated={handleScheduleChangeInitiated}
          handleAddScheduledChange={handleAddScheduledChange}
          scheduleChangeLoading={scheduleChangeLoading}
          setScheduleChangeActive={setScheduleChangeActive}
          inactiveRate={inactiveRate}
          rateSelects={rateSelects}
          scheduleChangeState={scheduleChangeState}
          isReadOnly={isReadOnly}
          updatePrevScheduleChange={updatePrevScheduleChange}
          onOfferingChange={onOfferingChange}
        />
        {/* Child offerings (scheduled changes) */}
        {!isChildOffering &&
          isOfferingOpen &&
          Array.isArray(childQuoteOfferings) &&
          childQuoteOfferings.map((childQuoteOffering, i) => (
            <QuoteOffering
              key={childQuoteOffering.id}
              index={offeringIndex + i + 1}
              isChildOffering
              quoteOffering={childQuoteOffering}
              priorScheduledQuoteOffering={
                i === 0 ? quoteOffering : childQuoteOfferings[i - 1]
              }
              nextScheduledQuoteOffering={childQuoteOfferings[i + 1]}
              childQuoteOfferings={childQuoteOfferings}
              quotePrices={quotePrices}
              isRootOfferingOpen={isOfferingOpen}
              parentQuoteOffering={quoteOffering}
              onChange={onOfferingChange}
              setParentScheduleChangeActiveProp={setParentScheduleChangeActive}
              quoteOfferings={quoteOfferings}
            />
          ))}
      </>
    );
  },
);
export default QuoteOffering;
