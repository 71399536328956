import { SalesforceOrgTypeEnum } from '../types';
import { objectToSelectOptions } from '../utils/misc';

export const SalesforceOrgTypeEnumDisplay: {
  [key in SalesforceOrgTypeEnum]: string;
} = {
  SANDBOX: 'Sandbox',
  PRODUCTION: 'Production',
  CUSTOM: 'Custom Domain',
};

export const SalesforceOrgTypeValuesForUri: {
  [key in SalesforceOrgTypeEnum]: string | null;
} = {
  SANDBOX: 'Sandbox',
  PRODUCTION: 'Production',
  CUSTOM: null,
};

export const SalesforceOrgTypeUrlPlaceholder: {
  [key in SalesforceOrgTypeEnum]: string;
} = {
  SANDBOX: 'login.salesforce.com',
  PRODUCTION: 'test.salesforce.com',
  CUSTOM: 'Enter Domain',
};

export const getSalesforceUrl = (
  orgType: SalesforceOrgTypeEnum,
  customUrl?: string | null,
) => {
  switch (orgType) {
    case SalesforceOrgTypeEnum.CUSTOM:
      return `https://${customUrl}.my.salesforce.com`;
    case SalesforceOrgTypeEnum.PRODUCTION:
      return 'https://login.salesforce.com';
    case SalesforceOrgTypeEnum.SANDBOX:
      return 'https://test.salesforce.com';
    default:
      return '';
  }
};

export const SalesforceOrgTypeSelectOptions = objectToSelectOptions(
  SalesforceOrgTypeEnumDisplay,
);
