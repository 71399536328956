import { useState } from 'react';
import { MdOutlineFileDownload } from 'react-icons/md';
import { handleApiErrorToast } from '../../../../../api/axios';
import { doPrintQuoteToPdf } from '../../../../../api/cpqService';
import {
  MButton,
  MFlex,
  MLink,
  MText,
} from '../../../../../components/Monetize';
import { useIsTenantEsignConfigured } from '../../../../../hooks/useIsTenantEsignConfigured';
import { useFlags } from '../../../../../services/launchDarkly';
import { IQuoteRespSchema } from '../../../../../types';
import { getSafeFilename } from '../../../../../utils';
import { downloadBlobAsFile } from '../../../../../utils/download';

export const SendQuoteManuallyContent = ({
  quote,
}: {
  quote: IQuoteRespSchema;
}) => {
  const { useQuotePdfV2 } = useFlags();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isTenantEsignConfigured = useIsTenantEsignConfigured();

  const handleDownloadQuote = async () => {
    try {
      setIsLoading(true);
      const resp = await doPrintQuoteToPdf(quote.id, useQuotePdfV2);
      const fileName = getSafeFilename(quote.description);

      downloadBlobAsFile(resp, `${fileName}.pdf`);
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MFlex flexDir="column" justify="center" align="start" gap="4">
      <MText fontWeight="normal">
        {`Clicking 'Confirm' indicates that you have sent this Quote to the customer.`}
      </MText>
      {!isTenantEsignConfigured && (
        <MFlex flexDir="column" color="tGray.darkPurple">
          <MText fontWeight="semibold">Did you know?</MText>
          <MText>
            MonetizeNow can automate signature gathering. To set it up, send
            your Administrator to our{' '}
            <MLink
              href="https://docs.monetizenow.io/docs/setting-up-esign"
              target="_blank"
            >
              documentation
            </MLink>{' '}
            or have them reach out to{' '}
            <MLink
              href="mailto:support@monetizenow.io"
              target="_blank"
              textDecoration="underline"
            >
              support@monetizenow.io
            </MLink>
          </MText>
        </MFlex>
      )}
      {isTenantEsignConfigured && !quote.requiresEsign && (
        <MText>
          {`This Quote can be sent automatically with eSign.  To do so, click 'Cancel', then 'Edit Quote', and turn on eSign for this Quote`}
        </MText>
      )}
      <MButton
        tabIndex={-1}
        variant="tertiary"
        leftIcon={<MdOutlineFileDownload size="18" />}
        px="1"
        isLoading={isLoading}
        onClick={handleDownloadQuote}
      >
        Download Quote PDF
      </MButton>
    </MFlex>
  );
};
