import {
  ContactFormattedAddress,
  IAddressSchema,
  IContactRespSchema,
  IPaymentMethodContactSchema,
} from '../types';
import {
  ILegalEntitySnapshot,
  LegalEntityAddressFormatEnum,
} from '../types/legalEntityTypes';

function joinAndFormat(parts: string[]) {
  return parts
    .map((part) => part.trim())
    .filter(Boolean)
    .join(', ');
}

/**
 * Combine address into string parts
 * @param address
 * @returns
 */
export const getAddress = (
  address?:
    | IAddressSchema
    | IPaymentMethodContactSchema
    | ILegalEntitySnapshot['address']
    | null,
  options?: {
    addressFormat?: LegalEntityAddressFormatEnum | null;
  },
): ContactFormattedAddress => {
  if (!address) {
    return {
      addressLine1: '',
      addressLine2: '',
      otherAddress: '',
      country: '',
      cityStatePCCountry: '',
      fullAddress: '',
    };
  }
  const opts = {
    addressFormat: LegalEntityAddressFormatEnum.US_FORMAT,
    ...options,
  };

  const getUSFormatOtherAddress = () => {
    if (!address.city?.trim() && address.state?.trim()) {
      return `${address.state || ''} ${address.postalCode || ''}`.trim();
    } else if (address.city?.trim() && !address.state?.trim()) {
      return `${address.city || ''} ${address.postalCode || ''}`.trim();
    }
    return joinAndFormat([
      address.city || '',
      `${address.state || ''} ${address.postalCode || ''}`,
    ]);
  };

  const otherAddress =
    opts.addressFormat === LegalEntityAddressFormatEnum.US_FORMAT
      ? getUSFormatOtherAddress()
      : joinAndFormat([
          `${address.postalCode || ''} ${address.city || ''}`,
          `${address.state || ''}`,
        ]);

  const cityStatePCCountry =
    opts.addressFormat === LegalEntityAddressFormatEnum.US_FORMAT
      ? joinAndFormat([
          address.city || '',
          `${address.state || ''} ${address.postalCode || ''}`,
          address.country || '',
        ])
      : joinAndFormat([
          `${address.postalCode || ''} ${address.city || ''}`,
          `${address.state || ''}`,
          address.country || '',
        ]);

  const formattedAddress: ContactFormattedAddress = {
    addressLine1: (address.line1 || '').trim(),
    addressLine2: (address.line2 || '').trim(),
    otherAddress,
    country: (address.country || '').trim(),
    cityStatePCCountry,
    fullAddress: '',
  };

  formattedAddress.fullAddress = joinAndFormat([
    formattedAddress.addressLine1,
    formattedAddress.addressLine2,
    formattedAddress.cityStatePCCountry,
  ]);
  return formattedAddress;
};

export const getAddressFromContact = (
  contact?: IContactRespSchema | null,
  options?: {
    addressFormat?: LegalEntityAddressFormatEnum | null;
  },
) => {
  if (!contact) {
    return getAddress(null);
  }

  const { address } = contact;
  return getAddress(address, options);
};
