import { FunctionComponent as FC } from 'react';
import { MText, MVStack } from '../../../../components/Monetize';
import { getDiscountText } from '../../../../utils';

type DiscountsTooltipProps = {
  discounts: any[];
};

const DiscountsAppliedTooltip: FC<DiscountsTooltipProps> = (
  props: DiscountsTooltipProps,
) => {
  const { discounts } = props;
  return (
    <MVStack spacing=".2rem" alignItems="self-start">
      <MText color="inherit" fontWeight="normal" fontStyle="italic">
        Discounts Applied:
      </MText>
      <MVStack spacing="-1" alignItems="self-start">
        {discounts.map((disc) => (
          <MText color="inherit" key={disc.id}>{`${
            disc.name
          } - ${getDiscountText(
            disc.discountAmount,
            disc.discountType,
          )}`}</MText>
        ))}
      </MVStack>
    </MVStack>
  );
};

export default DiscountsAppliedTooltip;
