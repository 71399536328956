import { toDateTimeShort } from '@monetize/utils/core';
import { FunctionComponent as FC } from 'react';
import { NoteMilestone } from '../../types';
import { MDivider, MFlex, MTag } from '../Monetize';

interface NoteMilestoneDisplayProps {
  milestone: NoteMilestone;
}

export const NoteMilestoneDisplay: FC<NoteMilestoneDisplayProps> = ({
  milestone,
}: NoteMilestoneDisplayProps) => {
  return (
    <MFlex alignItems="center">
      <MDivider color="tGray.back" />
      <MTag size="md" variant="mileStone">
        {milestone.label} - {toDateTimeShort(milestone.date)}
      </MTag>
      <MDivider color="tGray.back" />
    </MFlex>
  );
};
