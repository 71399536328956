import { z } from 'zod';

export const ESignRecipientSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  order: z.string(),
  status: z.string(),
  declinedDateTime: z.string().nullish(),
  deliveredDateTime: z.string().nullish(),
  sentDateTime: z.string().nullish(),
  signedDateTime: z.string().nullish(),
});
export type ESignRecipient = z.infer<typeof ESignRecipientSchema>;

export const ESignRecipientResponseSchema = z.object({
  recipientCount: z.string(),
  currentRoutingOrder: z.string(),
  signers: ESignRecipientSchema.array(),
  ccContacts: ESignRecipientSchema.array(),
});
export type ESignRecipientResponse = z.infer<
  typeof ESignRecipientResponseSchema
>;
