import {
  Box,
  FocusLock,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  QUOTE_CONTRACT_LENGTHS,
  QUOTE_CUSTOM_CONTRACT_LENGTHS,
} from '../../constants/quotes';
import {
  MButton,
  MCustomNumberInput,
  MFlex,
  MPopover,
  MText,
  MVStack,
  RadioStyleButtonGroup,
} from '../Monetize';

interface IQuoteContractLengthPopover {
  isCustomContractLength: boolean;
  contractLength: number;
  handleValueUpdate: (value: number) => void;
}

/**
 * This component manage the popup form state and submission for both- Whole Years or Any Months
 *
 * if isCustomContractLength is true means it's Any Months otherwise Whole Years
 */

const customContractOptions = QUOTE_CUSTOM_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));
const contractLengthOptions = QUOTE_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));

export const ContractLengthPopover = ({
  isCustomContractLength,
  contractLength,
  handleValueUpdate,
}: IQuoteContractLengthPopover) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [inputValue, setInputValue] = useState<number>(contractLength);

  useEffect(() => {
    handleValueUpdate(inputValue);
  }, [contractLength, handleValueUpdate, inputValue]);

  const handleOnOpen = () => {
    onOpen();
  };

  return (
    <MPopover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={true}
      size={'smaller'}
      strategy="fixed"
      placement="bottom-start"
      offset={[0, 0]}
    >
      <MFlex minH={8} align="center">
        <PopoverTrigger>
          <MButton
            size="sm"
            padding={1}
            variant="tertiary"
            onClick={handleOnOpen}
          >
            {inputValue}
          </MButton>
        </PopoverTrigger>
      </MFlex>

      <Portal>
        <Box zIndex="popover" w="full" h="full">
          <PopoverContent data-testid="custom-contractLength-popover-content">
            <PopoverBody>
              <FocusLock>
                <MText fontWeight={'semibold'} color="tPurple.dark">
                  Number of Months
                </MText>
                <MVStack
                  spacing={2}
                  fontWeight="semibold"
                  alignItems="flex-start"
                  mt={2}
                >
                  <MCustomNumberInput
                    inputMode="numeric"
                    value={inputValue}
                    width="full"
                    onChange={(
                      valueAsString: string,
                      valueAsNumber: number,
                    ) => {
                      setInputValue(valueAsNumber);
                    }}
                  />

                  <RadioStyleButtonGroup
                    name="contractLength"
                    options={
                      isCustomContractLength
                        ? customContractOptions
                        : contractLengthOptions
                    }
                    value={`${inputValue}`}
                    defaultValue={`${inputValue}`}
                    onChange={(value: string) => {
                      setInputValue(Number(value));
                    }}
                  />
                </MVStack>
              </FocusLock>
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </MPopover>
  );
};
