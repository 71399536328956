import { Heading } from '@chakra-ui/react';
import { UploadIcon } from '@monetize/ui/icons';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
    MBox,
    MButton,
    MDataTable,
    MFlex,
    MFormField,
    MPageContainer,
    MPageHeader,
    MPageLoader,
    MText,
} from '../../../../components/Monetize';
import DataTableActions from '../../../../components/Monetize/DataTable/MDataTableActions';
import { DatePicker } from '../../../../components/Monetize/DatePicker/DatePicker';
import { ExportTableButton } from '../../../../components/Monetize/ExportEntityButton';
import { LoadMore } from '../../../../components/Monetize/LoadMore';
import MEmptyDataPlaceholder from '../../../../components/Monetize/MEmptyDataPlaceholder';
import { getRateUsageBillingFrequencyDisplay } from '../../../../constants/offerings';
import { getSubscriptionOverviewRoute } from '../../../../constants/routes';
import { useSubscriptionUsage } from '../../../../hooks/AccountDetail/useSubscriptionUsage';
import { ManualUsageForm } from './ManualUsageForm';
import { UploadUsageModal } from './usageUpload/UploadUsageModal';
;

export const SubscriptionUsageOverviewPage: FC = () => {
  const params = useParams();

  const accountId = params.accountId ?? '';
  const subscriptionId = params.subscriptionId ?? '';
  const productId = params.productId ?? '';

  const {
    formData,
    getUsageRecordsQuery,
    onOpenUsageForm,
    onCloseUsageForm,
    onOpenUsageUpload,
    onCloseUsageUpload,
    isUsageFormOpen,
    isManualUsageModalOpen,
    usageRecords,
    totalElements,
    columns,
    filters,
    isCanceled,
    subscription,
    subscriptionItem,
    rate,
    isLoadingSubscription,
    activeUsageRecord,
    setActiveUsageRecord,
    canUpdateUsage,
    pager,
  } = useSubscriptionUsage({
    accountId,
    subscriptionId,
    productId,
  });

  if (!subscription || !subscriptionItem || !rate || isLoadingSubscription) {
    return <MPageLoader />;
  }
  const { control, formState } = formData;
  const {
    isLoading: isLoadingUsage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = getUsageRecordsQuery;

  return (
    <MPageContainer maxHeight="100vh">
      {isManualUsageModalOpen && (
        <UploadUsageModal
          accountId={accountId}
          subscriptionId={subscriptionId}
          onClose={onCloseUsageUpload}
        />
      )}
      <MPageHeader
        hasBackButton
        parentLink={getSubscriptionOverviewRoute(accountId, subscriptionId)}
        parentLinkTitle={`${subscription.offering.name || ''} - ${
          rate.name || ''
        }`}
        backButtonLink={getSubscriptionOverviewRoute(accountId, subscriptionId)}
        title={`${subscriptionItem.product.name} - Training Details`}
        extraSubtitleParts={[
          <MText key={`subtitle-parts-1`} size="sm">
            Usage Frequency:{' '}
            {getRateUsageBillingFrequencyDisplay(rate.usageBillingFrequency)}
          </MText>,
        ]}
      />
      <MFlex direction="column" w={'100%'} mb="5">
        <MFlex justifyContent="space-between" alignItems="center">
          <Heading size="md" fontSize={18} lineHeight={1}>
            Usage Details
          </Heading>
          <MFlex alignItems="center">
            <MFormField
              error={formState?.errors?.startTime}
              label="From"
              isHorizontal
              labelProps={{ mr: 2 }}
            >
              <Controller
                name="startTime"
                control={control}
                render={({ field }) => (
                  <DatePicker includeTime onClose={refetch} {...field} />
                )}
              />
            </MFormField>
            <MFormField
              error={formState?.errors?.endTime}
              label="to"
              labelProps={{ fontWeight: 'normal', mx: 2 }}
              isHorizontal
            >
              <Controller
                name="endTime"
                control={control}
                render={({ field }) => (
                  <DatePicker includeTime onClose={refetch} {...field} />
                )}
              />
            </MFormField>
            {canUpdateUsage && !isCanceled && (
              <MButton
                ml={5}
                mr={2}
                variant="secondary"
                onClick={() => onOpenUsageForm()}
              >
                New Usage
              </MButton>
            )}
            <MBox>
              <ExportTableButton
                entity="usage"
                applyInternalFiltersWithoutConfirmation
                internalFilters={filters}
                sortField={pager.sortField}
                sortOrder={pager.sortOrder}
                getFilename={() => `subscriptions-${accountId}.csv`}
              />
            </MBox>
            {canUpdateUsage && !isCanceled && (
              <DataTableActions
                actions={[
                  {
                    title: 'Import Usage',
                    icon: UploadIcon,
                    enabled: true,
                    action: () => onOpenUsageUpload(),
                  },
                ]}
              />
            )}
          </MFlex>
        </MFlex>
      </MFlex>
      {isUsageFormOpen && (
        <ManualUsageForm
          isOpen
          existingUsage={activeUsageRecord}
          onClose={() => {
            onCloseUsageForm();
            setActiveUsageRecord(undefined);
          }}
          subscriptionId={subscription.id}
          periodStartDate={subscription.periodStartDate}
          usageTypes={subscriptionItem.product.usageTypes}
          accountId={accountId}
        />
      )}

      <MDataTable
        value={usageRecords}
        paginator={false}
        className="p-datatable-responsive"
        emptyProps={{
          renderEmptyPlaceholder: () => (
            <MEmptyDataPlaceholder
              mainMessage="There aren't any usage records for this product."
              smallMessage={
                isCanceled
                  ? 'This subscription is canceled; no usage records can be added.'
                  : 'Add new usage records or change the date range.'
              }
              btnLabel={
                canUpdateUsage && !isCanceled ? 'Add New Usage' : undefined
              }
              onClick={onOpenUsageForm}
            />
          ),
        }}
        loading={isLoadingUsage}
        columns={columns}
        loadingContHeight="300px"
      />
      {!!usageRecords.length && (
        <MFlex flexDirection="column" mb={4} minH="70px">
          <LoadMore
            fetchedElementLength={usageRecords.length}
            totalElements={totalElements}
            isLoading={isFetchingNextPage}
            onLoadMore={fetchNextPage}
          />
        </MFlex>
      )}
    </MPageContainer>
  );
};
