import { FC } from 'react';
import { useAuth } from '../../services/auth0';
import Sentry from '../../services/sentry';
import { MButton, MFlex, MHeading, MText } from '../Monetize';

const FallbackComponent = () => {
  const handleReturnToPreviousPage = () => {
    window.history.back();
    setTimeout(function () {
      window.location.reload();
    }, 200);
  };

  return (
    <MFlex
      w="100vw"
      h="100vh"
      p={8}
      direction="column"
      justify="center"
      align="center"
    >
      <MHeading as="p" size="lg" mb={3}>
        Sorry, it looks like there was an error!
      </MHeading>
      <MText mb={3} color="gray.700">
        Please reach out to your account team
      </MText>
      <MButton onClick={handleReturnToPreviousPage}>
        Return to previous page
      </MButton>
    </MFlex>
  );
};

// eslint-disable-next-line react/prop-types
export const SentryErrorBoundary: FC<any> = ({ children }) => {
  const { isAuthenticated, userId, user, tenantId, currentTenant } = useAuth();

  if (isAuthenticated) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: userId,
        email: user?.email,
      });
    });
  }

  const handleBeforeCapture = (
    scope: Sentry.Scope,
    error: Error | null,
    componentStack: string | null,
  ) => {
    scope.setTag('tenant.id', tenantId);
    currentTenant?.name && scope.setTag('tenant.name', currentTenant.name);
    currentTenant?.testTenant &&
      scope.setTag('tenant.testTenant', currentTenant.testTenant);
    scope.setLevel('fatal');
  };

  return (
    <Sentry.ErrorBoundary
      fallback={FallbackComponent}
      showDialog
      beforeCapture={handleBeforeCapture}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
