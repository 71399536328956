import { z } from 'zod';
import { CustomFieldListResSchema } from './customFields.types';

/**
 * Overall structure:
 *
 * RuleSchema (Outer rule)
 * -> ruleConditions (Object to store the overall rule conditions)
 * --> rules (Array of conditions (e.x. condition group))
 * ---> conditions (Array of conditions)
 */

export enum RuleTypeEnum {
  APPROVAL = 'APPROVAL',
  VALIDATION = 'VALIDATION',
  CONDITIONAL_TERM = 'CONDITIONAL_TERM',
}

export enum RuleActionTypeEnum {
  APPROVAL = 'APPROVAL',
  VALIDATION = 'VALIDATION',
  CONDITIONAL_TERM = 'CONDITIONAL_TERM',
}

export enum EntityGroupEnum {
  QUOTE = 'QUOTE',
}

export enum RuleStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const RuleStatusEnumDisplay: Record<RuleStatusEnum, string> = {
  [RuleStatusEnum.ACTIVE]: 'Active',
  [RuleStatusEnum.INACTIVE]: 'Inactive',
  [RuleStatusEnum.ARCHIVED]: 'Archived',
};

export const RuleStatusEnumDisplayWithoutArchived = {
  [RuleStatusEnum.ACTIVE]: 'Active',
  [RuleStatusEnum.INACTIVE]: 'Inactive',
};

export enum FieldTypeEnum {
  STRING = 'STRING',
  EXISTS = 'EXISTS',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATE_EXTENDED = 'DATE_EXTENDED',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  REFERENCE = 'REFERENCE',
  LIST = 'LIST',
  /**
   * Custom behavior
   * TODO: figure out how to implement this
   */
  // SPECIAL = 'SPECIAL',
}

export enum ReferenceEntityEnum {
  ACCOUNT = 'ACCOUNT',
  CURRENCY = 'CURRENCY',
  OFFERING = 'OFFERING',
  PRODUCT = 'PRODUCT',
  RATE = 'RATE',
  RULE = 'RULE',
  TEAM = 'TEAM',
  USER = 'USER',
  DISCOUNTS = 'DISCOUNTS',
  DUNNING_PROCESS = 'DUNNING_PROCESS',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
}

export enum TypeOperatorEnum {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  LIST = 'LIST',
  REFERENCE_LIST = 'REFERENCE_LIST',
  FIELD_VALUES_LIST = 'FIELD_VALUES_LIST',
}

export enum ExpressionOperatorEnum {
  AND = 'AND',
  OR = 'OR',
  CUSTOM = 'CUSTOM',
}

export const ExpressionOperatorEnumDisplay: {
  [key in ExpressionOperatorEnum]: string;
} = {
  [ExpressionOperatorEnum.AND]: 'All Conditions Are Met',
  [ExpressionOperatorEnum.OR]: 'Any Condition Is Met',
  [ExpressionOperatorEnum.CUSTOM]: 'Custom Logic Is Met',
};

export enum ConditionOperatorEnum {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_INCLUSIVE = 'GREATER_THAN_INCLUSIVE',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_INCLUSIVE = 'LESS_THAN_INCLUSIVE',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  CONTAINS = 'CONTAINS',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  EXISTS = 'EXISTS',
  N_DAYS_IN_PAST = 'N_DAYS_IN_PAST',
  N_WEEKS_IN_PAST = 'N_WEEKS_IN_PAST',
  N_MONTHS_IN_PAST = 'N_MONTHS_IN_PAST',
  N_YEARS_IN_PAST = 'N_YEARS_IN_PAST',
  N_DAYS_IN_FUTURE = 'N_DAYS_IN_FUTURE',
  N_WEEKS_IN_FUTURE = 'N_WEEKS_IN_FUTURE',
  N_MONTHS_IN_FUTURE = 'N_MONTHS_IN_FUTURE',
  N_YEARS_IN_FUTURE = 'N_YEARS_IN_FUTURE',
  FIRST_DAY_OF_MONTH = 'FIRST_DAY_OF_MONTH',
  LAST_DAY_OF_MONTH = 'LAST_DAY_OF_MONTH',
  BEFORE_CURRENT_MONTH = 'BEFORE_CURRENT_MONTH',
  AFTER_CURRENT_MONTH = 'AFTER_CURRENT_MONTH',
  /**
   * FIXME: Ideally we could somehow generalize this in ConditionOperatorTypeEnum or something
   * instead of hard-coding this logic
   */
  N_DAYS_BEFORE_CONTRACT_START = 'N_DAYS_BEFORE_CONTRACT_START',
  N_DAYS_AFTER_CONTRACT_START = 'N_DAYS_AFTER_CONTRACT_START',
}

export const ConditionOperatorEnumDisplay: {
  [key in ConditionOperatorEnum]: string;
} = {
  [ConditionOperatorEnum.EQUAL]: '=',
  [ConditionOperatorEnum.NOT_EQUAL]: '!=',
  [ConditionOperatorEnum.GREATER_THAN]: '>',
  [ConditionOperatorEnum.GREATER_THAN_INCLUSIVE]: '>=',
  [ConditionOperatorEnum.LESS_THAN]: '<',
  [ConditionOperatorEnum.LESS_THAN_INCLUSIVE]: '<=',
  [ConditionOperatorEnum.STARTS_WITH]: 'Starts With',
  [ConditionOperatorEnum.ENDS_WITH]: 'Ends With',
  [ConditionOperatorEnum.CONTAINS]: 'Contains',
  [ConditionOperatorEnum.IN]: 'In',
  [ConditionOperatorEnum.NOT_IN]: 'Not In',
  [ConditionOperatorEnum.EXISTS]: 'Exists',
  [ConditionOperatorEnum.FIRST_DAY_OF_MONTH]: 'First Day Of Month',
  [ConditionOperatorEnum.LAST_DAY_OF_MONTH]: 'Last Day Of Month',
  [ConditionOperatorEnum.BEFORE_CURRENT_MONTH]: 'Before Current Month',
  [ConditionOperatorEnum.AFTER_CURRENT_MONTH]: 'After Current Month',
  [ConditionOperatorEnum.N_DAYS_IN_PAST]: 'More Than N Days In Past',
  [ConditionOperatorEnum.N_WEEKS_IN_PAST]: 'More Than N Weeks In Past',
  [ConditionOperatorEnum.N_MONTHS_IN_PAST]: 'More Than N Months In Past',
  [ConditionOperatorEnum.N_YEARS_IN_PAST]: 'More Than N Years In Past',
  [ConditionOperatorEnum.N_DAYS_IN_FUTURE]: 'More Than N Days In Future',
  [ConditionOperatorEnum.N_WEEKS_IN_FUTURE]: 'More Than N Weeks In Future',
  [ConditionOperatorEnum.N_MONTHS_IN_FUTURE]: 'More Than N Months In Future',
  [ConditionOperatorEnum.N_YEARS_IN_FUTURE]: 'More Than N Years In Future',
  [ConditionOperatorEnum.N_DAYS_BEFORE_CONTRACT_START]:
    'N Days Before Contract Start Date',
  [ConditionOperatorEnum.N_DAYS_AFTER_CONTRACT_START]:
    'N Days After Contract Start Date',
};

export enum AggregateConditionOperatorEnum {
  HAS_SAME_VALUE = 'HAS_SAME_VALUE',
  HAS_DIFFERENT_VALUE = 'HAS_DIFFERENT_VALUE',
}

export const AggregateConditionOperatorEnumDisplay: {
  [key in AggregateConditionOperatorEnum]: string;
} = {
  [AggregateConditionOperatorEnum.HAS_SAME_VALUE]: 'Has Same Value',
  [AggregateConditionOperatorEnum.HAS_DIFFERENT_VALUE]: 'Has Different Value',
};

// TODO: figure out what to do here
export enum ConditionOperatorTypeEnum {
  LITERAL = 'LITERAL',
  DATE = 'DATE',
  FIELD = 'FIELD',
}

/**
 * Entities available for Quote based rules
 */
export enum QuoteEntityEnum {
  QUOTE = 'QUOTE',
  QUOTE_CONDITIONAL_TERMS_FIELDS = 'QUOTE_CONDITIONAL_TERMS_FIELDS',
  QUOTE_OFFERING = 'QUOTE_OFFERING',
  QUOTE_ITEM = 'QUOTE_ITEM',
}

export const QuoteEntityEnumDisplay: { [key in QuoteEntityEnum]: string } = {
  [QuoteEntityEnum.QUOTE]: 'Quote',
  [QuoteEntityEnum.QUOTE_CONDITIONAL_TERMS_FIELDS]: 'Quote Conditional Terms',
  [QuoteEntityEnum.QUOTE_OFFERING]: 'Quote Offering',
  [QuoteEntityEnum.QUOTE_ITEM]: 'Quote Item',
};

export enum RuleValidationErrorLevelEnum {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARN = 'WARN',
}

export const RuleValidationErrorLevelEnumZ = z.nativeEnum(
  RuleValidationErrorLevelEnum,
);

export const RuleTypeEnumZ = z.nativeEnum(RuleTypeEnum);
export const RuleActionTypeEnumZ = z.nativeEnum(RuleActionTypeEnum);
export const EntityGroupEnumZ = z.nativeEnum(EntityGroupEnum);
export const RuleStatusEnumZ = z.nativeEnum(RuleStatusEnum);
export const FieldTypeEnumZ = z.nativeEnum(FieldTypeEnum);
export const ReferenceEntityEnumZ = z.nativeEnum(ReferenceEntityEnum);
export const TypeOperatorEnumZ = z.nativeEnum(TypeOperatorEnum);
export const ExpressionOperatorEnumZ = z.nativeEnum(ExpressionOperatorEnum);
export const ConditionOperatorEnumZ = z.nativeEnum(ConditionOperatorEnum);
export const AggregateConditionOperatorEnumZ = z.nativeEnum(
  AggregateConditionOperatorEnum,
);
export const ConditionOperatorTypeEnumZ = z.nativeEnum(
  ConditionOperatorTypeEnum,
);
export const QuoteEntityEnumZ = z.nativeEnum(QuoteEntityEnum);

/**
 * TYPES
 */

export const RuleFormFieldSchema = z.object({
  key: z.string(),
  label: z.string(),
  type: FieldTypeEnumZ,
  values: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .array()
    .nullish(),
  referenceEntity: ReferenceEntityEnumZ.nullish(),
  operatorOverride: z
    .object({
      label: z.string(),
      value: ConditionOperatorEnumZ,
    })
    .array()
    .nullish(),
});

export const RuleFormFieldMapSchema = z.record(RuleFormFieldSchema);

export const RuleFormFieldEntitySchema = z.object({
  entity: QuoteEntityEnumZ,
  fields: RuleFormFieldMapSchema,
});

export const TypeOperatorSchema = z.object({
  type: TypeOperatorEnumZ,
  options: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .array()
    .nullish(),
});

export const TypeOperatorMapSchema = z.object({
  operators: z.record(ConditionOperatorEnumZ, TypeOperatorSchema),
});

export const TypeOperatorByFieldTypeSchema = z.record(
  FieldTypeEnumZ,
  TypeOperatorMapSchema,
);

export const AggregateConditionSchema = z.object({
  field: z.string().min(1, { message: 'Field is required' }),
  operator: AggregateConditionOperatorEnumZ,
  value: z.union([z.literal('TRUE'), z.literal('FALSE')]),
  /**
   * If provided, records will grouped by this field and each group is evaluated individually
   */
  scopedToGroupField: z.string().nullish(),
});

export const ConditionValueSchema = z.object({
  tempType: ConditionOperatorTypeEnumZ.nullish(),
  type: ConditionOperatorTypeEnumZ, // FIXME: not sure what values to use here to describe the types, should have discriminated union
  // FIXME: we will need to support more types here, such as lists and potentially references?
  value: z.union([
    z.string().min(1, { message: 'Value is required' }),
    z.number(),
    z.boolean(),
    z.array(z.string()),
  ]),
});

export const ConditionSchema = z.object({
  field: z.string().min(1, { message: 'Field is required' }),
  operator: ConditionOperatorEnumZ,
  value: ConditionValueSchema,
});

export const ALLOW_AGGREGATE_ENTITIES = new Set<QuoteEntityEnum>([
  QuoteEntityEnum.QUOTE_ITEM,
  QuoteEntityEnum.QUOTE_OFFERING,
]);

export const ALLOW_DELETED_RECORDS = new Set<QuoteEntityEnum>([
  QuoteEntityEnum.QUOTE_ITEM,
  QuoteEntityEnum.QUOTE_OFFERING,
]);

export const RuleConditionSchema = z
  .object({
    entity: QuoteEntityEnumZ,
    expressionOperator: ExpressionOperatorEnumZ,
    /**
     * Applies if expressionOperator is CUSTOM and allows users to build complex conditions
     */
    customLogic: z.string().nullish(),
    conditions: z.array(ConditionSchema).min(1),
    includeDeletedRecords: z.boolean().default(false).nullish(),
    /**
     * If true, the rule outcome will be reversed
     */
    negate: z.boolean().default(false).nullish(),
    /**
     * Rule to apply to all records that evaluated to true based on conditions
     */
    aggregateCondition: AggregateConditionSchema.nullish(),
  })
  .refine(
    (value) =>
      !value.aggregateCondition || ALLOW_AGGREGATE_ENTITIES.has(value.entity),
    (value) => ({
      message: `${value.entity} does not support aggregate conditions.`,
    }),
  )
  .refine(
    (value) =>
      value.expressionOperator !== ExpressionOperatorEnum.CUSTOM ||
      (!!value.customLogic && value.customLogic.length >= 1),
    { message: `Custom logic is required.` },
  );

export const RuleActionSchema = z.object({
  actionType: RuleTypeEnumZ,
});

export const RuleActionApprovalSchema = z.object({
  actionType: z.literal(RuleTypeEnum.APPROVAL),
  approval: z.object({
    userId: z.string().nullish(),
    username: z.string().nullish(),
    teamId: z.string().nullish(),
    teamName: z.string().nullish(),
    mandatory: z.boolean(),
    priority: z.number(),
  }),
});

export const RuleActionConditionalTermSchema = z.object({
  actionType: z.literal(RuleTypeEnum.CONDITIONAL_TERM),
  conditionalTerms: z.object({
    displayOrder: z.number(),
    editableOnQuote: z.boolean(),
    terms: z.string(),
  }),
});

export const RuleActionValidationSchema = z.object({
  actionType: z.literal(RuleTypeEnum.VALIDATION),
  validation: z.object({
    errorLevel: RuleValidationErrorLevelEnumZ,
    message: z.string().min(1, { message: 'Message is required' }),
  }),
});

export enum RuleTimePeriodEvaluationEnum {
  ALL_DAYS = 'ALL_DAYS',
  EVERY_DAY = 'EVERY_DAY',
}

export const RuleConditionsSchema = z
  .object({
    expressionOperator: ExpressionOperatorEnumZ,
    customLogic: z.string().nullish(),
    rules: z.array(RuleConditionSchema).min(1),
    RuleTimePeriodEvaluation: z
      .string()
      .default(RuleTimePeriodEvaluationEnum.ALL_DAYS),
  })
  .refine(
    (value) =>
      value.expressionOperator !== ExpressionOperatorEnum.CUSTOM ||
      (!!value.customLogic && value.customLogic.length >= 1),
    { message: `Custom logic is required.` },
  );

export const RuleSchema = z.object({
  id: z.string().nullish(),
  name: z.string().min(1, { message: 'Name is required' }).max(255),
  description: z.string().max(500).nullish(),
  type: RuleTypeEnumZ,
  target: EntityGroupEnumZ.default(EntityGroupEnum.QUOTE),
  status: RuleStatusEnumZ,
  ruleConditions: RuleConditionsSchema,
  action: z.discriminatedUnion('actionType', [
    RuleActionApprovalSchema,
    RuleActionConditionalTermSchema,
    RuleActionValidationSchema,
  ]),
  createDate: z.union([z.string(), z.date()]).nullish(),
  createdBy: z.union([z.string(), z.date()]).nullish(),
  modifyDate: z.union([z.string(), z.date()]).nullish(),
  updatedBy: z.union([z.string(), z.date()]).nullish(),
});

/**
 * This is here because adding `.refine` to a type changes it from a ZodObject
 * to a ZodEffect, which means that a bunch of behavior is lost
 *
 * see @link https://github.com/colinhacks/zod/issues/2474
 *
 */
export const RuleSchema_Refined = RuleSchema.refine(
  (rule) => {
    if (rule.type === RuleTypeEnum.APPROVAL) {
      return rule.action.actionType === RuleTypeEnum.APPROVAL;
    }
    if (rule.type === RuleTypeEnum.CONDITIONAL_TERM) {
      return rule.action.actionType === RuleTypeEnum.CONDITIONAL_TERM;
    }
    if (rule.type === RuleTypeEnum.VALIDATION) {
      return rule.action.actionType === RuleTypeEnum.VALIDATION;
    }
    return true;
  },
  (rule) => ({
    message: rule.id
      ? `Rule ${rule.name} (${rule.id}) is invalid, type does not match action's type`
      : `Rule's type does not match the action's Type`,
    path: ['action', 'actionType'],
  }),
);

export const RuleFormResponseSchema = z.object({
  fields: z.record(QuoteEntityEnumZ, RuleFormFieldEntitySchema),
  operators: ConditionOperatorEnumZ.array(),
  operatorTypes: TypeOperatorByFieldTypeSchema,
  aggregateOperators: AggregateConditionOperatorEnumZ.array(),
});

export const RuleTestEvaluationResponseSchema = z.object({
  conditionResults: z.boolean().array(),
  finalResult: z.boolean(),
});

export type RuleFormFieldMap = z.infer<typeof RuleFormFieldMapSchema>;
export type RuleFormFieldEntity = z.infer<typeof RuleFormFieldEntitySchema>;
export type RuleFormField = z.infer<typeof RuleFormFieldSchema>;
export type TypeOperator = z.infer<typeof TypeOperatorSchema>;
export type TypeOperatorMap = z.infer<typeof TypeOperatorMapSchema>;
// Zod uses Partial for records, but all fields will be present and are required
export type TypeOperatorByFieldType = Record<
  FieldTypeEnum,
  Required<z.infer<typeof TypeOperatorMapSchema>>
>;
export type IAggregateCondition = z.infer<typeof AggregateConditionSchema>;
export type ConditionValue = z.infer<typeof ConditionValueSchema>;
export type ICondition = z.infer<typeof ConditionSchema>;
export type IRuleCondition = z.infer<typeof RuleConditionSchema>;
export type IRuleConditions = z.infer<typeof RuleConditionsSchema>;
export type RuleActionApproval = z.infer<typeof RuleActionApprovalSchema>;
export type RuleActionConditionalTerm = z.infer<
  typeof RuleActionConditionalTermSchema
>;
export type RuleActionValidation = z.infer<typeof RuleActionValidationSchema>;
export type RuleAction = z.infer<typeof RuleSchema>['action'];
export type IRule = z.infer<typeof RuleSchema>;

// zod record has all keys as optional, force them to be required
export interface IRuleFormResponse
  extends z.infer<typeof RuleFormResponseSchema> {
  fields: Required<z.infer<typeof RuleFormResponseSchema>['fields']>;
  operatorTypes: Record<
    FieldTypeEnum,
    Required<z.infer<typeof TypeOperatorMapSchema>>
  >;
}
export type RuleTestEvaluationResponse = z.infer<
  typeof RuleTestEvaluationResponseSchema
>;

export interface IRuleFormResponseWithEntities {
  ruleFormData: IRuleFormResponse;
  entities: QuoteEntityEnum[];
}

/**
 * REQUEST / RESPONSE SCHEMAS
 */

export const ApprovalRuleResponseSchema = z.object({
  userId: z.string().nullish(),
  username: z.string().nullish(),
  teamId: z.string().nullish(),
  teamName: z.string().nullish(),
  rank: z.number(),
  /** NOTE: this is the inverse of skippable, used for quote approval dto */
  mandatory: z.boolean(),
  /** Does not appear to be on the response, just the request */
  skipped: z.boolean().nullish(),
});

const ValidationRuleResponseSchema = z.object({
  errorLevel: z.enum(['INFO', 'WARN', 'ERROR']),
  message: z.string(),
});

const ConditionalTermsResponseSchema = z.object({
  displayOrder: z.number(),
  editableOnQuote: z.boolean(),
  terms: z.string(),
});

const ActionRequestSchema = z.object({
  type: RuleActionTypeEnumZ,
  approval: ApprovalRuleResponseSchema,
});

export type ActionRequest = z.infer<typeof ActionRequestSchema>;

const ActionResponseBaseSchema = z.object({
  type: RuleActionTypeEnumZ,
  ruleId: z.string(),
  ruleName: z.string(),
  description: z.string().nullish(),
});

export const ActionResponseApprovalSchema = ActionResponseBaseSchema.extend({
  approval: ApprovalRuleResponseSchema,
});
export type ActionResponseApproval = z.infer<
  typeof ActionResponseApprovalSchema
>;

export const ActionResponseValidationSchema = ActionResponseBaseSchema.extend({
  validation: ValidationRuleResponseSchema,
});
export type ActionResponseValidation = z.infer<
  typeof ActionResponseValidationSchema
>;

export const ActionResponseConditionalTermSchema =
  ActionResponseBaseSchema.extend({
    conditionalTerms: ConditionalTermsResponseSchema,
  });
export type ActionResponseConditionalTerm = z.infer<
  typeof ActionResponseConditionalTermSchema
>;

export const ActionResponseSchema = z.union([
  ActionResponseApprovalSchema,
  ActionResponseValidationSchema,
  ActionResponseConditionalTermSchema,
]);

/**
 * [REQUEST] validateRule
 */
export const GetFormDataRequestSchema = z.object({
  customFields: CustomFieldListResSchema.array().nullish(),
});
export type GetFormDataRequest = z.infer<typeof GetFormDataRequestSchema>;

/**
 * [RESPONSE] validateRule
 */
export const GetFormDataResponseSchema = z.object({
  valid: z.boolean(),
  error: z.any().nullish().optional(),
  errorMessage: z.string().nullish().optional(),
});
export type GetFormDataResponse = z.infer<typeof GetFormDataResponseSchema>;

/**
 * [REQUEST] applyRules
 */
export const ValidateExpressionRequestSchema = z.object({
  expression: z.string(),
  items: z.number().array().nullish(),
});
export type ValidateExpressionRequest = z.infer<
  typeof ValidateExpressionRequestSchema
>;

/**
 * [RESPONSE] ValidateExpression
 */
export const ValidateExpressionResponseSchema = z.object({
  success: z.boolean(),
  parseSuccess: z.boolean(),
  itemValidationSuccess: z.boolean().nullish(),
  parseResults: z.any().nullish(), // AndOrExpressionWithValues
  formattedExpression: z.string().nullish(),
  error: z.string().nullish(),
});
export type ValidateExpressionResponse = z.infer<
  typeof ValidateExpressionResponseSchema
>;

export const ApplyRulesFactsDefaultDocumentsSchema = z.object({
  defaultDocumentLocation: z.string().nullish(),
  defaultDocumentUrl: z.string().nullish(),
  defaultDocumentCover: z.string().nullish(),
  defaultDocumentFooter: z.string().nullish(),
});
export type ApplyRulesFactsDefaultDocuments = z.infer<
  typeof ApplyRulesFactsDefaultDocumentsSchema
>;

export const ApplyRulesFactsSchema = z.object({
  quote: z.any(),
  account: z.record(z.any()).nullish(),
  quoteOwner: z
    .object({
      teams: z.array(z.string()),
    })
    .nullish(),
  quoteDefaultDocument: ApplyRulesFactsDefaultDocumentsSchema.nullish(),
});
export type ApplyRulesFacts = z.infer<typeof ApplyRulesFactsSchema>;

/**
 * [REQUEST] applyRules
 */
export const ApplyRulesRequestSchema = z.object({
  target: EntityGroupEnumZ,
  facts: ApplyRulesFactsSchema,
  actions: z.array(ActionRequestSchema),
});
export type ApplyRulesRequest = z.infer<typeof ApplyRulesRequestSchema>;

/**
 * [RESPONSE] applyRules
 */
export const ApplyRulesResponseSchema = z.object({
  status: z.enum(['SUCCESS', 'FAILED']),
  actions: ActionResponseSchema.array(),
});
export type ApplyRulesResponse = z.infer<typeof ApplyRulesResponseSchema>;

/**
 * [REQUEST] testRule
 */
export const TestRuleRequestSchema = z.object({
  rule: RuleSchema,
  record: z.record(z.any()),
  type: RuleActionTypeEnumZ,
  account: z.record(z.any()).nullish(),
  quoteOwner: z
    .object({
      teams: z.array(z.string()),
    })
    .nullish(),
  quoteDefaultDocument: ApplyRulesFactsDefaultDocumentsSchema.nullish(),
});
export type TestRuleRequest = z.infer<typeof TestRuleRequestSchema>;

/**
 * [RESPONSE] testRule
 */
export const TestRuleResponseSchema = z.object({
  results: RuleTestEvaluationResponseSchema,
});
export type TestRuleResponse = z.infer<typeof TestRuleResponseSchema>;

/**
 * [REQUEST] validateRule
 */
export const ValidateRuleRequestSchema = z.object({
  rule: RuleSchema,
});
export type ValidateRuleRequest = z.infer<typeof ValidateRuleRequestSchema>;

/**
 * [RESPONSE] validateRule
 */
export const ValidateRuleResponseSchema = z.object({
  valid: z.boolean(),
  error: z.any().nullish().optional(),
  message: z.string().nullish().optional(),
});
export type ValidateRuleResponse = z.infer<typeof ValidateRuleResponseSchema>;
