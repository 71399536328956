import { StackProps } from '@chakra-ui/react';
import { MigratedQuoteIcon } from '@monetize/ui/icons';
import { UseFormReturn } from 'react-hook-form';
import { handleApiErrorToast } from '../../api/axios';
import { useUpdateNewQuoteType } from '../../api/cpqService';
import {
    INITIAL_MANUAL_RENEWAL_VALUES,
    QuoteStatusEnumDisplay,
    QuoteStatusTagStyle,
} from '../../constants/quotes';
import { QuoteTypeTag } from '../../routes/Quotes/Quote/components/QuoteTypeTag';
import { useACL } from '../../services/acl/acl';
import {
    INewQuoteTypeReqSchema,
    IQuoteRespSchema,
    NewQuoteTypeEnum,
    QuoteStatusEnum,
    QuoteTypeEnum,
} from '../../types';
import {
    MCircularProgress,
    MFlex,
    MHStack,
    MIcon,
    MTag,
    MTagCloseButton,
    MTagLabel,
    MText,
} from '../Monetize';
;

interface QuoteTagsV2Props extends StackProps {
  quote: IQuoteRespSchema | null | undefined;
  isReadOnly?: boolean;
  setQuote?: (quote: IQuoteRespSchema | null) => void;
  manualRenewalFormMethods?: UseFormReturn<INewQuoteTypeReqSchema, object>;
}

export const QuoteTagsV2 = ({
  quote,
  isReadOnly = false,
  setQuote,
  manualRenewalFormMethods,
  ...rest
}: QuoteTagsV2Props) => {
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);

  const { mutate: updateNewQuoteType, isPending: isLoading } =
    useUpdateNewQuoteType({
      onSuccess: (data) => {
        setQuote && setQuote(data);
        manualRenewalFormMethods?.reset(INITIAL_MANUAL_RENEWAL_VALUES);
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    });
  if (!quote) {
    return null;
  }

  const handleRemoveManualRenewal = () => {
    updateNewQuoteType({
      quoteId: quote.id,
      payload: { type: NewQuoteTypeEnum.NET_NEW },
    });
  };

  const isQuoteMigrated = quote?.migrated;
  const canRemoveManualRenewal =
    !isReadOnly &&
    canUpdateSales &&
    !isLoading &&
    quote.status === QuoteStatusEnum.DRAFT;

  return (
    <MHStack {...rest}>
      {[QuoteTypeEnum.AMENDMENT, QuoteTypeEnum.RENEWAL].includes(
        quote.type,
      ) && <QuoteTypeTag quote={quote} ml="0.5rem" />}

      <MText
        {...QuoteStatusTagStyle[quote.status]}
        ml=".5rem"
        data-testid="quote-tag-status"
      >
        {QuoteStatusEnumDisplay[quote.status]}
      </MText>

      {isQuoteMigrated && (
        <MFlex bgColor="tPurple.linkWater" pr={1} borderRadius={2}>
          <MIcon as={MigratedQuoteIcon} boxSize={5} />
          <MText m={0} color="tBlue.light">
            Migrated
          </MText>
        </MFlex>
      )}

      {quote.type === QuoteTypeEnum.NEW &&
        quote.newQuoteType === NewQuoteTypeEnum.MANUAL_RENEWAL && (
          <MTag variant="purple">
            <MTagLabel>Manual Renewal</MTagLabel>
            {isLoading && (
              <MCircularProgress
                ml="1.5"
                color="tPurple.safety"
                isIndeterminate
                size="3"
              />
            )}
            {canRemoveManualRenewal && (
              <MTagCloseButton onClick={handleRemoveManualRenewal} />
            )}
          </MTag>
        )}
    </MHStack>
  );
};
