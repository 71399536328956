import { toDateShort } from '@monetize/utils/core';
import { ColumnProps } from 'primereact/column';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MHStack,
  MPageHeader,
  MPageSearchInput,
  MText,
} from '../../../components/Monetize';
import { MDataTableFilter } from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { ROUTES } from '../../../constants';
import {
  CreditStatusEnumDisplay,
  CreditTypeEnumDisplay,
} from '../../../constants/credits';
import { getBillGroupPageRoute } from '../../../constants/routes';
import {
  ICreditWithBillGroup,
  useCredits,
} from '../../../hooks/AccountDetail/useCredits';
import { ACLCheck } from '../../../services/acl/ACLCheck';
import { useACL } from '../../../services/acl/acl';
import {
  CreditStatusEnum,
  CreditTypeEnum,
  FilterTypeOperator,
  IAccountRespSchema,
  IBillGroupResp,
  IGetCreditSchema,
  TransactableSourceType,
} from '../../../types';
import {
  formatCurrency,
  getFiltersApplied,
  getIsTrulyEmptyList,
} from '../../../utils';
import { objectToObjArray } from '../../../utils/misc';
import {
  currencyBodyTemplate,
  headerAndIdTemplate,
  nameWithIdBodyTemplate,
  statusBodyTemplate,
} from '../../../utils/tableUtils';
import { CreditActions } from './CreditActions';

export const CreditsList = ({
  account,
  billGroupId,
}: {
  account: IAccountRespSchema;
  billGroupId?: string;
}) => {
  const navigate = useNavigate();
  const { id: accountId = '' } = account;

  const {
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    listData,
    pager,
    setPager,
    loading,
    totalRecords,
    doActivateDeactivate,
  } = useCredits(accountId, billGroupId);

  const { canDo } = useACL();
  const canUpdateCredit = canDo([['billing', 'update']]);

  const onSelectRow = (rowData: IGetCreditSchema) => {
    const { id, creditNoteId, type } = rowData;
    if (
      [CreditTypeEnum.SERVICE, CreditTypeEnum.PREPAID].includes(type) &&
      canUpdateCredit
    ) {
      return navigate(ROUTES.getAccountCreditsViewRoute(accountId, id));
    }
    if (type === CreditTypeEnum.CREDIT_NOTE && canUpdateCredit) {
      return navigate(
        ROUTES.getAccountCreditNoteViewRoute(accountId, creditNoteId!),
      );
    }
  };

  const nameBodyTemplate = (rowData: IGetCreditSchema) => {
    const { type, name, id, creditNoteId } = rowData;
    return (
      <MBox width="12.5rem">
        <MText fontWeight="500" whiteSpace="normal">
          {name}
        </MText>
        <MText fontWeight="400" whiteSpace="normal">
          {type === CreditTypeEnum.SERVICE ? id : creditNoteId}
        </MText>
      </MBox>
    );
  };

  const expiryDateBodyTemplate = (rowData: IGetCreditSchema) => (
    <MText isTruncated noOfLines={1}>
      {rowData?.expirationDate ? toDateShort(rowData.expirationDate) : 'N/A'}
    </MText>
  );

  const columns: ColumnProps[] = [
    {
      field: 'name',
      header: 'Name',
      body: (credit: ICreditWithBillGroup) => {
        if (credit.type === CreditTypeEnum.CREDIT_NOTE) {
          return headerAndIdTemplate<ICreditWithBillGroup>(
            'name',
            'creditNoteId',
          )(credit);
        }

        return headerAndIdTemplate<ICreditWithBillGroup>('name', 'id')(credit);
      },
      sortable: true,
    },
    {
      field: 'billGroupId',
      header: 'Bill Group',
      body: nameWithIdBodyTemplate<ICreditWithBillGroup, IBillGroupResp>(
        'billGroup',
        {
          idLinkFn: (id) => getBillGroupPageRoute(id),
          fallbackProperty: 'billGroupId',
        },
      ),
      sortable: true,
      className: 'table-cell-md',
    },
    {
      field: 'type',
      header: 'Type',
      body: statusBodyTemplate<ICreditWithBillGroup, CreditTypeEnum>(
        'type',
        CreditTypeEnumDisplay,
      ),
      sortable: true,
      style: { width: '10em' },
    },
    {
      field: 'expirationDate',
      header: 'Expiration',
      body: expiryDateBodyTemplate,
      sortable: true,
      className: 'table-cell-md',
    },
    {
      field: 'status',
      header: 'Status',
      body: (credit: ICreditWithBillGroup) => {
        if (credit.status === CreditStatusEnum.VOID) {
          return (
            <MText
              color="tRed.base"
              noOfLines={1}
              maxW="fit-content"
              h="18px"
              lineHeight="18px"
            >
              {CreditStatusEnumDisplay[credit.status]}
            </MText>
          );
        }

        return statusBodyTemplate<ICreditWithBillGroup, CreditStatusEnum>(
          'status',
          CreditStatusEnumDisplay,
        )(credit);
      },
    },
    {
      field: 'amount',
      header: 'Amount',
      body: currencyBodyTemplate<ICreditWithBillGroup>('amount', 'currency'),
      sortable: true,
      style: { textAlign: 'right' },
      className: 'table-cell-md',
    },
    {
      field: 'amountRemaining',
      header: 'Amount Remaining',
      body: (credit: ICreditWithBillGroup) => {
        if (credit.status === CreditStatusEnum.VOID) {
          return (
            <MText noOfLines={1}>
              {formatCurrency(0, { currency: credit.currency })}
            </MText>
          );
        }

        return currencyBodyTemplate<ICreditWithBillGroup>(
          'amountRemaining',
          'currency',
        )(credit);
      },
      sortable: true,
      style: { textAlign: 'right' },
      className: 'table-cell-md',
    },
    {
      body: (credit: ICreditWithBillGroup) => (
        <CreditActions
          sourceType={
            credit.type === CreditTypeEnum.CREDIT_NOTE
              ? TransactableSourceType.creditNote
              : TransactableSourceType.credit
          }
          accountId={credit.accountId}
          applicationsSourceId={credit.id}
          amount={credit.amount}
          appliedAmount={credit.amountApplied}
          currency={credit.currency}
          billGroupId={credit.billGroupId}
          creditStatus={credit.status}
          creditNoteId={credit.creditNoteId}
        />
      ),
      style: { width: '4em' },
    },
  ];

  const statusItems = objectToObjArray(
    CreditStatusEnumDisplay,
    'value',
    'label',
  );
  const typeItems = objectToObjArray(CreditTypeEnumDisplay, 'value', 'label');

  const filterOptions = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
    {
      title: 'Type',
      key: 'type',
      operator: FilterTypeOperator.IN,
      items: typeItems,
    },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: listData?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  const getCreditCreateRoute = (
    accountIdProp: string,
    billGroupIdProp?: string,
  ) => {
    return billGroupIdProp
      ? ROUTES.getBillGroupCreditsCreateRoute(billGroupIdProp)
      : ROUTES.getAccountCreditCreate(accountIdProp);
  };

  return (
    <MBox data-testid="account-credits-list">
      <MPageHeader title="Credits" hideContent={isTrulyEmptyList}>
        <MFlex>
          <MHStack spacing="2">
            <MPageSearchInput
              placeholderKey="Name"
              value={searchTerm || ''}
              onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
              count={listData?.totalElements}
              resetSearch={searchComponentReset}
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={setFilters}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="credits"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              getFilename={() => `credits-${accountId}.csv`}
            />
            {(billGroupId || accountId) && (
              <ACLCheck acls={[['billing', 'create']]}>
                <MButton
                  variant="secondary"
                  as={Link}
                  to={getCreditCreateRoute(accountId, billGroupId)}
                >
                  New Credit
                </MButton>
              </ACLCheck>
            )}
          </MHStack>
        </MFlex>
      </MPageHeader>
      <MDataTable
        value={listData?.content}
        totalRecords={totalRecords}
        totalPages={listData?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        onSelectionChange={(e) => onSelectRow(e.value as IGetCreditSchema)}
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no credits here.',
          btnLabel: canDo([['billing', 'create']]) ? 'New Credit' : '',
          to:
            (billGroupId || accountId) &&
            getCreditCreateRoute(accountId, billGroupId),
        }}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset &&
            searchComponentReset.current &&
            searchComponentReset.current();
        }}
        loading={loading}
        columns={columns}
      />
    </MBox>
  );
};
