import { FormControl, FormLabel } from '@chakra-ui/react';
import { parseISO } from 'date-fns/parseISO';
import { FunctionComponent as FC } from 'react';
import {
  FilterTypeGlte,
  RenderTableFilterOptionProps,
} from '../../../../types';
import { MVStack } from '../../chakra';
import { DatePicker } from '../../DatePicker/DatePicker';

interface DateGlteTableFilterOptionContentProps
  extends RenderTableFilterOptionProps {
  showTimePicker?: boolean;
}

const DateGlteTableFilterOptionContent: FC<
  DateGlteTableFilterOptionContentProps
> = ({
  filter,
  filterOption,
  handleFilterChange,
  showTimePicker = false,
}: DateGlteTableFilterOptionContentProps) => {
  const internalFilter = filter as FilterTypeGlte;

  return (
    <MVStack>
      <FormControl>
        <FormLabel>From</FormLabel>
        <DatePicker
          includeTime={showTimePicker}
          placeholder="From"
          onChange={(date) => {
            handleFilterChange(
              {
                ...internalFilter?.value,
                min: date!,
              },
              filterOption,
            );
          }}
          value={internalFilter?.value?.min}
          maxDate={
            internalFilter?.value?.max
              ? parseISO(internalFilter.value.max)
              : undefined
          }
          clearable
        />
      </FormControl>
      <FormControl>
        <FormLabel>To</FormLabel>
        <DatePicker
          includeTime={showTimePicker}
          placeholder="To"
          onChange={(date) => {
            handleFilterChange(
              {
                ...internalFilter?.value,
                max: date!,
              },
              filterOption,
            );
          }}
          value={internalFilter?.value?.max}
          minDate={
            internalFilter?.value?.min
              ? parseISO(internalFilter.value.min)
              : undefined
          }
          clearable
        />
      </FormControl>
    </MVStack>
  );
};

export { DateGlteTableFilterOptionContent };
