import { ApexOptions } from 'apexcharts';
import { format as formatDate } from 'date-fns/format';
import { isBefore } from 'date-fns/isBefore';
import { startOfDay } from 'date-fns/startOfDay';
import { getPeriodsForRange, sortByProductObjType } from '.';
import {
  IGetSubscriptionItemSchema,
  IGetSubscriptionSchema,
  ISubscriptionOfferingReq,
  ISubscriptionOfferingReqUI,
  ISubscriptionProductReq,
  ISubscriptionProductReqUI,
  ISubscriptionsFormReq,
  ISubscriptionsFormReqUI,
  ISubscriptionUsageChartData,
  ISubscriptionUsagePerDayResponse,
} from '../types';
import { toDateOnly } from './dates';
import { arrayToObject } from './misc';

export const getCompletedSubOfferings = (
  formValues: ISubscriptionsFormReqUI,
): ISubscriptionOfferingReqUI[] => {
  return formValues.subscriptions.filter(
    (subscription: ISubscriptionOfferingReqUI) =>
      !!subscription.rateId &&
      Array.isArray(subscription.subscriptionItems) &&
      subscription.subscriptionItems.length > 0,
  );
};

const convertSubProductsToAPISchema = (
  subProducts: ISubscriptionProductReqUI[],
): ISubscriptionProductReq[] => {
  const convertedProducts = subProducts.map(
    (subProduct: ISubscriptionProductReqUI) => {
      const { product, units, ...offeringProductReq } = subProduct;
      return { ...offeringProductReq, units: Number(units) };
    },
  );
  return convertedProducts;
};

const convertSubOfferingsToAPISchema = (
  subscriptionOfferings: ISubscriptionOfferingReqUI[],
  effectiveDate: string,
): ISubscriptionOfferingReq[] => {
  const convertedSubOfferings = subscriptionOfferings.map(
    (subOffering: ISubscriptionOfferingReqUI) => {
      const convertedProducts = convertSubProductsToAPISchema(
        subOffering.subscriptionItems,
      );
      return {
        ...subOffering,
        subscriptionItems: convertedProducts,
        effectiveDate,
      };
    },
  );
  return convertedSubOfferings as ISubscriptionOfferingReq[];
};

export const convertSubscriptionsFormToAPISchema = (
  formValues: ISubscriptionsFormReqUI,
): ISubscriptionsFormReq => {
  // const formValues = getValues();
  const {
    effectiveDate = toDateOnly(new Date(), 'userTimezone'),
    ...requestValues
  } = formValues;
  // const { effectiveDate, ...requestValues } = formValues;
  const completedSubOfferings = getCompletedSubOfferings(formValues);
  const convertedSubOfferings = convertSubOfferingsToAPISchema(
    completedSubOfferings,
    effectiveDate!,
  );
  return { ...requestValues, subscriptions: convertedSubOfferings };
};

const convertSubscriptionProductRespToRequest = (
  subItemResp: IGetSubscriptionItemSchema,
): ISubscriptionProductReqUI => {
  // eslint-disable-next-line prefer-destructuring
  const units: number | undefined | null = subItemResp.units;
  const subItemReq: ISubscriptionProductReqUI = {
    id: subItemResp.id,
    description: subItemResp.description,
    units: units || units === 0 ? String(units) : '',
    orderItemId: subItemResp.orderItemId,
    productId: subItemResp.product.id,
    product: subItemResp.product,
    customPriceId: subItemResp.customPrice?.id,
    quoteItemId: subItemResp.quoteItemId,
  };
  return subItemReq;
};

const convertSubscriptionOfferingRespToRequest = (
  subResp: Partial<IGetSubscriptionSchema>,
): ISubscriptionOfferingReqUI => {
  const { offering, discounts, billGroupId, subscriptionItems, ...tmpSub } =
    subResp;
  const subItemReqs = subscriptionItems!.map(
    (subItem: IGetSubscriptionItemSchema) =>
      convertSubscriptionProductRespToRequest(subItem),
  );
  const sortedProductItems = sortByProductObjType(subItemReqs);
  const subscriptionOffering: Partial<ISubscriptionOfferingReqUI> = {
    ...tmpSub,
    referenceId: subResp.id!,
    subscriptionItems:
      (sortedProductItems as ISubscriptionProductReqUI[]) || subItemReqs,
    offeringId: offering!.id,
    discountIds: discounts!.map(({ id }) => id),
  };
  return subscriptionOffering as ISubscriptionOfferingReqUI;
};

export const convertSubscriptionsFormRespToRequest = (
  subscriptionResp: IGetSubscriptionSchema, // called a list for consistency, although the API only returns a single subscription within the list, unlike the POST endpoint, in which multiple subscriptions can be sent in a list and created at once
  blankSubscriptionsForm: ISubscriptionsFormReqUI,
): ISubscriptionsFormReqUI => {
  // properties in the SubscriptionsFormReq that are not returned in the response and that the FE does not use or send to the API:
  // contractEndDate, orderId, quoteId
  const {
    // response properties that do not belong anywhere in the API request:
    locked,
    mrr,
    createdBy,
    createDate,
    lastModifiedBy,
    modifyDate,
    periodStartDate,
    periodEndDate,
    usagePeriodStart,
    usagePeriodEnd,
    nextUsageBillDate,
    // response properties that stay on the SubscriptionsForm request:
    billGroupId,
    prorationDate,
    // response properties used on the single subscriptionOffering UI schema but not sent back with the request:
    offering,
    discounts,
    // properties that belong on the single subscriptionOffering, not the subscriptionsForm API request:
    ...subscriptionOfferingProperties
  } = subscriptionResp;
  const subscriptionOffering: ISubscriptionOfferingReqUI =
    convertSubscriptionOfferingRespToRequest({
      ...subscriptionOfferingProperties,
      offering,
      discounts,
    });
  const subscriptionsFormReqUI: ISubscriptionsFormReqUI = {
    ...blankSubscriptionsForm, // add back properties needed for a request which the API does not return in its response
    billGroupId,
    prorationDate,
    subscriptions: [subscriptionOffering],
  };
  return subscriptionsFormReqUI;
};

export function convertSubscriptionUsageToChartData(
  subscriptionItem: IGetSubscriptionItemSchema,
  usagePerDayResponse: ISubscriptionUsagePerDayResponse,
  chartOptions: ApexOptions,
  includeEndDateInChart = false,
): ISubscriptionUsageChartData {
  if (!subscriptionItem || !usagePerDayResponse) {
    throw new Error('Invalid subscription item or usage response');
  }
  const { period, ratedUsage } = usagePerDayResponse;
  const usageDataForProduct = [...ratedUsage].reverse();

  // Get all periods that should be displayed even if we don't have data for them
  const today = startOfDay(new Date());
  const endDate =
    includeEndDateInChart || isBefore(startOfDay(period.end), today)
      ? period.end
      : today;
  const periods = getPeriodsForRange(period.start, endDate, 'DAY', 'MMM dd');
  const seriesDataWithUsage = arrayToObject(
    usageDataForProduct?.map((usage) => ({
      x: formatDate(usage.date, 'MMM dd'),
      y: usage.unitsConsumed,
      usage,
    })),
    'x',
  );

  const output: ISubscriptionUsageChartData = {
    product: subscriptionItem.product,
    usageTypes: subscriptionItem?.product?.usageTypes,
    usageData: usageDataForProduct,
    totalRatedAmount: usageDataForProduct.reduce(
      (previousValue, currentValue) => previousValue + currentValue.ratedAmount,
      0,
    ),
    options: chartOptions,
    series: [
      {
        name: usageDataForProduct?.length
          ? usageDataForProduct[0].unitName
          : '',
        data: periods.map((x) => ({
          x,
          y: seriesDataWithUsage[x]?.y || 0,
          usage: seriesDataWithUsage[x]?.usage || null,
        })),
      },
    ],
  };
  return output;
}
