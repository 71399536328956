import { AvatarBadge } from '@chakra-ui/react';
import { MdStar } from 'react-icons/md';
import { IUsersInfo } from '../../types';
import { sortTeamUsers } from '../../utils/settings';
import { MAvatar, MIcon, MTooltip } from '../Monetize';

type MemberAvatarProps = {
  members: IUsersInfo[];
  owner: string;
};

/** 
 Display user avatar & owner badge
*/

export const MemberAvatar = ({ members, owner }: MemberAvatarProps) => {
  return (
    <>
      {sortTeamUsers(members, owner).map(({ name, id }) => (
        <MTooltip label={name} shouldWrapChildren key={id}>
          <MAvatar key={id} name={name} mr="2" width={'25px'} height={'25px'}>
            {id === owner && (
              <AvatarBadge bg="tIndigo.base" boxSize={3.5}>
                <MIcon as={MdStar} boxSize={3} />
              </AvatarBadge>
            )}
          </MAvatar>
        </MTooltip>
      ))}
    </>
  );
};
