import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../../api/axios';
import { useCreateEntity } from '../../../../../../api/queryUtils';
import {
  doTestTaxConnection,
  settingsQueryKeys,
} from '../../../../../../api/settingsService';
import {
  MAlert,
  MButton,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MHStack,
  MInput,
} from '../../../../../../components/Monetize';
import { TAX_IDENTIFIER_SELECT_OPTIONS } from '../../../../../../constants/taxes';
import { logger } from '../../../../../../services/logger';
import { Maybe } from '../../../../../../types';
import {
  ITaxConnectionSchema,
  TaxConnectionSchema,
  TaxIdentifierEnum,
  TaxProviderTypeEnum,
  TaxStatusEnum,
} from '../../../../../../types/taxTypes';

interface AnrokFormProps {
  connection?: Maybe<ITaxConnectionSchema>;
  handleCancel: () => void;
}

export const AnrokForm = ({ connection, handleCancel }: AnrokFormProps) => {
  const queryClient = useQueryClient();
  const [isStatusLoading, setStatusLoading] = useState<boolean>(false);
  const [testStatus, setTestStatus] = useState<{
    provider: TaxProviderTypeEnum;
    apiKey?: Maybe<string>;
  } | null>(() =>
    connection?.credentials
      ? {
          provider: TaxProviderTypeEnum.ANROK,
          apiKey: connection.credentials?.apiKey,
        }
      : null,
  );
  const [testStatusError, setTestStatusError] = useState<any>(null);

  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<ITaxConnectionSchema>({
    resolver: zodResolver(TaxConnectionSchema),
    mode: 'onSubmit',
    values: connection ? connection : undefined,
    defaultValues: {
      provider: TaxProviderTypeEnum.ANROK,
      configurations: {
        identifier: TaxIdentifierEnum.TAX_ITEM_CODE,
      },
      credentials: null,
      status: TaxStatusEnum.ACTIVE,
    },
  });

  const isValid = Object.keys(errors).length === 0;

  const { mutateAsync: createTaxConfiguration, isPending: isLoading } =
    useCreateEntity('tax', {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [...settingsQueryKeys.taxByProvider(TaxProviderTypeEnum.ANROK)],
          data,
        );
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    });

  const watchProvider = watch('provider');
  const watchApiKey = watch('credentials.apiKey');

  const isTestStatusValid =
    !testStatusError &&
    testStatus?.provider === watchProvider &&
    testStatus?.apiKey === watchApiKey;

  const onSubmitHandler = async (data: ITaxConnectionSchema) => {
    await createTaxConfiguration({
      ...data,
      credentials: {
        apiKey: data.credentials!.apiKey,
      },
    });
    handleCancel();
  };

  const onTestConnection = async () => {
    try {
      setStatusLoading(true);
      const { provider, credentials } = getValues();
      await doTestTaxConnection({
        provider,
        credentials: credentials?.apiKey
          ? {
              apiKey: credentials.apiKey,
            }
          : null,
      });

      setTestStatus({
        provider,
        apiKey: credentials?.apiKey ? credentials.apiKey : null,
      });

      setTestStatusError(null);
    } catch (error) {
      logger.error('Error while testing tax connection =======>', error);
      setTestStatusError(error);
    } finally {
      setStatusLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} style={{ width: '100%' }}>
      <MFlex flexDirection="column">
        <MFormField
          mb="4"
          error={errors.configurations?.identifier}
          label="Tax Calculation Field"
          isRequired
          tooltip="Choose which field on the product you would like us to send to Anrok as the product external id."
        >
          <Controller
            name="configurations.identifier"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MCustomSelect
                placeholder="Select Tax Calculation Field"
                items={TAX_IDENTIFIER_SELECT_OPTIONS}
                onChange={(value) => {
                  onChange(value);
                  if (testStatusError) {
                    setTestStatusError(null);
                  }
                }}
                {...rest}
              />
            )}
          />
        </MFormField>
        <MFlex alignItems="end">
          <MFormField
            error={errors.credentials?.apiKey}
            label="API Key"
            isRequired
          >
            <Controller
              name="credentials.apiKey"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <MInput
                  value={value || ''}
                  onChange={(value) => {
                    onChange(value);
                    if (testStatusError) {
                      setTestStatusError(null);
                    }
                  }}
                  {...rest}
                />
              )}
            />
          </MFormField>
        </MFlex>

        <MDivider my={4} />
        <MFlex justify="space-between" align="center">
          {!isTestStatusValid && !testStatusError && (
            <MButton
              type="submit"
              variant="tertiary"
              fontSize="xs"
              onClick={() => onTestConnection()}
              isLoading={isStatusLoading}
              isDisabled={!isValid || isStatusLoading || !watchApiKey}
            >
              Test Connection
            </MButton>
          )}

          {testStatus && isTestStatusValid && (
            <MAlert
              type="success"
              alertProps={{ px: 2, py: 1 }}
              alertIconProps={{ width: 4, height: 4 }}
              alertDescriptionProps={{ fontSize: 'sm' }}
            >
              Connection Valid
            </MAlert>
          )}
          {testStatusError && (
            <MAlert
              type="error"
              alertProps={{ px: 2, py: 1 }}
              alertIconProps={{ width: 4, height: 4 }}
              alertDescriptionProps={{ fontSize: 'sm' }}
            >
              Connection invalid
            </MAlert>
          )}
          <MHStack spacing="4" justify="end" align="center">
            <MButton variant="cancel" onClick={handleCancel}>
              Cancel
            </MButton>
            <MButton
              type="submit"
              variant="primary"
              isLoading={isLoading}
              isDisabled={!isTestStatusValid || isLoading}
            >
              Save
            </MButton>
          </MHStack>
        </MFlex>
      </MFlex>
    </form>
  );
};
