import { FC, useState } from 'react';
import { useResyncSalesforceProductCatalog } from '../../../../../../api/settingsService';
import { SettingCardEdit } from '../../../../../../components/Settings/SettingCardEdit';
import { SettingCardView } from '../../../../../../components/Settings/SettingCardView';
import { useFlags } from '../../../../../../services/launchDarkly';
import { useToast } from '../../../../../../services/toast';
import {
  ISalesforce,
  ISalesforceSchema,
  SettingCardViewAction,
} from '../../../../../../types';
import { SalesforceFieldMapping } from './SalesforceFieldMapping';
import { SalesforceForm } from './SalesforceForm';

const resyncToastMessages = {
  success: {
    summary: 'Product Catalog Synced Successfully',
    detail: 'Your Product Catalog has been synced to Salesforce.',
    severity: 'success',
  },
  warning: {
    summary: 'Product Catalog Partially Synced',
    detail:
      'One or more records were not synced to Salesforce, try again or contact support for assistance.',
    severity: 'warning',
  },
  error: {
    summary: 'Product Catalog Sync Failed',
    detail:
      'Your Product Catalog has not been synced, try reconnecting or contact support for assistance.',
    severity: 'error',
  },
} as const;

interface SalesforceCrmConnectionProps {
  connection: ISalesforce;
  onAuthorize: (data: ISalesforceSchema) => Promise<void>;
  verifyConnection: () => Promise<void>;
}

export const SalesforceCrmConnection: FC<SalesforceCrmConnectionProps> = ({
  connection,
  onAuthorize,
  verifyConnection,
}) => {
  const { crmFieldMappingSfdc } = useFlags();
  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const { addToast } = useToast();
  const { mutateAsync: doResyncProductCatalog } =
    useResyncSalesforceProductCatalog({
      onSuccess: (data) => {
        if (data.syncSummary === 'success') {
          addToast(resyncToastMessages.success);
        } else if (data.syncSummary === 'partial_success') {
          addToast(resyncToastMessages.warning);
        } else {
          addToast(resyncToastMessages.error);
        }
      },
      onError: (err) => {
        addToast(resyncToastMessages.error);
      },
    });

  const handleAction = async (action: SettingCardViewAction) => {
    switch (action) {
      case 'RESYNC-CATALOG':
        return doResyncProductCatalog();
      case 'VERIFY':
        await verifyConnection();
        break;
      case 'DELETE':
        break; // TODO:

      default:
        break;
    }
  };

  return (
    <>
      {mode === 'VIEW' && (
        <SettingCardView
          status={'ACTIVE'} // Once BE has error state, we will implement here
          actions={[
            {
              action: 'RESYNC-CATALOG',
              label: 'Resync Product Catalog',
              isDanger: false,
            },
            { action: 'VERIFY', label: 'Verify Connection' },
            // { // TODO: not yet implemented on BE
            //   action: 'DELETE',
            //   label: 'Delete',
            //   isDanger: true,
            //   confirmProps: {
            //     title: `Are you sure you want to delete this connection?`,
            //     description:
            //       'Once removed, this configuration will not longer be available.',
            //   },
            // },
          ]}
          type="Salesforce"
          tags={[
            {
              label: 'Environment',
              value: connection.orgType,
            },
            // {label: 'Username', value: connection.username}, // TODO: not yet available on BE
            { label: 'Instance URL', value: connection.instanceUrl },
          ]}
          onEdit={() => setMode('EDIT')}
          onAction={handleAction}
        />
      )}
      {mode === 'EDIT' && (
        <SettingCardEdit type="Salesforce">
          <SalesforceForm
            connection={connection}
            onAuthorize={onAuthorize}
            handleCancel={() => setMode('VIEW')}
          />
        </SettingCardEdit>
      )}
      {crmFieldMappingSfdc && <SalesforceFieldMapping />}
    </>
  );
};
