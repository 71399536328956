import { ApexOptions } from 'apexcharts';
import { parseISO } from 'date-fns/parseISO';
import { boolean, number, object, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { isNotNullish } from '../utils/misc';
import { SubscriptionItemCustomPriceSchema } from './customPriceType';
import {
  AmountUnitTypeEnum,
  ApplicableItemDiscountsSchema,
} from './discountTypes';
import { InvoiceUnsavedRespSchema } from './invoiceTypes';
import { BaseResponseSchema } from './miscTypes';
import { OfferingOnSubscriptionSchema } from './offeringTypes';
import { PriceModelEnumZ } from './priceTypes';
import {
  IProductResSchema,
  ProductResSchema,
  ProductTypeEnum,
} from './productTypes';
import { AggregationModelEnumZ } from './rateTypes';
import { IUsageTypeResSchema } from './usageTypes';

// from SubscriptionDetailDto at https://dev-nlb.monetizeplatform.com/swagger-ui/index.html?configUrl=/v3/api-docs/swagger-config#:~:text=SubscriptionDetailDto
export enum SubscriptionBillingStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  TRIAL = 'TRIAL',
}

export const SubscriptionBillingStatusEnumZ = z.nativeEnum(
  SubscriptionBillingStatusEnum,
);

// from SubscriptionDetailDto at https://dev-nlb.monetizeplatform.com/swagger-ui/index.html?configUrl=/v3/api-docs/swagger-config#:~:text=SubscriptionDetailDto
export enum SubscriptionProvisioningStatusEnum {
  PENDING = 'PENDING',
  PROVISIONED = 'PROVISIONED',
  SUSPENDED = 'SUSPENDED',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export const SubscriptionProvisioningStatusEnumZ = z.nativeEnum(
  SubscriptionProvisioningStatusEnum,
);

export enum UsagePeriodPointerEnum {
  CURRENT = 'CURRENT',
  PREVIOUS = 'PREVIOUS',
}

export const UsagePeriodPointerEnumZ = z.nativeEnum(UsagePeriodPointerEnum);

export interface ISubscriptionHistory {
  id: string;
  message: string;
  createDate: string;
  objectId: string;
  objectType: string;
  operation: string;
}

export const SubscriptionUsageDataSchema = z.object({
  date: z.string().transform((val) => parseISO(val)),
  ratedAmount: z.number(),
  unitName: z.string(),
  unitsConsumed: z.number(),
});

export type ISubscriptionUsageData = z.infer<
  typeof SubscriptionUsageDataSchema
>;

export const SubscriptionUsagePerDayResponseSchema = z.object({
  aggregrationModel: AggregationModelEnumZ,
  period: z.object({
    start: z.string().transform((val) => parseISO(val)),
    end: z.string().transform((val) => parseISO(val)),
  }),
  ratedUsage: z.array(SubscriptionUsageDataSchema),
});

export type ISubscriptionUsagePerDayResponse = z.infer<
  typeof SubscriptionUsagePerDayResponseSchema
>;

export interface ISubscriptionUsageChartData {
  product: IProductResSchema;
  usageTypes?: IUsageTypeResSchema[] | null;
  usageData: ISubscriptionUsageData[];
  totalRatedAmount: number;
  options: ApexOptions;
  series: {
    name: string;
    data: {
      x: string;
      y: number;
      usage: ISubscriptionUsageData | null;
    }[];
  }[];
}

/*  BEGIN REQUEST schemas used by at least 2 endpoints (and their UI-only variants):
    POST or PUT /api/subscriptions
    AND
    POST /api/subscriptions/pricing */

export const SubscriptionProductReqSchema = object({
  id: string().nullish(),
  description: string().nullish(),
  units: z
    .union([z.string(), z.number()])
    .refine(isNotNullish, getRequiredMessage('Quantity'))
    .transform((val) => Number(val)),
  productId: string().min(1),
  orderItemId: string().nullish(),
  customPriceId: string().nullish(),
  quoteItemId: string().nullish(),
});
export type ISubscriptionProductReq = z.infer<
  typeof SubscriptionProductReqSchema
>;

export const SubscriptionProductReqSchemaUI =
  SubscriptionProductReqSchema.extend({
    units: string(),
    product: ProductResSchema.optional(),
  });
export type ISubscriptionProductReqUI = z.infer<
  typeof SubscriptionProductReqSchemaUI
>;

export const SubscriptionOfferingReqSchema = object({
  id: string().nullish(),
  customId: string().nullish(),
  referenceId: string().min(1),
  offeringId: string().min(1),
  rateId: string().min(1),
  description: string().nullish(),
  addOn: boolean().nullish(),
  billingStatus: SubscriptionBillingStatusEnumZ,
  provisioningStatus: SubscriptionProvisioningStatusEnumZ.nullish(),
  trialStartDate: string().nullish(),
  trialEndDate: string().nullish(),
  trialEndAction: string().nullish(), // TODO: set up enum
  subscriptionItems: SubscriptionProductReqSchema.array(),
  discountIds: string().array().nullish(),
  canceled: boolean().optional(),
  nextBillDate: string().nullish(),
  nextUsageBillDate: string().nullish(),
  effectiveDate: string().nullish(),
  scheduledChanges: string().array().optional(), // punted on this, not used in FE currently
  quoteOfferingId: string().nullish(),
});
export type ISubscriptionOfferingReq = z.infer<
  typeof SubscriptionOfferingReqSchema
>;

export const SubscriptionOfferingReqSchemaUI =
  SubscriptionOfferingReqSchema.extend({
    subscriptionItems: SubscriptionProductReqSchemaUI.array(),
    offeringId: string().nullable(),
    rateId: string().nullable(),
  });
export type ISubscriptionOfferingReqUI = z.infer<
  typeof SubscriptionOfferingReqSchemaUI
>;

// from POST /api/subscriptions
export const SubscriptionsFormReqSchema = object({
  billGroupId: string().nonempty(),
  preview: boolean().optional(),
  invoiceNow: boolean().optional(),
  collectNow: boolean(),
  prorate: boolean(),
  resetBillGroup: boolean(),
  prorationDate: string().nullish(),
  contractEndDate: string().nullish(),
  orderId: string().nullish(),
  quoteId: string().nullish(),
  subscriptions: SubscriptionOfferingReqSchema.array(),
}).strict();
export type ISubscriptionsFormReq = z.infer<typeof SubscriptionsFormReqSchema>;

export const SubscriptionsFormReqSchemaUI = SubscriptionsFormReqSchema.extend({
  subscriptions: SubscriptionOfferingReqSchemaUI.array(),
  effectiveDate: string().nullish(), // positioned in the header in the UI only, for API purposes this is passed with every SubscriptionOffering
});
export type ISubscriptionsFormReqUI = z.infer<
  typeof SubscriptionsFormReqSchemaUI
>;

/*  END REQUEST schemas used by
    POST or PUT /api/subscriptions
    AND
    POST /api/subscriptions/pricing */
// =========================================
/*  BEGIN RESPONSE schemas used by:
    POST /api/subscriptions/pricing */

export const SubscriptionItemPricingResponseSchema = object({
  quantity: number(),
  productId: string().min(1),
  amount: number(),
  priceModel: PriceModelEnumZ,
  unitAmount: number().nullable(),
  amountWithoutDiscount: number().nullable(),
  discount: number().nullable(),
  creditProrationMultiplier: number().nullable(),
  creditAmount: number().nullable(),
  debitProrationMultiplier: number().nullable(),
  debitAmount: number().nullable(),
});

export type ISubscriptionItemPricingResponseSchema = z.infer<
  typeof SubscriptionItemPricingResponseSchema
>;

export const SubscriptionPricingResponseSchema = object({
  amount: number(),
  discount: number(),
  amountWithoutDiscount: number(),
  id: string().nullish(),
  referenceId: string().min(1),
  rateId: string().min(1),
  items: SubscriptionItemPricingResponseSchema.array(),
});

export type ISubscriptionPricingResponseSchema = z.infer<
  typeof SubscriptionPricingResponseSchema
>;

export const SubscriptionListPricingResponseSchema = object({
  amount: number(),
  discount: number(),
  amountWithoutDiscount: number(),
  subscriptions: SubscriptionPricingResponseSchema.array(),
});
export type ISubscriptionListPricingResponseSchema = z.infer<
  typeof SubscriptionListPricingResponseSchema
>;
/*  END RESPONSE schemas used by:
    POST /api/subscriptions/pricing */

// from GET /api/subscriptions/{id}:
export const GetSubscriptionItemSchema = object({
  id: string(),
  description: string().nullable(),
  units: number(),
  product: ProductResSchema,
  orderItemId: string().nullable(),
  customPrice: SubscriptionItemCustomPriceSchema.nullish(),
  applicableItemDiscounts: ApplicableItemDiscountsSchema.array(),
  quoteItemId: string().nullable(),
});
export type IGetSubscriptionItemSchema = z.infer<
  typeof GetSubscriptionItemSchema
>;

// from GET /api/subscriptions/{id}:
export const GetSubscriptionSchema = BaseResponseSchema.extend({
  customId: string(),
  billGroupId: string(),
  offering: OfferingOnSubscriptionSchema,
  rateId: string(),
  subscriptionItems: GetSubscriptionItemSchema.array(),
  description: string(),
  nextBillDate: string(),
  nextUsageBillDate: string(),
  addOn: boolean().nullish(),
  billingStatus: SubscriptionBillingStatusEnumZ,
  provisioningStatus: SubscriptionProvisioningStatusEnumZ,
  trialStartDate: string(),
  trialEndDate: string(),
  trialEndAction: string(),
  locked: boolean(),
  discounts: object({ id: string() }).array(), // TODO: use the correct type here, Discount types generally need lots of cleaning up
  periodStartDate: string(),
  periodEndDate: string(),
  prorationDate: string(),
  mrr: number(),
  usagePeriodStart: string().nullable(),
  usagePeriodEnd: string().nullable(),
  quoteOfferingId: string().nullable(),
});
export type IGetSubscriptionSchema = z.infer<typeof GetSubscriptionSchema>;

// from POST or PUT /api/subscriptions:
export const UpsertSubscriptionsRespSchema = object({
  isCollected: boolean(),
  subscriptions: GetSubscriptionSchema.array(),
  invoice: InvoiceUnsavedRespSchema,
});

export type IUpsertSubscriptionsRespSchema = z.infer<
  typeof UpsertSubscriptionsRespSchema
>;

// from PUT /api/billGroups/{{billGroupId}}/subscriptions/{{subscription0Id}}/cancel:
export const SubscriptionCancelSchema = z.object({
  effectiveDate: z.string().nonempty(getRequiredMessage('Effective Date')),
  prorate: z.boolean(),
});
export type ISubscriptionCancelSchema = z.infer<
  typeof SubscriptionCancelSchema
>;

/** Schedule changes */
// https://bitbucket.org/monetize-now/platform/pull-requests/3676#Lbilling-platform/src/main/java/com/monytyz/billing/subscription/model/enums/ChangeStatus.javaF3T3
export enum SubscriptionScheduledChangeEnum {
  PENDING = 'PENDING',
  INVOICED = 'INVOICED',
  COMPLETED = 'COMPLETED',
}
export type ISubscriptionScheduledChangeDiscount = {
  amount: number;
  discountType: AmountUnitTypeEnum;
  id: string;
  name: string;
};
export type ISubscriptionScheduledChangeItemChange = {
  id: string;
  productId: string;
  productName: string;
  productType: ProductTypeEnum;
  units: number;
};
export type ISubscriptionScheduledChange = {
  billingStatus: SubscriptionBillingStatusEnum;
  discounts: ISubscriptionScheduledChangeDiscount[];
  effectiveDate: string;
  id: string;
  invoice: boolean;
  itemChanges: ISubscriptionScheduledChangeItemChange[];
  prorate: boolean;
  quoteOfferingId: string | null;
  rateId: string | null;
  rateName: string;
  status: SubscriptionScheduledChangeEnum;
  subscriptionId: string;
};
