import { doGetTeamById } from '../../../../api/settingsService';
import { doGetTenantUser } from '../../../../api/usersService';
import { MBox, MCustomSelect, MText } from '../../../../components/Monetize';
import { useCustomSelectValue } from '../../../../components/Monetize/MCustomSelect/useCustomSelectValue';
import { ITeamResponseSchema, IUser } from '../../../../types';
import { MCustomSelectProps } from '../../../../types/mCustomSelectTypes';

const TabItem = ({ text, active, onClick }: any) => (
  <MBox onClick={onClick} p={2} cursor="pointer" fontSize="md">
    <MText
      color={active ? 'tPurple.base' : 'tGray.lightPurple'}
      fontWeight="bold"
    >
      {text}
    </MText>
  </MBox>
);

interface PersonTeamFilterProps extends MCustomSelectProps {
  tenantId: string;
  filterBy: {
    type: 'person' | 'team' | null;
  };
  setFilterBy: (filterBy: { type: 'person' | 'team' | null }) => void;
  filterValues: {
    person: string | null;
    team: string | null;
  };
  setFilterValues: (filterValues: {
    person: string | null;
    team: string | null;
  }) => void;
  clearable?: boolean;
}

const PersonTeamFilter = ({
  tenantId,
  filterBy,
  setFilterBy,
  filterValues,
  setFilterValues,
  clearable = false,
  ...rest
}: PersonTeamFilterProps) => {
  let props: any = {};
  if (filterBy.type === 'person') {
    props = {
      value: filterValues.person,
      onChange: (value: string) =>
        setFilterValues({ ...filterValues, person: value || '' }),

      endpoint: `/api/tenants/${tenantId}/users`,
      itemTitle: 'name',
      itemValue: 'id',
      doGetOneById: (userId: string) => doGetTenantUser(tenantId, userId),
    };
  } else {
    props = {
      value: filterValues.team,
      onChange: (value: string) =>
        setFilterValues({ ...filterValues, team: value || '' }),

      endpoint: `/api/teams`,
      itemTitle: 'name',
      itemValue: 'id',
      doGetOneById: doGetTeamById,
    };
  }

  const { doGetOneById, ...selectProps } = props;
  const {
    internalValue,
    isLoading: isLoadingSavedValue,
    onInternalValueChange,
  } = useCustomSelectValue<ITeamResponseSchema | IUser>({
    value: selectProps.value,
    setValue: selectProps.onChange,
    getOneById: doGetOneById,
  });

  const renderPreContent = () => (
    <MBox borderBottom={1} display="flex" mb={1} borderColor="tGray.darkPurple">
      <TabItem
        active={filterBy.type === 'person'}
        onClick={() => setFilterBy({ type: 'person' })}
        text="Person"
      />
      <TabItem
        active={filterBy.type === 'team'}
        onClick={() => setFilterBy({ type: 'team' })}
        text="Team"
      />
    </MBox>
  );

  return (
    <MCustomSelect
      placeholder="Select..."
      {...selectProps}
      renderPreContent={renderPreContent}
      showQueryInput
      loadAll={false}
      clearable={clearable}
      loading={isLoadingSavedValue}
      value={internalValue}
      returnItem
      onChange={onInternalValueChange}
      popOverContentProps={{ minWidth: 0 }}
      {...rest}
    />
  );
};

export { PersonTeamFilter };
