import { BoxProps } from '@chakra-ui/react';
import { MBox, MText } from '../../../../components/Monetize';
import MRowWithLineSeparator from '../../../../components/Monetize/MRowWithLineSeparator';
import { colors } from '../../../../styles/theme';
import { AmountUnitTypeEnum, IQuoteDiscounts } from '../../../../types';
import { formatCurrency, formatPercentNumerator } from '../../../../utils';

interface QuoteDiscountsProps {
  data: IQuoteDiscounts;
  showHeading?: boolean;
  lineProps?: BoxProps;
  currency: string;
}
export const QuoteDiscounts = ({
  data,
  showHeading = false,
  lineProps,
  currency,
}: QuoteDiscountsProps) => {
  return (
    <MBox>
      {showHeading && (
        <MBox
          py="4"
          my="10"
          textAlign="center"
          backgroundColor={`${colors.tPurple.linkWater}7F`}
        >
          <MText fontWeight="bold" fontSize="lg" textTransform="uppercase">
            Discounts
          </MText>
        </MBox>
      )}

      <MBox>
        {data.discounts.map((discount, index) => (
          <MBox key={index}>
            <MRowWithLineSeparator
              alignItems="flex-start"
              mb="0"
              lineProps={{
                background: 'tBlue.lightShade',
                marginTop: '10px',
                ...lineProps,
              }}
              leftLabel={
                <MText fontWeight="bold" maxW="50rem">
                  {discount.name}{' '}
                  {discount.description && (
                    <MText fontWeight="400" as="span">
                      {' '}
                      - {discount.description}
                    </MText>
                  )}
                </MText>
              }
              rightLabel={
                <MText>
                  {discount.type === AmountUnitTypeEnum.PERCENTAGE
                    ? `${formatPercentNumerator(
                        discount.percentageAmount || 0,
                        { currency },
                      )} `
                    : ''}
                  {!!discount.amount &&
                    formatCurrency(-discount.amount, { currency })}
                </MText>
              }
            />
          </MBox>
        ))}

        {!!data.total && (
          <MRowWithLineSeparator
            lineProps={{ background: 'tBlue.lightShade', ...lineProps }}
            leftLabel={
              <MText fontWeight="bold" fontSize="lg">
                Total Discount
              </MText>
            }
            rightLabel={
              <MText fontWeight="bold" fontSize="lg">
                {formatCurrency(-data.total, { currency })}
              </MText>
            }
          />
        )}
      </MBox>
    </MBox>
  );
};
