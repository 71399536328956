import { formatNumber } from '@monetize/utils/core';
import { MdInfo } from 'react-icons/md';
import { MSkeleton, MText } from '../../../../components/Monetize';
import { MBox, MIcon, MTooltip } from '../../../../components/Monetize/chakra';
import { IUsageTypeResSchema } from '../../../../types';

export const UsageUnit = ({
  consumptionLoading,
  hasError,
  usageTypes,
  unitsConsumed,
}: {
  consumptionLoading: boolean;
  hasError: boolean;
  usageTypes: IUsageTypeResSchema[];
  unitsConsumed: number | undefined | null;
}) => {
  if (consumptionLoading) {
    return <MSkeleton height={5} w={7} />;
  }

  if (hasError) {
    return (
      <MTooltip label="Failed to load usage data">
        <MBox display="inline">
          <MIcon as={MdInfo} w={4} h={4} color="tRed.base" />
        </MBox>
      </MTooltip>
    );
  }

  const usageType = usageTypes && usageTypes?.length > 0 ? usageTypes[0] : null;

  return (
    <MText>
      {formatNumber(unitsConsumed ?? 0, {
        maximumFractionDigits: usageType?.decimalPlaces ?? 0,
      })}
    </MText>
  );
};
