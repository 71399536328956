import {
  EntityNameEnum,
  EventTypeEnum,
  IntegrationLogStatusEnum,
} from '../../../../types/integrationLogTypes';

export const EntityNameEnumDisplay: Record<EntityNameEnum, string> = {
  [EntityNameEnum.INVOICE]: 'Invoice',
  [EntityNameEnum.PAYMENT]: 'Payment',
  [EntityNameEnum.CREDIT_NOTE]: 'Credit Note',
  [EntityNameEnum.CREDIT]: 'Credit',
  [EntityNameEnum.REFUND]: 'Refund',
};

export const EventTypeEnumDisplay: Record<EventTypeEnum, string> = {
  [EventTypeEnum.CREATE]: 'Create',
  [EventTypeEnum.UPDATE]: 'Update',
};

export const IntegrationStatusEnumDisplay: Record<
  IntegrationLogStatusEnum,
  string
> = {
  [IntegrationLogStatusEnum.FAILED]: 'Failed',
  [IntegrationLogStatusEnum.SUCCESS]: 'Successful',
};

export const IntegrationLogStatusTagStyle: {
  [key in IntegrationLogStatusEnum]: {
    color: string;
    bgColor?: string;
    px?: number;
    borderRadius?: number;
    fontStyle?: string;
  };
} = {
  Successful: {
    color: 'tGray.darkGrayPurple',
    bgColor: 'transparent',
  },
  Failed: {
    color: 'tRed.base',
    bgColor: 'tRed.cosmos',
    px: 2,
    borderRadius: 4,
  },
};
