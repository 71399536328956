import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useGetAllBillGroups } from '../../api/billGroupService';
import { useGetListData, usePerformEntityAction } from '../../api/queryUtils';
import {
  ApiListResponse,
  BillGroupSummarySchema,
  DEFAULT_API_RESPONSE,
  DEFAULT_PAGER,
  FilterType,
  GetCreditSchema,
  IGetCreditSchema,
} from '../../types';
import { buildFilterParamsRequestObject } from '../../utils';
import { arrayToObject } from '../../utils/misc';

const CreditWithBillGroup = GetCreditSchema.extend({
  billGroup: BillGroupSummarySchema,
});
export type ICreditWithBillGroup = z.infer<typeof CreditWithBillGroup>;

export const useCredits = (accountId: string, billGroupId?: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [pager, setPager] = useState(DEFAULT_PAGER);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchKey] = useState<string>('name');
  const [filterParams, setFilterParams] = useState(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  const {
    data: listData,
    isLoading: isAccountCreditResLoading,
    isFetched,
    isRefetching,
  } = useGetListData<IGetCreditSchema>(
    'accountCredits',
    {
      config: pager,
      filters: billGroupId ? { ...filterParams, billGroupId } : filterParams,
    },
    { endpointArgs: { accountId } },
  );
  const { isLoading: isBillGroupResLoading, data: billGroupsById } =
    useGetAllBillGroups(
      { filters: { accountId } },
      {
        enabled: !!accountId,
        select: (data) => arrayToObject(data, 'id'),
      },
    );

  const [creditsWithBillGroup, setCreditsWithBillGroup] =
    useState<ApiListResponse<ICreditWithBillGroup>>();

  useEffect(() => {
    if (listData && billGroupsById) {
      setCreditsWithBillGroup(() => {
        return {
          ...listData,
          content: listData.content.map((credit) => {
            return {
              ...credit,
              billGroup: billGroupsById[credit.billGroupId],
            };
          }),
        };
      });
    }
  }, [listData, billGroupsById]);

  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IGetCreditSchema>('accountCredits', {
      endpointArgs: { accountId, skipNestedEndpoint: true },
      meta: { showErrorToast: true },
    });

  useEffect(() => {
    if (filters) {
      setFilterParams(
        buildFilterParamsRequestObject(filters, searchTerm, searchKey),
      );
    }
  }, [filters, searchTerm, pager, searchKey]);

  const onResetFilter = () => {
    setFilters([]);
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    totalRecords: listData?.totalElements || 0,
    loading:
      isBillGroupResLoading ||
      isAccountCreditResLoading ||
      (isRefetching && !isFetched),
    listData: creditsWithBillGroup || DEFAULT_API_RESPONSE,
    doActivateDeactivate,
  };
};
