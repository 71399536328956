import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    fontWeight: 'normal',
    bg: 'tWhite.base',
    color: 'tPurple.dark',
    _hover: {
      bg: 'tBlue.hover',
      color: 'tPurple.dark',
    },
  },
  list: {
    border: '1px solid',
    borderColor: 'tGray.lightPurple',
    borderRadius: '2',
    bg: 'tWhite.base',
    p: 2,
    minW: 'fit-content',
  },
  item: {
    color: 'tPurple.dark',
    fontSize: 'sm',
    _hover: {
      bg: 'tBlue.hover',
      color: 'tPurple.dark',
    },
    _focus: {
      bg: 'tBlue.hover',
      color: 'tPurple.dark',
      fontWeight: 'bold',
    },
  },
  groupTitle: {
    // this will style the text defined by the title prop
    // in the MenuGroup and MenuOptionGroup components
    // If/When we implement this, we can figure out styles
  },
  command: {
    // this will style the text defined by the command
    // prop in the MenuItem and MenuItemOption components
    // If/When we implement this, we can figure out styles
  },
  divider: {
    // this will style the MenuDivider component
    // If/When we implement this, we can figure out styles
  },
});

export const menuTheme = defineMultiStyleConfig({
  variants: {
    outline: baseStyle,
  },
  baseStyle,
  defaultProps: {
    variant: 'outline',
  },
});

export default menuTheme;
