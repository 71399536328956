import { useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '../../api/axios';
import {
  useAcceptInvite,
  useDeleteTenant,
  useGetTenants,
} from '../../api/tenantService';
import {
  MBox,
  MButton,
  MFlex,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MVStack,
} from '../../components/Monetize';
import { TenantForm } from '../../components/Tenants/TenantForm';
import { ROUTES } from '../../constants';
import { useAuth } from '../../services/auth0';
import { useConfirmModal } from '../../services/confirmModal';
import { DEFAULT_PAGER, ITenant, TDataTablePager } from '../../types';
import { TenantCard } from './components/TenantCard';
import { TenantTabs } from './components/TenantTabs';

const pager: TDataTablePager = {
  ...DEFAULT_PAGER,
  rows: 100000,
  sortField: 'name',
  sortOrder: 1,
};

export const TenantPage = () => {
  const {
    tenantId,
    user,
    currentTenant,
    getAndHandleAccessTokenSilently,
    selectTenant,
  } = useAuth();
  const navigate = useNavigate();
  const [tenantEditData, setTenantEditData] = useState<ITenant | null>(null);
  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const {
    showConfirmModal: showDeleteModal,
    setModalLoading: setDeleteModalLoading,
    closeModal: closeDeleteModal,
  } = useConfirmModal();

  const { data: tenants = [], isLoading } = useGetTenants({ config: pager });
  const { mutateAsync: doAcceptInvite } = useAcceptInvite();
  const { mutateAsync: doDeleteTenant } = useDeleteTenant();

  const onDeleteTenant = (tenant: ITenant) => {
    const onYes = async () => {
      setDeleteModalLoading(true);
      try {
        await doDeleteTenant({ id: tenant.id });
        if (tenantId === tenant.id) {
          // If deleted tenant is the current tenant, then navigate to switch tenant
          selectTenant(null);
          navigate(ROUTES.ONBOARD_SELECT_TENANT);
        }
      } catch (err) {
        handleApiErrorToast(err);
      }
      setDeleteModalLoading(false);
      closeDeleteModal();
    };

    showDeleteModal({
      title: `Are you sure you want to delete "${tenant.name}"?`,
      description: 'Once deleted, you cannot access this tenant anymore.',
      onYes,
      yesBtnProps: {
        variant: 'delete' as any,
      },
    });
  };

  const handleEditTenant = (tenant: ITenant) => {
    setTenantEditData(tenant);
    onOpenModal();
  };

  const handleCreateTenant = () => {
    setTenantEditData(null);
    onOpenModal();
  };

  const onAcceptInvite = async (tenant: ITenant) => {
    try {
      await getAndHandleAccessTokenSilently();
      await doAcceptInvite({ id: tenant.id });
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  const onSwitchTenant = (tenant: ITenant) => {
    navigate(ROUTES.getSwitchTenantRoute(tenant.id));
  };

  const handleTenantSave = (tenant: ITenant) => {
    if (!tenantEditData) {
      navigate(ROUTES.getSwitchTenantRoute(tenant.id!));
    } else {
      setTenantEditData(null);
    }
  };

  return (
    <MPageContainer>
      <MPageHeader title="Manage Tenants">
        <MFlex>
          <MButton
            variant="primary"
            ml="2"
            onClick={handleCreateTenant}
            data-testid="tenant-new-btn"
          >
            New
          </MButton>
        </MFlex>
      </MPageHeader>

      {isLoading ? (
        <MPageLoader />
      ) : (
        <MBox width="full">
          <MVStack spacing={2} align="stretch" maxW="700px" mb={4}>
            {currentTenant && (
              <MVStack align="stretch" pt={2} mb={3} mt={-4}>
                <TenantCard
                  origin="page"
                  tenant={currentTenant}
                  isCurrentTenant
                  onDelete={onDeleteTenant}
                  onEdit={handleEditTenant}
                  onAccept={() => onAcceptInvite(currentTenant)}
                  onClick={onSwitchTenant}
                />
              </MVStack>
            )}

            <TenantTabs
              origin="page"
              tenants={tenants}
              onSwitchTenant={onSwitchTenant}
              onAcceptInvite={onAcceptInvite}
              onEdit={handleEditTenant}
              onDelete={onDeleteTenant}
            />
          </MVStack>
        </MBox>
      )}

      {isModalOpen && (
        <TenantForm
          isOpen
          onClose={onCloseModal}
          existingTenant={tenantEditData}
          onSaved={handleTenantSave}
        />
      )}
    </MPageContainer>
  );
};
