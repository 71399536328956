import { BoxProps } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { Accept, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { ACCEPT_FILES } from '../../constants/misc';
import { useToast } from '../../services/toast';
import { MBox } from './';

type AcceptFileType = keyof typeof ACCEPT_FILES;
interface MFileUploadButtonProps extends BoxProps {
  accept?: AcceptFileType[];
  label?: string;
  subtitle?: string;
  renderBoxContent: (data: { open: () => void }) => React.ReactElement;

  onFileUpload: (file: File) => void;
  maxFileSize?: number; // Max File size in MB, undefined for unlimited file size
  uploadLoading?: boolean;
  uploadProgressPercentage?: number;
  onCancel?: () => void;
}

export const MFileUploadButton = ({
  accept = ['*'],
  label = 'Drag your file here',
  subtitle,
  onFileUpload,
  renderBoxContent,
  maxFileSize = 10,
  uploadLoading,
  uploadProgressPercentage,
  onCancel,
  ...restProps
}: MFileUploadButtonProps) => {
  const { addToast } = useToast();
  const [fileError, setFileError] = useState<ErrorCode | null | string>(null);

  const onDrop = useCallback(
    async (files: File[], rejectedFiles: FileRejection[]) => {
      if (files.length) {
        setFileError(null);
        onFileUpload(files[0]);
      } else if (rejectedFiles.length) {
        if (fileError === ErrorCode.FileTooLarge) {
          addToast({
            summary: 'Upload File',
            detail: `The file exceeds the maximum allowed size of ${maxFileSize}MB`,
            severity: 'error',
          });
        } else if (fileError === ErrorCode.FileInvalidType) {
          addToast({
            summary: 'Upload File',
            detail: `Invalid File Format. Supported type: ${acceptedTypes
              .map((ac) => ac.label)
              .join(', ')}`,
            severity: 'error',
          });
        }
      }
    },
    [onFileUpload],
  );

  const acceptedTypes = accept.map((acc) => ACCEPT_FILES[acc]);

  const _accept: Accept = acceptedTypes.reduce((acc, key) => {
    key.accepted.forEach((it: any) => (acc[it] = []));
    return acc;
  }, {} as Accept);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: _accept,
    multiple: false,
    maxSize: maxFileSize * 1000000,
    onDrop,
    onFileDialogCancel: () => {
      onCancel && onCancel();
    },
  });

  return (
    <MBox
      position="relative"
      backgroundColor={isDragActive ? 'tBlue.hover' : 'transparent'}
      borderWidth="0px"
      {...getRootProps({
        className: 'dropzone',
        onClick: (event) => event.stopPropagation(),
      })}
    >
      <input {...getInputProps()} />
      {renderBoxContent({ open })}
    </MBox>
  );
};
