import { Link } from '@chakra-ui/react';
import { formatCurrency } from '@monetize/utils/core';
import { useQuoteContext } from '../../routes/Quotes/Quote/quoteContext';
import { IQuoteRespSchema, QuoteTypeEnum } from '../../types';
import { MDivider, MFlex, MLink, MText } from '../Monetize';
import { MLastSavedStatus } from '../Monetize/MLastSavedStatus';
import { QuoteAnalyticsV2 } from './quoteAnalytics/QuoteAnalyticsV2';

const QuoteHeaderSummaryItem = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) => {
  return (
    <MFlex alignItems="center">
      <MText fontWeight="bold" mr="2">
        {label}:{' '}
      </MText>
      {children}
    </MFlex>
  );
};

export const QuoteHeaderSummary = () => {
  const {
    isReadOnly,
    isError,
    quoteData,
    quoteStateData,
    manualRenewalFormMethods,
    isInternalView,
    isQuoteSaving,
  } = useQuoteContext();
  const { quote, setQuote } = quoteData;
  const { offeringLoadingState } = quoteStateData;
  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  if (!quote) {
    return null;
  }

  return (
    <MFlex alignItems="center">
      {!isReadOnly && quote.modifyDate && isInternalView && (
        <MLastSavedStatus
          isLoading={quoteData.saving || anyOfferingLoading || isQuoteSaving}
          isError={isError}
        >
          <MDivider
            orientation="vertical"
            mx="3"
            borderColor="tBlack.200"
            height="20px"
          />
        </MLastSavedStatus>
      )}

      <QuoteHeaderSummaryItem label="ARR">
        <QuoteAnalyticsV2
          quote={quote as IQuoteRespSchema}
          setQuote={setQuote}
          manualRenewalFormMethods={manualRenewalFormMethods}
          renderTrigger={({ onOpen }) => (
            <MLink fontSize="sm" fontWeight="normal" as={Link} onClick={onOpen}>
              {formatCurrency(quote?.revenueMetrics.arr, {
                currency: quote?.currency,
              })}
            </MLink>
          )}
        />
      </QuoteHeaderSummaryItem>

      <MDivider
        orientation="vertical"
        mx="3"
        borderColor="tBlack.200"
        height="20px"
      />
      <QuoteHeaderSummaryItem
        label={quote.type === QuoteTypeEnum.AMENDMENT ? 'iARR' : 'TCV'}
      >
        <QuoteAnalyticsV2
          quote={quote as IQuoteRespSchema}
          setQuote={setQuote}
          manualRenewalFormMethods={manualRenewalFormMethods}
          renderTrigger={({ onOpen }) => (
            <MLink fontSize="sm" fontWeight="normal" as={Link} onClick={onOpen}>
              {formatCurrency(
                quote.type === QuoteTypeEnum.AMENDMENT
                  ? quote?.revenueMetrics.incrementalArr
                  : quote?.amount,
                { currency: quote?.currency },
              )}
            </MLink>
          )}
        />
      </QuoteHeaderSummaryItem>
    </MFlex>
  );
};
