import { useEffect, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import {
  MAccordion,
  MBox,
  MButton,
  MFlex,
  MIcon,
  MPageLoader,
} from '../../../../components/Monetize';
import { useDocumentHead } from '../../../../services/documentHead';
import { QuoteBanners } from '../../Quote/quoteBanners/QuoteBanners';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteBasic } from './QuoteBasic';
import { QuoteCustomizingPdf } from './QuoteCustomizingPdf';
import { QuoteInternalBilling } from './QuoteInternalBilling';
import { QuoteInternalExtraInfo } from './QuoteInternalExtraInfo';
import { QuoteInternalOfferings } from './QuoteInternalOfferings';

interface QuotePageInternalProps {
  setContactDrawerOpen: (val: boolean) => void;
  customizePdfFormDisabled: boolean;
}
const ACCORDION_EXPAND_ALL = [0, 1, 2, 3, 4];

export const QuotePageInternal = ({
  setContactDrawerOpen,
  customizePdfFormDisabled,
}: QuotePageInternalProps) => {
  const { quoteData, handleSubmitButton } = useQuoteContext();
  const { quote } = quoteData;
  const { setDocTitle } = useDocumentHead();
  const [openAccordionIndex, setOpenAccordionIndex] = useState([0]);

  const isAccordionExpanded =
    openAccordionIndex.length === ACCORDION_EXPAND_ALL.length;

  const onToggleAccordion = () => {
    if (isAccordionExpanded) {
      setOpenAccordionIndex([]);
    } else {
      setOpenAccordionIndex(ACCORDION_EXPAND_ALL);
    }
  };

  useEffect(() => {
    setDocTitle(
      quote?.id &&
        `Edit Quote${quote.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description, quote?.id, setDocTitle]);

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <form onSubmit={handleSubmitButton} style={{ width: '100%' }}>
      <QuoteBanners />

      <MFlex alignItems="center" mb="1" justifyContent="flex-end">
        <MButton variant="tertiary" onClick={onToggleAccordion}>
          {isAccordionExpanded ? 'Collapse' : 'Expand'} All
          <MIcon
            as={isAccordionExpanded ? MdArrowDropUp : MdArrowDropDown}
            boxSize="5"
            ml="1"
          />
        </MButton>
      </MFlex>

      <MBox
        overflow="hidden"
        marginBottom="100px" // Give some space below
      >
        <MAccordion
          allowMultiple
          variant="bottomBorder"
          index={openAccordionIndex}
          onChange={(indexes: number[]) => setOpenAccordionIndex(indexes)}
        >
          <QuoteBasic
            onOpenContactDrawer={() => {
              setContactDrawerOpen(true);
            }}
          />
          <QuoteInternalOfferings />
          <QuoteInternalExtraInfo />
          <QuoteInternalBilling
            onOpenContactDrawer={() => setContactDrawerOpen(true)}
          />
          <QuoteCustomizingPdf
            accordionIndex={4}
            openAccordionIndex={openAccordionIndex}
            setContactDrawerOpen={setContactDrawerOpen}
            customizePdfFormDisabled={customizePdfFormDisabled}
          />
        </MAccordion>
      </MBox>
    </form>
  );
};
