import { z } from 'zod';
import { StorageReferenceTypeEnumZ, StorageTypeEnumZ } from './enums.types';

export const StorageSchema = z.object({
  id: z.string(),
  filename: z.string(),
  mediaType: z.string(),
  referenceFilename: z.string(),
  referenceId: z.string(),
  contentBase64Encoded: z.string().nullish(),
  referenceType: StorageReferenceTypeEnumZ,
  type: StorageTypeEnumZ,
});
export type IStorage = z.infer<typeof StorageSchema>;
