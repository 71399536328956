import { useDisclosure } from '@chakra-ui/react';
import { useRef } from 'react';
import { MdSettings } from 'react-icons/md';
import { useFlags } from '../../services/launchDarkly';
import {
  IOfferingRes,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResSchema,
  OnQuoteOfferingChange,
  PriceDisplayEnum,
} from '../../types';
import {
  MButton,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MTab,
  MTabList,
  MTabPanel,
  MTabPanels,
  MTabs,
  MVStack,
} from '../Monetize';
import { PriceDisplayFrequencyConfiguration } from './PriceDisplayFrequencyConfiguration';
import { PriceDisplaySettingsConfiguration } from './PriceDisplaySettingsConfiguration';
import { PriceUpliftConfiguration } from './PriceUpliftConfiguration';

type QuoteOfferingConfigurationDrawerProps = {
  isReadOnly: boolean;
  isDisabled: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  onOfferingChange: OnQuoteOfferingChange;
  renderTrigger?: ({
    onOpen,
    isDisabled,
  }: {
    onOpen: () => void;
    isDisabled: boolean;
  }) => React.ReactElement;
  handleQuoteOfferingUpdate: ({
    priceDisplay,
  }: {
    priceDisplay?: PriceDisplayEnum;
  }) => void;
  offering: IOfferingRes | null;
  offeringRate: IRateResSchema | null;
  quote?: IQuoteRespSchema | null;
  isOfferingLoading: boolean;
};

const TABS = [
  // NOTE: @tawsif is working on this part
  // {
  //   id: 'billing-frequency',
  //   title: 'Billing Frequency',
  // },
  {
    id: 'price-uplift',
    title: 'Price Uplift',
  },
  {
    id: 'display-settings',
    title: 'Display Settings',
  },
];

export const QuoteOfferingConfigurationDrawer = ({
  isReadOnly,
  isDisabled,
  quoteOffering,
  onOfferingChange,
  renderTrigger,
  handleQuoteOfferingUpdate,
  offering,
  offeringRate,
  quote,
  isOfferingLoading,
}: QuoteOfferingConfigurationDrawerProps) => {
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showPricingLevelConfigure } = useFlags();

  return (
    <>
      {renderTrigger ? (
        renderTrigger({ onOpen, isDisabled })
      ) : (
        <MButton
          leftIcon={<MdSettings />}
          ref={btnRef}
          variant="tertiary"
          size="sm"
          onClick={onOpen}
          isDisabled={isDisabled}
          data-testid="price-uplift-configure-btn"
        >
          Configure
        </MButton>
      )}
      <MDrawer
        size="md"
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        blockScrollOnMount={false}
      >
        <MDrawerOverlay />
        <MDrawerContent>
          <MDrawerCloseButton />
          <MDrawerHeader
            fontSize="md"
            borderBottomColor="tGray.back"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
          >
            {quoteOffering?.offeringName}
          </MDrawerHeader>
          <MDrawerBody p={0} bg="tGray.sidebarDark" color="tGray.darkPurple">
            <MTabs variant="line" size="sm" bg="tWhite.base" defaultIndex={0}>
              <MTabList px={6} py={2}>
                {TABS.map(({ id, title }) => (
                  <MTab key={id}>{title}</MTab>
                ))}
              </MTabList>
              <MTabPanels p={2} bg="tGray.sidebarDark">
                {/* // NOTE: @tawsif is working on this part */}
                {/* <MTabPanel p={0}>
                  <MFlex>Placeholder of subscription frequency</MFlex>
                </MTabPanel> */}
                <MTabPanel p={4} bg="tWhite.base" borderRadius={2}>
                  <PriceUpliftConfiguration
                    isReadOnly={isReadOnly}
                    quoteOffering={quoteOffering}
                    onOfferingChange={onOfferingChange}
                  />
                </MTabPanel>
                <MTabPanel p={0}>
                  <MVStack spacing="4">
                    {showPricingLevelConfigure && (
                      <PriceDisplaySettingsConfiguration
                        isReadOnly={isReadOnly}
                        quoteOffering={quoteOffering}
                        handleQuoteOfferingUpdate={handleQuoteOfferingUpdate}
                        offering={offering}
                        offeringRate={offeringRate}
                        quote={quote}
                        isOfferingLoading={isOfferingLoading}
                      />
                    )}
                    <PriceDisplayFrequencyConfiguration
                      isReadOnly={isReadOnly}
                      quoteOffering={quoteOffering}
                      onOfferingChange={onOfferingChange}
                      offering={offering}
                      quote={quote}
                      isOfferingLoading={isOfferingLoading}
                    />
                  </MVStack>
                </MTabPanel>
              </MTabPanels>
            </MTabs>
          </MDrawerBody>
          <MDrawerFooter
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <MButton type="button" variant="cancel" onClick={onClose}>
              Close
            </MButton>
          </MDrawerFooter>
        </MDrawerContent>
      </MDrawer>
    </>
  );
};
