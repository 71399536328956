import { ISlackConnectionActive } from '@monetize/types/app';
import { useState } from 'react';
import {
  useChangeSlackConnectionStatus,
  useDeleteSlackConnection,
} from '../../../../../api/settingsService';
import { SettingCardView } from '../../../../../components/Settings/SettingCardView';
import { SettingCardViewAction } from '../../../../../types';

interface SlackConnectionProps {
  connection: ISlackConnectionActive;
  reAuthorize: () => void;
}

const SlackConnection = ({ connection, reAuthorize }: SlackConnectionProps) => {
  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const { mutateAsync: doDeleteSlackConnection } = useDeleteSlackConnection();
  const { mutateAsync: doChangeSlackConnectionStatus } =
    useChangeSlackConnectionStatus();

  const handleAction = async (action: SettingCardViewAction) => {
    switch (action) {
      case 'RE_AUTHENTICATE':
        reAuthorize();
        break;
      case 'DISABLE':
        doChangeSlackConnectionStatus('DISABLE');
        break;
      case 'ENABLE':
        doChangeSlackConnectionStatus('ENABLE');
        break;
      case 'DELETE':
        await doDeleteSlackConnection();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {mode === 'VIEW' && (
        <SettingCardView
          omitEditButton
          status={connection.status}
          actions={[
            { action: 'RE_AUTHENTICATE', label: 'Re-Authenticate' },
            connection.status == 'ACTIVE'
              ? { action: 'DISABLE', label: 'Disable' }
              : { action: 'ENABLE', label: 'Enable' },
            {
              action: 'DELETE',
              label: 'Delete',
              isDanger: true,
              confirmProps: {
                title: `Are you sure you want to delete this connection?`,
                description:
                  'Once removed, this configuration will not longer be available.',
              },
            },
          ]}
          type="Slack"
          tags={[
            {
              label: 'Team Name',
              value: connection.teamName,
            },
          ]}
          onAction={handleAction}
          onEdit={() => {}}
        />
      )}
    </>
  );
};

export default SlackConnection;
