import { useEffect } from 'react';
import { EmbeddedMetabase } from '../../components/Metabase/EmbeddedMetabase';
import {
  MBox,
  MHeading,
  MPageContainer,
  MPageHeader,
} from '../../components/Monetize';
import { useDocumentHead } from '../../services/documentHead';

const ReportPage = () => {
  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Reporting');
  }, []);

  return (
    <MPageContainer data-testid="repording" maxWidth="none">
      <MPageHeader title="Reporting">
        <MHeading
          size="lg"
          fontWeight="normal"
          pl="1"
          color="tGray.lightPurple"
          marginBottom="1px"
        />
      </MPageHeader>
      <MBox w="100%" h="100%" flex="1" display="flex" flexDirection="column">
        <EmbeddedMetabase />
      </MBox>
    </MPageContainer>
  );
};

export { ReportPage };
