import { Grid, GridItem } from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons/lib';
import { SETTING_CARD_LOGOS } from '../../constants/settings';
import {
  IConfirmModalProps,
  SettingCardType,
  SettingCardViewAction,
} from '../../types';
import { MButton, MDivider, MFlex, MFormField, MText } from '../Monetize';
import {
  ActionType,
  DataTableActions,
} from '../Monetize/DataTable/MDataTableActions';
import { MEntityStatusBadge } from '../Monetize/MEntityStatusBadge';

const StatusDisplay = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  ERROR: 'Error',
} as const;

interface SettingCardViewProps {
  type: SettingCardType;
  status: 'ACTIVE' | 'INACTIVE' | 'ERROR';
  omitEditButton?: boolean;
  tags: {
    label: string;
    value: string;
    colSpan?: null | 1 | 2;
  }[];
  actions: {
    action: SettingCardViewAction;
    label: string;
    icon?: IconType;
    isDanger?: boolean;
    confirmProps?: Partial<IConfirmModalProps> | null;
  }[];
  onEdit: () => void;
  onAction: (action: SettingCardViewAction) => void;
}

export const SettingCardView: FC<SettingCardViewProps> = ({
  type,
  tags,
  actions,
  status,
  omitEditButton,
  onEdit,
  onAction,
}) => {
  const Logo = SETTING_CARD_LOGOS[type];

  const tableActions: ActionType[] = actions.map(
    ({ action, isDanger, label, icon, confirmProps }) => ({
      title: label,
      icon,
      action: () => onAction(action),
      color: isDanger ? 'danger' : undefined,
      enabled: true,
      confirmProps,
    }),
  );

  return (
    <MFlex
      bgColor="tWhite.base"
      alignItems="flex-start"
      flexDirection="column"
      p={4}
      border="1px solid"
      borderColor="tGray.back"
      borderRadius="3px"
      w="600px"
    >
      <MFlex justifyContent="space-between" w="100%" minH={8}>
        <MFlex alignItems="center">
          <Logo />
          <MText ml={2} fontSize="14px">
            {type}
          </MText>
          <MEntityStatusBadge status={StatusDisplay[status]} />
        </MFlex>
        <MFlex alignItems="center">
          {!omitEditButton && (
            <MButton variant="tertiary" size="sm" onClick={onEdit}>
              Edit
            </MButton>
          )}
          <DataTableActions actions={tableActions} />
        </MFlex>
      </MFlex>
      <MDivider my={4} />
      <Grid gap={4} templateColumns="repeat(2, 275px)" w="100%">
        {tags.map(({ label, value, colSpan }) => (
          <GridItem key={label} colSpan={colSpan || 2}>
            <MFormField
              key={label}
              label={label}
              minW="6rem"
              width="unset"
              overflow="hidden"
            >
              <MText isTruncated title={value}>
                {value}
              </MText>
            </MFormField>
          </GridItem>
        ))}
      </Grid>
    </MFlex>
  );
};
