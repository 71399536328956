import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../constants";
import { useToast } from "../../../../services/toast";

export const SlackCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const errorMessage = searchParams.get('errorMessage') || null;

  useEffect(() => {
    if (errorMessage) {
      addToast({
        summary: `Slack Connection Failed`,
        detail: `There was an error connecting to Slack. Please try again.`,
        severity: 'error',
      });
    } else {
      addToast({
        summary: `Slack Connected`,
        detail: `Slack has been successfully connected.`,
        severity: 'info',
      });
    }

    navigate(ROUTES.SETTINGS_SLACK, {});
  }, []);

  return null;
};