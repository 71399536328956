import { ROLES, ROUTES } from '../../../constants';
import { useAuth } from '../../../services/auth0';
import { FeatureFlag, useFlags } from '../../../services/launchDarkly';
import { ISettingsSideNav } from './settingsNavType';

/**
 * Get available settings based on role and feature flags
 * * Items are removed from list based on permissions
 * * isComingSoon is updated to account for feature flag availability
 */
export const useSettingsNavRoutes = (): ISettingsSideNav<string>[] => {
  const featureFlags = useFlags();
  const { currentTenantUserHasRole } = useAuth();

  // Filter out parent items that are not available based on permissions
  return (
    SETTINGS_NAV_ROUTES.filter(({ roles }) => currentTenantUserHasRole(roles))
      .map((setting) => ({
        ...setting,
        children: setting.children
          // Filter out children that are not available based on permissions
          .filter(({ roles }) => currentTenantUserHasRole(roles))
          .map((child) => ({
            ...child,
            route:
              typeof child.route === 'function'
                ? child.route(featureFlags)
                : child.route,
            // Update isComingSoon to account for feature flag availability
            isComingSoon:
              child.isComingSoon ||
              (child.requiresFeatureFlag &&
                !featureFlags[child.requiresFeatureFlag]),
          })),
      }))
      // Don't show header level items if there are no children
      .filter(({ children }) => children.length > 0)
  );
};

const SETTINGS_NAV_ROUTES: ISettingsSideNav<
  string | ((featureFlags: FeatureFlag) => string)
>[] = [
  {
    title: 'Tenant',
    roles: ROLES.TENANT_MENU_ROLES,
    children: [
      {
        key: 'apikeys',
        title: 'API Keys',
        route: ROUTES.API_KEYS,
        roles: ROLES.API_KEYS_ROLES,
        description: 'Create and manage API keys for external integrations.',
      },
      {
        key: 'companysettings',
        title: 'Company Settings',
        route: ROUTES.COMPANY_SETTINGS,
        roles: ROLES.COMPANY_SETTINGS_ROLES,
        description:
          'Configure your company information to personalize your customers experience.',
      },
      {
        key: 'customfields',
        title: 'Custom Fields',
        route: ROUTES.CUSTOM_FIELDS_LIST,
        roles: ROLES.CUSTOM_FIELDS_ROLES,
        description: 'Create and manage Custom Fields unique to your business.',
        requiresFeatureFlag: 'customFields',
      },
      {
        key: 'currencies',
        title: 'Currencies',
        route: ROUTES.SETTINGS_CURRENCIES,
        roles: ROLES.TENANT_MENU_ROLES,
        description: 'Define which currencies your company supports.',
      },
      {
        key: 'domains',
        title: 'Domains',
        route: ROUTES.SETTINGS_DOMAINS,
        roles: ROLES.DOMAIN_SETTINGS_ROLES,
        description:
          'Configure which email domains are allowed when inviting users to your tenant.',
        isComingSoon: true,
      },
      {
        key: 'notifications',
        title: 'Notifications',
        route: ROUTES.SETTINGS_NOTIFICATIONS,
        roles: ROLES.EMAIL_NOTIFICATIONS_ROLES,
        description:
          'Configure your internal and external notification frequency.',
      },
      {
        key: 'onboarding',
        title: 'Onboarding',
        route: ROUTES.ONBOARDING,
        roles: ROLES.BILLING_ADMIN_ROLES,
        description: 'Get started with MonetizeNow.',
        requiresFeatureFlag: 'guidedOnboardingEnabled',
      },
      {
        key: 'usersroles',
        title: 'Users & Roles',
        route: ROUTES.TENANT_DETAIL_USERS_LIST,
        roles: ROLES.TENANT_MENU_ROLES,
        description: 'Invite and manage the users in your tenant.',
      },
    ],
  },
  {
    title: 'Billing',
    roles: ROLES.BILLING_MENU_ROLES,
    children: [
      {
        key: 'dunning',
        title: 'Dunning',
        route: ROUTES.SETTINGS_DUNNING_LIST,
        roles: ROLES.SETTINGS_DUNNING_ROLES,
        requiresFeatureFlag: 'dunningEnabled',
        description: 'Configure your strategy for when an account is past due.',
      },
      {
        key: 'invoices',
        title: 'Invoices',
        route: ROUTES.SETTINGS_INVOICES,
        roles: ROLES.SETTINGS_INVOICES_ROLE,
        description: 'Manage and customize how you automate and send invoices.',
      },
      {
        key: 'invoicetemplates',
        title: 'Invoice Templates',
        route: ROUTES.SETTINGS_INVOICE_TEMPLATES,
        roles: ROLES.SETTINGS_INVOICE_TEMPLATES_ROLE,
        description: 'Customize the look and feel of your invoices.',
      },
      {
        key: 'pricing',
        title: 'Pricing',
        route: ROUTES.SETTINGS_PRICING,
        roles: ROLES.SETTINGS_PRICING_ROLE,
        description: 'Configure how you want pricing to work.',
      },
      {
        key: 'billingpayments',
        title: 'Payments',
        route: ROUTES.SETTINGS_PAYMENTS,
        roles: ROLES.SETTINGS_PAYMENT_ROLE,
        description: 'Connect and manage payment gateways.',
      },
      {
        key: 'revenuerecognition',
        title: 'Revenue',
        route: ROUTES.SETTINGS_REV_REC,
        roles: ROLES.SETTINGS_REV_REC_ROLES,
        description: 'Configure revenue recognition rules and policies.',
        isComingSoon: false,
        requiresFeatureFlag: 'revenueRecognition',
      },
      {
        key: 'billingsettings',
        title: 'Billing Settings',
        route: ROUTES.SETTINGS_BILLING_SETTINGS,
        roles: ROLES.SETTINGS_BILLING_SETTINGS_ROLES,
        description:
          'Define how billing settings, like accounting periods and more.',
        isComingSoon: false,
        requiresFeatureFlag: 'enableBillingSettings',
      },
    ],
  },

  {
    title: 'Quoting',
    roles: ROLES.QUOTING_MENU_ROLES,
    children: [
      {
        key: 'approvalrules',
        title: 'Approval Rules',
        route: () => ROUTES.SETTINGS_APPROVALS_V3,
        roles: ROLES.SETTINGS_APPROVALS_ROLES,
        description:
          'Create and manage rules that define who and when a Quote needs to be approved.',
      },
      {
        key: 'conditionalterms',
        title: 'Conditional Terms',
        route: () => ROUTES.SETTINGS_CONDITIONAL_TERMS_V3,
        roles: ROLES.SETTINGS_CONDITIONAL_TERM_ROLES,
        description: 'Automatically apply terms to quotes based on criteria.',
      },
      {
        key: 'dealRoom',
        title: 'Deal Room',
        route: () => ROUTES.SETTINGS_DEAL_ROOM,
        roles: ROLES.SETTINGS_DEAL_ROOM_ROLES,
        description: 'Configure and manage your company’s Dealrooms.',
        requiresFeatureFlag: 'useDealRoom',
      },
      {
        key: 'quotingemails',
        title: 'Emails',
        route: ROUTES.SETTINGS_EMAILS,
        roles: ROLES.SETTINGS_EMAIL_REMINDERS,
        description: 'Set the frequency of email reminders for Quotes.',
      },
      {
        key: 'guidedquoting',
        title: 'Guided Quoting',
        route: () => ROUTES.SETTINGS_GUIDED_QUOTING,
        roles: ROLES.ADMIN_ROLES,
        description: `Create and manage guided quoting configurations to help create quotes.`,
        requiresFeatureFlag: 'guidedSelling',
      },
      {
        key: 'orgmanagement',
        title: 'Org Management',
        route: '',
        roles: ROLES.QUOTING_MENU_ROLES,
        description: 'Manage your organization settings and connect your SSO.',
        isComingSoon: true,
      },
      {
        key: 'quotesettings',
        title: 'Quote Settings',
        route: ROUTES.SETTINGS_QUOTE_SETTINGS,
        roles: ROLES.SETTINGS_APPROVALS_ROLES,
        description: 'Configure and manage Quote automation and behavior.',
      },
      {
        key: 'quotetemplates',
        title: 'Quote Templates',
        route: ROUTES.SETTINGS_QUOTE_TEMPLATES,
        roles: ROLES.QUOTING_MENU_ROLES,
        description: 'Customize the look and feel of your Quotes.',
      },
      {
        key: 'teams',
        title: 'Teams',
        route: ROUTES.SETTINGS_TEAMS,
        roles: ROLES.SETTINGS_TEAMS_ROLES,
        description:
          'Set up approval and sales teams to match your team structure.',
      },
      {
        key: 'validationrules',
        title: 'Validation Rules',
        route: () => ROUTES.SETTINGS_VALIDATIONS_V3,
        roles: ROLES.SETTINGS_VALIDATIONS_ROLES,
        description:
          'Create and manage rules that define what Quote configurations are allowed.',
      },
    ],
  },

  {
    title: 'Integrations',
    roles: ROLES.INTEGRATIONS_MENU_ROLE,
    children: [
      {
        key: 'accounting',
        title: 'Accounting',
        route: ROUTES.SETTINGS_ACCOUNTING,
        roles: ROLES.SETTINGS_ACCOUNTING_ROLES,
        description: 'Connect and configure your accounting integration.',
        requiresFeatureFlag: 'accountingSettings',
      },
      {
        key: 'integrationlog',
        title: 'Integration Log',
        route: ROUTES.SETTINGS_INTEGRATION_LOG,
        roles: ROLES.SETTINGS_INTEGRATION_LOG_ROLES,
        description: 'View data sync records with external integrations',
        requiresFeatureFlag: 'integrationLogSettings',
      },
      {
        key: 'crm',
        title: 'CRM',
        route: ROUTES.SETTINGS_CRM,
        roles: ROLES.SETTINGS_CRM_ROLES,
        description:
          'Connect and manage your CRM for a bidirectional integration.',
      },
      {
        key: 'esign',
        title: 'eSign',
        route: ROUTES.SETTINGS_ESIGN,
        roles: ROLES.SETTINGS_E_SIGN_ROLES,
        description: 'Connect and configure your eSign provider like DocuSign.',
      },
      {
        key: 'paymentgateway',
        title: 'Payment Gateway',
        route: ROUTES.SETTINGS_PAYMENT_PROCESSOR,
        roles: ROLES.SETTINGS_PAYMENT_PROCESSOR_ROLES,
        description: 'Connect and configure payment gateways.',
      },
      {
        key: 'slack',
        title: 'Slack',
        route: ROUTES.SETTINGS_SLACK,
        roles: ROLES.SETTINGS_SLACK_ROLES,
        description: 'Connect your tenant to a Slack workspace.',
        requiresFeatureFlag: 'slackSettings',
      },
      {
        key: 'tax',
        title: 'Tax',
        route: ROUTES.SETTINGS_TAX,
        roles: ROLES.SETTINGS_TAX_ROLES,
        description:
          'Connect your tax processor and manage how you will handle taxation during billing.',
        isComingSoon: false,
        requiresFeatureFlag: 'taxAnrok',
      },
      {
        key: 'webhooks',
        title: 'Webhooks',
        route: ROUTES.SETTINGS_WEBHOOK_LIST,
        roles: ROLES.SETTINGS_WEBHOOK_ROLES,
        description:
          'Set up and manage Webhooks to integrate with other external services.',
      },
    ],
  },
];
