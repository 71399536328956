import { CardBody } from '@chakra-ui/react';
import { formatCurrency } from '@monetize/utils/core';
import { FunctionComponent as FC } from 'react';
import { MCard, MDivider, MFlex, MText } from '../../Monetize';

interface QuoteAnalyticsCardProps {
  label: string;
  amount: number;
  currency: string;
  offerings: {
    name: string;
    amount: number;
    products: {
      name: string;
      amount: number;
    }[];
  }[];
}

export const QuoteAnalyticsCard: FC<QuoteAnalyticsCardProps> = (
  props: QuoteAnalyticsCardProps,
) => {
  const { amount, label, offerings, currency } = props;
  return (
    <MCard variant="borderless" mt="2">
      <CardBody>
        <MFlex flexDirection="column">
          <MFlex justifyContent="space-between">
            <MText fontSize="2xl">{label}</MText>
            <MText fontSize="2xl">{formatCurrency(amount, { currency })}</MText>
          </MFlex>
          <MDivider />
          {offerings.map((offering, i) => (
            <MFlex
              key={`${offering.name}-${i}`}
              flexDirection="column"
              justifyContent="space-between"
              mt={6}
            >
              <MFlex justifyContent="space-between">
                <MText fontSize="md" fontWeight={600}>
                  {offering.name}
                </MText>
                <MText fontSize="md" fontWeight={600}>
                  {formatCurrency(offering.amount, { currency })}
                </MText>
              </MFlex>
              <MDivider />
              {offering.products.map((product) => (
                <MFlex
                  key={product.name}
                  mt={2}
                  pl={4}
                  justifyContent="space-between"
                >
                  <MText>{product.name}</MText>
                  <MText>{formatCurrency(product.amount, { currency })}</MText>
                </MFlex>
              ))}
            </MFlex>
          ))}
        </MFlex>
      </CardBody>
    </MCard>
  );
};
