import { ProductResWithMandatory } from '../../../types';
import { IOneTimeOfferingProductRequestSchema } from '../../../types/oneTimeInvoiceTypes';

export const getProductObj = ({
  id,
  description,
  isMandatory,
  isSelected,
}: ProductResWithMandatory): IOneTimeOfferingProductRequestSchema => {
  return {
    productId: id!,
    quantity: 1,
    unitPrice: 0,
    productDescription: description,
    isMandatory,
    isSelected,
  };
};
