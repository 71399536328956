import { FormatNumberOptions, NumberStyleEnum } from '@monetize/types/common';

// NOTE: Intl.NumberFormat should handle any formatting we want, so consider adding the appropriate option to this function
// before building our own strings (ie. setting currencySign = 'accounting' gives us parentheses instead of '-' to indicate negative number)
// For details on Intl.NumberFormat options (ie. currencySign), see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat

export const formatNumber = (
  input: number | string | null | undefined,
  {
    style = NumberStyleEnum.DECIMAL,
    zeroStr,
    currency,
    currencySign,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  }: FormatNumberOptions = {},
): string => {
  let value;
  if (input || input === 0) {
    value = +input;
  } else {
    return '';
  }
  if (zeroStr && value === 0) {
    return zeroStr;
  }

  if (maximumFractionDigits === 'max' || maximumFractionDigits > 16) {
    maximumFractionDigits = 16; // max supported by JS is documented as 20 but in practice seems to be 16
  }
  if (minimumFractionDigits > maximumFractionDigits) {
    minimumFractionDigits = maximumFractionDigits;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    currency,
    currencySign,
    signDisplay: value === 0 ? 'never' : 'auto',
  } as Intl.ResolvedNumberFormatOptions);
  const formatted = formatter.format(value);
  return formatted;
};

export const formatInteger = (
  input: number | string | null | undefined,
  {
    minimumFractionDigits = 0,
    maximumFractionDigits = 0,
    ...rest
  }: FormatNumberOptions = {},
): string => {
  return formatNumber(input, {
    minimumFractionDigits,
    maximumFractionDigits,
    ...rest,
  });
};

export const formatCurrency = (
  input: number | string | null | undefined,
  {
    style = NumberStyleEnum.CURRENCY,
    currency = 'USD',
    currencySign = 'accounting', // parentheses for negative number
    ...rest
  }: // }: Omit<FormatNumberOptions, 'currency'> & { currency: string },
  FormatNumberOptions = {},
): string => {
  return formatNumber(input, {
    style,
    currency,
    currencySign,
    maximumFractionDigits: 'max',
    ...rest,
  });
};

// for use with real number values, not values that are the numerator of a percent
// ie. the value .0918, which would be displayed as 9.18%
export const formatPercent = (
  input: number | string | null | undefined,
  { style = NumberStyleEnum.PERCENT, ...rest }: FormatNumberOptions = {},
): string => {
  return formatNumber(input, { style, ...rest });
};

// handles a value that is expressed as the numerator only and needs to be divided by denominator 100 to find the true value
// ie. the value 9.18 which is intended to mean 9.18/100 and would be displayed as 9.18%
export const formatPercentNumerator = (
  input: number | string | null | undefined,
  style: FormatNumberOptions = {},
): string => {
  return formatPercent(input ? Number(input) / 100 : input, style);
};

export const roundNumberToDecimal = (value: number, digits = 2) => {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (value < 0) {
    negative = true;
    value = value * -1;
  }
  const multiplicator = Math.pow(10, digits);
  value = parseFloat((value * multiplicator).toFixed(11));
  value = +(Math.round(value) / multiplicator).toFixed(digits);
  if (negative) {
    value = +(value * -1).toFixed(digits);
  }
  return value;
};
