import { ColumnProps } from 'primereact/column';
import React, { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useActivateDeactivateContact,
  useUpdateContact,
} from '../../../api/contactsService';
import {
  MButton,
  MDataTable,
  MFlex,
  MHStack,
  MPageContentContainer,
  MPageHeader,
  MPageSearchInput,
  MTag,
  MText,
  MVStack,
} from '../../../components/Monetize';
import { MDataTableActions } from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { CONTACT_STATUS_DISPLAY } from '../../../constants/contacts';
import {
  getAccountContactCreate,
  getAccountContactEdit,
  getAccountContactView,
} from '../../../constants/routes';
import { useContacts } from '../../../hooks/AccountDetail';
import { useACL } from '../../../services/acl/acl';
import { ACLCheck } from '../../../services/acl/ACLCheck';
import { ContactStatusEnum, IContactRespSchema } from '../../../types';
import { getFiltersApplied, getIsTrulyEmptyList } from '../../../utils';
import { getAddress } from '../../../utils/address';
import {
  idBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '../../../utils/tableUtils';

const ContactList: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params?.accountId || '';
  const { canDo } = useACL();
  const canUpdateAccountContacts = canDo([['account_contacts', 'update']]);
  const {
    filters,
    setFilters,
    onResetFilter,
    searchKey,
    searchTerm,
    setSearchTerm,
    listData,
    pager,
    setPager,
    loading,
  } = useContacts(accountId);

  const onSelectRow = (rowData: IContactRespSchema) => {
    if (canUpdateAccountContacts) {
      navigate(getAccountContactEdit(accountId, rowData?.id));
    } else {
      navigate(getAccountContactView(accountId, rowData?.id));
    }
  };

  const fullNameBodyTemplate = (rowData: IContactRespSchema) => (
    <MHStack>
      <MText noOfLines={1} onClick={() => onSelectRow(rowData)}>
        {rowData.fullName}
      </MText>
      {rowData.primary && (
        // If name is truncated, then width is not set correctly without minWidth being explicitly specified
        <MTag variant="blue" ml="3">
          Primary
        </MTag>
      )}
    </MHStack>
  );

  const addressBodyTemplate = (rowData: IContactRespSchema) => {
    if (!rowData.address) {
      return null;
    }

    const { cityStatePCCountry } = getAddress(rowData.address);
    return (
      <MVStack alignItems="flex-start" spacing="0.2rem" my="0.8rem">
        {rowData.address.line1 && (
          <MText noOfLines={1}>{rowData.address.line1}</MText>
        )}
        {rowData.address.line2 && (
          <MText noOfLines={1}>{rowData.address.line2}</MText>
        )}
        <MText noOfLines={1}>{cityStatePCCountry}</MText>
      </MVStack>
    );
  };

  const { mutate: activateDeactivateMutate } = useActivateDeactivateContact();
  const { mutate: doUpdateContact } = useUpdateContact();

  const handleDeactivateContact = async (rowData: IContactRespSchema) => {
    await activateDeactivateMutate({
      action: 'cancel',
      accountId,
      contactId: rowData.id,
    });
  };

  const handleActivateContact = async (rowData: IContactRespSchema) => {
    await activateDeactivateMutate({
      action: 'activate',
      accountId,
      contactId: rowData.id,
    });
  };

  const handleMakePrimaryContact = async (rowData: IContactRespSchema) => {
    await doUpdateContact({
      contactId: rowData.id,
      payload: {
        ...rowData,
        primary: true,
      },
    });
  };

  const actionsBodyTemplate = (rowData: IContactRespSchema) => {
    const { status, primary } = rowData;
    const actions = [
      {
        title: 'Set as Primary',
        enabled:
          !primary &&
          status === ContactStatusEnum.ACTIVE &&
          canUpdateAccountContacts,
        action: () => handleMakePrimaryContact(rowData),
      },
      {
        title: 'Edit',
        enabled: canUpdateAccountContacts,
        action: () => onSelectRow(rowData),
      },
      {
        title: 'Activate',
        enabled:
          status === ContactStatusEnum.CANCELED && canUpdateAccountContacts,
        action: () => handleActivateContact(rowData),
      },
      {
        title: 'Deactivate',
        color: 'danger',
        enabled:
          !primary &&
          status === ContactStatusEnum.ACTIVE &&
          canUpdateAccountContacts,
        action: () => handleDeactivateContact(rowData),
      },
    ];
    return <MDataTableActions actions={actions} />;
  };

  const columns: ColumnProps[] = [
    {
      className: 'table-cell-md',
      field: 'customId',
      header: 'ID',
      body: idBodyTemplate<IContactRespSchema>('id'),
    },
    {
      className: 'table-cell-lg',
      field: 'fullName',
      header: 'Name',
      sortable: true,
      body: fullNameBodyTemplate,
    },
    {
      field: 'email',
      header: 'Email',
      body: textBodyTemplate<IContactRespSchema>('email'),
      className: 'table-cell-lg',
    },
    {
      field: 'address.city',
      header: 'Address',
      body: addressBodyTemplate,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      body: statusBodyTemplate<IContactRespSchema, ContactStatusEnum>(
        'status',
        CONTACT_STATUS_DISPLAY,
      ),
    },
    {
      field: 'actions',
      header: '',
      style: { width: '4em' },
      body: actionsBodyTemplate,
    },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: listData?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MPageContentContainer className="datatable-responsive">
      <MPageHeader title="Contacts" hideContent={isTrulyEmptyList}>
        <MFlex>
          <MHStack spacing="2">
            <MPageSearchInput
              value={searchTerm || ''}
              onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
              count={listData?.totalElements}
              resetSearch={searchComponentReset}
              placeholderKey="Name"
            />
            <ExportTableButton
              entity="contacts"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
              endpointParams={[accountId]}
              getFilename={() => `contacts-${accountId}.csv`}
            />
            <ACLCheck acls={[['account_contacts', 'create']]}>
              <MButton
                variant="secondary"
                as={Link}
                to={getAccountContactCreate(accountId)}
              >
                New Contact
              </MButton>
            </ACLCheck>
          </MHStack>
        </MFlex>
      </MPageHeader>

      <MDataTable
        value={listData?.content}
        totalRecords={listData?.totalElements}
        pager={pager}
        setPager={setPager}
        rowHover
        onSelectionChange={(e) => onSelectRow(e.value as IContactRespSchema)}
        className="p-datatable-responsive"
        emptyProps={{
          mainMessage: 'Looks like there are no contacts here.',
          btnLabel: canDo([['account_contacts', 'create']])
            ? 'New Contact'
            : '',
          to: getAccountContactCreate(accountId),
        }}
        filtersApplied={getFiltersApplied(filters!) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        loading={loading}
        columns={columns}
      />
    </MPageContentContainer>
  );
};

export default ContactList;
