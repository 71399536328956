import { zodResolver } from '@hookform/resolvers/zod';
import isNil from 'lodash/isNil';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../api/axios';
import { quoteOfferingInitial } from '../../../../../constants/quotes';
import { QuoteDataTypes } from '../../../../../hooks/useQuote';
import { logger } from '../../../../../services/logger';
import {
  BulkQuoteOfferingReqSchema,
  IBulkQuoteOfferingReqSchema,
  IOfferingProductSchema,
  IOfferingRes,
  IProductResSchema,
  IQuoteItemReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteRespSchema,
  IRateResBaseSchema,
  OfferingTypesEnum,
} from '../../../../../types';
import { BillingFrequency } from './BillingFrequency';
import { BulkRates } from './BulkRates';
import { BulkOfferingSelection } from './OfferingSelection';
import { StepperComponent } from './StepperComponent';

export type BulkOfferingItemQuantityHandlerProps = {
  rateId: string;
  items: IQuoteItemReqSchema[];
};

export const BulkOfferingFlow = ({
  items,
  disabled,
  isBulkOfferingLoading,
  quote,
  quoteData,
  displayImportOffering,
  setIsBulkOfferingLoading,
  onSubmit,
  handleInitialQuoteItemQuantityForBulkOffering,
  fetchQuote,
}: {
  items: IOfferingRes[];
  disabled?: boolean;
  isBulkOfferingLoading: boolean;
  quote: IQuoteRespSchema;
  quoteData: QuoteDataTypes;
  displayImportOffering: boolean;
  setIsBulkOfferingLoading: (loadingState: boolean) => void;
  onSubmit: ({
    data,
    doUpdateParent,
  }: {
    data: IQuoteOfferingReqSchema;
    doUpdateParent: boolean;
  }) => Promise<void>;
  handleInitialQuoteItemQuantityForBulkOffering: ({
    rateId,
    items,
  }: BulkOfferingItemQuantityHandlerProps) => Promise<
    {
      productId: string;
      quantity: number;
    }[]
  >;
  fetchQuote: (
    quoteId: string,
    runEvaluate?: boolean,
    modifyQuoteResp?: (quote: IQuoteRespSchema) => IQuoteRespSchema,
  ) => Promise<IQuoteRespSchema | null>;
}) => {
  const bulkOfferingCreationState = useForm<IBulkQuoteOfferingReqSchema>({
    resolver: zodResolver(BulkQuoteOfferingReqSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      offerings: [],
      billingFrequency: '',
      customBillingFrequency: null,
      bulkRate: [],
    },
  });

  const {
    control,
    trigger,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = bulkOfferingCreationState;

  const watchOfferings = watch('offerings');
  const watchBillingFrequency = watch('billingFrequency');
  const watchCustomBillingFreq = watch('customBillingFrequency');
  const bulkRateWatch = watch('bulkRate');

  useEffect(() => {
    const isCustomFreqValid =
      watchCustomBillingFreq && watchCustomBillingFreq > 0;
    if (isCustomFreqValid && watchBillingFrequency) {
      clearErrors('billingFrequency');
    }
  }, [watchCustomBillingFreq, watchBillingFrequency]);

  const areAllItemsInOfferingOptional = (selectedOffering: IOfferingRes) => {
    const allItems = selectedOffering?.offeringProducts || [];
    return allItems.every(
      (item: { isMandatory: boolean }) => !item.isMandatory,
    );
  };

  /**
   * Submit quote bulk offering
   *
   */
  const onBulkOfferingSubmit = async ({
    data,
    resetStepper,
  }: {
    data: IBulkQuoteOfferingReqSchema;
    resetStepper?: () => void;
  }) => {
    setIsBulkOfferingLoading(true);

    const bulkOfferingData = data.bulkRate.map((bulkRate) => ({
      ...quoteOfferingInitial,
      offeringId: bulkRate.offeringId,
      rateId: bulkRate.rateId,
    }));

    try {
      for (const [index, offering] of bulkOfferingData.entries()) {
        const tempOffering = offering;
        if (tempOffering) {
          // Find the corresponding offering by offeringId
          const currentOffering = data.offerings.find(
            (offeringItem) => offeringItem.id === tempOffering.offeringId,
          );

          const areAllOptional = currentOffering
            ? areAllItemsInOfferingOptional(currentOffering)
            : false;

          // Map products to items with initial quantity
          if (areAllOptional) {
            offering.items = [
              {
                productId: currentOffering?.offeringProducts[0].product.id,
                quantity: 0,
              } as IQuoteItemReqSchema,
            ];
          } else {
            offering.items =
              currentOffering?.offeringProducts
                ?.filter(
                  (products: IOfferingProductSchema) => products.isMandatory,
                )
                .map(
                  ({ product }: { product: IProductResSchema }) =>
                    ({
                      productId: product.id,
                      quantity: 0,
                    }) as IQuoteItemReqSchema,
                ) || [];
          }

          // Process initial quote item quantity for bulk offerings
          tempOffering.items =
            (await handleInitialQuoteItemQuantityForBulkOffering({
              rateId: tempOffering.rateId,
              items: tempOffering.items,
            })) as IQuoteItemReqSchema[];
        }

        // Submit each bulk offering
        await onSubmit({
          data: tempOffering,
          doUpdateParent: false,
        });
      }
      quote?.id && (await fetchQuote(quote?.id));
      setIsBulkOfferingLoading(false);
    } catch (error) {
      handleApiErrorToast(error);
      logger.error('ERROR onBulkOfferingSubmit', error);
      setIsBulkOfferingLoading(false);
    } finally {
      resetStepper?.();
      reset();
    }
  };

  const handleBulkRates = (billingFreq: string) => {
    if (watchOfferings?.length) {
      const bulkRateValues = watchOfferings.map((offering: IOfferingRes) => {
        const matchingRate = offering.rates.find(
          (rate: IRateResBaseSchema) =>
            String(rate.billingFrequencyInMonths) === billingFreq,
        );

        if (offering.type === OfferingTypesEnum.ONETIME) {
          return {
            offeringId: offering.id,
            rateId: offering.rates[0].id,
          };
        }

        return {
          offeringId: offering.id,
          rateId: matchingRate?.id ?? '', // Safely handle the case where no rate is found
        };
      });

      setValue('bulkRate', bulkRateValues);
    }
  };

  const handleBillingFrequencyNext = (moveNext: () => void) => {
    const customBillingFreq = getValues('customBillingFrequency');
    let billingFreq = getValues('billingFrequency');

    const isBillingFrequencyEmpty = billingFreq === '';

    if (isBillingFrequencyEmpty) {
      setError('billingFrequency', {
        type: 'custom',
        message: 'Billing frequency is required',
      });
      return;
    }

    // Move to next step if billingFreq is greater than 1
    if (Number(billingFreq) >= 1) {
      clearErrors('billingFrequency');
      handleBulkRates(billingFreq);
      moveNext();
      return;
    }

    const isCustomBillingFreqMissing =
      isNil(customBillingFreq) && Number(billingFreq) === 0;
    const isCustomBillingFreqInvalid =
      customBillingFreq !== null &&
      customBillingFreq !== undefined &&
      customBillingFreq < 1;

    if (isCustomBillingFreqMissing) {
      setError('billingFrequency', {
        type: 'custom',
        message: 'Custom value is required',
      });
    } else if (isCustomBillingFreqInvalid) {
      setError('billingFrequency', {
        type: 'custom',
        message: 'Custom value cannot be less than 1',
      });
    } else {
      // Move to next step if customBillingFreq is valid
      handleBulkRates(billingFreq);
      moveNext();
    }
  };

  const handleRatesNextAndSubmit = (
    _: () => void,
    resetStepper?: () => void,
  ) => {
    trigger('bulkRate').then((res) => {
      if (res) {
        handleSubmit((data) => onBulkOfferingSubmit({ data, resetStepper }))();
      }
    });
  };

  const isOfferingCreateable = useMemo(() => {
    const data = getValues();

    const offeringIdsValid =
      Array.isArray(data.offerings) && data.offerings.length > 0;

    const bulkRatesValid =
      Array.isArray(data.bulkRate) &&
      data.bulkRate.every((rate) => rate.rateId && rate.rateId.trim() !== '');

    return !(offeringIdsValid && bulkRatesValid); // reverse this as we are controlling the disable state
  }, [JSON.stringify(bulkRateWatch)]);

  const checkIfAllOfferingIsOnetime = (listOfOfferings: IOfferingRes[]) => {
    return listOfOfferings.every(
      (offering: IOfferingRes) => offering.type === OfferingTypesEnum.ONETIME,
    );
  };

  const handleSkipBillingFrequency = () => {
    setValue('billingFrequency', '');
    clearErrors('billingFrequency');
    handleBulkRates('');
  };

  const handleOnetimeOfferingNext = (moveNext: (value?: number) => void) => {
    handleSkipBillingFrequency();
    // to move to the last index which for now is 2
    moveNext(1);
  };

  const handleBulkRatesBack = (movePrev: (value?: number) => void) => {
    if (checkIfAllOfferingIsOnetime(watchOfferings)) {
      // to move back to the first index
      movePrev(-2);
      return;
    }

    movePrev();
  };

  return (
    <StepperComponent
      content={[
        {
          label: 'Select Offerings',
          item: (
            <BulkOfferingSelection
              displayImportOffering={displayImportOffering}
              quote={quote}
              onImported={quoteData.setQuote}
              disabled={disabled}
              control={control}
              errors={errors}
              items={items}
            />
          ),
          footer: {
            next: {
              label: 'Next',
              onClick: (moveNext: () => void) => {
                trigger('offerings').then((res) => {
                  if (res) {
                    if (checkIfAllOfferingIsOnetime(watchOfferings)) {
                      handleOnetimeOfferingNext(moveNext);
                    }
                    moveNext();
                  }
                });
              },
              disable: watchOfferings.length < 1,
            },
          },
        },
        {
          label: 'Select Billing Frequency for all Offerings:',
          item: (
            <BillingFrequency
              disabled={disabled}
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
            />
          ),
          footer: {
            prev: {
              label: 'Back',
              onClick: (movePrev: () => void) => {
                movePrev();
              },
              disable: false,
            },
            next: {
              label: 'Next',
              onClick: handleBillingFrequencyNext,
              disable: false,
            },
            skip: {
              label: 'Skip',
              onClick: (moveNext: () => void) => {
                handleSkipBillingFrequency();
                moveNext();
              },
            },
          },
        },
        {
          label: 'Select Pricing for Offerings:',
          item: (
            <BulkRates
              setValue={setValue}
              watch={watch}
              control={control}
              disabled={disabled || isBulkOfferingLoading}
            />
          ),
          footer: {
            prev: {
              label: 'Back',
              onClick: handleBulkRatesBack,
              disable: isBulkOfferingLoading,
            },
            next: {
              label: 'Create',
              loading: isBulkOfferingLoading,
              onClick: handleRatesNextAndSubmit,
              disable: isOfferingCreateable,
            },
          },
        },
      ]}
      defaultIndex={0}
    />
  );
};
