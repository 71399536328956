import { BoxProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FunctionComponent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  CUSTOM_FIELD_DATA_FORM_SPACING,
  CUSTOM_FORM_CONTENT_DIVIDER_VIEW_MODES,
} from '../../constants/customFields';
import { useCustomFields } from '../../hooks/useCustomFields';
import { useFlags } from '../../services/launchDarkly';
import {
  CustomFieldEntityEnum,
  CustomFieldRecordSchema,
  CustomFieldTypeEnum,
  ICustomFieldMode,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
} from '../../types';
import {
  convertFormValuesToReq,
  sortCustomFields,
} from '../../utils/customFields';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionItem,
  MAccordionPanel,
  MBox,
  MButton,
  MDivider,
  MFlex,
  MGrid,
  MGridItem,
  MSimpleGrid,
  MSpinner,
  MText,
} from '../Monetize';
import { CustomFormDataFormField } from './CustomFormDataFormField';

// Ensure stable data to avoid re-renders
const DEFAULT_EMPTY_VALUES: [ICustomFieldResSchema[], ICustomFieldResSchema[]] =
  [[], []];

export interface CustomFieldDataFormProps extends BoxProps {
  value: ICustomFieldRecordSchema;
  setValue: (val: ICustomFieldRecordSchema, shouldPersist?: boolean) => void;
  entity: CustomFieldEntityEnum;
  fieldColSpan?: number;
  mode?: ICustomFieldMode;
  accordionAutoExpanded?: boolean;
  accordionBorderColor?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  variant?: string;
  showDivider?: boolean;
  isHorizontal?: boolean;
  saveOptions?: {
    handleOnSave: () => void;
    isLoading: boolean;
  };
}

const CustomFieldDataForm: FunctionComponent<CustomFieldDataFormProps> = ({
  value,
  setValue,
  entity,
  fieldColSpan = 3,
  mode = 'accordion',
  accordionAutoExpanded = false,
  accordionBorderColor,
  isDisabled,
  isReadOnly,
  variant = 'primary',
  showDivider = false,
  isHorizontal,
  saveOptions,
  ...rest
}: CustomFieldDataFormProps) => {
  const { customFieldList, zodSchemasByEntity, isLoading } =
    useCustomFields(entity);

  const zodSchema = zodSchemasByEntity[entity];

  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm<ICustomFieldRecordSchema>({
    resolver: zodResolver(
      zodSchemasByEntity[entity] || CustomFieldRecordSchema,
    ),
    mode: 'onChange',
    defaultValues: value,
    values: value,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });
  const formValues = getValues();
  const { customFields } = useFlags();

  const [nonCheckBoxCustomFields, checkBoxCustomFields] = useMemo(() => {
    if (!customFieldList) {
      return DEFAULT_EMPTY_VALUES;
    }
    return [
      customFieldList
        .filter((field) => field.type !== CustomFieldTypeEnum.CHECKBOX)
        .sort(sortCustomFields),
      customFieldList
        .filter((field) => field.type === CustomFieldTypeEnum.CHECKBOX)
        .sort(sortCustomFields),
    ] as const;
  }, [customFieldList]);

  const onSave = (field: string, value: any) => {
    const parsedValue = { ...formValues, [field]: value };
    setValue(
      convertFormValuesToReq(zodSchema.parse(parsedValue) || parsedValue),
    );
  };

  const spacingInfo = CUSTOM_FIELD_DATA_FORM_SPACING[mode];

  if (isLoading) {
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }

  if (customFieldList?.length === 0 || !customFields) {
    return null;
  }

  const formContent = (
    <>
      {mode === 'modal' && (
        <>
          <MDivider my="4" />
          <MText fontSize={16} fontWeight={600} mb={4}>
            Additional Fields
          </MText>
        </>
      )}
      {CUSTOM_FORM_CONTENT_DIVIDER_VIEW_MODES.has(mode) &&
        checkBoxCustomFields.length > 0 && <MDivider my="4" />}
      {nonCheckBoxCustomFields.length > 0 && (
        <MGrid
          templateColumns="repeat(12, 1fr)"
          gap={spacingInfo.gridGap}
          mt={spacingInfo.gridTop}
          mb={spacingInfo.gridBottom}
        >
          {nonCheckBoxCustomFields?.map(
            (customField: ICustomFieldResSchema, index) => {
              return (
                <MGridItem colSpan={fieldColSpan} key={index}>
                  <CustomFormDataFormField
                    customField={customField}
                    control={control}
                    onSave={onSave}
                    isDisabled={isDisabled}
                    isReadOnly={isReadOnly}
                    variant={variant}
                    isHorizontal={isHorizontal}
                  />
                </MGridItem>
              );
            },
          )}
        </MGrid>
      )}
      {mode === 'accordion' && <MBox my="6" />}

      <MGrid
        templateColumns="repeat(12, 1fr)"
        gap={spacingInfo.gridGap}
        mb={spacingInfo.gridBottom}
      >
        {checkBoxCustomFields?.map(
          (customField: ICustomFieldResSchema, index) => {
            return (
              <MGridItem colSpan={fieldColSpan} key={index}>
                <CustomFormDataFormField
                  customField={customField}
                  control={control}
                  onSave={onSave}
                  isDisabled={isDisabled || isReadOnly}
                  isReadOnly={isReadOnly}
                  variant={variant}
                  isHorizontal={isHorizontal}
                />
              </MGridItem>
            );
          },
        )}
      </MGrid>

      {!!saveOptions && (
        <MFlex justifyContent="flex-end">
          <MButton
            isLoading={saveOptions.isLoading}
            onClick={saveOptions.handleOnSave}
            isDisabled={!isValid}
            type="submit"
            minW="auto"
          >
            Save
          </MButton>
        </MFlex>
      )}
    </>
  );

  return (
    <MBox w="100%" {...rest}>
      {showDivider && (
        <MDivider mt={spacingInfo.dividerTop} mb={spacingInfo.dividerBottom} />
      )}
      {mode === 'accordion' && (
        <MAccordion
          allowToggle
          defaultIndex={accordionAutoExpanded ? 0 : undefined}
        >
          <MAccordionItem borderColor={accordionBorderColor}>
            {({ isExpanded }) => (
              <>
                <MAccordionCustomButton
                  isExpanded={isExpanded}
                  label="Additional Fields"
                ></MAccordionCustomButton>

                <MAccordionPanel>{formContent}</MAccordionPanel>
              </>
            )}
          </MAccordionItem>
        </MAccordion>
      )}
      {(mode === 'drawer' || mode === 'modal') && formContent}
      {mode === 'page' && (
        <MSimpleGrid columns={4} gap={4} mb={6} alignItems="flex-end">
          {customFieldList?.map((customField: ICustomFieldResSchema) => {
            return (
              <CustomFormDataFormField
                key={`${customField.entity}-${customField.key}`}
                customField={customField}
                control={control}
                onSave={onSave}
                isDisabled={isDisabled}
                isReadOnly={isReadOnly}
                variant={variant}
                isHorizontal={isHorizontal}
              />
            );
          })}
        </MSimpleGrid>
      )}
    </MBox>
  );
};

export { CustomFieldDataForm };
