import { BoxProps } from '@chakra-ui/react';
import { MdDoNotDisturbAlt } from 'react-icons/md';
import { MBox, MHeading, MIcon, MText } from '../components/Monetize';

interface AccessDeniedSmallProps {
  containerProps?: BoxProps;
}
export const AccessDeniedSmall = ({
  containerProps,
}: AccessDeniedSmallProps) => {
  return (
    <MBox {...containerProps}>
      <MHeading fontSize="xl" mb="4" color="tRed.base">
        Access Denied <MIcon as={MdDoNotDisturbAlt} />
      </MHeading>
      <MText size="lg">
        <p>Please contact the system administrator</p>
      </MText>
    </MBox>
  );
};
