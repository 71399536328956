import { toDateShort } from '@monetize/utils/core';
import { ColumnProps } from 'primereact/column';
import { MDataTable, MText } from '../../../../components/Monetize';
import { MCurrency } from '../../../../components/Monetize/MCurrency';
import { IBillingSchedulePeriodRespSchema } from '../../../../types';

interface InvoiceItemsTableProps {
  period: IBillingSchedulePeriodRespSchema;
}

export const InvoiceItemsTable = ({
  period: { items },
}: InvoiceItemsTableProps) => {
  const sortedItems = items.sort((a, b) => a.lineNumber - b.lineNumber);

  const columns: ColumnProps[] = [
    {
      field: 'servicePeriod',
      header: 'Service Period',
      body: ({ servicePeriod: { start, end } }) => (
        <MText>
          {toDateShort(start)} - {toDateShort(end)}
        </MText>
      ),
      style: { width: '15rem' },
    },
    {
      field: 'offeringName',
      header: 'Offering Name',
      style: { width: '13rem' },
    },
    {
      field: 'productName',
      header: 'Product Name',
      style: { width: '10rem' },
    },
    {
      field: 'quantity',
      header: 'Quantity',
      style: { width: '8rem' },
      body: ({ quantity }) => quantity,
    },
    {
      field: 'pricePerUnit',
      header: 'PPU',
      style: { width: '12rem' },
      body: ({ pricePerUnit, currency }) => (
        <MCurrency options={{ currency }} amount={pricePerUnit} />
      ),
    },
    {
      field: 'amount',
      header: 'Amount',
      body: ({ amount, currency }) => (
        <MCurrency options={{ currency }} amount={amount} />
      ),
      style: { width: '10rem', textAlign: 'left' },
    },
  ];

  return (
    <MDataTable
      style={{ paddingLeft: '3.5rem' }}
      tableStyle={{ tableLayout: 'fixed' }}
      value={sortedItems || []}
      dataKey="uid"
      columns={columns}
    />
  );
};
