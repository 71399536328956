import { ColumnProps } from 'primereact/column';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  useGetListData,
  usePerformEntityAction,
} from '../../../api/queryUtils';
import {
  MDataTable,
  MFlex,
  MHStack,
  MPageContainer,
  MPageSearchInput,
  MSwitch,
  MText,
  shouldOpenInNewWindow,
} from '../../../components/Monetize';
import {
  AccountIdTableFilterOptionContent,
  AmountTableFilterOptionContent,
  MDataTableActions,
  MDataTableFilter,
} from '../../../components/Monetize/DataTable';
import { CurrencyTableFilterOptionContent } from '../../../components/Monetize/DataTable/FilterOptions/CurrencyTableFilterOptionContent';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { DISCOUNTS, ROUTES } from '../../../constants';
import { AMOUNT_UNIT_DISPLAY_TEXT } from '../../../constants/amountUnits';
import { DISCOUNT_STATUS_ENUM_DISPLAY } from '../../../constants/discounts';
import { useSettingsReadOnlyRule } from '../../../hooks/useSettingsReadOnlyRule';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '../../../store/global.store';
import { FilterStateKeys } from '../../../store/store.types';
import {
  AmountUnitTypeEnum,
  DiscountStatusEnum,
  FilterType,
  FilterTypeEqual,
  FilterTypeOperator,
  GetListApiFilter,
  IDiscount,
  RenderTableFilterOptionProps,
} from '../../../types';
import {
  buildFilterParamsRequestObject,
  getDiscountText,
  getFiltersApplied,
  getIsTrulyEmptyList,
} from '../../../utils';
import { objectToObjArray } from '../../../utils/misc';
import {
  idBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '../../../utils/tableUtils';
import PageHeader from '../components/PageHeader';
import TableHeader from '../components/TableHeader';

const renderAmountOptionContent = (props: RenderTableFilterOptionProps) => {
  return <AmountTableFilterOptionContent {...props} />;
};
const renderAccountIdToggleOptionContent = ({
  filter,
  filterOption,
  handleFilterChange,
}: RenderTableFilterOptionProps) => {
  const internalFilter = filter as FilterTypeEqual;

  return (
    <MFlex alignItems="center" my="2" justifyContent="space-between">
      <MText mr="4">Show Custom Discounts</MText>
      <MSwitch
        size="md"
        isChecked={internalFilter?.value === filterOption.options?.falseValue}
        onChange={(val) => {
          handleFilterChange(
            val.target.checked
              ? filterOption.options?.falseValue!
              : filterOption.options?.trueValue!,
            filterOption,
          );
        }}
      />
    </MFlex>
  );
};

const DiscountListPage: FC = () => {
  const navigate = useNavigate();
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.DISCOUNT_LIST),
  );

  const [pager, setPager] = useRecoilState(
    tablePagerSelector(FilterStateKeys.DISCOUNT_LIST),
  );
  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchKey] = useState<string>('name');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const {
    data: discountList,
    isLoading,
    isFetched,
    isRefetching,
    refetch,
    isError,
    error,
  } = useGetListData<IDiscount>('productCatalogDiscounts', {
    config: pager,
    filters: filterParams,
  });

  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IDiscount>('productCatalogDiscounts', {
      meta: { showErrorToast: true },
    });

  const loading = isLoading || (isRefetching && !isFetched);
  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: discountList?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  const onResetFilter = () => {
    setFilters([]);
  };

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, searchTerm, searchKey),
    );
  }, [pager, searchTerm, filters, searchKey]);

  const discountAmountBodyTemplate = (rowData: IDiscount) => {
    const { discountAmount, discountType } = rowData;
    return (
      <MText noOfLines={1}>
        {getDiscountText(discountAmount, discountType)}
      </MText>
    );
  };
  const { canCreateSettings } = useSettingsReadOnlyRule();
  const actionBodyTemplate = (rowData: IDiscount) => {
    if (!canCreateSettings) {
      return null;
    }
    const { id, name, status } = rowData;
    const confirmProps = {
      title: `Are you sure you want to deactivate "${name}"?`,
      description:
        'Once deactivated, the code associated with this discount will no longer be redeemable. Customers who have already redeemed this discount will not be affected.',
    };
    const actions = [
      {
        title: 'Edit',
        enabled: true,
        action: () => onSelectEdit(id),
      },
      {
        title: 'Activate',
        enabled: status === DiscountStatusEnum.INACTIVE,
        action: () => doActivateDeactivate({ id, action: 'activate' }),
      },
      {
        title: 'Deactivate',
        color: 'danger',
        confirmProps: {
          ...confirmProps,
          yesButton: 'Deactivate',
        },
        enabled: status === DiscountStatusEnum.ACTIVE,
        action: () => doActivateDeactivate({ id, action: 'deactivate' }),
      },
    ];

    return <MDataTableActions actions={actions} />;
  };

  const onSelectEdit = (id: string, openInNewWindow = false) => {
    openInNewWindow
      ? window.open(ROUTES.getDiscountEditRoute(id), '_blank')
      : navigate(ROUTES.getDiscountEditRoute(id));
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden table-cell-md',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IDiscount>('id'),
    },
    {
      field: 'name',
      header: 'Name',
      body: textBodyTemplate<IDiscount>('name'),
      sortable: true,
    },
    {
      field: 'discountCode',
      header: 'Code',
      sortable: true,
      body: textBodyTemplate<IDiscount>('discountCode'),
    },
    {
      field: 'discountType',
      header: 'Type',
      body: statusBodyTemplate<IDiscount, AmountUnitTypeEnum>(
        'discountType',
        AMOUNT_UNIT_DISPLAY_TEXT,
      ),
      sortable: true,
    },

    {
      field: 'status',
      header: 'Status',
      body: statusBodyTemplate<IDiscount, DiscountStatusEnum>(
        'status',
        DISCOUNT_STATUS_ENUM_DISPLAY,
      ),
      sortable: true,
    },
    {
      field: 'currency',
      header: 'Currency',
      sortable: true,
      body: textBodyTemplate<IDiscount>('currency'),
    },

    {
      field: 'discountAmount',
      header: 'Amount',
      body: discountAmountBodyTemplate,
      style: { textAlign: 'right' },
      sortable: true,
    },

    {
      body: actionBodyTemplate,
    },
  ];

  const statusItems = objectToObjArray(
    DISCOUNTS.DISCOUNT_STATUS_ENUM_DISPLAY,
    'value',
    'label',
  );

  const filterOptions = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
    {
      title: '',
      key: 'accountId',
      operator: FilterTypeOperator.TOGGLE,
      renderOptionContent: renderAccountIdToggleOptionContent,
      options: {
        falseValue: 'eq:null',
        trueValue: '',
      },
    },
    {
      title: 'Account Name',
      key: 'accountId',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (optionsProps: RenderTableFilterOptionProps) => (
        <AccountIdTableFilterOptionContent {...optionsProps} />
      ),
    },
    {
      title: 'Currency',
      key: 'currency',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (optionsProps: RenderTableFilterOptionProps) => (
        <CurrencyTableFilterOptionContent {...optionsProps} />
      ),
    },
  ];

  const hasFiltersOrSearchApplied =
    getFiltersApplied(filters) > 0 || !!searchTerm;

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);

  return (
    <MPageContainer>
      <PageHeader />
      <TableHeader
        model="Discount"
        showCreateNewBtn={!isTrulyEmptyList && canCreateSettings}
      >
        {!isTrulyEmptyList && (
          <MHStack spacing="2">
            <MPageSearchInput
              placeholderKey="Name"
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e)}
              count={discountList?.totalElements}
              resetSearch={searchComponentReset}
            />
            <MDataTableFilter
              filters={filters}
              filterOptions={filterOptions}
              setFilters={(f) => setFilters && setFilters(f)}
              onResetFilter={onResetFilter}
              resetFilter={filterComponentReset}
            />
            <ExportTableButton
              entity="discounts"
              filters={filters}
              searchKey={searchKey}
              searchTerm={searchTerm}
              sortField={pager.sortField}
              sortOrder={pager.sortOrder}
            />
          </MHStack>
        )}
      </TableHeader>
      <MDataTable
        value={discountList?.content}
        totalRecords={discountList?.totalElements}
        totalPages={discountList?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        loading={loading}
        columns={columns}
        filtersApplied={hasFiltersOrSearchApplied}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        onRowClick={(e) => onSelectEdit(e.data?.id, shouldOpenInNewWindow(e))}
        singleEmptyPlaceholder
        emptyFilterResultsProps={{
          mainMessage: 'Looks like there are no discounts here.',
          smallMessage: null,
          btnLabel: 'New Discount',
          to: ROUTES.DISCOUNT_NEW,
          secondaryBtnLabel: hasFiltersOrSearchApplied
            ? 'Clear filters'
            : undefined,
        }}
        errorProps={{
          isFetchingError: isError,
          error: error,
          onRefetchData: refetch,
        }}
      />
    </MPageContainer>
  );
};

export default DiscountListPage;
