import { toDateShort } from '@monetize/utils/core';
import { useMemo } from 'react';
import { MAlert } from '../../components/Monetize';
import { AlertType } from '../../components/Monetize/MAlert';
import { Maybe, QuoteShareResponse, QuoteStatusEnum } from '../../types';

const ELIGIBLE_STATUSES = new Set([
  QuoteStatusEnum.SENT,
  QuoteStatusEnum.ACCEPTED,
  QuoteStatusEnum.PROCESSED,
]);

function getBannerText(
  status: QuoteStatusEnum,
  {
    sentAt,
    acceptedAt,
  }: { sentAt?: Maybe<string>; acceptedAt?: Maybe<string> },
) {
  return {
    [QuoteStatusEnum.SENT]: `Your quote is awaiting eSign. Check your email to finish the acceptance process.`,
    [QuoteStatusEnum.ACCEPTED]: acceptedAt
      ? `Your quote was accepted on ${toDateShort(acceptedAt)} and is awaiting final processing.`
      : `Your quote was accepted and is awaiting final processing.`,
    [QuoteStatusEnum.PROCESSED]: `Your quote has been successfully accepted and processed.`,
  }[status];
}

function getBannerType(status: QuoteStatusEnum): AlertType {
  switch (status) {
    case QuoteStatusEnum.SENT:
    case QuoteStatusEnum.ACCEPTED:
      return 'info';
    case QuoteStatusEnum.PROCESSED:
      return 'success';
    default:
      return 'info';
  }
}

interface QuoteShareStatusBannerProps {
  quote: QuoteShareResponse['quote'];
}

export const QuoteShareStatusBanner = ({
  quote,
}: QuoteShareStatusBannerProps) => {
  const bannerText = useMemo(
    () =>
      getBannerText(quote.status, {
        acceptedAt: quote.acceptedAt,
        sentAt: quote.sentDate,
      }),
    [quote.status, quote.acceptedAt, quote.sentDate],
  );

  if (!ELIGIBLE_STATUSES.has(quote.status)) {
    return null;
  }

  return (
    <MAlert
      alertIconProps={{ boxSize: 4 }}
      alertDescriptionProps={{ fontSize: 'sm' }}
      alignSelf="stretch"
      type={getBannerType(quote.status)}
      my={4}
    >
      {bannerText}
    </MAlert>
  );
};
