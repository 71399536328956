import { MSkeleton, MVStack } from '../../../../components/Monetize';

interface SubscriptionFormLoadingProps {
  pricingData?: any;
}

export const SubscriptionFormLoading = ({
  pricingData,
}: SubscriptionFormLoadingProps) => {
  return (
    <MVStack w="full" spacing={6} align="flex-end">
      <MSkeleton height="8" w="full" />
      <MSkeleton height="8" w="full" />
      <MSkeleton height="8" w="full" />
      <MSkeleton height="8" w="full" />
      <MSkeleton height="8" w="full" />
    </MVStack>
  );
};
