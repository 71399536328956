import { z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { MCustomNumberTypeNullish } from './miscTypes';

export enum PriceUpliftConfigTypeEnum {
  APPLY_TO_ALL_PRODUCTS = 'APPLY_TO_ALL_PRODUCTS',
  PRODUCT_SPECIFIC = 'PRODUCT_SPECIFIC',
}

export const PriceUpliftConfigTypeEnumZ = z.nativeEnum(
  PriceUpliftConfigTypeEnum,
);

export enum PriceUpliftPercentageTypeEnum {
  MAX = 'MAX',
  FIXED = 'FIXED',
}

export const PriceUpliftPercentageTypeEnumZ = z.nativeEnum(
  PriceUpliftPercentageTypeEnum,
);

export const PriceUpliftProductConfigurationSchema = z.object({
  productId: z.string(),
  amount: MCustomNumberTypeNullish,
  percentageType: PriceUpliftPercentageTypeEnumZ,
});

export type PriceUpliftProductConfigurationType = z.infer<
  typeof PriceUpliftProductConfigurationSchema
>;

export const PriceUpliftConfigResSchema = z.object({
  id: z.string(),
  type: PriceUpliftConfigTypeEnumZ,
  percentageType: PriceUpliftPercentageTypeEnumZ,
  locked: z.boolean(),
  amount: MCustomNumberTypeNullish,
  productConfigurations: z
    .array(PriceUpliftProductConfigurationSchema)
    .nullish(),
});

export type PriceUpliftConfigResType = z.infer<
  typeof PriceUpliftConfigResSchema
>;

export const PriceUpliftConfigFormSchema = z
  .object({
    type: PriceUpliftConfigTypeEnumZ,
    percentageType: PriceUpliftPercentageTypeEnumZ,
    amount: MCustomNumberTypeNullish,
    productConfigurations: z
      .array(
        z.object({
          amount: MCustomNumberTypeNullish.nullish(),
          productId: z.string().nullish(),
          percentageType: PriceUpliftPercentageTypeEnumZ.nullish(),
        }),
      )
      .nullish(),
  })
  .refine(
    ({ amount, type }) => {
      if (
        type === PriceUpliftConfigTypeEnum.APPLY_TO_ALL_PRODUCTS &&
        (amount === null || isNaN(amount))
      ) {
        return false;
      }

      return true;
    },
    {
      message: getRequiredMessage('Percentage'),
      path: ['amount'],
    },
  );

export type PriceUpliftConfigFormType = z.infer<
  typeof PriceUpliftConfigFormSchema
>;
