import { useDisclosure } from '@chakra-ui/react';
import { BsPen } from 'react-icons/bs';
import { MCustomIconButton, MText } from '../../../../../components/Monetize';
import MPopoverWrapper from '../../../../../components/Monetize/MPopoverWrapper';
import { IQuoteRespSchema, QuoteStatusEnum } from '../../../../../types';

interface QuoteEsignPopoverProps {
  quote: IQuoteRespSchema;
}

const QuoteEsignPopover = ({ quote }: QuoteEsignPopoverProps) => {
  let esignText = '';
  // Determines esign text based on quote status, requiresEsign and quote sent date
  if (quote.requiresEsign) {
    switch (quote.status) {
      case QuoteStatusEnum.DRAFT:
      case QuoteStatusEnum.REVIEW:
      case QuoteStatusEnum.APPROVED:
      case QuoteStatusEnum.DENIED:
      case QuoteStatusEnum.CANCELED:
        esignText = 'This Quote will be sent through eSign';
        break;
      case QuoteStatusEnum.SENT:
      case QuoteStatusEnum.ACCEPTED:
      case QuoteStatusEnum.PROCESSED:
        esignText = 'This Quote was sent through eSign';
        break;
      case QuoteStatusEnum.EXPIRED: {
        if (!quote.sentDate) {
          esignText = 'This Quote will be sent through eSign';
        } else {
          esignText = 'This Quote was sent through eSign';
        }
        break;
      }
      default:
        break;
    }
  }
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <MPopoverWrapper
      isOpen={isOpen}
      placement="bottom-start"
      showArrow
      onOpen={onOpen}
      onClose={onClose}
      triggerContent={
        <MCustomIconButton
          p={4}
          minH="8"
          variant="icon"
          aria-label="Esign"
          btnSize={5}
          icon={BsPen}
        />
      }
      bodyContent={
        <>
          <MText color="tPurple.dark" fontWeight="bold">
            {esignText}
          </MText>
          {quote.contacts?.esign?.length > 0 && (
            <>
              <MText>{quote.contacts.esign[0].fullName}</MText>
              <MText>{quote.contacts.esign[0].email}</MText>
            </>
          )}
        </>
      }
    />
  );
};

export default QuoteEsignPopover;
