import { CardBody } from '@chakra-ui/react';
import { FunctionComponent as FC, useEffect, useRef, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { AccountAddressSelect } from '../../../../../components/Account/AccountAddressSelect';
import {
  MBox,
  MButton,
  MCard,
  MFlex,
  MText,
} from '../../../../../components/Monetize';
import { useACL } from '../../../../../services/acl/acl';
import { IQuoteContactAddressDataSchema } from '../../../../../types';
import { LegalEntityAddressFormatEnum } from '../../../../../types/legalEntityTypes';

interface QuoteContactsAddressItemProps {
  label: string;
  subLabel: string;
  isDisabled?: boolean;
  accountId: string;
  addressDataForm: UseFormReturn<IQuoteContactAddressDataSchema>;
  addressFormat?: LegalEntityAddressFormatEnum | null;
  name: 'shippingAddressId' | 'billingAddressId';
}

const QuoteContactsAddressItem: FC<QuoteContactsAddressItemProps> = ({
  label,
  subLabel,
  isDisabled = false,
  accountId,
  addressDataForm,
  addressFormat = LegalEntityAddressFormatEnum.US_FORMAT,
  name,
}: QuoteContactsAddressItemProps) => {
  const { canDo } = useACL();
  const {
    formState: { errors },
    control,
    watch,
  } = addressDataForm;
  const [isEditMode, setIsEditMode] = useState(false);
  const addressId = watch(name);
  useEffect(() => {
    if (!addressId) {
      setIsEditMode(true);
    }
  }, [addressId]);
  const selectRef = useRef<any>(null);
  useEffect(() => {
    if (isEditMode && selectRef) {
      selectRef.current?.focus();
    }
  }, [isEditMode, selectRef]);

  return (
    <MCard variant="borderless" mb="2">
      <CardBody py="2">
        <MFlex direction="column" grow={1}>
          <MText fontSize="sm" fontWeight="600" mb="4">
            {label}
          </MText>

          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, ...rest } }) => {
              return (
                <AccountAddressSelect
                  isDisabled={isDisabled}
                  isReadOnly={isDisabled || !isEditMode}
                  addressFormat={addressFormat}
                  accountId={accountId}
                  onChange={(value) => {
                    onChange(value);
                    setIsEditMode(false);
                  }}
                  {...rest}
                  ref={selectRef}
                />
              );
            }}
          />
          {!isDisabled && !isEditMode && (
            <MBox>
              <MButton
                size="xs"
                ml={-2}
                p={2}
                variant="tertiary"
                onClick={() => setIsEditMode(true)}
              >
                Edit
              </MButton>
            </MBox>
          )}
        </MFlex>
      </CardBody>
    </MCard>
  );
};

export default QuoteContactsAddressItem;
