import { useEffect } from 'react';
import AppLoading from '../../components/App/AppLoading';
import { useAuth } from '../../services/auth0';

const EmptyLogin = () => {
  const { loginWithRedirect } = useAuth();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <AppLoading />;
};

export default EmptyLogin;
