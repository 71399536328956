import { FC } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {
  MBox,
  MCustomNumberInput,
  MCustomSelect,
  MFlex,
  MFormField,
  MInput,
  MText,
  MTextarea,
} from '../../../../components/Monetize';
import { CUSTOM_ID_PLACEHOLDER } from '../../../../constants/placeholders';
import { useFlags } from '../../../../services/launchDarkly';
import { IDunningCreateSchema } from '../../../../types';
import { SettingsToggle } from '../../Quoting/QuoteSettings/components/SettingsToggle';
import { CustomerCCSelector } from './components/CustomerCCSelector';

interface DunningStepProps {
  index: number;
  control: Control<IDunningCreateSchema>;
  errors: FieldErrors<IDunningCreateSchema>;
  setValue: UseFormSetValue<IDunningCreateSchema>;
  clearErrors: UseFormClearErrors<IDunningCreateSchema>;
}
const DunningStep: FC<DunningStepProps> = ({
  index,
  control,
  errors,
  setValue,
  clearErrors,
}: DunningStepProps) => {
  const { dunningEmailTemplates = [] } = useFlags();

  const stepsErrors = errors?.dunningSteps?.[index]
    ? errors?.dunningSteps[index]
    : {};

  const isSendEmail = useWatch({
    control,
    name: `dunningSteps.${index}.sendEmail`,
  });

  const daysSinceDueDate = useWatch({
    control,
    name: `dunningSteps.${index}.daysSinceDueDate`,
  });

  const isNegativeDaysSinceDueDate = daysSinceDueDate < 0;
  return (
    <MBox
      border="1px solid"
      borderColor="tGray.back"
      p={4}
      borderRadius={4}
      boxSizing="border-box"
    >
      <MFlex gap="4">
        <MFormField error={stepsErrors?.stepName} label="Name" isRequired>
          <Controller
            name={`dunningSteps.${index}.stepName`}
            control={control}
            render={({ field }) => (
              <MInput placeholder="Enter Name" {...field} />
            )}
          />
        </MFormField>

        <MFormField
          error={stepsErrors?.daysSinceDueDate}
          label="Day Since Due Date"
          isRequired
          tooltip="Based on the oldest invoice due date on the Bill Group. To send reminder email before due date, enter a negative number."
        >
          <Controller
            name={`dunningSteps.${index}.daysSinceDueDate`}
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MCustomNumberInput
                min={Number.MIN_SAFE_INTEGER}
                onChange={(valueAsString: string) => onChange(valueAsString)}
                {...rest}
              />
            )}
          />
        </MFormField>

        <MFormField
          error={stepsErrors?.customId}
          label="Custom ID"
          tooltip="A unique ID that you can map to your internal logic on how to handle these dunning steps."
        >
          <Controller
            name={`dunningSteps.${index}.customId`}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange, ...rest } }) => (
              <MInput
                placeholder={CUSTOM_ID_PLACEHOLDER}
                value={value || ''}
                onChange={(val) => {
                  onChange(val);
                  clearErrors();
                }}
                {...rest}
              />
            )}
          />
        </MFormField>
      </MFlex>

      <MBox my="6">
        <MFormField error={stepsErrors?.sendEmail} flex="1">
          <Controller
            name={`dunningSteps.${index}.sendEmail`}
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <SettingsToggle
                heading="Send Email"
                subheading="When turned on, MonetizeNow will send customers an email per a
                pre-configured template."
                onChange={(val) => {
                  onChange(val);
                  if (!val) {
                    [
                      'emailTemplateId',
                      'internalCCEmails',
                      'emailBillGroupCCEmails',
                      'emailBillGroupShippingContact',
                    ].forEach((id) => {
                      setValue(`dunningSteps.${index}.${id}` as any, null);
                    });
                  }

                  if (val) {
                    [
                      'emailBillGroupCCEmails',
                      'emailBillGroupShippingContact',
                    ].forEach((id) => {
                      setValue(`dunningSteps.${index}.${id}` as any, false);
                    });
                  }
                }}
                {...rest}
              />
            )}
          />
        </MFormField>

        {isSendEmail && (
          <MFlex mt="4" gap="4">
            <MFormField
              error={stepsErrors?.emailTemplateId}
              label="Email Template"
              isRequired
            >
              <Controller
                name={`dunningSteps.${index}.emailTemplateId`}
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    placeholder="Enter emails"
                    itemTitle="label"
                    items={dunningEmailTemplates}
                    {...field}
                  />
                )}
              />
            </MFormField>
            <MFormField label="From">
              <MFlex h={8} align="center">
                <MText>MonetizeNow (no-reply)</MText>
              </MFlex>
            </MFormField>
            <MFormField label="To" isRequired>
              <MFlex h={8} align="center">
                <MText>Bill Group Billing Contact</MText>
              </MFlex>
            </MFormField>
            <MFormField
              label="CC"
              error={
                stepsErrors?.emailBillGroupCCEmails ||
                stepsErrors?.emailBillGroupShippingContact
              }
            >
              <CustomerCCSelector
                control={control}
                index={index}
                setValue={setValue}
              />
            </MFormField>

            <MFormField
              error={stepsErrors?.internalCCEmails}
              label="Internal CC"
              tooltip="Enter any number of email addresses separated by commas"
            >
              <Controller
                name={`dunningSteps.${index}.internalCCEmails`}
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <MTextarea
                    rows={1}
                    placeholder="Enter emails"
                    value={value || ''}
                    {...rest}
                  />
                )}
              />
            </MFormField>
          </MFlex>
        )}
      </MBox>

      <MFormField
        opacity={isNegativeDaysSinceDueDate ? 0.6 : 1}
        error={stepsErrors?.retryCollection}
        flex="1"
      >
        <Controller
          name={`dunningSteps.${index}.retryCollection`}
          control={control}
          render={({ field }) => (
            <SettingsToggle
              heading="Attempt Collection"
              subheading="If the Bill Group has a payment method assigned, attempt collection for Invoices with outstanding amounts starting with the oldest due date."
              {...field}
            />
          )}
        />
      </MFormField>
    </MBox>
  );
};

export default DunningStep;
