import { MDivider } from '../../../../../components/Monetize';
import {
  IOfferingRes,
  IQuoteOfferingRespSchema,
  IRateResBaseSchema,
  IRateResSchema,
  OnQuoteOfferingChange,
  QuoteOfferingProps,
  QuoteOfferingState,
  ScheduleChangeState,
} from '../../../../../types';
import { useQuoteContext } from '../../quoteContext';
import { QuoteOfferingCreateScheduleChange } from './QuoteOfferingCreateScheduleChange';
import { QuoteOfferingScheduledChangeButton } from './QuoteOfferingScheduleChangeButton';

export interface QuoteOfferingScheduleChangeProps
  extends Pick<
    QuoteOfferingProps,
    | 'quoteOffering'
    | 'isChildOffering'
    | 'childQuoteOfferings'
    | 'parentQuoteOffering'
    | 'nextScheduledQuoteOffering'
    | 'priorScheduledQuoteOffering'
  > {
  quoteOfferingState: QuoteOfferingState;
  offering?: IOfferingRes | null;
  isOfferingOpen: boolean;
  scheduleChangeActive: boolean;
  isOpen: boolean;
  isLoadingOfferings: boolean;
  offeringRatesById: Record<string, IRateResBaseSchema>;
  handleScheduleChangeInitiated: () => void;
  handleAddScheduledChange: ({
    startDate,
    endDate,
    rateId,
  }: {
    startDate: string;
    endDate: string;
    rateId: string;
  }) => Promise<IQuoteOfferingRespSchema | null>;
  scheduleChangeLoading: boolean;
  setScheduleChangeActive: (val: boolean) => void;
  inactiveRate?: IRateResSchema;
  rateSelects: [IRateResBaseSchema[], IRateResBaseSchema[]];
  scheduleChangeState: ScheduleChangeState;
  isReadOnly: boolean;
  updatePrevScheduleChange: ({ endDate }: { endDate: string }) => void;
  onOfferingChange: OnQuoteOfferingChange;
}

/**
 * Manages the quote offering menu based on the state of the quote offering
 */
export const QuoteOfferingScheduleChange = ({
  quoteOffering,
  isChildOffering,
  childQuoteOfferings = [],
  parentQuoteOffering,
  nextScheduledQuoteOffering,
  priorScheduledQuoteOffering,
  quoteOfferingState,
  offering,
  isOfferingOpen,
  scheduleChangeActive,
  isOpen,
  isLoadingOfferings,
  offeringRatesById,
  handleScheduleChangeInitiated,
  handleAddScheduledChange,
  scheduleChangeLoading,
  setScheduleChangeActive,
  inactiveRate,
  rateSelects,
  scheduleChangeState,
  isReadOnly,
  updatePrevScheduleChange,
  onOfferingChange,
}: QuoteOfferingScheduleChangeProps) => {
  const { quoteData, useAmendmentV2 } = useQuoteContext();
  const { quote } = quoteData;
  const {
    isNewQuoteOfferingForm,
    isRemoved,
    isAmendment,
    isRenewal,
    gridColumns,
    isOfferingOnetime,
    isNoOfferingChanged,
    newlyAddedOffering,
  } = quoteOfferingState;

  const { availableMonthSelectOptions, monthInterval, scheduleChangeInitDate } =
    scheduleChangeState;

  const shouldAllowScheduleChange =
    !isReadOnly &&
    !scheduleChangeActive &&
    !isLoadingOfferings &&
    !isOfferingOnetime &&
    (!isAmendment || (isAmendment && useAmendmentV2)) &&
    !(isRenewal && isRemoved) &&
    isOfferingOpen &&
    isOpen &&
    !isNewQuoteOfferingForm &&
    availableMonthSelectOptions.length > 0 &&
    ((isChildOffering && !nextScheduledQuoteOffering) ||
      (!isChildOffering && childQuoteOfferings.length === 0)) &&
    !isReadOnly &&
    !(useAmendmentV2 && !newlyAddedOffering && isRemoved) &&
    !(useAmendmentV2 && !newlyAddedOffering && isNoOfferingChanged);

  const handleAddScheduledChangeAndFetch = async ({
    startDate,
    endDate,
    rateId,
  }: {
    startDate: string;
    endDate: string;
    rateId: string;
  }) => {
    const newQuoteOffering = await handleAddScheduledChange({
      startDate,
      endDate,
      rateId,
    });
    // fetch quote data
    newQuoteOffering && (await onOfferingChange('ADD', newQuoteOffering));
    return newQuoteOffering;
  };
  return (
    <>
      {shouldAllowScheduleChange && (
        <QuoteOfferingScheduledChangeButton
          isReadOnly={isReadOnly}
          handleScheduleChangeInitiated={handleScheduleChangeInitiated}
        />
      )}
      {/* Initial scheduled offering selection before this is an actual quote offering */}
      {quoteOffering && offering && scheduleChangeActive && (
        <>
          <QuoteOfferingCreateScheduleChange
            initialDate={scheduleChangeInitDate}
            quote={quote!}
            offeringRatesById={offeringRatesById}
            loading={scheduleChangeLoading}
            quoteOffering={quoteOffering}
            parentQuoteOffering={parentQuoteOffering}
            inactiveRate={inactiveRate}
            priorScheduledQuoteOffering={priorScheduledQuoteOffering}
            onSubmit={handleAddScheduledChangeAndFetch}
            onCancel={() => setScheduleChangeActive(false)}
            availableMonthSelectOptions={availableMonthSelectOptions}
            monthInterval={monthInterval}
            offering={offering}
            childQuoteOfferings={childQuoteOfferings}
            gridColumns={gridColumns}
            rateSelects={rateSelects}
            updatePrevScheduleChange={updatePrevScheduleChange}
          />

          <MDivider />
        </>
      )}
    </>
  );
};
