import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { MCustomMultiSelect } from '../../../../../components/Monetize';
import {
  CUSTOMER_CC_DISPLAY,
  CUSTOMER_CC_OPTIONS,
} from '../../../../../constants/dunnings';
import { CustomerCCEnum, IDunningCreateSchema } from '../../../../../types';

type CustomerCC = {
  title: string;
  value: string;
};

interface CustomerCCSelectorProps {
  index: number;
  control: Control<IDunningCreateSchema>;
  setValue: UseFormSetValue<IDunningCreateSchema>;
}

export const CustomerCCSelector = ({
  control,
  setValue,
  index,
}: CustomerCCSelectorProps) => {
  const {
    emailBillGroupCCEmails,
    emailBillGroupShippingContact,
    emailAccountCCEmails,
  } = useWatch({
    control,
    name: `dunningSteps.${index}`,
  });

  let selectedCustomerCC: CustomerCC[] = [];
  if (emailBillGroupCCEmails) {
    selectedCustomerCC = [
      ...selectedCustomerCC,
      {
        value: CustomerCCEnum.emailBillGroupCCEmails,
        title: CUSTOMER_CC_DISPLAY[CustomerCCEnum.emailBillGroupCCEmails],
      },
    ];
  }

  if (emailBillGroupShippingContact) {
    selectedCustomerCC = [
      ...selectedCustomerCC,
      {
        value: CustomerCCEnum.emailBillGroupShippingContact,
        title:
          CUSTOMER_CC_DISPLAY[CustomerCCEnum.emailBillGroupShippingContact],
      },
    ];
  }

  if (emailAccountCCEmails) {
    selectedCustomerCC = [
      ...selectedCustomerCC,
      {
        value: CustomerCCEnum.emailAccountCCEmails,
        title: CUSTOMER_CC_DISPLAY[CustomerCCEnum.emailAccountCCEmails],
      },
    ];
  }

  const onChange = (val: CustomerCC[]) => {
    const selectedIds = val.map(({ value }) => value);

    Object.values(CustomerCCEnum).map((value) =>
      setValue(
        `dunningSteps.${index}.${value}` as any,
        selectedIds.includes(value),
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      ),
    );
  };

  return (
    <MCustomMultiSelect
      itemTitle="title"
      itemValue="value"
      items={CUSTOMER_CC_OPTIONS}
      useExternalQuery
      multiple
      returnItem
      value={selectedCustomerCC}
      closeButtonText="Apply"
      placeholder="Select Recipients"
      onChange={onChange}
      mt="0"
    />
  );
};
