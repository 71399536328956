import { toDateOnly } from '@monetize/utils/core';
import { formatISO } from 'date-fns/formatISO';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '../../../api/axios';
import {
  doAmendContract,
  doRenewContract,
  useCancelContract,
  useUpdateContractById,
} from '../../../api/cpqService';
import DataTableActions from '../../../components/Monetize/DataTable/MDataTableActions';
import { getQuoteEditRoute } from '../../../constants/routes';
import { IContractWithBillGroup } from '../../../hooks/AccountDetail';
import { useACL } from '../../../services/acl/acl';
import { useAuth } from '../../../services/auth0';
import { useConfirmModal } from '../../../services/confirmModal';
import { useFlags } from '../../../services/launchDarkly';
import { logger } from '../../../services/logger';
import {
  ContractCancelTypeEnum,
  ContractEndActionEnum,
  ContractStatusEnum,
  ICancelContractUISchema,
  IContract,
  getContractUIRequestSchema,
} from '../../../types';
import {
  canAmendContract,
  canCancelContract,
  canRenewContract,
  canTurnOffAutoRenew,
} from '../../../utils/contracts';
import { CancelContractForm } from './components/CancelContractForm';

type ContractActionsProps = {
  contract: IContract;
  handleEarlyRenewal?: (contract: IContractWithBillGroup) => void;
};

const ContractActions: FC<ContractActionsProps> = ({
  contract,
  handleEarlyRenewal,
}: ContractActionsProps) => {
  const { userId } = useAuth();
  const navigate = useNavigate();
  const { canDo } = useACL();
  const {
    showConfirmModal: showCancelContractConfirmModal,
    closeModal: closeCancelContractConfirmModal,
    setModalLoading: setCancelContractModalLoading,
  } = useConfirmModal();
  const updateContract = useUpdateContractById();
  const {
    showConfirmModal: showAutoTurnOffConfirmModal,
    closeModal: closeAutoTurnOffConfirmModal,
    setModalLoading: setAutoTurnOffConfirmModalLoading,
  } = useConfirmModal();
  const { enableEarlyRenewal } = useFlags();

  const canCreateContracts = canDo([['contracts', 'create']]);

  const { mutateAsync: doCancelContract } = useCancelContract();

  const defaultCancelContractValues = {
    type: ContractCancelTypeEnum.SPECIFIC_DATE,
    date: formatISO(new Date()),
  };
  const { control, clearErrors, handleSubmit } =
    useForm<ICancelContractUISchema>({
      resolver: getContractUIRequestSchema(contract),
      mode: 'onChange',
      defaultValues: defaultCancelContractValues,
    });

  const onCancelContractSubmit = async ({
    type,
    date,
  }: ICancelContractUISchema) => {
    try {
      setCancelContractModalLoading(true);
      let amendmentEffectiveDate = toDateOnly(
        type === ContractCancelTypeEnum.ENTIRE_CONTRACT
          ? contract.startDate
          : date || new Date(),
        'userTimezone',
      );
      const quote = await doCancelContract({
        contractId: contract.id,
        amendmentEffectiveDate,
        ownerId: userId,
      });
      if (quote.id) {
        closeCancelContractConfirmModal();
        navigate(getQuoteEditRoute(quote.id));
      }
    } catch (error) {
      handleApiErrorToast(error);
      setCancelContractModalLoading(false);
    }
  };

  const onCancelContractError = (error: any) => {
    logger.error('Cancel Contract Error ====>', error);
  };

  const handleAmendContract = async (contractId: string) => {
    try {
      const amendedQuote = await doAmendContract(contractId);
      amendedQuote?.id &&
        navigate({
          pathname: getQuoteEditRoute(amendedQuote.id),
        });
    } catch (error) {
      handleApiErrorToast(error);
    }
  };

  const handleRenewContract = async (contractId: string) => {
    if (enableEarlyRenewal && handleEarlyRenewal) {
      handleEarlyRenewal(contract);
      return;
    }

    try {
      const renewedQuote = await doRenewContract(contractId);
      renewedQuote?.id &&
        navigate({
          pathname: getQuoteEditRoute(renewedQuote.id),
        });
    } catch (error) {
      handleApiErrorToast(error);
    }
  };

  const handleTurnOffAutoRenew = async (contractId: string) => {
    showAutoTurnOffConfirmModal({
      title: 'Turn off Auto Renew',
      description:
        'This contract is set to Auto Renew. By turning off Auto Renew, the Contract must be renewed manually. Once turned off, to turn Auto Renew back on, an Amendment Quote is required.',
      onYes: async () => {
        try {
          setAutoTurnOffConfirmModalLoading(true);
          await updateContract.mutateAsync({
            id: contractId,
            payload: { endAction: ContractEndActionEnum.CANCEL },
          });
        } catch (error) {
          handleApiErrorToast(error);
        } finally {
          setAutoTurnOffConfirmModalLoading(false);
          closeAutoTurnOffConfirmModal();
        }
      },
      yesButton: 'Turn Off',
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  };

  const handleCancelContract = () => {
    showCancelContractConfirmModal({
      title: 'Cancel Contract',
      content: (
        <CancelContractForm
          contract={contract}
          control={control}
          clearErrors={clearErrors}
        />
      ),
      onYes: handleSubmit(onCancelContractSubmit, onCancelContractError),
      onNo: closeCancelContractConfirmModal,
      yesButton: 'Next',
      yesBtnProps: {
        variant: 'primary',
      },
      noBtnProps: {
        variant: 'cancel',
      },
    });
  };

  const actions = [
    {
      title: 'Amend',
      enabled: canCreateContracts && canAmendContract(contract),
      action: () => contract?.id && handleAmendContract(contract.id),
    },
    {
      title: 'Renew',
      enabled: canCreateContracts && canRenewContract(contract),
      action: () => contract?.id && handleRenewContract?.(contract.id),
    },
    {
      title: 'Renew',
      enabled:
        canCreateContracts && contract.status === ContractStatusEnum.CANCELED,
      isDisabled: true,
      tooltipMsg:
        'Canceled Contracts cannot be renewed.  Instead, create a New Quote.',
      action: () => {},
    },
    {
      title: 'Turn off Auto Renew',
      color: 'danger',
      enabled: canCreateContracts && canTurnOffAutoRenew(contract),
      action: () => contract?.id && handleTurnOffAutoRenew(contract.id),
    },
    {
      title: 'Cancel',
      enabled: canCreateContracts && canCancelContract(contract),
      color: 'danger',
      action: handleCancelContract,
    },
  ];
  return <DataTableActions actions={actions} />;
};

export default ContractActions;
