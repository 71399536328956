import { Box, BoxProps } from '@chakra-ui/react';
import React, { FunctionComponent as FC, FormEvent, useRef } from 'react';

interface MFileClickableProps extends BoxProps {
  onFileSelected: (files: FileList | null) => void;
  children: any;
  inputProps?: any;
}

const MFileClickable: FC<MFileClickableProps> = React.forwardRef<
  any,
  MFileClickableProps
>(
  (
    { onFileSelected, children, inputProps, ...rest }: MFileClickableProps,
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>();

    const handleOnClick = () => {
      if (inputRef?.current) {
        inputRef.current.click();
        // this ensures that if the user uploads the same file twice,
        // the second time the onChange event will still fire
        inputRef.current.value = '';
      }
    };

    const onFileChange = (ev: FormEvent<HTMLInputElement>) => {
      const { files } = ev.currentTarget;
      onFileSelected(files);
    };

    return (
      <Box {...rest} ref={ref} position="relative" onClick={handleOnClick}>
        <input
          type="file"
          ref={inputRef}
          style={{
            width: '100%',
            height: '100%',
            display: 'none',
            position: 'absolute',
          }}
          onChange={onFileChange}
          {...inputProps}
        />
        {children}
      </Box>
    );
  },
);

export default MFileClickable;
