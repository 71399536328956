import { addMonthsCustom } from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoMdCalendar } from 'react-icons/io';
import {
  MBox,
  MButton,
  MCloseButton,
  MCustomSelect,
  MFlex,
  MFormField,
  MGrid,
  MIcon,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import {
  DatePicker,
  DatePickerProps,
} from '../../../../../components/Monetize/DatePicker/DatePicker';
import { useFlags } from '../../../../../services/launchDarkly';
import {
  EffectiveDateType,
  IOfferingRes,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResBaseSchema,
  OfferingTypesEnum,
  RateBillingFrequencyEnum,
} from '../../../../../types';
import { useQuoteContext } from '../../quoteContext';
import QuoteOfferingRateRightEl from './QuoteOfferingRateRightEl';
import {
  getIsUsingDelayedBilling,
  getRateRightElement,
  hasUsageProducts,
} from './quoteOfferingUtils';

export interface QuoteOfferingScheduledChangeProps {
  initialDate: Date;
  quote: IQuoteRespSchema;
  quoteOffering: IQuoteOfferingRespSchema;
  parentQuoteOffering?: IQuoteOfferingRespSchema | null;
  priorScheduledQuoteOffering?: IQuoteOfferingRespSchema | null;
  offeringRatesById: Record<string, IRateResBaseSchema>;
  loading?: boolean;
  inactiveRate?: IRateResBaseSchema;
  onSubmit: (value: {
    startDate: string;
    endDate: string;
    rateId: string;
  }) => void;
  onCancel: () => void;
  availableMonthSelectOptions: {
    title: string;
    value: EffectiveDateType;
  }[];
  offering: IOfferingRes;
  monthInterval: number;
  childQuoteOfferings?: IQuoteOfferingRespSchema[];
  gridColumns: string;
  rateSelects: [IRateResBaseSchema[], IRateResBaseSchema[]];
  updatePrevScheduleChange: ({ endDate }: { endDate: string }) => void;
}

/**
 * This component allows a user to add a new scheduled change
 * This is in charge of the initial date selection and the rate selection
 */
export const QuoteOfferingCreateScheduleChange = ({
  initialDate,
  quote,
  quoteOffering,
  parentQuoteOffering,
  priorScheduledQuoteOffering,
  offeringRatesById,
  inactiveRate,
  loading,
  onSubmit,
  onCancel,
  availableMonthSelectOptions,
  offering,
  monthInterval,
  childQuoteOfferings,
  gridColumns,
  rateSelects,
  updatePrevScheduleChange,
}: QuoteOfferingScheduledChangeProps) => {
  const { useAmendmentV2 } = useQuoteContext();
  const {
    allowChangingQuoteOfferingStartAndEndDate,
    useQuoteEditV2,
    allowOptionalProducts,
  } = useFlags();
  const [hasDateSelection, setHasDateSelection] = useState(false);
  const isMinCommit = offering.type === OfferingTypesEnum.MIN_COMMIT;

  const hasUsageProduct = hasUsageProducts(
    parentQuoteOffering,
    offering,
    allowOptionalProducts,
  );

  const isUsingDelayedBilling = getIsUsingDelayedBilling(quote);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<{
    startDate: string | undefined;
    rateId: string;
    endDate: string;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',

    defaultValues: {
      // startDate: formatISO(addDays(initialDate, 1), { representation: 'date' }),
      endDate: quote.contractEndDate,
      rateId: quoteOffering.rateId,
    },
  });

  const startDateWatch = watch('startDate');
  const endDateWatch = watch('endDate');

  useEffect(() => {
    // find endDate in childQuoteOfferings
    const foundOffering = (
      [parentQuoteOffering, ...(childQuoteOfferings || [])].filter(
        (offering) => !!offering,
      ) as IQuoteOfferingRespSchema[]
    ).find(
      ({ startDate, endDate }) =>
        startDateWatch &&
        isBefore(parseISO(startDateWatch), parseISO(endDate)) &&
        isBefore(parseISO(startDate), parseISO(startDateWatch)),
    );

    if (foundOffering) {
      setValue('endDate', foundOffering.endDate);
    } else {
      setValue('endDate', quote.contractEndDate);
    }
  }, [startDateWatch, childQuoteOfferings, setValue, quote.contractEndDate]);

  function handleInitialDate(value: EffectiveDateType) {
    if (typeof value === 'number') {
      setValue(
        'startDate',
        formatISO(addMonthsCustom(initialDate, value), {
          representation: 'date',
        }),
      );
      // Update local state - set previous scheduled change end date
      updatePrevScheduleChange({
        endDate: formatISO(addDays(addMonthsCustom(initialDate, value), -1), {
          representation: 'date',
        }),
      });
    } else {
      setValue(
        'startDate',
        formatISO(addDays(initialDate, 1), { representation: 'date' }),
      );
      // Update local state - set previous scheduled change end date
      updatePrevScheduleChange({
        endDate: formatISO(initialDate, { representation: 'date' }),
      });
    }
    setHasDateSelection(true);
  }

  const handleCancel = () => {
    // Update local state - find the previous segment end date and reset
    const prevQuoteOffering = quote?.quoteOfferings.find(
      (quoteOff) => quoteOff.id === quoteOffering.id,
    );
    if (prevQuoteOffering) {
      updatePrevScheduleChange({
        endDate: prevQuoteOffering.endDate,
      });
    }
    onCancel();
  };

  const datepickerOptions: Partial<DatePickerProps> = {
    minDate: quoteOffering?.startDate
      ? addDays(parseISO(quoteOffering?.startDate), 1)
      : addDays(parseISO(quote.contractStartDate), 1),
    maxDate: addDays(parseISO(quote.contractEndDate), 0),
  };
  const startDatePickerOptions: Partial<DatePickerProps> = {};
  const endDatePickerOptions: Partial<DatePickerProps> = {};

  // Restrict mode based on use-case
  if (isMinCommit) {
    datepickerOptions.mode =
      quoteOffering.billingFrequency === RateBillingFrequencyEnum.ANNUALLY
        ? 'YEAR'
        : 'MONTH';
    datepickerOptions.interval =
      quoteOffering.billingFrequency === RateBillingFrequencyEnum.ANNUALLY
        ? 1
        : (monthInterval as 1 | 3 | 6); // This will be only 1 or 3 or 6
  } else if (hasUsageProduct) {
    datepickerOptions.mode = 'MONTH';
    datepickerOptions.interval = 1;
  }

  if (isUsingDelayedBilling) {
    datepickerOptions.mode = 'YEAR';
    datepickerOptions.interval = 1;
  }

  if (isMinCommit || hasUsageProduct || isUsingDelayedBilling) {
    // Last frequency period cannot be selected
    datepickerOptions.minDate = addMonthsCustom(initialDate, 1 * monthInterval);
    datepickerOptions.maxDate = addMonthsCustom(
      addDays(parseISO(quote.contractEndDate), 1),
      -1 * monthInterval,
    );
    datepickerOptions.baseDate = initialDate;
    datepickerOptions.anchorMonth = initialDate.getMonth() + 1;
  } else {
    startDatePickerOptions.maxDate = parseISO(endDateWatch);
    startDateWatch && (endDatePickerOptions.minDate = parseISO(startDateWatch));
  }

  return (
    <MGrid
      bg={useQuoteEditV2 ? 'tWhite.base' : 'tWhite.titanWhite'}
      alignItems="center"
      gridTemplateColumns={gridColumns}
      columnGap={6}
      pl="52px"
      py="2"
    >
      <MFlex gridColumn={'1 / 3'} direction="row" alignItems="flex-end">
        <MFormField
          alignSelf="center"
          label="Rate"
          error={errors.rateId}
          maxW="10.25rem"
        >
          <Controller
            control={control}
            name="rateId"
            render={({ field }) => (
              <MCustomSelect
                isDisabled={loading}
                maxW="187px"
                placeholder="Select Rate"
                items={rateSelects[0]}
                disabledItems={rateSelects[1].map(({ id }) => id)}
                itemTitle="name"
                itemValue="id"
                renderRightElement={({ value }) =>
                  getRateRightElement({
                    value,
                    offeringRatesById,
                  })
                }
                renderItemContent={({ title, isDisabled, item }) => {
                  return (
                    <MTooltip
                      label={
                        isDisabled
                          ? 'Only Rates with the same Frequency (e.g., Monthly, Annually) and Timing (Advance, Arrears) are available. To change to a different Frequency or Timing, remove scheduled changes first'
                          : ''
                      }
                    >
                      <MFlex alignItems="center" gap={2}>
                        <QuoteOfferingRateRightEl rate={item} />
                        <MText
                          color={isDisabled ? 'tGray.disabledText' : 'inherit'}
                        >
                          {title}
                        </MText>
                      </MFlex>
                    </MTooltip>
                  );
                }}
                {...field}
              />
            )}
          />
        </MFormField>

        {!hasDateSelection ? (
          <MFormField
            alignSelf="center"
            label="Start Date"
            maxW="10.25rem"
            ml="4"
          >
            <MCustomSelect
              placeholder="Select"
              items={availableMonthSelectOptions}
              renderInputLeftElement
              inputLeftElementContent={<MIcon as={IoMdCalendar} w="5" h="5" />}
              onChange={handleInitialDate as any}
            />
          </MFormField>
        ) : (
          <MFormField
            alignSelf="center"
            label="Start Date"
            maxW="10.25rem"
            ml="4"
            error={errors.startDate}
          >
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, ...rest } }) => (
                <DatePicker
                  {...rest}
                  {...datepickerOptions}
                  {...startDatePickerOptions}
                  isDisabled={loading}
                  onChange={(date) => {
                    onChange(date);

                    date &&
                      updatePrevScheduleChange({
                        endDate: formatISO(addDays(parseISO(date), -1), {
                          representation: 'date',
                        }),
                      });
                  }}
                />
              )}
            />
          </MFormField>
        )}

        <MFormField
          alignSelf="center"
          label="End Date"
          maxW="10.25rem"
          error={errors.endDate}
          ml="4"
        >
          <Controller
            control={control}
            name="endDate"
            render={({ field: { onChange, ...rest } }) => (
              <DatePicker
                {...rest}
                {...datepickerOptions}
                {...endDatePickerOptions}
                isDisabled={loading}
                isReadOnly={
                  !useAmendmentV2 && !allowChangingQuoteOfferingStartAndEndDate
                }
                variant={
                  !useAmendmentV2 && !allowChangingQuoteOfferingStartAndEndDate
                    ? 'readonly'
                    : 'primary'
                }
                onChange={(date) => {
                  onChange(date);
                }}
              />
            )}
          />
        </MFormField>
        <MBox ml="4">
          <MButton
            data-testid="scheduled-change-add-button"
            variant="primary"
            size="sm"
            isLoading={loading}
            isDisabled={!isValid || loading || !startDateWatch}
            onClick={handleSubmit((data) => {
              if (!!data.startDate) {
                onSubmit({
                  rateId: data.rateId,
                  startDate: data.startDate,
                  endDate: data.endDate,
                });
              }
            })}
            type="button"
          >
            Add
          </MButton>
        </MBox>
      </MFlex>

      <MFlex gridColumn={'4 / -1'} justifyContent="flex-end" height="100%">
        {!loading && (
          <MCloseButton
            alignSelf="end"
            justifySelf="end"
            minH="7"
            minW="8"
            onClick={() => handleCancel()}
          />
        )}
      </MFlex>
    </MGrid>
  );
};
