export const UploadIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 12"
    {...props}
    fill="currentColor"
  >
    <path d="M6.66699 2.55019V8.66667H5.33366V2.55438L3.174 4.71405L2.23119 3.77124L5.33366 0.668764V0.666667H5.33576L6.00242 0L9.77366 3.77124L8.83085 4.71405L6.66699 2.55019ZM2.00033 6V10.6667H10.0003V6H11.3337V12H0.666992V6H2.00033Z" />
  </svg>
);
