import { Box, ButtonProps, Tooltip } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { useHover } from '../../hooks';

interface MCustomBadgeProps extends ButtonProps {
  text?: string;
  tooltipLabel?: string;
  /** If provided, the badge turns into an `x` icon and allows clicking, which should clear the items */
  onClickReset?: () => void;
  children: any;
}

const MCustomBadge: FC<MCustomBadgeProps> = React.forwardRef<
  any,
  MCustomBadgeProps
>(({ text, tooltipLabel, onClickReset, children }: MCustomBadgeProps, ref) => {
  const [hoveredRef, isHovered] = useHover<HTMLDivElement>();

  const handleReset = () => {
    onClickReset && onClickReset();
  };

  return (
    <Box ref={ref}>
      {children}

      {!!text && (
        <Tooltip label={tooltipLabel}>
          <Box
            ref={hoveredRef}
            backgroundColor="tRed.base"
            position="absolute"
            top="-2"
            right="-1"
            borderRadius="50%"
            width="5"
            height="5"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="white"
            fontSize="xs"
            fontWeight="bold"
            borderWidth="2px"
            borderColor="white"
            _hover={onClickReset ? { cursor: 'pointer' } : undefined}
            onClick={handleReset}
          >
            {/* Cannot use icon here because isHovered changes state */}
            {onClickReset && isHovered ? 'x' : text}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
});

export default MCustomBadge;
