import { Accordion } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import {
  MBox,
  MButton,
  MFlex,
  MFormField,
  MInput,
  MTextarea,
  MVStack,
} from '../../../../../components/Monetize';
import { MSettingAccordionItem } from '../../../../../components/Monetize/MSettingAccordionItem';
import {
  DealRoomSettingWelcomeSchema,
  IDealRoomSettingWelcomeSchema,
} from '../../../../../types/dealroomTypes';
import { ContractSettingSection } from '../../QuoteSettings/components/ContractSettingSection';
import { SettingsToggle } from '../../QuoteSettings/components/SettingsToggle';

interface DealRoomSettingWelcomeProps {
  value: IDealRoomSettingWelcomeSchema;
  onSubmit: (data: IDealRoomSettingWelcomeSchema) => Promise<void>;
  isLoading: boolean;
  isDisabled: boolean;
}

export const DealRoomSettingWelcome = ({
  value,
  onSubmit,
  isLoading,
  isDisabled,
}: DealRoomSettingWelcomeProps) => {
  const quoteSettingProcessingForm = useForm<IDealRoomSettingWelcomeSchema>({
    resolver: zodResolver(DealRoomSettingWelcomeSchema),
    mode: 'onChange',
    defaultValues: value,
    values: value,
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
    setValue,
  } = quoteSettingProcessingForm;

  const useDefaultWelcomeMessageWatch = watch('useDefaultWelcomeMessage');

  return (
    <Accordion allowMultiple w="full">
      <MSettingAccordionItem label="Welcome Message">
        <MBox w="100%" pb={4}>
          <form>
            <MVStack alignItems="start" spacing={4}>
              <ContractSettingSection showDivider={false}>
                <SettingsToggle
                  name="useDefaultWelcomeMessage"
                  heading="Use a Default Welcome Message"
                  subheading="Show a default welcome message in every Dealroom."
                  value={useDefaultWelcomeMessageWatch}
                  onChange={(val) => {
                    setValue('useDefaultWelcomeMessage', val, {
                      shouldDirty: true,
                    });
                  }}
                  isDisabled={isDisabled}
                />
              </ContractSettingSection>

              <ContractSettingSection showDivider={false}>
                <MFormField
                  error={errors.defaultWelcomeMessage}
                  label="Default Welcome Message"
                  labelProps={{ fontSize: 'md', fontWeight: '600' }}
                >
                  <Controller
                    name="defaultWelcomeMessage"
                    control={control}
                    defaultValue=""
                    render={({ field: { value, ...rest } }) => (
                      <MTextarea
                        value={value || ''}
                        {...rest}
                        isDisabled={
                          isDisabled || !useDefaultWelcomeMessageWatch
                        }
                      />
                    )}
                  />
                </MFormField>
              </ContractSettingSection>

              <ContractSettingSection showDivider={false}>
                <MFormField
                  error={errors.defaultWelcomeUrl}
                  label="Link"
                  labelProps={{ fontSize: 'md', fontWeight: '600' }}
                >
                  <Controller
                    name="defaultWelcomeUrl"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        value={value || ''}
                        {...rest}
                        isDisabled={
                          isDisabled || !useDefaultWelcomeMessageWatch
                        }
                      />
                    )}
                  />
                </MFormField>
              </ContractSettingSection>
            </MVStack>
          </form>
        </MBox>

        <MFlex justifyContent="flex-end">
          <MButton
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty || isDisabled}
            isLoading={isLoading}
          >
            Save
          </MButton>
        </MFlex>
      </MSettingAccordionItem>
    </Accordion>
  );
};
