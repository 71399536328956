import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { doGetTenantUser, doGetUserById } from '../api/usersService';
import { ROUTES } from '../constants';
import { currentTenantState } from '../store/global.store';
import { IUser } from '../types';

const useCurrentUser = (
  isTokenRefreshed: boolean,
  tenantId?: string,
  userId?: string,
) => {
  const navigate = useNavigate();
  // user fetched from /tenants/{id}/users/{id}
  const [currentTenantUser, setCurrentTenantUser] = useState<null | IUser>(
    null,
  );
  const setCurrentTenant = useSetRecoilState(currentTenantState);
  // User fetched from /users/{id}
  const [, setCurrentUser] = useState(null);
  const [error, setError] = useState<any>();

  const currentTenantUserHasRole = (routeRoles: string[]) => {
    return (
      currentTenantUser?.roles?.some((role) =>
        routeRoles.includes(role.name),
      ) || false
    );
  };

  const currentTenantUserHasSpecificRole = (routeRole: string) => {
    return (
      currentTenantUser?.roles?.some((role) => role.name === routeRole) || false
    );
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const [currentUserP, currentTenantUserP] = await Promise.all([
          doGetUserById(userId!),
          doGetTenantUser(tenantId!, userId!),
        ]);
        setCurrentUser(currentUserP);
        setCurrentTenantUser(currentTenantUserP);
        if (error) {
          setError(undefined);
        }
      } catch (err) {
        setError(err);
        setCurrentTenantUser(null);
        setCurrentUser(null);
        // Remove current tenant selected and navigate to switch the tenant
        setCurrentTenant(null);
        navigate(ROUTES.ROOT);
      }
    };
    if (isTokenRefreshed && userId && tenantId) {
      loadData();
    } else {
      setCurrentTenantUser(null);
      setCurrentUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenRefreshed, userId, tenantId, setCurrentTenant]);

  return {
    error,
    currentTenantUser,
    currentTenantUserHasRole,
    currentTenantUserHasSpecificRole,
  };
};

export default useCurrentUser;
