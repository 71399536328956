import { useGetConfigurationEsign } from '../api/settingsService';

/**
 * Hook to check if eSign is configured for the current tenant
 * The results are cached to avoid re-checking for configuration from API over and over
 *
 * @param quoteRequiresEsign - if true, then this will be checked in addition to the tenant configuration
 */
export const useIsTenantEsignConfigured = () => {
  const { data: isTenantEsignConfigured } = useGetConfigurationEsign();
  return !!isTenantEsignConfigured;
};

export const useIsTenantEsignConfiguredWithLoading = () => {
  const { data, isLoading } = useGetConfigurationEsign();
  return { isLoading, isTenantEsignConfigured: !!data };
};
