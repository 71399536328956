import { Flex, FlexProps, PlacementWithLogical } from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { MdConstruction } from 'react-icons/md';
import { MBox, MIcon, MTooltip } from './chakra';

interface MComingSoonWrapperProps extends FlexProps {
  label?: string;
  placement?: PlacementWithLogical;
  iconDisplay?: string;
  children?: React.ReactNode;
}

export const MComingSoonWrapper: FC<MComingSoonWrapperProps> = React.forwardRef<
  any,
  MComingSoonWrapperProps
>((props: MComingSoonWrapperProps, ref) => {
  const {
    label = 'Coming Soon',
    placement = 'bottom-end',
    iconDisplay = 'none',
    children,
    ...rest
  } = props;

  return (
    <MTooltip label={label} placement={placement}>
      <Flex alignItems="center" cursor="pointer" role="group" {...rest}>
        {children}
        <MBox minW="4" ml={2}>
          <MIcon
            color="tGray.lightPurple"
            as={MdConstruction}
            _groupHover={{ display: 'block' }}
            display={iconDisplay}
          />
        </MBox>
      </Flex>
    </MTooltip>
  );
});

export default MComingSoonWrapper;
