import React, { FunctionComponent as FC, useState } from 'react';

import { useDisclosure } from '@chakra-ui/react';
import { toDateShort } from '@monetize/utils/core';
import {
  MdCheck,
  MdChevronLeft,
  MdChevronRight,
  MdClose,
  MdRemove,
} from 'react-icons/md';
import { useGetTeamsById } from '../../../api/settingsService';
import { useGetTenantUserById } from '../../../api/usersService';
import { useAuth } from '../../../services/auth0';
import {
  ApprovalStatusEnum,
  IApprovalRespSchema,
  IQuoteRespSchema,
  ITeamResponseSchema,
  IUsersInfo,
  QuoteStatusEnum,
} from '../../../types';
import { pluralize } from '../../../utils';
import { sortTeamUsers } from '../../../utils/settings';
import {
  MAvatar,
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MIcon,
  MLink,
  MSpinner,
  MText,
  MTooltip,
  MVStack,
} from '../../Monetize';
import { QuoteApprovalRejectForm } from './QuoteApprovalRejectForm';

interface QuoteStepApprovalItemProps {
  quote: IQuoteRespSchema;
  approval: IApprovalRespSchema;
  canDoActions: boolean;
  onClose: () => void;
  onApprove: (approval: IApprovalRespSchema) => void;
  reloadData?: () => void;
  isLoading?: boolean;
  itemIndex: number;
  showConnectionLine?: boolean;
}

const CAN_APPROVE_STATUS = new Set([
  ApprovalStatusEnum.NO_ACTION,
  ApprovalStatusEnum.DECLINED,
]);
const CAN_REJECT_STATUS = new Set([ApprovalStatusEnum.NO_ACTION]);
const CAN_RETRACT_STATUS = new Set([ApprovalStatusEnum.APPROVED]);

const ApprovalTeam = ({
  teamUsers,
  teamName,
}: {
  teamUsers: IUsersInfo[];
  teamName: string;
}) => {
  const [showAll, setShowAll] = useState(false);
  if (teamUsers.length === 0) {
    return null;
  }
  if (!showAll) {
    return (
      <MFlex flexWrap="wrap" alignItems="center">
        <MFlex flexWrap="wrap" mt="2">
          {(teamUsers.length > 5 ? teamUsers.slice(0, 4) : teamUsers).map(
            (member: IUsersInfo, index: number) => (
              <MTooltip
                key={member.id}
                shouldWrapChildren
                label={member.name}
                placement="bottom-start"
                lineHeight="1rem"
              >
                <MAvatar
                  name={member.name}
                  size="2xs"
                  ml={index > 0 ? '-2' : 0}
                />
              </MTooltip>
            ),
          )}
          {teamUsers.length > 5 && <MAvatar name={'+ 5'} size="2xs" ml="-2" />}
        </MFlex>
        <MText
          fontSize="xs"
          isTruncated
          ml="2"
          color="tGray.darkPurple"
          onClick={() => setShowAll(true)}
          cursor="pointer"
        >
          {teamName} ({teamUsers?.length}{' '}
          {pluralize('Reviewer', teamUsers?.length)})
        </MText>
        <MCustomIconButton
          ml="1"
          btnSize={5}
          variant="icon"
          icon={MdChevronRight}
          iconColor="tGray.darkPurple"
          onClick={() => setShowAll(true)}
        />
      </MFlex>
    );
  }
  if (showAll) {
    return (
      <MBox>
        <MFlex alignItems="center" ml="-1">
          <MCustomIconButton
            ml="1"
            btnSize={5}
            variant="icon"
            icon={MdChevronLeft}
            iconColor="tGray.darkPurple"
            onClick={() => setShowAll(false)}
          />
          <MText
            fontSize="xs"
            isTruncated
            ml="1"
            color="tGray.darkPurple"
            onClick={() => setShowAll(false)}
            cursor="pointer"
          >
            {teamUsers?.length} {pluralize('Reviewer', teamUsers?.length)}
          </MText>
        </MFlex>
        <MVStack alignItems="flex-start" spacing="1.5" my="2">
          {teamUsers.map((member: IUsersInfo) => (
            <MFlex key={member.id} alignItems="center" maxW="100%">
              <MAvatar name={member.name} size="2xs" mr="2" />
              <MText isTruncated fontSize="xs" color="tGray.darkPurple">
                {member.name}
              </MText>
            </MFlex>
          ))}
        </MVStack>
      </MBox>
    );
  }
  return null;
};

export const QuoteStepApprovalItem: FC<QuoteStepApprovalItemProps> =
  React.forwardRef<any, QuoteStepApprovalItemProps>(
    (
      {
        quote,
        approval,
        canDoActions,
        onClose,
        onApprove,
        reloadData,
        isLoading,
        itemIndex,
        showConnectionLine,
      }: QuoteStepApprovalItemProps,
      ref,
    ) => {
      const { tenantId } = useAuth();
      const { teamId, userId } = approval;
      const [showMore, setShowMore] = useState(false);

      const { data: teamUsers, isLoading: teamLoading } = useGetTeamsById(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        teamId!,
        {
          enabled: !!teamId,
          select: (team: ITeamResponseSchema) => {
            return sortTeamUsers(team?.users, team?.owner);
          },
        },
      );

      const { data: user, isLoading: userLoading } = useGetTenantUserById({
        tenantId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userId: userId!,
        options: {
          enabled: !!userId,
          refetchOnWindowFocus: false,
        },
      });

      const {
        isOpen: isRejectFormOpen,
        onOpen: onOpenRejectForm,
        onClose: onCloseRejectForm,
      } = useDisclosure();

      const canApprove =
        quote.status !== QuoteStatusEnum.DENIED &&
        CAN_APPROVE_STATUS.has(approval.status as ApprovalStatusEnum);
      const canReject =
        quote.status !== QuoteStatusEnum.DENIED &&
        CAN_REJECT_STATUS.has(approval.status as ApprovalStatusEnum);
      const canRetract =
        quote.status !== QuoteStatusEnum.DENIED &&
        CAN_RETRACT_STATUS.has(approval.status as ApprovalStatusEnum);

      const isApproved =
        approval.status === ApprovalStatusEnum.APPROVED || approval.skipped;
      const isDeclined = approval.status === ApprovalStatusEnum.DECLINED;
      // Default state when no action
      let styles = {
        fontColor: 'tGray.lightPurple',
        textColor: 'tGray.darkPurple',
        circleBackColor: 'tWhite.base',
      };
      if (canDoActions) {
        styles = {
          fontColor: 'tIndigo.base',
          textColor: 'tIndigo.base',
          circleBackColor: 'tWhite.base',
        };
      }
      if (isDeclined) {
        styles = {
          fontColor: 'tRed.base',
          textColor: 'tRed.base',
          circleBackColor: 'tWhite.base',
        };
      }
      if (isApproved) {
        styles = {
          fontColor: 'tGreen.approval',
          textColor: 'tWhite.base',
          circleBackColor: 'tGreen.approval',
        };
      }
      const approverName =
        approval.approverName ??
        (approval.teamId ? approval.teamName : approval.username);

      const REASON_SLICE_LENGTH = 100;
      const showShowMore =
        isDeclined &&
        approval.declineReason &&
        approval.declineReason.length > REASON_SLICE_LENGTH;

      return (
        <MFlex w="100%" pt="1">
          <MBox px="2" position="relative">
            <MBox
              w={5}
              h={5}
              borderRadius="50%"
              borderWidth="2px"
              borderColor={styles.fontColor}
              backgroundColor={styles.circleBackColor}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {isDeclined ? (
                <MIcon as={MdClose} color={styles.textColor} />
              ) : isApproved ? (
                <MIcon as={MdCheck} color={styles.textColor} />
              ) : canDoActions ? (
                <MIcon as={MdRemove} color={styles.textColor} />
              ) : (
                <MText fontWeight="semibold" color={styles.textColor}>
                  {itemIndex}
                </MText>
              )}
            </MBox>
            {showConnectionLine && (
              <MBox
                height="100%"
                position="absolute"
                backgroundColor="tGray.lightPurple"
                width="1.5px"
                left="calc(50% - 0.75px)"
              />
            )}
          </MBox>
          <MBox flex="1" minW="0">
            <MText fontSize="14px" fontWeight={400}>
              {approval.name}
            </MText>
            {isApproved && (
              <>
                <MFlex alignItems="center" justifyContent="space-between">
                  <MFlex alignItems="center" width="calc(100% - 66px)">
                    <MText
                      fontSize="xs"
                      whiteSpace="nowrap"
                      mr="1"
                      color="tGray.darkPurple"
                    >
                      Approved by
                    </MText>
                    <MTooltip label={approverName}>
                      <MText fontSize="xs" isTruncated color="tGray.darkPurple">
                        {approverName}
                      </MText>
                    </MTooltip>
                  </MFlex>
                  {approval.approvalTimestamp && (
                    <MText
                      fontSize="xs"
                      whiteSpace="nowrap"
                      color="tGray.darkPurple"
                    >
                      {toDateShort(approval.approvalTimestamp)}
                    </MText>
                  )}
                </MFlex>
                {approval.teamName && (
                  <MText fontSize="xs">Team: {approval.teamName}</MText>
                )}
              </>
            )}
            {!isApproved && !isDeclined && (
              <>
                {!!teamId && teamLoading && <MSpinner />}
                {!!userId && userLoading && <MSpinner />}
                {!!teamId && !teamLoading && approval.teamName && (
                  <ApprovalTeam
                    teamUsers={teamUsers as IUsersInfo[]}
                    teamName={approval.teamName}
                  />
                )}
                {!!userId && !!user && !userLoading && (
                  <MFlex mt={1} alignItems="center">
                    <MAvatar name={user.name} mr="1" size="2xs" />
                    <MTooltip
                      shouldWrapChildren
                      label={user.name}
                      placement="bottom-start"
                    >
                      <MText
                        fontSize="xs"
                        isTruncated
                        lineHeight="18px"
                        color="tGray.darkPurple"
                      >
                        {user.name}
                      </MText>
                    </MTooltip>
                  </MFlex>
                )}
              </>
            )}

            {isDeclined && (
              <>
                <MFlex alignItems="center" justifyContent="space-between">
                  <MFlex alignItems="center" width="calc(100% - 66px)">
                    <MText
                      fontSize="xs"
                      whiteSpace="nowrap"
                      mr="1"
                      color="tGray.darkPurple"
                    >
                      Declined by
                    </MText>
                    <MTooltip label={approval.declinerName}>
                      <MText fontSize="xs" isTruncated color="tGray.darkPurple">
                        {approval.declinerName}
                      </MText>
                    </MTooltip>
                  </MFlex>
                  {approval.declinedTimestamp && (
                    <MText
                      fontSize="xs"
                      whiteSpace="nowrap"
                      color="tGray.darkPurple"
                    >
                      {toDateShort(approval.declinedTimestamp)}
                    </MText>
                  )}
                </MFlex>
                <MFlex alignItems="flex-end" mt="2">
                  <MText color="tGray.darkPurple" fontSize="xs">
                    <b>Reason</b>:{' '}
                    {!showMore
                      ? `${(approval.declineReason || '').slice(
                          0,
                          REASON_SLICE_LENGTH,
                        )}...`
                      : approval.declineReason}
                  </MText>
                  {showShowMore && (
                    <MLink fontSize="xs" onClick={() => setShowMore(!showMore)}>
                      {!showMore ? 'More' : 'Less'}
                    </MLink>
                  )}
                </MFlex>
              </>
            )}

            {canDoActions && (
              <>
                {isRejectFormOpen && (
                  <QuoteApprovalRejectForm
                    isOpen={isRejectFormOpen}
                    onClose={onCloseRejectForm}
                    quote={quote}
                    approval={approval}
                    reloadData={reloadData}
                  />
                )}
                {!isRejectFormOpen && (canApprove || canReject) && (
                  <MFlex mt={2} justifyContent="flex-end">
                    {canReject && (
                      <MButton
                        variant={'deleteOutline'}
                        onClick={() => {
                          onOpenRejectForm();
                        }}
                        minW="unset"
                        isLoading={isLoading}
                      >
                        Deny
                      </MButton>
                    )}

                    {canApprove && (
                      <MButton
                        ml={2}
                        onClick={() => {
                          onApprove(approval);
                          onClose();
                        }}
                        minW="unset"
                        isLoading={isLoading}
                      >
                        Approve
                      </MButton>
                    )}
                  </MFlex>
                )}
              </>
            )}
          </MBox>
        </MFlex>
      );
    },
  );
