import { startOfYear } from 'date-fns/startOfYear';
import { Link } from 'react-router-dom';
import { useGetSalesMonthlyPeriod } from '../../../../api/dashboardsService';
import {
  MBox,
  MCustomSelect,
  MFlex,
  MLink,
} from '../../../../components/Monetize';
import { ROUTES } from '../../../../constants';
import { MonthlySale, SalesCreatedByFilter } from '../../../../types';
import { formatCurrency } from '../../../../utils';
import { toDateOnly } from '../../../../utils/dates';
import { SalesCard } from './SalesCard';
import { SalesChart } from './SalesChart/SalesChart';

const options = [
  { value: 'YTD', text: 'YTD' },
  { value: 'PREVIOUS_YEAR', text: 'Previous Year' },
  { value: 'PREVIOUS_12_MONTH', text: 'Previous 12 Month' },
];

const TotalSales = ({
  totalAmount,
  currentQuarter,
  setCurrentQuarter,
  isLoading,
  currency,
}: {
  totalAmount: number;
  currentQuarter: string;
  setCurrentQuarter: (val: string) => void;
  isLoading: boolean;
  currency: string;
}) => {
  return (
    <SalesCard
      actions={
        <MFlex alignItems="center" ml="2">
          <MCustomSelect
            items={options}
            itemTitle="text"
            itemValue="value"
            value={currentQuarter}
            onChange={(ev) => {
              setCurrentQuarter(ev as any);
            }}
            inputProps={{
              style: {
                border: 0,
                textAlign: 'right',
                maxWidth: '160px',
                paddingRight: '24px',
              },
            }}
          />
        </MFlex>
      }
      title="Total Sales"
      value={formatCurrency(totalAmount, {
        currency,
      })}
      loading={isLoading}
    />
  );
};

const ActiveContracts = ({
  totalNumberOfContracts,
  isLoading,
  appliedFilters,
}: {
  totalNumberOfContracts: number;
  isLoading: boolean;
  appliedFilters: Record<string, any>;
}) => {
  return (
    <SalesCard
      actions={
        <MLink
          fontSize="sm"
          as={Link}
          to={ROUTES.SALES_CONTRACTS_ROUTE}
          cursor="pointer"
          state={appliedFilters}
        >
          View Contracts
        </MLink>
      }
      title="Active Contracts"
      value={String(totalNumberOfContracts)}
      loading={isLoading}
    />
  );
};

const CURRENT_QUARTER_DATE_MAP = {
  YTD: {
    min: toDateOnly(startOfYear(new Date())),
    max: toDateOnly(new Date()),
  },
  PREVIOUS_YEAR: {
    min: toDateOnly(new Date(new Date().getFullYear() - 1, 0, 1)),
    max: toDateOnly(new Date(new Date().getFullYear() - 1, 11, 31)),
  },
  PREVIOUS_12_MONTH: {
    min: toDateOnly(
      new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1),
    ),
    max: toDateOnly(
      new Date(new Date().getFullYear(), new Date().getMonth(), 31),
    ),
  },
};

const TotalSection = ({
  currentQuarter,
  setCurrentQuarter,
  createdByFilter,
  currency,
}: {
  currentQuarter: string;
  setCurrentQuarter: (val: string) => void;
  createdByFilter: SalesCreatedByFilter;
  currency: string;
}) => {
  const { isLoading, data: series } = useGetSalesMonthlyPeriod(
    { period: currentQuarter, query: { ...createdByFilter, currency } },
    {
      select: (data) => ({
        ...data,
        monthlySales: data.monthlySales.sort(
          (m1: MonthlySale, m2: MonthlySale) =>
            m1.yearMonth.localeCompare(m2.yearMonth),
        ),
        totalNumberOfContracts: data.totalNumberOfContracts,
        totalAmount: data.totalAmount,
      }),
    },
  );

  const appliedFilters = {
    owner: createdByFilter?.userId || createdByFilter?.teamId,
    startDate: currentQuarter ? CURRENT_QUARTER_DATE_MAP[currentQuarter] : null,
    currency: currency,
  };
  return (
    <MFlex maxHeight="380px">
      <MBox>
        <TotalSales
          isLoading={isLoading}
          currentQuarter={currentQuarter}
          totalAmount={series ? series.totalAmount : 0}
          setCurrentQuarter={setCurrentQuarter}
          currency={currency}
        />
        <MBox mt="8" />
        <ActiveContracts
          totalNumberOfContracts={series ? series.totalNumberOfContracts : 0}
          isLoading={isLoading}
          appliedFilters={appliedFilters}
        />
      </MBox>
      <MBox flex="1" height="100%" ml="4">
        <SalesChart
          createdByFilter={createdByFilter}
          series={series?.monthlySales || []}
          isLoading={isLoading}
          currency={currency}
        />
      </MBox>
    </MFlex>
  );
};

export { TotalSection };
