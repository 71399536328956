import { MFlex } from '../../../../components/Monetize';
import MText, { MTextProps } from '../../../../components/Monetize/MText';
import {
  IQuoteItem,
  IQuoteRespSchema,
  QuoteAmendmentVersionEnum,
  QuoteTypeEnum,
} from '../../../../types';
import { hasQuotePreviousQty, isQuoteQtyChanged } from '../../../../utils';

const getQuoteColors = (
  quote: IQuoteRespSchema | null,
  isAmendmentOrScheduled: boolean = false,
) => {
  if (
    (quote?.type === QuoteTypeEnum.AMENDMENT &&
      quote?.amendmentVersion === QuoteAmendmentVersionEnum.v1) ||
    quote?.type === QuoteTypeEnum.RENEWAL ||
    isAmendmentOrScheduled
  ) {
    return { color: '#8C32FF', bgcolor: '#F7ECFF' };
  }
  return { color: 'tIndigo.base', bgcolor: 'white' };
};

const getTextColor = ({
  color,
  isRemoved = false,
  hasChangedQuantity = false,
}: {
  color: string;
  isRemoved?: boolean;
  hasChangedQuantity?: boolean;
}) => {
  if (isRemoved) {
    return 'tGray.darkGrayPurple';
  }

  if (hasChangedQuantity) {
    return color;
  }

  return 'tPurple.base';
};

interface QuantityTextProps {
  value: string | number;
  item: IQuoteItem;
  quote: IQuoteRespSchema | null;
  type: QuoteTypeEnum;
  isScheduledChange: boolean;
  scheduledChangePriorQty?: number;
  styles?: MTextProps;
  isRemoved?: boolean;
  hideDifference?: boolean;
}

export const QuantityText = ({
  value,
  item,
  quote,
  type,
  isScheduledChange,
  scheduledChangePriorQty,
  isRemoved,
  styles = {},
  hideDifference,
}: QuantityTextProps) => {
  const isAmendmentOrScheduled =
    type === QuoteTypeEnum.AMENDMENT || isScheduledChange;
  const { color } = getQuoteColors(quote, isAmendmentOrScheduled);
  const checkPrevQty = hasQuotePreviousQty(item, scheduledChangePriorQty);
  const checkQtyChanged = isQuoteQtyChanged(
    item,
    Number(value),
    isScheduledChange ? scheduledChangePriorQty : item.previousQuantity!,
  );

  const previousQuantity = isScheduledChange
    ? scheduledChangePriorQty
    : item.previousQuantity;
  const prevAsNum = checkPrevQty ? Number(previousQuantity) : 0;
  const valueAsNum = Number(value);
  const quantityDifference = `(${valueAsNum > prevAsNum ? '+' : '-'}${Math.abs(
    valueAsNum - prevAsNum,
  )})`;

  const hasChangedQuantity =
    checkPrevQty &&
    checkQtyChanged &&
    isAmendmentOrScheduled &&
    !hideDifference;
  const textColor = getTextColor({
    color,
    hasChangedQuantity,
    isRemoved,
  });

  return (
    <MFlex
      data-testid="quantity-cell"
      justifyContent="start"
      fontWeight={hasChangedQuantity ? '600' : 'unset'}
    >
      <MText color={textColor} data-testid={`${item.id}-quantity`} {...styles}>
        {value}
      </MText>
      {hasChangedQuantity && (
        <MText ml={1} lineHeight={1.4} {...styles}>
          {quantityDifference}
        </MText>
      )}
    </MFlex>
  );
};
