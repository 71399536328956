import { useDisclosure } from '@chakra-ui/react';
import hasValue from 'lodash/has';
import isString from 'lodash/isString';
import { unparse } from 'papaparse';
import React, { useEffect, useState } from 'react';
import {
  apiGetAllList,
  apiGetAllListInfiniteScroll,
  handleApiErrorToast,
} from '../../api/axios';
import { EXPORT } from '../../constants';
import { FilterType, MimeTypeEnum, SortOrder } from '../../types';
import {
  buildFilterParamsRequestObject,
  flattenObj,
  transformTableFilterValue,
} from '../../utils';
import { saveFile } from '../../utils/download';
import { ExportEntityButtonUI } from './ExportEntityButtonUI';

function getFilters(
  applyFilters: boolean,
  filters?: FilterType[],
  internalFilters?: FilterType[],
  searchKey?: string,
  searchTerm?: string,
) {
  let filtersToApply: FilterType[] = [];

  if (internalFilters) {
    filtersToApply = internalFilters;
  }

  if (applyFilters && filters) {
    filtersToApply = [...filtersToApply, ...filters];
  }
  return applyFilters
    ? buildFilterParamsRequestObject(
        transformTableFilterValue(filtersToApply),
        searchTerm,
        searchKey,
      )
    : [];
}
const getFilenameFn = (entity: EXPORT.ExportEntity) => `${entity}.csv`;

interface ExportTableButtonProps {
  entity: EXPORT.ExportEntity;
  isDisabled?: boolean;
  /** Filers that can optionally be included in export, user can choose */
  filters?: FilterType[];
  searchKey?: string;
  searchTerm?: string;
  /** Filters to apply without asking for user consent */
  internalFilters?: FilterType[];
  sortField?: string;
  sortOrder?: SortOrder;
  /** Required for some entities if the endpoint has a path with ids */
  endpointParams?: string[];
  /** Show modal no matter what */
  forceShowModal?: boolean;
  /** Transform results if needed */
  transformResults?: (results: any[]) => any[];
  getFilename?: (entity: EXPORT.ExportEntity) => string;
  customConfigElement?: React.ReactElement;
  onStateChanged?: ({ isLoading }: { isLoading: boolean }) => void;
  /** Add Internal Filters to the endpoint url without showing modal  */
  applyInternalFiltersWithoutConfirmation?: boolean;
  tooltipLabel?: string;
}
export const ExportTableButton = React.forwardRef(
  (
    {
      entity,
      isDisabled = false,
      filters,
      searchKey,
      searchTerm,
      internalFilters,
      sortField,
      sortOrder,
      endpointParams,
      forceShowModal = false,
      transformResults,
      getFilename = getFilenameFn,
      customConfigElement,
      onStateChanged,
      applyInternalFiltersWithoutConfirmation = false,
      tooltipLabel = 'Export CSV',
    }: ExportTableButtonProps,
    ref,
  ) => {
    const showModal =
      forceShowModal || Boolean(searchTerm) || Boolean(filters?.length);
    const [isLoading, setIsLoading] = useState(false);
    const [applyFilters, setApplyFilters] = useState(true);
    const {
      isOpen: isModalOpen,
      onClose: closeModal,
      onOpen: openModal,
    } = useDisclosure();

    const handleButtonClick = async () => {
      if (showModal) {
        openModal();
      } else {
        handleDownload();
      }
    };

    const handleDownload = async () => {
      try {
        if (
          !EXPORT.EXPORT_CONFIGURATION[entity] ||
          (!isString(EXPORT.EXPORT_CONFIGURATION[entity].endpoint) &&
            !endpointParams)
        ) {
          return;
        }
        setIsLoading(true);

        const {
          endpoint: _endpoint,
          fields,
          fieldTransformFns,
          useInfinityCursor,
        } = EXPORT.EXPORT_CONFIGURATION[entity];

        const endpoint = isString(_endpoint)
          ? _endpoint
          : _endpoint(...endpointParams!);

        const fetchFn = useInfinityCursor
          ? apiGetAllListInfiniteScroll
          : apiGetAllList;

        const shouldApplyFilter =
          (showModal && applyFilters) ||
          applyInternalFiltersWithoutConfirmation;

        const results = await fetchFn<any>(endpoint, {
          filters: getFilters(
            shouldApplyFilter,
            filters,
            internalFilters,
            searchKey,
            searchTerm,
          ),
          sortField,
          sortOrder,
        });
        /*
         * When downloading records from a table, including all custom fields.
         * TICKET: https://monetizenow.atlassian.net/browse/BP-8373
         */
        const headers = [...fields];
        const resultWithCustomFields = results.find((r) =>
          hasValue(r, 'customFields'),
        );
        if (resultWithCustomFields) {
          for (const key of Object.keys(resultWithCustomFields.customFields)) {
            headers.push(`customFields.${key}`);
          }
        }
        const transformedResults = transformResults
          ? transformResults(results)
          : results;

        const csv = unparse(
          {
            fields: headers,
            data: flattenObj(transformedResults, headers, fieldTransformFns),
          },
          { header: true },
        );
        saveFile(csv, getFilename(entity), MimeTypeEnum.CSV);
        setApplyFilters(true);
      } catch (ex) {
        handleApiErrorToast(ex);
      } finally {
        setIsLoading(false);
        closeModal();
      }
    };

    useEffect(() => {
      onStateChanged && onStateChanged({ isLoading });
    }, [isLoading, onStateChanged]);

    return (
      <ExportEntityButtonUI
        ref={ref}
        handleButtonClick={handleButtonClick}
        handleDownload={handleDownload}
        isDisabled={isDisabled}
        tooltipLabel={tooltipLabel}
        isLoading={isLoading}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        customConfigElement={customConfigElement}
        applyFilters={applyFilters}
        setApplyFilters={setApplyFilters}
      />
    );
  },
);
