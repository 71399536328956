import { toDateShort } from '@monetize/utils/core';
import isEmpty from 'lodash/isEmpty';
import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountById } from '../../../api/accountsService';
import {
  useGetRefundById,
  usePrintRefundToHtml,
} from '../../../api/refundService';
import {
  DynamicLabelValueBanner,
  LabelValueLinkItem,
} from '../../../components/DynamicLabelValueBanner/DynamicLabelValueBanner';
import {
  MBox,
  MPageContainer,
  MPageHeader,
  MSpinner,
} from '../../../components/Monetize';
import { HtmlIFrameContentRenderer } from '../../../components/Monetize/HtmlIFrameContentRenderer';
import { ROUTES } from '../../../constants';
import { REFUND_STATUS_DISPLAY } from '../../../types';
import { RefundActions } from './RefundActions';

const refundReadableErrorMessage: { [key: string]: string } = {
  lost_or_stolen_card: 'The card was reported lost or stolen.',
  expired_or_canceled_card: 'The card has expired or was canceled.',
  charge_for_pending_refund_disputed:
    'The charge for the pending refund was disputed.',
  insufficient_funds: 'There are insufficient funds available.',
  declined: 'The transaction was declined.',
  merchant_request: 'Refund requested by the merchant.',
  unknown: 'The reason for the refund failure is unknown.',
};

export const RefundPage: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const refundId = params?.refundId || '';
  const accountId = params?.accountId || '';

  const { data: refund, isLoading: isRefundIdLoading } = useGetRefundById(
    refundId,
    {
      enabled: !!refundId,
      meta: {
        showErrorToast: true,
        navigateTo: accountId
          ? ROUTES.getAccountPaymentsRoute(accountId)
          : null,
      },
    },
  );

  const { data: accountDetails } = useGetAccountById(accountId, {
    enabled: !!accountId,
    refetchOnWindowFocus: false,
  });

  const handleBackNavigation = () => {
    navigate(ROUTES.getAccountPaymentsRoute(accountId!));
  };

  const { data, isLoading, isRefetching, error, isPending } =
    usePrintRefundToHtml({
      refundId,
    });

  const DisplayBannerComponent = useMemo(() => {
    if (!isRefundIdLoading) {
      if (isEmpty(refund)) {
        return null;
      }

      const items: LabelValueLinkItem[] = [
        {
          label: 'Created Date',
          value: toDateShort(refund?.createDate),
        },
        {
          label: 'Last Modified Date',
          value: toDateShort(refund?.modifyDate),
        },
        {
          label: 'Settled Date',
          value: toDateShort(refund?.settledDate),
        },
        {
          label: 'Payment Method',
          value: toDateShort(refund.paymentMethodId),
        },
      ];

      refund?.gatewayReference &&
        items.push({
          label: 'Gateway Reference No.',
          value: refund?.gatewayReference,
        });
      refund?.description &&
        items.push({
          label: 'Description',
          value: refund?.description,
        });
      refund?.errorCode &&
        items.push({
          label: 'Error Code',
          value: refund?.errorCode,
        });
      refund.errorMessage &&
        items.push({
          label: 'Error Message',
          value: refundReadableErrorMessage[refund.errorMessage],
        });

      return <DynamicLabelValueBanner title="Refund Details" items={items} />;
    }
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }, [isRefundIdLoading, refund]);

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <MPageContainer height="auto">
      <MPageHeader
        hasBackButton
        title="View Refund"
        status={refund?.status ? REFUND_STATUS_DISPLAY[refund?.status] : ''}
        parentLink={ROUTES.getAccountPaymentsRoute(accountId)}
        parentLinkTitle={accountId && accountDetails?.accountName}
        copyUrl
        backButtonCallback={handleBackNavigation}
        id={refund?.id}
      >
        {refund && <RefundActions refund={refund} />}
      </MPageHeader>

      <MBox
        width="100%"
        px={[1, 1, 8]}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {DisplayBannerComponent}
        <MBox
          width="100%"
          mt={6}
          borderWidth={0.5}
          borderColor="tBlue.hover"
          background="tWhite.titanWhite"
        >
          <HtmlIFrameContentRenderer
            id={'refund-content'}
            html={data}
            isLoading={isLoading}
            isReFetching={isPending || isRefetching}
          ></HtmlIFrameContentRenderer>
        </MBox>
      </MBox>
    </MPageContainer>
  );
};
