import { MFlex } from '../../../../../components/Monetize';
import { UserAvatarAndName } from '../../../../../components/Monetize/MCustomSelect/components/UserAvatarAndName';
import { IUser } from '../../../../../types';

export const MemberLeftElementContent = ({ value }: { value: IUser[] }) => {
  if (!value?.length) {
    return null;
  }

  return (
    <MFlex
      width={'max-content'}
      alignItems={'center'}
      position="inherit"
      left={0}
    >
      {value?.length === 1 && (
        <UserAvatarAndName
          userName={value[0].name}
          isAvatarOnly
          textProps={{ fontSize: 'sm' }}
        />
      )}
    </MFlex>
  );
};
