import DiscountsAppliedTooltip from '../../routes/Quotes/Quote/components/DiscountsAppliedTooltip';
import { IDiscountSchema } from '../../types';
import { getDiscountText } from '../../utils';
import { MTooltip } from './chakra';
import MText from './MText';

interface MDiscountCellProps {
  discounts: IDiscountSchema[];
}

const getItemDiscountText = (discounts: IDiscountSchema[]) => {
  if (discounts.length > 1) {
    return 'Multiple';
  }
  if (discounts.length > 0) {
    const [{ discountAmount, discountType }] = discounts;
    return getDiscountText(discountAmount, discountType);
  }
  return '';
};

export const MDiscountCell = (props: MDiscountCellProps) => {
  const { discounts = [] } = props;

  return (
    <MTooltip
      isDisabled={discounts.length < 2}
      label={<DiscountsAppliedTooltip discounts={discounts} />}
      placement="right-end"
    >
      <MText textAlign="left">{getItemDiscountText(discounts)}</MText>
    </MTooltip>
  );
};
