import { zodResolver } from '@hookform/resolvers/zod';
import { FunctionComponent as FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useGetListData } from '../../../../../api/queryUtils';
import {
  ILegalEntitiesSelector,
  selectLegalEntities,
} from '../../../../../api/selectors';
import { useCurrencies } from '../../../../../hooks/useCurrencies';
import {
  AccountSchema,
  AccountStatusEnum,
  DEFAULT_PAGER,
  IAccountSchema,
} from '../../../../../types';
import { ILegalEntityResponseSchema } from '../../../../../types/legalEntityTypes';
import AccountForm from '../../../../Accounts/AccountForm';

interface NewAccountFormProps {
  onChange: (account: IAccountSchema) => void;
}

const NewAccountForm: FC<NewAccountFormProps> = ({
  onChange,
}: NewAccountFormProps) => {
  const { defaultCurrency } = useCurrencies();
  const { data: legalEntitiesData } = useGetListData<
    ILegalEntityResponseSchema,
    ILegalEntitiesSelector
  >(
    'legalEntities',
    { config: DEFAULT_PAGER },
    {
      select: selectLegalEntities,
    },
  );

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IAccountSchema>({
    resolver: zodResolver(AccountSchema),
    mode: 'onChange',
    defaultValues: {
      status: AccountStatusEnum.ACTIVE,
      defaultCurrency,
      defaultDunningProcessId: null,
      isTestAccount: false,
      customFields: {},
      taxEntityUseCode: null,
      taxExemptionNumber: null,
    },
    values: {
      defaultLegalEntityId: legalEntitiesData?.defaultLegalEntity?.id!,
      defaultCurrency,
      defaultDunningProcessId: null,
      isTestAccount: false,
      accountName: '',
      status: AccountStatusEnum.ACTIVE,
      customId: '',
      customFields: {},
      taxEntityUseCode: null,
      taxExemptionNumber: null,
    },
  });

  const accountName = watch('accountName');
  const isTestAccount = watch('isTestAccount');
  const customId = watch('customId');
  const status = watch('status');
  const currency = watch('defaultCurrency');
  const defaultLegalEntityId = watch('defaultLegalEntityId');
  const customFields = watch('customFields');
  const taxEntityUseCode = watch('taxEntityUseCode');
  const taxExemptionNumber = watch('taxExemptionNumber');
  const defaultDunningProcessId = watch('defaultDunningProcessId');

  useEffect(() => {
    onChange({
      accountName,
      isTestAccount,
      customId,
      status,
      defaultCurrency: currency,
      defaultLegalEntityId,
      customFields,
      taxEntityUseCode,
      taxExemptionNumber,
      defaultDunningProcessId,
    });
  }, [
    accountName,
    isTestAccount,
    customId,
    status,
    currency,
    defaultLegalEntityId,
    customFields,
    taxEntityUseCode,
    taxExemptionNumber,
    defaultDunningProcessId,
  ]);

  return (
    <AccountForm
      control={control}
      errors={errors}
      setValue={setValue}
      watch={watch}
      legalEntities={legalEntitiesData?.legalEntities}
    />
  );
};

export default NewAccountForm;
