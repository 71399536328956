import { Heading, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { toDateOnly, toDateShort } from '@monetize/utils/core';
import { addDays } from 'date-fns/addDays';
import { formatISO } from 'date-fns/formatISO';
import { isBefore } from 'date-fns/isBefore';
import { parseISO } from 'date-fns/parseISO';
import isString from 'lodash/isString';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '../../api/axios';
import {
  doRenewContract,
  useGetOrCreateOpportunityWithCrmLink,
} from '../../api/cpqService';
import { useGetById } from '../../api/queryUtils';
import { getQuoteEditRoute } from '../../constants/routes';
import {
  ContractEndActionEnum,
  ContractRenewalUi,
  ContractRenewalUiSchema,
  CrmOpportunityForInput,
  IAccountDetails,
  IContract,
  IContractRenewalReqSchema,
  IOpportunity,
  IQuoteRespSchema,
  Maybe,
  ProductInclusionEnum,
  ProductTypeEnum,
  SubscriptionTimingEnum,
} from '../../types';
import {
  MBox,
  MButton,
  MCenterModal,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MRadio,
  MRadioGroup,
  MSkeleton,
  MStack,
  MText,
} from '../Monetize';
import { DatePicker } from '../Monetize/DatePicker/DatePicker';
import { ContractLengthPopover } from './ContractLengthPopover';

interface RenewalModalProps {
  contract: IContract;
  crmOpportunity?: CrmOpportunityForInput | null;
  existingOpportunityId?: Maybe<string>;
  account?: IAccountDetails | '';
  onClose: () => void;
}

export const RenewalModal = ({
  contract,
  crmOpportunity,
  existingOpportunityId,
  account,
  onClose,
}: RenewalModalProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: doCreateOrGetOpportunity } =
    useGetOrCreateOpportunityWithCrmLink();

  const { data: quote, isFetching } = useGetById<IQuoteRespSchema>(
    'cpqServiceQuotes',
    contract.latestQuoteId,
    {
      enabled: !!contract.latestQuoteId,
      retry: false,
      refetchOnWindowFocus: false,
      meta: { showErrorToast: true },
    },
  );

  const getInitialRenewalDate = (): string => {
    const today = new Date();
    return isBefore(contract.startDate, today)
      ? contract.startDate
      : formatISO(today);
  };

  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ContractRenewalUi>({
    resolver: zodResolver(ContractRenewalUiSchema),
    mode: 'onChange',
    defaultValues: {
      newQuoteType: ContractEndActionEnum.RENEW,
      renewalDate: getInitialRenewalDate(),
      productInclusion: ProductInclusionEnum.AS_OF_RENEWAL_DATE,
    },
  });

  const watchNewQuoteType = watch('newQuoteType');
  const watchRenewalDateStr = watch('renewalDate');
  const watchRenewalDate = useMemo(
    () => toDateShort(watchRenewalDateStr ?? new Date()),
    [watchRenewalDateStr],
  );
  const contractEndDate = toDateShort(contract.endDate);

  const handleRenewContract = async () => {
    setIsLoading(true);
    try {
      let payload: {
        contractRenewalOptions?: IContractRenewalReqSchema;
        opportunity?: Partial<Pick<IOpportunity, 'id' | 'name' | 'customId'>>;
      } = {};

      let opportunityId = existingOpportunityId;

      const { newQuoteType, renewalDate, productInclusion, contractLength } =
        getValues();

      if (crmOpportunity && account && !isString(account)) {
        const opportunity = await doCreateOrGetOpportunity({
          customId: crmOpportunity.id,
          createPayload: {
            accountId: account.id,
            name: crmOpportunity.name,
            customId: crmOpportunity.id,
          },
        });
        opportunityId = opportunity.id;
      }

      if (newQuoteType === ContractEndActionEnum.EARLY_RENEWAL) {
        payload = {
          contractRenewalOptions: {
            newQuoteType: newQuoteType,
            renewalDate: renewalDate ? toDateOnly(renewalDate) : undefined,
            includeSubscriptionsAsOfRenewalDate:
              productInclusion === ProductInclusionEnum.AS_OF_RENEWAL_DATE,
            contractLength: contractLength,
          },
        };
      }

      if (opportunityId) {
        payload.opportunity = opportunityId ? { id: opportunityId } : undefined;
      }

      const renewedQuote = await doRenewContract(contract.id, payload);
      renewedQuote?.id &&
        navigate({
          pathname: getQuoteEditRoute(renewedQuote.id),
        });
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  const hasUsageOrArrears = useMemo(() => {
    return (
      quote?.quoteOfferings?.some(
        (offering) =>
          offering?.subscriptionTiming === SubscriptionTimingEnum.ARREARS ||
          offering.items.some(
            (item) => item.productType === ProductTypeEnum.USAGE,
          ),
      ) ?? false
    );
  }, [quote?.quoteOfferings]);

  return (
    <MCenterModal
      modalHeaderProps={{ px: 6, py: 4 }}
      modalBodyProps={{ px: 6, py: 4 }}
      renderModalTitleActions={() => (
        <Heading fontSize="18px" color="tPurple.base">
          Renew Contract
        </Heading>
      )}
      isOpen
      size="lg"
      onClose={onClose}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            type="submit"
            minW="auto"
            isLoading={isLoading}
            onClick={handleRenewContract}
          >
            Next
          </MButton>
        </MStack>
      )}
    >
      <MGrid gap={4} templateColumns="1">
        <MGridItem>
          <MText color="tGray.darkGrayPurple">
            This contract term is {''}
            {toDateShort(contract.startDate)} {''}
            to {''}
            {contractEndDate}.
          </MText>
        </MGridItem>
        <MGridItem>
          <Stack direction="row">
            <MFlex gap="2" align="center">
              <MText>Renew Contract for another </MText>
              <ContractLengthPopover
                contractLength={12}
                isCustomContractLength
                handleValueUpdate={(value) => setValue('contractLength', value)}
              />
              <MText fontWeight="bold">Months</MText>
            </MFlex>
          </Stack>
        </MGridItem>
        <MGridItem>
          <MGrid templateColumns="0.08fr 1fr">
            <MGridItem>
              <MText>On:</MText>
            </MGridItem>
            <MGridItem>
              <MFormField error={errors?.newQuoteType} mb={2} mt={0.5}>
                <Controller
                  name="newQuoteType"
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <MRadioGroup
                      onChange={(value) => {
                        onChange(value);
                        if (value === ContractEndActionEnum.EARLY_RENEWAL) {
                          setValue(
                            'productInclusion',
                            ProductInclusionEnum.AS_OF_RENEWAL_DATE,
                          );
                        }
                      }}
                      {...rest}
                    >
                      <MStack direction="column">
                        <MRadio value={ContractEndActionEnum.RENEW}>
                          End of Contract Term - After {contractEndDate}
                        </MRadio>
                        <MRadio value={ContractEndActionEnum.EARLY_RENEWAL}>
                          Early Renewal Date - Before {contractEndDate}
                        </MRadio>
                      </MStack>
                    </MRadioGroup>
                  )}
                />
              </MFormField>
              {watchNewQuoteType === ContractEndActionEnum.EARLY_RENEWAL && (
                <MFormField error={errors?.renewalDate} pl="5" mb={4}>
                  <Controller
                    name="renewalDate"
                    control={control}
                    render={({ field }) => (
                      <MBox maxWidth="11rem">
                        <DatePicker
                          minDate={parseISO(contract.startDate)}
                          maxDate={addDays(parseISO(contract.endDate), -1)}
                          mode={hasUsageOrArrears ? 'MONTH' : 'DATE'}
                          {...field}
                          onChange={(data) => {
                            setValue(
                              'newQuoteType',
                              ContractEndActionEnum.EARLY_RENEWAL,
                            );
                            setValue('renewalDate', data ?? undefined, {
                              shouldDirty: true,
                            });
                          }}
                        />
                      </MBox>
                    )}
                  />
                </MFormField>
              )}

              {watchNewQuoteType === ContractEndActionEnum.EARLY_RENEWAL &&
                isFetching && <SkeletonLoader />}

              {watchNewQuoteType === ContractEndActionEnum.EARLY_RENEWAL &&
                !isFetching && (
                  <MFormField error={errors?.productInclusion} mb={2} mt={0.5}>
                    <MText fontWeight={600} mb={2}>
                      Include Products as of:
                    </MText>
                    <Controller
                      name="productInclusion"
                      control={control}
                      render={({ field: { ...rest } }) => (
                        <MRadioGroup {...rest}>
                          <MStack direction="column">
                            <MRadio
                              value={ProductInclusionEnum.AS_OF_RENEWAL_DATE}
                            >
                              Early Renewal Date ({watchRenewalDate})
                            </MRadio>
                            <MRadio
                              value={ProductInclusionEnum.END_OF_CONTRACT_DATE}
                            >
                              End of Current Contract ({contractEndDate})
                            </MRadio>
                          </MStack>
                        </MRadioGroup>
                      )}
                    />
                  </MFormField>
                )}
            </MGridItem>
          </MGrid>
        </MGridItem>
        {watchNewQuoteType === ContractEndActionEnum.EARLY_RENEWAL && (
          <MGridItem>
            <MText color="tGray.darkGrayPurple">
              This renews the contract on {watchRenewalDate} and ends the
              existing contract the day before this date.
            </MText>
          </MGridItem>
        )}
      </MGrid>
    </MCenterModal>
  );
};

const SkeletonLoader = () => {
  return Array.from({ length: 5 }, (_, index) => (
    <MSkeleton key={index} height="1rem" width="70%" mt={2} />
  ));
};
