import { ESignRecipient, IQuoteContacts } from '@monetize/types/app';
import { toDateTimeShort } from '@monetize/utils/core';
import { parseISO } from 'date-fns/parseISO';
import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import {
  MdCheck,
  MdCheckCircle,
  MdEmail,
  MdLens,
  MdRefresh,
  MdRemoveCircle,
} from 'react-icons/md';
import { getMessageFromError } from '../../../api/axios';
import { useGetEsignRecipients } from '../../../api/cpqService';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MIcon,
  MSkeleton,
  MText,
  MTooltip,
} from '../../Monetize';

function getIcon(
  isComplete: boolean,
  isFinalStep: boolean,
): { icon: IconType; color: string } {
  if (isComplete && isFinalStep) {
    return {
      icon: MdCheckCircle,
      color: 'tGreen.approval',
    };
  }
  if (isComplete) {
    return {
      icon: MdCheck,
      color: 'tGreen.approval',
    };
  }
  return {
    icon: MdLens,
    color: 'tGray.back',
  };
}

function getActionLabels(recipient: ESignRecipient, isCC: boolean) {
  const output: { label: string; icon: IconType; color: string }[] = [];

  output.push({
    label: !recipient.sentDateTime
      ? 'Not sent'
      : `Sent at ${toDateTimeShort(parseISO(recipient.sentDateTime))}`,
    ...getIcon(!!recipient.sentDateTime, false),
  });

  output.push({
    label: !recipient.deliveredDateTime
      ? 'Not opened'
      : `Opened at ${toDateTimeShort(parseISO(recipient.deliveredDateTime))}`,
    ...getIcon(!!recipient.deliveredDateTime, false),
  });

  if (!isCC) {
    output.push({
      label: !recipient.signedDateTime
        ? 'Not signed'
        : `Signed at ${toDateTimeShort(parseISO(recipient.signedDateTime))}`,
      ...getIcon(!!recipient.signedDateTime, true),
    });
  }

  if (!isCC && recipient.declinedDateTime) {
    output.push({
      label: `Declined at ${toDateTimeShort(parseISO(recipient.declinedDateTime))}`,
      icon: MdRemoveCircle,
      color: 'tRed.base',
    });
  }
  return output;
}

interface QuoteStepEsignRecipientsProps {
  quoteId: string;
  contacts: IQuoteContacts;
}

export const QuoteStepEsignRecipients = React.forwardRef<
  any,
  QuoteStepEsignRecipientsProps
>(({ quoteId, contacts }: QuoteStepEsignRecipientsProps, ref) => {
  const {
    isLoading,
    data: esignRecipients,
    error,
    refetch,
    isRefetching,
    dataUpdatedAt,
  } = useGetEsignRecipients(quoteId, {
    refetchOnWindowFocus: false,
    gcTime: 1000 * 60 * 10, // 5 minutes
    placeholderData: (previousData, previousQuery) => previousData,
  });

  const lastUpdatedAt = useMemo(
    () =>
      toDateTimeShort(
        dataUpdatedAt ? new Date(dataUpdatedAt) : new Date(),
        'userTimezone',
      ),
    [dataUpdatedAt],
  );

  const errorMessage = useMemo(() => {
    if (!error) {
      return null;
    }
    return getMessageFromError(error, 'Failed to load eSign recipients');
  }, [error]);

  if (isLoading) {
    return <LoadingSkeleton contacts={contacts} />;
  }

  if (errorMessage) {
    return <MText color="tRed.base">{errorMessage}</MText>;
  }

  if (!esignRecipients) {
    return null;
  }

  return (
    <MFlex w="100%" flexDirection="column">
      {!!esignRecipients.signers.length && (
        <MFlex justifyContent="space-between" alignItems="center">
          <MText fontWeight="600" fontSize="md">
            Signers
          </MText>
          <MTooltip label={`Last updated at ${lastUpdatedAt}`}>
            <MCustomIconButton
              btnSize={4}
              variant="icon"
              icon={MdRefresh}
              iconColor="tPurple.base"
              isLoading={isRefetching}
              isDisabled={isRefetching}
              onClick={() => refetch()}
            />
          </MTooltip>
        </MFlex>
      )}
      {esignRecipients.signers.map((recipient) => (
        <RecipientItem key={recipient.id} recipient={recipient} />
      ))}
      {!!esignRecipients.ccContacts.length && (
        <MText fontWeight="600" fontSize="md" mt={2}>
          CC Recipients
        </MText>
      )}
      {esignRecipients.ccContacts.map((recipient) => (
        <RecipientItem key={recipient.id} recipient={recipient} isCC />
      ))}
    </MFlex>
  );
});

const RecipientItem = ({
  recipient,
  isCC = false,
}: {
  recipient: ESignRecipient;
  isCC?: boolean;
}) => {
  return (
    <MFlex position="relative" flexDirection="column" mt={1} ml={1}>
      <MFlex>
        <MText fontWeight="600" noOfLines={2}>
          {recipient.name}
        </MText>
        <MTooltip label={recipient.email} placement="top-start">
          <MBox>
            <MIcon as={MdEmail} ml={1} />
          </MBox>
        </MTooltip>
      </MFlex>
      {getActionLabels(recipient, isCC).map(({ label, color, icon }) => (
        <MFlex key={label}>
          <MIcon as={icon} color={color} mt={0.5} mr={1} />
          <MText size="xs">{label}</MText>
        </MFlex>
      ))}
    </MFlex>
  );
};

const LoadingSkeleton = ({ contacts }: { contacts: IQuoteContacts }) => {
  return (
    <MBox padding="2" w="100%">
      <MFlex flexDirection="column" w="100%">
        <MSkeleton height="25px" width="50%" mt={2} />
        {contacts.esign.map((_, i) => (
          <MBox key={i} ml={2}>
            <MSkeleton height="21px" width="75%" mt={2} />
            <MFlex>
              <MSkeleton height="20px" width="16px" mt={2} mr={1} />
              <MSkeleton height="20px" width="80%" mt={2} />
            </MFlex>
          </MBox>
        ))}
      </MFlex>

      <MFlex flexDirection="column" w="100%" mt={2}>
        <MSkeleton height="25px" width="50%" mt={2} />
        <MBox ml={2}>
          <MSkeleton height="21px" width="75%" mt={2} />
          <MFlex>
            <MSkeleton height="20px" width="16px" mt={2} mr={1} />
            <MSkeleton height="20px" width="80%" mt={2} />
          </MFlex>
        </MBox>
      </MFlex>
    </MBox>
  );
};
