import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

/**
 * {@link https://chakra-ui.com/docs/components/card/theming}
 */

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {
    borderRadius: 4,
    backgroundColor: 'tWhite.base',
  },
  header: {},
  body: {
    padding: '4',
  },
  footer: {},
});

const variants = {
  borderless: definePartsStyle({
    container: {
      boxShadow: undefined,
    },
  }),
  border: definePartsStyle({
    container: {
      borderColor: 'tGray.lightPurple',
      borderRadius: '0.25rem',
      borderWidth: '1px',
    },
  }),
  edit: definePartsStyle({
    header: {
      py: 2,
      px: 2,
      mx: 2,
      borderBottomWidth: '1px',
      borderBottomColor: 'tBlue.hover',
      borderBottomStyle: 'solid',
    },
    container: {
      backgroundColor: 'tWhite.base',
      border: '1px solid #E3E8EC',
      borderRadius: '4px',
    },
  }),
  entity: definePartsStyle({
    container: {
      cursor: 'pointer',
      border: '1px solid #EAECF9',
      boxShadow: '0px 1px 16px rgba(200, 197, 212, 0.15)',
      minWidth: '12.5rem',
      height: '11.25rem',
    },
    body: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      _hover: {
        bgColor: 'tBlue.hover',
      },
    },
  }),
};

const defaultProps = {
  variant: 'outline',
  size: 'md',
} as any;

export const CardTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps,
});
