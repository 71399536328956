import { usePrintInvoiceToHtml } from '../../api/accountsService';
import { IInvoiceRespSchema } from '../../types';
import { HtmlIFrameContentRenderer } from '../Monetize/HtmlIFrameContentRenderer';

interface InvoiceHtmlProps {
  invoice: IInvoiceRespSchema;
}

export const InvoiceHtml = ({ invoice }: InvoiceHtmlProps) => {
  const { data, isLoading, isRefetching, error, isPending } =
    usePrintInvoiceToHtml({
      invoiceId: invoice.id,
      lastModifiedTimestamp: invoice.modifyDate,
    });

  if (error) {
    // TODO: add error state
    return null;
  }

  return (
    <HtmlIFrameContentRenderer
      id="invoice-content"
      html={data}
      isLoading={isLoading}
      isReFetching={isPending || isRefetching}
    ></HtmlIFrameContentRenderer>
  );
};
