import { TeamTypeEnum } from '../types';
import { objectToSelectOptions } from '../utils/misc';

export const TEAM_TYPE_ENUM_DISPLAY: {
  [key in TeamTypeEnum]: string;
} = {
  SALES: 'Sales',
  APPROVAL: 'Approval',
};

export const TEAM_TYPE_DISPLAY_OPTIONS = objectToSelectOptions(
  TEAM_TYPE_ENUM_DISPLAY,
);
