import { formatInteger } from '@monetize/utils/core';
import { Maybe } from '../../types';
import { MButton, MCenter, MText } from './';

interface LoadMoreProps {
  fetchedElementLength: number;
  totalElements?: number;
  isLoading?: boolean;
  containerElement?: Maybe<HTMLElement>;
  scrollToTopElement?: Maybe<HTMLElement>;
  hasMoreItem?: boolean;
  onLoadMore: () => void;
}

export const LoadMore = ({
  fetchedElementLength,
  totalElements,
  isLoading,
  containerElement,
  scrollToTopElement,
  hasMoreItem,
  onLoadMore,
}: LoadMoreProps) => {
  const scrollToTop = () => {
    scrollToTopElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      {totalElements && (
        <>
          <MText align="center" fontSize="sm" fontWeight="bold" mt="1rem">
            {`Showing ${formatInteger(fetchedElementLength)} of ${formatInteger(
              totalElements,
            )}`}
          </MText>
          {totalElements > fetchedElementLength && (
            <MCenter>
              <MButton
                variant="tertiary"
                isLoading={isLoading}
                onClick={onLoadMore}
              >
                Load More
              </MButton>
            </MCenter>
          )}
        </>
      )}
      {!totalElements && (
        <>
          <MText align="center" fontSize="sm" fontWeight="bold" mt="1rem">
            {`Showing ${formatInteger(fetchedElementLength)} ${fetchedElementLength > 1 ? 'items' : 'item'}`}
          </MText>
          {hasMoreItem && (
            <MCenter>
              <MButton
                variant="tertiary"
                isLoading={isLoading}
                onClick={onLoadMore}
              >
                Load More
              </MButton>
            </MCenter>
          )}
        </>
      )}

      {containerElement &&
        scrollToTopElement &&
        containerElement.scrollHeight > containerElement.clientHeight && (
          <MCenter>
            <MButton variant="tertiary" onClick={scrollToTop} mt=".5rem">
              Back to Top
            </MButton>
          </MCenter>
        )}
    </>
  );
};
