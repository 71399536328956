import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants';

// NOTE: If there is no browser history to go back to, we should go to main dashboard page
// By 'stateIdx' let the component decide when to navigate back, use stateIdx=1 if the component render by redirect url
// otherwise use the default value
// TICKET: https://monetizenow.atlassian.net/browse/BP-8221
export const useBackNavigate = (stateIdx: number = 0) => {
  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.history.state && window.history.state.idx > stateIdx) {
      navigate(-1);
    } else {
      navigate(ROUTES.DASHBOARD, { replace: true });
    }
  };
  return { navigate, navigateBack };
};
