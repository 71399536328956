import { useDragLayer } from 'react-dnd';
import { MBox } from '../components/Monetize';
import { DraggableItem } from '../types';
import { QuoteOfferingDraggablePreview } from './Quotes/Quote/components/quoteOffering/QuoteOfferingDraggablePreview';

/**
 * Full-screen drag preview for drag/drop
 *
 * {@link https://codesandbox.io/s/magical-moon-t29gde}
 */
export const DragAndDropCustomDragLayer = () => {
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  );

  function renderItem() {
    switch (itemType) {
      case DraggableItem.QUOTE_OFFERING:
        return (
          <QuoteOfferingDraggablePreview quoteOffering={item?.quoteOffering} />
        );
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }

  return (
    <MBox
      position="fixed"
      pointerEvents="none"
      zIndex={100}
      left={0}
      top={0}
      width="100%"
      height="100%"
    >
      <MBox
        transform={
          currentOffset && currentOffset
            ? `translate(${currentOffset.x}px, ${currentOffset.y}px)`
            : undefined
        }
      >
        {renderItem()}
      </MBox>
    </MBox>
  );
};
