import { useRecoilState } from 'recoil';
import {
  ContractStatusEnum,
  IContractWithQuotes,
  IQuoteRespSchema,
} from '../../../../types';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function ContractIsCancelledBanner({
  contract,
  quote,
}: {
  contract: IContractWithQuotes;
  quote: IQuoteRespSchema;
}) {
  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'ContractIsCancelledBanner']),
  );

  if (contract.status !== ContractStatusEnum.CANCELED || dismissed) {
    return null;
  }

  return (
    <QuoteBannerItem
      onDismiss={() => {
        setDismissed(true);
      }}
      type="info"
    >
      This Contract has been Canceled and cannot be renewed.
    </QuoteBannerItem>
  );
}
