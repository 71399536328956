import { useRecoilState } from 'recoil';
import { useCurrencies } from '../../../hooks/useCurrencies';
import { currencyFilterState } from '../../../store/global.store';

export const useDashboardCurrencyFilter = () => {
  const [currency, setCurrency] = useRecoilState(currencyFilterState);
  const { defaultCurrency } = useCurrencies();

  return {
    currency: currency || defaultCurrency,
    setCurrency,
  };
};
