import { zodResolver } from '@hookform/resolvers/zod';
import { formatCurrency, toDateShort } from '@monetize/utils/core';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useVoidCredit } from '../../../../api/accountsService';
import { useGetAggregatedTransactionApplicationsWithInvoices } from '../../../../api/transactableService';
import {
  MButton,
  MCenterModal,
  MDivider,
  MFormField,
  MFormFieldReadOnly,
  MGrid,
  MGridItem,
  MSkeleton,
  MStack,
  MText,
  MTextarea,
} from '../../../../components/Monetize';
import {
  IVoidCreditSchema,
  TransactableSourceType,
  VoidCreditSchema,
} from '../../../../types';

export const VoidModal = ({
  voidReason = '',
  creditId,
  creditNoteId,
  amount,
  currency,
  sourceType,
  accountId,
  billGroupId,
  onClose,
}: {
  voidReason?: string;
  creditId: string;
  amount: number;
  currency: string;
  sourceType: TransactableSourceType;
  accountId: string;
  billGroupId?: string;
  creditNoteId?: string | null;
  onClose: () => void;
}) => {
  const creditType =
    sourceType === TransactableSourceType.creditNote
      ? 'creditNotes'
      : 'credits';

  const sourceTypeId =
    (sourceType === TransactableSourceType.creditNote
      ? creditNoteId
      : creditId) ?? '';

  const { mutateAsync: doVoid, isPending: isLoading } = useVoidCredit(
    creditType,
    sourceTypeId,
    accountId,
  );

  /**
   * FIXME: we do not yet support credit notes applying directly to invoices,
   * so we use the related credit for now.
   */
  const applicationsSourceType =
    sourceType === TransactableSourceType.creditNote
      ? TransactableSourceType.credit
      : sourceType;

  const {
    data: aggregationResponse,
    isLoading: isAggregationLoading,
    isError,
  } = useGetAggregatedTransactionApplicationsWithInvoices(
    {
      accountId,
      sourceId: creditId,
      sourceType: applicationsSourceType,
      currency,
      billGroupId,
    },
    {
      refetchOnWindowFocus: false,
      meta: { showErrorToast: true },
    },
  );

  useEffect(() => {
    if (isError) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
  } = useForm<IVoidCreditSchema>({
    resolver: zodResolver(VoidCreditSchema),
    defaultValues: {
      reason: voidReason ?? '',
    },
  });

  const handleVoidingAction = async (data: IVoidCreditSchema) => {
    await doVoid(data);
    onClose();
  };

  const entityType =
    sourceType === TransactableSourceType.creditNote ? 'Credit Note' : 'Credit';

  const modalTitle = `Void ${entityType}`;

  return (
    <MCenterModal
      size="lg"
      isOpen
      modalTitle={modalTitle}
      onClose={onClose}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="end"
          flex={1}
        >
          <MButton
            onClick={onClose}
            variant="cancel"
            minW="auto"
            isDisabled={isLoading}
          >
            Cancel
          </MButton>
          <MButton
            onClick={handleSubmit(handleVoidingAction)}
            isDisabled={!isDirty || !isValid}
            isLoading={isLoading}
            type="submit"
            minW="auto"
          >
            Confirm
          </MButton>
        </MStack>
      )}
    >
      <MGrid gridTemplateColumns="1fr 1fr" columnGap={4} rowGap={4}>
        <MGridItem>
          <MFormFieldReadOnly label="Amount">
            {formatCurrency(amount, {
              currency,
            })}
          </MFormFieldReadOnly>
        </MGridItem>
        <MGridItem>
          <MFormFieldReadOnly label="Void Date">
            {toDateShort(new Date())}
          </MFormFieldReadOnly>
        </MGridItem>

        <MGridItem colSpan={2}>
          <MFormField label="Reason" isRequired>
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <MTextarea width="full" {...field} maxLength={255}></MTextarea>
              )}
            />
          </MFormField>
        </MGridItem>
        {isAggregationLoading && (
          <MGridItem colSpan={2}>
            <MSkeleton w="100%" height={8} />
          </MGridItem>
        )}
        {(
          aggregationResponse?.aggregateTransactable?.aggregatedApplications ??
          []
        ).length > 0 &&
          !isAggregationLoading && (
            <>
              <MGridItem colSpan={2}>
                <MDivider></MDivider>
              </MGridItem>
              <MGridItem colSpan={2}>
                <MText>
                  Upon Voiding this {entityType},{' '}
                  {formatCurrency(
                    aggregationResponse?.aggregateTransactable?.appliedAmount,
                    { currency: currency },
                  )}{' '}
                  previously applied to Invoices will be unapplied.
                </MText>
              </MGridItem>
            </>
          )}
      </MGrid>
    </MCenterModal>
  );
};
