import { FunctionComponent as FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../../constants';
import { useToast } from '../../../../services/toast';
import { toTitleCase } from '../../../../utils';

interface CrmAuthCallbackProps {}

/**
 * Show toast message and redirect to CRM settings page
 *
 * Examples:
 * /settings/crm/callback?type=salesforce&errorMessage=Unable%20to%20connect%20to%20Salesforce
 * /settings/crm/callback?type=hubspot&errorMessage=
 */
export const CrmAuthCallback: FC<CrmAuthCallbackProps> = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { addToast } = useToast();

  const type = toTitleCase(searchParams.get('type') || 'salesforce');
  const errorMessage = searchParams.get('errorMessage') || null;

  useEffect(() => {
    if (errorMessage) {
      addToast({
        summary: `CRM Connection Failed`,
        detail: `There was an error connecting to ${type}. Please try again.`,
        severity: 'error',
      });
    } else {
      addToast({
        summary: `CRM Connected`,
        detail: `${type} has been successfully connected.`,
        severity: 'info',
      });
    }

    navigate(ROUTES.SETTINGS_CRM, {});
  }, []);

  return null;
};
