import { FC } from 'react';
import { MdLock } from 'react-icons/md';
import {
  MIcon,
  MPageHeader,
  MTooltip,
  MVStack,
} from '../../../components/Monetize';
import { BILL_GROUP_STATUS_DISPLAY } from '../../../constants/billGroups';
import { getAccountBillGroupsRoute } from '../../../constants/routes';
import { IBillGroupResp } from '../../../types';

interface BillGroupModalTitleProps {
  isLoading: boolean;
  isReadOnly?: boolean;
  accountId?: string | null;
  billGroup?: IBillGroupResp;
  accountName?: string;
}
export const BillGroupModalTitle: FC<BillGroupModalTitleProps> = ({
  isLoading,
  billGroup,
  isReadOnly = false,
  accountId,
  accountName = '',
}) => {
  return (
    <MVStack alignItems="flex-start">
      <MPageHeader
        title={
          isLoading
            ? ''
            : `${
                billGroup ? `${isReadOnly ? 'View' : 'Edit'}` : 'New'
              } Bill Group`
        }
        status={
          billGroup?.status
            ? BILL_GROUP_STATUS_DISPLAY[billGroup?.status]
            : undefined
        }
        tag={
          billGroup?.locked && billGroup?.activeContract ? (
            <MTooltip
              label="This bill group is part of a contract."
              shouldWrapChildren
              placement="bottom-start"
            >
              <MIcon
                mt="1"
                w={4}
                h={4}
                as={MdLock}
                alignSelf="center"
                style={{
                  cursor: 'pointer',
                }}
              />
            </MTooltip>
          ) : null
        }
        parentLink={
          accountId || billGroup?.accountId
            ? getAccountBillGroupsRoute(accountId || billGroup!.accountId)
            : undefined
        }
        parentLinkTitle={accountName}
        copyUrl
        id={billGroup?.id}
        containerProps={{ mb: 0 }}
        tagStyle={{ ml: 0 }}
      />
    </MVStack>
  );
};
