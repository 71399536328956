export const CollaborationIcon = (props: any) => (
  <svg
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.75625 10.5C6.14375 10.5 6.46875 10.3656 6.73125 10.0969C6.99375 9.82812 7.125 9.50625 7.125 9.13125C7.125 8.75625 6.99375 8.43437 6.73125 8.16562C6.46875 7.89687 6.15 7.7625 5.775 7.7625C5.3875 7.7625 5.05937 7.89687 4.79062 8.16562C4.52187 8.43437 4.3875 8.75625 4.3875 9.13125C4.3875 9.50625 4.52187 9.82812 4.79062 10.0969C5.05937 10.3656 5.38125 10.5 5.75625 10.5ZM9.24375 10.5C9.61875 10.5 9.94063 10.3656 10.2094 10.0969C10.4781 9.82812 10.6125 9.50625 10.6125 9.13125C10.6125 8.75625 10.4781 8.43437 10.2094 8.16562C9.94063 7.89687 9.61875 7.7625 9.24375 7.7625C8.86875 7.7625 8.54688 7.89687 8.27813 8.16562C8.00938 8.43437 7.875 8.75625 7.875 9.13125C7.875 9.50625 8.00938 9.82812 8.27813 10.0969C8.54688 10.3656 8.86875 10.5 9.24375 10.5ZM7.5 6.99375C7.875 6.99375 8.19688 6.8625 8.46563 6.6C8.73438 6.3375 8.86875 6.0125 8.86875 5.625C8.86875 5.25 8.73438 4.92812 8.46563 4.65937C8.19688 4.39062 7.875 4.25625 7.5 4.25625C7.125 4.25625 6.80312 4.39062 6.53437 4.65937C6.26562 4.92812 6.13125 5.25 6.13125 5.625C6.13125 6.0125 6.26562 6.3375 6.53437 6.6C6.80312 6.8625 7.125 6.99375 7.5 6.99375ZM7.5 15C6.475 15 5.50625 14.8031 4.59375 14.4094C3.68125 14.0156 2.88438 13.4781 2.20312 12.7969C1.52188 12.1156 0.984375 11.3188 0.590625 10.4062C0.196875 9.49375 0 8.51875 0 7.48125C0 6.45625 0.196875 5.4875 0.590625 4.575C0.984375 3.6625 1.52188 2.86875 2.20312 2.19375C2.88438 1.51875 3.68125 0.984375 4.59375 0.590625C5.50625 0.196875 6.48125 0 7.51875 0C8.54375 0 9.5125 0.196875 10.425 0.590625C11.3375 0.984375 12.1313 1.51875 12.8063 2.19375C13.4813 2.86875 14.0156 3.6625 14.4094 4.575C14.8031 5.4875 15 6.4625 15 7.5C15 8.525 14.8031 9.49375 14.4094 10.4062C14.0156 11.3188 13.4813 12.1156 12.8063 12.7969C12.1313 13.4781 11.3375 14.0156 10.425 14.4094C9.5125 14.8031 8.5375 15 7.5 15Z" />
  </svg>
);
