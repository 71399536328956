import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  MGrid,
  MGridItem,
  MPageContainer,
  MSettingsPageHeader,
  MText,
} from '../../../../components/Monetize';
import { DateDisplay } from '../../../../components/Monetize/DateDisplay';
import { ROUTES } from '../../../../constants';
import { useDocumentHead } from '../../../../services/documentHead';
import {
  IntegrationLogStatusEnum,
  TIntegrationLogResSchema,
} from '../../../../types/integrationLogTypes';

const IntegrationLogView = () => {
  const { setDocTitle } = useDocumentHead();
  const [searchParams] = useSearchParams();
  const [logData, setLogData] = useState<TIntegrationLogResSchema | null>(null);

  useEffect(() => {
    const logDataParam = searchParams.get('logData');
    if (logDataParam) {
      try {
        // Decode and parse
        const decoded = JSON.parse(decodeURIComponent(logDataParam));
        setLogData(decoded);
      } catch (error) {
        console.error('Error parsing logData from query param:', error);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setDocTitle('Settings', 'Integration Log View');
  }, []);

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader
        divider={false}
        hasBackButton
        backButtonTitle="Back to Integration Log"
        backButtonLink={ROUTES.SETTINGS_INTEGRATION_LOG}
        title="Integration Log Details"
      />

      <MGrid
        w="full"
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        }}
        gap={2}
      >
        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Timestamp
          </MText>
          <DateDisplay date={logData?.lastUpdatedAt} />
        </MGridItem>

        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Object
          </MText>
          <MText color="tPurple.base">{logData?.entityName}</MText>
        </MGridItem>

        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Operation
          </MText>
          <MText color="tPurple.base">{logData?.eventType}</MText>
        </MGridItem>

        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Label
          </MText>
          <MText color="tPurple.base">{logData?.label}</MText>
        </MGridItem>

        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Source Record ID
          </MText>
          <MText color="tPurple.base">{logData?.sourceId}</MText>
        </MGridItem>

        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Target Record ID
          </MText>
          <MText color="tPurple.base">{logData?.targetId ?? 'Blank'}</MText>
        </MGridItem>

        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Status
          </MText>
          <MText color="tPurple.base">{logData?.status}</MText>
        </MGridItem>

        <MGridItem p={4}>
          <MText fontWeight="bold" mb="0.25rem" color="tPurple.base">
            Error Message
          </MText>
          <MText color="tPurple.base">
            {logData?.status === IntegrationLogStatusEnum.FAILED
              ? logData?.log
              : 'Blank'}
          </MText>
        </MGridItem>
      </MGrid>
    </MPageContainer>
  );
};

export default IntegrationLogView;
