import { useState } from 'react';
import { useGetListData } from '../../../api/queryUtils';
import { PRODUCTS_ITEM_DEFAULT_PAGER } from '../../../routes/ProductCatalog/Offerings/components/Rate/RatePriceUtils';
import {
  IProduct,
  PercentOfTotalConfigTypeEnum,
  TDataTablePager,
} from '../../../types';
import { MBox, MButton, MSkeleton, MText } from '../../Monetize';

const ScopedProductsList = ({ productIds }: { productIds: string[] }) => {
  const [pager, setPager] = useState<TDataTablePager>({
    ...PRODUCTS_ITEM_DEFAULT_PAGER,
    rows: 3,
  });
  const {
    data: productList,
    isLoading: productListLoading,
    isFetching: productListRefetching,
  } = useGetListData<IProduct>(
    'productCatalogProducts',
    {
      config: pager,
      filters: {
        id: { in: productIds },
        sort: 'name',
      },
    },
    {
      placeholderData: (previousData, previousQuery) => previousData,
      refetchOnWindowFocus: false,
      enabled: productIds.length > 0,
    },
  );

  if (productListLoading) {
    return <MSkeleton h="3rem" />;
  }

  return (
    <>
      {productList?.content.map(({ name, id }) => {
        return <MText key={id}>{name}</MText>;
      })}

      {productList && productList.totalElements > 3 && (
        <MButton
          ml={-2.5}
          variant={'tertiary'}
          type="button"
          size="sm"
          fontWeight="400"
          isDisabled={productListRefetching}
          isLoading={productListRefetching}
          onClick={() => {
            if (pager.rows === 3) {
              setPager({
                ...pager,
                rows: productList.totalElements,
              });
            } else {
              setPager({ ...pager, rows: 3 });
            }
          }}
        >
          {pager.rows === 3 ? 'View More' : 'View Less'}
        </MButton>
      )}
    </>
  );
};

export const PricesDrawerScopedProducts = ({
  type,
  productIds,
}: {
  type: PercentOfTotalConfigTypeEnum;
  productIds?: string[] | null;
}) => {
  const isEligibleProducts = type === PercentOfTotalConfigTypeEnum.ALL_ELIGIBLE;

  return (
    <MBox ml="8">
      <MText fontWeight="bold" my="1">
        Scoped Products
      </MText>
      {isEligibleProducts && (
        <MText>All Subscription and Onetime Products</MText>
      )}
      {!isEligibleProducts && !!(productIds && productIds.length > 0) && (
        <ScopedProductsList productIds={productIds} />
      )}
    </MBox>
  );
};
