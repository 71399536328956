import { ButtonProps } from '@chakra-ui/react';
import MButton from './MButton';

export interface ExpandCollapseButtonProps
  extends Omit<ButtonProps, 'onChange' | 'onClick' | 'children'> {
  allExpanded: boolean;
  onChange: (allExpanded: boolean) => void;
}

export const ExpandCollapseButton = ({
  allExpanded,
  onChange,
  ...buttonProps
}: ExpandCollapseButtonProps) => {
  return (
    <MButton
      variant="tertiary"
      size="sm"
      onClick={() => onChange(!allExpanded)}
      {...buttonProps}
    >
      {allExpanded ? 'Collapse all' : 'Expand all'}
    </MButton>
  );
};
