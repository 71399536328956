import { ReactNode } from 'react';
import { MTooltip } from '../../../../../../components/Monetize';

export const TooltipOrChildren = ({
  isDisabled = false,
  label,
  children,
}: {
  label: ReactNode;
  isDisabled?: boolean;
  children: ReactNode;
}) => {
  if (isDisabled) {
    return (
      <MTooltip label={label} placement="bottom-start">
        {children}
      </MTooltip>
    );
  }

  return <>{children}</>;
};
