import { useRecoilState } from 'recoil';
import { IQuoteRespSchema, NewQuoteTypeEnum, QuoteTypeEnum } from '../../../../types/quoteTypes';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function EarlyRenewalBanner({ quote }: { quote: IQuoteRespSchema }) {
  const showBanner =
    quote?.type === QuoteTypeEnum.RENEWAL &&
    quote.newQuoteType === NewQuoteTypeEnum.EARLY_RENEWAL;

  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'EarlyRenewalBanner']),
  );

  if (!showBanner || dismissed) {
    return null;
  }

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="warning">
      Early Renewal: This quote qualifies as an Early Renewal, which will
      replace the existing contract. Once processed, the current contract will
      end, and billing will adjust accordingly. Submit and Process the Quote for
      the Cancelation to take effect.
    </QuoteBannerItem>
  );
}
