import { formatNumber } from '@monetize/utils/core';
import { FunctionComponent as FC } from 'react';
import {
  MGrid,
  MGridItem,
  MTag,
  MTagCloseButton,
  MTagLabel,
  MText,
} from '../../../../../components/Monetize';

interface UploadUsageFileSummaryProps {
  filename: string;
  rows: number;
  onRemoveFile: () => void;
}

export const UploadUsageFileSummary: FC<UploadUsageFileSummaryProps> = (
  props: UploadUsageFileSummaryProps,
) => {
  const { filename, rows, onRemoveFile } = props;

  return (
    <MGrid templateColumns="2.5rem 1fr" gap={4}>
      <MGridItem>
        <MText fontWeight="600" mr={2}>
          File:
        </MText>
      </MGridItem>
      <MGridItem>
        <MTag size="md" variant="upload">
          <MTagLabel>{filename}</MTagLabel>

          <MTagCloseButton
            data-testid="clear-item-btn"
            onClick={() => onRemoveFile()}
          />
        </MTag>
      </MGridItem>
      <MGridItem>
        <MText fontWeight="600">Rows:</MText>
      </MGridItem>
      <MGridItem>{formatNumber(rows, { maximumFractionDigits: 0 })}</MGridItem>
    </MGrid>
  );
};
