import { AccordionItemProps, AccordionProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useGetListData } from '../../../../api/queryUtils';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionItem,
  MAccordionPanel,
} from '../../../../components/Monetize';
import useQuotePrices from '../../../../hooks/useQuotePrices';
import { DEFAULT_PAGER, IOfferingSummaryResSchema } from '../../../../types';
import { arrayToObject } from '../../../../utils/misc';
import PricesSectionContent from './reviewQuote/PricesSectionContent';

interface QuoteFormPricesSectionProps extends AccordionProps {
  itemProps?: AccordionItemProps;
  quoteId: string;
  currency: string;
}

const QuoteFormPricesSection: FC<QuoteFormPricesSectionProps> =
  React.forwardRef<any, QuoteFormPricesSectionProps>(
    (
      {
        itemProps = {},
        quoteId,
        currency,
        ...rest
      }: QuoteFormPricesSectionProps,
      ref,
    ) => {
      const { quotePrices, loading } = useQuotePrices(quoteId);
      const { data: offeringsById = {} } = useGetListData<
        IOfferingSummaryResSchema,
        Record<string, IOfferingSummaryResSchema>
      >(
        'productCatalogOfferings',
        {
          config: DEFAULT_PAGER,
          filters: {
            id: { in: quotePrices.map(({ offering }) => offering.id) },
          },
        },
        {
          enabled: quotePrices.length > 0,
          gcTime: 1000 * 60 * 10, // 10 minutes
          staleTime: 1000 * 60 * 5, // 5 minutes
          select: ({ content }) => {
            return arrayToObject(content, 'id');
          },
        },
      );

      const isEmptyData =
        !quotePrices ||
        quotePrices.length === 0 ||
        quotePrices.every(({ schedule }) => schedule.length === 0);

      if (!loading && isEmptyData) {
        return null;
      }

      return (
        <MAccordion allowToggle defaultIndex={[]} {...rest}>
          <MAccordionItem {...itemProps}>
            {({ isExpanded }) => (
              <>
                <MAccordionCustomButton
                  isExpanded={isExpanded}
                  label="Prices"
                />
                <MAccordionPanel>
                  <PricesSectionContent
                    offeringsById={offeringsById}
                    currency={currency}
                    quotePrices={quotePrices}
                    loading={loading}
                  />
                </MAccordionPanel>
              </>
            )}
          </MAccordionItem>
        </MAccordion>
      );
    },
  );

export { QuoteFormPricesSection };
