import { getFormattedDate } from '@monetize/utils/core';
import isString from 'lodash/isString';
import { unparse } from 'papaparse';
import { apiGet } from '../../../api/axios';
import { EXPORT } from '../../../constants';
import {
  BillGroupInvoiceFrequencyEnum,
  BillGroupStatusEnum,
  IBillGroupReq,
  IBillGroupReqUI,
  IBillGroupResp,
  IBillingSchedulePeriodItemsSchema,
  IBillingSchedulePeriodRespSchema,
  INetTerm,
  MimeTypeEnum,
  NetTermsEnum,
  QuoteSettingsDefaultAddressSourceEnum,
} from '../../../types';
import {
  FirstInvoiceCreationStrategyEnum,
  InvoiceCreationEnum,
} from '../../../types/InvoiceSettingsTypes';
import { saveFile } from '../../../utils/download';

/**
 * If no bill group, return default form values
 */
export const convertBillGroupRespToReqUI = (
  bgResp?: IBillGroupResp,
  defaultNetTerm?: INetTerm | null,
): IBillGroupReqUI => {
  // Default values
  if (!bgResp) {
    return {
      customId: '',
      // invoiceTemplateId: null,
      name: '',
      status: BillGroupStatusEnum.ACTIVE,
      billDay: new Date().getDate(),
      netTerms: defaultNetTerm?.value || NetTermsEnum.NET_0,
      invoicingFrequency: BillGroupInvoiceFrequencyEnum.MONTHLY,
      invoicingFrequencyInMonths: null,
      contactId: '',
      shippingContactId: '',
      defaultPaymentGatewayId: null,
      paymentMethodId: null,
      dunningProcessId: null,
      collectInvoiceBalanceAutomatically: true,
      autoEmailInvoice: true,
      invoiceCreation: InvoiceCreationEnum.AUTOMATIC,
      firstInvoiceCreationStrategy:
        FirstInvoiceCreationStrategyEnum.FOLLOW_EXISTING_SETTINGS,
      invoiceDelay: 0,
      locked: false,
      activeContract: false,
      nextInvoiceDate: null,
      purchaseOrderNumber: null,
      registrationNumber: null,
      vatNumber: '',
      legalEntityId: '',
      currency: 'USD',
      customFields: {},
      addressData: {
        addressSource: QuoteSettingsDefaultAddressSourceEnum.CONTACT,
      },
    };
  }

  const billGroup: IBillGroupReqUI = {
    customId: bgResp.customId,
    // invoiceTemplateId: null,
    name: bgResp.name,
    status: bgResp.status,
    billDay: bgResp.billDay,
    netTerms: bgResp.netTerms,
    invoicingFrequency: bgResp.invoicingFrequency,
    invoicingFrequencyInMonths: bgResp.invoicingFrequencyInMonths,
    contactId: bgResp.billingContactId,
    shippingContactId: bgResp.shippingContactId,
    defaultPaymentGatewayId: bgResp.defaultPaymentGateway?.id,
    paymentMethodId: bgResp.paymentMethodId,
    dunningProcessId: bgResp.dunningProcessId,
    collectInvoiceBalanceAutomatically:
      bgResp.collectInvoiceBalanceAutomatically,
    autoEmailInvoice: bgResp.autoEmailInvoice,
    invoiceCreation: bgResp.invoiceCreation,
    firstInvoiceCreationStrategy: bgResp.firstInvoiceCreationStrategy,
    invoiceDelay: bgResp.invoiceDelay,
    locked: bgResp.locked,
    activeContract: bgResp.activeContract,
    nextInvoiceDate: bgResp.nextInvoiceDate,
    ccEmails: bgResp.ccEmails,
    strCcEmails: bgResp.ccEmails?.join(','),
    purchaseOrderNumber: bgResp.purchaseOrderNumber,
    registrationNumber: bgResp.registrationNumber,
    vatNumber: bgResp.vatNumber,
    legalEntityId: bgResp.legalEntityId,
    currency: bgResp.currency,
    customFields: bgResp.customFields,
    addressData: {
      addressSource: bgResp.addressSource,
      billingAddressId: bgResp.billingAddress?.id,
      shippingAddressId: bgResp.shippingAddress?.id,
    },
    shippingAddress: bgResp.billingAddress,
    billingAddress: bgResp.shippingAddress,
  };

  return billGroup;
};
export const convertBillGroupReqUIToBillGroupReq = (
  data: IBillGroupReqUI,
): IBillGroupReq => {
  const ccEmails = data.strCcEmails
    ?.split(',')
    .map((val) => val.trim())
    .filter((val) => val);

  return {
    name: data.name,
    contactId: data.contactId,
    shippingContactId: data.shippingContactId,
    defaultPaymentGatewayId: data.defaultPaymentGatewayId,
    paymentMethodId: data.paymentMethodId,
    dunningProcessId: data.dunningProcessId,
    billDay: data.billDay,
    status: data.status,
    collectInvoiceBalanceAutomatically: data.collectInvoiceBalanceAutomatically,
    firstInvoiceCreationStrategy: data.firstInvoiceCreationStrategy,
    customId: data.customId,
    netTerms: data.netTerms,
    autoEmailInvoice: data.autoEmailInvoice,
    invoiceCreation: data.invoiceCreation,
    invoiceDelay: data.invoiceDelay,
    ccEmails,
    purchaseOrderNumber: data.purchaseOrderNumber,
    registrationNumber: data.registrationNumber,
    vatNumber: data.vatNumber,
    legalEntityId: data.legalEntityId,
    customFields: data.customFields,
    currency: data.currency,
    addressData: data.addressData,
  } as IBillGroupReq;
};

type InvoiceFlattenedItem = Record<string, string | number>;

export const flattenInvoices = ({
  invoices,
  billGroupId,
  accountId,
}: {
  invoices: Record<string, IBillingSchedulePeriodRespSchema[]>;
  billGroupId: string;
  accountId: string;
}) => {
  const flattenedData: InvoiceFlattenedItem[] = [];

  Object.entries(invoices).forEach(([contractId, invoiceList]) => {
    invoiceList.forEach((invoice) => {
      invoice.items.forEach((item: IBillingSchedulePeriodItemsSchema) => {
        const flattenedItem: InvoiceFlattenedItem = {
          contractId,
          billGroupId,
          accountId,
          invoiceDate: invoice.invoiceDate,
          invoiceId: invoice.invoiceId ?? '',
          status: invoice.status,
          invoiceStatus: invoice.invoiceStatus ?? '',
          servicePeriodStart: item.servicePeriod.start,
          servicePeriodEnd: item.servicePeriod.end,
          offeringName: item.offeringName,
          productName: item.productName,
          quantity: item.quantity,
          pricePerUnit: item.pricePerUnit,
          amount: item.amount,
        };
        flattenedData.push(flattenedItem);
      });
    });
  });
  return flattenedData;
};

export const downloadInvoices = async ({ contractIds, billGroup }: any) => {
  const { endpoint: _endpoint, fields } =
    EXPORT.EXPORT_CONFIGURATION.billingSchedule;
  const currentDate = getFormattedDate(new Date(), 'yyyy-MM-dd');
  const headers = [...fields];

  const allResults: Record<string, any> = {};
  if (contractIds.length > 1) {
    for (const contractId of contractIds) {
      const endpoint = isString(_endpoint)
        ? _endpoint
        : _endpoint(...[contractId]);

      const results = await apiGet<any>(endpoint);
      allResults[contractId] = results.data.periods;
    }
  } else {
    const singleContractId = contractIds[0];
    const endpoint = isString(_endpoint)
      ? _endpoint
      : _endpoint(singleContractId);

    const results = await apiGet<any>(endpoint);
    allResults[singleContractId] = results.data.periods;
  }

  const csv = unparse(
    {
      fields: headers,
      data: flattenInvoices({
        invoices: allResults,
        billGroupId: billGroup.id,
        accountId: billGroup.accountId,
      }),
    },
    { header: true },
  );
  saveFile(
    csv,
    `${currentDate}-bill-schedule-${billGroup.accountId}.csv`,
    MimeTypeEnum.CSV,
  );
};
