import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { ENVIRONMENT } from '@monetize/ui/config';
import React, { FC } from 'react';

interface MTooltipProps extends TooltipProps {
  usePopover?: boolean;
}

export const MTooltip: FC<MTooltipProps> = React.forwardRef<MTooltipProps, any>(
  (
    {
      label,
      children,
      placement,
      size,
      bg = 'tPurple.dark',
      usePopover = false,
      ...rest
    },
    ref,
  ) => {
    const bgColor = ENVIRONMENT.NODE_ENV === 'test' ? {} : { bg };

    if (!usePopover) {
      return (
        <Tooltip
          label={label}
          placement={placement}
          size={size}
          {...bgColor}
          {...rest}
          ref={ref as any} // FIXME: this is complaining
        >
          {children}
        </Tooltip>
      );
    } else {
      return (
        <Popover
          placement={placement || 'bottom-end'}
          size={size}
          closeOnBlur
          trigger="hover"
          offset={[0, 0]}
          {...rest}
        >
          <PopoverTrigger>{children}</PopoverTrigger>
          <PopoverContent>
            <PopoverBody bgColor="tPurple.dark" color="tWhite.base" p="2">
              {label}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      );
    }
  },
);
