import { TagProps } from '@chakra-ui/react';
import { MTag } from '../../../../components/Monetize';

export const QuotePrimaryBadge = ({ ...rest }: TagProps) => {
  return (
    <MTag variant="blue" {...rest}>
      Primary
    </MTag>
  );
};
