import { differenceInCalendarDays } from 'date-fns/differenceInCalendarDays';
import { parseISO } from 'date-fns/parseISO';
import { Fragment, useEffect, useState } from 'react';
import {
  MBox,
  MDivider,
  MFlex,
  MHeading,
  MText,
} from '../../../../../components/Monetize';
import { DateDisplay } from '../../../../../components/Monetize/DateDisplay';
import { ISubscriptionHistory } from '../../../../../types';
import { formatNumber, pluralize } from '../../../../../utils';

interface SubscriptionHistoryProps {
  subscriptionHistory: ISubscriptionHistory[];
}

export const SubscriptionHistory = ({
  subscriptionHistory,
}: SubscriptionHistoryProps) => {
  const [lastEventDate, setLastEventDate] = useState<number | null>(0);

  useEffect(() => {
    if (
      subscriptionHistory?.length > 0 &&
      !!subscriptionHistory[0].createDate
    ) {
      setLastEventDate(
        Math.abs(
          differenceInCalendarDays(
            new Date(),
            parseISO(subscriptionHistory[0].createDate as string),
          ),
        ),
      );
    }
  }, [subscriptionHistory]);

  if (!subscriptionHistory?.length) {
    return null;
  }

  return (
    <>
      <MFlex justify="space-between" alignItems="center" mt={8} mb={6}>
        <MHeading fontSize="18">Subscription History</MHeading>
        <MText color="tGray.darkPurple">
          Last event was{' '}
          {lastEventDate === 0
            ? 'today'
            : `${formatNumber(lastEventDate, {
                maximumFractionDigits: 0,
              })} ${pluralize('day', lastEventDate || 0)} ago`}{' '}
        </MText>
      </MFlex>
      {subscriptionHistory.map((historyItem, index: number) => (
        <Fragment key={historyItem.id}>
          <MBox mt="4" mb="4">
            <MFlex justify="space-between" alignItems="center">
              <MFlex>
                <MText fontSize="12" ml="4">
                  {historyItem?.message}
                </MText>
              </MFlex>
              <DateDisplay
                date={historyItem?.createDate}
                textProps={{ fontWeight: 'bold', fontSize: 'xs' }}
              />
            </MFlex>
          </MBox>
          {index !== subscriptionHistory.length - 1 && (
            <MDivider orientation="horizontal" color="red" />
          )}
        </Fragment>
      ))}
    </>
  );
};
