import { getRequiredMessage } from '@monetize/utils/core';
import * as z from 'zod';
import {
  ContractEndActionEnumZ,
  QuoteSettingsDefaultAddressSourceEnumZ,
  RenewalTermLengthEnum,
  RenewalTermLengthEnumZ,
} from './enums.types';
import { NetTermsReqSchema } from './netTerms.types';
import { StorageSchema } from './storage.types';

export enum QuoteStartDateSourceEnum {
  ORIGINAL_CONTRACT_START = 'ORIGINAL_CONTRACT_START',
  AT_TIME_OF_SIGNING = 'AT_TIME_OF_SIGNING',
  MANUAL = 'MANUAL',
}
export const QuoteStartDateSourceEnumZ = z.nativeEnum(QuoteStartDateSourceEnum);

export enum QuoteSettingsCategoryEnum {
  QUOTE_APPROVAL_NOTE = 'QUOTE_APPROVAL_NOTE',
}

export const QuoteSettingsCategoryEnumZ = z.nativeEnum(
  QuoteSettingsCategoryEnum,
);

export const QuoteApprovalMessageSchema = z.object({
  category: QuoteSettingsCategoryEnumZ,
  status: z.boolean(),
  approvalNote: z.string().max(500).optional(),
});

export type IQuoteApprovalMessageSchema = z.infer<
  typeof QuoteApprovalMessageSchema
>;

export interface IQuoteSettings {
  category: QuoteSettingsCategoryEnum;
  status: boolean;
  approvalNote: string;
}

export interface IQuoteSettingsRes {
  allowPONumberOnQuotes: boolean;
}

// new types for quoteSettings

export const QuoteApprovalNoteSchema = z.object({
  status: z.boolean(),
  approvalNote: z.string().max(500).optional(),
});

export const QuoteSettingApprovalSchema = z.object({
  quoteApprovalNotes: QuoteApprovalNoteSchema,
});
export type IQuoteSettingApprovalSchema = z.infer<
  typeof QuoteSettingApprovalSchema
>;

export const QuoteCustomContractLengthSchema = z.object({
  allowCustomLength: z.boolean(),
});

export const DefaultQuoteDocumentsSchema = z.object({
  key: z.string(),
  url: z.string().nullish(),
  storage: StorageSchema,
});

export const QuoteSettingsContractRenewalTerms = z.object({
  autoRenewalNoticePeriod: z
    .union([z.number(), z.string()])
    .refine(
      (val) => val !== null && val !== undefined && val !== '',
      getRequiredMessage('Notice Period'),
    )
    .transform((val) => Number(val))
    .refine((val) => val <= 28, 'Must be less than or equal to 28'),
  contractEndAction: ContractEndActionEnumZ,
  renewalTermLength: z
    .object({
      type: RenewalTermLengthEnumZ,
      months: z
        .union([z.string(), z.number()])
        .transform((val) => Number(val))
        .nullish(),
    })
    .superRefine((data, ctx) => {
      if (data.type === RenewalTermLengthEnum.FixedMonths && !data.months) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: getRequiredMessage('Custom Length'),
          path: ['months'],
        });
      }
      if (
        data?.type === RenewalTermLengthEnum.FixedMonths &&
        data.months &&
        (data.months > 120 || data.months < 1)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'The Custom Length must be between 1 to 120',
          path: ['months'],
        });
      }
    }),
});

export const QuoteSettingsConfigSchema = z.object({
  quoteApprovalNotes: QuoteApprovalNoteSchema,
  netTerms: NetTermsReqSchema,
  customContractLength: QuoteCustomContractLengthSchema,
  allowPONumberOnQuotes: z.boolean(),
  autoRenewContract: z.boolean(),
  defaultQuoteDocuments: z.array(DefaultQuoteDocumentsSchema),
  contractEndBehavior: z.string().optional(),
  contractRenewalTerms: QuoteSettingsContractRenewalTerms,
  defaultAddressSource: QuoteSettingsDefaultAddressSourceEnumZ,
  pauseAtAccepted: z.boolean(),
  defaultStartDateSource: QuoteStartDateSourceEnumZ,
  allowQuoteLevelOverrideOfStartDateSource: z.boolean(),
});

export type IQuoteApprovalNoteSchema = z.infer<typeof QuoteApprovalNoteSchema>;

export type IQuoteCustomContractLengthSchema = z.infer<
  typeof QuoteCustomContractLengthSchema
>;

export type IQuoteSettingsConfigSchema = z.infer<
  typeof QuoteSettingsConfigSchema
>;

export type IDefaultQuoteDocumentsSchema = z.infer<
  typeof DefaultQuoteDocumentsSchema
>;
export type IQuoteSettingsContractRenewalTerms = z.infer<
  typeof QuoteSettingsContractRenewalTerms
>;

export const QuoteSettingContractSchema = z.object({
  contractRenewalTerms: QuoteSettingsContractRenewalTerms.nullish(),
});
export type IQuoteSettingContractSchema = z.infer<
  typeof QuoteSettingContractSchema
>;

export const QuoteSettingProcessingSchema = z.object({
  defaultStartDateSource: QuoteStartDateSourceEnumZ,
  allowQuoteLevelOverrideOfStartDateSource: z.boolean(),
  pauseAtAccepted: z.boolean(),
});
export type IQuoteSettingProcessingSchema = z.infer<
  typeof QuoteSettingProcessingSchema
>;
