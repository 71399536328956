import {
  Grid,
  GridItem,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { formatInteger } from '@monetize/utils/core';
import { useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { MdError } from 'react-icons/md';
import { CustomFieldDataForm } from '../../../../components/CustomFields/CustomFieldDataForm';
import {
  MFormField,
  MInput,
  MText,
  MTextarea,
} from '../../../../components/Monetize';
import {
  CustomFieldEntityEnum,
  ICustomFieldRecordSchema,
  InvoiceSummaryResp,
  MassCreditInvoice,
} from '../../../../types';
import { MassCreditModalFormRow } from './MassCreditModalFormRow';

interface MassCreditModalFormProps {
  rows: InvoiceSummaryResp[];
  isLoading: boolean;
  hasSubmitted: boolean;
  control: Control<MassCreditInvoice>;
  errors: FieldErrors<MassCreditInvoice>;
  setValue: UseFormSetValue<MassCreditInvoice>;
  watch: UseFormWatch<MassCreditInvoice>;
}

export const MassCreditModalForm = ({
  rows,
  isLoading,
  control,
  errors,
  hasSubmitted,
  setValue,
  watch,
}: MassCreditModalFormProps) => {
  const [customFields, setCustomFields] = useState<ICustomFieldRecordSchema>(
    {},
  );

  useEffect(() => {
    setValue('customFields', customFields);
  }, [customFields, setValue]);

  const creditConfigHasError =
    !!errors.customFields || !!errors.name || !!errors.reason;

  return (
    <Tabs>
      <TabList>
        <Tab>
          Credit Configuration
          {creditConfigHasError && (
            <Icon as={MdError} ml={1} h={4} w={4} color="tRed.base" />
          )}
        </Tab>
        <Tab>
          Invoices ({formatInteger(rows.length)})
          {!!errors.rows && (
            <Icon as={MdError} ml={1} h={4} w={4} color="tRed.base" />
          )}
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <MFormField isRequired label="Name" error={errors.name}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MInput {...field} isDisabled={isLoading} />
              )}
            />
          </MFormField>

          <MFormField isRequired label="Reason" error={errors.reason} mt={2}>
            <Controller
              name="reason"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <MTextarea
                  placeholder="Reason for credit..."
                  {...field}
                  isDisabled={isLoading}
                />
              )}
            />
          </MFormField>

          <CustomFieldDataForm
            entity={CustomFieldEntityEnum.CREDIT}
            value={customFields}
            setValue={(val) => {
              setCustomFields(val);
            }}
            fieldColSpan={6}
            mode="modal"
          />
        </TabPanel>
        <TabPanel>
          <Grid
            templateColumns="minmax(min-content, 8rem) minmax(6rem, auto) repeat(4, minmax(min-content, 8rem)) 5rem"
            rowGap={2}
            columnGap={4}
            alignItems="center"
          >
            <GridItem alignSelf="end">
              <MText fontWeight="bold">Invoice</MText>
            </GridItem>
            <GridItem alignSelf="end">
              <MText fontWeight="bold">Account</MText>
            </GridItem>
            <GridItem alignSelf="end">
              <MText textAlign="right" fontWeight="bold">
                Amount
              </MText>
            </GridItem>
            <GridItem alignSelf="end">
              <MText textAlign="right" fontWeight="bold">
                Prior Amount Due
              </MText>
            </GridItem>
            <GridItem alignSelf="end">
              <MText textAlign="right" fontWeight="bold" pr={1}>
                Credit Amount
              </MText>
            </GridItem>
            <GridItem alignSelf="end">
              <MText textAlign="right" fontWeight="bold">
                New Amount Due
              </MText>
            </GridItem>
            <GridItem alignSelf="end">
              <MText textAlign="right" fontWeight="bold">
                New Status
              </MText>
            </GridItem>

            {rows.map((row, index) => (
              <MassCreditModalFormRow
                key={index}
                index={index}
                row={row}
                isLoading={isLoading}
                isLastRow={index === rows.length - 1}
                control={control}
                errors={errors}
                hasSubmitted={hasSubmitted}
                setValue={setValue}
                watch={watch}
              />
            ))}
          </Grid>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
