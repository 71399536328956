import { GridProps } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import {
  IQuoteItemRespSchema,
  IQuoteOfferingRespSchema,
  ProductTypeEnum,
} from '../../types';
import { formatCurrency, getQuoteItemAmendStatus } from '../../utils';
import { MGrid, MText } from '../Monetize';

interface CostCellProps extends GridProps {
  item: IQuoteItemRespSchema;
  showEnable?: boolean;
  currency: string;
  onClick?: () => void;
}

export const getAmountBeforeDiscount = (
  item: IQuoteItemRespSchema | IQuoteOfferingRespSchema,
  currency: string,
) => {
  if (+item.amountWithoutDiscount - +item.amount > 0) {
    return formatCurrency(item.amountWithoutDiscount, { currency });
  }

  return '';
};

const CostCell: FunctionComponent<CostCellProps> = ({
  item,
  showEnable,
  currency,
  onClick,
  ...rest
}: CostCellProps): React.ReactElement => {
  const amountBeforeDiscount = getAmountBeforeDiscount(item, currency);
  const { isRemoved } = getQuoteItemAmendStatus(item);

  if (showEnable) {
    return (
      <MText
        data-testid="amount-cell"
        textAlign="right"
        textDecor="underline"
        color="tIndigo.base"
        fontWeight="bold"
        alignSelf="right"
        onClick={onClick}
        cursor="pointer"
      >
        Enable
      </MText>
    );
  }

  if (isRemoved) {
    return (
      <MGrid
        data-testid="amount-cell"
        gridTemplateColumns="1fr"
        minH={8}
        maxH={8}
        alignSelf="center"
        {...rest}
      >
        <MText
          textAlign="right"
          alignSelf={!amountBeforeDiscount.length ? 'center' : 'end'}
          disabled={isRemoved}
          color="tPurple.dark"
        >
          {formatCurrency(item.amount, { currency })}
        </MText>
      </MGrid>
    );
  }

  const isAmountBeforeDiscount = !!amountBeforeDiscount.length;

  return (
    <MGrid
      data-testid="amount-cell"
      gridTemplateColumns="1fr"
      minH={isAmountBeforeDiscount ? 8 : undefined} // TODO: find alternative ways instead of using min height
      alignSelf="center"
      {...rest}
    >
      {isAmountBeforeDiscount && (
        <MText
          textDecoration="line-through"
          fontWeight="bold"
          color="tGray.darkPurple"
          textAlign="right"
          fontSize="xs"
          alignSelf="start"
          data-testid={`${item.id}-amountBeforeDiscount`}
        >
          {amountBeforeDiscount}
        </MText>
      )}
      <MText
        textAlign="right"
        alignSelf={!isAmountBeforeDiscount ? 'center' : 'end'}
        fontWeight="bold"
        data-testid={`${item.id}-amount`}
      >
        {item.productType === ProductTypeEnum.USAGE && item.amount === 0
          ? ''
          : formatCurrency(item.amount, { currency })}
      </MText>
    </MGrid>
  );
};

export default CostCell;
