import isValidDomain from 'is-valid-domain';
import { array, boolean, nativeEnum, object, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';

export enum NotificationTypeEnum {
  DOMAIN = 'DOMAIN',
  EMAIL = 'EMAIL',
}
export const NotificationTypeEnumZ = nativeEnum(NotificationTypeEnum);

export const NotificationItemSchema = object({
  //notification emain/domain list
  type: NotificationTypeEnumZ,
  emailOrDomains: array(string()),
});
export type INotificationItemSchema = z.infer<typeof NotificationItemSchema>;

export const NotificationSchema = object({
  //notification main list
  restrict: boolean(),
  notifications: array(NotificationItemSchema),
});

export type INotificationSchema = z.infer<typeof NotificationSchema>;

export const NotificationEmailSchema = object({
  email: string().email().nonempty(getRequiredMessage('Email')),
});
export type INotificationEmailSchema = z.infer<typeof NotificationEmailSchema>;

export const NotificationDomainSchema = object({
  domain: string()
    .nonempty(getRequiredMessage('Domain'))
    .refine((domain) => isValidDomain(domain), {
      message: 'Invalid domain',
    }),
});
export type INotificationDomainSchema = z.infer<
  typeof NotificationDomainSchema
>;
