import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import AppWrapper from './AppWrapper';
import { AppReactQueryWrapper } from './components/App/AppReactQueryWrapper';
import { SentryErrorBoundary } from './components/Sentry/SentryErrorBoundary';
import { ACLProvider } from './services/acl/acl';
import { Auth0Wrapper } from './services/Auth0Wrapper';
import { ConfirmModalProvider } from './services/confirmModal';
import { DocumentHeadProvider } from './services/documentHead';
import { ToastProvider } from './services/toast';
import MChakraProvider from './styles/MChakraProvider';
import { ReactQueryDevtoolsWrapper } from './utils/ReactQueryDevtoolsWrapper';

export const App = () => {
  return (
    <RecoilRoot>
      <RecoilNexus />
      <Router>
        <AppReactQueryWrapper>
          <ReactQueryDevtoolsWrapper />
          <MChakraProvider>
            <Auth0Wrapper>
              <ToastProvider>
                <ConfirmModalProvider>
                  <ACLProvider>
                    <DocumentHeadProvider>
                      <DndProvider backend={HTML5Backend}>
                        <SentryErrorBoundary>
                          <AppWrapper />
                        </SentryErrorBoundary>
                      </DndProvider>
                    </DocumentHeadProvider>
                  </ACLProvider>
                </ConfirmModalProvider>
              </ToastProvider>
            </Auth0Wrapper>
          </MChakraProvider>
        </AppReactQueryWrapper>
      </Router>
    </RecoilRoot>
  );
};
