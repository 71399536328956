import { BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import {
  MBox,
  MDivider,
  MFlex,
  MSkeleton,
  MText,
} from '../../../../../components/Monetize';
interface QuoteAccountSectionSegmentProps {
  label?: string;
  skeletonWidth?: string;
  content?: any;
  loading?: boolean;
  containerProps?: BoxProps;
  showDivider?: boolean;
}

const QuoteAccountSectionSegment: FC<QuoteAccountSectionSegmentProps> = ({
  label,
  content,
  skeletonWidth = '52px',
  loading,
  containerProps,
  showDivider = true,
}: QuoteAccountSectionSegmentProps) => {
  if (loading) {
    return (
      <MSkeleton ml="3" mr="68" h="12" w={skeletonWidth} {...containerProps} />
    );
  }

  // Completely hides the segment if no description
  if (!content) {
    return null;
  }

  return (
    <MBox pl="3" {...containerProps}>
      {label && (
        <MText fontSize="md" fontWeight="bold">
          {label}
        </MText>
      )}
      <MFlex w="full" minH="8" alignItems="center">
        {content}
        {showDivider && <MDivider ml="2" minH="8" orientation="vertical" />}
      </MFlex>
    </MBox>
  );
};

export default QuoteAccountSectionSegment;
