import { BoxProps, StyleProps } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import {
  MBox,
  MCustomIconButton,
  MTooltip,
} from '../../../../components/Monetize';
import { IQuoteRespSchema } from '../../../../types';
import { PreviewQuotePdfDrawerWrapper } from './PreviewQuotePdfDrawerWrapper';

interface PreviewQuotePdfDrawerProps extends BoxProps {
  quote: IQuoteRespSchema;
  iconButtonStyles?: StyleProps;
}

/**
 * Allow user to click a button to open a drawer and view quote PDF documents
 */
export const PreviewQuotePdfDrawer = ({
  quote,
  iconButtonStyles = {},
  ...rest
}: PreviewQuotePdfDrawerProps) => {
  const buttonRef = useRef<typeof MCustomIconButton>(null);
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(true);
  }

  return (
    <MTooltip label={!isOpen ? 'View Quote Documents' : null}>
      <MBox position="relative" {...rest}>
        <MCustomIconButton
          ref={buttonRef}
          btnSize={6}
          variant="icon"
          p={4}
          ml={1}
          mr={3}
          icon={AiOutlineFilePdf}
          iconColor="tPurple.base"
          isDisabled={!quote.quoteOfferings.length}
          onClick={handleOpen}
          {...iconButtonStyles}
        />

        {isOpen && (
          <PreviewQuotePdfDrawerWrapper
            quote={quote}
            onCloseFocusRef={buttonRef}
            onClose={() => setIsOpen(false)}
          />
        )}
      </MBox>
    </MTooltip>
  );
};
