import { RuleStatusEnumDisplay } from '@monetize/types/app';
import { ColumnProps } from 'primereact/column';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MButton,
  MDataTable,
  MFlex,
  MPageContainer,
  MPageSearchInput,
  MSettingsPageHeader,
  shouldOpenInNewWindow,
} from '../../../components/Monetize';
import {
  DateGlteTableFilterOptionContent,
  MDataTableFilter,
} from '../../../components/Monetize/DataTable';
import { ExportTableButton } from '../../../components/Monetize/ExportEntityButton';
import { ROUTES } from '../../../constants';
import { getRuleEditRoute } from '../../../constants/routes';
import { RULE_STATUS_DISPLAY } from '../../../constants/rules';
import { useDocumentHead } from '../../../services/documentHead';
import {
  FilterOptionType,
  FilterTypeOperator,
  IRuleResSchema,
  RuleStatusEnum,
  RuleTypeEnum,
} from '../../../types';
import { getFiltersApplied } from '../../../utils';
import { objectToObjArray } from '../../../utils/misc';
import {
  dateTimeBodyTemplate,
  numberBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '../../../utils/tableUtils';
import { useRuleTableData } from '../rules-v3/useRuleTableData';

interface ConditionalTermsPageProps {
  /** @deprecated */
  V3?: true;
}

const statusItems = objectToObjArray(RuleStatusEnumDisplay, 'value', 'label');
const filterOptions: FilterOptionType[] = [
  {
    title: 'Status',
    key: 'status',
    operator: FilterTypeOperator.IN,
    items: statusItems,
  },
  {
    title: 'Created',
    key: 'createDate',
    operator: FilterTypeOperator.GLTE,
    renderOptionContent: (props) => (
      <DateGlteTableFilterOptionContent showTimePicker {...props} />
    ),
  },
  {
    title: 'Updated',
    key: 'modifyDate',
    operator: FilterTypeOperator.GLTE,
    renderOptionContent: (props) => (
      <DateGlteTableFilterOptionContent showTimePicker {...props} />
    ),
  },
];

const ConditionalTermsPage = ({}: ConditionalTermsPageProps) => {
  const {
    pager,
    setPager,
    filters,
    setFilters,
    globalFilter,
    setGlobalFilter,
    loading,
    hasLoaded,
    rules,
    actionBodyTemplate,
  } = useRuleTableData({ type: RuleTypeEnum.CONDITIONAL_TERM });

  const { setDocTitle } = useDocumentHead();
  const navigate = useNavigate();
  const redirectToCreate = () =>
    navigate(ROUTES.SETTINGS_CONDITIONAL_TERMS_V3_CREATE);

  const onSelectEdit = (id: string, openInNewWindow = false) => {
    openInNewWindow
      ? window.open(getRuleEditRoute(id, 'conditional-terms'), '_blank')
      : navigate(getRuleEditRoute(id, 'conditional-terms'));
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'name',
      header: 'Name',
      sortable: true,
      body: textBodyTemplate<IRuleResSchema>('name'),
    },
    {
      className: 'overflow-hidden',
      field: 'description',
      header: 'Description',
      style: { maxWidth: '300px' },
      sortable: true,
      body: textBodyTemplate<IRuleResSchema>('description'),
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      body: statusBodyTemplate<IRuleResSchema, RuleStatusEnum>(
        'status',
        RULE_STATUS_DISPLAY,
      ),
    },
    {
      field: 'conditionalTerms.displayOrder',
      header: 'Order',
      sortable: false,
      body: numberBodyTemplate<IRuleResSchema>(
        'action.conditionalTerms.displayOrder',
      ),
    },
    {
      field: 'modifyDate',
      header: 'Updated',
      sortable: true,
      body: dateTimeBodyTemplate('modifyDate'),
      className: 'table-cell-md',
    },
    { body: actionBodyTemplate, style: { width: '4em' } },
  ];

  useEffect(() => {
    setDocTitle('Settings', 'Conditional Terms');
  }, []);

  return (
    <MPageContainer
      alignItems="stretch"
      overflowY="auto"
      data-testid="conditional-terms-list-page"
    >
      <MSettingsPageHeader title="Conditional Terms">
        <MPageSearchInput
          placeholderKey="Name or Description"
          value={globalFilter}
          onChange={(e: any) => setGlobalFilter(e)}
          count={rules?.totalElements}
        />
        <MDataTableFilter
          filters={filters}
          filterOptions={filterOptions}
          setFilters={(filters) => setFilters(filters)}
          onResetFilter={() => setFilters([])}
        />
        {(hasLoaded || (rules?.content.length !== 0 && !loading)) && (
          <MFlex gap={4}>
            <ExportTableButton
              applyInternalFiltersWithoutConfirmation
              entity="conditionalTerms"
              internalFilters={[
                {
                  key: 'type',
                  value: RuleTypeEnum.CONDITIONAL_TERM,
                  operator: FilterTypeOperator.EQUAL,
                },
              ]}
            />
            <MButton
              variant="primary"
              onClick={redirectToCreate}
              data-testid="new-conditional-term-btn"
            >
              New
            </MButton>
          </MFlex>
        )}
      </MSettingsPageHeader>
      <MDataTable
        value={rules?.content}
        totalRecords={rules?.totalElements}
        totalPages={rules?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        className="p-datatable-responsive"
        loading={loading}
        columns={columns}
        emptyProps={{
          mainMessage: 'Looks like there are no Conditional Terms here.',
          btnLabel: 'New Conditional Term',
          to: ROUTES.SETTINGS_CONDITIONAL_TERMS_V3_CREATE,
        }}
        onRowClick={(e) => onSelectEdit(e.data?.id, shouldOpenInNewWindow(e))}
        filtersApplied={getFiltersApplied(filters) > 0 || !!globalFilter}
      />
    </MPageContainer>
  );
};

export default ConditionalTermsPage;
