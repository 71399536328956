import { HEAP_CONFIG } from '@monetize/ui/config';

export const onHeapIdentify = (
  email: string,
  userProperties: {
    id: string;
    name: string;
    tenantId: string;
    tenantName: string;
  },
) => {
  if (!window.heap || !HEAP_CONFIG.appId) {
    // return silently
    return;
  }

  window.heap?.identify(email);
  window.heap?.addUserProperties(userProperties);
};

export const onHeapLogout = () => {
  if (!window.heap || !HEAP_CONFIG.appId) {
    // return silently
    return;
  }

  window.heap?.resetIdentity();
};
