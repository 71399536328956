import { ImageProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MBox, MImage, MSpinner } from '../../../components/Monetize';
import { useLegalEntityLogo } from '../../../hooks/useLegalEntityLogo';
import { Maybe } from '../../../types';

interface CompanyLogoProps extends ImageProps {
  legalEntityId: Maybe<string>;
  legalEntityLogoId: Maybe<string>;
}

const CompanyLogo: FC<CompanyLogoProps> = ({
  legalEntityId,
  legalEntityLogoId,
  ...rest
}: CompanyLogoProps) => {
  const { data, isLoading, base64 } = useLegalEntityLogo({
    id: legalEntityId as string,
  });

  if (isLoading) {
    return <MSpinner />;
  }

  if (!data || !base64) {
    return null;
  }

  return (
    <MBox>
      <MImage
        src={`${base64}`}
        alt="Logo"
        w="auto"
        maxHeight="100px"
        maxWidth="300px"
        {...rest}
      />
    </MBox>
  );
};

export default CompanyLogo;
