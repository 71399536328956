import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  MBox,
  MCustomIconButton,
  MFlex,
  MHStack,
  MText,
  MTooltip,
} from '../../../../components/Monetize';
import { MDataTableActions } from '../../../../components/Monetize/DataTable';
import { MEntityStatusBadge } from '../../../../components/Monetize/MEntityStatusBadge';
import { ROUTES } from '../../../../constants';
import { DUNNING_STATUS_DISPLAY } from '../../../../constants/dunnings';
import { DunningStatusEnum, IDunnings } from '../../../../types';

interface DunningItemCardProps {
  isDefaultProcessLoading: boolean;
  dunnings: IDunnings[];
  currentDunningId?: string;
  handleDeleteDunning: (dunning: IDunnings) => void;
  handleUpdateDefaultDunningProcess: (dunning: IDunnings) => void;
  handleDeleteDefaultDunningProcess: (dunning: IDunnings) => void;
  children: React.ReactNode;
}

const DunningItemCards: FunctionComponent<any> = ({
  dunnings,
  children,
  currentDunningId,
  isDefaultProcessLoading,
  handleDeleteDunning,
  handleDeleteDefaultDunningProcess,
  handleUpdateDefaultDunningProcess,
}: DunningItemCardProps) => {
  const navigate = useNavigate();

  const redirectToEdit = (id: string) =>
    navigate(ROUTES.getDunningEditRoute(id));

  const actionMenu = (dunning: IDunnings) => {
    const actions = [
      {
        title: 'Edit',
        enabled: true,
        action: () => redirectToEdit(dunning.id),
      },
      {
        title: 'Delete',
        color: 'danger',
        enabled: true,
        action: () => handleDeleteDunning(dunning),
      },
    ];

    return <MDataTableActions actions={actions} />;
  };

  return (
    <MBox maxW="37.5rem">
      <>
        {dunnings?.map((dunning) => (
          <Card key={dunning.id} variant="edit" mb="4">
            <CardHeader>
              <MFlex justifyContent="space-between" alignItems="center">
                <MFlex flex="1">
                  <MText
                    noOfLines={1}
                    fontWeight="normal"
                    fontSize="sm"
                    isTruncated
                    title={dunning.name}
                    maxW="20rem"
                    whiteSpace="normal"
                  >
                    {dunning.name}
                  </MText>
                  <MEntityStatusBadge
                    status={DUNNING_STATUS_DISPLAY[DunningStatusEnum.ACTIVE]}
                  />
                </MFlex>
                <MFlex>
                  <MTooltip label="Set as dunning process for new Bill Groups">
                    <MCustomIconButton
                      variant="icon"
                      containerSize="8"
                      btnSize={6}
                      fontSize="lg"
                      cursor="pointer"
                      icon={
                        dunning.defaultProcess
                          ? MdOutlineStar
                          : MdOutlineStarBorder
                      }
                      onClick={() => {
                        if (dunning.defaultProcess) {
                          handleDeleteDefaultDunningProcess(dunning);
                        } else {
                          handleUpdateDefaultDunningProcess(dunning);
                        }
                      }}
                      iconColor="tPurple.base"
                      isLoading={
                        currentDunningId === dunning.id &&
                        isDefaultProcessLoading
                      }
                    />
                  </MTooltip>
                  <MFlex alignSelf={'center'}>{actionMenu(dunning)}</MFlex>
                </MFlex>
              </MFlex>
            </CardHeader>
            <CardBody>
              <MHStack spacing="8">
                <MBox>
                  <MText fontWeight="semibold" mb={1}>
                    Dunning period
                  </MText>
                  <MText>{dunning.dunningProcessDays} Days</MText>
                </MBox>
                <MBox>
                  <MText fontWeight="semibold" mb={1}>
                    Dunning Steps
                  </MText>
                  <MText>{dunning.totalDunningStep}</MText>
                </MBox>
              </MHStack>
            </CardBody>
          </Card>
        ))}

        {!!dunnings?.length && children}
      </>
    </MBox>
  );
};

export default DunningItemCards;
