import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { ReactNode, useState } from 'react';
import { handleApiErrorToast } from '../../api/axios';
import { setByIdCacheFromReturnedList } from '../../api/queryUtilsHelpers';
import { useNavigateToErrorPage } from '../../routes/ErrorPages/ErrroPage';
import { isApiListResponse } from '../../utils';

export const AppReactQueryWrapper = ({ children }: { children: ReactNode }) => {
  const navigateToErrorPage = useNavigateToErrorPage();
  const navigate = useNavigate();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            /**
             * There is a Chrome bug that impacts some users
             * {@link https://github.com/TanStack/query/issues/4772}
             * {@link https://bugs.chromium.org/p/chromium/issues/detail?id=678075}
             */
            networkMode: 'always',
          },
          mutations: {
            networkMode: 'always',
          },
        },
        mutationCache: new MutationCache({
          onError: (error, variables, context, mutation) => {
            const showErrorToast = mutation.meta?.showErrorToast === true;
            const doNavigateToErrorPage =
              mutation.meta?.navigateToErrorPage === true;
            const navigateTo = mutation.meta?.navigateTo;

            if (showErrorToast) {
              handleApiErrorToast(error);
            }

            if (doNavigateToErrorPage) {
              navigateToErrorPage(error);
            } else if (typeof navigateTo === 'string') {
              navigate(navigateTo, { replace: !!mutation.meta?.replace });
            }
          },
        }),
        queryCache: new QueryCache({
          onSuccess: (data, query) => {
            /**
             * Set list data in byId cache
             */
            if (
              query.meta?.setByIdCacheFromReturnedList &&
              (Array.isArray(data) || isApiListResponse(data))
            ) {
              const listData = Array.isArray(data) ? data : data.content;
              setByIdCacheFromReturnedList(
                queryClient,
                query.meta.setByIdCacheFromReturnedList.byIdQueryKey,
                listData,
                query.meta.setByIdCacheFromReturnedList.endpointArgs,
              );
            }
          },
          onError: (error, query) => {
            const showErrorToast = query.meta?.showErrorToast === true;
            const doNavigateToErrorPage =
              query.meta?.navigateToErrorPage === true;
            const navigateTo = query.meta?.navigateTo;

            if (showErrorToast) {
              handleApiErrorToast(error);
            }

            if (doNavigateToErrorPage) {
              navigateToErrorPage(error);
            } else if (typeof navigateTo === 'string') {
              navigate(navigateTo, { replace: !!query.meta?.replace });
            }
          },
        }),
      }),
  );
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
