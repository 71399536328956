import isString from 'lodash/isString';
import { unparse } from 'papaparse';
import {
  EXPORT_CONFIGURATION,
  ExportEntity,
} from '../constants/entityExportConfiguration';
import { GetListApiFilter, MimeTypeEnum, SortOrder } from '../types';
import { flattenObj } from '../utils';
import { saveFile } from '../utils/download';
import { apiGet } from './axios';

export type ExportEntityParams = {
  endpointParams?: string[];
  entity: ExportEntity;
  filename?: string;
  filters?: GetListApiFilter;
  sortField?: string;
  sortOrder?: SortOrder;
};

const validateExportEntityConfig = (
  entity: ExportEntity,
  endpointParams: string[],
) => {
  if (
    !EXPORT_CONFIGURATION[entity] ||
    (!isString(EXPORT_CONFIGURATION[entity].endpoint) && !endpointParams)
  ) {
    return;
  }
};
// NOTE: exports data to a CSV file based on provided entity configuration
export const doExportEntity = async ({
  endpointParams = [],
  entity,
  filename,
}: ExportEntityParams) => {
  validateExportEntityConfig(entity, endpointParams);
  const {
    endpoint: _endpoint,
    fields,
    fieldTransformFns,
  } = EXPORT_CONFIGURATION[entity];

  const endpoint = isString(_endpoint)
    ? _endpoint
    : _endpoint(...endpointParams);

  const { data } = await apiGet(endpoint);
  const csv = unparse(
    {
      fields,
      data: flattenObj([data], fields, fieldTransformFns),
    },
    { header: true },
  );
  saveFile(csv, `${filename || entity}.csv`, MimeTypeEnum.CSV);
};
