import { formatNumber } from '@monetize/utils/core';
import { OFFERINGS } from '../../../constants';
import {
  IOrderedObj,
  IPriceResSchema,
  IQuoteItemRespSchema,
  PriceModelEnum,
} from '../../../types';
import { MBox, MCheckbox, MFlex, MHStack, MText } from '../../Monetize';
import { MCurrency } from '../../Monetize/MCurrency';
import { MPercent } from '../../Monetize/MPercent';

interface SortedProductsWithPricesRowProps {
  id: string;
  prices: IPriceResSchema[];
  isPoTOfferingType: boolean;
  productsObj: IOrderedObj<IQuoteItemRespSchema>;
  currency: string;
  handleCheckboxChange: (id: string, priceId: string, checked: boolean) => void;
  isChecked: (id: string, priceId: string) => boolean;
}

export const SortedProductsWithPricesRow = ({
  id,
  prices,
  isPoTOfferingType,
  productsObj,
  currency,
  handleCheckboxChange,
  isChecked,
}: SortedProductsWithPricesRowProps) => {
  return prices.map(
    (
      { from, to, priceModel, amount, product, id: priceId },
      productIndex: number,
    ) => {
      const formattedFrom = formatNumber(from, {
        maximumFractionDigits: 0,
      });

      const formattedTo =
        formatNumber(to, {
          maximumFractionDigits: 0,
        }) || '∞';

      const isLastRow = prices.length - 1 === productIndex;

      return (
        <MHStack
          key={`${productIndex}-range-${id}`}
          align="flex-start"
          spacing={4}
          mb={!isLastRow ? 2 : 0}
        >
          <MFlex>
            <MCheckbox
              onChange={(e) => {
                handleCheckboxChange(id, priceId, e.target.checked);
              }}
              py={2}
              isChecked={isChecked(id, priceId)}
            />
          </MFlex>
          <MFlex align="center" minW={4} minH={8}>
            <MText color="tPurple.base">{`${productIndex + 1}.`}</MText>
          </MFlex>
          <MFlex align="center" minH="8" minW="40">
            <MText>{OFFERINGS.PRICE_MODEL_OPTIONS[priceModel]}</MText>
          </MFlex>
          <MFlex align="center" minH="8" minW="6.25rem">
            <MText>{formattedFrom}</MText>
          </MFlex>
          <MFlex align="center" minH="8" minW="6.25rem">
            <MText>{formattedTo}</MText>
          </MFlex>
          <MFlex align="center" minH="8" w="7.125rem">
            {isPoTOfferingType && <MPercent amount={amount} />}
            {!isPoTOfferingType && (
              <MCurrency
                amount={
                  priceModel === PriceModelEnum.CUSTOM
                    ? productsObj.byId[product.id]?.unitPrice
                    : amount
                }
                options={{
                  maximumFractionDigits: 'max',
                  currency,
                }}
              />
            )}
          </MFlex>

          <MBox minW="8" />
        </MHStack>
      );
    },
  );
};
