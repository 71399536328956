import { useState } from 'react';
import { MdInfo } from 'react-icons/md';
import {
  MFlex,
  MIcon,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import { IRateResSchema } from '../../../../../types';
import QuoteOfferingRateRightEl from './QuoteOfferingRateRightEl';

const RateItem = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
  isDisabled,
}: {
  title: string;
  item: IRateResSchema;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
  isDisabled: boolean;
}) => {
  /**
   *  using _groupHover seems to be not working as expected. It was showing the icon
   *  even when we hover the dropdown box. To avoid this we are using state to track
   *  the hover state and show info icon based on that
   */
  const [infoIcon, showInfoIcon] = useState<boolean>(false);

  return (
    <MTooltip
      label={
        isDisabled
          ? 'Only Rates with the same Frequency (e.g., Monthly, Annually) and Timing (Advance, Arrears) are available. To change to a different Frequency or Timing, remove scheduled changes first'
          : ''
      }
    >
      <MFlex
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        onMouseEnter={() => {
          showInfoIcon(true);
        }}
        onMouseLeave={() => {
          showInfoIcon(false);
        }}
      >
        <MFlex alignItems="center">
          <QuoteOfferingRateRightEl rate={item} />
          <MText
            color="inherit"
            fontSize={isSubtitle ? '12px' : 'sm'}
            mb="-2px"
            mt="0px"
            ml="2"
            whiteSpace="nowrap"
          >
            {title}
          </MText>
        </MFlex>
        {/* Tooltip only displays if there is an internal rate, but space is reserved so the menu does not change sizes */}
        {item?.internalDescription && (
          <MTooltip
            label={
              item.internalDescription?.includes('\n') ? (
                <>
                  {item.internalDescription.split('\n').map((row, i) => (
                    <div key={i}>{row}</div>
                  ))}
                </>
              ) : (
                item.internalDescription
              )
            }
            placement="bottom-start"
          >
            <MFlex ml="2" alignItems="center" minWidth={4}>
              <MIcon
                as={MdInfo}
                display={infoIcon ? 'flex' : 'none'}
                color="tPurple.base"
                className="info"
              />
            </MFlex>
          </MTooltip>
        )}
      </MFlex>
    </MTooltip>
  );
};
export const RateItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
  isDisabled,
}: {
  title: string;
  item: IRateResSchema;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
  isDisabled: boolean;
}) => (
  <RateItem
    title={title}
    item={item}
    isSubtitle={isSubtitle}
    isSelected={isSelected}
    isHighlight={isHighlight}
    isDisabled={isDisabled}
  />
);
