import { MBox, MText } from '../../../../../../components/Monetize';

export const DisabledCustomPriceModelLabel = () => {
  return (
    <MBox maxWidth="9rem">
      <MText color="tWhite.base" fontSize="xs">
        You cannot set the tier with a custom pricing model
      </MText>
    </MBox>
  );
};
