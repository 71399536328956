import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  cpqServiceQueryKeys,
  doDeleteQuoteStorageById,
  doUploadQuoteDocument,
  useGetQuoteStorageById,
} from '../../../../../api/cpqService';
import { logger } from '../../../../../services/logger';
import {
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IStorage,
  StorageTypeEnum,
} from '../../../../../types';
import {
  ActiveDocumentType,
  DocumentItemState,
} from '../../../../../types/quoteDocumentsType';
import {
  convertToBase64,
  showPdfInNewTab,
} from '../../../../../utils/download';
import { getQuoteRequestFromQuoteResponse } from '../../../../../utils/quotes';

export const useQuoteDocuments = ({
  quote,
  saving,
  onUpdate,
  setSaving,
  handleClose,
  saveOnUpdate,
}: {
  quote: IQuoteRespSchema;
  saving: boolean;
  setSaving: (value: boolean) => void;
  onUpdate: (data: IQuoteRequestSchema, requestType: string) => void;
  handleClose?: () => void;
  saveOnUpdate?: boolean;
}) => {
  const queryClient = useQueryClient();
  const [isDirty, setIsDirty] = useState(false);

  const msaDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.documentLocation!,
      params: { attachContent: true },
    },
    { enabled: !!quote.documentLocation && !saving },
  );

  const sowDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.sowDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.sowDocumentStorageId && !saving },
  );

  const quoteDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.signedDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.signedDocumentStorageId && !saving },
  );
  const pdfCoverDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.coverDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.coverDocumentStorageId && !saving },
  );
  const pdfFooterDocument = useGetQuoteStorageById(
    {
      quoteId: quote.id,
      storageId: quote.footerDocumentStorageId!,
      params: { attachContent: true },
    },
    { enabled: !!quote.footerDocumentStorageId && !saving },
  );

  const isLoadingInitialData =
    (msaDocument.isLoading && msaDocument.isFetching) ||
    (sowDocument.isLoading && sowDocument.isFetching) ||
    (quoteDocument.isLoading && quoteDocument.isFetching) ||
    (pdfCoverDocument.isLoading && pdfCoverDocument.isFetching) ||
    (pdfFooterDocument.isLoading && pdfFooterDocument.isFetching);

  const [msaData, setMsaData] = useState<DocumentItemState>({
    savedData: {
      storage: msaDocument.data,
      link: quote.documentUrl || undefined,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const [sowData, setSowData] = useState<DocumentItemState>({
    savedData: {
      storage: sowDocument.data,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const [pdfCoverData, setPdfCoverData] = useState<DocumentItemState>({
    savedData: {
      storage: pdfCoverDocument.data,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const [pdfFooterData, setPdfFooterData] = useState<DocumentItemState>({
    savedData: {
      storage: pdfFooterDocument.data,
    },
    activeItem: 'none',
    linkUrl: '',
    savedItemText: '',
  });

  const handlerMap = {
    setter: {
      msa: setMsaData,
      sow: setSowData,
      cover: setPdfCoverData,
      footer: setPdfFooterData,
    },
    getter: {
      msa: msaData,
      sow: sowData,
      cover: pdfCoverData,
      footer: pdfFooterData,
    },
  };

  useEffect(() => {
    (async () => {
      if (isDirty && saveOnUpdate) {
        setIsDirty(false);
        await handleSave();
      }
    })();
  }, [isDirty, saveOnUpdate]);

  // Set initial state id msaDocument changes
  useEffect(() => {
    if (msaDocument.data || quote.documentUrl) {
      setMsaData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (msaDocument.data) {
          activeItem = 'file';
        } else if (quote.documentUrl) {
          activeItem = 'link';
        }
        return {
          ...priorData,
          savedData: {
            link: quote.documentUrl || undefined,
            storage: msaDocument.data,
          },
          linkUrl: quote.documentUrl || '',
          activeItem,
          savedItemText: msaDocument.data?.filename || quote.documentUrl || '',
        };
      });
    }
  }, [msaDocument.data, quote.documentUrl]);

  useEffect(() => {
    if (sowDocument.data) {
      setSowData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (sowDocument.data) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: sowDocument.data,
          },
          activeItem,
          savedItemText: sowDocument.data?.filename || '',
        };
      });
    }
  }, [sowDocument.data?.filename, sowDocument.data]);

  useEffect(() => {
    if (pdfCoverDocument.data) {
      setPdfCoverData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (pdfCoverDocument.data) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: pdfCoverDocument.data,
          },
          activeItem,
          savedItemText: pdfCoverDocument.data?.filename || '',
        };
      });
    }
  }, [pdfCoverDocument.data?.filename, pdfCoverDocument.data]);

  useEffect(() => {
    if (pdfFooterDocument.data) {
      setPdfFooterData((priorData) => {
        let activeItem: ActiveDocumentType = 'none';
        if (pdfFooterDocument.data) {
          activeItem = 'file';
        }
        return {
          ...priorData,
          savedData: {
            link: undefined,
            storage: pdfFooterDocument.data,
          },
          activeItem,
          savedItemText: pdfFooterDocument.data?.filename || '',
        };
      });
    }
  }, [pdfFooterDocument.data?.filename, pdfFooterDocument.data]);

  async function handleSave(event?: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    event?.stopPropagation();
    const storagesToDelete: {
      type: StorageTypeEnum;
      storage: IStorage;
      quoteProp: keyof IQuoteRequestSchema;
    }[] = [];
    const storagesToCreate: {
      fileToUpload: DocumentItemState['fileToUpload'];
      type: StorageTypeEnum;
      quoteProp: keyof IQuoteRequestSchema;
    }[] = [];
    const updatedQuote = getQuoteRequestFromQuoteResponse(quote);

    // determine if storage needs to be deleted
    if (
      (msaData.activeItem !== 'file' || !!msaData.fileToUpload) &&
      msaData.savedData.storage
    ) {
      storagesToDelete.push({
        type: StorageTypeEnum.MSA,
        storage: msaData.savedData.storage,
        quoteProp: 'documentLocation',
      });
    }
    if (
      (sowData.activeItem !== 'file' || !!sowData.fileToUpload) &&
      sowData.savedData.storage
    ) {
      storagesToDelete.push({
        type: StorageTypeEnum.SOW,
        storage: sowData.savedData.storage,
        quoteProp: 'sowDocumentStorageId',
      });
    }
    if (
      (pdfCoverData.activeItem !== 'file' || !!pdfCoverData.fileToUpload) &&
      pdfCoverData.savedData.storage
    ) {
      storagesToDelete.push({
        type: StorageTypeEnum.COVER,
        storage: pdfCoverData.savedData.storage,
        quoteProp: 'coverDocumentStorageId',
      });
    }
    if (
      (pdfFooterData.activeItem !== 'file' || !!pdfFooterData.fileToUpload) &&
      pdfFooterData.savedData.storage
    ) {
      storagesToDelete.push({
        type: StorageTypeEnum.FOOTER,
        storage: pdfFooterData.savedData.storage,
        quoteProp: 'footerDocumentStorageId',
      });
    }

    // determine if storage needs to be added
    if (msaData.activeItem === 'file' && msaData.fileToUpload) {
      storagesToCreate.push({
        fileToUpload: msaData.fileToUpload,
        type: StorageTypeEnum.MSA,
        quoteProp: 'documentLocation',
      });
    }

    if (sowData.activeItem === 'file' && sowData.fileToUpload) {
      storagesToCreate.push({
        fileToUpload: sowData.fileToUpload,
        type: StorageTypeEnum.SOW,
        quoteProp: 'sowDocumentStorageId',
      });
    }

    if (pdfCoverData.activeItem === 'file' && pdfCoverData.fileToUpload) {
      storagesToCreate.push({
        fileToUpload: pdfCoverData.fileToUpload,
        type: StorageTypeEnum.COVER,
        quoteProp: 'coverDocumentStorageId',
      });
    }

    if (pdfFooterData.activeItem === 'file' && pdfFooterData.fileToUpload) {
      storagesToCreate.push({
        fileToUpload: pdfFooterData.fileToUpload,
        type: StorageTypeEnum.FOOTER,
        quoteProp: 'footerDocumentStorageId',
      });
    }

    // Determine if links need to be removed or updated
    let needsQuoteUpdate = false;
    // Determine request type
    let requestType = 'GET';

    if (msaData.activeItem === 'link' && msaData.linkUrl) {
      needsQuoteUpdate = true;
      requestType = 'PUT';
      updatedQuote.documentUrl = msaData.linkUrl;
      updatedQuote.documentLocation = null;
      setMsaData((priorData) => ({
        ...priorData,
        savedItemText: msaData.linkUrl,
      }));
    } else if (msaData.activeItem !== 'link' && msaData.savedData.link) {
      requestType = 'PUT';
      needsQuoteUpdate = true;
      updatedQuote.documentUrl = null;
    }

    setSaving(true);
    // DELETE STORAGE
    if (storagesToDelete.length > 0) {
      needsQuoteUpdate = true;
      for (const { storage, quoteProp } of storagesToDelete) {
        try {
          if (storage.type === StorageTypeEnum.MSA && msaData.linkUrl) {
            requestType = 'PUT';
          } else {
            requestType = 'GET';
          }
          await doDeleteQuoteStorageById(quote.id, storage.id, {
            type: storage.type,
          });

          (updatedQuote as any)[quoteProp] = null;
        } catch (ex) {
          logger.warn('Error deleting storage', ex);
        }
      }
    }

    // CREATE STORAGE
    if (storagesToCreate.length > 0) {
      requestType = 'GET';
      needsQuoteUpdate = true;
      for (const { fileToUpload, quoteProp, type } of storagesToCreate) {
        try {
          const { id } = await doUploadQuoteDocument(
            quote.id,
            fileToUpload?.file!,
            type,
          );
          (updatedQuote as any)[quoteProp] = id;
        } catch (ex) {
          logger.warn('Error creating storage', ex);
        }
      }
    }

    // UPDATE QUOTE WITH LINKS AND STORAGE IDs
    if (needsQuoteUpdate) {
      await onUpdate(updatedQuote, requestType);
      queryClient.invalidateQueries({
        queryKey: [[...cpqServiceQueryKeys.quoteStorage(quote.id)]],
      });
    }

    setSaving(false);
    handleClose && handleClose();
  }

  function handleActiveItemChange(
    type: 'msa' | 'sow' | 'cover' | 'footer',
    item: ActiveDocumentType,
  ) {
    handlerMap.setter[type]((priorData) => ({
      ...priorData,
      activeItem: item,
    }));
  }

  function handleAddItem(
    type: 'msa' | 'sow' | 'cover' | 'footer',
    item: { type: 'file'; value: File } | { type: 'link'; value: string },
  ) {
    if (item.type === 'file') {
      handlerMap.setter[type]((priorData) => ({
        ...priorData,
        fileToUpload: {
          file: item.value,
          fileName: item.value.name,
        },
        savedItemText: item.value.name,
      }));
    } else {
      handlerMap.setter[type]((priorData) => ({
        ...priorData,
        linkUrl: item.value,
        savedItemText: '', // for links, this is not set until save
      }));
    }
    setIsDirty(true);
  }

  function handleRemoveItem(type: 'msa' | 'sow' | 'cover' | 'footer') {
    handlerMap.setter[type]((priorData) => ({
      ...priorData,
      fileToUpload: undefined,
      linkUrl: '',
      activeItem: 'none',
      savedItemText: '',
    }));
    setIsDirty(true);
  }

  /**
   * Open PDF in new tab or go to link URL
   */
  async function handleGoToItem(
    type: 'msa' | 'sow' | 'signedQuote' | 'cover' | 'footer',
  ) {
    if (type === 'signedQuote') {
      if (quoteDocument.data?.contentBase64Encoded) {
        const base64Data = quoteDocument.data.contentBase64Encoded;
        const fileName = quoteDocument.data.filename;
        showPdfInNewTab(base64Data, fileName);
      }
    } else {
      if (handlerMap.getter[type].activeItem === 'file') {
        let base64Data = '';
        let fileName = '';
        if (handlerMap.getter[type].fileToUpload) {
          base64Data = await convertToBase64(
            handlerMap.getter[type].fileToUpload!.file,
          );
          // eslint-disable-next-line prefer-destructuring
          fileName = handlerMap.getter[type].fileToUpload!.fileName;
        } else if (
          handlerMap.getter[type].savedData.storage?.contentBase64Encoded
        ) {
          base64Data =
            handlerMap.getter[type].savedData.storage!.contentBase64Encoded!;
          fileName = handlerMap.getter[type].savedData.storage!.filename;
        }
        showPdfInNewTab(base64Data, fileName);
      } else {
        const link =
          handlerMap.getter[type].linkUrl ||
          handlerMap.getter[type].savedData.link;
        if (link) {
          window.open(link, '_blank');
        }
      }
    }
  }

  return {
    quoteDocument,
    msaData,
    sowData,
    pdfCoverData,
    pdfFooterData,

    msaDocument,
    sowDocument,
    pdfCoverDocument,
    pdfFooterDocument,

    isLoadingInitialData,

    handleSave,
    handleActiveItemChange,
    handleAddItem,
    handleRemoveItem,
    handleGoToItem,
  };
};
