import { StyleProps, Tab, useDisclosure } from '@chakra-ui/react';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RECURRING_REVENUE_PRODUCT_TYPES } from '../../../constants/products';
import {
  INewQuoteTypeReqSchema,
  IQuoteRespSchema,
  IRevenueMetricsQuoteItem,
  NewQuoteTypeEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '../../../types';
import {
  MBox,
  MButton,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MDropdownActionItem,
  MFlex,
  MTabList,
  MTabPanel,
  MTabPanels,
  MTabs,
  MText,
} from '../../Monetize';
import { QuoteAnalyticsCard } from './QuoteAnalyticsCard';
import { QuoteAnalyticsManualRenewalForm } from './QuoteAnalyticsManualRenewalForm';

interface QuoteAnalyticsV2Props {
  quote: IQuoteRespSchema;
  isReadOnly?: boolean;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  manualRenewalFormMethods?: UseFormReturn<INewQuoteTypeReqSchema, object>;
  iconButtonStyles?: StyleProps;
  renderTrigger?: (props: { onOpen: () => void }) => React.ReactElement;
}

interface AnalyticsItem {
  label: string;
  amount: number;
  offerings: {
    name: string;
    amount: number;
    products: {
      name: string;
      amount: number;
    }[];
  }[];
}

interface AnalyticsCards {
  arr: AnalyticsItem;
  incrementalArr?: AnalyticsItem;
  mrr: AnalyticsItem;
  incrementalMrr?: AnalyticsItem;
}

function getAnalyticsItem(
  quote: IQuoteRespSchema,
  label: string,
  type: keyof IRevenueMetricsQuoteItem,
) {
  const { revenueMetrics, quoteOfferings } = quote;
  return {
    label,
    amount: revenueMetrics[type],
    offerings: quoteOfferings.map((quoteOffering) => ({
      amount: quoteOffering.items.reduce(
        (amount, quoteItem) => amount + quoteItem.revenueMetrics[type],
        0,
      ),
      name: quoteOffering.offeringName,
      products: quoteOffering.items
        .filter((quoteItem) =>
          RECURRING_REVENUE_PRODUCT_TYPES.has(quoteItem.productType),
        )
        .map((quoteItem) => ({
          name: quoteItem.productName,
          amount: quoteItem.revenueMetrics[type],
        })),
    })),
  };
}

export const QuoteAnalyticsV2: FC<QuoteAnalyticsV2Props> = ({
  quote,
  isReadOnly,
  iconButtonStyles = {},
  setQuote,
  manualRenewalFormMethods,
  renderTrigger,
}: QuoteAnalyticsV2Props) => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [analyticsCards, setAnalyticsCards] = useState<AnalyticsCards | null>(
    null,
  );

  useEffect(() => {
    if (quote && isOpen) {
      const includeIncremental =
        quote.type !== QuoteTypeEnum.NEW ||
        quote.newQuoteType === NewQuoteTypeEnum.MANUAL_RENEWAL;

      setAnalyticsCards({
        arr: getAnalyticsItem(quote, 'ARR', 'arr'),
        incrementalArr: includeIncremental
          ? getAnalyticsItem(quote, 'Incremental ARR', 'incrementalArr')
          : undefined,
        mrr: getAnalyticsItem(quote, 'MRR', 'mrr'),
        incrementalMrr: includeIncremental
          ? getAnalyticsItem(quote, 'Incremental MRR', 'incrementalMrr')
          : undefined,
      });
    }
  }, [quote, isOpen]);
  const hasManualRenewalFormValue =
    manualRenewalFormMethods?.getValues('previousArr');

  return (
    <MBox position="relative" display="flex" alignItems="center">
      {renderTrigger ? (
        renderTrigger({ onOpen })
      ) : (
        <MDropdownActionItem
          key={'Quote Analytics'}
          onClick={onOpen}
          colorScheme="tPurple.base"
          display="flex"
          aria-label="Quote Analytics"
          alignItems="center"
          data-testid={`data-table-actions-Analytics`}
          role="group"
          w="100%"
        >
          <MText color="inherit">Quote Analytics</MText>
        </MDropdownActionItem>
      )}
      <MDrawer
        isOpen={isOpen}
        placement="right"
        size="sm"
        onClose={() => {
          manualRenewalFormMethods?.reset?.();
          onClose();
        }}
        returnFocusOnClose={false}
        blockScrollOnMount={false}
      >
        <MDrawerOverlay />
        <MDrawerContent>
          <MDrawerCloseButton />
          <MDrawerHeader>Quote Analytics</MDrawerHeader>

          <MDrawerBody p={0} bg="tGray.drawerSidebar">
            {analyticsCards && (
              <MTabs
                variant="line"
                size="sm"
                backgroundColor="tWhite.base"
                defaultIndex={0}
              >
                <MTabList px={6} pb={2}>
                  <Tab>Annual</Tab>
                  <Tab>Monthly</Tab>
                </MTabList>
                <MTabPanels p={2} bg="tGray.sidebarDark">
                  <MTabPanel p={0}>
                    <QuoteAnalyticsCard
                      currency={quote.currency}
                      label={analyticsCards.arr.label}
                      amount={analyticsCards.arr.amount}
                      offerings={analyticsCards.arr.offerings}
                    />
                    {analyticsCards.incrementalArr && (
                      <QuoteAnalyticsCard
                        currency={quote.currency}
                        label={analyticsCards.incrementalArr.label}
                        amount={analyticsCards.incrementalArr.amount}
                        offerings={analyticsCards.incrementalArr.offerings}
                      />
                    )}
                    {/* MANUAL RENEWAL FORM */}
                    {!!manualRenewalFormMethods &&
                      !!hasManualRenewalFormValue &&
                      quote.status === QuoteStatusEnum.DRAFT && (
                        <QuoteAnalyticsManualRenewalForm
                          isReadOnly={isReadOnly}
                          quote={quote}
                          setQuote={setQuote}
                          manualRenewalForm={manualRenewalFormMethods}
                        />
                      )}
                  </MTabPanel>
                  <MTabPanel p={0}>
                    <QuoteAnalyticsCard
                      currency={quote.currency}
                      label={analyticsCards.mrr.label}
                      amount={analyticsCards.mrr.amount}
                      offerings={analyticsCards.mrr.offerings}
                    />
                    {analyticsCards.incrementalMrr && (
                      <QuoteAnalyticsCard
                        currency={quote.currency}
                        label={analyticsCards.incrementalMrr.label}
                        amount={analyticsCards.incrementalMrr.amount}
                        offerings={analyticsCards.incrementalMrr.offerings}
                      />
                    )}
                  </MTabPanel>
                </MTabPanels>
              </MTabs>
            )}
          </MDrawerBody>
          <MDrawerFooter borderTop="1px" borderColor="inherit">
            <MBox w="full">
              <MFlex justifyContent="center">
                <MButton
                  variant="cancel"
                  justifySelf="center"
                  onClick={onClose}
                >
                  Close
                </MButton>
              </MFlex>
            </MBox>
          </MDrawerFooter>
        </MDrawerContent>
      </MDrawer>
    </MBox>
  );
};
