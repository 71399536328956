import { AmountUnitTypeEnum } from '../types';

export const AMOUNT_UNIT_DISPLAY_TEXT: {
  [key in AmountUnitTypeEnum]: string;
} = {
  FLAT: 'Flat',
  PERCENTAGE: 'Percent',
};

export const AMOUNT_UNIT_DISPLAY_SYMBOL: {
  [key in AmountUnitTypeEnum]: string;
} = {
  FLAT: '$',
  PERCENTAGE: '%',
};
