import { BaseResponseSchema } from '@monetize/types/common';
import { getRequiredMessage } from '@monetize/utils/core';
import { boolean, string, z } from 'zod';
import { CustomFieldRecordSchema } from './customFields.types';
import { ProductTypeEnumZ } from './enums.types';
import { UsageTypeResSchema } from './usage.types';

export enum PrePaidCreditConfigTypeEnum {
  ALL_PRODUCTS = 'ALL_PRODUCTS',
  USAGE_PRODUCTS = 'USAGE_PRODUCTS',
  SUBSCRIPTION_PRODUCTS_ONLY = 'SUBSCRIPTION_PRODUCTS_ONLY',
  SPECIFIC_PRODUCTS = 'SPECIFIC_PRODUCTS',
}
export enum ProductStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum RecognitionMethodEnum {
  IMMEDIATE = 'IMMEDIATE',
  EVENLY_MONTHLY_IN_ADVANCE = 'EVENLY_MONTHLY_IN_ADVANCE',
  // THESE DO NOT YET EXIST
  EVENLY_MONTHLY_IN_ARREARS = 'EVENLY_MONTHLY_IN_ARREARS',
  EVENLY_MONTHLY_12_MONTHS = 'EVENLY_MONTHLY_12_MONTHS',
  EVENLY_MONTHLY_3_MONTHS = 'EVENLY_MONTHLY_3_MONTHS',
}
export const ProductStatusEnumZ = z.nativeEnum(ProductStatusEnum);

export const PrePaidCreditConfigTypeEnumZ = z.nativeEnum(
  PrePaidCreditConfigTypeEnum,
);
export const RecognitionMethodEnumZ = z.nativeEnum(RecognitionMethodEnum);

// from POST /api/products
export const ProductSchema = z.object({
  id: z.string().optional(),
  customId: z.string().max(255).nullish(),
  financeId: z.string().nullish(),
  name: z
    .string({ required_error: getRequiredMessage('Name') })
    .min(1)
    .max(80),
  description: z
    .string({ required_error: getRequiredMessage('Description') })
    .min(1)
    .max(80),
  status: ProductStatusEnumZ,
  productType: ProductTypeEnumZ,
  usageTypeIds: z.array(z.string()).nullish(),
  sku: z.string().max(255).nullish(),
  // TODO: we should make this required, but requires all DB entries to be updated
  recognitionMethod: RecognitionMethodEnumZ.nullish(),
  oneTimePrepaidCreditConfig: z
    .object({
      type: PrePaidCreditConfigTypeEnumZ,
    })
    .nullish(),
  customFields: CustomFieldRecordSchema.optional(),
  taxItemCode: z.string().nullish(),
  taxExempt: z.boolean().default(false).nullish(),
});

export type IProductSchema = z.infer<typeof ProductSchema>;
export type IProduct = IProductSchema;

export const ProductResSchema = BaseResponseSchema.extend({
  customId: string().nullable(),
  financeId: string().nullable(),
  name: string(),
  description: string(),
  status: ProductStatusEnumZ,
  productType: ProductTypeEnumZ,
  usageTypes: z.array(UsageTypeResSchema).nullish(),
  // TODO: we should make this required, but requires all DB entries to be updated
  recognitionMethod: RecognitionMethodEnumZ.nullish(),
  sku: string().nullable(),
  locked: boolean(),
  customFields: CustomFieldRecordSchema.optional(),
  taxItemCode: z.string().nullish(),
  isMandatory: z.boolean().default(false),
  isSelected: z.boolean().default(false).nullish(),
  taxExempt: z.boolean().default(false).nullish(),
});
export type IProductResSchema = z.infer<typeof ProductResSchema>;

export const ProductResWithMandatorySchema = ProductResSchema.extend({
  isMandatory: z.boolean().default(false),
  isSelected: z.boolean().default(false).nullish(),
});
export type ProductResWithMandatory = z.infer<
  typeof ProductResWithMandatorySchema
>;

export const ProductSchemaUI = ProductSchema.extend({
  prices: z.array(z.any()).nullish(),
  createDate: z.union([z.string(), z.date()]).nullish(),
  createdBy: string().nullish(),
  modifyDate: string().nullish(),
  lastModifiedBy: string().nullish(),
});
export type IProductSchemaUI = z.infer<typeof ProductSchemaUI>;

export const SelectedProductsWithinOfferingSchema = ProductResSchema.pick({
  id: true,
  name: true,
  description: true,
  productType: true,
}).extend({
  quoteItemId: z.string().nullish(),
  isMandatory: z.boolean(),
  isSelected: z.boolean(),
});
export type SelectedProductsWithinOffering = z.infer<
  typeof SelectedProductsWithinOfferingSchema
>;

// wrapped in an object to make it easier to use with react-hook-form
export const SelectedProductsWithinOfferingFormSchema = z.object({
  products: SelectedProductsWithinOfferingSchema.array().refine(
    (values) => values.some(({ isSelected }) => isSelected),
    { message: `At least one product must be selected` },
  ),
});
export type SelectedProductsWithinOfferingForm = z.infer<
  typeof SelectedProductsWithinOfferingFormSchema
>;
