import React, { FunctionComponent as FC } from 'react';
import { DEFAULT_QUOTE_COLORS } from '../../constants/quotes';
import {
  AmountUnitTypeEnum,
  IQuoteItemRespSchema,
  IQuoteRespSchema,
  QuoteItemAmendmentStatusEnum,
} from '../../types';
import { getQuoteColors } from '../../utils';
import { MGrid } from '../Monetize';
import MAmountWithUnitTypeInput, {
  MAmountWithUnitTypeInputProps,
} from '../Monetize/MAmountWithUnitTypeInput';

export interface DiscountCellProps extends MAmountWithUnitTypeInputProps {
  item: IQuoteItemRespSchema;
  quote: IQuoteRespSchema;
  discountType?: AmountUnitTypeEnum | null;
  value: string;
  ref?: any;
  disableUnitTypeSelect?: boolean;
  disableUnitTypeMessage?: string;
}

export const DiscountCell: FC<DiscountCellProps> = React.forwardRef<
  any,
  DiscountCellProps
>(
  (
    { item, quote, discountType, value, ...rest }: DiscountCellProps,
    ref: any,
  ): React.ReactElement => {
    const isQtyChanged =
      (item?.amendmentStatus === QuoteItemAmendmentStatusEnum.UPDATED &&
        item?.previousCustomDiscountAmountOrPercent &&
        +value !== +item?.previousCustomDiscountAmountOrPercent) ||
      (item?.amendmentStatus === QuoteItemAmendmentStatusEnum.UPDATED &&
        discountType !== item?.previousCustomDiscountType);

    const quoteColors = isQtyChanged
      ? getQuoteColors(quote?.type)
      : DEFAULT_QUOTE_COLORS;

    // If the discount amount is greater than totalAmount then the flat discountAmount should be equals to
    // totalAmount making the total price of the item to $0.
    const getAmountCeiling = () => {
      const totalAmount = item.quantity * item.unitPrice;
      if (discountType === AmountUnitTypeEnum.FLAT && +value > totalAmount) {
        return totalAmount;
      }

      if (discountType === AmountUnitTypeEnum.PERCENTAGE && +value > 100) {
        return 100;
      }

      return +value;
    };

    const amountCeiling: number = getAmountCeiling();

    return (
      <MGrid alignItems="center">
        <MAmountWithUnitTypeInput
          ref={ref}
          /** Optimize for up to three characters for min-width */
          minWidth={`min(calc(3ch + 3rem), 5rem)`}
          name={`discount-${item.id}`}
          amountUnitType={discountType}
          value={value}
          amountCeiling={amountCeiling}
          currency={quote.currency}
          isDisabled={
            item?.amendmentStatus === QuoteItemAmendmentStatusEnum.REMOVED
          }
          containerColors={quoteColors}
          usePortal
          {...rest}
        />
      </MGrid>
    );
  },
);
