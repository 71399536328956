import React, { createContext, useContext, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useProductOfferings } from '../../../hooks';
import { QuoteDataTypes } from '../../../hooks/useQuote';
import { QuoteSettingDataTypes } from '../../../hooks/useQuoteSettings';
import {
  IContractWithQuotes,
  INewQuoteTypeReqSchema,
  IQuoteItemRespSchema,
  IQuotePrice,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IQuoteReviewReq,
  OrderableRecordsWithSequence,
  ProductTypeEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteTypeEnum,
} from '../../../types';
import { useQuoteContacts } from './components/contacts/useQuoteContacts';
import { OpportunityLinkDataTypes } from './OpportunityLink';
import { QuoteModalDataTypes } from './QuoteReviewModal';

type OfferingLoadingState = Record<
  string,
  {
    id: string;
    isLoading: boolean;
    isDeleting: boolean /** , changedFields: Set<string> */;
    modifiedItems?: Map<string, Set<string>>;
  }
>;

export interface QuoteStateDataTypes {
  /** Keeps track of which offerings are loading/deleting and which fields were modified */
  offeringLoadingState: OfferingLoadingState;
  isReviewBtnPressed: boolean;
  isSelectedOfferingAndRateIdsInactiveForInfo: boolean;
  isSelectedOfferingAndRateIdsInactiveForWarning: boolean;
  setIsSelectedOfferingAndRateIdsInactiveForWarning: (val: boolean) => void;
  setIsSelectedOfferingAndRateIdsInactiveForInfo: (val: boolean) => void;
  setOfferingLoadingState: React.Dispatch<
    React.SetStateAction<OfferingLoadingState>
  >;
  setIsReviewBtnPressed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useQuoteState = ({
  productOfferingsObj,
  quote,
  isLoading = false,
}: {
  productOfferingsObj: OrderableRecordsWithSequence;
  quote?: IQuoteRespSchema | null;
  isLoading?: boolean;
}): QuoteStateDataTypes => {
  const [
    isSelectedOfferingAndRateIdsInactiveForInfo,
    setIsSelectedOfferingAndRateIdsInactiveForInfo,
  ] = useState<boolean>(false);

  const [
    isSelectedOfferingAndRateIdsInactiveForWarning,
    setIsSelectedOfferingAndRateIdsInactiveForWarning,
  ] = useState<boolean>(false);

  // Keeps track of the review btn pressed
  const [isReviewBtnPressed, setIsReviewBtnPressed] = useState<boolean>(false);
  const [offeringLoadingState, setOfferingLoadingState] =
    useState<OfferingLoadingState>({});

  useEffect(() => {
    if (!isLoading && quote && productOfferingsObj.orderedIds.length > 0) {
      const selectedOfferingAndRateIdsAndItems: [
        string,
        string,
        IQuoteItemRespSchema[],
      ][] = quote?.quoteOfferings?.map(({ offeringId, rateId, items }) => [
        offeringId,
        rateId,
        items,
      ]);

      if (selectedOfferingAndRateIdsAndItems?.length) {
        setIsSelectedOfferingAndRateIdsInactiveForWarning(
          selectedOfferingAndRateIdsAndItems.some(
            ([offeringId, rateId, items]) => {
              const isOfferingOrRateActive: boolean =
                !!productOfferingsObj.byId[offeringId]?.ratesObj?.byId[rateId];
              return (
                !isOfferingOrRateActive &&
                items?.some(
                  ({ amendmentStatus }) =>
                    amendmentStatus === QuoteItemAmendmentStatusEnum.ADDED,
                )
              );
            },
          ),
        );

        if (
          quote?.type === QuoteTypeEnum.AMENDMENT ||
          quote?.type === QuoteTypeEnum.RENEWAL
        ) {
          setIsSelectedOfferingAndRateIdsInactiveForInfo(
            selectedOfferingAndRateIdsAndItems.some(
              ([offeringId, rateId, items]) => {
                const isOfferingOrRateActive: boolean =
                  !!productOfferingsObj.byId[offeringId]?.ratesObj?.byId[
                    rateId
                  ];
                return (
                  !isOfferingOrRateActive &&
                  items?.some(
                    ({ amendmentStatus }) =>
                      amendmentStatus !== QuoteItemAmendmentStatusEnum.ADDED,
                  )
                );
              },
            ),
          );
        }
      }
    }

    // If there is selected quoteOfferings but no productOfferings is empty
    // then the banner should be shown
    if (
      !isLoading &&
      quote?.quoteOfferings &&
      quote?.quoteOfferings?.length > 0 &&
      !productOfferingsObj.orderedIds.length
    ) {
      setIsSelectedOfferingAndRateIdsInactiveForInfo(false);
    }
  }, [quote, productOfferingsObj, isLoading]);

  return {
    offeringLoadingState,
    isReviewBtnPressed,
    isSelectedOfferingAndRateIdsInactiveForInfo,
    isSelectedOfferingAndRateIdsInactiveForWarning,
    setIsSelectedOfferingAndRateIdsInactiveForWarning,
    setIsSelectedOfferingAndRateIdsInactiveForInfo,
    setOfferingLoadingState,
    setIsReviewBtnPressed,
  };
};

export type QuoteContextTypes = {
  isReadOnly: boolean;
  isReadOnlyQuote: boolean;
  isLoading: boolean;
  isError: boolean;
  methods: UseFormReturn<IQuoteRequestSchema, object>;
  manualRenewalFormMethods: UseFormReturn<INewQuoteTypeReqSchema, object>;
  quoteData: QuoteDataTypes;
  productOfferingsData: ReturnType<typeof useProductOfferings>;
  opportunityLinkData: OpportunityLinkDataTypes;
  isTenantEsignConfigured: boolean;
  quoteStateData: QuoteStateDataTypes;
  reviewQuoteModalData: QuoteModalDataTypes;
  showPrimaryContactRequiredBg: boolean;
  quoteSettingsData: QuoteSettingDataTypes;
  quotePrices: IQuotePrice[];
  isEsignEnabled: boolean;
  /** Update quote without using the quote form, the results of the update will re-initialize the form state. */
  handleUpdateQuoteWithFormReset: (quote: IQuoteRequestSchema) => Promise<void>;
  handleReview: (data?: IQuoteReviewReq) => void;
  /** Debounced form submission, only submits if form is flagged as dirty. */
  handleSubmitButton: () => void;
  /** Debounced form submission that applies even if form is not considered dirty. */
  handleSubmitButtonWithoutDirtyCheck: () => void;
  handleBackButton: () => void;
  handleChangeOwner: (ownerId: string) => void;
  isInternalView: boolean;
  setIsInternalView: (val: boolean) => void;
  handleEdit: () => void;
  actionRunning: boolean;
  handleSendQuote: () => void;
  handleRecreateQuote: () => void;
  useAmendmentV2: boolean;
  loadData: () => void;
  reviewQuoteDisplayWidth: number;
  setReviewQuoteDisplayWidth: (val: number) => void;
  handleBackButtonPress: () => void;
  quoteContacts: ReturnType<typeof useQuoteContacts>;
  isQuoteSaving: boolean;
  setIsQuoteSaving: (val: boolean) => void;
  contract?: IContractWithQuotes;
};

const initialContext = {
  isReadOnly: false,
  isLoading: false,
  isError: false,
  handleReview: (data?: IQuoteReviewReq) => {},
  handleSubmitButton: () => {},
  handleSubmitButtonWithoutDirtyCheck: () => {},
  handleBackButton: () => {},
  handleChangeOwner: () => {},
};

export const checkIfOneTime = (item: any) =>
  item?.productType === ProductTypeEnum.ONETIME;

export const QuoteContext = createContext<QuoteContextTypes>(
  initialContext as any,
);

export const useQuoteContext = () => {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error(
      `useQuoteContext must be used rendered within the QuoteContextProvider`,
    );
  }
  return context;
};
