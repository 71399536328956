import { BaseResponseSchema } from '@monetize/types/common';
import {
  formDateStringZodSchema,
  getRequiredMessage,
} from '@monetize/utils/core';
import { boolean, z } from 'zod';
import { CustomFieldRecordSchema } from './customFields.types';
import { DiscountRespSchema } from './discount.types';
import { OfferingStatusEnumZ, OfferingTypesEnum } from './enums.types';
import { ProductResSchema } from './product.types';
import { IRateResBaseSchema, RateResBaseSchema } from './rate.types';

export enum OfferingTypesNameEnum {
  SUBSCRIPTION = 'Subscription',
  ONETIME = 'Onetime',
  MIN_COMMIT = 'Min Commit',
  CUSTOM_PERCENT_OF_TOTAL = 'Custom Percent of Total',
}

export const OfferingTypesEnumZ = z.nativeEnum(OfferingTypesEnum);

export interface IPrice {
  totalAmountAfterDiscount: number;
  totalAmount: number;
  perUnit: number;
  priceModel: string;
}

const offeringProductSchema = z.object({
  productId: z.string(),
  isMandatory: z.boolean(),
  position: z.number().optional(),
});

const OfferingProductGetSchema = z.object({
  product: ProductResSchema,
  isMandatory: z.boolean(),
});

export type IOfferingProductSchema = z.infer<typeof OfferingProductGetSchema>;

export const OfferingReqSchema = z.object({
  // these fields are probably an error in Swagger: https://monetizenow.atlassian.net/browse/BP-3869
  // createdBy: z.string().nullish(),
  // createDate: z.string().nullish(),
  // lastModifiedBy: z.string().nullish(),
  id: z.string().optional(),
  customId: z.string().nullish(),
  name: z
    .string({ required_error: getRequiredMessage('Name') })
    .max(80)
    .min(2, { message: 'Name must contain at least 2 character(s)' }),
  description: z.string().max(80).nullish(),
  status: OfferingStatusEnumZ,
  startDate: formDateStringZodSchema(z.string()).nullish(),
  endDate: formDateStringZodSchema(z.string()).nullish(),
  type: OfferingTypesEnumZ.default(OfferingTypesEnum.SUBSCRIPTION),
  offeringProducts: z.array(offeringProductSchema),
  customFields: CustomFieldRecordSchema.optional(),
});

export type IOfferingReqSchema = z.infer<typeof OfferingReqSchema>;

// from GET /api/offerings/sumary
export const OfferingSummaryResSchema = BaseResponseSchema.extend({
  customId: z.string(),
  name: z.string(),
  type: OfferingTypesEnumZ,
  description: z.string().nullable(),
  status: OfferingStatusEnumZ,
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  locked: boolean(),
});

export type IOfferingSummaryResSchema = z.infer<
  typeof OfferingSummaryResSchema
>;

// from GET /api/offerings/${id}
export const OfferingResSchema = BaseResponseSchema.extend({
  customId: z.string().nullable(),
  name: z.string(),
  type: OfferingTypesEnumZ,
  description: z.string().nullable(),
  status: OfferingStatusEnumZ,
  startDate: z.string().nullish(),
  endDate: z.string().nullish(),
  products: z.array(ProductResSchema),
  offeringProducts: z.array(OfferingProductGetSchema),
  rates: z.array(RateResBaseSchema),
  discounts: z.array(DiscountRespSchema),
  locked: boolean(),
});

export type IOfferingRes = z.infer<typeof OfferingResSchema>;

// from GET /api/subscriptions/{subscriptionId}
export const OfferingOnSubscriptionSchema = BaseResponseSchema.merge(
  OfferingResSchema.pick({
    customId: true,
    name: true,
    description: true,
    status: true,
    startDate: true,
    endDate: true,
  }),
);
export type IOfferingOnSubscription = z.infer<
  typeof OfferingOnSubscriptionSchema
>;

export type IOfferingResUI = IOfferingRes & {
  rightLabel?: string;
};

// from GET /api/orders/orderable:
export const OrderablesResSchema = z.object({
  offerings: z.array(OfferingResSchema),
});
export type IOrderablesRes = z.infer<typeof OrderablesResSchema>;

// UI purposes only:
export interface OfferingResWithRateRecords extends IOfferingRes {
  ratesObj: {
    orderedIds: string[];
    byId: Record<string, IRateResBaseSchema>;
  };
}
// UI purposes only:
export type OrderableRecordsWithSequence = {
  orderedIds: string[];
  byId: Record<string, OfferingResWithRateRecords>;
};

export type ReOrderRequestTypes = {
  order: string[];
};
