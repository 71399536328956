import { CardBody, Center, VStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { toDateShort } from '@monetize/utils/core';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../api/axios';
import { useAcceptAndProcessQuoteForQuoteShare } from '../../api/shareService';
import AppLoading from '../../components/App/AppLoading';
import { MBox, MButton, MCard, MFlex, MText } from '../../components/Monetize';
import { PAPER_PAGE_MAX_WIDTH } from '../../constants/quotes';
import { useFlags } from '../../services/launchDarkly';
import { logger } from '../../services/logger';
import Sentry from '../../services/sentry';
import { Maybe, QuoteStatusEnum } from '../../types';
import {
  QuoteShareForm,
  QuoteShareFormSchema,
} from '../../types/recordShareTypes';
import { getFormFromResponse } from './quote-share.utils';
import { QuoteShareHeaderContainer } from './QuoteShareHeaderContainer';
import { QuoteSharePdf } from './QuoteSharePdf';
import { QuoteShareStatusBanner } from './QuoteShareStatusBanner';
import { useQuoteShareData } from './useQuoteShareData';

export const QuoteSharePage = () => {
  const { quoteShareConfig } = useFlags();
  const requirePaymentMethod = !!quoteShareConfig?.paymentMethod?.required;

  const [stripeData, setStripeData] = useState<{
    stripe?: Maybe<Stripe>;
    elements?: Maybe<StripeElements>;
  }>();

  const {
    isLoading,
    isLoadingPdf,
    stripeKeys,
    quoteData,
    quoteDataHasError,
    quotePdfData,
    quotePdfHasError,
    recordShareAuthData,
  } = useQuoteShareData();

  const { mutateAsync: acceptOrProcessQuote, isPending: isSaveLoading } =
    useAcceptAndProcessQuoteForQuoteShare();

  const formMethods = useForm<QuoteShareForm>({
    resolver: zodResolver(QuoteShareFormSchema),
    defaultValues: getFormFromResponse(
      !!quoteShareConfig?.contact?.requireAddress,
      requirePaymentMethod,
      quoteData,
    ),
  });
  const { handleSubmit, reset } = formMethods;

  // initialize form with quote data
  useEffect(() => {
    if (quoteData) {
      reset(
        getFormFromResponse(
          !!quoteShareConfig?.contact?.requireAddress,
          requirePaymentMethod,
          quoteData,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quoteData,
    quoteShareConfig.contact?.requireAddress,
    requirePaymentMethod,
  ]);

  async function handleAcceptQuote(data: QuoteShareForm) {
    try {
      if (!quoteData) {
        return;
      }
      await acceptOrProcessQuote({
        authParams: recordShareAuthData,
        data,
        quoteShareData: quoteData,
        elements: stripeData?.elements,
        stripe: stripeData?.stripe,
      });
    } catch (ex) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          purchaseOrderNumber: data?.purchaseOrderNumber,
          paymentGatewayId: data?.payment?.paymentGatewayId,
          paymentMethodType: data?.payment?.paymentMethodType,
          paymentMethod: data?.payment?.paymentMethod,
          requireAddress: data?.config?.requireAddress,
        });
        Sentry.captureException(ex, {
          tags: {
            quoteId: recordShareAuthData.recordId,
            tenantId: recordShareAuthData.tenantId,
            type: 'QUOTE_SHARE',
          },
        });
      });
      handleApiErrorToast(ex);
      logger.error(ex);
    }
  }

  if (isLoading || !quoteData) {
    return <AppLoading />;
  }

  // Errors are handled in useQuoteShareData and displayed by the layout wrapper via query params
  if (quoteDataHasError || quotePdfHasError) {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <MBox bg="tGray.shareBack" minH="100vh">
        <MFlex
          bg="tBlue.dark"
          alignContent="center"
          justifyContent="space-between"
          px={12}
          py={4}
        >
          <LabelValue label="Quote for" value={quoteData.quote.accountName} />
          <LabelValue
            label="Expiration date"
            value={toDateShort(quoteData.quote.expirationDate)}
          />
        </MFlex>
        <form
          onSubmit={handleSubmit(handleAcceptQuote, (err) => logger.error(err))}
        >
          <MFlex
            width="100%"
            maxW={PAPER_PAGE_MAX_WIDTH}
            flexDirection="column"
            pb="10"
            px="12"
            alignItems="center"
            justifySelf="center"
          >
            <QuoteShareStatusBanner quote={quoteData.quote} />

            <QuoteSharePdf
              isLoading={isLoadingPdf}
              quoteId={quoteData.quote.id}
              quotePdfData={quotePdfData}
            />

            {quoteData.quote.status === QuoteStatusEnum.APPROVED && (
              <QuoteShareHeaderContainer
                requirePaymentMethod={requirePaymentMethod}
                stripeKeys={stripeKeys}
                onStripeInit={setStripeData}
                existingPaymentMethods={quoteData.paymentMethods}
              />
            )}
            {quoteData.quote.status === QuoteStatusEnum.APPROVED && (
              <MCard variant="border" w="100%" mt={2}>
                <CardBody>
                  <Center>
                    <VStack>
                      {quoteData.quote.requiresEsign && (
                        <MText textAlign="right" mt={1}>
                          {`After accepting, you'll complete the eSign process via email.`}
                        </MText>
                      )}
                      <MButton
                        variant="primary"
                        type="submit"
                        minW="auto"
                        px="50px"
                        isLoading={isSaveLoading}
                        isDisabled={isSaveLoading}
                      >
                        Accept Quote
                      </MButton>
                    </VStack>
                  </Center>
                </CardBody>
              </MCard>
            )}
          </MFlex>
        </form>
      </MBox>
    </FormProvider>
  );
};

function LabelValue({ label, value }: { label: string; value: string }) {
  return (
    <MFlex>
      <MText color="tWhite.text" mr={2} fontSize="md">
        {label}:
      </MText>
      <MText color="tWhite.text" fontSize="md" fontWeight="500">
        {value}
      </MText>
    </MFlex>
  );
}
