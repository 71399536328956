import { FunctionComponent as FC } from 'react';

import { ListItem, UnorderedList } from '@chakra-ui/react';
import { MBox } from './chakra';
import MText from './MText';

type MTooltipContentListProps = {
  listItems: string[];
  title?: string;
};

export const MTooltipContentList: FC<MTooltipContentListProps> = (
  props: MTooltipContentListProps,
) => {
  const { listItems, title } = props;

  return (
    <MBox maxW={180}>
      <MText
        border={0}
        pb={0}
        fontWeight="bold"
        color="tWhite.base"
        fontSize={12}
      >
        {title}
      </MText>
      <UnorderedList ml="4">
        {listItems.map((msg, idx) => {
          return (
            <ListItem key={`msg-${idx}`}>
              <MText pt={0} color="tWhite.base" fontSize={12}>
                {msg}
              </MText>
            </ListItem>
          );
        })}
      </UnorderedList>
    </MBox>
  );
};
