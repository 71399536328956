import { ENVIRONMENT } from '@monetize/ui/config';

/* eslint-disable no-console */
interface Logger {
  isEnabled: boolean;
  log: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
  group: (...args: any[]) => void;
  groupCollapsed: (...args: any[]) => void;
  groupEnd: (...args: any[]) => void;
}

const IS_TEST = ENVIRONMENT.JEST_WORKER_ID !== undefined;

// In production
const LOG_NOOP = (...logs: any[]) => {};

export const logger: Logger = {
  isEnabled: false,
  log: LOG_NOOP,
  info: LOG_NOOP,
  warn: LOG_NOOP,
  /** Always log errors to the console to aid in production troubleshooting */
  error: console.error.bind(console, '%c ERROR', 'font-weight: bold;'),
  group: LOG_NOOP,
  groupCollapsed: LOG_NOOP,
  groupEnd: LOG_NOOP,
};

export const enableLogger = (enable: boolean) => {
  // ensure logging is disabled in jest tests
  if (IS_TEST) {
    return;
  }
  logger.isEnabled = enable;
  if (!enable) {
    logger.log = LOG_NOOP;
    logger.info = LOG_NOOP;
    logger.warn = LOG_NOOP;
    logger.group = LOG_NOOP;
    logger.groupCollapsed = LOG_NOOP;
    logger.groupEnd = LOG_NOOP;
  } else {
    // bind is used to ensure that we get accurate line numbers from where the log was produced instead of always showing logger.ts
    try {
      logger.log = console.log.bind(
        console,
        '%c DEBUG',
        'color: blue; font-weight: bold;',
      );
      logger.info = console.info.bind(
        console,
        '%c INFO',
        'color: green; font-weight: bold;',
      );
      logger.warn = console.warn.bind(console, '%c WARN', 'font-weight: bold;');
      logger.group = console.group.bind(console);
      logger.groupCollapsed = console.groupCollapsed.bind(console);
      logger.groupEnd = console.groupEnd.bind(console);
    } catch (ex) {
      // fail silently
    }
  }
};

if (ENVIRONMENT.IS_DEV) {
  enableLogger(true);
  logger.info('[LOGGING ENABLED]', { ENVIRONMENT });
}

export function logFormError(err: unknown) {
  console.warn('[FORM ERROR]', err);
}
