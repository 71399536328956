import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { handleApiErrorToast } from '../../../api/axios';
import { useGetListData, useUpdateEntity } from '../../../api/queryUtils';
import { useArchiveRuleV3 } from '../../../api/ruleServiceV3';
import DataTableActions from '../../../components/Monetize/DataTable/MDataTableActions';
import { tablePagerSelector } from '../../../store/global.store';
import { FilterStateKeys } from '../../../store/store.types';
import {
  ApiListResponse,
  FilterType,
  GetListApiFilter,
  IRuleReqSchema,
  IRuleResSchema,
  RuleStatusEnum,
  RuleTypeEnum,
} from '../../../types';
import { buildFilterParamsRequestObject } from '../../../utils';
import { RULE_DATA_KEY_V3, getTitleByType } from './rules.utils';

interface UseRuleTableDataProps {
  type: RuleTypeEnum;
  V3?: boolean;
}

const useRuleTableData = ({ type }: UseRuleTableDataProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() => ({
    type,
    ...buildFilterParamsRequestObject(filters, globalFilter, 'name'),
  }));
  const { queryKey, getEditPage, getClonePage } = RULE_DATA_KEY_V3[type];
  const [pager, setPager] = useRecoilState(
    tablePagerSelector(
      type === RuleTypeEnum.APPROVAL
        ? FilterStateKeys.APPROVAL_RULES_LIST
        : FilterStateKeys.VALIDATION_RULES_LIST,
    ),
  );
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setFilterParams({
      type,
      ...buildFilterParamsRequestObject(filters, globalFilter, 'name'),
    });
  }, [type, pager, globalFilter, filters]);

  const { isLoading, data: rules } = useGetListData<
    ApiListResponse<IRuleResSchema>
  >(
    queryKey,
    {
      config: pager,
      filters: filterParams,
    },
    {
      meta: { showErrorToast: true },
    },
  );

  const { mutateAsync: mutateUpdateRule } = useUpdateEntity<
    IRuleReqSchema,
    IRuleReqSchema
  >(queryKey, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['rules'],
      });
    },
    meta: { showErrorToast: true },
  });

  const { mutate: doRuleArchivedV3 } = useArchiveRuleV3({
    onError: (err) => {
      handleApiErrorToast(err);
    },
  });

  useEffect(() => {
    setHasLoaded((prevValue) => prevValue || !isLoading);
  }, [isLoading]);

  const doUpdateActiveInactive = (
    isApprovalActive: boolean,
    data: IRuleResSchema,
  ) => {
    const payload = { ...data };
    payload.status = isApprovalActive
      ? RuleStatusEnum.INACTIVE
      : RuleStatusEnum.ACTIVE;

    return mutateUpdateRule({ id: payload.id, payload });
  };

  const actionBodyTemplate = (rowData: IRuleResSchema) => {
    const { id, status, name } = rowData;

    const title = getTitleByType(type);
    const isActive = status === RuleStatusEnum.ACTIVE;
    const isArchived = status === RuleStatusEnum.ARCHIVED;
    const deActiveConfirmProps = {
      title: `Are you sure you want to deactivate "${name}"?`,
      description: `Once deactivated, this ${title.single.toLowerCase()} will no longer be available.`,
      yesButton: 'Deactivate',
    };
    const confirmProps = {
      title: `Are you sure you want to archive "${name}"?`,
      description: `Once archived, this ${title.single.toLowerCase()} will no longer be available.`,
    };
    if (id) {
      const actions = [
        {
          title: 'Clone',
          enabled: [
            RuleTypeEnum.APPROVAL,
            RuleTypeEnum.CONDITIONAL_TERM,
            RuleTypeEnum.VALIDATION,
          ].includes(type),
          action: () =>
            navigate(getClonePage(), {
              state: {
                action: 'clone',
                rule: rowData,
              },
            }),
        },
        {
          title: 'Edit',
          enabled: true,
          action: () => navigate(getEditPage(id)),
        },
        {
          title: 'Activate',
          enabled: !isActive,
          action: () => doUpdateActiveInactive(isActive, rowData),
        },
        {
          title: 'Deactivate',
          color: 'danger',
          confirmProps: deActiveConfirmProps,
          enabled: isActive && !isArchived,
          action: () => doUpdateActiveInactive(isActive, rowData),
        },
        {
          title: 'Archive',
          color: 'danger',
          confirmProps,
          // enabled: !isActive && !isArchived,
          enabled: false, // Disable archiving rules until we have a UX for archived rules
          action: () => doRuleArchivedV3({ ruleId: id, type }),
        },
      ];
      return <DataTableActions actions={actions} />;
    }

    return null;
  };

  return {
    filters,
    setFilters,
    globalFilter,
    setGlobalFilter,
    pager,
    setPager,
    loading: isLoading,
    hasLoaded,
    rules,
    mutateUpdateRule,
    doUpdateActiveInactive,
    actionBodyTemplate,
  };
};

export { useRuleTableData };
