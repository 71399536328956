import { useEffect, useState } from 'react';
import { useGetPaymentsByAccount } from '../../api/accountsService';
import {
  DEFAULT_API_RESPONSE,
  DEFAULT_PAGER,
  FilterType,
  GetListApiFilter,
  TDataTablePager,
} from '../../types';
import { buildFilterParamsRequestObject } from '../../utils';

export const useAccountPayments = ({
  accountId,
  billGroupId,
}: {
  accountId: string;
  billGroupId?: string;
}) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);

  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters),
  );

  const {
    isLoading,
    isFetched,
    isRefetching,
    data: payments,
    refetch,
  } = useGetPaymentsByAccount(
    {
      accountId,
      config: pager,
      filters: filterParams,
    },
    { meta: { showErrorToast: true } },
  );

  useEffect(() => {
    setFilterParams(buildFilterParamsRequestObject(filters!));
  }, [pager, filters]);

  const onResetFilter = () => {
    setFilters([]);
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    refetch,
    totalRecords: payments?.totalElements || 0,
    loading: isLoading || (isRefetching && !isFetched),
    listData: payments || DEFAULT_API_RESPONSE,
  };
};
