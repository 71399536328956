import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MButton,
  MFlex,
  MHeading,
  MHStack,
} from '../../../components/Monetize';
import { ROUTES } from '../../../constants';

interface ITableHeaderProps {
  model: string;
  children: React.ReactNode;
  showCreateNewBtn: boolean;
}

const TableHeader = ({
  children,
  model,
  showCreateNewBtn,
}: ITableHeaderProps) => {
  const navigate = useNavigate();

  const handleCreateNew = () => {
    switch (model) {
      case 'Product':
        navigate(ROUTES.PRODUCT_NEW);
        break;

      case 'Offering':
        navigate(ROUTES.OFFERING_NEW);
        break;

      case 'Discount':
        navigate(ROUTES.DISCOUNT_NEW);
        break;

      case 'Usage Type':
        navigate(ROUTES.USAGE_TYPE_NEW);
        break;

      default:
        break;
    }
  };

  return (
    <MFlex w="100%" mb={4} justifyContent="space-between" alignItems="center">
      <MHeading size="md">{`${model}s`}</MHeading>
      <MHStack spacing={4} shouldWrapChildren alignItems="center">
        {children}
        {showCreateNewBtn && (
          <MButton variant="secondary" onClick={handleCreateNew}>
            New {model}
          </MButton>
        )}
      </MHStack>
    </MFlex>
  );
};

export default TableHeader;
