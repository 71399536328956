import { UserStatusEnum, UserTypeEnum } from '../types';
import { objectToObjArray } from '../utils/misc';

export const USER_STATUS_DISPLAY_STRING: {
  [key in UserStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  INVITED: 'Invited',
};

export const USER_STATUS_DISPLAY: {
  [key in UserStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  DEACTIVATED: 'Deactivated',
  INVITED: 'Invited',
};

export const userStatusItems = objectToObjArray(
  USER_STATUS_DISPLAY,
  'value',
  'label',
);

export const USER_TYPE_DISPLAY: {
  [key in UserTypeEnum]: string;
} = {
  APP: 'API Key',
  USER: 'User',
};
