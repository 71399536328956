import { TagProps } from '@chakra-ui/react';
import { MTag } from '../../../../components/Monetize';
import { NewQuoteTypeEnumDisplay, QuoteTypeEnumDisplay } from '../../../../constants/quotes';
import { IQuoteRespSchema, NewQuoteTypeEnum } from '../../../../types';
import { getVariantByStatus } from '../../../../utils';

interface IQuoteTypeTagProps extends TagProps {
  quote: IQuoteRespSchema;
}

export const QuoteTypeTag = ({ quote, ...rest }: IQuoteTypeTagProps) => {
  const variant = getVariantByStatus(quote.type);

  if (quote.newQuoteType === NewQuoteTypeEnum.EARLY_RENEWAL) {
    return (
      <MTag variant={variant} {...rest}>
        {NewQuoteTypeEnumDisplay[quote.newQuoteType]}
      </MTag>
    );
  }

  return (
    <MTag variant={variant} {...rest}>
      {QuoteTypeEnumDisplay[quote.type]}
    </MTag>
  );
};
