import { Tab } from '@chakra-ui/react';
import {
  MTabList,
  MTabPanel,
  MTabPanels,
  MTabs,
} from '../../../components/Monetize';
import { useAuth } from '../../../services/auth0';
import { ITenant } from '../../../types';
import { TenantList } from './TenantList';

interface TenantTabsProps {
  origin?: 'modal' | 'page';
  tenants: ITenant[];
  onSwitchTenant: (tenant: ITenant) => void;
  onAcceptInvite: (tenant: ITenant) => void;
  onEdit?: (tenant: ITenant) => void;
  onDelete?: (tenant: ITenant) => void;
}

export const TenantTabs = ({
  origin,
  tenants,
  onSwitchTenant,
  onAcceptInvite,
  onEdit,
  onDelete,
}: TenantTabsProps) => {
  const { currentTenant } = useAuth();
  const testTenants = tenants.filter(
    (tenant) => tenant.id !== currentTenant?.id && tenant.testTenant,
  );
  const liveTenants = tenants.filter(
    (tenant) => tenant.id !== currentTenant?.id && !tenant.testTenant,
  );

  if (!testTenants.length && !liveTenants.length) {
    return null;
  }

  return (
    <MTabs
      variant="line"
      size="sm"
      backgroundColor="tWhite.base"
      defaultIndex={0}
    >
      <MTabList>
        {!!testTenants.length && (
          <Tab data-testid="tab-test-tenant">Test Tenants</Tab>
        )}
        {!!liveTenants.length && (
          <Tab data-testid="tab-live-tenant">Live Tenants</Tab>
        )}
      </MTabList>

      <MTabPanels p={0}>
        {!!testTenants.length && (
          <MTabPanel p={0} data-testid="tabpanel-test-tenant">
            <TenantList
              origin={origin}
              tenants={testTenants}
              onSwitchTenant={onSwitchTenant}
              onAcceptInvite={onAcceptInvite}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </MTabPanel>
        )}

        {!!liveTenants.length && (
          <MTabPanel p={0} data-testid="tabpanel-live-tenant">
            <TenantList
              origin={origin}
              tenants={liveTenants}
              onSwitchTenant={onSwitchTenant}
              onAcceptInvite={onAcceptInvite}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </MTabPanel>
        )}
      </MTabPanels>
    </MTabs>
  );
};
