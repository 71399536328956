import { isAxiosError } from '../../api/axios';
import ErrorPage from '../../routes/ErrorPages/ErrroPage';

const MInlineErrorPage = ({
  error,
  reFetchData,
}: {
  error: any;
  reFetchData: () => void;
}) => {
  let errMessage: string = error?.message;
  if (isAxiosError(error) && (error.response?.data as any)?.message) {
    errMessage = (error.response?.data as any)?.message;
  }

  return <ErrorPage inlineErrMessage={errMessage} reFetchData={reFetchData} />;
};

export default MInlineErrorPage;
