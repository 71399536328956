import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { FunctionComponent as FC } from 'react';
import { Link } from 'react-router-dom';
import { useGetSalesMonthly } from '../../../../../api/dashboardsService';
import {
  MBox,
  MChart,
  MFlex,
  MLink,
  MSkeleton,
  MText,
} from '../../../../../components/Monetize';
import { ROUTES } from '../../../../../constants';
import { logger } from '../../../../../services/logger';
import { colors } from '../../../../../styles/theme';
import { SalesCreatedByFilter } from '../../../../../types';
import { formatCurrency, sortDateByAscendingOrder } from '../../../../../utils';
import { BASE_BAR_CHART_OPTIONS } from '../../../../../utils/charts';
import { salesChartOptions, salesTooltipStyles } from '../chartOptions';
import './styles.scss';

type Point = {
  x: string;
  y: number;
};

const chartColors = [
  colors.tBlue.totalBlue,
  colors.tPurple.safety,
  colors.tGreen.lime,
];

const ProcessedQuotesChart: FC<{
  createdByFilter: SalesCreatedByFilter;
  currency: string;
}> = ({ createdByFilter, currency }) => {
  const { isLoading, data: sales } = useGetSalesMonthly(
    { query: { ...createdByFilter, currency } },
    {
      select: (data) => {
        const sortedData = sortDateByAscendingOrder(
          data.monthlySales,
          'yearMonth',
        );
        const newData: Point[] = [],
          amendData: Point[] = [],
          renewData: Point[] = [];
        sortedData.forEach((sorted) => {
          const { yearMonth, newCount, amendCount, renewalCount, amount } =
            sorted;
          const total = newCount + amendCount + renewalCount;
          const x = formatDate(parseISO(yearMonth), 'MMM yy');
          if (total > 0) {
            newData.push({ x, y: (newCount / total) * amount });
            amendData.push({ x, y: (amendCount / total) * amount });
            renewData.push({ x, y: (renewalCount / total) * amount });
          } else {
            newData.push({ x, y: 0 });
            amendData.push({ x, y: 0 });
            renewData.push({ x, y: 0 });
          }
        });

        return {
          options: {
            ...salesChartOptions(currency),
            fill: {
              colors: chartColors,
            },
            colors: chartColors,
            chart: {
              ...BASE_BAR_CHART_OPTIONS.chart,
              stacked: true,
            },

            tooltip: {
              custom: (chartData: any) => {
                try {
                  const { series, seriesIndex, dataPointIndex } = chartData;
                  return `<div class="custom-tooltip" style="${salesTooltipStyles}"> 
                    <p>${newData ? newData[dataPointIndex].x : ''}</p>
                    <p>Total: <b style="font-size: 0.875rem">${formatCurrency(
                      sortedData[dataPointIndex].amount,
                      { currency },
                    )}</b></p>
                    <p>New: ${formatCurrency(
                      sortedData[dataPointIndex].newCount,
                      { currency },
                    )}</p>
                    <p>Amendment: ${formatCurrency(
                      sortedData[dataPointIndex].amendCount,
                      { currency },
                    )}</p>
                    <p>Renewal: ${formatCurrency(
                      sortedData[dataPointIndex].renewalCount,
                      { currency },
                    )}</p>
                  </div>`;
                } catch (err) {
                  logger.warn(err);
                  return '';
                }
              },
            },
            xaxis: {
              ...BASE_BAR_CHART_OPTIONS.xaxis,
              labels: {
                ...BASE_BAR_CHART_OPTIONS.labels,
                rotateAlways: false,
                rotate: 0,
              },
            },
          },
          series: [
            {
              name: 'New',
              data: newData,
            },
            {
              name: 'Amendment',
              data: amendData,
            },
            {
              name: 'Renewal',
              data: renewData,
            },
          ],
        };
      },
    },
  );

  return (
    <MBox h="400px">
      <MFlex alignItems="center">
        <MText fontSize="2xl" fontWeight="bold">
          Processed Quotes
        </MText>
        <MLink
          fontSize="sm"
          as={Link}
          to={ROUTES.SALES_QUOTES_ROUTE}
          cursor="pointer"
          ml="3"
          mt="1"
        >
          View Quotes
        </MLink>
      </MFlex>
      {isLoading ? (
        <MSkeleton height="20px" w="100%" />
      ) : (
        <MChart
          options={sales?.options || {}}
          series={sales?.series || []}
          type="bar"
          height={350}
          className="sales-processed-quotes-chart"
        />
      )}
    </MBox>
  );
};

export { ProcessedQuotesChart };
