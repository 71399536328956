import { useRecoilValue } from 'recoil';
import {
  MBox,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import { appGlobalDataState } from '../../../../../store/global.store';
import { QuoteSettingsDefaultAddressSourceEnum } from '../../../../../types';

interface AddressSourceSettingProps {
  isLoading?: boolean;
  value?: QuoteSettingsDefaultAddressSourceEnum;
  onChange: (
    value: QuoteSettingsDefaultAddressSourceEnum,
  ) => void | Promise<void>;
}

export const AddressSourceSetting = ({
  isLoading = false,
  value = QuoteSettingsDefaultAddressSourceEnum.CONTACT,
  onChange,
}: AddressSourceSettingProps) => {
  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);

  return (
    <MBox w="100%">
      <MText fontSize="md" fontWeight="600">
        Address Sources
      </MText>
      <MText my="2">
        Select the source of Shipping and Billing Addresses on New Quotes.
        <br />
        Addresses from Accounts are defined on CRM settings.
      </MText>
      <MRadioGroup onChange={onChange} value={value} isDisabled={isLoading}>
        <MStack spacing={2} direction="column" color="tGray.darkPurple">
          <MRadio
            value={QuoteSettingsDefaultAddressSourceEnum.ACCOUNT}
            isDisabled={!hasCrmConfigured || isLoading}
          >
            <MTooltip
              label="CRM must be connected to use Account Addresses"
              placement="bottom-start"
              shouldWrapChildren
              isDisabled={hasCrmConfigured}
            >
              <MText
                display="inline-flex"
                alignItems="center"
                color="currentColor"
              >
                Source from Account Addresses
              </MText>
            </MTooltip>
          </MRadio>
          <MRadio value={QuoteSettingsDefaultAddressSourceEnum.CONTACT}>
            Source from Contacts
          </MRadio>
        </MStack>
      </MRadioGroup>
    </MBox>
  );
};
