import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import AppLoading from '../../components/App/AppLoading';
import {
  MBox,
  MButton,
  MHeading,
  MPageContainer,
  MText,
} from '../../components/Monetize';
import { ROUTES } from '../../constants';
import { AUTH0_ERROR_MAPPING } from '../../constants/auth0';
import { useQuery } from '../../hooks';
import { useAuth } from '../../services/auth0';
import { logger } from '../../services/logger';
import {
  currentTenantState,
  tempRedirectInfoState,
} from '../../store/global.store';
import { toTitleCase } from '../../utils';

const CallbackError = ({
  description,
  error,
}: {
  description: string;
  error: string;
}) => {
  const desc = (AUTH0_ERROR_MAPPING as any)[description] || description;

  return (
    <MPageContainer>
      <MBox
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        flex={1}
      >
        <MHeading fontSize="3xl" mb="4" color="tPurple.base">
          {/* turns access_denied into Access Denied */}
          {error.split('_').map(toTitleCase).join(' ')}
        </MHeading>
        <MText size="lg" textAlign="center">
          {desc}
        </MText>

        <MButton variant="secondary" my="4" as={Link} to={ROUTES.AUTH_LOGOUT}>
          Back to Login
        </MButton>
      </MBox>
    </MPageContainer>
  );
};

const Callback = () => {
  const { getAndHandleAccessTokenSilently } = useAuth();
  const { isAuthenticated, isLoading, user } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const error = query.get('error') || '';
  const errorDescription = query.get('error_description') || '';
  const [redirectInfo, setRedirectInfo] = useRecoilState(tempRedirectInfoState);
  const currentTenant = useRecoilValue(currentTenantState);

  const getUserMetadata = async () => {
    try {
      await getAndHandleAccessTokenSilently();
      let navigateTo = ROUTES.ROOT;

      // navigate to redirect URL if set
      const tenantId = redirectInfo?.tenantId || currentTenant?.id;
      if (redirectInfo?.redirectUrl && tenantId) {
        // if correct current tenant is already selected, go to route directly
        if (redirectInfo.tenantId === currentTenant?.id) {
          navigateTo = redirectInfo.redirectUrl;
        } else if (!user?.email_verified) {
          navigateTo = ROUTES.ONBOARD_VERIFY_EMAIL;
        } else {
          // ensure user is in correct tenant based on saved redirect url
          navigateTo = ROUTES.getSwitchTenantRoute(
            tenantId,
            redirectInfo.redirectUrl,
          );
        }
      }

      if (redirectInfo) {
        setRedirectInfo(null);
      }

      navigate(navigateTo);
    } catch (ex) {
      logger.error(ex);
      navigate(ROUTES.AUTH_LOGOUT);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !isLoading && user) {
      getUserMetadata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, user]);

  /**
   * Handle error when error is valid string
   * example: https://dev.monetizeplatform.com/auth/callback?error=unauthorized&error_description=Your%20company%20admin%20is%20preventing%20login%20with%20this%20method%20of%20authentication%2C%20please%20try%20another%20method%20such%20as%20Google%20Login.&state=MkNkUWczdmhWMFBTX0ZJaDlxSWgtWWJWRXlpT21kVEVZMVpWZUJQSzVENw%3D%3D
   */
  if (error) {
    return <CallbackError error={error} description={errorDescription} />;
  }

  return <AppLoading loadingText="Logging You in..." />;
};

export default Callback;
