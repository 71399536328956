import { OpportunityStatusEnum } from '../types';

export const OPPORTUNITY_STATUS_DISPLAY: {
  [key in OpportunityStatusEnum]: string;
} = {
  WON: 'Won',
  CANCELED: 'Canceled',
  ACTIVE: 'Active',
  LOST: 'Lost',
};
