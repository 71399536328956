import isBoolean from 'lodash/isBoolean';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { ZodError } from 'zod';

export function getErrorMessage(ex: unknown) {
  if (ex instanceof ZodError) {
    const errorDetails = Object.values(
      ex.flatten((issue) => ({
        message: `Invalid request: '${issue.path.join('.')}' is ${
          issue.message
        }`,
        errorCode: issue.code,
      })).fieldErrors,
    );

    const formattedMessage = errorDetails
      .flatMap((item) => item)
      .map((item) => item?.message)
      .filter(Boolean)
      .join(', ');

    return formattedMessage;
  } else if (ex instanceof Error) {
    return ex.message;
  }
  return JSON.stringify(ex);
}

export function getErrorStack(ex: unknown) {
  return ex instanceof Error ? ex.stack : null;
}

export function getLogObjectFromError(
  ex: unknown,
  extra?: Record<string, unknown>,
) {
  return { message: getErrorMessage(ex), stack: getErrorStack(ex), ...extra };
}

export const getRequiredMessage = (title: string) => `${title} is required.`; // error msg for empty input

export function ensureBoolean(value: unknown) {
  if (isBoolean(value)) {
    return value;
  } else if (isString(value)) {
    return value.toLowerCase().startsWith('t');
  }
  return false;
}

export function ensureNumber(value: unknown, defaultValue = 0) {
  if (isNumber(value) && !isNaN(value)) {
    return value;
  } else if (isString(value) && /^-?\d*(\.\d+)?$/.test(value)) {
    return parseFloat(value);
  }
  return defaultValue;
}

export function ensureArray(value: unknown) {
  if (Array.isArray(value)) {
    return value;
  } else if (isNil(value)) {
    return [];
  }
  return [value];
}

/**
 * Not 10000% perfect, but will ensure numbers that are close enough are considered equal
 * normalizing away precision
 *
 * @param a
 * @param b
 * @returns
 */
export function areNumbersEqual(a: number, b: number) {
  return Math.abs(a - b) < Number.EPSILON;
}
