import { useNavigate } from 'react-router';
import { ROLES, ROUTES } from '../../constants';
import { useAuth } from '../../services/auth0';
import { Maybe } from '../../types';
import { getTaxCalculationError } from '../../utils/invoices';
import { MAlert, MButton, MFlex, MText } from '../Monetize';

interface getTaxCalculationErrorProps {
  taxError?: Maybe<string>;
  showReviewAction?: boolean;
  onOpenEditContactModal?: () => void;
}

export const InvoiceTaxCalculationError = ({
  taxError,
  showReviewAction = false,
  onOpenEditContactModal,
}: getTaxCalculationErrorProps) => {
  const navigate = useNavigate();
  const { currentTenantUserHasRole } = useAuth();
  const canUpdateTax = currentTenantUserHasRole(ROLES.SETTINGS_TAX_ROLES);

  if (!taxError) {
    return null;
  }

  const { message, reviewAction } = getTaxCalculationError(
    taxError,
    canUpdateTax && showReviewAction,
  );

  function handleClickedAction(action: 'ADDRESS' | 'TAX-CONFIG') {
    switch (action) {
      case 'ADDRESS':
        onOpenEditContactModal?.();
        break;
      case 'TAX-CONFIG':
        navigate(ROUTES.SETTINGS_TAX);
        break;
    }
  }

  return (
    <MAlert type="error" mb="4">
      <MFlex align="center">
        <MText>{message}</MText>
        {reviewAction && onOpenEditContactModal && showReviewAction && (
          <MButton
            pl={1}
            variant="link"
            size="sm"
            _hover={{ bg: 'none' }}
            onClick={() => handleClickedAction(reviewAction)}
          >
            Review
          </MButton>
        )}
      </MFlex>
    </MAlert>
  );
};
