import { Heading } from '@chakra-ui/react';
import { MouseEvent } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import {
  MBox,
  MButton,
  MFlex,
  MHStack,
  MIcon,
  MPageHeader,
} from '../../../../components/Monetize';

interface UsagePageHeaderProps {
  hideHeader: boolean;
  isExpandedAll: boolean;
  onToggleExpandAll: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const UsagePageHeader = ({
  hideHeader,
  isExpandedAll,
  onToggleExpandAll,
}: UsagePageHeaderProps) => {
  return (
    <MPageHeader
      hideContent={hideHeader}
      renderLeftSection={
        <MFlex alignItems="center">
          <Heading size="lg" color="tPurple.base">
            Usage
          </Heading>
        </MFlex>
      }
    >
      <MHStack spacing="2">
        <MBox w={120}>
          <MButton
            variant="tertiary"
            rightIcon={
              <MIcon
                as={MdArrowDropDown}
                w="4"
                h="4"
                transform={isExpandedAll ? 'rotate(180deg)' : undefined}
                transition="all ease 0.3s"
              />
            }
            onClick={onToggleExpandAll}
          >
            {isExpandedAll ? 'Collapse all' : 'Expand all'}
          </MButton>
        </MBox>
      </MHStack>
    </MPageHeader>
  );
};
