import { useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import {
  MCustomMultiSelect,
  MCustomNumberInput,
  MCustomSelect,
  MFlex,
  MInput,
  MText,
} from '../../../../../components/Monetize';
import { DatePicker } from '../../../../../components/Monetize/DatePicker/DatePicker';
import { MainSearchInputV2 } from '../../../../../components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { QuestionCompareToConstant } from '../../../../../constants/guidedQuoting';
import { logger } from '../../../../../services/logger';
import {
  GuidedQuotingReq,
  ICustomFieldResSchema,
  Maybe,
  QuestionCompareFromEnum,
  QuestionTypesEnum,
} from '../../../../../types';
import {
  objectToSelectOptions,
  orderObjectsBy,
} from '../../../../../utils/misc';
import { referenceOptions } from '../guidedQuoting.utils';

type ResponseType = Exclude<
  QuestionTypesEnum,
  | QuestionTypesEnum.PRODUCT
  | QuestionTypesEnum.OFFERING
  | QuestionTypesEnum.RATE
  | QuestionTypesEnum.TEXT
  | QuestionTypesEnum.BOOLEAN
  | QuestionTypesEnum.CURRENCY
  | QuestionTypesEnum.CUSTOM_FIELD
  | QuestionTypesEnum.LEGAL_ENTITY
>;

interface GuidedQuotingCompareToConstantProps {
  responseType: QuestionTypesEnum;
  compareFrom: QuestionCompareFromEnum | null | undefined;
  field: ControllerRenderProps<GuidedQuotingReq, any>;
  customFieldKey: Maybe<string>;
  customFieldsForSelectedEntity: ICustomFieldResSchema[];
  firstValue: any;
}

export const GuidedQuotingCompareToConstant = ({
  responseType,
  compareFrom,
  field,
  customFieldKey,
  customFieldsForSelectedEntity,
  firstValue,
}: GuidedQuotingCompareToConstantProps) => {
  const type =
    QuestionCompareToConstant[compareFrom ?? (responseType as ResponseType)];

  const customFieldValues = useMemo(() => {
    if (type.type === 'DROPDOWN') {
      return orderObjectsBy(
        customFieldsForSelectedEntity.find(({ key }) => key === customFieldKey)
          ?.values || [],
        ['order'],
      );
    }
    return [];
  }, [customFieldKey, customFieldsForSelectedEntity, type.type]);

  if (type.type === 'MULTI-SELECT') {
    if (type.itemsType === 'OBJECT') {
      return (
        <MCustomMultiSelect
          mt="0 !important"
          showQueryInput
          closeButtonText="Apply"
          items={objectToSelectOptions(type.items)}
          {...field}
          isLazy
          multiple
          inputProps={{
            _placeholder: { color: 'tPurple.dark' },
            _disabled: {
              _placeholder: {
                color: 'tGray.darkPurple',
              },
            },
          }}
        />
      );
    }

    if (type.itemsType === 'REF') {
      return (
        <MCustomMultiSelect
          mt="0 !important"
          showQueryInput
          closeButtonText="Apply"
          {...field}
          {...referenceOptions(compareFrom ?? responseType)}
          isLazy
          multiple
          inputProps={{
            _placeholder: { color: 'tPurple.dark' },
            _disabled: {
              _placeholder: {
                color: 'tGray.darkPurple',
              },
            },
          }}
        />
      );
    }

    logger.warn('Unhandled multi-select type', type);
  }

  if (type.type === 'SELECT') {
    if (type.itemsType === 'OBJECT') {
      return (
        <MCustomSelect
          items={objectToSelectOptions(type.items)}
          {...field}
          value={firstValue}
        />
      );
    }

    if (type.itemsType === 'REF') {
      return (
        <MCustomMultiSelect
          mt="0 !important"
          {...field}
          {...referenceOptions(compareFrom ?? responseType)}
        />
      );
    }

    logger.warn('Unhandled select type', type);
  }

  if (type.type === 'DROPDOWN') {
    return (
      <MCustomSelect
        useMainInputAsSearchInput
        MainInputComponent={MainSearchInputV2}
        itemTitle="label"
        itemValue="value"
        items={customFieldValues}
        renderItemContent={({ title, item }) => {
          return (
            <MFlex
              direction="column"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              w="100%"
            >
              <MText fontWeight="bold" noOfLines={1}>
                {title}
              </MText>
              <MText color="tGray.darkPurple" fontSize="xs">
                {item.value}
              </MText>
            </MFlex>
          );
        }}
        {...field}
      />
    );
  }

  if (type.type === 'DATE') {
    return <DatePicker {...field} value={firstValue ?? ''} />;
  }

  if (type.type === 'NUMBER') {
    return <MCustomNumberInput {...field} value={firstValue ?? ''} />;
  }

  if (type.type === 'TEXT') {
    return <MInput {...field} value={firstValue ?? ''} />;
  }

  logger.warn('Unhandled constant type', type);

  return null;
};
