export const ConditionalTermIcon = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.99132 20C2.15639 20 1.4491 19.7089 0.869453 19.1268C0.289818 18.5446 0 17.8343 0 16.9957V13.2948H2.90335V0H18.0327V9.92815C17.7214 9.83721 17.4022 9.82182 17.0752 9.88197C16.7482 9.94211 16.4314 10.0743 16.125 10.2786V1.9252H4.82465V13.2948H13.0227L11.1151 15.2064H1.90767V16.9957C1.90767 17.3033 2.01202 17.5623 2.22072 17.7728C2.42943 17.9832 2.67827 18.0884 2.96724 18.0884H9.79584V20H2.99132ZM11.2407 20V17.1316L15.2748 13.0892L16.1397 13.9286L12.449 17.6288V18.7893H13.6075L17.2997 15.091L18.151 15.9576L14.1033 20H11.2407ZM18.151 15.9576L15.2748 13.0892L16.8391 11.5218C17.038 11.3225 17.2656 11.2228 17.5217 11.2228C17.7779 11.2228 18.0009 11.3225 18.1908 11.5218L19.7016 13.0357C19.9005 13.226 20 13.4494 20 13.7061C20 13.9628 19.9005 14.1909 19.7016 14.3902L18.151 15.9576ZM6.26953 6.11867V4.41168H14.6801V6.11867H6.26953ZM6.26953 9.2871V7.5665H14.6801V9.2871H6.26953Z"
        fill="currentColor"
      />
    </svg>
  );
};
