import { useMemo } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import {
  MCheckbox,
  MCurrencySelect,
  MCustomNumberInput,
  MCustomSelect,
  MInput,
  MText,
} from '../../../../../components/Monetize';
import { DatePicker } from '../../../../../components/Monetize/DatePicker/DatePicker';
import { MainSearchInputV2 } from '../../../../../components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { MLegalEntitySelect } from '../../../../../components/Monetize/MLegalEntitySelect';
import { CUSTOM_FIELDS_PREFIX } from '../../../../../constants/customFields';
import {
  CustomFieldTypeToGuidedSellingType,
  GuidedQuotingFieldDataTypeMapping,
  GuidedQuotingVariables,
} from '../../../../../constants/guidedQuoting';
import {
  CustomFieldEntityEnum,
  CustomFieldTypeEnum,
  GuidedQuotingReq,
  ICustomFieldResSchema,
  QuestionFilterByEnum,
  QuestionReq,
  QuestionTypesEnum,
  QuoteSourceFieldName,
} from '../../../../../types';
import { objectToSelectOptions } from '../../../../../utils/misc';

const VARIABLE_OPTIONS = objectToSelectOptions(GuidedQuotingVariables);

function getQuestionOptions(
  questions: QuestionReq[],
  dataType: QuestionTypesEnum,
  customField?: ICustomFieldResSchema,
) {
  return questions
    .filter((question) => {
      if (customField) {
        if (!question.customFieldType) {
          return false;
        }
        // For dropdowns, require an exact field match
        if (question.customFieldType === CustomFieldTypeEnum.DROPDOWN) {
          return (
            question.customFieldEntity === CustomFieldEntityEnum.QUOTE &&
            question.customField === customField.key
          );
        }
        // For all other custom fields, allow a data type match
        return (
          CustomFieldTypeToGuidedSellingType[question.customFieldType] ===
          dataType
        );
      }
      return question.type === dataType;
    })
    .map((question) => ({
      title: `${question.questionNumber} - ${question.questionText}`,
      value: question.id,
    }));
}

function getDefaultValueText(
  name: QuoteSourceFieldName,
  customField?: ICustomFieldResSchema,
) {
  if (customField?.type === CustomFieldTypeEnum.CHECKBOX) {
    return 'False';
  }
  if (customField) {
    return 'Blank';
  }

  switch (name) {
    case 'expirationDate':
      return '30 days after Quote creation';
    case 'contractStartDate':
      return 'Day of Quote creation';
    case 'contractLength':
      return '12 months';
    case 'currency':
      return `Use Account's currency`;
    case 'legalEntity':
      return `Use Account's Legal Entity`;
  }
  return '';
}

interface GuidedQuotingQuoteFieldsValuesProps {
  name: QuoteSourceFieldName;
  type: QuestionFilterByEnum;
  field: ControllerRenderProps<GuidedQuotingReq, any>;
  questions: QuestionReq[];
  customField?: ICustomFieldResSchema;
  clearErrors: () => void;
}

export const GuidedQuotingQuoteFieldsValues = ({
  name,
  type,
  field,
  questions,
  customField,
  clearErrors,
}: GuidedQuotingQuoteFieldsValuesProps) => {
  const dataType = useMemo(() => {
    if (customField && name.startsWith(CUSTOM_FIELDS_PREFIX)) {
      return CustomFieldTypeToGuidedSellingType[customField.type];
    }
    return GuidedQuotingFieldDataTypeMapping[name];
  }, [name, customField]);

  if (type === QuestionFilterByEnum.QUESTION) {
    return (
      <MCustomSelect
        // FIXME: this is a HUGE performance problem
        items={getQuestionOptions(questions, dataType, customField)}
        {...field}
        onChange={(value) => {
          field.onChange(value);
          clearErrors();
        }}
      />
    );
  }

  if (type === QuestionFilterByEnum.VARIABLE) {
    return (
      <MCustomSelect
        items={VARIABLE_OPTIONS}
        {...field}
        onChange={(value) => {
          field.onChange(value);
          clearErrors();
        }}
      />
    );
  }

  if (type === QuestionFilterByEnum.CONSTANT) {
    switch (dataType) {
      case QuestionTypesEnum.BOOLEAN:
        return (
          <MCheckbox
            isChecked={field.value ?? false}
            {...field}
            value={field.value ?? false}
            onChange={(value) => {
              field.onChange(value);
              clearErrors();
            }}
          />
        );
      case QuestionTypesEnum.CURRENCY:
        return (
          <MCurrencySelect
            {...field}
            onChange={(value) => {
              field.onChange(value);
              clearErrors();
            }}
          />
        );
      case QuestionTypesEnum.DATE:
        return (
          <DatePicker
            {...field}
            onChange={(date) => {
              field.onChange(date);
              clearErrors();
            }}
          />
        );
      case QuestionTypesEnum.LEGAL_ENTITY:
        return (
          <MLegalEntitySelect
            {...field}
            onChange={(value) => {
              field.onChange(value);
              clearErrors();
            }}
          />
        );
      case QuestionTypesEnum.NUMBER:
        return (
          <MCustomNumberInput
            {...field}
            value={field.value || ''}
            onChange={(value) => {
              field.onChange(value);
              clearErrors();
            }}
          />
        );
      case QuestionTypesEnum.TEXT:
        return customField?.type === CustomFieldTypeEnum.DROPDOWN ? (
          <MCustomSelect
            useMainInputAsSearchInput
            MainInputComponent={MainSearchInputV2}
            itemTitle="label"
            itemValue="value"
            items={customField.values || []}
            {...field}
            onChange={(value) => {
              field.onChange(value);
              clearErrors();
            }}
          />
        ) : (
          <MInput
            {...field}
            value={field.value || ''}
            onChange={(value) => {
              field.onChange(value);
              clearErrors();
            }}
          />
        );
      // These types are not used for constants
      case QuestionTypesEnum.CUSTOM_FIELD:
      case QuestionTypesEnum.BILLING_FREQUENCY:
      case QuestionTypesEnum.OFFERING:
      case QuestionTypesEnum.PRODUCT:
      case QuestionTypesEnum.RATE:
      default:
        break;
    }
  }

  return (
    <MText fontStyle="italic">{getDefaultValueText(name, customField)}</MText>
  );
};
