import { AvatarProps, Link, TextProps } from '@chakra-ui/react';
import { MigratedQuoteIcon } from '@monetize/ui/icons';
import get from 'lodash/get';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import React, { ReactNode } from 'react';
import { MdInfo } from 'react-icons/md';
import { Link as ReactDomLink } from 'react-router-dom';
import { formatCurrency } from '.';
import {
  MAvatar,
  MBox,
  MFlex,
  MIcon,
  MIDCopyBox,
  MText,
  MTooltip,
} from '../components/Monetize';
import { DateDisplay } from '../components/Monetize/DateDisplay';
import { FrequencyMapReturn } from '../constants/offerings';
import { logger } from '../services/logger';
import { FilterOptionType, FilterType, Maybe } from '../types';
import { Leaves } from '../types/utils';
import { toDateShort } from './dates';

interface TemplateOptions<ExtraProps = TextProps> {
  /**
   * Optional extra properties to pass to the component
   */
  extraProps?: ExtraProps;
  /**
   * Value to use if the data[property] is null/undefined
   */
  fallback?: ReactNode;
  /** If provided with compatible templates (e.x. ones that take an id), the id will be a clickable link */
  idLinkFn?: (id: string, record: any) => string;

  /**
   * If provided this will act as a fallback property to use if the main property is not found
   * **/
  fallbackProperty?: string;
}

export function idBodyTemplate<T>(
  property: keyof T,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      return (
        isString(value) && <MIDCopyBox copyValue={value} displayIcon={false} />
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function idWithExtraBodyTemplate<T>(
  property: keyof T,
  children: (data: T) => ReactNode,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      return (
        isString(value) && (
          <MFlex alignItems="center" columnGap={2} {...extraProps}>
            <MIDCopyBox copyValue={value} displayIcon={false} />
            {children(data)}
          </MFlex>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function nameWithIdBodyTemplate<
  T extends object,
  K extends { name: string; id: string },
>(
  property: keyof T,
  { extraProps, fallback, fallbackProperty, idLinkFn }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const extractedValue = (data[property] as K) ?? fallback;

      if (
        isUndefined(extractedValue) &&
        !React.isValidElement(extractedValue)
      ) {
        const fallbackValue = data[fallbackProperty as keyof T] as string;

        if (!fallbackValue) {
          return;
        }

        const link =
          idLinkFn && isString(fallbackValue)
            ? idLinkFn(fallbackValue, data)
            : undefined;
        return (
          <MBox width="12.5rem" {...extraProps}>
            <Link
              as={ReactDomLink}
              to={link}
              fontWeight="400"
              whiteSpace="normal"
              _hover={{ textDecoration: 'underline' }}
            >
              {fallbackValue}
            </Link>
          </MBox>
        );
      }

      const { name, id } = extractedValue;
      const link = idLinkFn && isString(id) ? idLinkFn(id, data) : undefined;

      return (
        isString(name) &&
        isString(id) && (
          <MBox width="12.5rem" {...extraProps}>
            <MText fontWeight="500" whiteSpace="normal">
              {name}
            </MText>
            {link ? (
              <Link
                as={ReactDomLink}
                to={link}
                fontWeight="400"
                whiteSpace="normal"
                _hover={{ textDecoration: 'underline' }}
              >
                {id}
              </Link>
            ) : (
              <MIDCopyBox copyValue={id} displayIcon={false} />
            )}
          </MBox>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function headerAndIdTemplate<T extends object>(
  headerKey: keyof T,
  idKey: keyof T,
  { extraProps, idLinkFn, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const header = get(data, headerKey, fallback) as
        | string
        | number
        | boolean
        | null
        | undefined;
      const id = get(data, idKey) as string | null | undefined;
      const link = idLinkFn && isString(id) ? idLinkFn(id, data) : undefined;
      return (
        <MBox {...extraProps}>
          {header && (
            <MText fontWeight="500" whiteSpace="normal" noOfLines={2}>
              {header}
            </MText>
          )}
          {id &&
            (link ? (
              <Link
                as={ReactDomLink}
                to={link}
                fontWeight="400"
                whiteSpace="normal"
                _hover={{ textDecoration: 'underline' }}
              >
                {id}
              </Link>
            ) : (
              <MIDCopyBox copyValue={id} displayIcon={false} />
            ))}
        </MBox>
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        headerKey,
        idKey,
        ex,
      });
    }
  };
}

export function textBodyTemplate<T>(
  property: Leaves<T>,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = get(data, property) ?? fallback;
      return (
        isString(value) && (
          <MText noOfLines={1} {...extraProps}>
            {value}
          </MText>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function textWithTooltipBodyTemplate<T>(
  property: keyof T,
  tooltipProperty: keyof T,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      const tooltip = data[tooltipProperty];
      return (
        isString(value) && (
          <MTooltip
            label={isString(tooltip) ? tooltip : null}
            placement="bottom-end"
          >
            <MText noOfLines={1} {...extraProps}>
              {value}
            </MText>
          </MTooltip>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function textWithExtraBodyTemplate<T>(
  property: keyof T,
  children: (data: T) => ReactNode,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      return (
        isString(value) && (
          <MFlex align="center" columnGap={2} {...extraProps}>
            <MText noOfLines={1}>{value}</MText>
            {children(data)}
          </MFlex>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function quoteNameBodyTemplate<T>(
  property: keyof T,
  migratedProperty: keyof T,
  children?: (data: T) => ReactNode | undefined,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      const isMigrated = data[migratedProperty] ?? fallback;

      return (
        isString(value) && (
          <MFlex align="center" columnGap={2} {...extraProps}>
            <MText title={value} noOfLines={1}>
              {value}
            </MText>
            {isMigrated && (
              <MTooltip
                label="Quote migrated to MonetizeNow"
                placement="bottom-end"
                shouldWrapChildren
              >
                <MIcon
                  as={MigratedQuoteIcon}
                  bg="tPurple.linkWater"
                  borderRadius={2}
                  boxSize={18}
                  mt={1}
                />
              </MTooltip>
            )}
            {children && children(data)}
          </MFlex>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function currencyBodyTemplate<T>(
  property: keyof T,
  currencyProperty?: keyof T,
  { extraProps, fallback }: TemplateOptions = {},
  currencyFallback = 'USD',
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      const currency =
        currencyProperty && isString(data[currencyProperty])
          ? (data[currencyProperty] as string)
          : currencyFallback;
      return (
        (isString(value) || isNumber(value)) && (
          <MText noOfLines={1} {...extraProps}>
            {formatCurrency(value, { currency })}
          </MText>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function numberBodyTemplate<T>(
  property: Leaves<T>,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = get(data, property) ?? fallback;
      return (
        (isString(value) || isNumber(value)) && (
          <MText noOfLines={1} {...extraProps}>
            {value}
          </MText>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function dateBodyTemplate<T>(
  property: keyof T,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      return (
        (isString(value) || isDate(value)) && (
          <DateDisplay mode="date" date={value} textProps={extraProps} />
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function dateTimeBodyTemplate<T>(
  property: keyof T,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      return (
        (isString(value) || isDate(value)) && (
          <DateDisplay date={value} textProps={extraProps} />
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function dateRangeBodyTemplate<T>(
  startProperty: keyof T,
  endProperty: keyof T,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const startDate = data[startProperty];
      const endDate = data[endProperty];
      if (
        isString(startDate) ||
        (isDate(startDate) && (isString(endDate) || isDate(endDate)))
      ) {
        return (
          (isString(startDate) || isDate(startDate)) &&
          (isString(endDate) || isDate(endDate)) && (
            <MText isTruncated noOfLines={1} {...extraProps}>
              {toDateShort(startDate)} - {toDateShort(endDate)}
            </MText>
          )
        );
      } else if (isString(startDate) || isDate(startDate)) {
        <MText isTruncated noOfLines={1} {...extraProps}>
          {toDateShort(startDate)}
        </MText>;
      }
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        startProperty,
        endProperty,
        ex,
      });
    }
  };
}

/**
 * This can be used for any enum, not just status (we should probably rename)
 *
 * @param styleMap Only provide this if special styling is needed for some values (e.x. QuoteStatusTagStyle)
 */
export function statusBodyTemplate<T, TEnumValue extends string>(
  property: keyof T,
  displayMap: { [key in TEnumValue]: string },
  styleMap?: { [key in TEnumValue]: TextProps },
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = displayMap[data[property] as TEnumValue];
      const dynamicStyle = styleMap
        ? styleMap[data[property] as TEnumValue]
        : {};
      return (
        isString(value) && (
          <MText
            noOfLines={1}
            {...dynamicStyle}
            {...extraProps}
            maxW="fit-content"
            h="18px"
            lineHeight="18px"
          >
            {value}
          </MText>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function statusWithErrorTooltipTemplate<T, TEnumValue extends string>(
  property: keyof T,
  errorProperty: keyof T,
  displayMap: { [key in TEnumValue]: string },
  /** get user-facing error message from error code */
  getErrorMessage: (errorValue?: Maybe<string>) => { message: string },
  styleMap?: { [key in TEnumValue]: TextProps },
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const { message: errorMessage } = isString(data[errorProperty])
        ? getErrorMessage(data[errorProperty] as string)
        : { message: null };
      const value = displayMap[data[property] as TEnumValue];
      const dynamicStyle = styleMap
        ? styleMap[data[property] as TEnumValue]
        : {};

      return (
        isString(value) && (
          <MFlex align="center" gap="2">
            {isString(errorMessage) && (
              <MTooltip label={errorMessage} shouldWrapChildren placement="top">
                <MIcon as={MdInfo} color="tRed.base" />
              </MTooltip>
            )}
            <MText
              noOfLines={1}
              {...dynamicStyle}
              {...extraProps}
              maxW="fit-content"
              h="18px"
              lineHeight="18px"
            >
              {value}
            </MText>
          </MFlex>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

/**
 *
 * @param styleMap Only provide this if special styling is needed for some values (e.x. QuoteStatusTagStyle)
 */
export function enumFuncBodyTemplate<T, TEnumValue extends string>(
  property: keyof T,
  secondProperty: keyof T,
  displayMap: {
    [key in TEnumValue]: (val?: number | null) => FrequencyMapReturn;
  },
  styleMap?: { [key in TEnumValue]: TextProps },
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const { label } = displayMap[data[property] as TEnumValue](
        data[secondProperty] as number | null | undefined,
      );
      const dynamicStyle = styleMap
        ? styleMap[data[property] as TEnumValue]
        : {};
      return (
        isString(label) && (
          <MText
            noOfLines={1}
            {...dynamicStyle}
            {...extraProps}
            maxW="fit-content"
            h="18px"
            lineHeight="18px"
          >
            {label}
          </MText>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function userAvatarBodyTemplate<T>(
  property: keyof T,
  { extraProps, fallback }: TemplateOptions<AvatarProps> = {},
) {
  return (data: T) => {
    try {
      const value = data[property] ?? fallback;
      return (
        isString(value) && (
          <MFlex justify="center" align="center">
            <MTooltip shouldWrapChildren label={value} placement="bottom-start">
              <MAvatar name={value} mr="0" {...extraProps} />
            </MTooltip>
          </MFlex>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export function nestedTextBodyTemplate<T, U>(
  property: keyof T,
  valueProperty: keyof U,
  { extraProps, fallback }: TemplateOptions = {},
) {
  return (data: T) => {
    try {
      const value = (data[property] as U)[valueProperty] ?? fallback;
      return (
        isString(value) && (
          <MText noOfLines={1} {...extraProps}>
            {value}
          </MText>
        )
      );
    } catch (ex) {
      logger.warn('Could not generate template', { data, property, ex });
    }
  };
}

export const getFiltersFromState = (
  state: Record<string, any>,
  currentFilters: FilterType[],
  filterOptions: FilterOptionType[],
) => {
  let newFilters = [...currentFilters];

  Object.keys(state || {}).forEach((key) => {
    const filterOption = filterOptions.find((option) => option.key === key);
    if (filterOption && state[key] && filterOption.operator) {
      const foundFilter = newFilters.find((filter) => filter.key === key);

      if (foundFilter) {
        newFilters = newFilters.map((filter) => {
          if (filter.key === key) {
            return { ...filter, value: state[key] };
          }
          return filter;
        });
      } else {
        newFilters.push({
          key,
          value: state[key],
          operator: filterOption.operator,
        });
      }
    }
  });

  return newFilters;
};
