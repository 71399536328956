import { Auth0Provider as Auth0ProviderReactWrapper } from '@auth0/auth0-react';
import { AUTH0 } from '@monetize/ui/config';
import React, { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { tempRedirectInfoState } from '../store/global.store';

export const Auth0ProviderReact = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const setRedirectInfo = useSetRecoilState(tempRedirectInfoState);

  const onRedirectCallback = useCallback(
    (appState: any) => {
      if (appState && appState.returnTo) {
        // When auth0 app state contains the redirect info then save it in redirectUrl
        // This will be used on /select-tenant route.
        setRedirectInfo({
          redirectUrl: appState.returnTo,
        });
      }
    },
    [setRedirectInfo],
  );

  return (
    <Auth0ProviderReactWrapper
      domain={AUTH0.domain}
      clientId={AUTH0.clientId}
      redirectUri={AUTH0.callbackUrl}
      audience={AUTH0.apiAudience}
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0ProviderReactWrapper>
  );
};
