import { useDisclosure } from '@chakra-ui/react';
import { forwardRef, useEffect, useState } from 'react';
import { CONTACTS } from '../../constants';
import { useACL } from '../../services/acl/acl';
import { IContactRespSchema } from '../../types';
import { MCustomSelectProps } from '../../types/mCustomSelectTypes';
import { ContactFormModal } from '../Contacts/ContactFormModal';
import { contactSelectRenderItem } from '../Contacts/ContactSelect';
import { MCustomSelect, QuoteSelectInput } from '../Monetize';

interface QuoteBasicContactSelectProps
  extends Omit<MCustomSelectProps, 'value' | 'onChange'> {
  contacts: IContactRespSchema[];
  value?: IContactRespSchema | null;
  accountId?: string;
  onChange: (contact: IContactRespSchema) => void;
  defaultValues?: Partial<IContactRespSchema>;
}

export const QuoteBasicContactSelect = forwardRef(
  (
    {
      contacts,
      value,
      onChange,
      accountId,
      defaultValues,
      ...rest
    }: QuoteBasicContactSelectProps,
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const [iContacts, setIContacts] = useState(contacts);
    const onChangeQuery = (q: string): void => {
      if (q !== query) {
        setQuery(q);
      }
    };
    const { canDo } = useACL();
    const contactModalState = useDisclosure();

    useEffect(() => {
      if (query) {
        setIContacts(
          contacts.filter(
            (c) =>
              (c.fullName &&
                c.fullName.toLowerCase().includes(query.toLowerCase())) ||
              (c.email && c.email.toLowerCase().includes(query.toLowerCase())),
          ),
        );
      } else {
        setIContacts(contacts);
      }
    }, [contacts, query]);

    const canCreateContact =
      canDo([['account_contacts', 'create']]) && !!accountId;

    const handleContactModalClose = async (contact: IContactRespSchema) => {
      contactModalState.onClose();
      onChange(contact);
    };

    return (
      <>
        <MCustomSelect
          value={value}
          name="contacts"
          popOverProps={{ placement: 'bottom-end', matchWidth: false }}
          items={iContacts}
          itemTitle="fullName"
          itemValue="id"
          onChange={(ev: any) => onChange(ev)}
          MainInputComponent={QuoteSelectInput}
          showContentInInput
          useHighlightedItemList
          showItemDivider
          inputProps={{
            autoComplete: 'off',
          }}
          placeholder="Select"
          showQueryInput
          returnItem
          useExternalQuery
          externalQuery={query}
          onChangeQuery={onChangeQuery}
          skipFilter={true}
          prependItems={
            canCreateContact
              ? [
                  {
                    item: CONTACTS.NEW_CONTACT,
                    isAction: true,
                    hasDivider: true,
                    onClick: ({ onClose }) => {
                      onClose && onClose();
                      contactModalState.onOpen();
                    },
                  },
                ]
              : []
          }
          renderItemContent={contactSelectRenderItem}
          ref={ref}
          {...rest}
        />
        {contactModalState.isOpen && accountId && (
          <ContactFormModal
            isOpen
            accountId={accountId}
            // isLoading={true}
            onClose={() => contactModalState.onClose()}
            onCreatedContact={handleContactModalClose}
            defaultValues={defaultValues}
          />
        )}
      </>
    );
  },
);
