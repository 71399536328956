import { formatCurrency } from '@monetize/utils/core';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import {
  MBox,
  MCenter,
  MCustomNumberInput,
  MFormField,
  MLockedTextOrContent,
  MSimpleGrid,
  MText,
} from '../../../../components/Monetize';
import { GRID_PROPS } from '../../../../constants/oneTimeInvoice';
import { IProductResSchema, ProductTypeEnum } from '../../../../types';
import { IOneTimeInvoiceFormSchemaWithOfferings } from '../../../../types/oneTimeInvoiceTypes';
import { roundNumberToDecimal } from '../../../../utils/numberUtils';

interface OneTimeInvoiceOfferingProductProps {
  control: Control<IOneTimeInvoiceFormSchemaWithOfferings>;
  offeringIndex: number;
  index: number;
  errors: FieldErrors<IOneTimeInvoiceFormSchemaWithOfferings>;
  selectedProduct: IProductResSchema;
  currency: string;
}
export const OneTimeInvoiceOfferingProduct = ({
  control,
  offeringIndex,
  index,
  errors,
  selectedProduct,
  currency,
}: OneTimeInvoiceOfferingProductProps) => {
  const isMinCommitProductType =
    selectedProduct.productType === ProductTypeEnum.MIN_COMMIT;
  const quantity = useWatch({
    control,
    name: `offerings.${offeringIndex}.products.${index}.quantity`,
  });
  const unitPrice = useWatch({
    control,
    name: `offerings.${offeringIndex}.products.${index}.unitPrice`,
  });

  const total = formatCurrency(roundNumberToDecimal(quantity * unitPrice), {
    currency,
  });

  return (
    <MSimpleGrid {...GRID_PROPS} bg="tWhite.titanWhite">
      <MBox gridColumn="1/4" pl={6}>
        <MText fontWeight="700" noOfLines={1} maxW="80ch">
          {selectedProduct?.name || 'test name'}
        </MText>
        <MText fontWeight="400" noOfLines={1} maxW="80ch">
          {selectedProduct?.description || 'test description'}
        </MText>
      </MBox>
      <MFormField
        error={errors?.offerings?.[offeringIndex]?.products?.[index]?.quantity}
      >
        <MLockedTextOrContent
          isLocked={isMinCommitProductType}
          text={quantity}
          justifyContent="center"
        >
          <Controller
            name={`offerings.${offeringIndex}.products.${index}.quantity`}
            control={control}
            render={({ field }) => (
              <MCenter>
                <MCustomNumberInput maxW="5rem" textAlign="center" {...field} />
              </MCenter>
            )}
          />
        </MLockedTextOrContent>
      </MFormField>
      <MFormField
        error={errors?.offerings?.[offeringIndex]?.products?.[index]?.unitPrice}
      >
        <Controller
          name={`offerings.${offeringIndex}.products.${index}.unitPrice`}
          control={control}
          render={({ field }) => (
            <MCenter>
              <MCustomNumberInput
                textAlign="center"
                maxW="5rem"
                inputMode="decimal"
                {...field}
              />
            </MCenter>
          )}
        />
      </MFormField>
      <MText textAlign="right" fontWeight="600" pr={4}>
        {total}
      </MText>
    </MSimpleGrid>
  );
};
