import { QueryClient } from '@tanstack/react-query';
import clamp from 'lodash/clamp';
import { ReactElement, useState } from 'react';
import { handleApiErrorToast } from '../../../api/axios';
import { CPQ_SERVICE_API, doGetQuote } from '../../../api/cpqService';
import { doGetOfferingRate } from '../../../api/productCatalogService';
import { doGetTenantUser } from '../../../api/usersService';
import { MText } from '../../../components/Monetize';
import {
  CUSTOM_SELECT_SUBTITLE_TYPE,
  OPTION_TYPE_FIELD,
} from '../../../constants/customSelect';
import { OFFERING_TYPES_LABEL_DISPLAY } from '../../../constants/offerings';
import { DEFAULT_QUOTE_COLORS } from '../../../constants/quotes';
import { useAuth } from '../../../services/auth0';
import { logger } from '../../../services/logger';
import {
  IOfferingRes,
  IQuoteOfferingDeltaView,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  IRateResSchema,
  IUser,
  Maybe,
  QuoteOfferingViolationLabelEnum,
  QuoteTypeEnum,
  RateTypeEnum,
} from '../../../types';
import { groupBy } from '../../../utils/misc';

export interface CreatedByUserDataTypes {
  loading: boolean;
  createdByUser: IUser | null;
  fetchCreatedByUser: (userId: string) => Promise<IUser | null>;
}

/**
 * This is no longer used anywhere in the application.
 * Prefer to use quote.ownerName instead.
 */
export const useCreatedByUser = (): CreatedByUserDataTypes => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createdByUser, setCreatedByUser] = useState<IUser | null>(null);
  const { tenantId } = useAuth();

  const fetchCreatedByUser = async (userId: string): Promise<IUser | null> => {
    setLoading(true);
    try {
      const user = await doGetTenantUser(tenantId, userId);

      setCreatedByUser(user);
      return user;
    } catch (error) {
      handleApiErrorToast(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    createdByUser,
    fetchCreatedByUser,
  };
};

const getTooltipLabel = (quoteType: string): string => {
  switch (quoteType) {
    case QuoteTypeEnum.AMENDMENT:
      return 'Amendments allow you to update quotes after a quote has been processed.';
    case QuoteTypeEnum.RENEWAL:
      return 'Renewals allow you to take an existing quote and create an entirely new quote.';
    default:
      return '';
  }
};

export const getQuoteTagColor = (quoteType: QuoteTypeEnum) => {
  switch (quoteType) {
    case 'AMENDMENT':
    case 'RENEWAL':
      return {
        color: 'tPurple.safety',
        bgColor: 'tPurple.inputBox',
      };
    default:
      return DEFAULT_QUOTE_COLORS;
  }
};

export const getQuoteDescription = (accountName?: string) => {
  if (accountName) {
    return `Quote for ${accountName}`;
  }

  return 'Quote ';
};

/**
 * Since quote has many places that do not use ReactQuery, this is a helper method
 * for updating the quote in the query cache.
 */
export const updateQuoteQueryCache = (
  queryClient: QueryClient,
  updatedQuote: IQuoteRespSchema,
) => {
  const queryKey = CPQ_SERVICE_API.cpqServiceQuotes.byId?.queryKey?.(
    updatedQuote.id,
  );
  if (queryKey) {
    queryClient.setQueryData(queryKey, updatedQuote);
  }
};

/**
 * Returns offeringList based on inactive offering
 */
export const getOfferingList = (
  allOfferings: IOfferingRes[],
  inactiveOffering?: IOfferingRes,
) => {
  if (inactiveOffering) {
    const isInactiveOfferingActuallyActive = allOfferings.find(
      ({ id }) => id === inactiveOffering.id,
    );
    if (!isInactiveOfferingActuallyActive) {
      return [
        ...allOfferings,
        {
          name: 'Inactive',
          [OPTION_TYPE_FIELD]: CUSTOM_SELECT_SUBTITLE_TYPE,
        },
        {
          ...inactiveOffering,
          rightLabel: OFFERING_TYPES_LABEL_DISPLAY[inactiveOffering.type],
        },
      ];
    }

    return allOfferings;
  }

  return allOfferings;
};

/**
 * Get all Quote Offerings from a quote and create a payload that can be used to create the same offerings for a new quote
 *
 * This wil ensure that any account specific rates that are from other accounts are replaced with the CATALOG rate
 * No discounts are carried over from the original QuoteItems
 * The quantity will default to the rate min bound
 */
export const getQuoteOfferingsToImport = async (
  quoteIdToImport: string,
  accountId: string,
): Promise<IQuoteOfferingReqSchema[]> => {
  const quoteToImport = await doGetQuote(quoteIdToImport);
  if (!quoteToImport) {
    throw new Error('Quote not found');
  }

  // Helper function to ensure that a failure to fetch a rate does not result in an exception
  async function fetchRate(rateId: string) {
    try {
      return await doGetOfferingRate(rateId);
    } catch (ex) {
      logger.warn('Failed to get rate', ex);
    }
  }

  // Check all rates and make sure that any account specific rates from different accounts are replaced with the CATALOG rate
  // Group by productId for easy lookup
  let ratesById = groupBy(
    (
      await Promise.all(
        Array.from(
          new Set(quoteToImport.quoteOfferings.map(({ rateId }) => rateId)),
        ).map((rateId) => fetchRate(rateId)),
      )
    ).filter(Boolean) as IRateResSchema[],
    'id',
  );

  // Fallback to CATALOG rate if the provided quote is using an account specific rate from a different account
  const { quoteOfferings, parentRates } = quoteToImport.quoteOfferings.reduce(
    (
      output: {
        quoteOfferings: IQuoteOfferingRespSchema[];
        parentRates: Set<string>;
      },
      quoteOffering,
    ) => {
      const rate = ratesById[quoteOffering.rateId];
      if (
        rate &&
        rate.rateType === RateTypeEnum.ACCOUNT &&
        rate.parentRateId &&
        rate.accountId !== accountId
      ) {
        output.quoteOfferings.push({
          ...quoteOffering,
          rateId: rate.parentRateId,
        });
        output.parentRates.add(rate.parentRateId);
      } else {
        output.quoteOfferings.push(quoteOffering);
      }
      return output;
    },
    { quoteOfferings: [], parentRates: new Set<string>() },
  );

  // If CATALOG rates were replaced with actual rates, fetch these rates so proper min/max can be set
  ratesById = {
    ...ratesById,
    ...groupBy(
      (
        await Promise.all(
          Array.from(parentRates).map((rateId) => fetchRate(rateId)),
        )
      ).filter(Boolean) as IRateResSchema[],
      'id',
    ),
  };

  // Get payload for new quoteOfferings - quoteItem quantity will be set to the minimum bound allowed by the rate
  return getQuoteOfferingCreatePayloadFromQuoteOffering(
    quoteOfferings,
    ratesById,
  );
};

/**
 * Convert existing QuoteOfferings into a request payload that can be used to create the same offerings for a new quote
 */
export const getQuoteOfferingCreatePayloadFromQuoteOffering = (
  existingOfferings: IQuoteOfferingRespSchema[],
  ratesById: Record<string, IRateResSchema>,
): IQuoteOfferingReqSchema[] => {
  if (!existingOfferings || !existingOfferings.length) {
    return [];
  }
  return existingOfferings
    .filter((offering) => !offering.parentQuoteOfferingId)
    .map((offering): IQuoteOfferingReqSchema => {
      const productPriceRanges =
        ratesById[offering.rateId]?.productPriceRanges || {};
      return {
        offeringId: offering.offeringId,
        rateId: offering.rateId,
        subscriptionId: offering.subscriptionId,
        description: offering.description,
        discountIds: null,
        schedule: null,
        items: offering.items.map((item) => ({
          customId: item.customId,
          productId: item.productId,
          sku: item.sku,
          description: item.description,
          // Ensure that the quoteOffering quantity is within the rate's min/max range
          quantity: productPriceRanges[item.productId]
            ? clamp(
                0,
                productPriceRanges[item.productId].min,
                productPriceRanges[item.productId].max ?? Infinity,
              )
            : item.quantity,
          customDiscountType: null,
          customDiscountAmountOrPercent: null,
          customDiscountName: null,
          customPrice: null,
          options: item.options,
          isSelected: item.isSelected,
          productName: item.productName,
          productType: item.productType,
          position: item.position
        })),
      };
    });
};

/**
 * Based on quote, defaultContactId, esignConfiguration returns esignIds
 */
export const getDefaultEsignIds = ({
  quote,
  defaultContactId,
  internalEsignContactId,
  isTenantEsignConfigured,
}: {
  quote: IQuoteRespSchema;
  defaultContactId?: Maybe<string>;
  internalEsignContactId?: Maybe<string>;
  isTenantEsignConfigured: boolean;
}) => {
  if (isTenantEsignConfigured && !quote.contacts.esign.length) {
    if (defaultContactId && internalEsignContactId) {
      return [defaultContactId, internalEsignContactId];
    } else if (defaultContactId) {
      return [defaultContactId];
    } else if (internalEsignContactId) {
      return [internalEsignContactId];
    }
  }

  return quote.contacts.esign.map(({ id }) => id);
};

export const getOfferingNameById = (id: string, offerings: IOfferingRes[]) => {
  return offerings?.find((offering) => offering.id === id)?.name ?? '';
};

export const findQOByOfferingIdFromQuote = (
  quote: IQuoteRespSchema,
  offeringId: string,
) => {
  return quote.quoteOfferings.find(
    (quoteOffering) => quoteOffering.offeringId === offeringId,
  );
};

export const findQOByQuoteItemIdFromQuote = (
  quote: IQuoteRespSchema,
  quoteItemId: string,
) => {
  const quoteOffering = quote.quoteOfferings.find(({ items }) =>
    items.find(({ id }) => id === quoteItemId),
  );

  return {
    quoteOffering,
    quoteItem: quoteOffering?.items.find(({ id }) => id === quoteItemId),
  };
};

export const findQOBySubscriptionIdFromQuote = (
  quote: IQuoteRespSchema,
  subscriptionId: string,
) => {
  return quote.quoteOfferings.find(
    (quoteOffering) => quoteOffering.subscriptionId === subscriptionId,
  );
};

export const getExplanationItem = (nameInBold: string, description: string) => {
  return (
    <>
      <MText fontWeight="semibold" as="span">
        {nameInBold}
      </MText>
      <MText as="span">{description}</MText>
    </>
  );
};

export const getExplanationFromDeltaView = (
  quote: IQuoteRespSchema,
  deltaView: IQuoteOfferingDeltaView,
) => {
  if (!deltaView.violations?.length) {
    return [];
  }

  const explanations: ReactElement[] = [];

  deltaView.violations.map((viloation) => {
    switch (viloation.label) {
      case QuoteOfferingViolationLabelEnum.DISCONTINUITY_VIOLATION: {
        const quoteOffering = findQOBySubscriptionIdFromQuote(
          quote,
          viloation.subscriptionId!,
        );
        if (quoteOffering) {
          explanations.push(
            getExplanationItem(
              quoteOffering.offeringName,
              "'s quantity or price was adjusted in multiple, discontinuous, periods of the contract term",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.VARIABLE_QUANTITY_VIOLATION: {
        const quoteOffering = findQOBySubscriptionIdFromQuote(
          quote,
          viloation.subscriptionId!,
        );
        if (quoteOffering) {
          explanations.push(
            getExplanationItem(
              quoteOffering.offeringName,
              "'s quantity was amended by multiple amounts during the contract term",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.QUOTE_OFFERING_DESCRIPTION_CHANGE_VIOLATION: {
        const quoteOffering = quote.quoteOfferings.find(
          ({ id }) => id === viloation.quoteOfferingId,
        );
        if (quoteOffering) {
          explanations.push(
            getExplanationItem(
              quoteOffering.offeringName,
              "'s description was adjusted",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.QUOTE_OFFERING_RATE_CHANGE_VIOLATION: {
        const quoteOffering = quote.quoteOfferings.find(
          ({ id }) => id === viloation.quoteOfferingId,
        );
        if (quoteOffering) {
          explanations.push(
            getExplanationItem(
              quoteOffering.offeringName,
              "'s Rate was adjusted",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.QUOTE_OFFERING_DISCOUNT_CHANGE_VIOLATION: {
        const quoteOffering = quote.quoteOfferings.find(
          ({ id }) => id === viloation.quoteOfferingId,
        );
        if (quoteOffering) {
          explanations.push(
            getExplanationItem(
              quoteOffering.offeringName,
              "'s Discount was adjusted",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.QUOTE_ITEM_DISCOUNT_CHANGE_VIOLATION: {
        const { quoteOffering, quoteItem } = findQOByQuoteItemIdFromQuote(
          quote,
          viloation.quoteItemId!,
        );
        if (quoteOffering && quoteItem) {
          explanations.push(
            getExplanationItem(
              `${quoteOffering.offeringName} - ${quoteItem.productName}`,
              "'s Discount was adjusted",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.QUOTE_ITEM_DESCRIPTION_CHANGE_VIOLATION: {
        const { quoteOffering, quoteItem } = findQOByQuoteItemIdFromQuote(
          quote,
          viloation.quoteItemId!,
        );
        if (quoteOffering && quoteItem) {
          explanations.push(
            getExplanationItem(
              `${quoteOffering.offeringName} - ${quoteItem.productName}`,
              "'s description was adjusted",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.QUOTE_ITEM_CUSTOM_DISCOUNT_CHANGE_VIOLATION: {
        const { quoteOffering, quoteItem } = findQOByQuoteItemIdFromQuote(
          quote,
          viloation.quoteItemId!,
        );
        if (quoteOffering && quoteItem) {
          explanations.push(
            getExplanationItem(
              `${quoteOffering.offeringName} - ${quoteItem.productName}`,
              "'s Custom Discount was adjusted",
            ),
          );
        }
        break;
      }
      case QuoteOfferingViolationLabelEnum.QUOTE_ITEM_CUSTOM_PRICE_CHANGE_VIOLATION: {
        const { quoteOffering, quoteItem } = findQOByQuoteItemIdFromQuote(
          quote,
          viloation.quoteItemId!,
        );
        if (quoteOffering && quoteItem) {
          explanations.push(
            getExplanationItem(
              `${quoteOffering.offeringName} - ${quoteItem.productName}`,
              "'s Custom Price was adjusted",
            ),
          );
        }
        break;
      }
      // This is only temporary while we wait for BP-11743: [NOT IN MVP SCOPE] Support mandatory optional updates in delta view
      case QuoteOfferingViolationLabelEnum.QUOTE_ITEM_MANDATORY_OPTIONAL_CHANGE_VIOLATION: {
        const { quoteOffering, quoteItem } = findQOByQuoteItemIdFromQuote(
          quote,
          viloation.quoteItemId!,
        );
        if (quoteOffering && quoteItem) {
          explanations.push(
            getExplanationItem(
              `${quoteOffering.offeringName} - ${quoteItem.productName}`,
              "'s Mandatory Option was adjusted",
            ),
          );
        }
        break;
      }
    }
  });

  return explanations;
};
