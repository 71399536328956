import {
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import {
  MGrid,
  MGridItem,
  MLink,
  MText,
} from '../../../../../components/Monetize';
import { IUsageTypeResSchema } from '../../../../../types';

interface UsageTypeWithDetailsProps {
  usageTypes: IUsageTypeResSchema[];
}

export const UsageTypeWithDetails = ({
  usageTypes,
}: UsageTypeWithDetailsProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  if (!usageTypes || usageTypes.length == 0) {
    return null;
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="right"
    >
      <PopoverTrigger>
        <MLink onClick={onOpen} fontSize="sm" cursor="pointer">
          {usageTypes.length > 1 ? 'Multiple' : usageTypes[0].name}
        </MLink>
      </PopoverTrigger>
      <PopoverContent p={4} border="0" boxShadow="popover">
        <FocusLock persistentFocus={false}>
          <PopoverArrow mt="1" />
          {usageTypes.length === 1 ? (
            <MGrid templateColumns={'1fr'} columnGap={8}>
              <MGridItem py={2}>
                <MText fontWeight="bold">Usage Type ID</MText>
              </MGridItem>
              <MGridItem py={1}>
                <MText>{usageTypes[0].id}</MText>
              </MGridItem>
            </MGrid>
          ) : (
            <MGrid templateColumns={'1fr 1fr'} columnGap={8}>
              <MGridItem py={2}>
                <MText fontWeight="bold">Usage Type ID</MText>
              </MGridItem>
              <MGridItem py={2}>
                <MText fontWeight="bold">Usage Type</MText>
              </MGridItem>
              {usageTypes.map((usageType, index) => (
                <Fragment key={index}>
                  <MGridItem py={1}>
                    <MText>{usageType.id}</MText>
                  </MGridItem>
                  <MGridItem py={1}>
                    <MText>{usageType.name}</MText>
                  </MGridItem>
                </Fragment>
              ))}
            </MGrid>
          )}
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
