import { boolean, object, string, z } from 'zod';
import { CurrencyCodes } from '../constants/currencies';

export interface ICurrencyResp {
  id: string;
  code: CurrencyCodes;
  description: string;
  locked: boolean;
  defaultCurrency: boolean;
}

export const CurrencySchema = object({
  code: string().nonempty(),
  description: string().nullish(),
  defaultCurrency: boolean(),
});
export type ICurrencySchema = z.infer<typeof CurrencySchema>;
