import isNil from 'lodash/isNil';
import { transformValueToMinimumTwoDecimalPlaces } from '.';
import { RATE_BILLING_FREQUENCY_MAP } from '../constants/offerings';
import {
  AggregationModelEnum,
  IPriceResSchema,
  IPricesAndOptionsReqSchema,
  IProduct,
  IRateReqSchemaUI,
  IRateResSchema,
  Maybe,
  OfferingTypesEnum,
  PercentOfTotalConfigTypeEnum,
  ProductTypeEnum,
} from '../types';

export const convertRateResToReq = (
  rate: IRateResSchema,
  products: IProduct[],
  defaultCurrency: string,
  type?: OfferingTypesEnum,
): IRateReqSchemaUI => {
  const rateProducts: Record<string, IPricesAndOptionsReqSchema> =
    products.reduce(
      (acc: Record<string, IPricesAndOptionsReqSchema>, curr: IProduct) => {
        const prices = rate.prices || [];
        const currentOption = rate.options?.productOptions?.find(
          ({ productId }) => productId === curr.id,
        );

        return {
          ...acc,
          [curr.id!]: {
            productType: curr.productType,
            prices: prices
              .filter(
                (price: IPriceResSchema) => price?.product?.id === curr.id,
              )
              .map(
                (price: IPriceResSchema) =>
                  ({
                    id: price.id,
                    from: price.from,
                    to: isNil(price.to) ? '' : String(price.to),
                    amount: transformValueToMinimumTwoDecimalPlaces(
                      price.amount,
                    ),
                    description: `${price.description || ''}`,
                    productId: curr.id,
                    priceModel: price.priceModel,
                  }) as any as IPriceResSchema,
              ),
            aggregationModel:
              curr.productType === ProductTypeEnum.USAGE
                ? currentOption?.aggregationModel || AggregationModelEnum.SUM
                : undefined,
          },
        };
      },
      {},
    );

  const defaultPercentOfTotalConfig = {
    type: PercentOfTotalConfigTypeEnum.ALL_ELIGIBLE,
  };

  let billingFrequencyInMonths: Maybe<number> = null;
  if (rate.billingFrequencyInMonths) {
    billingFrequencyInMonths = rate.billingFrequencyInMonths;
  } else {
    billingFrequencyInMonths = rate.billingFrequency
      ? RATE_BILLING_FREQUENCY_MAP[rate.billingFrequency]().month
      : null;
  }
  let percentOfTotalConfig: Maybe<IRateResSchema['percentOfTotalConfig']> =
    null;
  if (type === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL) {
    percentOfTotalConfig = rate.percentOfTotalConfig
      ? rate.percentOfTotalConfig
      : defaultPercentOfTotalConfig;
  }

  return {
    id: rate.id,
    customId: rate.customId,
    name: rate.name || '',
    description: rate.description || '',
    status: rate.status || '',
    currency: rate.currency || defaultCurrency,
    billingFrequency: rate.billingFrequency || null,
    billingFrequencyInMonths,
    usageBillingFrequency: rate.usageBillingFrequency || null,
    startDate: rate.startDate,
    endDate: rate.endDate,
    rateType: rate.rateType,
    prices: rate.prices,
    products: rateProducts,
    quotable: rate.quotable,
    subscriptionTiming: rate.subscriptionTiming || null,
    // Since internalDescription field can be null/undefined setting it as empty string
    // to make it easier to handle dirty state. Eventually it will be null if empty when we make api calls
    internalDescription: rate.internalDescription || '',
    minCommitConfig:
      type === OfferingTypesEnum.MIN_COMMIT ? rate.minCommitConfig : null,
    percentOfTotalConfig,
    options: {
      priceDisplay: rate.options?.priceDisplay,
      productOptions: rate.options?.productOptions || [],
    },
  };
};
