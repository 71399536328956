import {
  Flex,
  NumberInputFieldProps,
  NumberInputProps,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { additionRegex, hasAdditionRegex } from '../../constants/common';
import MCustomIconButton from './MCustomIconButton';
import { MCustomNumberInput } from './MCustomNumberInput';

interface MAdditionNumberInputProps extends NumberInputProps {
  styleProps?: NumberInputFieldProps;
  quoteColors?: any;
  iconColor?: string;
  previousQuantity?: number | null;
  handleInputBlur: (add: boolean) => void;
}

export const MAdditionNumberInput: FC<MAdditionNumberInputProps> =
  React.forwardRef<any, MAdditionNumberInputProps>(
    (
      {
        styleProps = {},
        quoteColors = {},
        iconColor,
        previousQuantity = 0,
        handleInputBlur,
        onChange,
        value,
        ...rest
      }: MAdditionNumberInputProps,
      ref: any,
    ) => {
      const [additionValue, setAdditionValue] = useState<string>('+');
      const [additionMode, setAdditionMode] = useState<boolean>(false);

      const reset = () => {
        setAdditionMode(false);
        setAdditionValue('+');
      };

      // this will update the value
      const doAddition = () => {
        if (previousQuantity !== null) {
          let newValue = Number(value);
          const additionValueAsNumber = Number(additionValue);
          const prevAsNum = Number(previousQuantity);
          // if NaN, it is likely the onBlur value is just '+'. stop update/addition if so.
          if (!Number.isNaN(additionValueAsNumber)) {
            newValue = additionValueAsNumber + prevAsNum;
          }
          // only update if different
          if (onChange && String(newValue) !== value) {
            onChange && onChange(String(newValue), newValue);
          }
        }
        reset();
        handleInputBlur(additionMode);
      };

      // FIXME: breaking change
      // // trigger addition on return/enter key press
      // useHotkeys('return', () => additionMode && doAddition(), {
      //   enableOnTags: ['INPUT'],
      // });

      // new onChange for MCustomNumberInput does not update the actual value
      const handleAddition = (val: string) => {
        if (
          (hasAdditionRegex.test(String(val)) || additionMode) &&
          previousQuantity !== null
        ) {
          setAdditionMode(true);
          let newAdditionValue = additionRegex.test(val) ? val : additionValue;
          // allow user to clear input
          if (val === '') {
            reset();
          }
          if (val === '+') {
            newAdditionValue = val;
          }
          // keep internalValue in sync
          setAdditionValue(newAdditionValue);
          return false;
        }
        // use internal handleChange of MCustomNumberInput
        return true;
      };

      const getInput = () => (additionMode ? additionValue : value);
      const valueDiff = () =>
        additionMode && additionValue !== '+' && additionValue !== value;

      return (
        <Flex>
          <MCustomNumberInput
            value={getInput()}
            handleChange={handleAddition}
            onChange={onChange}
            onBlur={doAddition}
            variant="quantity"
            styleProps={styleProps}
            mr={valueDiff() ? 0 : 7}
            ml={7}
            {...rest}
          />
          {valueDiff() && (
            <MCustomIconButton
              data-testid="addition-input-submit"
              bg="none"
              btnSize={4}
              py={4}
              px={2}
              ml={2}
              _hover={{
                background: 'none',
              }}
              iconColor={iconColor || 'tIndigo.base'}
              icon={MdCheck}
              onClick={doAddition}
            />
          )}
        </Flex>
      );
    },
  );
