import { Spinner } from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import { useState } from 'react';
import {
  MBox,
  MButton,
  MCenter,
  MFlex,
  MGrid,
  MGridItem,
  MText,
} from '../../../../../components/Monetize';

interface StepperComponentProps {
  content: Array<{
    label: string;
    item: JSX.Element;
    footer: {
      prev?: {
        label: string;
        disable?: boolean;
        loading?: boolean;
        onClick?: (
          event: (forcedIndex?: number) => void,
          resetStepper?: () => void,
        ) => void;
      };
      next: {
        label: string;
        loading?: boolean;
        disable?: boolean;
        onClick?: (
          event: (forcedIndex?: number) => void,
          resetStepper?: () => void,
        ) => void;
      };
      skip?: {
        label: string;
        loading?: boolean;
        disable?: boolean;
        onClick?: (
          event: (forcedIndex?: number) => void,
          resetStepper?: () => void,
        ) => void;
      };
    };
  }>;
  defaultIndex?: number;
  loading?: boolean;
  onNextStep?: () => void;
  onBackStep?: () => void;
}

export const StepperComponent = ({
  content,
  defaultIndex = 0,
  loading,
  onNextStep,
  onBackStep,
}: StepperComponentProps) => {
  const [currentIndex, setCurrentIndex] = useState(defaultIndex);

  const canMoveNext = !isNil(content[currentIndex + 1]);
  const canMovePrev = currentIndex > 0;

  const resetStepper = () => {
    setCurrentIndex(defaultIndex);
  };

  const handleIndexChange = (type: 'next' | 'prev' | 'skip') => {
    function moveIndex(step: number) {
      setCurrentIndex((prevIndex) => prevIndex + step);
    }

    const footer = content[currentIndex]?.footer;
    const onClick = footer?.[type]?.onClick;
    const step = type === 'prev' ? -1 : 1;

    if (typeof onClick === 'function') {
      onClick(
        (forcedIndex?: number) => moveIndex(forcedIndex ?? step),
        resetStepper,
      );
      return;
    }

    if ((type === 'next' && canMoveNext) || (type === 'prev' && canMovePrev)) {
      moveIndex(step);
    }

    if (type === 'next' && onNextStep) {
      onNextStep();
    } else if (type === 'prev' && onBackStep) {
      onBackStep();
    }
  };

  const handleNextIndex = () => handleIndexChange('next');
  const handlePrevIndex = () => handleIndexChange('prev');
  const handleSkipIndex = () => handleIndexChange('skip');

  if (loading) {
    return (
      <MCenter>
        <Spinner />
      </MCenter>
    );
  }

  const currentTemplateColumns: Record<number, string> = {
    0: '0fr 0.5fr 1fr',
    1: '0fr 0.5fr 1fr',
    2: '0fr 1fr',
  };

  return (
    <MGrid
      templateColumns={currentTemplateColumns[currentIndex as number]}
      gap={4}
    >
      <MGridItem>
        <MText fontWeight="semibold">{currentIndex + 1}. </MText>
      </MGridItem>
      <MGridItem>
        <MBox>
          <MText mb={4} fontWeight="semibold">
            {content[currentIndex].label}
          </MText>
          <MBox mb={4}>{content[currentIndex].item}</MBox>
          <MFlex>
            {content[currentIndex]?.footer?.prev && (
              <MButton
                w={20}
                isLoading={content[currentIndex]?.footer?.prev?.loading}
                isDisabled={
                  !canMovePrev || content[currentIndex]?.footer?.prev?.disable
                }
                variant="secondary"
                mr={2}
                onClick={handlePrevIndex}
              >
                {content[currentIndex]?.footer?.prev?.label}
              </MButton>
            )}
            <MButton
              mr={2}
              w={20}
              isLoading={content[currentIndex]?.footer?.next?.loading}
              isDisabled={content[currentIndex]?.footer?.next?.disable}
              onClick={handleNextIndex}
            >
              {content[currentIndex]?.footer?.next?.label}
            </MButton>
            {content[currentIndex]?.footer?.skip && (
              <MButton
                variant={'tertiary'}
                style={{ minWidth: '2rem' }}
                isLoading={content[currentIndex]?.footer?.skip?.loading}
                isDisabled={content[currentIndex]?.footer?.skip?.disable}
                onClick={handleSkipIndex}
              >
                {content[currentIndex]?.footer?.skip?.label}
              </MButton>
            )}
          </MFlex>
        </MBox>
      </MGridItem>
    </MGrid>
  );
};
