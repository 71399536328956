import { MBox, MFlex, MText } from '../../../../../components/Monetize';

export interface QuoteOfferingScheduledChangeProps {
  isReadOnly: boolean;
  handleScheduleChangeInitiated: () => void;
}

/**
 * This component rendered + Schedule Change Row Button
 */
export const QuoteOfferingScheduledChangeButton = ({
  isReadOnly,
  handleScheduleChangeInitiated,
}: QuoteOfferingScheduledChangeProps) => {
  return (
    <MFlex gap="4" gridColumn={'1 / -1'} role="group" mt="-2">
      <MBox
        width="full"
        position="relative"
        onClick={handleScheduleChangeInitiated}
        cursor="pointer"
      >
        <MBox
          w="full"
          backgroundColor="transparent"
          _groupHover={{ backgroundColor: 'tBlue.lightShade' }}
          my="7px"
          height="3px"
        />

        <MBox
          bgColor="transparent"
          _groupHover={{ backgroundColor: 'tBlue.hover' }}
          left="50px"
          top="0px"
          padding="2px 4px 2px 4px"
          borderRadius="2px"
          position="absolute"
        >
          <MText
            color="transparent"
            _groupHover={{ color: 'tIndigo.base' }}
            fontWeight="bold"
            fontSize="10px"
            textTransform="uppercase"
          >
            + Schedule Change
          </MText>
        </MBox>
      </MBox>
    </MFlex>
  );
};
