import { Divider } from '@chakra-ui/react';
import { FunctionComponent as FC, useMemo, useState } from 'react';
import { getMessageFromError } from '../../../../../api/axios';
import { useSearchCrmOpportunities } from '../../../../../api/crmService';
import { useGetCrmConfiguration } from '../../../../../api/settingsService';
import { MCustomSelect, MFormField } from '../../../../../components/Monetize';
import { MainSearchInput } from '../../../../../components/Monetize/MCustomSelect/components/MainSearchInput';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { CrmOpportunityForInput, Maybe } from '../../../../../types';

interface OpportunitySearchInputProps {
  label?: string;
  accountId: string;
  crmOpportunityId?: Maybe<string>;
  onChange: (crmOpportunity: CrmOpportunityForInput) => void;
  showDivider?: boolean;
}

/**
 * Search for an Opportunity in the CRM
 * This is a typeahead search that will search for opportunities in the CRM
 */
export const OpportunitySearchInput: FC<OpportunitySearchInputProps> = ({
  label = 'Link a Salesforce Opportunity',
  accountId,
  crmOpportunityId,
  onChange,
  showDivider = false,
}: OpportunitySearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchTermDebounced = useDebounce(searchTerm);

  // Hubspot is not yet supported
  const { data: crmConnections, isLoading: crmConfigLoading } =
    useGetCrmConfiguration({
      meta: { showErrorToast: true },
    });

  const {
    data: items,
    isFetching: searchLoading,
    error,
  } = useSearchCrmOpportunities(accountId, searchTermDebounced, {
    enabled:
      !crmConfigLoading &&
      !!accountId &&
      !!crmConnections?.salesforce /** || crmConnections?.hubspot */,
    select: (values) =>
      values.map((item) => ({ ...item, rightLabel: item.stage })),
  });

  const errorMessage = useMemo(
    () =>
      error
        ? {
            message: getMessageFromError(
              error,
              'There was a problem searching for opportunities.',
            ),
          }
        : null,
    [error],
  );

  const onChangeQuery = (q: string): void => {
    setSearchTerm(q);
  };

  if (
    crmConfigLoading ||
    !accountId ||
    !crmConnections?.salesforce /** && !crmConnections?.hubspot */
  ) {
    return null;
  }

  return (
    <>
      {showDivider && crmConnections?.salesforce && <Divider />}

      <MFormField label={label} error={errorMessage}>
        <MCustomSelect
          clearable
          items={items}
          itemTitle="name"
          itemValue="id"
          useMainInputAsSearchInput
          useExternalQuery
          externalQuery={searchTerm}
          onChangeQuery={onChangeQuery}
          itemSearch="q"
          MainInputComponent={MainSearchInput}
          loading={searchLoading}
          placeholder="Search"
          value={crmOpportunityId || ''}
          onChange={(val: any) => {
            onChange({
              id: val,
              name: items?.find((item) => item.id === val)?.name || '',
            });
          }}
        />
      </MFormField>
    </>
  );
};
