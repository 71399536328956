import { Flex } from '@chakra-ui/react';
import {
  ConditionOperatorEnum,
  ConditionOperatorTypeEnum,
  ExpressionOperatorEnum,
  FieldTypeEnum,
  ICondition,
  IRule,
  IRuleCondition,
  IRuleFormResponse,
  QuoteEntityEnum,
  ReferenceEntityEnum,
  RuleFormField,
  RuleSchema,
  RuleStatusEnum,
  RuleTypeEnum,
  TypeOperator,
  TypeOperatorByFieldType,
  TypeOperatorEnum,
} from '@monetize/types/app';
import { ReactNode } from 'react';
import { composeGetQuery } from '../../../api/utils';
import { MFlex, MText } from '../../../components/Monetize';
import { Highlighter } from '../../../components/Monetize/MPageSearchInput/Highlighter';
import { ROUTES } from '../../../constants';
import { DISCOUNT_STATUS_ENUM_DISPLAY } from '../../../constants/discounts';
import {
  OFFERING_STATUS_DISPLAY,
  OFFERING_TYPES_ENUM_DISPLAY,
  RATE_BILLING_FREQUENCY_DISPLAY,
  SUBSCRIPTION_TIMING_OPTIONS,
} from '../../../constants/offerings';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '../../../constants/products';
import { QuoteStatusEnumDisplay } from '../../../constants/quotes';
import {
  getRuleEditRouteV3Wrapper,
  getRuleListRoute,
} from '../../../constants/routes';
import { RULE_STATUS_DISPLAY } from '../../../constants/rules';
import { TEAM_TYPE_ENUM_DISPLAY } from '../../../constants/teams';
import { USER_TYPE_DISPLAY } from '../../../constants/users';
import { GetListApiConfig, Maybe, UserTypeEnum } from '../../../types';
import { MCustomSelectProps } from '../../../types/mCustomSelectTypes';
import { nullifyEmptyStrings } from '../../../utils/misc';

type RouteParamType = Parameters<typeof getRuleListRoute>[0];

export type ReferenceOptions = Pick<
  MCustomSelectProps,
  | 'additionalSearchParams'
  | 'itemSearch'
  | 'loadAll'
  | 'getByIdEndpointFn'
  | 'itemTitle'
  | 'omitContainsItemSearch'
  | 'itemValue'
  | 'endpoint'
  | 'renderItemContent'
  | 'popOverContentProps'
  | 'returnItem'
>;

// If the operator changes value, some types require clearing out the value
export const compatibleOperatorTypes = [
  new Set([
    ConditionOperatorEnum.EQUAL,
    ConditionOperatorEnum.NOT_EQUAL,
    ConditionOperatorEnum.GREATER_THAN,
    ConditionOperatorEnum.GREATER_THAN_INCLUSIVE,
    ConditionOperatorEnum.LESS_THAN,
    ConditionOperatorEnum.LESS_THAN_INCLUSIVE,
    ConditionOperatorEnum.STARTS_WITH,
    ConditionOperatorEnum.ENDS_WITH,
    ConditionOperatorEnum.CONTAINS,
  ]),
  new Set([ConditionOperatorEnum.IN, ConditionOperatorEnum.NOT_IN]),
  new Set([ConditionOperatorEnum.EXISTS]),
  new Set([
    ConditionOperatorEnum.N_DAYS_IN_PAST,
    ConditionOperatorEnum.N_WEEKS_IN_PAST,
    ConditionOperatorEnum.N_MONTHS_IN_PAST,
    ConditionOperatorEnum.N_YEARS_IN_PAST,
    ConditionOperatorEnum.N_DAYS_IN_FUTURE,
    ConditionOperatorEnum.N_WEEKS_IN_FUTURE,
    ConditionOperatorEnum.N_MONTHS_IN_FUTURE,
    ConditionOperatorEnum.N_YEARS_IN_FUTURE,
  ]),
  new Set([
    ConditionOperatorEnum.FIRST_DAY_OF_MONTH,
    ConditionOperatorEnum.LAST_DAY_OF_MONTH,
  ]),
  new Set([
    ConditionOperatorEnum.BEFORE_CURRENT_MONTH,
    ConditionOperatorEnum.AFTER_CURRENT_MONTH,
  ]),
  new Set([
    ConditionOperatorEnum.N_DAYS_BEFORE_CONTRACT_START,
    ConditionOperatorEnum.N_DAYS_AFTER_CONTRACT_START,
  ]),
];

export const RuleStatusTagStyle: {
  [key in RuleStatusEnum]: {
    color: string;
    bgColor?: string;
    px?: number;
    borderRadius?: number;
    fontStyle?: string;
  };
} = {
  ACTIVE: {
    color: 'tGray.darkGrayPurple',
  },
  INACTIVE: {
    color: 'tRed.base',
  },
  ARCHIVED: {
    color: 'tRed.base',
  },
};

// This should cover pretty much all statuses - we just need to display the text
const STATUS_DISPLAY_OPTIONS = {
  ...OFFERING_STATUS_DISPLAY,
  ...QuoteStatusEnumDisplay,
};

export const listOperators = new Set([
  ConditionOperatorEnum.IN,
  ConditionOperatorEnum.NOT_IN,
]);

export const getRuleRouteType = (type: RuleTypeEnum): RouteParamType => {
  switch (type) {
    case RuleTypeEnum.APPROVAL:
      return 'approvals';
    case RuleTypeEnum.CONDITIONAL_TERM:
      return 'conditional-terms';
    case RuleTypeEnum.VALIDATION:
      return 'validations';
    default:
      throw new Error(`Invalid rule type: ${type}`);
  }
};

export const getNewRule = (): IRuleCondition => {
  const output: IRuleCondition = {
    entity: QuoteEntityEnum.QUOTE,
    expressionOperator: ExpressionOperatorEnum.AND,
    customLogic: '',
    conditions: [getNewRuleCondition()],
  };
  return output;
};

export const getNewRuleCondition = (): ICondition => {
  return {
    field: '',
    operator: ConditionOperatorEnum.EQUAL,
    value: {
      type: ConditionOperatorTypeEnum.LITERAL,
      value: '',
    },
  };
};

export const getOperators = (
  ruleFormData: IRuleFormResponse,
  selectedEntity: QuoteEntityEnum,
  selectedField: string,
): ConditionOperatorEnum[] => {
  let operations = new Set<ConditionOperatorEnum>();
  const operatorType =
    ruleFormData.fields[selectedEntity]?.fields[selectedField]?.type;
  if (operatorType && ruleFormData.operatorTypes[operatorType]?.operators) {
    operations = new Set(
      Object.keys(
        ruleFormData.operatorTypes[operatorType]?.operators || {},
      ) as ConditionOperatorEnum[],
    );
  }

  return ruleFormData.operators.filter((value) => operations.has(value));
};

/**
 * Get value type for selected field and operator
 * This determines what kind of input will be rendered
 * And how to fetch related data if applicable
 */
export function getValueType(
  operatorTypes: TypeOperatorByFieldType,
  selectedFieldMetadata: Maybe<RuleFormField>,
  selectedOperator: Maybe<ConditionOperatorEnum>,
  tenantId: string,
): TypeOperator & {
  referenceOptions?: Pick<
    MCustomSelectProps,
    'itemTitle' | 'itemValue' | 'endpoint'
  >;
} {
  if (!selectedFieldMetadata || !selectedOperator) {
    return { type: TypeOperatorEnum.TEXT };
  }

  const typeOperator =
    operatorTypes[selectedFieldMetadata.type]?.operators?.[selectedOperator];

  if (typeOperator) {
    if (
      typeOperator.type === TypeOperatorEnum.FIELD_VALUES_LIST &&
      selectedFieldMetadata.values
    ) {
      return {
        type: TypeOperatorEnum.LIST,
        options: selectedFieldMetadata.values,
      };
    }
    if (
      typeOperator.type === TypeOperatorEnum.REFERENCE_LIST &&
      selectedFieldMetadata.referenceEntity
    ) {
      let referenceOptions: ReferenceOptions = {};

      const listApiConfig: GetListApiConfig = {
        first: 0,
        rows: 50,
        page: 0,
        sortField: 'name',
        sortOrder: 1,
      };
      if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.ACCOUNT
      ) {
        listApiConfig.sortField = 'accountName';
        // query
        referenceOptions = {
          endpoint: `/api/accounts/search`,
          additionalSearchParams: composeGetQuery(listApiConfig),
          getByIdEndpointFn: (recordId) => `/api/accounts/${recordId}`,
          itemSearch: 'query',
          omitContainsItemSearch: true,
          itemTitle: 'accountName',
          itemValue: 'id',
          renderItemContent: renderItemContentForAccount,
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.CURRENCY
      ) {
        listApiConfig.sortField = 'code';
        referenceOptions = {
          endpoint: `/api/currencies`,
          additionalSearchParams: composeGetQuery(listApiConfig),
          loadAll: true,
          itemTitle: 'code',
          itemValue: 'code',
          renderItemContent: renderItemForCurrency,
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.OFFERING
      ) {
        referenceOptions = {
          endpoint: `/api/offerings/summary`,
          additionalSearchParams: composeGetQuery(listApiConfig),
          loadAll: true,
          getByIdEndpointFn: (recordId) =>
            `/api/offerings/summary?id=${recordId}`,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemDynamicContent({
            row2Item1Fn: (item) => OFFERING_TYPES_ENUM_DISPLAY[item.type],
            row3Item1Fn: (item) => item.id,
            row3Item2Fn: (item) =>
              STATUS_DISPLAY_OPTIONS[item.status] || item.status,
          }),
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.PRODUCT
      ) {
        referenceOptions = {
          endpoint: `/api/products`,
          loadAll: true,
          additionalSearchParams: composeGetQuery(listApiConfig),
          getByIdEndpointFn: (recordId) => `/api/products/${recordId}`,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemDynamicContent({
            row2Item1Fn: (item) => PRODUCT_TYPE_DISPLAY_SHORT[item.productType],
            row3Item1Fn: (item) => item.id,
            row3Item2Fn: (item) =>
              STATUS_DISPLAY_OPTIONS[item.status] || item.status,
          }),
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.RATE
      ) {
        referenceOptions = {
          endpoint: `/api/rates`,
          additionalSearchParams: composeGetQuery(listApiConfig),
          getByIdEndpointFn: (recordId) => `/api/rates/${recordId}`,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemDynamicContent({
            row2Item1Fn: (item) =>
              RATE_BILLING_FREQUENCY_DISPLAY[item.billingFrequency],
            row2Item2Fn: (item) =>
              SUBSCRIPTION_TIMING_OPTIONS[item.subscriptionTiming],
            row3Item1Fn: (item) => item.id,
            row3Item2Fn: (item) =>
              STATUS_DISPLAY_OPTIONS[item.status] || item.status,
          }),
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.RULE
      ) {
        listApiConfig.sortField = 'name';
        referenceOptions = {
          endpoint: `/rules-v3/rules`,
          additionalSearchParams: composeGetQuery(listApiConfig, {
            type: 'CONDITIONAL_TERM',
          }),
          loadAll: true,
          getByIdEndpointFn: (recordId) => `/rules-v3/rules/${recordId}`,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemDynamicContent({
            row2Item1Fn: (item) =>
              RULE_STATUS_DISPLAY[item.status] || item.status,
          }),
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.TEAM
      ) {
        listApiConfig.sortField = 'name';
        referenceOptions = {
          endpoint: `/api/teams`,
          additionalSearchParams: composeGetQuery(listApiConfig),
          loadAll: true,
          getByIdEndpointFn: (recordId) => `/api/teams/${recordId}`,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemDynamicContent({
            row2Item1Fn: (item) => `${TEAM_TYPE_ENUM_DISPLAY[item.type]} Team`,
            row3Item1Fn: (item) => item.id,
          }),
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.USER
      ) {
        listApiConfig.sortField = 'name';
        referenceOptions = {
          endpoint: `/api/tenants/${tenantId}/users`,
          additionalSearchParams: composeGetQuery(listApiConfig, {
            type: { in: [UserTypeEnum.APP, UserTypeEnum.USER] },
          }),
          loadAll: true,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemContentForOwner,
        };
      } else if (
        selectedFieldMetadata.referenceEntity === ReferenceEntityEnum.DISCOUNTS
      ) {
        listApiConfig.sortField = 'name';
        referenceOptions = {
          endpoint: `api/discounts`,
          additionalSearchParams: composeGetQuery(listApiConfig, {
            accountId: 'eq:null',
          }),
          loadAll: true,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemDynamicContent({
            row2Item1Fn: (item) => item.id,
            row2Item2Fn: (item) => DISCOUNT_STATUS_ENUM_DISPLAY[item.status],
          }),
        };
      } else if (
        selectedFieldMetadata.referenceEntity ===
        ReferenceEntityEnum.LEGAL_ENTITY
      ) {
        listApiConfig.sortField = 'name';
        referenceOptions = {
          endpoint: `api/configurations/legalEntities`,
          additionalSearchParams: composeGetQuery(listApiConfig, {}),
          loadAll: true,
          itemTitle: 'name',
          itemValue: 'id',
          renderItemContent: renderItemDynamicContent({
            row2Item1Fn: (item) => item.id,
          }),
        };
      }
      referenceOptions.popOverContentProps = { width: '100%' };

      return { type: TypeOperatorEnum.REFERENCE_LIST, referenceOptions };
    }
    return typeOperator;
  }
  return { type: TypeOperatorEnum.TEXT };
}

export const renderItemContentForItemsWithIdName: ReferenceOptions['renderItemContent'] =
  ({ item, query }) => {
    return (
      <MFlex
        direction="column"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        w="100%"
      >
        <MFlex justifyContent="space-between" w="100%">
          <Highlighter
            title={item.name}
            color="tPurple.dark"
            fontSize="sm"
            fontWeight="medium"
            textToHighlight={item.name}
            searchWords={[query]}
            whiteSpace="normal"
          />
        </MFlex>
        <MText color="tGray.darkPurple" fontSize="xs">
          {item.id}
        </MText>
      </MFlex>
    );
  };

export const renderItemContentForItemsWithIdNameStatus: ReferenceOptions['renderItemContent'] =
  ({ item, query }) => {
    return (
      <MFlex
        direction="column"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        w="100%"
      >
        <MFlex justifyContent="space-between" w="100%">
          <Highlighter
            title={item.name}
            color="tPurple.dark"
            fontSize="sm"
            fontWeight="medium"
            textToHighlight={item.name}
            searchWords={[query]}
            whiteSpace="normal"
          />
        </MFlex>
        <MFlex justifyContent="space-between">
          <MText color="tGray.darkPurple" fontSize="xs">
            {item.id}
          </MText>
          <MText color="tGray.darkPurple" fontSize="xs">
            {STATUS_DISPLAY_OPTIONS[item.status] || item.status}
          </MText>
        </MFlex>
      </MFlex>
    );
  };

const renderItemContentForAccount: ReferenceOptions['renderItemContent'] = ({
  item,
  query,
}) => {
  return (
    <MFlex
      direction="column"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      w="100%"
    >
      <MFlex flexDir="column" w="100%">
        <Highlighter
          title={item.accountName}
          color="tPurple.dark"
          fontSize="sm"
          fontWeight="medium"
          textToHighlight={item.accountName}
          searchWords={[query]}
        />
        <Highlighter
          title={item.id}
          color="tGray.darkPurple"
          fontSize="xs"
          textToHighlight={item.id}
          searchWords={[query]}
        />
        <Highlighter
          title={item.customId}
          color="tGray.darkPurple"
          fontSize="xs"
          textToHighlight={item.customId}
          searchWords={[query]}
        />
      </MFlex>
    </MFlex>
  );
};

const renderItemDynamicContent = ({
  row2Item1Fn,
  row2Item2Fn,
  row3Item1Fn,
  row3Item2Fn,
}: {
  row2Item1Fn: (item: any) => ReactNode;
  row2Item2Fn?: (item: any) => ReactNode;
  row3Item1Fn?: (item: any) => ReactNode;
  row3Item2Fn?: (item: any) => ReactNode;
}) => {
  const fn: ReferenceOptions['renderItemContent'] = ({ item, query }) => {
    return (
      <MFlex
        direction="column"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        w="100%"
      >
        <MFlex justifyContent="space-between" w="100%">
          <Highlighter
            title={item.name}
            color="tPurple.dark"
            fontSize="sm"
            fontWeight="medium"
            textToHighlight={item.name}
            searchWords={[query]}
            whiteSpace="normal"
          />
        </MFlex>
        <MFlex justifyContent="space-between" w="100%">
          <MText color="tGray.darkPurple" fontSize="xs">
            {row2Item1Fn(item)}
          </MText>
          {row2Item2Fn && (
            <MText color="tGray.darkPurple" fontSize="xs">
              {row2Item2Fn(item)}
            </MText>
          )}
        </MFlex>
        {row3Item1Fn && (
          <MFlex justifyContent="space-between">
            <MText color="tGray.darkPurple" fontSize="xs">
              {row3Item1Fn(item)}
            </MText>
            {row3Item2Fn && (
              <MText color="tGray.darkPurple" fontSize="xs">
                {row3Item2Fn(item)}
              </MText>
            )}
          </MFlex>
        )}
      </MFlex>
    );
  };
  return fn;
};

export const renderItemForCurrency: ReferenceOptions['renderItemContent'] = ({
  title,
  item,
  isSubtitle,
}) => {
  return (
    <Flex alignItems="center">
      <MText
        color="inherit"
        fontWeight="bold"
        isTruncated
        noOfLines={1}
        fontSize={isSubtitle ? '12px' : 'sm'}
        mr="2"
      >
        {title}
      </MText>
      <MText
        fontWeight={'normal'}
        color="inherit"
        isTruncated
        noOfLines={1}
        fontSize={isSubtitle ? '12px' : 'sm'}
      >
        {item.description}
      </MText>
    </Flex>
  );
};

export const renderItemContentForOwner: ReferenceOptions['renderItemContent'] =
  ({ item, query }) => {
    return (
      <MFlex
        direction="column"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        w="100%"
      >
        <MFlex justifyContent="space-between" w="100%">
          <Highlighter
            title={item.name}
            color="tPurple.dark"
            fontSize="sm"
            fontWeight="medium"
            textToHighlight={item.name}
            searchWords={[query]}
            whiteSpace="normal"
          />
        </MFlex>
        <MText color="tGray.darkPurple" fontSize="xs">
          {item.id} ({USER_TYPE_DISPLAY[item.type]})
        </MText>
      </MFlex>
    );
  };

/**
 * Fill in all non-critical fields with default values so a user can test the rule
 * without having to fill in every field
 */
export function prepareRuleForTest(rule: IRule) {
  rule = nullifyEmptyStrings(rule);

  // Ensure that all required fields are initialized if they are not part of rule logic
  rule.name = rule.name || 'Test Rule';
  rule.description = rule.description || 'Test Rule';
  rule.status = rule.status || RuleStatusEnum.ACTIVE;
  switch (rule.action.actionType) {
    case RuleTypeEnum.APPROVAL:
      break;
    case RuleTypeEnum.CONDITIONAL_TERM:
      rule.action.conditionalTerms.terms =
        rule.action.conditionalTerms.terms || 'Test Rule';
      break;
    case RuleTypeEnum.VALIDATION:
      rule.action.validation.message =
        rule.action.validation.message || 'Test Rule';
      break;
  }

  return RuleSchema.safeParse(rule);
}

export const getTitleByType = (type: RuleTypeEnum) => {
  switch (type) {
    case RuleTypeEnum.APPROVAL:
      return {
        single: 'Approval Rule',
        plural: 'Approvals',
      };
    case RuleTypeEnum.VALIDATION:
      return {
        single: 'Validation Rule',
        plural: 'Validations',
      };
    case RuleTypeEnum.CONDITIONAL_TERM:
      return {
        single: 'Conditional Term',
        plural: 'Conditional Terms',
      };
  }
};

export const RULE_DATA_KEY_V3: {
  [key in RuleTypeEnum]: {
    queryKey: 'approvals-v3' | 'validations-v3' | 'conditionalTerms-v3';
    getEditPage: (id: string) => string;
    getClonePage: () => string;
  };
} = {
  [RuleTypeEnum.APPROVAL]: {
    queryKey: 'approvals-v3',
    getEditPage: getRuleEditRouteV3Wrapper('approvals'),
    getClonePage: () => ROUTES.SETTINGS_APPROVALS_V3_CREATE,
  },
  [RuleTypeEnum.VALIDATION]: {
    queryKey: 'validations-v3',
    getEditPage: getRuleEditRouteV3Wrapper('validations'),
    getClonePage: () => ROUTES.SETTINGS_VALIDATIONS_V3_CREATE,
  },
  [RuleTypeEnum.CONDITIONAL_TERM]: {
    queryKey: 'conditionalTerms-v3',
    getEditPage: getRuleEditRouteV3Wrapper('conditional-terms'),
    getClonePage: () => ROUTES.SETTINGS_CONDITIONAL_TERMS_V3_CREATE,
  },
};

export const getTempType = (value: any, isDate: boolean = false) => {
  if (value === ConditionOperatorTypeEnum.FIELD) {
    return value;
  }
  return isDate
    ? ConditionOperatorTypeEnum.DATE
    : ConditionOperatorTypeEnum.LITERAL;
};

export const isDateFieldType = (fieldType: FieldTypeEnum) => {
  return (
    fieldType === FieldTypeEnum.DATE ||
    fieldType === FieldTypeEnum.DATE_EXTENDED
  );
};

export const VALUE_FIELD_TYPES = [
  { key: ConditionOperatorTypeEnum.LITERAL, label: 'Value' },
  { key: ConditionOperatorTypeEnum.FIELD, label: 'Field' },
];
