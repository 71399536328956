import { formatCurrency } from '@monetize/utils/core';
import { ColumnProps } from 'primereact/column';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOpportunityById } from '../../api/cpqService';
import { useGetCrmConfiguration } from '../../api/settingsService';
import { ROUTES } from '../../constants';
import { OPPORTUNITY_STATUS_DISPLAY } from '../../constants/opportunities';
import {
  QUOTE_SENT_STATUSES,
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
  QuoteTypeEnumDisplay,
} from '../../constants/quotes';
import { useCurrencies } from '../../hooks/useCurrencies';
import { useACL } from '../../services/acl/acl';
import {
  IOpportunityQuote,
  Maybe,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '../../types';
import { openQuote } from '../../utils/quotes';
import {
  currencyBodyTemplate,
  dateTimeBodyTemplate,
  idBodyTemplate,
  statusBodyTemplate,
} from '../../utils/tableUtils';
import {
  MBox,
  MCenterModal,
  MDataTable,
  MDivider,
  MFlex,
  MHStack,
  MPageHeader,
  MSkeleton,
  MTag,
  MText,
  MVStack,
} from '../Monetize';
import { TitleValuePair } from '../Monetize/TitleValuePair';
import { OpportunityLinkCrmOpportunityInput } from './OpportunityLinkCrmOpportunityInput';
import { OpportunityModalActions } from './OpportunityModalActions';
import { OpportunityQuoteActions } from './OpportunityQuoteActions';

interface OpportunityModalProps {
  isOpen: boolean;
  opportunityId?: Maybe<string>;
  isDisabled?: boolean;
  onClose: (skipNavigate?: boolean) => void;
}

export const OpportunityModal = ({
  isOpen,
  opportunityId: opportunityIdProp,
  isDisabled,
  onClose,
}: OpportunityModalProps) => {
  const navigate = useNavigate();
  const params = useParams();

  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);

  const opportunityId = opportunityIdProp || params?.opportunityId || '';
  const { defaultCurrency } = useCurrencies();
  const { data: opportunity, isLoading: isOpportunityLoading } =
    useGetOpportunityById(opportunityId, {
      enabled: isOpen && !!opportunityId,
    });
  const { data: crmConnections } = useGetCrmConfiguration({
    enabled: isOpen && !!opportunityId,
  });
  const { primaryQuoteId } = opportunity || {};
  const hasProcessedOrSentQuote = !!opportunity?.quotes?.some((quote) =>
    QUOTE_SENT_STATUSES.has(quote.status),
  );

  const canUserModifyCrmLink =
    !isDisabled && canUpdateSales && !hasProcessedOrSentQuote;

  const quoteNameBodyTemplate = (rowData: IOpportunityQuote) => (
    <MHStack spacing={3}>
      <MText noOfLines={1} color="inherit">
        {rowData.name}
      </MText>
      {rowData.id === primaryQuoteId && <MTag variant="blue">Primary</MTag>}
    </MHStack>
  );

  const quoteColumns: ColumnProps[] = [
    {
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IOpportunityQuote>('id'),
      className: 'table-cell-md',
    },
    {
      field: 'name',
      header: 'Quote Name',
      body: quoteNameBodyTemplate,
      className: 'table-cell-md',
    },
    {
      field: 'modifyDate',
      header: 'Updated',
      body: dateTimeBodyTemplate<IOpportunityQuote>('modifyDate'),
      className: 'table-cell-md',
    },
    {
      field: 'type',
      header: 'Type',
      body: statusBodyTemplate<IOpportunityQuote, QuoteTypeEnum>(
        'type',
        QuoteTypeEnumDisplay,
      ),
      className: 'table-cell-md',
    },
    {
      field: 'status',
      header: 'Status',
      body: statusBodyTemplate<IOpportunityQuote, QuoteStatusEnum>(
        'status',
        QuoteStatusEnumDisplay,
        QuoteStatusTagStyle,
      ),
      className: 'table-cell-sm',
    },
    {
      field: 'total',
      header: 'Total',
      body: currencyBodyTemplate<IOpportunityQuote>('total', 'currency'), // FIXME: we don't know currency ISO code
      className: 'text-right table-cell-md',
      headerStyle: {
        textAlign: 'right',
      },
    },
    {
      field: 'action',
      header: '',
      body: (opportunityQuote: IOpportunityQuote) => {
        if (opportunity) {
          return (
            <OpportunityQuoteActions
              isDisabled={isDisabled}
              opportunity={opportunity}
              opportunityQuote={opportunityQuote}
            />
          );
        }
        return null;
      },
      style: { paddingRight: '8px', width: '1rem' },
    },
  ];

  const title = opportunity && (
    <MVStack alignItems="flex-start">
      <MPageHeader
        title="Opportunity Details"
        copyUrl
        id={opportunity?.id}
        status={OPPORTUNITY_STATUS_DISPLAY[opportunity?.status]}
        containerProps={{ mb: 0 }}
      ></MPageHeader>
    </MVStack>
  );

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      size="6xl"
      modalContentProps={{ 'data-testid': 'opportunity-form' } as any}
      renderModalTitleActions={() => title}
    >
      {isOpportunityLoading && <MSkeleton width={'full'} height={10} mt={4} />}
      {!isOpportunityLoading && opportunity && (
        <MFlex
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'nowrap'}
        >
          <MHStack gap={6}>
            <MBox maxW={'330'}>
              <TitleValuePair
                title="Opportunity Name"
                value={opportunity?.name}
              />
            </MBox>
            <MBox minW={'145'}>
              <TitleValuePair
                title="Opportunity Amount"
                value={formatCurrency(opportunity?.amount, {
                  currency: opportunity.currency || defaultCurrency,
                })}
              />
            </MBox>

            {/*
                For Salesforce, allow searching for and linking Opportunity
                If user is not allowed to change, still show the link if it exists
               */}
            {opportunity &&
              crmConnections?.salesforce &&
              (canUserModifyCrmLink || !!opportunity.customId) && (
                <MBox>
                  <TitleValuePair
                    title="CRM Opportunity"
                    value={
                      <OpportunityLinkCrmOpportunityInput
                        isDisabled={!canUserModifyCrmLink}
                        crmType="salesforce"
                        opportunity={opportunity}
                        quotes={opportunity?.quotes || []}
                      />
                    }
                  />
                </MBox>
              )}
          </MHStack>

          {opportunity && (
            <MFlex justifyContent={'flex-end'} minW={10}>
              <OpportunityModalActions
                isDisabled={isDisabled}
                opportunity={opportunity}
              />
            </MFlex>
          )}
        </MFlex>
      )}

      <MDivider mt={6} mb={5} borderWidth="2px" borderColor="tBlue.hover" />
      <MText fontSize="md" fontWeight="bold">
        Quotes
      </MText>
      {isOpportunityLoading && <MSkeleton height={100} width="full" mt={8} />}
      {!isOpportunityLoading && (
        <MDataTable
          value={opportunity?.quotes || []}
          totalRecords={opportunity?.quotes?.length || 0}
          className="p-datatable-responsive"
          emptyProps={{
            smallMessage: 'No quotes are associated with this opportunity.',
          }}
          columns={quoteColumns}
          onRowClick={(e: DataTableRowClickEvent, openInNewWindow) => {
            const quoteId = e.data?.id;
            if (
              [
                ROUTES.getQuoteEditRoute(quoteId),
                ROUTES.getQuoteReviewRoute(quoteId),
              ].includes(window.location.pathname)
            ) {
              onClose(true);
              return;
            }

            openQuote(navigate, quoteId, e.data?.status, openInNewWindow);
            onClose(true);
          }}
        />
      )}
    </MCenterModal>
  );
};
