import { Link } from '@chakra-ui/react';
import { useRef } from 'react';
import { MdEdit } from 'react-icons/md';
import { Link as ReactDomLink } from 'react-router-dom';
import { colors } from '../../styles/theme';
import { MCard, MCustomIconButton, MFlex, MHeading, MText } from '../Monetize';

export interface LabelValueLinkItem {
  label: string;
  value: string;
  link?: string;
}

const KeyValueDetail = ({ label, value, link }: LabelValueLinkItem) => {
  const id = `label_${label}`;
  return (
    <MFlex direction="column" gap={2} pt={2}>
      <MText id={id} color={colors.tGray.darkPurple}>
        {label}
      </MText>
      {value === 'Blank' ? (
        <MText aria-labelledby={id} fontStyle="italic">
          Blank
        </MText>
      ) : (
        <MText aria-labelledby={id}>
          {link ? (
            <Link as={ReactDomLink} to={link}>
              {value}
            </Link>
          ) : (
            value
          )}
        </MText>
      )}
    </MFlex>
  );
};

interface DynamicLabelValueBannerProps {
  title: string;
  items: LabelValueLinkItem[];
  isEditable?: boolean;
  onEditClick?: () => void;
}

// TODO: use this component to replace the the payment details component as this is more dynamic
export const DynamicLabelValueBanner = ({
  title,
  items,
  isEditable = false,
  onEditClick,
}: DynamicLabelValueBannerProps) => {
  const buttonRef = useRef<typeof MCustomIconButton>(null);

  //check if the data is empty or the only value is null
  if (items.length === 0) {
    return null;
  }

  return (
    <MCard
      width={'100%'}
      p={6}
      borderRadius="lg"
      borderColor={colors.tGray.lightPurple}
    >
      <MFlex
        borderBottom="2px solid"
        borderColor={colors.tWhite.titanWhite}
        py={2}
        justifyContent="space-between"
      >
        <MHeading color="tPurple.dark" fontSize={16} fontWeight={600}>
          {title}
        </MHeading>

        {isEditable && (
          <MCustomIconButton
            ref={buttonRef}
            btnSize={5}
            variant="icon"
            icon={MdEdit}
            iconColor="tPurple.base"
            onClick={onEditClick}
          />
        )}
      </MFlex>

      <MFlex flexWrap="wrap" justifyContent="left" gap={4} pt="4">
        {items.map((item, index) => (
          <KeyValueDetail
            key={index}
            label={item.label}
            value={item.value}
            link={item.link}
          />
        ))}
      </MFlex>
    </MCard>
  );
};
