import { Link } from '@chakra-ui/react';
import { MFlex, MText } from '../Monetize';

interface AppDocsGetMoreHelpProps {
  isAdmin: boolean;
}

export const AppDocsGetMoreHelp = ({ isAdmin }: AppDocsGetMoreHelpProps) => {
  if (isAdmin) {
    return (
      <MFlex alignContent="middle" justifyContent="center">
        <MText>
          If your question isn't answered here,
          <Link
            href={
              'https://monetizenow.atlassian.net/servicedesk/customer/portal/4/group/12/create'
            }
            target="_blank"
            ml={1}
          >
            file a Support Ticket
          </Link>
          .
        </MText>
      </MFlex>
    );
  }

  return (
    <MFlex mt={2} alignContent="middle" justifyContent="center">
      <MText>
        If your question isn't answered here, reach out to your Administrator.
      </MText>
    </MFlex>
  );
};
