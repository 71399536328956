import { Divider, useDisclosure } from '@chakra-ui/react';
import isString from 'lodash/isString';
import { ElementType, Fragment, ReactNode } from 'react';
import { MCustomIconButton, MFlex, MText } from '../../../components/Monetize';
import { MCustomIconButtonProps } from '../../../components/Monetize/MCustomIconButton';
import MPopoverWrapper from '../../../components/Monetize/MPopoverWrapper';

export interface BillGroupPaymentInfoPopoverItem {
  label?: string;
  value?: ReactNode;
  valueOnNewline?: boolean;
  bottomDivider?: boolean;
}

interface BillGroupPaymentInfoPopoverProps {
  iconButtonProps: { icon: ElementType } & Partial<MCustomIconButtonProps>;
  items: BillGroupPaymentInfoPopoverItem[];
}
const BillGroupPaymentInfoPopover = ({
  iconButtonProps,
  items,
}: BillGroupPaymentInfoPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <MPopoverWrapper
      showArrow
      usePortal
      isOpen={isOpen}
      placement="bottom-start"
      onOpen={onOpen}
      onClose={onClose}
      triggerContent={
        <MCustomIconButton
          p={4}
          minH="8"
          variant="icon"
          btnSize={5}
          iconProps={{
            fill: 'tPurple.base',
          }}
          {...iconButtonProps}
        />
      }
      bodyContent={
        <MFlex flexDirection="column" gap={2}>
          {items.map(({ label, value, valueOnNewline, bottomDivider }, i) => (
            <Fragment key={`${i}_${label}`}>
              <MFlex flexDirection={valueOnNewline ? 'column' : 'row'}>
                {label && (
                  <MText size="md" fontWeight={700} pr={2}>
                    {label}:
                  </MText>
                )}
                {isString(value) ? <MText size="md">{value}</MText> : value}
              </MFlex>
              {bottomDivider && <Divider />}
            </Fragment>
          ))}
        </MFlex>
      }
    />
  );
};

export default BillGroupPaymentInfoPopover;
