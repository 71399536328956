import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { QuoteStartDateSourceEnum } from '@monetize/types/app';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  MAccordion,
  MAccordionCustomButton,
  MAlert,
  MBox,
  MButton,
  MFlex,
  MFormField,
  MRadio,
  MRadioGroup,
  MText,
} from '../../../../../components/Monetize';
import { MSettingAccordionItem } from '../../../../../components/Monetize/MSettingAccordionItem';
import { QUOTE_START_DATE_SOURCE_OPTIONS } from '../../../../../constants/quotes';
import { useFlags } from '../../../../../services/launchDarkly';
import {
  IQuoteSettingProcessingSchema,
  QuoteSettingProcessingSchema,
} from '../../../../../types';
import { ContractSettingSection } from './ContractSettingSection';

interface QuoteSettingProcessingProps {
  value: IQuoteSettingProcessingSchema;
  onSubmit: (data: IQuoteSettingProcessingSchema) => Promise<void>;
  isLoading?: boolean;
}

export const QuoteSettingProcessing = ({
  value,
  onSubmit,
  isLoading,
}: QuoteSettingProcessingProps) => {
  const { useDelayedBilling } = useFlags();

  const quoteSettingProcessingForm = useForm<IQuoteSettingProcessingSchema>({
    resolver: zodResolver(QuoteSettingProcessingSchema),
    mode: 'onChange',
    defaultValues: value,
    values: value,
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
  } = quoteSettingProcessingForm;

  useEffect(() => {
    if (value) {
      reset({
        ...value,
      });
    }
  }, [value, reset]);

  const onSave = async (data: IQuoteSettingProcessingSchema) => {
    try {
      await onSubmit({
        ...data,
        defaultStartDateSource: pauseAtAcceptedChangedFromTrueToFalse
          ? QuoteStartDateSourceEnum.ORIGINAL_CONTRACT_START
          : data.defaultStartDateSource,
      });
    } catch (err) {
      handleApiErrorToast(err);
    }
  };
  const pauseAtAcceptedWatch = watch('pauseAtAccepted');

  const pauseAtAcceptedChangedFromTrueToFalse =
    !pauseAtAcceptedWatch && value.pauseAtAccepted;

  return (
    <MAccordion allowMultiple data-testid="quote-setting-processing" w="100%">
      <form onSubmit={handleSubmit(onSave)}>
        <MSettingAccordionItem
          renderAccordionButton={({ isExpanded }: { isExpanded: boolean }) => (
            <MAccordionCustomButton
              isExpanded={isExpanded}
              label="Quote Processing"
            />
          )}
        >
          <MBox mt="4">
            <ContractSettingSection label="Approval Behavior">
              <MFormField
                error={errors?.allowQuoteLevelOverrideOfStartDateSource}
                onClick={(ev) => ev.stopPropagation()}
                w="auto"
              >
                <Controller
                  name="pauseAtAccepted"
                  control={control}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <FormControl
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <FormLabel
                        htmlFor="pauseAtAccepted"
                        fontWeight="400"
                        mb="0"
                      >
                        Quotes automatically move from Accepted to Processed.
                      </FormLabel>
                      <Switch
                        size="md"
                        id="pauseAtAccepted"
                        aria-describedby={`pauseAtAccepted-subheading`}
                        isChecked={!value}
                        onChange={(event) => {
                          onChange(!event.target.checked);
                        }}
                      />
                    </FormControl>
                  )}
                />
              </MFormField>

              {pauseAtAcceptedChangedFromTrueToFalse && (
                <MAlert
                  type="warning"
                  bg="tOrange.input"
                  mt={2}
                  alertDescriptionProps={{ fontSize: 'sm' }}
                >
                  Enabling the feature may disrupt the processing of quotes
                  configured with delayed billing and currently in Accepted
                  status. This will automatically set the default contract start
                  to "Editable until Drafted".
                </MAlert>
              )}
            </ContractSettingSection>

            {useDelayedBilling && (
              <ContractSettingSection label="Start Date">
                <MText mb="2">
                  Select the default contract start behavior:{' '}
                </MText>
                <MFormField
                  error={errors?.defaultStartDateSource}
                  onClick={(ev) => ev.stopPropagation()}
                  w="auto"
                >
                  <Controller
                    name="defaultStartDateSource"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MRadioGroup
                        onChange={(val: string) => onChange(val)}
                        value={value || ''}
                        rowGap={2}
                        isDisabled={!pauseAtAcceptedWatch}
                        display="flex"
                        flexDir="column"
                      >
                        {QUOTE_START_DATE_SOURCE_OPTIONS.map(
                          ({ value, title }) => (
                            <MRadio value={value} key={value} mt="1">
                              {title}
                            </MRadio>
                          ),
                        )}
                      </MRadioGroup>
                    )}
                  />
                </MFormField>

                <MFormField
                  error={errors?.allowQuoteLevelOverrideOfStartDateSource}
                  onClick={(ev) => ev.stopPropagation()}
                  w="auto"
                  mt="6"
                >
                  <Controller
                    name="allowQuoteLevelOverrideOfStartDateSource"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <FormControl
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <FormLabel
                          htmlFor="allowQuoteLevelOverrideOfStartDateSource"
                          fontWeight="400"
                          mb="0"
                        >
                          Allow this setting to be editable on Quotes
                        </FormLabel>
                        <Switch
                          size="md"
                          id="allowQuoteLevelOverrideOfStartDateSource"
                          aria-describedby={`allowQuoteLevelOverrideOfStartDateSource-subheading`}
                          isChecked={!!value}
                          onChange={(event) => {
                            onChange(event.target.checked);
                          }}
                          isDisabled={!pauseAtAcceptedWatch}
                        />
                      </FormControl>
                    )}
                  />
                </MFormField>
              </ContractSettingSection>
            )}

            <MFlex justify="end">
              <MButton
                variant="primary"
                onClick={handleSubmit(onSave)}
                isDisabled={!isDirty || !isValid || isLoading}
                isLoading={isLoading}
              >
                Save
              </MButton>
            </MFlex>
          </MBox>
        </MSettingAccordionItem>
      </form>
    </MAccordion>
  );
};
