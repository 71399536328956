import React, { useEffect, useMemo, useState } from 'react';
import {
  Control,
  useFieldArray,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { MGrid, MGridItem, MText } from '../../../../../components/Monetize';
import { RATE_BILLING_FREQUENCY_MAP } from '../../../../../constants/offerings';
import {
  IBulkQuoteOfferingReqSchema,
  IOfferingRes,
  IRateResBaseSchema,
} from '../../../../../types';
import BulkRatesRow from './BulkRatesRow';

export const BulkRates = ({
  setValue,
  control,
  watch,
  disabled,
}: {
  setValue: UseFormSetValue<IBulkQuoteOfferingReqSchema>;
  control: Control<IBulkQuoteOfferingReqSchema>;
  watch: UseFormWatch<IBulkQuoteOfferingReqSchema>;
  disabled?: boolean;
}) => {
  const [errorMessages, setErrorMessage] = useState<
    Record<string, string> | undefined
  >();

  const offerings = watch('offerings');
  const billingFrequency = watch('billingFrequency');
  const customBillingFrequency = watch('customBillingFrequency');

  const itemListByOfferingId = useMemo(() => {
    return offerings.reduce(
      (
        acc: Record<
          string,
          {
            items: IRateResBaseSchema[];
            isBillingFrequencyMatch: boolean;
          }
        >,
        offering: IOfferingRes,
      ) => {
        const filteredRates = offering.rates.filter(
          (rate: IRateResBaseSchema) =>
            rate.billingFrequencyInMonths === Number(billingFrequency),
        );

        // If no rates match, return the original rates, otherwise return the filtered rates
        acc[offering.id] = {
          items: filteredRates.length > 0 ? filteredRates : offering.rates,
          isBillingFrequencyMatch: filteredRates.length > 0,
        };

        return acc;
      },
      {},
    );
  }, [offerings, billingFrequency]);

  const { fields } = useFieldArray<IBulkQuoteOfferingReqSchema, 'bulkRate'>({
    control,
    name: 'bulkRate',
  });

  const getBillingFrequencyLabel = (value: string): string => {
    switch (value) {
      case '0': {
        const customValue = customBillingFrequency ? customBillingFrequency : 1; // keeping monthly as default
        return RATE_BILLING_FREQUENCY_MAP.CUSTOM(customValue).label;
      }
      case '1':
        return RATE_BILLING_FREQUENCY_MAP.MONTHLY().label;
      case '3':
        return RATE_BILLING_FREQUENCY_MAP.QUARTERLY().label;
      case '6':
        return 'Semi-Annual';
      case '12':
        return 'Annual';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (fields) {
      fields.map((field) => {
        if (field.rateId === '') {
          setErrorMessage((prev) => ({
            ...prev,
            [field.offeringId]: `There is no ${getBillingFrequencyLabel(
              billingFrequency,
            )} rate for this offering. Please select another Rate.`,
          }));
        }
      });
    }
  }, [fields]);

  const handleErrorMessageValidation = (offeringId: string) => {
    if (errorMessages?.[offeringId]) {
      const updatedErrorMessages = { ...errorMessages };
      delete updatedErrorMessages[offeringId];
      setErrorMessage(updatedErrorMessages);
    }
  };

  const handleRemoveOffering = (offeringId: string) => {
    setValue(
      'offerings',
      offerings.filter((offering) => offering.id !== offeringId),
    );
    setValue(
      'bulkRate',
      fields.filter((offering) => offering.offeringId !== offeringId),
    );
  };

  return (
    <React.Fragment>
      <MGrid templateColumns="repeat(12, 1fr)" gap={4} rowGap={4} mb={4}>
        <MGridItem colSpan={6}>
          <MText fontWeight="semibold">Offerings</MText>
        </MGridItem>

        <MGridItem colSpan={6}>
          <MText fontWeight="semibold">Rate</MText>
        </MGridItem>
      </MGrid>
      <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
        {fields.map((field, index) => {
          const moreThanOneRate = Boolean(
            itemListByOfferingId[field.offeringId]?.items?.length > 1,
          );
          return (
            <BulkRatesRow
              fieldsCount={fields.length}
              key={`bulkRate.${field.offeringId}`}
              control={control}
              field={field}
              offerings={offerings}
              errorMessages={errorMessages}
              disabled={disabled}
              index={index}
              itemListByOfferingId={itemListByOfferingId}
              billingFrequency={billingFrequency}
              moreThanOneRate={moreThanOneRate}
              handleErrorMessageValidation={handleErrorMessageValidation}
              getBillingFrequencyLabel={getBillingFrequencyLabel}
              removeOffering={handleRemoveOffering}
            />
          );
        })}
      </MGrid>
    </React.Fragment>
  );
};
