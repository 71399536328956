import { Control, Controller } from 'react-hook-form';
import {
  MCustomSelect,
  MFlex,
  MFormField,
  MText,
} from '../../../components/Monetize';
import {
  BillGroupSummary,
  IAccountRespSchema,
  IBillGroupResp,
  IOneTimeInvoiceReqSchema,
} from '../../../types';
import { ILegalEntityResponseSchema } from '../../../types/legalEntityTypes';

interface OneTimeInvoiceModalContentProps {
  accountDetails: IAccountRespSchema;
  legalEntity?: ILegalEntityResponseSchema;
  control: Control<IOneTimeInvoiceReqSchema>;
  billGroupsLoading: boolean;
  billGroups: IBillGroupResp[] | BillGroupSummary[];
}
export const OneTimeInvoiceModalContent = ({
  accountDetails,
  control,
  legalEntity,
  billGroups,
  billGroupsLoading,
}: OneTimeInvoiceModalContentProps) => {
  return (
    <>
      <MFormField label="Account" mb="4">
        <MText>{accountDetails?.accountName || ''}</MText>
      </MFormField>
      <MFlex gap="2" mb="4">
        <MFormField label="Legal Entity" isRequired>
          <MText>{legalEntity?.name || ''}</MText>
        </MFormField>
        <MFormField label="Currency" isRequired>
          <MText>{accountDetails?.defaultCurrency || ''}</MText>
        </MFormField>
      </MFlex>
      <MFormField label="Bill Group" isRequired>
        <Controller
          name="billGroupId"
          control={control}
          render={({ field }) => (
            <MCustomSelect
              placeholder="Select a Bill Group"
              items={billGroups}
              itemTitle="name"
              itemValue="id"
              loading={billGroupsLoading}
              isDisabled={billGroupsLoading}
              {...field}
            />
          )}
        />
      </MFormField>
    </>
  );
};
