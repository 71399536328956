import {
  AccountHistoryObjectTypeEnum,
  AccountHistoryOperationEnum,
  AccountStatusEnum,
} from '../types';

export const STATUS_OPTIONS = [
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Inactive', value: 'INACTIVE' },
  { name: 'Canceled', value: 'CANCELED' },
  { name: 'Suspended', value: 'SUSPENDED' },
];

export const AccountStatusDisplayText: {
  [key in AccountStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  SUSPENDED: 'Suspended',
  INACTIVE: 'Inactive',
};

export const AccountHistoryObjectTypeDisplayText: {
  [key in AccountHistoryObjectTypeEnum]: string;
} = {
  ACCOUNT: 'Account',
  BILL_GROUP: 'Bill Group',
  CONTACT: 'Contact',
  SUBSCRIPTION: 'Subscription',
  TEAM: 'Team',
};

export const AccountHistoryOperationDisplayText: {
  [key in AccountHistoryOperationEnum]: string;
} = {
  CREATE: 'Created',
  UPDATE: 'Updated',
  DELETE: 'Deleted',
};

export const billGroupDetailsTabs = new Set([
  'bill-groups',
  'payment-methods',
  'contacts',
  'overview',
]);
