import {
  FormControl,
  FormLabel,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../api/axios';
import {
  MBox,
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MInput,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  RadioStyleButtonGroup,
} from '../../../../components/Monetize';
import { CONTRACT_END_ACTION_UI_DISPLAY } from '../../../../constants/contracts';
import { QUOTE_CUSTOM_CONTRACT_LENGTHS } from '../../../../constants/quotes';
import { logger } from '../../../../services/logger';
import {
  ContractEndActionEnum,
  IQuoteContractRenewalTerms,
  QuoteContractRenewalTerms,
  RenewalTermLengthEnum,
} from '../../../../types';

const customContractOptions = QUOTE_CUSTOM_CONTRACT_LENGTHS.map((value) => ({
  label: `${value}`,
  value: `${value}`,
}));

interface QuoteContractRenewalTermsActionProps {
  contractRenewalTerms?: IQuoteContractRenewalTerms;
  defaultContractRenewalTerms?: IQuoteContractRenewalTerms;
  onSubmit: (data: IQuoteContractRenewalTerms) => Promise<void>;
  isReadOnly?: boolean;
}
export const QuoteContractRenewalTermsAction = ({
  contractRenewalTerms,
  defaultContractRenewalTerms,
  onSubmit,
  isReadOnly = false,
}: QuoteContractRenewalTermsActionProps) => {
  const {
    watch,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    reset,
    formState: { errors, isDirty },
  } = useForm<IQuoteContractRenewalTerms>({
    resolver: zodResolver(QuoteContractRenewalTerms),
    mode: 'onChange',
    defaultValues: contractRenewalTerms,
    values: contractRenewalTerms,
    resetOptions: {
      keepDirty: true,
      keepErrors: true,
    },
  });
  const { onOpen, onClose, isOpen } = useDisclosure({
    onOpen: () => {
      reset(contractRenewalTerms);
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const contractEndAction = watch('contractEndAction');
  const renewalTermLengthType = watch('renewalTermLength.type');
  const renewalTermLengthMonths = watch('renewalTermLength.months');

  const onContractRenewalTermsSubmit = async (
    data: IQuoteContractRenewalTerms,
  ) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const onError = (err: any) => logger.error(err);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      arrowSize={20}
      gutter={16}
    >
      <PopoverTrigger>
        <MFormField label="Contract End Action">
          <MButton px="2" variant="tertiary" size="sm" ml="-2">
            {
              CONTRACT_END_ACTION_UI_DISPLAY[
                contractRenewalTerms?.contractEndAction ||
                  ContractEndActionEnum.CANCEL
              ]
            }
          </MButton>
        </MFormField>
      </PopoverTrigger>
      <Portal>
        <PopoverContent p={4} left={-12}>
          <PopoverArrow />

          <MFormField mt="2" mb="4" error={errors.contractEndAction}>
            <Controller
              name="contractEndAction"
              control={control}
              render={({ field: { name, value, onChange, ...rest } }) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  px="2"
                >
                  <FormLabel htmlFor={name} fontWeight="400" mb="0">
                    Auto Renew Contract
                  </FormLabel>

                  <Switch
                    size="md"
                    id={name}
                    aria-describedby={`${name}-subheading`}
                    isChecked={value === ContractEndActionEnum.RENEW}
                    isDisabled={isReadOnly}
                    onChange={(event) => {
                      if (event.target.checked) {
                        onChange(ContractEndActionEnum.RENEW);
                        setValue(
                          'renewalTermLength.type',
                          contractRenewalTerms?.renewalTermLength?.type ||
                            defaultContractRenewalTerms?.renewalTermLength
                              ?.type ||
                            RenewalTermLengthEnum.ContractLength,
                        );
                        setValue(
                          'renewalTermLength.months',
                          contractRenewalTerms?.renewalTermLength?.months ||
                            defaultContractRenewalTerms?.renewalTermLength
                              ?.months ||
                            null,
                        );
                        setValue(
                          'autoRenewalNoticePeriod',
                          contractRenewalTerms?.autoRenewalNoticePeriod ||
                            defaultContractRenewalTerms?.autoRenewalNoticePeriod ||
                            null,
                        );
                      } else {
                        onChange(ContractEndActionEnum.CANCEL);
                        setValue('renewalTermLength', null);
                        setValue('autoRenewalNoticePeriod', null);
                      }
                    }}
                  />
                </FormControl>
              )}
            />
          </MFormField>

          {contractEndAction === ContractEndActionEnum.RENEW && (
            <>
              <MDivider />
              <MBox ml="2.5">
                <MText mt="4" fontSize="md" fontWeight="600">
                  Renewal Configuration
                </MText>

                <MFormField
                  mt="2"
                  error={
                    errors.renewalTermLength?.type ||
                    errors.renewalTermLength?.months
                  }
                >
                  <Controller
                    name="renewalTermLength.type"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MRadioGroup
                        value={value || RenewalTermLengthEnum.ContractLength}
                        onChange={(val) => {
                          onChange(val);
                          if (val === RenewalTermLengthEnum.ContractLength) {
                            setValue('renewalTermLength.months', null, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                            if (errors.renewalTermLength?.months) {
                              clearErrors('renewalTermLength.months');
                            }
                          }
                        }}
                        {...rest}
                      >
                        <MStack>
                          <MRadio
                            isDisabled={isReadOnly}
                            value={RenewalTermLengthEnum.ContractLength}
                            fontWeight="bold"
                          >
                            Same as Prior Contract Length
                          </MRadio>

                          <MRadio
                            isDisabled={isReadOnly}
                            value={RenewalTermLengthEnum.FixedMonths}
                            fontWeight="bold"
                          >
                            Custom Length in Months
                          </MRadio>
                        </MStack>
                      </MRadioGroup>
                    )}
                  />
                  {renewalTermLengthType ===
                    RenewalTermLengthEnum.FixedMonths && (
                    <MBox pl="5">
                      <Controller
                        control={control}
                        name="renewalTermLength.months"
                        render={({ field: { value, ...rest } }) => (
                          <MInput
                            w="10rem"
                            value={value || ''}
                            {...rest}
                            isDisabled={isReadOnly}
                          />
                        )}
                      />

                      <RadioStyleButtonGroup
                        name="renewalTermLengthMonths"
                        containerProps={{ maxW: '160px', mt: 2 }}
                        options={customContractOptions}
                        value={`${renewalTermLengthMonths || ''}` || undefined}
                        defaultValue={
                          `${renewalTermLengthMonths || ''}` || undefined
                        }
                        onChange={(value: string) => {
                          setValue('renewalTermLength.months', +value, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        }}
                      />
                    </MBox>
                  )}
                </MFormField>

                <MFormField
                  mt="4"
                  label="Notice Period (in days)"
                  isRequired
                  labelProps={{
                    display: 'block',
                  }}
                  tooltip="Notice of non-renewal by this number of days before Contract ends"
                  error={errors.autoRenewalNoticePeriod}
                >
                  <Controller
                    control={control}
                    name="autoRenewalNoticePeriod"
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        value={value || ''}
                        {...rest}
                        isDisabled={isReadOnly}
                      />
                    )}
                  />
                </MFormField>
                <MText mt="2" fontWeight="400" color="tGray.acGray">
                  Example: If Contract with auto-renewal ends on July 15th with
                  notice period of 10 days, notice of non-renewal must be
                  received by July 5th
                </MText>
              </MBox>
            </>
          )}
          {!isReadOnly && (
            <>
              <MDivider my="4" />
              <MFlex justify="end">
                <MButton
                  variant="primary"
                  onClick={handleSubmit(onContractRenewalTermsSubmit, onError)}
                  isDisabled={!isDirty || isLoading}
                  isLoading={isLoading}
                >
                  Save
                </MButton>
              </MFlex>
            </>
          )}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
