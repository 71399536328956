import { Progress } from '@chakra-ui/react';
import {
  MBox,
  MButton,
  MCenterModal,
  MPageLoader,
  MSpacer,
  MStack,
  MText,
} from '../../../../../components/Monetize';
import { useCustomFieldsAll } from '../../../../../hooks/useCustomFieldsAll';
import { IAccountRespSchema, IQuoteRequestSchema } from '../../../../../types';
import { isQuestionSubmitDisabled } from './guidedQuote.utils';
import { QuestionForm } from './questionForm/QuestionForm';
import {
  useGuidedQuoteProcess,
  UseGuidedQuoteProcessProps,
} from './useGuidedQuoteProcess';

interface NewGuidedQuoteModalProps
  extends Pick<
    UseGuidedQuoteProcessProps,
    'getInitialQuoteFieldValues' | 'onQuoteCreated' | 'onFinished'
  > {
  account: IAccountRespSchema;
  initialQuoteData?: Partial<IQuoteRequestSchema>;
  onCancel: () => void;
  onManualQuote: () => void;
}

export const NewGuidedQuoteModal = ({
  account,
  initialQuoteData,
  onCancel,
  onManualQuote,
  ...rest
}: NewGuidedQuoteModalProps) => {
  const {
    guidedQuoting,
    // reducer state
    currentQuestion,
    hasNextQuestion,
    hasPreviousQuestion,
    offeringQuestion,
    productQuestion,
    progress,
    // other state
    isLoading,
    context,
    // Actions
    onPreviousQuestion,
    onNextQuestion,
    setContext,
  } = useGuidedQuoteProcess({
    initialQuoteData,
    account,
    ...rest,
  });

  const { data: customFieldsData, isLoading: isCustomFieldLoading } =
    useCustomFieldsAll();

  if (
    !currentQuestion ||
    !guidedQuoting ||
    isCustomFieldLoading ||
    !customFieldsData
  ) {
    return <MPageLoader />;
  }

  return (
    <MCenterModal
      isOpen
      size="xl"
      onClose={onCancel}
      modalTitle="New Guided Quote"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      subTitle={
        <MText fontWeight="normal">
          Creating quote for Account{' '}
          <MBox as="span" fontWeight={500}>
            {account.accountName}
          </MBox>
        </MText>
      }
      renderFooter={() => (
        <MStack spacing={4} direction="row" align="center" flex={1}>
          {currentQuestion && Object.keys(currentQuestion).length > 0 && (
            <MText>
              Want to create the quote{' '}
              {
                <MButton
                  variant="link"
                  size="sm"
                  fontWeight="normal"
                  _hover={{ bg: 'unset' }}
                  onClick={() => onManualQuote()}
                >
                  manually
                </MButton>
              }
              ?
            </MText>
          )}
          <MSpacer />
          {currentQuestion && Object.keys(currentQuestion).length > 0 ? (
            <MButton
              variant="cancel"
              isDisabled={isLoading || !currentQuestion || !hasPreviousQuestion}
              onClick={onPreviousQuestion}
              minW="auto"
            >
              Back
            </MButton>
          ) : (
            <MButton variant="cancel" onClick={onPreviousQuestion} minW="auto">
              Back
            </MButton>
          )}

          <MButton
            variant="primary"
            onClick={onNextQuestion}
            isDisabled={isQuestionSubmitDisabled(
              context,
              currentQuestion,
              offeringQuestion,
            )}
            isLoading={isLoading}
            minW="auto"
          >
            {hasNextQuestion ? 'Next' : 'Create'}
          </MButton>
        </MStack>
      )}
    >
      <Progress
        colorScheme="tIndigo"
        value={progress}
        position="absolute"
        height="4px"
        width="100%"
        top="65px"
        left="0px"
        zIndex={-1}
      />
      <MBox minH="4rem">
        <QuestionForm
          key={currentQuestion.id}
          question={currentQuestion}
          context={context}
          setContext={setContext}
          productQuestion={productQuestion}
          offeringQuestion={offeringQuestion}
          guidedQuoting={guidedQuoting}
          customFieldsData={customFieldsData}
        />
      </MBox>
    </MCenterModal>
  );
};
