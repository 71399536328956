import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import React, { FunctionComponent as FC } from 'react';
import { useConfirmModal } from '../../services/confirmModal';
import { MModalCloseButton, MText } from '../Monetize';

interface MConfirmModalProps {}

const MConfirmModal: FC<MConfirmModalProps> = () => {
  const initialRef = React.useRef(null);
  const { modalData, closeModal, loading } = useConfirmModal();

  if (!modalData) {
    return null;
  }

  const onClose = () => {
    closeModal();
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      blockScrollOnMount
      closeOnOverlayClick
      isCentered
      motionPreset="slideInBottom"
      size="lg"
      variant="primary"
      {...modalData.modalProps}
      isOpen
      onClose={onClose}
      scrollBehavior="inside"
      finalFocusRef={modalData.finalFocusRef ?? initialRef}
    >
      <ModalOverlay style={{ opacity: 0.5 }} />
      <ModalContent data-testid="confirm-modal">
        <ModalHeader fontSize="lg" fontWeight="600" pr="10">
          {modalData.title}
        </ModalHeader>
        {modalData.showCloseButton && <MModalCloseButton />}
        <ModalBody pt="0" pb="4" {...modalData.modalBodyProps}>
          {modalData.description && (
            <MText color="tGray.darkPurple" textAlign="left">
              {modalData.description}
            </MText>
          )}
          {modalData.content && modalData.content}
        </ModalBody>

        <ModalFooter px="0" py="4" mx="4" {...modalData.modalFooterProps}>
          <Stack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            {!!modalData.noButton && (
              <Button
                onClick={modalData.onNo}
                variant="cancel"
                minW="auto"
                isDisabled={loading}
                {...modalData.noBtnProps}
              >
                {modalData.noButton}
              </Button>
            )}
            {!!modalData.yesButton && (
              <Button
                ref={initialRef}
                variant="primary"
                minW="auto"
                {...modalData.yesBtnProps}
                onClick={modalData.onYes}
                isLoading={loading}
                data-testid="modal-confirm-btn"
              >
                {modalData.yesButton}
              </Button>
            )}
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MConfirmModal;
