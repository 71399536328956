import { FC } from 'react';
import { CustomSelectStateProps } from '../../../../types/mCustomSelectTypes';
import { MCustomSelectItem } from './MCustomSelectItem';
import { StandardOptionRender } from './StandardOptionRender';

interface ItemListProps
  extends Pick<
    CustomSelectStateProps,
    | 'isSubtitleItem'
    | 'isDisabledItem'
    | 'isSelectedItem'
    | 'onClose'
    | 'itemRefs'
    | 'getTitle'
    | 'cursor'
    | 'items'
    | 'handleChange'
    | 'renderItemContent'
    | 'setCursorCurrent'
    | 'displayAvatar'
    | 'query'
    | 'useHighlightedItemList'
    | 'showItemDivider'
  > {
  indexOffset: number;
}

export const ItemList: FC<ItemListProps> = ({
  indexOffset,
  isSubtitleItem,
  isDisabledItem,
  isSelectedItem,
  itemRefs,
  getTitle,
  cursor,
  items,
  handleChange,
  renderItemContent,
  query,
  useHighlightedItemList,
  showItemDivider,
}: ItemListProps) => {
  const elements = items.map((item: any, i: number) => {
    const index = indexOffset + i;
    const isSelected = isSelectedItem(item);
    const isSubtitle = isSubtitleItem(item);
    const isDisabled = isDisabledItem(item);
    const isHighlight = cursor === index;

    return (
      <MCustomSelectItem
        key={index}
        isSubtitle={isSubtitle}
        onClick={() => handleChange(item)}
        isHighlight={isHighlight}
        rightLabel={item.rightLabel}
        isSelected={isSelected}
        isDisabled={isDisabled}
        ref={(element: HTMLDivElement) => (itemRefs.current[index] = element)}
        hasDivider={showItemDivider && i !== items.length - 1}
        px={2}
      >
        {renderItemContent ? (
          renderItemContent({
            title: getTitle(item),
            item,
            isSubtitle,
            isSelected,
            isHighlight,
            isDisabled,
            query,
          })
        ) : (
          <StandardOptionRender
            content={getTitle(item)}
            isSubtitle={isSubtitle}
            isSelected={isSelected}
            isHighlight={isHighlight}
            query={query}
            useHighlightedItemList={useHighlightedItemList}
          />
        )}
      </MCustomSelectItem>
    );
  });
  return <>{elements}</>;
};
