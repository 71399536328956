import { FlexProps, FormLabel } from '@chakra-ui/react';
import { FC } from 'react';
import { MBox, MFlex, MText } from '../Monetize';

interface QuoteBasicInfoLineProps extends FlexProps {
  title: string;
  children?: React.ReactElement<any> | string;
  inputId?: string;
  showRequired?: boolean;
  orientation?: 'horizontal' | 'vertical';
  labelExtra?: React.ReactElement;
}

export const QuoteBasicInfoLine: FC<QuoteBasicInfoLineProps> = ({
  title,
  children,
  inputId,
  showRequired,
  orientation = 'horizontal',
  labelExtra,
  ...rest
}: QuoteBasicInfoLineProps) => {
  if (orientation === 'horizontal') {
    return (
      <MFlex
        alignItems="center"
        justifyContent="space-between"
        minHeight="8"
        my="1"
        {...rest}
      >
        <FormLabel
          m="0"
          color="tGray.darkPurple"
          mr="6"
          htmlFor={inputId}
          fontWeight="400"
          sx={{ textWrap: 'nowrap' }}
        >
          {title}
          {showRequired && (
            <MText as="span" color="tRed.base" ml="1">
              *
            </MText>
          )}
        </FormLabel>
        <MBox minWidth={0}>{children}</MBox>
      </MFlex>
    );
  }

  return (
    <MFlex
      alignItems="flex-start"
      justifyContent="space-between"
      flexDir="column"
      {...rest}
    >
      <MFlex alignItems="center">
        <FormLabel
          m="0"
          mr="2"
          color="tPurple.dark"
          htmlFor={inputId}
          fontWeight="600"
          sx={{ textWrap: 'nowrap' }}
        >
          {title}
          {showRequired && (
            <MText as="span" color="tRed.base" ml="1">
              *
            </MText>
          )}
        </FormLabel>
        {labelExtra}
      </MFlex>

      <MFlex minWidth={0} mt="1" minHeight="8" alignItems="center">
        {children}
      </MFlex>
    </MFlex>
  );
};
