import { z } from 'zod';
import { MCustomNumberTypeNullish } from './_app.types';

export enum PriceUpliftConfigTypeEnum {
  APPLY_TO_ALL_PRODUCTS = 'APPLY_TO_ALL_PRODUCTS',
  PRODUCT_SPECIFIC = 'PRODUCT_SPECIFIC',
}

export const PriceUpliftConfigTypeEnumZ = z.nativeEnum(
  PriceUpliftConfigTypeEnum,
);

export enum PriceUpliftPercentageTypeEnum {
  MAX = 'MAX',
  FIXED = 'FIXED',
}

export const PriceUpliftPercentageTypeEnumZ = z.nativeEnum(
  PriceUpliftPercentageTypeEnum,
);

export const PriceUpliftProductConfigurationSchema = z.object({
  amount: z.number(),
  percentageType: PriceUpliftPercentageTypeEnumZ,
  productId: z.string(),
});

export type PriceUpliftProductConfigurationType = z.infer<
  typeof PriceUpliftProductConfigurationSchema
>;

export const PriceUpliftConfigBase = z.object({
  id: z.string(),
  locked: z.boolean(),
  type: PriceUpliftConfigTypeEnumZ,
});

export const PriceUpliftConfigAll = PriceUpliftConfigBase.extend({
  amount: z.number(),
  percentageType: PriceUpliftPercentageTypeEnumZ,
  type: z.literal(PriceUpliftConfigTypeEnum.APPLY_TO_ALL_PRODUCTS),
});

export const PriceUpliftConfigSpecificProducts = PriceUpliftConfigBase.extend({
  type: z.literal(PriceUpliftConfigTypeEnum.PRODUCT_SPECIFIC),
  productConfigurations: PriceUpliftProductConfigurationSchema.array(),
});

export const PriceUpliftConfigResSchema = z.discriminatedUnion('type', [
  PriceUpliftConfigAll,
  PriceUpliftConfigSpecificProducts,
]);

export type PriceUpliftConfigResType = z.infer<
  typeof PriceUpliftConfigResSchema
>;

export const PriceUpliftConfigFormSchema = z.object({
  type: PriceUpliftConfigTypeEnumZ,
  percentageType: PriceUpliftPercentageTypeEnumZ,
  amount: MCustomNumberTypeNullish,
  productConfigurations: z
    .array(
      z.object({
        amount: MCustomNumberTypeNullish.nullish(),
        productId: z.string().nullish(),
        percentageType: PriceUpliftPercentageTypeEnumZ.nullish(),
      }),
    )
    .nullish(),
});

export type PriceUpliftConfigFormType = z.infer<
  typeof PriceUpliftConfigFormSchema
>;
