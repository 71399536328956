import { useLinkOpportunityToCrm } from '../../api/cpqService';
import { QUOTE_SENT_STATUSES } from '../../constants/quotes';
import { useACL } from '../../services/acl/acl';
import { IOpportunityWithQuotes } from '../../types';
import DataTableActions, {
  ActionType,
} from '../Monetize/DataTable/MDataTableActions';

type OpportunityModalActionsProps = {
  opportunity: IOpportunityWithQuotes;
  isDisabled?: boolean;
};

export const OpportunityModalActions = ({
  opportunity,
  isDisabled,
}: OpportunityModalActionsProps) => {
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);
  const { mutateAsync: doLinkOpportunityToCrm } = useLinkOpportunityToCrm();

  const hasProcessedOrSentQuote = !!opportunity.quotes?.some((quote) =>
    QUOTE_SENT_STATUSES.has(quote.status),
  );

  const isUnlinkOppEnabled =
    !isDisabled &&
    canUpdateSales &&
    !!opportunity.customId &&
    !hasProcessedOrSentQuote;

  const actions: ActionType[] = [
    {
      title: 'Unlink Opportunity',
      enabled: isUnlinkOppEnabled,
      action: () =>
        doLinkOpportunityToCrm({
          customId: null,
          opportunityId: opportunity.id,
        }),
    },
  ];
  return <DataTableActions actions={actions} />;
};
