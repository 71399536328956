import { Spinner } from '@chakra-ui/react';
import { Suspense, forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import type { Props as ChartProps } from 'react-apexcharts';
import lazyWithPreload from '../App/LazyLoadRetry';

// eslint-disable-next-line no-restricted-imports
const Chart = lazyWithPreload(() => import('react-apexcharts'));

export const MChart = forwardRef<any, ChartProps>(
  (props: ChartProps, ref: any) => {
    return (
      <Suspense fallback={<Spinner />}>
        <Chart {...props} ref={ref} />
      </Suspense>
    );
  },
);

export default MChart;
