import { useQueryClient } from '@tanstack/react-query';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { handleApiErrorToast } from '../../api/axios';
import { doGetContactById } from '../../api/contactsService';
import { useGetById } from '../../api/queryUtils';
import { AccountAddressSelect } from '../../components/Account/AccountAddressSelect';
import { ContactSelect } from '../../components/Contacts/ContactSelect';
import {
  MButton,
  MCenterModal,
  MFormField,
  MGrid,
  MGridItem,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
  MTooltip,
} from '../../components/Monetize';
import { getUseSelectDataQueryKey } from '../../components/Monetize/MCustomSelect/components/useSelectData';
import { useCustomSelectValue } from '../../components/Monetize/MCustomSelect/useCustomSelectValue';
import { useACL } from '../../services/acl/acl';
import { useFlags } from '../../services/launchDarkly';
import { appGlobalDataState } from '../../store/global.store';
import {
  IBillGroupResp,
  IContactRespSchema,
  IInvoiceAddressDataSchema,
  IInvoiceRespSchema,
  QuoteSettingsDefaultAddressSourceEnum,
} from '../../types';
import { getAddressFromContact } from '../../utils/address';

interface EditContactDetailModalProps {
  isOpen: boolean;
  invoice: IInvoiceRespSchema;
  onClose?: null | ((reload: boolean) => void);
  onContinue: ({
    billingContactId,
    shippingContactId,
    action,
    isSubmit,
  }: {
    billingContactId: string;
    shippingContactId: string;
    action?: 'BILLING' | 'SHIPPING';
    isSubmit?: boolean;
  }) => Promise<void>;
  addressForm: UseFormReturn<IInvoiceAddressDataSchema>;
}

const EditContactDetailModal: FC<EditContactDetailModalProps> = ({
  isOpen,
  invoice,
  onClose,
  onContinue,
  addressForm,
}: EditContactDetailModalProps) => {
  const { canDo } = useACL();
  const canUpdateContact = canDo([['account_contacts', 'update']]);
  const canCreateContact = canDo([['account_contacts', 'create']]);
  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);
  const { enableAccountBasedAddresses } = useFlags();

  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const accountId = invoice.account.id as string;

  const { isLoading: isBillGroupLoading, data: billGroup } =
    useGetById<IBillGroupResp>('billGroups', invoice.billGroupId!, {
      enabled: !!invoice?.billGroupId,
      refetchOnWindowFocus: false,
    });

  const billingContactCustomValue = useCustomSelectValue<IContactRespSchema>({
    value: invoice?.billingContact?.id,
    setValue: () => {},
    getOneById: doGetContactById,
  });
  const shippingContactCustomValue = useCustomSelectValue<IContactRespSchema>({
    value: invoice?.shippingContact?.id,
    setValue: () => {},
    getOneById: doGetContactById,
  });

  useEffect(() => {
    if (billGroup && invoice) {
      addressForm.reset({
        addressSource: billGroup?.addressSource,
        billingAddressId: invoice.billingAddress?.id,
        shippingAddressId: invoice.shippingAddress?.id,
      });
    }
  }, [billGroup, invoice, addressForm]);

  const isValid =
    !!billingContactCustomValue.internalValue ||
    !!shippingContactCustomValue.internalValue;

  const onCloseContactModalHandle = (contact?: IContactRespSchema) => {
    queryClient.invalidateQueries({
      queryKey: getUseSelectDataQueryKey(`/api/accounts/${accountId}/contacts`),
    });
  };

  const handleEdit = async (isActionFor: 'BILLING' | 'SHIPPING') => {
    setIsLoading(true);
    try {
      if (
        !billingContactCustomValue?.internalValue ||
        !shippingContactCustomValue?.internalValue
      ) {
        return;
      }
      await onContinue({
        billingContactId: billingContactCustomValue.internalValue.id,
        shippingContactId: shippingContactCustomValue.internalValue.id,
        action: isActionFor,
      });
    } catch (err) {
      handleApiErrorToast(err);
    }
    setIsLoading(false);
  };

  const saveAddress = async () => {};
  const addressSource = addressForm.watch('addressSource');
  const handleContinue = async () => {
    setIsLoading(true);
    try {
      if (
        !billingContactCustomValue?.internalValue ||
        !shippingContactCustomValue?.internalValue
      ) {
        return;
      }
      await onContinue({
        billingContactId: billingContactCustomValue?.internalValue?.id,
        shippingContactId: shippingContactCustomValue?.internalValue?.id,
        isSubmit: true,
      });
      await saveAddress();
    } catch (err) {
      handleApiErrorToast(err);
    }
    setIsLoading(false);
  };

  return (
    <MCenterModal
      size="lg"
      isOpen={isOpen}
      onClose={() => onClose && onClose(false)}
      modalTitle="Update Invoice Contact"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton
            onClick={() => onClose && onClose(false)}
            variant="cancel"
            minW="auto"
          >
            Cancel
          </MButton>
          <MButton
            variant="primary"
            onClick={handleContinue}
            minW="auto"
            isDisabled={!isValid}
            isLoading={isLoading || isBillGroupLoading}
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <MGrid templateColumns="repeat(12, 1fr)" columnGap={4} rowGap={5}>
        <MGridItem colSpan={12}>
          <MText>
            Saving will update contacts and addresses on this Invoice.
          </MText>
          <MText>Changes do not update other Invoices or the Bill Group.</MText>
        </MGridItem>

        {enableAccountBasedAddresses && (
          <MGridItem colSpan={12}>
            <MFormField
              label="Addresses Sourced From"
              isHorizontal
              labelProps={{ mb: 0 }}
              tooltip="Set source of Shipping and Billing Addresses on this Invoice"
            >
              <Controller
                control={addressForm.control}
                name="addressSource"
                render={({ field: { value, onChange, ...rest } }) => (
                  <MRadioGroup onChange={onChange} value={value} {...rest}>
                    <MStack
                      spacing={4}
                      direction="row"
                      color="tGray.darkPurple"
                    >
                      <MRadio
                        value={QuoteSettingsDefaultAddressSourceEnum.ACCOUNT}
                        isDisabled={!hasCrmConfigured}
                        onChange={() => {}}
                      >
                        <MTooltip
                          label="CRM must be connected to use Account Addresses"
                          placement="bottom-start"
                          shouldWrapChildren
                          isDisabled={hasCrmConfigured}
                        >
                          <MText
                            display="inline-flex"
                            alignItems="center"
                            color="currentColor"
                          >
                            Account Addresses
                          </MText>
                        </MTooltip>
                      </MRadio>
                      <MRadio
                        value={QuoteSettingsDefaultAddressSourceEnum.CONTACT}
                      >
                        Contacts
                      </MRadio>
                    </MStack>
                  </MRadioGroup>
                )}
              />
            </MFormField>
          </MGridItem>
        )}

        <MGridItem colSpan={12}>
          <MFormField
            isRequired
            label="Billing Contact"
            labelRightComponent={
              canUpdateContact &&
              billingContactCustomValue.internalValue && (
                <MButton
                  variant="tertiary"
                  size="xs"
                  mb="2"
                  onClick={() => {
                    handleEdit('BILLING');
                  }}
                >
                  Edit
                </MButton>
              )
            }
          >
            <ContactSelect
              showAddContacts={canCreateContact}
              accountId={accountId}
              loading={billingContactCustomValue.isLoading}
              value={billingContactCustomValue.internalValue}
              returnItem
              onChange={(val) => {
                onCloseContactModalHandle();
                billingContactCustomValue.onInternalValueChange(val as any);
              }}
              clearable={false}
            />
          </MFormField>
        </MGridItem>

        <MGridItem colSpan={12}>
          <MFormField label="Billing Address">
            <Controller
              name="billingAddressId"
              control={addressForm.control}
              render={({ field }) => {
                if (
                  addressSource ===
                  QuoteSettingsDefaultAddressSourceEnum.CONTACT
                ) {
                  return billingContactCustomValue.internalValue ? (
                    <MText>
                      {
                        getAddressFromContact(
                          billingContactCustomValue.internalValue,
                          {
                            addressFormat: invoice.fromCompany.addressFormat,
                          },
                        ).fullAddress
                      }
                    </MText>
                  ) : (
                    <MText fontStyle="italic">
                      Address populates from Billing Contact
                    </MText>
                  );
                } else {
                  return (
                    <AccountAddressSelect
                      {...field}
                      addressFormat={invoice.fromCompany.addressFormat}
                      accountId={accountId}
                    />
                  );
                }
              }}
            />
          </MFormField>
        </MGridItem>

        <MGridItem colSpan={12}>
          <MFormField
            isRequired
            label="Shipping Contact"
            labelRightComponent={
              canUpdateContact &&
              shippingContactCustomValue.internalValue && (
                <MButton
                  variant="tertiary"
                  size="xs"
                  mb="2"
                  onClick={() => {
                    handleEdit('SHIPPING');
                  }}
                >
                  Edit
                </MButton>
              )
            }
          >
            <ContactSelect
              showAddContacts={canCreateContact}
              accountId={accountId}
              loading={shippingContactCustomValue.isLoading}
              value={shippingContactCustomValue.internalValue}
              returnItem
              onChange={(val) => {
                onCloseContactModalHandle();
                shippingContactCustomValue.onInternalValueChange(val as any);
              }}
              clearable={false}
            />
          </MFormField>
        </MGridItem>

        <MGridItem colSpan={12}>
          <MFormField label="Shipping Address">
            <Controller
              name="shippingAddressId"
              control={addressForm.control}
              render={({ field }) => {
                if (
                  addressSource ===
                  QuoteSettingsDefaultAddressSourceEnum.CONTACT
                ) {
                  return shippingContactCustomValue.internalValue ? (
                    <MText>
                      {
                        getAddressFromContact(
                          shippingContactCustomValue.internalValue,
                          {
                            addressFormat: invoice.fromCompany.addressFormat,
                          },
                        ).fullAddress
                      }
                    </MText>
                  ) : (
                    <MText fontStyle="italic">
                      Address populates from Shipping Contact
                    </MText>
                  );
                } else {
                  return (
                    <AccountAddressSelect
                      {...field}
                      addressFormat={invoice.fromCompany.addressFormat}
                      accountId={accountId}
                    />
                  );
                }
              }}
            />
          </MFormField>
        </MGridItem>
      </MGrid>
    </MCenterModal>
  );
};

export { EditContactDetailModal };
