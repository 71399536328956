import { StyleProps, useDisclosure } from '@chakra-ui/react';
import orderBy from 'lodash/orderBy';
import {
  FunctionComponent as FC,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdCircle } from 'react-icons/md';
import {
  useCreateNote,
  useDeleteNote,
  useGetNotes,
  useUpdateNote,
} from '../../api/notesService';
import { useACL } from '../../services/acl/acl';
import {
  INoteReqSchema,
  INoteResSchema,
  NoteMilestone,
  TDataTablePager,
  isMilestone,
} from '../../types';
import {
  MBox,
  MButton,
  MCircularProgress,
  MCustomIconButton,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MDropdownActionItem,
  MFlex,
  MText,
} from '../Monetize';
import { LoadMore } from '../Monetize/LoadMore';
import MEmptyDataPlaceholder from '../Monetize/MEmptyDataPlaceholder';
import { NoteForm } from './NoteForm';
import { NoteList } from './NoteList';

const NEW_NOTE_TEMP_KEY = '~~NEW~~';

interface NotesDrawerProps {
  id: string;
  disabled?: boolean;
  entity: 'accounts' | 'quotes';
  milestones?: NoteMilestone[];
  iconButtonStyles?: StyleProps;
}

const pageSize = 20;

export const NotesDrawerV2: FC<NotesDrawerProps> = ({
  id,
  entity = 'accounts',
  disabled = false,
  milestones,
  iconButtonStyles = {},
}: NotesDrawerProps) => {
  const noteBtnRef = useRef<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [unsavedNotes, setUnsavedNotes] = useState<Map<string, INoteResSchema>>(
    new Map(),
  );
  const [isCreateFormActive, setIsCreateFormActive] = useState(() =>
    unsavedNotes.has(NEW_NOTE_TEMP_KEY),
  );

  const { canDo } = useACL();
  const canAddNewNotes = canDo([['sales', 'update']]);

  const hasUnSavedNotes = unsavedNotes.size > 0;

  const [pager, setPager] = useState<TDataTablePager>({
    first: 0,
    rows: pageSize,
    page: 0,
    sortField: 'createDate',
    sortOrder: -1,
  });

  const [notesBodyRef, setRefToNotesBody] = useState<HTMLDivElement | null>(
    null,
  );
  const notesBodyTopRef = useRef<HTMLDivElement | null>(null);
  const {
    data: notesWithoutMilestones,
    isLoading: fetchLoading,
    isFetching: fetchFetching,
  } = useGetNotes(
    { entityPath: entity, id: id, config: pager },
    { enabled: !!id },
  );

  const notes = useMemo(() => {
    if (notesWithoutMilestones?.content.length && milestones?.length) {
      return orderBy(
        [...notesWithoutMilestones.content, ...milestones],
        [
          (noteOrMilestone) =>
            isMilestone(noteOrMilestone)
              ? noteOrMilestone.date
              : noteOrMilestone.createDate,
        ],
        ['desc'],
      );
    }
    return notesWithoutMilestones?.content || [];
  }, [notesWithoutMilestones, milestones]);

  const { mutateAsync: createNote } = useCreateNote({ entityPath: entity, id });
  const { mutateAsync: updateNote } = useUpdateNote({ entityPath: entity, id });
  const { mutateAsync: deleteNote } = useDeleteNote({ entityPath: entity, id });

  /**
   * Save state of editing notes so that state can be restored if user closes drawer
   */
  const handleNoteModified = useCallback(
    (action: 'ADD' | 'CANCEL', note: INoteResSchema) => {
      setUnsavedNotes((prevNotes) => {
        const newNotes = new Map(prevNotes);
        const mapKey = note.id ? note.id : NEW_NOTE_TEMP_KEY;
        action === 'ADD' ? newNotes.set(mapKey, note) : newNotes.delete(mapKey);
        return newNotes;
      });
    },
    [],
  );

  /**
   * Handle any mutation action
   * Will remove note from unsavedNotes if save is successful
   */
  const handleAction = async (
    action: 'CREATE' | 'UPDATE' | 'DELETE',
    note: INoteReqSchema,
  ) => {
    function removeFromUnsavedNotes(noteId: string) {
      setUnsavedNotes((prevNotes) => {
        const newNotes = new Map(prevNotes);
        newNotes.delete(noteId);
        return newNotes;
      });
    }
    switch (action) {
      case 'CREATE':
        return createNote(note).then((res) => {
          removeFromUnsavedNotes(NEW_NOTE_TEMP_KEY);
          setPager({
            ...pager,
            rows: pager.rows + 1,
          });
          return res;
        });
      case 'UPDATE':
        return updateNote(note).then((res) => {
          removeFromUnsavedNotes(note.id!);
          return res;
        });
      case 'DELETE':
        return deleteNote(note.id!).then((res) => {
          removeFromUnsavedNotes(note.id!);
          return res;
        });
      default:
        throw new Error('Invalid action');
    }
  };

  const handleCreateFormClose = () => {
    setIsCreateFormActive(false);
    if (unsavedNotes.has(NEW_NOTE_TEMP_KEY)) {
      handleNoteModified('CANCEL', unsavedNotes.get(NEW_NOTE_TEMP_KEY)!);
    }
  };

  const onCloseDrawer = () => {
    onClose();
    // If unsaved new note is blank, remove it from unsavedNotes
    if (unsavedNotes.has(NEW_NOTE_TEMP_KEY)) {
      const { subject, note } = unsavedNotes.get(NEW_NOTE_TEMP_KEY)!;
      if (!subject && !note) {
        setIsCreateFormActive(false);
        handleNoteModified('CANCEL', unsavedNotes.get(NEW_NOTE_TEMP_KEY)!);
      }
    }
  };

  const numberOfNotes = notesWithoutMilestones?.totalElements || 0;
  const label = entity === 'accounts' ? 'Account Notes' : 'Quote Notes';

  const scrollToTop = () => {
    notesBodyTopRef?.current && notesBodyTopRef.current.scrollIntoView();
  };

  const getNextPageSize = (): number => {
    let nextPageSize = pageSize;
    if (
      notesWithoutMilestones &&
      notesWithoutMilestones.totalElements -
        notesWithoutMilestones.content.length <
        1.3 * pageSize
    ) {
      nextPageSize = notesWithoutMilestones.totalElements;
    }
    return nextPageSize;
  };

  return (
    <MBox position="relative">
      <MDropdownActionItem
        key={label}
        aria-label={label}
        onClick={onOpen}
        colorScheme="tPurple.base"
        display="flex"
        alignItems="center"
        data-testid={`data-table-actions-Customize Display`}
        role="group"
        justifyContent="space-between"
        w="full"
      >
        <MText color="inherit">Notes</MText>
        {numberOfNotes > 0 && !hasUnSavedNotes && (
          <MFlex
            zIndex={100}
            justifyContent="center"
            alignItems="center"
            width={4}
            height={4}
            borderRadius={10}
            border="1px solid"
            borderColor="tIndigo.base"
          >
            <MText
              fontWeight="bold"
              color="tIndigo.base"
              mb={-0.5}
              fontSize="xs"
            >
              {numberOfNotes}
            </MText>
          </MFlex>
        )}
      </MDropdownActionItem>

      {!isOpen && hasUnSavedNotes && (
        <MFlex
          zIndex={100}
          position="absolute"
          justifyContent="center"
          alignItems="center"
          top="-1.8px"
          right="20px"
          width={3}
          height={3}
          borderRadius={10}
        >
          <MCustomIconButton
            pointerEvents="none"
            bg="none"
            variant="icon"
            boxSize={3}
            aria-label={label}
            icon={MdCircle}
            iconColor="tOrange.tangerine"
            _hover={{
              bg: 'none',
            }}
          />
        </MFlex>
      )}

      <MDrawer
        isOpen={isOpen}
        placement="right"
        onClose={onCloseDrawer}
        finalFocusRef={noteBtnRef}
        size="sm"
        blockScrollOnMount={false}
      >
        <MDrawerOverlay />
        <MDrawerContent>
          {/* This header is larger than most because it includes a button, requires minor adjustment */}
          <MDrawerCloseButton color="tPurple.base" mt={-0.5} />
          <MDrawerHeader py="2.5" pl={4} mr={7}>
            <MFlex justifyContent="space-between" alignItems="center" minH={8}>
              <MText fontSize="inherit" fontWeight="inherit">
                Notes
              </MText>
              {!isCreateFormActive && canAddNewNotes && (
                <MButton
                  variant="secondary"
                  role="button"
                  onClick={() => setIsCreateFormActive(true)}
                >
                  New Note
                </MButton>
              )}
            </MFlex>
          </MDrawerHeader>
          <MDrawerBody
            ref={setRefToNotesBody}
            px="2"
            bgColor="tGray.sidebarDark"
          >
            <MBox ref={notesBodyTopRef} height={1} />
            {fetchLoading && !notes.length && (
              <MFlex w="full" h="full" justify="center" alignItems="center">
                <MCircularProgress isIndeterminate size={6} />
              </MFlex>
            )}
            {!notes.length && !isCreateFormActive && (
              <MFlex flexDirection="column" pt={2} pb={4} bgColor="tWhite.base">
                <MEmptyDataPlaceholder
                  mainMessage="Looks like there are no notes here."
                  boxProps={{ height: '10vh' }}
                  mainMessageProps={{
                    fontSize: 'md',
                    fontWeight: 'semibold',
                  }}
                />
              </MFlex>
            )}
            {isCreateFormActive && (
              <NoteForm
                onClose={handleCreateFormClose}
                createNote={(note) => handleAction('CREATE', note)}
                updateNote={(note) => handleAction('UPDATE', note)}
                handleNoteModified={handleNoteModified}
                initialNote={unsavedNotes.get(NEW_NOTE_TEMP_KEY)}
              />
            )}

            {!!notes.length && (
              <>
                <NoteList
                  notes={notes}
                  milestones={milestones}
                  handleAction={handleAction}
                  unsavedNotes={unsavedNotes}
                  handleNoteModified={handleNoteModified}
                  isReadOnly={!canAddNewNotes}
                />
                {notesWithoutMilestones?.content.length && (
                  <LoadMore
                    fetchedElementLength={notesWithoutMilestones.content.length}
                    totalElements={notesWithoutMilestones.totalElements}
                    isLoading={
                      fetchFetching && !!notesWithoutMilestones.content.length
                    }
                    containerElement={notesBodyRef}
                    scrollToTopElement={notesBodyTopRef.current}
                    onLoadMore={() => {
                      setPager({
                        ...pager,
                        rows: pager.rows + getNextPageSize(),
                      });
                    }}
                  />
                )}
              </>
            )}
          </MDrawerBody>

          <MDivider />

          <MDrawerFooter>
            <MBox w="full">
              <MFlex justifyContent="center">
                <MButton
                  mt={5}
                  variant="cancel"
                  justifySelf="center"
                  onClick={onCloseDrawer}
                >
                  Close
                </MButton>
              </MFlex>
            </MBox>
          </MDrawerFooter>
        </MDrawerContent>
      </MDrawer>
    </MBox>
  );
};
