import { z } from 'zod';
import { logger } from '../services/logger';
import {
  IGetBrowserStorageProps,
  ISaveToBrowserStorageProps,
  PersistKeysEnum,
  QuoteLocalPreferences,
  SchemaValidators,
  StorageOptions,
  StorageType,
} from './store.types';

/**
 * Get value from localstorage and validate the shape of data is correct
 * @param persistKey
 * @param schemaValidator
 * @param defaultValue
 * @returns
 */
export function getFromBrowserStorage<T extends SchemaValidators, TDefault>({
  persistKey,
  schemaValidator,
  defaultValue = '',
  type = StorageOptions.LOCAL,
}: IGetBrowserStorageProps<T, TDefault>): z.infer<T> | TDefault {
  try {
    if (type === StorageOptions.ALL) {
      const sessionItem = getFromBrowserStorage({
        persistKey,
        schemaValidator,
        defaultValue,
        type: StorageOptions.SESSION,
      });

      if (sessionItem) {
        return sessionItem;
      }

      const localItem = getFromBrowserStorage({
        persistKey,
        schemaValidator,
        defaultValue,
        type: StorageOptions.LOCAL,
      });

      return localItem;
    }

    const storage =
      type === StorageOptions.LOCAL ? localStorage : sessionStorage;

    const storageItem = storage.getItem(persistKey);
    if (!storageItem) {
      return defaultValue;
    }
    return schemaValidator.parse(JSON.parse(storageItem));
  } catch (ex) {
    logger.warn(`Could not parse ${persistKey} from ${type} storage`, ex);
  }
  return defaultValue;
}

/**
 *
 * @param persistKey
 * @param schemaValidator
 * @param value
 * @param transformFn Function to modify data after validated but before being saved to localStorage
 */
export function saveToBrowserStorage<T extends SchemaValidators>({
  persistKey,
  schemaValidator,
  value,
  type = StorageOptions.LOCAL,
  transformFn,
}: ISaveToBrowserStorageProps<T>): void {
  try {
    const storage =
      type === StorageOptions.LOCAL ? localStorage : sessionStorage;

    let parsedValue = schemaValidator.parse(value);
    if (transformFn) {
      parsedValue = transformFn(parsedValue);
    }
    storage.setItem(persistKey, JSON.stringify(parsedValue));

    if (type === StorageOptions.ALL) {
      saveToBrowserStorage({
        persistKey,
        schemaValidator,
        value,
        type: StorageOptions.LOCAL,
        transformFn,
      });
    }
  } catch (ex) {
    logger.warn(`Could not save ${persistKey} to ${type} storage`);
  }
}

export function removeFalseValuesFromAdvancedQuoteOptions(
  value: QuoteLocalPreferences,
) {
  try {
    return Object.keys(value)
      .filter((key) => value[key].advanced)
      .reduce((acc: QuoteLocalPreferences, key) => {
        acc[key] = value[key];
        return acc;
      }, {});
  } catch (ex) {
    logger.warn('Failed to remove false values from advanced quote options');
  }
  return value;
}

export function clearBrowserStorage({
  persistKey,
  type = StorageOptions.LOCAL,
}: {
  persistKey: PersistKeysEnum;
  type?: StorageType;
}) {
  try {
    const storage =
      type === StorageOptions.LOCAL ? localStorage : sessionStorage;
    storage.removeItem(persistKey);
  } catch (ex) {
    logger.warn(`Could not remove ${persistKey} from ${type} storage`);
  }
}

/**
 * Clear all local stroage but some exceptions like redirect information
 */
export function clearLocalStorage() {
  const tempRediectInfo = localStorage.getItem(PersistKeysEnum.REDIRECT_INFO);
  localStorage.clear();
  tempRediectInfo &&
    localStorage.setItem(PersistKeysEnum.REDIRECT_INFO, tempRediectInfo);
}
