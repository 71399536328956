import { StackProps } from '@chakra-ui/react';
import { FC } from 'react';

import { MdLock } from 'react-icons/md';
import { SETTING_CARD_LOGOS } from '../../constants/settings';
import { SettingCardType } from '../../types';
import { MIcon } from '../Monetize';
import { ButtonCard } from '../Monetize/Cards/ButtonCard';
interface SettingCardEmptyProps extends StackProps {
  type: SettingCardType;
  /** Disable and show lock icon */
  isLocked?: boolean;
  /** Disable without lock icon */
  isDisabled?: boolean;
  onClick?: () => void;
}

export const SettingCardEmpty: FC<SettingCardEmptyProps> = ({
  type,
  isLocked,
  isDisabled,
  onClick,
  ...rest
}) => {
  const Logo = SETTING_CARD_LOGOS[type];
  return (
    <ButtonCard
      h="82px"
      cursor={isDisabled || isLocked ? 'not-allowed' : 'pointer'}
      titleLeftContent={<Logo />}
      title={type}
      description={`Connect to ${type}`}
      isDisabled={isDisabled || isLocked}
      titleRightContent={
        isLocked ? (
          <MIcon as={MdLock} w={3} h={3} mt={1} color="tPurple.dark" />
        ) : undefined
      }
      width={isLocked ? '100%' : undefined}
      onClick={() => {
        !isDisabled && !isLocked && onClick && onClick();
      }}
    />
  );
};
