import { BoxProps } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { MBox, MCard, MGrid, MGridItem } from '../../../../components/Monetize';
import { SalesCreatedByFilter } from '../../../../types';
import { salesFilterValuesState } from '../../Dashboard';
import { useDashboardCurrencyFilter } from '../useDashboardCurrencyFilter';
import { ProcessedQuotesChart } from './ProcessedQuotesChart/ProcessedQuotesChart';
import { QuotesChart } from './QuotesChart/QuotesChart';
import { TotalSection } from './TotalSection';
import { UpcomingRenewalsChart } from './UpcomingRenewalsChart';

const CardBox = ({
  children,
  ...rest
}: { children: React.ReactNode } & BoxProps) => (
  <MCard p="6" variant="edit" {...rest}>
    {children}
  </MCard>
);

interface SalesDashboardProps {
  createdByFilter: SalesCreatedByFilter;
}

const SalesDashboard = ({ createdByFilter }: SalesDashboardProps) => {
  const { currency } = useDashboardCurrencyFilter();
  const [salesFilterValues, setSalesFilterValues] = useRecoilState(
    salesFilterValuesState,
  );
  const { currentQuarter } = salesFilterValues;
  const setCurrentQuarter = (quarter: string) => {
    setSalesFilterValues({ ...salesFilterValues, currentQuarter: quarter });
  };

  return (
    <MBox mt="6">
      <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
        <MGridItem colSpan={12}>
          <CardBox>
            <TotalSection
              key={currency}
              currentQuarter={currentQuarter}
              setCurrentQuarter={setCurrentQuarter}
              createdByFilter={createdByFilter}
              currency={currency}
            />
          </CardBox>
        </MGridItem>
        <MGridItem colSpan={12}>
          <CardBox>
            <UpcomingRenewalsChart
              key={currency}
              createdByFilter={createdByFilter}
              currency={currency}
            />
          </CardBox>
        </MGridItem>
        <MGridItem colSpan={6}>
          <CardBox>
            <QuotesChart
              createdByFilter={createdByFilter}
              currency={currency}
            />
          </CardBox>
        </MGridItem>
        <MGridItem colSpan={6}>
          <CardBox>
            <ProcessedQuotesChart
              key={currency}
              createdByFilter={createdByFilter}
              currency={currency}
            />
          </CardBox>
        </MGridItem>
      </MGrid>
    </MBox>
  );
};

export { SalesDashboard };
