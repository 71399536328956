import {
  ContractEndActionEnum,
  ContractStatusEnum,
  FilterTypeOperator,
} from '../types';
import { objectToObjArray } from '../utils/misc';
import { NEW_CONTRACT_SLUG } from './quotes';

export const CONTRACT_STATUS_DISPLAY: {
  [key in ContractStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  FINISHED: 'Finished',
};

export const CONTRACT_END_ACTION_DISPLAY: {
  [key in ContractEndActionEnum]: string;
} = {
  RENEW: 'Auto Renew',
  MANUAL_RENEW: 'Renewal',
  EARLY_RENEWAL: 'Early Renewal',
  CANCEL: 'Cancel',
};

export const CONTRACT_END_ACTION_UI_DISPLAY: {
  [key in ContractEndActionEnum]: string;
} = {
  RENEW: 'Auto Renew',
  MANUAL_RENEW: 'Renewal',
  EARLY_RENEWAL: 'Early Renewal',
  CANCEL: 'No Auto Renew',
};

export const CONTRACT_STATUS_FILTER_ITEMS = objectToObjArray(
  CONTRACT_STATUS_DISPLAY,
  'value',
  'label',
);

export const CONTRACT_STATUS_FILTER_OPTION = {
  title: 'Status',
  key: 'status',
  operator: FilterTypeOperator.IN,
  items: CONTRACT_STATUS_FILTER_ITEMS,
};

export const NEW_CONTRACT_OPTION = {
  contractName: '+ New Contract',
  id: NEW_CONTRACT_SLUG,
};

export const CONTRACT_END_ACTION_FILTER_OPTION = {
  title: 'End Action',
  key: 'endAction',
  operator: FilterTypeOperator.IN,
  items: objectToObjArray(
    CONTRACT_END_ACTION_UI_DISPLAY,
    'value',
    'label',
  ).filter((item) => item.value !== 'MANUAL_RENEW'),
};

export const CONTRACT_STATUS_AMEND_ALLOWED = new Set([
  ContractStatusEnum.ACTIVE,
  ContractStatusEnum.PENDING,
  ContractStatusEnum.CANCELED,
]);

export const CONTRACT_STATUS_RENEWAL_ALLOWED = new Set([
  ContractStatusEnum.ACTIVE,
  ContractStatusEnum.FINISHED,
]);

export const CONTRACT_STATUS_CANCEL_ALLOWED = new Set([
  ContractStatusEnum.ACTIVE,
  ContractStatusEnum.PENDING,
]);
