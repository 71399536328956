import { v4 as uuidv4 } from 'uuid';
import {
  CustomerCCEnum,
  DunningBillGroupStatusEnum,
  DunningInvoiceActionEnum,
  DunningStatusEnum,
  DunningSubscriptionStatusEnum,
  IDunningStepCreateSchema,
} from '../types';
import { objectToSelectOptions } from '../utils/misc';

export const DUNNING_STATUS_DISPLAY: {
  [key in DunningStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
};

export const DUNNING_STATUS_FILTER = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Canceled', value: 'CANCELED' },
];

export const DUNNING_INVOICE_ACTION_DISPLAY: {
  [key in DunningInvoiceActionEnum]: string;
} = {
  VOID: 'Void',
  WRITE_OFF: 'Write off',
};

export const DUNNING_BILL_GROUP_STATUS_DISPLAY: {
  [key in DunningBillGroupStatusEnum]: string;
} = {
  NO_CHANGE: 'No Change',
  SUSPENDED: 'Suspended',
  CANCELED: 'Canceled',
};

export const DUNNING_BILL_GROUP_STATUS_OPTIONS = objectToSelectOptions(
  DUNNING_BILL_GROUP_STATUS_DISPLAY,
);

export const DUNNING_SUBSCRIPTION_STATUS_DISPLAY: {
  [key in DunningSubscriptionStatusEnum]: string;
} = {
  NO_CHANGE: 'No Change',
  SUSPENDED: 'Suspended',
  CANCELED: 'Canceled',
};

export const DUNNING_SUBSCRIPTION_STATUS_OPTIONS = objectToSelectOptions(
  DUNNING_SUBSCRIPTION_STATUS_DISPLAY,
);

export const CUSTOMER_CC_DISPLAY: {
  [key in CustomerCCEnum]: string;
} = {
  emailBillGroupCCEmails: 'All CC emails on Bill Group',
  emailBillGroupShippingContact: 'Bill Group Shipping Contact',
  emailAccountCCEmails: 'All CC emails on Account',
};

export const CUSTOMER_CC_OPTIONS = objectToSelectOptions(CUSTOMER_CC_DISPLAY);

// Default steps form values
export const defaultStepValue: IDunningStepCreateSchema = {
  id: uuidv4(),
  stepName: '',
  stepSequence: 1,
  retryCollection: false,
  sendEmail: false,
  daysSinceDueDate: 0,
  emailTemplateId: null,
  customId: null,
  emailBillGroupCCEmails: null,
  emailBillGroupShippingContact: null,
  emailAccountCCEmails: null,
  internalCCEmails: null,
};

/** Affinity Template Ids */
export const affinityEmailTemplateOptions = [
  {
    title: 'Affinity Reminder Template',
    value: 'd-8172169478fc46df871468bacabe2284',
  },
  {
    title: 'Affinity 0-21 Day Template',
    value: 'd-e7a983bd771343ada7429b7c6a695e8c',
  },
  {
    title: 'Affinity 30-45 Day Template',
    value: 'd-f71639b166174aefb8f7143f845ce8a6',
  },
  {
    title: 'Affinity 45 Days Past Due Template',
    value: 'd-0ae393bc247a44a29528d33bcd661688',
  },
];
