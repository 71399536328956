import { FC } from 'react';
import { MButton, MFlex, MSearchInput, MText } from './';

interface MSearchInputWithClearProps {
  heading?: string;
  placeholder?: string;
  value: string;
  selectedItemCount: number;
  visibleItemCount: number;
  onChange: (value: string) => void;
  onClearAll: () => void;
}

/**
 * This renders a search input with the number of selected items and a clear all button above the search input.
 * This can be used as a custom renderer for MCustomMultiSelect as a renderer for a prepended item.
 */
export const MSearchInputWithClear: FC<MSearchInputWithClearProps> = (
  props: MSearchInputWithClearProps,
) => {
  const {
    heading,
    value,
    placeholder = 'Search',
    selectedItemCount,
    visibleItemCount,
    onChange,
    onClearAll,
  } = props;

  return (
    <MFlex flexDir="column" w="full">
      {heading && (
        <MText color="tGray.darkPurple" isTruncated noOfLines={1} fontSize="sm">
          {heading}
        </MText>
      )}
      <MFlex justifyContent="space-between">
        <MText fontStyle="italic" fontSize="xs">
          ({selectedItemCount} selected)
        </MText>
        <MButton
          variant="tertiary"
          size="xs"
          role="button"
          onClick={onClearAll}
        >
          Clear All
        </MButton>
      </MFlex>
      <MSearchInput
        count={visibleItemCount}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </MFlex>
  );
};
