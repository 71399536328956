import { useEffect, useState } from 'react';
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import { MdAccountBalance } from 'react-icons/md';
import { useGetById } from '../../api/queryUtils';
import { CREDIT_CARD_TYPE_DISPLAY } from '../../constants/paymentMethods';
import {
  IPayment,
  IPaymentMethodResp,
  PaymentMethodTypeEnum,
} from '../../types';
import { MGridItem, MIcon, MSkeleton, MText } from '../Monetize';

interface ElectronicRefundDetailProps {
  payment: IPayment;
}

export const ElectronicRefundDetail = ({
  payment,
}: ElectronicRefundDetailProps) => {
  const [paymentGatewayDescription, setPaymentGatewayDescription] =
    useState<string>();
  const [electricMethodLastFour, setElectricMethodLastFour] =
    useState<string>();

  const { data: paymentMethod, isLoading: isPaymentMethodLoading } =
    useGetById<IPaymentMethodResp>(
      'accountPaymentMethods',
      payment.paymentMethodId!,
      {
        enabled: !!payment.paymentMethodId,
      },
    );
  useEffect(() => {
    if (paymentMethod) {
      const { lastFour, paymentMethodType, creditCardType } = paymentMethod;
      setElectricMethodLastFour(lastFour ?? '');
      setPaymentGatewayDescription(() => {
        switch (paymentMethodType) {
          case PaymentMethodTypeEnum.CREDIT_CARD:
            return CREDIT_CARD_TYPE_DISPLAY[creditCardType];
          case PaymentMethodTypeEnum.DIRECT_DEBIT:
          default:
            return 'BANK';
        }
      });
    }
  }, [paymentMethod]);

  if (isPaymentMethodLoading) {
    return (
      <MGridItem colSpan={12}>
        <MSkeleton height="20px" />
      </MGridItem>
    );
  }

  return (
    <MGridItem
      colSpan={12}
      alignContent={'center'}
      display={'flex'}
      lineHeight={2}
      gap={2}
    >
      <MIcon
        width={6}
        height={6}
        as={
          payment.paymentType === PaymentMethodTypeEnum.CREDIT_CARD
            ? BsFillCreditCard2FrontFill
            : MdAccountBalance
        }
      />
      <MText>
        {paymentGatewayDescription} - {electricMethodLastFour}
      </MText>
    </MGridItem>
  );
};
