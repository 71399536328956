import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { doGetAccountingAuthorizeRedirectUrl } from '../../../../../api/settingsService';
import {
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MHStack,
  MRadio,
  MRadioGroup,
  MStack,
} from '../../../../../components/Monetize';
import {
  IQuickBook,
  IQuickBookSchema,
  QuickBookEnvEnum,
  QuickBookSchema,
} from '../../../../../types/accountingTypes';

interface QuickBooksFormProps {
  connection?: IQuickBook | null;
  onClose: () => void;
}

export const QuickBooksForm = ({ onClose }: QuickBooksFormProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IQuickBookSchema>({
    resolver: zodResolver(QuickBookSchema),
    mode: 'onChange',
    defaultValues: {
      environment: QuickBookEnvEnum.SANDBOX,
    },
  });

  const onSubmitHandler = async (data: IQuickBookSchema) => {
    setIsLoading(true);
    const { url } = await doGetAccountingAuthorizeRedirectUrl(
      'quickbooks',
      data.environment,
    );
    window.location.href = url;
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} style={{ width: '100%' }}>
      <MFlex flexDirection="column">
        <MFormField error={errors.environment} label="Environment" isRequired>
          <Controller
            name="environment"
            control={control}
            render={({ field }) => (
              <MRadioGroup {...field}>
                <MStack direction="row" spacing={3}>
                  <MRadio
                    key={QuickBookEnvEnum.SANDBOX}
                    value={QuickBookEnvEnum.SANDBOX}
                  >
                    Sandbox
                  </MRadio>
                  <MRadio
                    key={QuickBookEnvEnum.PRODUCTION}
                    value={QuickBookEnvEnum.PRODUCTION}
                  >
                    Production
                  </MRadio>
                </MStack>
              </MRadioGroup>
            )}
          />
        </MFormField>
        <MDivider my={4} />
        <MHStack spacing="4" justify="end" align="center">
          <MButton variant="cancel" onClick={onClose}>
            Cancel
          </MButton>
          <MButton
            type="submit"
            variant="primary"
            onClick={handleSubmit(onSubmitHandler)}
            isLoading={isLoading}
          >
            Connect
          </MButton>
        </MHStack>
      </MFlex>
    </form>
  );
};
