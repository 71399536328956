import { useQuery } from '@tanstack/react-query';
import { logger } from '../../services/logger';
import { ApiResponseV2, SearchResultV2 } from './AppSupport.types';

const README_SEARCH_URL = 'https://docs-search.monetizeplatform.com/search';

export const useDocsSearch = (isOpen: boolean, query: string) => {
  return useQuery({
    queryKey: ['app', 'docs', query],
    queryFn: async () => {
      if (!query) {
        return [] as SearchResultV2[];
      }
      const url = new URL(README_SEARCH_URL);
      url.searchParams.append('search', query);

      const response = await fetch(url.toString());

      if (!response.ok) {
        logger.error(await response.text());
        throw new Error('Failed to fetch search results');
      }

      return response.json().then(({ data }: ApiResponseV2) => data);
    },
    enabled: isOpen,
    refetchOnWindowFocus: false,
    placeholderData: (previousData, previousQuery) => previousData,
  });
};
