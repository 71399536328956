import { format as formatDate } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import { useGetRevenueList } from '../../../../api/dashboardsService';
import {
  MBox,
  MChart,
  MSkeleton,
  MText,
} from '../../../../components/Monetize';
import { revenueChartOptions } from './chartOptions';

interface RevenueChartProps {
  currency: string;
}

export const RevenueChart = ({ currency }: RevenueChartProps) => {
  const { isLoading, data: revenue } = useGetRevenueList(
    { currency },
    {
      select: (data) => ({
        series: [
          {
            name: 'revenue-series-1',
            data: data.map(({ yearMonth, amount }: any) => ({
              x: formatDate(parseISO(yearMonth), 'MMM'),
              y: amount,
            })),
          },
        ],
      }),
    },
  );

  const chartOptions = revenueChartOptions(currency);

  return (
    <MBox>
      <MText fontSize="lg" fontWeight="bold">
        Revenue
      </MText>
      {isLoading ? (
        <MSkeleton height={300} />
      ) : (
        <MChart
          key={currency}
          options={chartOptions}
          series={revenue?.series || []}
          height={300}
        />
      )}
    </MBox>
  );
};
