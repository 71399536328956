import { useDisclosure } from '@chakra-ui/react';
import { toDateShort } from '@monetize/utils/core';
import { MdPieChartOutline } from 'react-icons/md';
import {
  MCustomIconButton,
  MFlex,
  MGrid,
  MGridItem,
  MHeading,
  MText,
  MTooltip,
} from '../../../../../components/Monetize';
import { RATE_BILLING_FREQUENCY_DISPLAY } from '../../../../../constants/offerings';
import {
  IGetSubscriptionItemSchema,
  IGetSubscriptionSchema,
  IRateResSchema,
} from '../../../../../types';
import { UsageOverviewChartDrawer } from '../../Usage/UsageOverviewChartDrawer';
import { UsageDetailsItem } from './UsageDetailsItem';

interface UsageDetailsProps {
  accountId: string;
  rate?: IRateResSchema;
  subscription: IGetSubscriptionSchema;
  usageSubscriptionItems: IGetSubscriptionItemSchema[];
}

export const UsageDetails = ({
  accountId,
  rate,
  subscription,
  usageSubscriptionItems,
}: UsageDetailsProps) => {
  const {
    isOpen: isUsageChartsOpen,
    onOpen: onOpenUsageCharts,
    onClose: onCloseUsageCharts,
  } = useDisclosure();

  return (
    <>
      {/* Ensure that extra usage API calls are not made unless the user opens drawer */}
      {isUsageChartsOpen && (
        <UsageOverviewChartDrawer
          open
          onClose={onCloseUsageCharts}
          accountId={accountId as string}
          subscriptionId={subscription.id}
          subscription={subscription}
        />
      )}
      <MFlex flexDir="column" alignItems="start" mt={8} mb={4}>
        <MFlex justifyContent="space-between" w="100%">
          <MHeading fontSize="18">Usage</MHeading>
          <MTooltip
            label="View Usage Details"
            placement="bottom-start"
            closeOnMouseDown
          >
            <MCustomIconButton
              data-testid="view-usage-button"
              background="none"
              btnSize={6}
              p={4}
              mb="-10px" // Ensure that button does not increase size of row and impact the subtitle
              onClick={onOpenUsageCharts}
              icon={MdPieChartOutline}
            />
          </MTooltip>
        </MFlex>
        <MFlex alignItems="center" mt={0}>
          <MText color="tGray.darkPurple" mr={4}>
            Frequency:{' '}
            {rate
              ? RATE_BILLING_FREQUENCY_DISPLAY[rate.usageBillingFrequency!]
              : ''}{' '}
            | Period: {toDateShort(subscription?.usagePeriodStart || '')}{' '}
            &mdash; {toDateShort(subscription?.usagePeriodEnd || '')}
          </MText>
        </MFlex>

        <MGrid
          w="100%"
          mt={4}
          templateColumns={'2fr 4fr 1.1fr 1.2fr'}
          columnGap={8}
        >
          <MGridItem py={2} colSpan={2}>
            <MText fontSize="16px" pl={2} fontWeight="bold">
              Product
            </MText>
          </MGridItem>
          <MGridItem py={2} mr={8}>
            <MText fontSize="16px" fontWeight="bold" textAlign="right">
              Units
            </MText>
          </MGridItem>
          <MGridItem py={2}>
            <MText fontSize="16px" fontWeight="bold" textAlign="left">
              Type
            </MText>
          </MGridItem>
        </MGrid>
        {usageSubscriptionItems.map((subscriptionItem) => (
          <UsageDetailsItem
            key={subscriptionItem.id}
            accountId={accountId}
            subscription={subscription}
            subscriptionItem={subscriptionItem}
          />
        ))}
      </MFlex>
    </>
  );
};
