import { FC } from 'react';
import { useGetInvoiceSettings } from '../../api/settingsService';
import { useLegalEntityLogo } from '../../hooks/useLegalEntityLogo';
import {
  IInvoiceRespSchema,
  InvoiceStatusEnum,
  UnpaidInvoiceShareLink,
} from '../../types';
import InvoiceDisplay from './InvoiceDisplay';

interface InvoiceDisplayProps {
  invoice?: IInvoiceRespSchema | null;
  loadingInvoice: boolean;
  isInReview?: boolean;
  handleOpenEditContactModal?: () => void;
  unpaidInvoices?: UnpaidInvoiceShareLink[];
}

export const InvoiceContent: FC<InvoiceDisplayProps> = ({
  invoice,
  loadingInvoice,
  isInReview,
  handleOpenEditContactModal,
  unpaidInvoices,
}: InvoiceDisplayProps) => {
  const { data: invoiceSettings } = useGetInvoiceSettings();
  const legalEntityId = invoice?.fromCompany.legalEntityId;

  const { isLoading, base64 } = useLegalEntityLogo({
    id: legalEntityId as string,
  });

  const canPayInvoice =
    invoice?.status === InvoiceStatusEnum.UNPAID &&
    !invoice?.paid &&
    invoice.amount > 0;

  return (
    <InvoiceDisplay
      invoice={invoice}
      unpaidInvoices={unpaidInvoices}
      loadingInvoice={loadingInvoice}
      invoiceSettings={invoiceSettings}
      isInReview={isInReview}
      canPayInvoice={canPayInvoice}
      companyLogoData={{ data: base64, isLoading }}
      handleOpenEditContactModal={handleOpenEditContactModal}
    />
  );
};
