import { getColor } from '@chakra-ui/theme-tools';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import { FunctionComponent as FC, useMemo } from 'react';
import theme from '../../../styles/theme';

interface StripeWrapperProps {
  apiKey: string;
  children: any;
  clientSecret: string;
}

export const StripeWrapper: FC<StripeWrapperProps> = ({
  apiKey,
  children,
  clientSecret,
}: StripeWrapperProps) => {
  const stripePromise = useMemo(() => {
    return loadStripe(apiKey);
  }, [apiKey]);

  const appearance: Appearance = {
    variables: {
      colorPrimary: getColor(theme, 'tIndigo.base'),
      // colorBackground: 'red',
      colorText: getColor(theme, 'tPurple.base'),
      colorDanger: getColor(theme, 'tRed.base'),
      fontFamily: `"Roboto", sans-serif`,
      spacingUnit: '2px',
      borderRadius: '2px',
    },
    rules: {
      '.Label': {
        fontWeight: '600',
        marginBottom: '8px',
        marginTop: '16px',
      },
      '.Input': {
        borderColor: getColor(theme, 'tGray.lightPurple'),
      },
      '.Input--invalid': {
        borderWidth: '1px',
      },
      '.Error': {
        marginTop: '4px',
      },
    },
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance,
      }}
    >
      {children}
    </Elements>
  );
};
