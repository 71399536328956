import { QuoteBodyAccordion } from '../../../../components/Quotes/QuoteBodyAccordion';
import { useFlags } from '../../../../services/launchDarkly';
import { hasVariableAmountProduct } from '../../../../utils/quotes';
import QuoteFormBillingSchedule from '../../Quote/components/QuoteFormBillingSchedule';
import { QuoteFormDiscounts } from '../../Quote/components/QuoteFormDiscounts';
import { QuoteFormPricesSection } from '../../Quote/components/QuoteFormPricesSection';
import { useQuoteContext } from '../../Quote/quoteContext';

interface QuoteInternalBillingProps {
  onOpenContactDrawer: () => void;
}

export const QuoteInternalBilling = ({
  onOpenContactDrawer,
}: QuoteInternalBillingProps) => {
  const { hideBillingSchedule } = useFlags();
  const { quoteData } = useQuoteContext();
  const { quote } = quoteData;

  const anyVariableAmountProducts = hasVariableAmountProduct(quote);

  return (
    <QuoteBodyAccordion title="Billing Details" mb="0">
      {quote && (
        <QuoteFormDiscounts
          quote={quote}
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.lightPurple"
          itemProps={{ border: 'none' }}
        />
      )}
      {quote?.id && (
        <QuoteFormPricesSection
          quoteId={quote?.id}
          currency={quote?.currency}
          mt="6"
          borderWidth="1px"
          borderRadius="md"
          borderColor="tGray.lightPurple"
          itemProps={{ border: 'none' }}
        />
      )}

      {!hideBillingSchedule &&
        quote?.quoteOfferings &&
        quote?.quoteOfferings.length > 0 && (
          <QuoteFormBillingSchedule
            loading={quoteData.billingScheduleLoading}
            borderWidth="1px"
            borderRadius="0.25rem"
            mt="6"
            borderColor="tGray.lightPurple"
            itemProps={{ border: 'none' }}
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        )}
    </QuoteBodyAccordion>
  );
};
