import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import toString from 'lodash/toString';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { handleApiErrorToast } from '../../../api/axios';
import {
  doGetOfferingById,
  doGetProductById,
} from '../../../api/productCatalogService';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '../../../api/queryUtils';
import {
  MAmountWithUnitTypeInput,
  MBox,
  MButton,
  MCheckbox,
  MCustomNumberInput,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MHStack,
  MInput,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MStatusIDBox,
  MText,
} from '../../../components/Monetize';
import { DatePicker } from '../../../components/Monetize/DatePicker/DatePicker';
import { MainSearchInput } from '../../../components/Monetize/MCustomSelect/components/MainSearchInput';
import { useCustomSelectValue } from '../../../components/Monetize/MCustomSelect/useCustomSelectValue';
import { DISCOUNTS, ROUTES } from '../../../constants';
import { DISCOUNT_STATUS_ENUM_DISPLAY } from '../../../constants/discounts';
import { STATUS_PLACEHOLDER } from '../../../constants/placeholders';
import { DISCOUNT_LIST } from '../../../constants/routes';
import { useCtrlEnterHotkey } from '../../../hooks/useHotkeys';
import { useSettingsReadOnlyRule } from '../../../hooks/useSettingsReadOnlyRule';
import {
  AmountUnitTypeEnum,
  DiscountDurationEnum,
  DiscountSchema,
  DiscountStatusEnum,
  IDiscount,
  IDiscountSchema,
  ProductTypeEnum,
} from '../../../types';
import { sortByProductType, sortOfferingsByTypeArr } from '../../../utils';
const additionalProductSearchParams = {
  productType: `ne:${ProductTypeEnum.MIN_COMMIT}`,
};
export const DiscountForm: FC = () => {
  const navigate = useNavigate();
  const { discountId = '' } = useParams();
  const [discountType, setDiscountType] = useState<AmountUnitTypeEnum>(
    AmountUnitTypeEnum.PERCENTAGE,
  );

  const {
    data: discount,
    isLoading: isDiscountLoading,
    isError,
  } = useGetById<IDiscount>('productCatalogDiscounts', discountId!, {
    enabled: !!discountId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (discount) {
      const formData = pick(discount, Object.keys(DiscountSchema.shape));
      reset({
        ...formData,
        discountAmount: formData.discountAmount,
        durationMonths:
          formData.durationMonths !== null ? formData.durationMonths : 0,
        maxUses: formData.maxUses,
        startDate: formData.startDate,
        endDate: formData.endDate,
      });
      // validate fields
      trigger();
      setDiscountType(formData.discountType!);
    } else if (isError) {
      navigate(ROUTES.DISCOUNT_LIST, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount, isError, navigate]);

  const doCreateDiscount = useCreateEntity('productCatalogDiscounts', {
    onSuccess: () => navigate(ROUTES.DISCOUNT_LIST),
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const doUpdateDiscount = useUpdateEntity('productCatalogDiscounts', {
    onSuccess: () => navigate(ROUTES.DISCOUNT_LIST),
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const isDiscountLocked = !!discount?.locked;

  const isSaveLoading =
    doCreateDiscount.isPending || doUpdateDiscount.isPending;

  const {
    trigger,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm<IDiscountSchema>({
    resolver: zodResolver(DiscountSchema),
    mode: 'onChange',
    defaultValues: {
      autoRemove: false,
      ...discount,
      status: discount?.status || DiscountStatusEnum.ACTIVE,
      durationMonths: discount?.durationMonths || 0,
    },
    reValidateMode: 'onChange',
  });

  const selectedDurationType = watch('durationType');
  const selectedDurationMonth = watch('durationMonths');

  useEffect(() => {
    if (
      selectedDurationType !== DiscountDurationEnum.LIMITED &&
      selectedDurationMonth
    ) {
      setValue('durationMonths', null);
    }
  }, [selectedDurationMonth, selectedDurationType, setValue]);

  useCtrlEnterHotkey(() => {
    handleSubmit(onSubmit)();
  });

  const onSubmit = async (data: any) => {
    const payload = {
      ...data,
      discountType,
      discountAmount: Number(data.discountAmount),
      durationMonths:
        selectedDurationType === 'LIMITED' ? data.durationMonths : 0,
      maxUses: data.maxUses,
      startDate: data.startDate,
      endDate: data.endDate,
      currency: 'USD', // TODO-no options to choose in FE. will be implemented later
    };

    if (!discountId) {
      doCreateDiscount.mutate(payload);
    } else {
      doUpdateDiscount.mutate({
        id: discountId,
        payload: { id: discountId, ...payload },
      });
    }
  };

  const onChangeDiscountType = (disType: AmountUnitTypeEnum) => {
    setValue('discountType', disType, { shouldDirty: true }); // only used to set the form's dirty state, the value in the form state is not used
    setDiscountType(disType);
  };

  useEffect(() => {
    // duration type should alway be ONETIME when discount type FLAT is selected.
    if (discountType === AmountUnitTypeEnum.FLAT) {
      setValue('durationType', DiscountDurationEnum.ONE_TIME);
    }
  }, [discountType, setValue]);

  const watchScopeOfferingId = watch('scopeOfferingId');
  const {
    internalValue: internalScopeOffering,
    isLoading: isLoadingScopeOffering,
    onInternalValueChange: onInternalScopeOfferingChange,
  } = useCustomSelectValue<any>({
    value: watchScopeOfferingId,
    setValue: (value) => {
      setValue('scopeOfferingId', value, { shouldDirty: true });
    },
    getOneById: doGetOfferingById,
  });

  const watchScopeProductId = watch('scopeProductId');
  const {
    internalValue: internalScopeProduct,
    isLoading: isLoadingScopeProduct,
    onInternalValueChange: onInternalScopeProductChange,
  } = useCustomSelectValue<any>({
    value: watchScopeProductId,
    setValue: (value) => {
      setValue('scopeProductId', value, { shouldDirty: true });
    },
    getOneById: doGetProductById,
  });
  const { isReadOnly, inputVariant } = useSettingsReadOnlyRule();
  const headerTitle = isReadOnly
    ? 'View Discount'
    : !discountId
      ? 'New Discount'
      : 'Edit Discount';

  const isDataLoading =
    (discountId && isDiscountLoading) ||
    isLoadingScopeOffering ||
    isLoadingScopeProduct;

  return (
    <MPageContainer alignItems="stretch">
      <MPageHeader
        hasBackButton
        backButtonCallback={() => navigate(DISCOUNT_LIST)}
        title={headerTitle}
        subTitle={
          <MHStack ml={0} gap={0}>
            <MStatusIDBox id={discount?.id} copyUrl />
          </MHStack>
        }
        status={
          discount?.status ? DISCOUNT_STATUS_ENUM_DISPLAY[discount?.status] : ''
        }
      >
        {!isReadOnly && (
          <MButton
            form="discount-form"
            variant="primary"
            isLoading={isSaveLoading}
            isDisabled={!isDirty || !isValid || isSaveLoading}
            type="submit"
          >
            Save
          </MButton>
        )}
      </MPageHeader>

      {isDataLoading ? (
        <MFlex justify="center" grow={1} height={'100%'}>
          <MPageLoader height="auto" />
        </MFlex>
      ) : (
        <MBox width="100%" py="4">
          <form
            id="discount-form"
            onSubmit={handleSubmit(onSubmit)}
            style={{ maxWidth: '725px', margin: '0 auto' }}
          >
            <MGrid templateColumns="repeat(12, 1fr)" gap={4}>
              <MGridItem colSpan={6}>
                <MFormField error={errors.name} label="Name" isRequired>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={40}
                        isDisabled={isDiscountLocked || isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField error={errors.status} label="Status" isRequired>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <MCustomSelect
                        placeholder={STATUS_PLACEHOLDER}
                        itemTitle="name"
                        itemValue="value"
                        items={DISCOUNTS.DISCOUNT_STATUS}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField
                  error={errors.description}
                  label="Description"
                  isRequired
                  skipLabel
                >
                  <Controller
                    name="description"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={80}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12} mt="2">
                <MText fontWeight="semibold" fontSize="lg">
                  Discount
                </MText>
                <MDivider mt="4" borderColor="tGray.back" />
              </MGridItem>

              <MGridItem colSpan="auto" minWidth="6rem">
                <MFormField
                  error={errors.discountAmount}
                  label="Amount"
                  isRequired
                >
                  <Controller
                    name="discountAmount"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MAmountWithUnitTypeInput
                        placeholder="10"
                        amountUnitType={discountType}
                        value={toString(value)}
                        onChangeAmountUnitType={onChangeDiscountType}
                        isDisabled={isDiscountLocked || isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        usePortal
                        onChange={(valueAsString: string) => {
                          onChange(valueAsString);
                        }}
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3} ml="-1">
                <MFormField
                  error={errors.discountCode}
                  label="Discount Code"
                  isRequired
                >
                  <Controller
                    name="discountCode"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <MInput
                        maxLength={40}
                        placeholder="SAVE10%"
                        isDisabled={isDiscountLocked || isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        {...field}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12} mt="2">
                <MText fontWeight="semibold" fontSize="lg">
                  Scope of Discount
                </MText>
                <MDivider mt="4" borderColor="tGray.back" />
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField error={errors.scopeOfferingId} label="Offering">
                  <Controller
                    name="scopeOfferingId"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MCustomSelect
                        placeholder="Search Offerings"
                        endpoint="/api/offerings"
                        itemTitle="name"
                        itemValue="id"
                        clearable
                        useMainInputAsSearchInput
                        MainInputComponent={MainSearchInput}
                        transformDataFromApi={(d) =>
                          Array.isArray(d) ? sortOfferingsByTypeArr(d) : []
                        }
                        value={internalScopeOffering}
                        onChange={(e) => {
                          onInternalScopeOfferingChange(e as any);
                        }}
                        loading={isLoadingScopeOffering}
                        returnItem
                        isDisabled={
                          isDiscountLocked || isSaveLoading || isReadOnly
                        }
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={6}>
                <MFormField error={errors.scopeProductId} label="Product">
                  <Controller
                    name="scopeProductId"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MCustomSelect
                        placeholder="Search Products"
                        endpoint="/api/products"
                        itemTitle="name"
                        itemValue="id"
                        clearable
                        useMainInputAsSearchInput
                        MainInputComponent={MainSearchInput}
                        additionalSearchParams={additionalProductSearchParams}
                        transformDataFromApi={(data) => {
                          const productList = Array.isArray(data)
                            ? sortByProductType(data)
                            : [];
                          return productList;
                        }}
                        value={internalScopeProduct}
                        onChange={(e) => {
                          onInternalScopeProductChange(e as any);
                        }}
                        loading={isLoadingScopeProduct}
                        returnItem
                        isDisabled={
                          isDiscountLocked || isSaveLoading || isReadOnly
                        }
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={6} mt="1">
                <MFormField label="Account ID">
                  <Controller
                    name="accountId"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MInput
                        placeholder="Limit this discount to only one account"
                        value={value || ''}
                        {...rest}
                        isDisabled={isDiscountLocked || isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12} mt="2">
                <MText fontWeight="semibold" fontSize="lg">
                  Duration
                </MText>
                <MDivider mt="4" borderColor="tGray.back" />
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField
                  error={errors.durationType}
                  label="Duration Type"
                  isRequired
                >
                  <Controller
                    name="durationType"
                    control={control}
                    render={({ field }) => (
                      <MCustomSelect
                        placeholder="Select type"
                        items={DISCOUNTS.DISCOUNT_DURATION_TYPES}
                        {...field}
                        isDisabled={
                          isDiscountLocked ||
                          discountType === AmountUnitTypeEnum.FLAT ||
                          isSaveLoading
                        }
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField
                  error={errors.durationMonths}
                  label="Duration Month"
                >
                  <Controller
                    name="durationMonths"
                    control={control}
                    defaultValue={0}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MCustomNumberInput
                        value={toString(value)}
                        isDisabled={
                          selectedDurationType !==
                            DiscountDurationEnum.LIMITED ||
                          isDiscountLocked ||
                          isSaveLoading
                        }
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        onChange={(valueAsString: string) =>
                          onChange(valueAsString)
                        }
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12} mt="6">
                <MText fontWeight="semibold" fontSize="lg">
                  Validity
                </MText>
                <MDivider mt="3" borderColor="tGray.back" />
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField error={errors.startDate} label="Start Date">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        clearable
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField error={errors.endDate} label="End Date">
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        clearable
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={3}>
                <MFormField error={errors.maxUses} label="Max Applications">
                  <Controller
                    name="maxUses"
                    control={control}
                    render={({ field: { value, onChange, ...rest } }) => (
                      <MCustomNumberInput
                        value={toString(value)}
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                        onChange={(valueAsString: string) =>
                          onChange(valueAsString)
                        }
                        {...rest}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>

              <MGridItem colSpan={12}>
                <MFormField error={errors.autoRemove}>
                  <Controller
                    name="autoRemove"
                    control={control}
                    render={({ field: { value, ...rest } }) => (
                      <MCheckbox
                        isChecked={value as any}
                        {...rest}
                        iconColor="tWhite.base"
                        spacing="5"
                        fontWeight="normal"
                        isDisabled={isSaveLoading}
                        isReadOnly={isReadOnly}
                        variant={inputVariant}
                      >
                        Auto remove this discount after the end date
                      </MCheckbox>
                    )}
                  />
                </MFormField>
              </MGridItem>
            </MGrid>
          </form>
        </MBox>
      )}
    </MPageContainer>
  );
};
