import { object, string, z } from 'zod';

export type IApprovalStatus = 'NO_ACTION' | 'DECLINED' | 'APPROVED';

export interface IApproval {
  approvalTimestamp: string | null;
  createDate: string | null;
  createdBy: string;
  declineReason: string | null;
  description: string;
  id: string;
  lastModifiedBy: string;
  mandatory: boolean;
  modifyDate: string;
  quoteId: string;
  rank: number;
  ruleId: string;
  skipped: boolean;
  status: IApprovalStatus;
  teamId: string | null;
  teamName: string | null;
  userId: string | null;
  username: string;
  currentUserApprover: boolean;
  declinedDate: string | null;
  sentDate: string | null;
}

export interface IDashboardApprovalQuote {
  id: string;
}

export const RejectSchema = object({
  declineReason: string().max(500).nonempty("Can't be empty."),
});
export type IRejectSchema = z.infer<typeof RejectSchema>;
