import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  MAccordion,
  MAccordionCustomButton,
  MBox,
  MButton,
  MFlex,
  MStack,
} from '../../../../../components/Monetize';
import { MSettingAccordionItem } from '../../../../../components/Monetize/MSettingAccordionItem';
import {
  IQuoteSettingContractSchema,
  QuoteSettingContractSchema,
} from '../../../../../types';
import { ContractRenewalTermsForm } from './ContractRenewalTermsForm';

interface QuoteSettingEndActionProps {
  value: IQuoteSettingContractSchema;
  onSubmit: (data: IQuoteSettingContractSchema) => Promise<void>;
  isLoading?: boolean;
}

export const QuoteSettingEndAction = ({
  value,
  onSubmit,
  isLoading,
}: QuoteSettingEndActionProps) => {
  const contractSettingForm = useForm<IQuoteSettingContractSchema>({
    resolver: zodResolver(QuoteSettingContractSchema),
    mode: 'onChange',
    defaultValues: value,
    values: value,
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = contractSettingForm;

  useEffect(() => {
    if (value) {
      reset({
        ...value,
      });
    }
  }, [value]);

  const onSave = async (data: IQuoteSettingContractSchema) => {
    try {
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    }
  };

  return (
    <MStack w="100%" data-testid="contract-setting-config">
      <form onSubmit={handleSubmit(onSave)} data-testid="contract-setting-form">
        <MAccordion allowMultiple>
          <MSettingAccordionItem
            renderAccordionButton={({
              isExpanded,
            }: {
              isExpanded: boolean;
            }) => (
              <MAccordionCustomButton
                isExpanded={isExpanded}
                label="End Action"
              />
            )}
          >
            <MBox mt="4">
              <ContractRenewalTermsForm
                contractSettingForm={contractSettingForm}
              />

              <MFlex justify="end">
                <MButton
                  variant="primary"
                  onClick={handleSubmit(onSave)}
                  isDisabled={!isDirty || !isValid || isLoading}
                  isLoading={isLoading}
                >
                  Save
                </MButton>
              </MFlex>
            </MBox>
          </MSettingAccordionItem>
        </MAccordion>
      </form>
    </MStack>
  );
};
