import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { EMPTY_ARRAY } from '../constants/common';
import {
  ApiListResponse,
  ContactStatusEnum,
  DEFAULT_PAGER,
  GetListApiConfig,
  GetListApiFilter,
  IContactRequestSchema,
  IContactRespSchema,
  Maybe,
} from '../types';
import { sortAlphabetically } from '../utils';
import { apiGet, apiGetAllList, apiPost, apiPut } from './axios';
import {
  accountServiceQueryKeys,
  contactServiceQueryKeys,
} from './queryKeysService';
import { useGetListData } from './queryUtils';
import { updateListCacheWithUpdatedItem } from './queryUtilsHelpers';
import { composeGetQuery } from './utils';

/**
 * Create a contact for an account or quote
 */
export function useCreateContact(
  options: Partial<
    UseMutationOptions<
      IContactRespSchema,
      unknown,
      {
        accountId: string;
        payload: IContactRequestSchema;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IContactRespSchema,
    unknown,
    {
      accountId: string;
      payload: IContactRequestSchema;
    }
  >({
    mutationFn: ({ accountId, payload }) =>
      apiPost<IContactRespSchema>(
        `/api/accounts/${accountId}/contacts`,
        payload,
      ).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...contactServiceQueryKeys.contactList()],
      });
      queryClient.setQueryData(
        [...contactServiceQueryKeys.contactDetail(data.id)],
        data,
      );

      // Contact may be stored in cache more than once if contact is associated to an account
      if (data.accountId) {
        queryClient.invalidateQueries({
          queryKey: [
            ...accountServiceQueryKeys.accounts.accountContacts(data.accountId),
          ],
        });
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

/**
 * Update contact
 */
export function useUpdateContact(
  options: Partial<
    UseMutationOptions<
      IContactRespSchema,
      unknown,
      {
        contactId: string;
        payload: IContactRequestSchema;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IContactRespSchema,
    unknown,
    {
      contactId: string;
      payload: IContactRequestSchema;
    }
  >({
    mutationFn: ({ contactId, payload }) =>
      apiPut<IContactRespSchema>(`/api/contacts/${contactId}`, payload).then(
        (res) => res.data,
      ),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...contactServiceQueryKeys.contactList()],
      });
      queryClient.setQueryData(
        [...contactServiceQueryKeys.contactDetail(data.id)],
        data,
      );

      if (data.accountId) {
        queryClient.invalidateQueries({
          queryKey: [
            ...accountServiceQueryKeys.accounts.accountContacts(data.accountId),
          ],
        });
        updateListCacheWithUpdatedItem(
          queryClient,
          [...accountServiceQueryKeys.accounts.accountContacts(data.accountId)],
          data,
        );
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useGetAccountContacts<
  SelectData = ApiListResponse<IContactRespSchema>,
>(
  {
    accountId,
    includeInternal = false,
    config,
    filters,
    isGetAll = false,
  }: {
    accountId: string;
    /**
     * If true, then showInternal filter will not be added to query
     * default, false, will add showInternal=false to query filters
     */
    includeInternal?: boolean;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
    /** If true, it will return all contacts iterating all pages. */
    isGetAll?: boolean;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<IContactRespSchema>, unknown, SelectData>
  > = {},
) {
  let params = {};
  filters = filters || {};
  if (!includeInternal) {
    filters = {
      ...filters,
      showInternal: 'false',
    };
  }

  if (config) {
    params = composeGetQuery(config, filters);
  }
  const queryClient = useQueryClient();

  const apiGetList = async (): Promise<ApiListResponse<IContactRespSchema>> => {
    if (isGetAll) {
      const items = await apiGetAllList(`/api/accounts/${accountId}/contacts`, {
        rows: config?.rows || 100,
        filters,
        config,
      });
      return {
        totalElements: items.length,
        totalPages: Math.ceil(items.length / (config?.rows || 100)),
        content: items,
      } as ApiListResponse<IContactRespSchema>;
    } else {
      return apiGet<ApiListResponse<IContactRespSchema>>(
        `/api/accounts/${accountId}/contacts`,
        {
          params,
        },
      ).then((res) => res.data);
    }
  };

  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.accounts.accountContacts(accountId),
      params,
    ],
    queryFn: apiGetList,
    meta: {
      setByIdCacheFromReturnedList: {
        byIdQueryKey: (id) => contactServiceQueryKeys.contactDetail(id),
      },
    },
    ...options,
  });
}

/**
 * This is used in a case where it would be inconvenient to use useGetAccountContacts
 */
export const doGetAccountContacts = async (
  accountId: string,
  config: GetListApiConfig = DEFAULT_PAGER,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IContactRespSchema>> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IContactRespSchema>>(
    `/api/accounts/${accountId}/contacts`,
    { params },
  );
  return res.data;
};

export function useActivateDeactivateContact(
  options: Partial<
    UseMutationOptions<
      IContactRespSchema,
      unknown,
      { action: 'activate' | 'cancel'; accountId: string; contactId: string }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IContactRespSchema,
    unknown,
    { action: 'activate' | 'cancel'; accountId: string; contactId: string }
  >({
    mutationFn: ({ accountId, contactId, action }) =>
      apiPut<IContactRespSchema>(
        `/api/accounts/${accountId}/contacts/${contactId}/${action}`,
      ).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(
        contactServiceQueryKeys.contactDetail(data.id),
        data,
      );
      updateListCacheWithUpdatedItem(
        queryClient,
        [...contactServiceQueryKeys.contactList()],
        data,
      );
      if (data.accountId) {
        updateListCacheWithUpdatedItem(
          queryClient,
          [...accountServiceQueryKeys.accounts.accountContacts(data.accountId)],
          data,
        );
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

/**
 * Wrapper around useGetListData which returns all and the default esigner contacts
 */
export function useGetEsignContacts(
  options: {
    accountId?: string;
    refetchOnWindowFocus?: boolean;
  } = {},
) {
  const { accountId, ...restOptions } = options;
  const {
    isLoading,
    data: { allContacts, defaultContact } = { allContacts: EMPTY_ARRAY },
  } = useGetListData<
    IContactRespSchema,
    {
      allContacts: IContactRespSchema[];
      defaultContact?: IContactRespSchema;
    }
  >(
    'contacts',
    {
      config: { ...DEFAULT_PAGER, sortField: 'fullName', rows: 100 },
      filters: {
        esigner: 'true',
        status: { in: [ContactStatusEnum.ACTIVE] },
      },
    },
    {
      select: (response) => {
        const contacts = response.content.sort((a, b) =>
          sortAlphabetically('fullName')(a, b),
        );
        return {
          allContacts:
            contacts.filter(
              (contact) =>
                !contact.accountId || contact.accountId === accountId,
            ) || EMPTY_ARRAY,
          defaultContact: contacts.find((contact) => contact.defaultESigner),
        };
      },
      ...restOptions,
      isGetAll: true,
    },
  );

  return {
    allContacts,
    defaultContact,
    isLoading,
  };
}

/**
 * Update esigner. The API does not return any data,
 * so we update the query cache with what we know the state should be after this action
 * Old contact has default=false and new contact has default=true
 *
 */
export function useChangeDefaultEsigner(
  currentDefaultSignerId?: Maybe<string>,
  options: Partial<UseMutationOptions<void, unknown, { id: string }>> = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<void, unknown, { id: string }>({
    mutationFn: ({ id }) =>
      apiPut(`/api/contacts/${id}/defaultESigner`).then(({ data }) => data),
    onSuccess: (data, variables, context) => {
      // update cache for the old default
      if (currentDefaultSignerId) {
        let previousDefault = queryClient.getQueryData<IContactRespSchema>(
          contactServiceQueryKeys.contactDetail(currentDefaultSignerId),
        );
        if (previousDefault) {
          previousDefault = { ...previousDefault, defaultESigner: false };
          updateListCacheWithUpdatedItem(
            queryClient,
            contactServiceQueryKeys.contactList(),
            previousDefault,
          );
          queryClient.setQueryData(
            contactServiceQueryKeys.contactDetail(currentDefaultSignerId),
            previousDefault,
          );
        }
      }

      // Update cache for new default
      let newDefault = queryClient.getQueryData<IContactRespSchema>(
        contactServiceQueryKeys.contactDetail(variables.id),
      );
      if (newDefault) {
        newDefault = { ...newDefault, defaultESigner: true };
        updateListCacheWithUpdatedItem(
          queryClient,
          contactServiceQueryKeys.contactList(),
          newDefault,
        );
        queryClient.setQueryData(
          contactServiceQueryKeys.contactDetail(variables.id),
          newDefault,
        );
      }

      // Invalidate cache to re-query just to be sure the data is what we expect it to be
      queryClient.invalidateQueries({
        queryKey: contactServiceQueryKeys.contactList(),
      });

      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export const doGetContactById = async (
  id: string,
): Promise<IContactRespSchema> => {
  const res = await apiGet<IContactRespSchema>(`/api/contacts/${id}`);
  return res.data;
};
