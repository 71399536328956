import { BoxProps, TextProps } from '@chakra-ui/react';
import { ReactNode, isValidElement } from 'react';
import { MSimpleGrid, MText } from '../../../../components/Monetize';

interface TotalRowProps {
  leftColumn: string | ReactNode;
  rightColumn: string | ReactNode;
  textProps?: TextProps;
  containerProps?: BoxProps;
}
export const TotalRow = ({
  leftColumn,
  rightColumn,
  textProps,
  containerProps,
}: TotalRowProps) => {
  const isValidLeftColumnElement = isValidElement(leftColumn);
  const isValidRightColumnElement = isValidElement(rightColumn);

  return (
    <MSimpleGrid
      px="2"
      columns={2}
      h="12"
      alignItems="center"
      {...containerProps}
    >
      {!isValidLeftColumnElement && (
        <MText fontWeight="600" fontSize="md" {...textProps}>
          {leftColumn}
        </MText>
      )}
      {isValidLeftColumnElement && leftColumn}
      {!isValidRightColumnElement && (
        <MText
          fontWeight="600"
          fontSize="md"
          textAlign="right"
          pr={2}
          {...textProps}
        >
          {rightColumn}
        </MText>
      )}
      {isValidRightColumnElement && rightColumn}
    </MSimpleGrid>
  );
};
