import {
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { formatInteger } from '@monetize/utils/core';
import lodashGet from 'lodash/get';
import isString from 'lodash/isString';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { MdFilterAlt, MdFilterList } from 'react-icons/md';
import { useNonInitialEffect } from '../../../../hooks/useNonInitialEffect';
import MCustomIconButton from '../../MCustomIconButton';
import MCustomRadioGroup from '../../MCustomRadioGroup';
import MText from '../../MText';
import {
  DataTableFilterRef,
  FilterProps,
  SelectionOption,
} from '../DataTableTypes';

const ALL: SelectionOption = 'ALL';
const SELECTED: SelectionOption = 'SELECTED';
const UNSELECTED: SelectionOption = 'UNSELECTED';

function getOptions(rowCount: number, selectedCount: number) {
  return [
    {
      title: `All (${formatInteger(rowCount)})`,
      value: ALL,
    },
    {
      title: `Selected (${formatInteger(selectedCount)})`,
      value: SELECTED,
    },
    {
      title: `Unselected (${formatInteger(rowCount - selectedCount)})`,
      value: UNSELECTED,
    },
  ];
}

export const DataTableHeaderSelectionFilter = forwardRef<
  DataTableFilterRef,
  FilterProps<any>
>(({ column, table }, ref) => {
  useImperativeHandle(
    ref,
    (): DataTableFilterRef => ({
      resetFilters: () => {
        setSelectedValue('ALL');
      },
      setFilterValue: ({ columnKey, value }) => {
        if (
          columnKey === column.id &&
          isString(value) &&
          [ALL, SELECTED, UNSELECTED].includes(value as SelectionOption)
        ) {
          setSelectedValue(value as SelectionOption);
        }
      },
    }),
  );

  const key = lodashGet(column, 'columnDef.accessorKey', column.id) as string;

  const [selectedValue, setSelectedValue] = useState<SelectionOption>(ALL);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const rowCount = table.getCoreRowModel().rows.length;
  const selectedCount = table.getSelectedRowModel().rows.length;

  const options = useMemo(
    () => getOptions(rowCount, selectedCount),
    [rowCount, selectedCount],
  );

  useNonInitialEffect(() => {
    column.setFilterValue(selectedValue);
  }, [column, selectedValue]);

  const handleClose = () => {
    onClose();
  };

  const filtersApplied = selectedValue !== 'ALL';

  return (
    <Popover
      placement="auto-start"
      trigger="click"
      offset={[0, 0]}
      variant="responsive"
      isOpen={isOpen}
      onClose={handleClose}
      strategy="fixed"
      isLazy
    >
      <PopoverTrigger>
        <MCustomIconButton
          data-testid={`table-column-filter-${key}`}
          btnSize={5}
          onClick={onOpen}
          icon={filtersApplied ? MdFilterAlt : MdFilterList}
          variant="icon"
          ml="2"
          color={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
          iconColor={filtersApplied ? 'tIndigo.base' : 'tPurple.base'}
        />
      </PopoverTrigger>
      <PopoverContent>
        {isOpen && (
          <FocusLock>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader fontWeight="semibold">
              <MText pt={4} px={4}>
                Filter
              </MText>
            </PopoverHeader>
            <PopoverBody minW="304px">
              <MCustomRadioGroup
                value={selectedValue}
                my={1}
                itemTitle="title"
                itemValue="value"
                items={options}
                onChange={(value) => setSelectedValue(value as SelectionOption)}
              />
            </PopoverBody>
          </FocusLock>
        )}
      </PopoverContent>
    </Popover>
  );
});
