import { getRequiredMessage } from '@monetize/utils/core';
import * as z from 'zod';

export enum LegalEntityAddressFormatEnum {
  US_FORMAT = 'US_FORMAT',
  EUROPEAN_FORMAT = 'EUROPEAN_FORMAT',
}
export const LegalEntityAddressFormatEnumZ = z.nativeEnum(
  LegalEntityAddressFormatEnum,
);
export const LegalEntityResponseSchema = z.object({
  city: z.string(),
  country: z.string(),
  createDate: z.string(),
  createdBy: z.string(),
  customId: z.string().nullable(),
  defaultEntity: z.boolean(),
  id: z.string(),
  lastModifiedBy: z.string(),
  line1: z.string(),
  line2: z.string().nullable(),
  logoStorageId: z.string().nullable(),
  modifyDate: z.string(),
  name: z.string(),
  postalCode: z.string(),
  state: z.string(),
  email: z.string(),
  addressFormat: LegalEntityAddressFormatEnumZ.optional(),
});

export type ILegalEntityResponseSchema = z.infer<
  typeof LegalEntityResponseSchema
>;

const ACCEPTED_FILE_TYPES = ['image/png'];

export const LegalEntityRequestSchema = z.object({
  file: z
    .custom<File>((val) => val instanceof File, 'Please upload a file')
    .refine((file) => ACCEPTED_FILE_TYPES.includes(file?.type), {
      message: 'Please choose png format image only',
    })
    .optional(),
  name: z
    .string({ required_error: getRequiredMessage('Name') })
    .min(1, { message: getRequiredMessage('Name') }),
  customId: z.string().optional(),
  addressFormat: LegalEntityAddressFormatEnumZ.optional(),
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .nullish()
    .or(z.literal('')),
  line1: z.string().optional(),
  line2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.string().optional(),
  country: z.string().optional(),
});

export type ILegalEntityRequestSchema = z.infer<
  typeof LegalEntityRequestSchema
>;

/**
 * Legal entity data gets stamped to quotes and invoices
 */
export const LegalEntitySnapshot = z.object({
  legalEntityId: z.string(),
  name: z.string().nullable(),
  customId: z.string().nullish(),
  email: z.string().nullish(),
  logoStorageId: z.string().nullish(),
  addressFormat: LegalEntityAddressFormatEnumZ.nullish(),
  address: z.object({
    line1: z.string().nullable(),
    line2: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    country: z.string().nullable(),
    postalCode: z.string().nullable(),
  }),
});

export type ILegalEntitySnapshot = z.infer<typeof LegalEntitySnapshot>;

/** Used in places where a summary snaphot is provided */
export const LegalEntitySnapshotSlim = LegalEntitySnapshot.pick({
  legalEntityId: true,
  name: true,
}).extend({
  id: z.string(),
});
export type ILegalEntitySnapshotSlim = z.infer<typeof LegalEntitySnapshotSlim>;
