import { MigratedQuoteIcon } from '@monetize/ui/icons';
import { UseFormReturn } from 'react-hook-form';
import { handleApiErrorToast } from '../../../../api/axios';
import { useUpdateNewQuoteType } from '../../../../api/cpqService';
import {
    MCircularProgress,
    MFlex,
    MHStack,
    MIcon,
    MTag,
    MTagCloseButton,
    MTagLabel,
    MText,
} from '../../../../components/Monetize';
import {
    INITIAL_MANUAL_RENEWAL_VALUES,
    QuoteStatusEnumDisplay,
    QuoteStatusTagStyle,
} from '../../../../constants/quotes';
import { useACL } from '../../../../services/acl/acl';
import {
    INewQuoteTypeReqSchema,
    IQuoteRespSchema,
    NewQuoteTypeEnum,
    QuoteStatusEnum,
    QuoteTypeEnum,
} from '../../../../types';
import { QuoteTypeTag } from './QuoteTypeTag';
;

interface QuoteTagsProps {
  quote: IQuoteRespSchema | null | undefined;
  isReadOnly?: boolean;
  setQuote?: (quote: IQuoteRespSchema | null) => void;
  manualRenewalFormMethods?: UseFormReturn<INewQuoteTypeReqSchema, object>;
}

export const QuoteTags = ({
  quote,
  isReadOnly = false,
  setQuote,
  manualRenewalFormMethods,
}: QuoteTagsProps) => {
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);

  const { mutate: updateNewQuoteType, isPending: isLoading } =
    useUpdateNewQuoteType({
      onSuccess: (data) => {
        setQuote && setQuote(data);
        manualRenewalFormMethods?.reset(INITIAL_MANUAL_RENEWAL_VALUES);
      },
      onError: (error) => {
        handleApiErrorToast(error);
      },
    });
  if (!quote) {
    return null;
  }

  const handleRemoveManualRenewal = () => {
    updateNewQuoteType({
      quoteId: quote.id,
      payload: { type: NewQuoteTypeEnum.NET_NEW },
    });
  };

  const statusStyle = QuoteStatusTagStyle[quote.status];
  const isQuoteMigrated = quote?.migrated;
  const canRemoveManualRenewal =
    !isReadOnly &&
    canUpdateSales &&
    !isLoading &&
    quote.status === QuoteStatusEnum.DRAFT;

  return (
    <MHStack>
      {isQuoteMigrated && (
        <MFlex bgColor="tPurple.linkWater" pr={1} borderRadius={2}>
          <MIcon as={MigratedQuoteIcon} boxSize={4} />
          <MText m={0} color="tBlue.light">
            Migrated
          </MText>
        </MFlex>
      )}
      <MText {...statusStyle} ml=".5rem" data-testid="quote-tag-status">
        {QuoteStatusEnumDisplay[quote.status]}
      </MText>
      {quote.type === QuoteTypeEnum.NEW &&
        quote.newQuoteType === NewQuoteTypeEnum.MANUAL_RENEWAL && (
          <MTag variant="purple">
            <MTagLabel>Manual Renewal</MTagLabel>
            {isLoading && (
              <MCircularProgress
                ml="1.5"
                color="tPurple.safety"
                isIndeterminate
                size="3"
              />
            )}
            {canRemoveManualRenewal && (
              <MTagCloseButton onClick={handleRemoveManualRenewal} />
            )}
          </MTag>
        )}
      {[QuoteTypeEnum.AMENDMENT, QuoteTypeEnum.RENEWAL].includes(
        quote.type,
      ) && <QuoteTypeTag quote={quote} />}
    </MHStack>
  );
};
