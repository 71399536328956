import { FlexProps, FormLabel, Switch, SwitchProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { MFlex } from '../../../../components/Monetize';

interface ViewToggleProps extends SwitchProps {
  leftLabel: string | ReactNode;
  rightLabel: string | ReactNode;
  containerProps?: FlexProps;
}

export const ViewToggle = ({
  leftLabel,
  rightLabel,
  containerProps,
  isChecked,
  ...rest
}: ViewToggleProps) => {
  return (
    <MFlex align="center" gap="3" {...containerProps}>
      <FormLabel
        m="0"
        htmlFor={isChecked ? 'view-switch' : undefined}
        cursor="pointer"
        fontWeight="600"
      >
        {leftLabel}
      </FormLabel>
      <Switch
        id="view-switch"
        variant="dualLabel"
        isChecked={isChecked}
        {...rest}
      />
      <FormLabel
        m="0"
        htmlFor={!isChecked ? 'view-switch' : undefined}
        cursor="pointer"
        fontWeight="600"
      >
        {rightLabel}
      </FormLabel>
    </MFlex>
  );
};
