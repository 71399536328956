import { ButtonProps, Icon } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { IconType } from 'react-icons';
import { FiChevronDown } from 'react-icons/fi';
import { MBox } from '.';
import MButton from './MButton';

interface MTertiaryArrowButtonProps extends ButtonProps {
  isOpen: boolean;
  setIsOpen?: (val: boolean) => void;
  showArrow?: boolean;
  dropDownIcon?: IconType;
}

export const MTertiaryArrowButton = forwardRef<any, MTertiaryArrowButtonProps>(
  (
    {
      isDisabled,
      children,
      isOpen,
      setIsOpen,
      showArrow = true,
      variant = 'plain',
      dropDownIcon = FiChevronDown,
      ...rest
    }: MTertiaryArrowButtonProps,
    ref,
  ) => {
    return (
      <MButton
        ref={ref}
        variant={variant}
        size="sm"
        isDisabled={isDisabled}
        cursor={isDisabled ? 'default' : 'pointer'}
        alignItems="center"
        display="flex"
        onClick={() => setIsOpen && setIsOpen(!isOpen)}
        {...rest}
      >
        {children}
        {!isDisabled && showArrow && (
          <MBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            ml="2"
          >
            <Icon as={dropDownIcon} fontSize="20" zIndex="1" />
          </MBox>
        )}
      </MButton>
    );
  },
);
