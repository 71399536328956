import { QuoteBodyAccordion } from '../../../../components/Quotes/QuoteBodyAccordion';
import { QuoteCustomFields } from '../../Quote/components/QuoteCustomFields';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteConditionalTerms } from './QuoteConditionalTerms';
import { QuoteTerms } from './QuoteTerms';
import { QuoteUploadDocuments } from './QuoteUploadDocuments';

interface QuoteInternalExtraInfoProps {}

export const QuoteInternalExtraInfo = ({}: QuoteInternalExtraInfoProps) => {
  const { quoteData } = useQuoteContext();
  const { quote } = quoteData;

  if (!quote) {
    return null;
  }

  return (
    <QuoteBodyAccordion title="Contract Documents and Terms">
      <QuoteUploadDocuments />

      <QuoteCustomFields
        accordionAutoExpanded
        accordionBorderColor="tGray.lightGray"
        showDivider={false}
        mt="6"
      />

      <QuoteTerms mt={6} />

      <QuoteConditionalTerms mt={6} />
    </QuoteBodyAccordion>
  );
};
