export const InvoiceTemplateIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.4469 2H4.88938C3.85022 2 3 2.85022 3 3.88938V19.0044C3 20.0436 3.85022 20.8938 4.88938 20.8938H16.2257C17.2648 20.8938 18.115 20.0436 18.115 19.0044V7.66814L12.4469 2ZM4.88938 19.0044V3.88938H11.5022V7.66814H16.2257V19.0044H4.88938ZM9.61283 18.0597H11.5022V17.115H12.4469C12.9665 17.115 13.3916 16.6899 13.3916 16.1704V13.3363C13.3916 12.8167 12.9665 12.3916 12.4469 12.3916H9.61283V11.4469H13.3916V9.55752H11.5022V8.61283H9.61283V9.55752H8.66814C8.14856 9.55752 7.72345 9.98263 7.72345 10.5022V13.3363C7.72345 13.8559 8.14856 14.281 8.66814 14.281H11.5022V15.2257H7.72345V17.115H9.61283V18.0597Z"
        fill="currentColor"
      />
      <rect
        x="12.5592"
        y="13.4272"
        width="9.36241"
        height="9.4469"
        rx="4.6812"
        transform="rotate(1.39913 12.5592 13.4272)"
        fill="white"
      />
      <path
        d="M19.6958 18.6496L19.6921 18.6565C19.7089 18.5414 19.7258 18.4228 19.7287 18.3038C19.7316 18.1849 19.7239 18.0726 19.7127 17.9568L19.716 17.9639L20.5862 17.313L19.772 15.8156L18.7579 16.197L18.7613 16.2006C18.5828 16.0562 18.3862 15.9323 18.1715 15.8361L18.175 15.8361L18.0402 14.7615L16.3362 14.7199L16.156 15.7903L16.1595 15.7904C15.9404 15.8761 15.738 15.9902 15.5527 16.1257L15.5562 16.1223L14.5585 15.6883L13.6687 17.144L14.5061 17.8367L14.5097 17.8297C14.4929 17.9449 14.4797 18.0566 14.4768 18.1755C14.4739 18.2945 14.4815 18.4137 14.4961 18.5296L14.4928 18.5225L13.7439 19.0819L13.6262 19.1701L14.4406 20.6604L15.4581 20.2826L15.4515 20.2685C15.6334 20.4165 15.8299 20.5403 16.0482 20.6366L16.0377 20.6364L16.1688 21.7179L17.8658 21.7594C17.8658 21.7594 17.8778 21.6966 17.8904 21.6129L18.046 20.6889L18.0425 20.6889C18.2616 20.6032 18.4675 20.4892 18.6564 20.3502L18.6491 20.3641L19.647 20.7911L20.5331 19.3423C20.5331 19.3423 20.4851 19.2991 20.4199 19.2485L19.6958 18.6496ZM17.0711 19.4643C16.3958 19.4478 15.8599 18.885 15.8764 18.2097C15.8929 17.5344 16.4556 16.9985 17.1309 17.015C17.8062 17.0315 18.3421 17.5943 18.3256 18.2696C18.3091 18.9448 17.7464 19.4808 17.0711 19.4643Z"
        fill="currentColor"
      />
    </svg>
  );
};
