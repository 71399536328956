import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { IoMdDocument } from 'react-icons/io';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MFormField,
  MText,
  MTextarea,
} from '../../../../../components/Monetize';
import MPopoverWrapper from '../../../../../components/Monetize/MPopoverWrapper';
import {
  IQuoteItemRespSchema,
  QuoteItemNote,
  QuoteItemNoteSchema,
} from '../../../../../types';

export interface QuoteItemNotePopoverProps {
  index: number;
  item: IQuoteItemRespSchema;
  setValue: UseFormSetValue<any>;
  handleQuoteOfferingWithoutDirtyCheck: () => void;
  isLoading: boolean;
  isRemoved?: boolean;
  triggerVisibility: 'hidden' | 'visible';
  isReadOnly: boolean;
}

const QuoteItemNotePopover = ({
  index,
  item,
  setValue: setValueOnQuoteOffering,
  handleQuoteOfferingWithoutDirtyCheck,
  isLoading,
  isRemoved = false,
  triggerVisibility,
  isReadOnly,
}: QuoteItemNotePopoverProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<QuoteItemNote>({
    mode: 'onChange',
    resolver: zodResolver(QuoteItemNoteSchema),
    defaultValues: {
      notes: item.notes ?? '',
    },
  });

  const hasError = Object.keys(errors).length > 0;
  const hasNotes = !!item.notes && !isEditing;

  const { onOpen, onClose, isOpen } = useDisclosure();

  const onModalOpen = () => {
    onOpen();
  };

  const onCloseModal = () => {
    reset({
      notes: '',
    });

    setIsEditing(false);
    onClose();
  };

  const onSubmit = async ({ notes }: QuoteItemNote) => {
    const fieldName = `items.${index}.notes`;
    setValueOnQuoteOffering(fieldName, notes, {
      shouldDirty: true,
      shouldValidate: true,
    });
    handleQuoteOfferingWithoutDirtyCheck();
    onCloseModal();
  };

  const onCancel = () => {
    if (isEditing) {
      setIsEditing(false);
    } else {
      onCloseModal();
    }
  };

  return (
    <MPopoverWrapper
      usePortal
      showArrow
      isOpen={isOpen}
      placement="left-start"
      onOpen={onModalOpen}
      onClose={onCloseModal}
      popoverContentProps={{
        minW: '300px',
      }}
      triggerContent={
        <MCustomIconButton
          isDisabled={isRemoved || isLoading}
          icon={IoMdDocument}
          iconColor="tGray.darkPurple"
          btnSize={4}
          padding={0}
          containerSize={5}
          ml={-5}
          onClick={onOpen}
          visibility={isOpen ? 'visible' : triggerVisibility}
        />
      }
      bodyContent={
        <MBox maxH="75vh" overflowY="auto">
          <MText color="tPurple.dark" size="md" fontWeight="bold" mb={2}>
            Notes
          </MText>
          {hasNotes && <MText>{item.notes}</MText>}
          {(!item.notes || isEditing) && (
            <MFormField error={errors.notes}>
              <Controller
                name="notes"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <MTextarea placeholder="Add notes" {...field} />
                )}
              />
            </MFormField>
          )}
        </MBox>
      }
      footerContent={
        <MFlex direction="row" align="center" justify="right">
          {hasNotes && (
            <MButton
              variant="primary"
              isDisabled={hasError || isReadOnly || isLoading}
              onClick={() => {
                setIsEditing(true);
                reset({
                  notes: item.notes ?? '',
                });
              }}
              size="sm"
            >
              Edit
            </MButton>
          )}
          {(isEditing || !item.notes) && (
            <>
              <MButton
                variant="outline"
                isDisabled={hasError || isReadOnly || isLoading}
                onClick={onCancel}
                size="sm"
                mr={2}
              >
                Cancel
              </MButton>
              <MButton
                variant="primary"
                isDisabled={hasError || !isDirty || isReadOnly || isLoading}
                onClick={handleSubmit(onSubmit)}
                size="sm"
              >
                Save
              </MButton>
            </>
          )}
        </MFlex>
      }
    />
  );
};

export default QuoteItemNotePopover;
