import { FunctionComponent as FC } from 'react';
import { useParams } from 'react-router';
import { doGetBillGroupById } from '../../../../api/accountsService';
import {
  FilterTypeEqual,
  RenderTableFilterOptionProps,
} from '../../../../types';
import { MCustomSelect } from '../../MCustomSelect/MCustomSelect';
import { MainSearchInput } from '../../MCustomSelect/components/MainSearchInput';
import { useCustomSelectValue } from '../../MCustomSelect/useCustomSelectValue';

interface BillGroupIdTableFilterOptionContentProps
  extends RenderTableFilterOptionProps {}

export const BillGroupIdTableFilterOptionContent: FC<
  BillGroupIdTableFilterOptionContentProps
> = ({
  filter,
  filterOption,
  handleFilterChange,
}: BillGroupIdTableFilterOptionContentProps) => {
  const { accountId = '' } = useParams();

  const internalFilter = filter as FilterTypeEqual;
  const { internalValue, isLoading, onInternalValueChange } =
    useCustomSelectValue({
      value: internalFilter?.value,
      setValue: (value: string) => {
        handleFilterChange(value, filterOption);
      },
      getOneById: doGetBillGroupById,
    });

  return (
    <MCustomSelect
      isLazy
      clearable
      itemTitle="name"
      itemValue="id"
      placeholder="Search"
      useMainInputAsSearchInput
      endpoint={`api/accounts/${accountId}/billGroups`}
      MainInputComponent={MainSearchInput}
      value={internalValue}
      returnItem
      onChange={(val: any) => {
        onInternalValueChange(val);
      }}
      loading={isLoading}
    />
  );
};
