import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { handleApiErrorToast } from '../../api/axios';
import {
  useCreateInvoicePaymentMethodForInvoiceShare,
  useInvoicePaymentForInvoiceShare,
} from '../../api/shareService';
import { getInvoiceShareStripePaymentCallbackRoute } from '../../constants/routes';
import { tempSharedInvoicePaymentState } from '../../store/global.store';
import { ISharedInvoicePaymentReq, ISharedInvoicePaymentUI } from '../../types';

interface usePayInvoiceWithStripeAnonymousProps {
  tenantId: string;
  invoiceSecretId: string;
  allowPartialPayment: boolean;
  amountDue: number;
  onSuccess: () => void;
}

export const usePayInvoiceWithStripeAnonymous = ({
  tenantId,
  invoiceSecretId,
  allowPartialPayment,
  amountDue,
  onSuccess,
}: usePayInvoiceWithStripeAnonymousProps) => {
  const queryClient = useQueryClient();
  const elements = useElements();
  const stripe = useStripe();

  const [requiresVerification, setRequiredVerification] = useState<
    false | true | { verificationUrl: string }
  >(false);

  const [sharedInvoicePayment, setSharedInvoicePayment] = useRecoilState(
    tempSharedInvoicePaymentState,
  );

  const {
    mutateAsync: doCreateSharedInvoicePayment,
    isPending: isSavePaymentLoading,
  } = useInvoicePaymentForInvoiceShare();

  const { mutateAsync: createPaymentMethodWithoutPayment } =
    useCreateInvoicePaymentMethodForInvoiceShare();

  const { mutateAsync: confirmSetupStripePayment, isPending: stripeLoading } =
    useMutation({
      mutationFn: ({
        stripe,
        elements,
        billingDetails,
      }: {
        stripe: Stripe;
        elements: StripeElements;
        billingDetails: { email: string; name: string };
      }) =>
        stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: getStripeCallbackUrl(),
            payment_method_data: {
              billing_details: billingDetails,
            },
          },
          redirect: 'if_required',
        }),
    });

  const getStripeCallbackUrl = () => {
    const url = getInvoiceShareStripePaymentCallbackRoute(
      tenantId,
      invoiceSecretId,
    );
    return `${window.location.origin}${url}`;
  };

  const handleFormSubmit = async (requestData: ISharedInvoicePaymentUI) => {
    try {
      if (!stripe || !elements) {
        return;
      }
      // Store in localstorage so we can get data back if Stripe invokes the callback
      setSharedInvoicePayment(requestData);

      const { email, fullName } = requestData.billingDetails;
      /**
       * Confirm payment method with Stripe
       */
      const response = await confirmSetupStripePayment({
        stripe,
        elements,
        billingDetails: { email, name: fullName },
      });

      if (response.error) {
        throw new Error(response.error.message);
      }

      if (
        response.setupIntent.status === 'requires_action' &&
        response?.setupIntent?.next_action
      ) {
        if (
          response.setupIntent.next_action.type === 'redirect_to_url' &&
          response.setupIntent.next_action.redirect_to_url?.url
        ) {
          // redirect user to finish verification
          window.location.href =
            response.setupIntent.next_action.redirect_to_url.url;
        } else if (
          response.setupIntent.next_action.type === 'verify_with_microdeposits'
        ) {
          await createPaymentMethod(response.setupIntent.id, requestData);
          // create payment method in pending state (maybe?)
          const verificationUrl =
            response.setupIntent.next_action.verify_with_microdeposits
              ?.hosted_verification_url;
          setRequiredVerification(verificationUrl ? { verificationUrl } : true);
        }
      } else if (
        (response.setupIntent.status === 'succeeded' ||
          response.setupIntent.status === 'processing') &&
        response?.setupIntent?.id
      ) {
        await createInvoicePayment(response.setupIntent.id, requestData);
      }
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  const createInvoicePayment = async (
    paymentToken: string,
    requestData: ISharedInvoicePaymentUI,
  ) => {
    try {
      const amount =
        allowPartialPayment && sharedInvoicePayment?.amount
          ? sharedInvoicePayment.amount
          : amountDue;

      const payload: ISharedInvoicePaymentReq = {
        amount,
        paymentToken,
        paymentMethodName: requestData.billingDetails.paymentMethodName,
        billingDetails: {
          fullName: requestData.billingDetails.fullName,
          email: requestData.billingDetails.email,
        },
      };
      await doCreateSharedInvoicePayment({
        invoiceSecretId,
        tenantId,
        body: payload,
      });
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setSharedInvoicePayment(null);
      onSuccess();
    }
  };

  const createPaymentMethod = async (
    paymentToken: string,
    requestData: ISharedInvoicePaymentUI,
  ) => {
    try {
      const amount =
        allowPartialPayment && sharedInvoicePayment?.amount
          ? sharedInvoicePayment.amount
          : amountDue;

      const payload: ISharedInvoicePaymentReq = {
        amount,
        paymentToken,
        paymentMethodName: requestData.billingDetails.paymentMethodName,
        billingDetails: {
          fullName: requestData.billingDetails.fullName,
          email: requestData.billingDetails.email,
        },
      };
      await createPaymentMethodWithoutPayment({
        body: payload,
        invoiceSecretId,
        tenantId,
      });
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setSharedInvoicePayment(null);
    }
  };

  return {
    elements,
    stripe,
    requiresVerification,
    isSavePaymentLoading,
    stripeLoading,
    handleFormSubmit,
  };
};
