import {
  adjustTimeZoneOffsetWithUtc,
  getFormattedDate,
  isValidDate,
  parseDateAsUtc,
  TIMEZONE_CONFIG,
} from '@monetize/utils/core';
import { clamp } from 'date-fns/clamp';
import { isSameDay } from 'date-fns/isSameDay';
import { useCallback, useEffect, useState } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  doAmendContract,
  doGetContract,
  doRenewContract,
  useCancelContract,
} from '../../api/cpqService';
import AppLoading from '../../components/App/AppLoading';
import {
  MAlert,
  MBox,
  MFlex,
  MPageContainer,
  MText,
} from '../../components/Monetize';
import { getQuoteEditRoute, SALES_LANDING } from '../../constants/routes';
import { useAuth } from '../../services/auth0';
import { logger } from '../../services/logger';
import {
  ContractCancelTypeEnum,
  ContractCancelTypeEnumZ,
  IContractWithQuotes,
  IOpportunity,
  Maybe,
} from '../../types';
import {
  canAmendContract,
  canCancelContract,
  canRenewContract,
} from '../../utils/contracts';

type ContractAction = 'amend' | 'renew' | 'cancel';
const contractActions = new Set<ContractAction>(['amend', 'renew', 'cancel']);

const ERROR_INVALID_CONTRACT_ID = 'The provided contract id is invalid.';
const ERROR_INVALID_CONTRACT_STATUS_AMEND =
  'The provided contract is not eligible to be amended.';
const ERROR_INVALID_CONTRACT_STATUS_RENEW =
  'The provided contract is not eligible to be renewed.';
const ERROR_INVALID_CONTRACT_STATUS_CANCEL =
  'The provided contract is not eligible to be cancelled.';
const ERROR_INVALID_ACTION = 'The provided action is invalid.';
const ERROR_UNKNOWN = 'There was a problem processing your request.';

// Confirms that the params are in a valid format and that the contract is eligible for the action.
async function isValid(
  contractId: string,
  action: ContractAction,
): Promise<{ contract: IContractWithQuotes } | { error: string }> {
  if (!contractActions.has(action)) {
    return { error: ERROR_INVALID_ACTION };
  }

  // Confirm contract exists and has a correct status before attempting to take action
  try {
    const contract = await doGetContract(contractId);
    if (action === 'amend' && !canAmendContract(contract)) {
      return { error: ERROR_INVALID_CONTRACT_STATUS_AMEND };
    } else if (action === 'renew' && !canRenewContract(contract)) {
      return { error: ERROR_INVALID_CONTRACT_STATUS_RENEW };
    } else if (action === 'cancel' && !canCancelContract(contract)) {
      return { error: ERROR_INVALID_CONTRACT_STATUS_CANCEL };
    }
    return { contract };
  } catch (ex) {
    logger.warn('[ERROR] looking up contract', ex);
    return { error: ERROR_INVALID_CONTRACT_ID };
  }
}

export function getCancellationDate(
  contract: IContractWithQuotes,
  {
    cancellationTypeParam,
    cancellationDateParam,
  }: {
    cancellationTypeParam?: Maybe<string>;
    cancellationDateParam?: Maybe<string>;
  },
) {
  const cancellationType = ContractCancelTypeEnumZ.optional()
    .default(ContractCancelTypeEnum.ENTIRE_CONTRACT)
    .parse(cancellationTypeParam);

  let cancellationDate = new Date();

  const contractStartDate = parseDateAsUtc(contract.startDate);
  const contractEndDate = parseDateAsUtc(contract.endDate);

  if (cancellationType === ContractCancelTypeEnum.ENTIRE_CONTRACT) {
    cancellationDate = contractStartDate;
  } else if (
    cancellationType === ContractCancelTypeEnum.SPECIFIC_DATE &&
    cancellationDateParam
  ) {
    cancellationDate = parseDateAsUtc(cancellationDateParam);
    if (!isValidDate(cancellationDate)) {
      cancellationDate = adjustTimeZoneOffsetWithUtc(new Date());
    }
  }

  // Ensure that cancellation date is within the contract period
  cancellationDate = clamp(cancellationDate, {
    start: contractStartDate,
    end: contractEndDate,
  });

  // Returning string to avoid any potential timezone issues
  if (isSameDay(cancellationDate, contractStartDate)) {
    return contract.startDate;
  }

  if (isSameDay(cancellationDate, contractEndDate)) {
    return contract.endDate;
  }

  return getFormattedDate(cancellationDate, 'yyyy-MM-dd', TIMEZONE_CONFIG.UTC);
}

/**
 * This component produces a page that amends or renews a contract or shows an error message.
 * Once completed, the user will be redirected to the newly created quote, and this page removed from history.
 */
export const CrmAmendCancelRenewContract = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { userId } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const { mutateAsync: doCancelContract } = useCancelContract();

  const cancellationTypeParam = searchParams.get('cancellationType');
  const cancellationDateParam = searchParams.get('cancellationDate');

  const handleContractAction = useCallback(
    async (
      contract: IContractWithQuotes,
      action: ContractAction,
      opportunity?: Partial<Pick<IOpportunity, 'id' | 'name' | 'customId'>>,
    ) => {
      switch (action) {
        case 'amend':
          return doAmendContract(contract.id, opportunity);
        case 'renew':
          return doRenewContract(contract.id, { opportunity });
        case 'cancel': {
          const amendmentEffectiveDate = getCancellationDate(contract, {
            cancellationTypeParam,
            cancellationDateParam,
          });

          return await doCancelContract({
            contractId: contract.id,
            amendmentEffectiveDate,
            ownerId: userId,
          });
        }
        default:
          throw new Error('Invalid action');
      }
    },
    [cancellationDateParam, cancellationTypeParam, doCancelContract, userId],
  );

  const amendOrRenewOrCancelContract = useCallback(
    async (
      contractId: string,
      action: ContractAction,
      opportunity?: Partial<Pick<IOpportunity, 'id' | 'name' | 'customId'>>,
    ) => {
      try {
        setErrorMessage(null);
        setIsLoading(true);

        if (!contractId || !contractId.startsWith('ctrct')) {
          setErrorMessage(ERROR_INVALID_CONTRACT_ID);
          setIsLoading(false);
          return;
        }

        const response = await isValid(contractId, action);

        if ('error' in response) {
          setErrorMessage(response.error);
          setIsLoading(false);
          return;
        }

        setLoadingMessage(`Your contract is being ${action}ed.`);

        const quote = await handleContractAction(
          response.contract,
          action,
          opportunity,
        );

        quote?.id &&
          navigate(
            {
              pathname: getQuoteEditRoute(quote.id),
            },
            { replace: true },
          );
      } catch (ex) {
        logger.warn(
          'Error amending or renewing contract',
          contractId,
          action,
          ex,
        );
        setErrorMessage(ERROR_UNKNOWN);
        setIsLoading(false);
      }
    },
    [handleContractAction, navigate],
  );

  useEffect(() => {
    if (params?.contractId && params?.action) {
      const opportunityCustomId = searchParams.get('opportunityCustomId');
      const opportunityName = searchParams.get('opportunityName');
      amendOrRenewOrCancelContract(
        params.contractId,
        params.action as ContractAction,
        opportunityCustomId && opportunityName
          ? {
              customId: opportunityCustomId,
              name: opportunityName,
            }
          : undefined,
      );
    }
  }, [
    params.contractId,
    params.action,
    searchParams,
    amendOrRenewOrCancelContract,
  ]);

  return (
    <MPageContainer>
      {isLoading && (
        <AppLoading>
          <MText my="4" fontSize="sm">
            {loadingMessage}
          </MText>
        </AppLoading>
      )}
      {errorMessage && (
        <MAlert type="error" title="Error">
          <MFlex direction="column">
            <MText>{errorMessage}</MText>
            <MBox>
              <Link to={SALES_LANDING}>
                <MText mt={2} textDecor="underline" fontSize="sm">
                  Go to Sales Dashboard
                </MText>
              </Link>
            </MBox>
          </MFlex>
        </MAlert>
      )}
    </MPageContainer>
  );
};
