import React from 'react';
import { BsPeople, BsPeopleFill } from 'react-icons/bs';
import {
  MBox,
  MCustomIconButton,
  MTooltip,
} from '../../../../components/Monetize';

interface OneTimeInvoiceContactIconProps {
  selectedShippingContactId?: string;
  onOpen: () => void;
}

export const OneTimeInvoiceContactIcon = React.forwardRef(
  (
    { onOpen, selectedShippingContactId }: OneTimeInvoiceContactIconProps,
    ref,
  ) => {
    return (
      <MBox position="relative">
        <MTooltip label="Contacts" placement="bottom-end">
          <MBox>
            <MCustomIconButton
              variant="icon"
              btnSize={6}
              aria-label="Signature Contacts"
              p={4}
              iconColor={selectedShippingContactId ? 'tIndigo.base' : 'initial'}
              icon={selectedShippingContactId ? BsPeopleFill : BsPeople}
              onClick={() => onOpen()}
            />
          </MBox>
        </MTooltip>
      </MBox>
    );
  },
);
