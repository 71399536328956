import {
  MCheckbox,
  MCustomNumberInput,
  MGridItem,
  MText,
} from '../../../../../components/Monetize';
import { useFlags } from '../../../../../services/launchDarkly';
import { ContextProduct } from '../../../../../types';

interface ProductQuantityTableRowProps {
  row: ContextProduct;
  onChange: (quantity: string, isSelected: boolean) => void;
}

export const ProductQuantityTableRow = ({
  row,
  onChange,
}: ProductQuantityTableRowProps) => {
  const { salesDemoMedinsightTempGuidedQuotingSpecialConfig } = useFlags();

  return (
    <>
      <MGridItem
        area={`${row.id}`}
        display="flex"
        alignItems="center"
        justifyContent="left"
        overflow="auto"
      >
        {salesDemoMedinsightTempGuidedQuotingSpecialConfig ? (
          <MCheckbox
            pl={1}
            mr={1}
            isChecked={row.isSelected}
            isDisabled={row.isMandatory}
            onChange={(value) => onChange(row.qty, !row.isSelected)}
          >
            {row.name}
          </MCheckbox>
        ) : (
          <MText>{row.name}</MText>
        )}
      </MGridItem>
      <MGridItem
        area={`${row.id}qty`}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {row.isSelected && (
          <MCustomNumberInput
            isDisabled={salesDemoMedinsightTempGuidedQuotingSpecialConfig?.lockQuantityEdit?.productIds?.includes(
              row.id,
            )}
            value={row.qty ?? ''}
            onChange={(newValue: any) => onChange(newValue, row.isSelected)}
            min={0}
            minW="auto"
            alignSelf="right"
          />
        )}
      </MGridItem>
    </>
  );
};
