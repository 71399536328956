import {
  CustomFieldEntityEnum,
  CustomFieldPermissionsEnum,
  CustomFieldPermissionsUiEnum,
  CustomFieldStatusEnum,
  CustomFieldTypeEnum,
  ICustomFieldMode,
} from '../types';

export const CUSTOM_FIELDS_PREFIX = 'customFields.';

export const CUSTOM_FIELD_STATUS_DISPLAY: {
  [key in CustomFieldStatusEnum]: string;
} = {
  ACTIVE: 'Active',
};

export const CUSTOM_FIELD_PERMISSION_DISPLAY: {
  [key in CustomFieldPermissionsEnum]: string;
} = {
  READ: 'Read',
  WRITE: 'Write',
};

export const CUSTOM_FIELD_PERMISSION_UI_DISPLAY: {
  [key in CustomFieldPermissionsUiEnum]: string;
} = {
  EDITABLE: 'Editable',
  READ_ONLY: 'Read Only',
  HIDDEN: 'Hidden',
};

export const CUSTOM_FIELD_TYPE_DISPLAY: {
  [key in CustomFieldTypeEnum]: string;
} = {
  CHECKBOX: 'Checkbox',
  DATE: 'Date',
  NUMBER: 'Number',
  SINGLE_LINE_TEXT: 'Single Line Text',
  DROPDOWN: 'Dropdown',
};

export const CUSTOM_FIELD_ENTITY_DISPLAY: {
  [key in CustomFieldEntityEnum]: string;
} = {
  ACCOUNT: 'Account',
  BILL_GROUP: 'Bill Group',
  CONTACT: 'Contact',
  CONTRACT: 'Contract',
  CREDIT: 'Credit',
  CREDIT_NOTE: 'Credit Note',
  INVOICE: 'Invoice',
  OFFERING: 'Offering',
  PRODUCT: 'Product',
  QUOTE: 'Quote',
};

export const CUSTOM_FIELD_DATA_FORM_SPACING = {
  modal: {
    dividerTop: 0,
    dividerBottom: 4,
    gridTop: 4,
    gridBottom: 4,
    gridGap: 4,
  },
  accordion: {
    dividerTop: 4,
    dividerBottom: 4,
    gridTop: 4,
    gridBottom: 0,
    gridGap: 4,
  },
  drawer: {
    dividerTop: 4,
    dividerBottom: 4,
    gridTop: 0,
    gridBottom: 4,
    gridGap: 4,
  },
  page: {
    dividerTop: 0,
    dividerBottom: 0,
    gridTop: 0,
    gridBottom: 0,
    gridGap: 0,
  },
};

export const CUSTOM_FORM_CONTENT_DIVIDER_VIEW_MODES = new Set<ICustomFieldMode>(
  ['drawer'],
);
