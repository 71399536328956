import { formatCurrency } from '@monetize/utils/core';
import type { ApexOptions } from 'apexcharts';
import { colors } from '../../../../../styles/theme';

const styles = `background: var(--chakra-colors-tPurple-base) !important;padding: 6px 8px;color: white;font-weight: bold;font-size: 10px;`;

const chartCustomTooltip = {
  custom: ({ series, seriesIndex, dataPointIndex, w }: any) =>
    `<div class="custom-tooltip" style="${styles}">
      <div>Units: ${series[seriesIndex][dataPointIndex]} ${
        w.globals.seriesNames[0]
      }</div>
      <div>Price: ${formatCurrency(
        w.config.series[seriesIndex]?.data[dataPointIndex]?.usage?.ratedAmount,
      )}</div>
    </div>`,
};

const commonChartOptions: ApexOptions = {
  chart: {
    width: '100%',
    height: '100%',
    selection: {
      enabled: false,
    },
    zoom: {
      enabled: false,
      autoScaleYaxis: true,
    },
    toolbar: {
      show: false,
    },
  },
  colors: [colors.tIndigo.base],
  fill: {
    colors: [colors.tIndigo.base],
  },
  plotOptions: {
    bar: {
      columnWidth: '20%',
      // columnHeight: '100%', // Not mentioned in type
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: undefined,
          },
        ],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    labels: {
      rotate: -45,
      rotateAlways: true,
      style: {
        colors: [colors.tPurple.base],
      },
    },
    axisTicks: {
      show: false,
    },
    tickPlacement: 'on',
  },
  yaxis: {
    show: true,
    labels: {
      show: true,
      formatter: (value: any) => value.toFixed(),
      style: {
        colors: [colors.tGray.lightPurple],
      },
    },
  },
  grid: {
    borderColor: colors.tGray.support,
    xaxis: {
      lines: {
        show: false,
      },
    },
  },
};

export const usageChartOptions: ApexOptions = {
  ...commonChartOptions,
  tooltip: {
    ...chartCustomTooltip,
    onDatasetHover: {
      highlightDataSeries: false,
    },
  },
};
