import { array, number, object, string, union, z } from 'zod';

export enum ReminderTypeEnum {
  FIRST_EMAIL = 'FIRST_EMAIL',
  SECOND_EMAIL = 'SECOND_EMAIL',
  LAST_EMAIL = 'LAST_EMAIL',
}
export const ReminderTypeEnumZ = z.nativeEnum(ReminderTypeEnum);

export const ReminderInterval = object({
  type: ReminderTypeEnumZ,
  days: union([string(), number()]),
});
export type ReminderIntervalSchema = z.infer<typeof ReminderInterval>;

export const QuoteExpirationReminderRes = array(ReminderInterval);
export type QuoteExpirationReminderSchema = z.infer<
  typeof QuoteExpirationReminderRes
>;

// we need a 'named' array to pass in to react-hook-form
export const QuoteExpirationReminderForm = object({
  quoteExpirationReminders: array(ReminderInterval),
});
