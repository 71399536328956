import {
  BaseResponseSchema,
  MCustomNumberTypeRequired,
} from '@monetize/types/common';
import { number, object, string } from 'zod';

export const QuoteItemCustomPriceSchema = object({
  id: string().nullish(),
  unitAmount: MCustomNumberTypeRequired('Price cannot be empty'),
});

export const SubscriptionItemCustomPriceSchema = BaseResponseSchema.extend({
  id: string(),
  amount: number(),
});
