import { Card, CardBody, FormLabel } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaStripe } from 'react-icons/fa';
import { MdCheck } from 'react-icons/md';
import { handleApiErrorToast } from '../../../../../api/axios';
import {
  useCreateOrUpdatePaymentGateway,
  useGetPaymentGatewayById,
  useVerifyStripeKey,
} from '../../../../../api/paymentsService';
import {
  MBox,
  MButton,
  MCenter,
  MCheckbox,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MHStack,
  MIcon,
  MInput,
  MPageLoader,
  MPasswordInput,
  MText,
} from '../../../../../components/Monetize';
import { PAYMENT_GATEWAY } from '../../../../../constants';
import { useCurrencies } from '../../../../../hooks/useCurrencies';
import { useFlags } from '../../../../../services/launchDarkly';
import { useToast } from '../../../../../services/toast';
import {
  IPaymentGateway,
  IPaymentGatewayReqUISchema,
  IPaymentMethodEnabledSchema,
  PaymentGatewayReqUISchema,
  PaymentGatewayStatusEnum,
  PaymentGatewayTypeEnum,
  PaymentMethodTypeEnum,
} from '../../../../../types';
import { initDefaultPaymentGatewayOptionsIfNotExists } from '../../../../../utils/payment.utils';
import { StripeFormAmountConfiguration } from './StripeFormAmountConfiguration';

interface StripeFormProps {
  gatewayId: string;
  resetFormVisible: () => void;
}

const StripeForm = ({ gatewayId, resetFormVisible }: StripeFormProps) => {
  const [isVerified, setIsVerified] = useState(false);
  const [showSecretFields, setShowSecretFields] = useState(!gatewayId);
  const { enableUsBankTransfer } = useFlags();
  const { currencies } = useCurrencies();

  const { addToast } = useToast();

  const { isLoading: loadingPaymentGateway, data: paymentGateway } =
    useGetPaymentGatewayById<IPaymentGatewayReqUISchema>(
      { id: gatewayId },
      {
        enabled: !!gatewayId,
        refetchOnWindowFocus: false,
        select: (gateway: IPaymentGateway) => {
          const formData: IPaymentGatewayReqUISchema = {
            ...pick(gateway, Object.keys(PaymentGatewayReqUISchema.shape)),
            creditCardEnabled: false,
            directDebitEnabled: false,
            achCreditEnabled: false,
            usBankTransferEnabled: false,
          } as IPaymentGatewayReqUISchema;

          formData.status = formData.status || PaymentGatewayStatusEnum.ACTIVE;
          formData.paymentConfiguration =
            initDefaultPaymentGatewayOptionsIfNotExists(
              currencies.map((currency) => currency.code),
              formData.paymentConfiguration,
            );

          gateway.allowedPaymentMethods?.forEach(
            (paymentMethod: IPaymentMethodEnabledSchema) => {
              if (paymentMethod.enabled) {
                if (
                  paymentMethod.method === PaymentMethodTypeEnum.CREDIT_CARD
                ) {
                  formData.creditCardEnabled = true;
                }
                if (
                  paymentMethod.method === PaymentMethodTypeEnum.DIRECT_DEBIT
                ) {
                  formData.directDebitEnabled = true;
                }
                if (paymentMethod.method === PaymentMethodTypeEnum.ACH_CREDIT) {
                  formData.achCreditEnabled = true;
                }
                if (
                  paymentMethod.method ===
                  PaymentMethodTypeEnum.US_BANK_TRANSFER
                ) {
                  formData.usBankTransferEnabled = true;
                }
              }
            },
          );

          if (
            !formData.creditCardEnabled &&
            !formData.directDebitEnabled &&
            !formData.achCreditEnabled &&
            !formData.usBankTransferEnabled
          ) {
            formData.creditCardEnabled = true;
          }

          return formData;
        },
      },
    );

  useEffect(() => {
    if (paymentGateway) {
      reset({ ...paymentGateway });
      setShowSecretFields(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentGateway]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, dirtyFields, isDirty },
    reset,
    watch,
    getValues,
  } = useForm<IPaymentGatewayReqUISchema>({
    resolver: zodResolver(PaymentGatewayReqUISchema),
    mode: 'onChange',
    defaultValues: paymentGateway
      ? {
          ...paymentGateway,
          paymentConfiguration: initDefaultPaymentGatewayOptionsIfNotExists(
            currencies.map((currency) => currency.code),
            paymentGateway.paymentConfiguration,
          ),
        }
      : {
          status: PaymentGatewayStatusEnum.ACTIVE,
          defaultGateway: true,
          creditCardEnabled: true,
          description: '',
          paymentConfiguration: initDefaultPaymentGatewayOptionsIfNotExists(
            currencies.map((currency) => currency.code),
          ),
        },
  });

  const doCreateOrUpdateStripe = useCreateOrUpdatePaymentGateway({
    onSuccess: (response, variables) => {
      resetFormVisible();
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const onSubmit = async (data: IPaymentGatewayReqUISchema) => {
    try {
      doCreateOrUpdateStripe.mutate({
        gatewayId,
        data: {
          stripeSecretKey: data.secretKey,
          stripePublishableKey: data.publicKey,
          description: data.description,
          status: data.status,
          defaultGateway: data.defaultGateway,
          type: PaymentGatewayTypeEnum.STRIPEV2,
          paymentConfiguration: data.paymentConfiguration,
          allowedPaymentMethods: [
            {
              method: PaymentMethodTypeEnum.CREDIT_CARD,
              enabled: !!data.creditCardEnabled,
            },
            {
              method: PaymentMethodTypeEnum.DIRECT_DEBIT,
              enabled: !!data.directDebitEnabled,
            },
            {
              method: PaymentMethodTypeEnum.ACH_CREDIT,
              enabled: !!data.achCreditEnabled,
            },
            {
              method: PaymentMethodTypeEnum.US_BANK_TRANSFER,
              enabled: !!data.usBankTransferEnabled,
            },
          ],
        },
      });
    } catch (error) {
      addToast({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed',
      });
    }
  };

  const doVerifyStripeKey = useVerifyStripeKey({
    onSuccess: (response: any) => {
      if (response.isValid) {
        setIsVerified(true);
      } else {
        setIsVerified(false);
        addToast({
          severity: 'error',
          summary: 'Failed',
          detail: response.message || 'Key is not correct',
        });
      }
    },
    onError: (error) => {
      setIsVerified(false);
      handleApiErrorToast(error);
    },
  });

  const onVerify = async () => {
    let secretKey: string | undefined = undefined;
    if (dirtyFields.secretKey) {
      secretKey = getValues('secretKey');
    }
    doVerifyStripeKey.mutate({
      paymentGatewayId: gatewayId,
      stripeSecretKey: secretKey,
    });
  };

  const secretKey = watch('secretKey');

  const isTestConnectionEnabled =
    (dirtyFields.secretKey && secretKey) || gatewayId;

  // For existing connections, as long as secret key is not changed, we can save
  const isSaveEnabled =
    !doCreateOrUpdateStripe.isPending &&
    ((dirtyFields.secretKey && isVerified && isValid && isDirty) ||
      (gatewayId && !dirtyFields.secretKey && isValid && isDirty));

  if (loadingPaymentGateway) {
    return <MPageLoader />;
  }

  return (
    <Card mt="2" variant="edit" maxW="37.5rem">
      <CardBody>
        <MGrid templateColumns="1fr 1fr">
          <MGridItem>
            <MFlex>
              <MHStack>
                <MCenter
                  w="12"
                  h="12"
                  bg="tWhite.base"
                  borderColor="tGray.lightPurple"
                  fontSize="lg"
                  fontWeight="bold"
                  borderRadius={2}
                >
                  <MIcon as={FaStripe} color="tBlue.bSweet" w="70%" h="100%" />
                </MCenter>
              </MHStack>
              <MFlex ml={2} flexDirection="column" alignSelf={'center'}>
                <MText fontSize="md">Stripe</MText>
              </MFlex>
            </MFlex>
          </MGridItem>

          <MGridItem colSpan={2}>
            <MDivider />
          </MGridItem>
        </MGrid>

        <form onSubmit={handleSubmit(onSubmit)}>
          <MGrid
            templateColumns="repeat(12, 1fr)"
            mt="4"
            rowGap="3.5"
            columnGap="4"
            alignItems="center"
          >
            <MGridItem colSpan={4}>
              <MFormField error={errors.description} label="Name">
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field: { value, ...rest } }) => (
                    <MInput value={value || ''} {...rest} />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={4}>
              <MFormField error={errors.status} label="Status" isRequired>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      itemTitle="name"
                      itemValue="value"
                      items={PAYMENT_GATEWAY.PAYMENT_GATEWAY_STATUS}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>

            <MGridItem colSpan={12}>
              <MBox as="fieldset">
                <FormLabel
                  as="legend"
                  mb={-7}
                  display="inline-block"
                  htmlFor="creditCardEnabled,directDebitEnabled,achCreditEnabled"
                >
                  <MFlex>
                    <MText>Payment Method Support</MText>
                    <MText color="tRed.cinnabar" ml={2}>
                      *
                    </MText>
                  </MFlex>
                </FormLabel>
                <MFlex gap={4}>
                  <MFormField
                    error={errors.creditCardEnabled}
                    skipLabel
                    id="creditCardEnabled"
                    w="unset"
                  >
                    <Controller
                      name="creditCardEnabled"
                      control={control}
                      render={({ field: { value, ...rest } }) => (
                        <MCheckbox
                          isChecked={value as any}
                          {...rest}
                          iconColor="tWhite.base"
                          spacing="5"
                          fontWeight="normal"
                        >
                          Credit Card
                        </MCheckbox>
                      )}
                    />
                  </MFormField>

                  <MFormField
                    error={errors.directDebitEnabled}
                    skipLabel
                    id="directDebitEnabled"
                    w="unset"
                  >
                    <Controller
                      name="directDebitEnabled"
                      control={control}
                      render={({ field: { value, ...rest } }) => (
                        <MCheckbox
                          isChecked={value as any}
                          {...rest}
                          iconColor="tWhite.base"
                          spacing="5"
                          fontWeight="normal"
                        >
                          ACH Direct Debit
                        </MCheckbox>
                      )}
                    />
                  </MFormField>
                  <MFormField
                    error={errors.achCreditEnabled}
                    skipLabel
                    id="achCreditEnabled"
                    w="unset"
                  >
                    <Controller
                      name="achCreditEnabled"
                      control={control}
                      render={({ field: { value, ...rest } }) => (
                        <MCheckbox
                          isChecked={value as any}
                          {...rest}
                          iconColor="tWhite.base"
                          spacing="5"
                          fontWeight="normal"
                        >
                          ACH Credit Transfer
                        </MCheckbox>
                      )}
                    />
                  </MFormField>
                  {enableUsBankTransfer && (
                    <MFormField
                      error={errors.usBankTransferEnabled}
                      skipLabel
                      id="usBankTransferEnabled"
                      w="unset"
                    >
                      <Controller
                        name="usBankTransferEnabled"
                        control={control}
                        render={({ field: { value, ...rest } }) => (
                          <MCheckbox
                            isChecked={value as any}
                            {...rest}
                            iconColor="tWhite.base"
                            spacing="5"
                            fontWeight="normal"
                          >
                            US Bank Transfer
                          </MCheckbox>
                        )}
                      />
                    </MFormField>
                  )}
                </MFlex>
              </MBox>
            </MGridItem>

            {enableUsBankTransfer && (
              <MGridItem colSpan={12}>
                <StripeFormAmountConfiguration
                  control={control}
                  errors={errors}
                  watch={watch}
                />
              </MGridItem>
            )}

            <MGridItem colSpan={12}>
              <MText fontSize="lg" fontWeight="bold" mt={2} mb={2}>
                Keys
              </MText>
              <MBox position="relative">
                {!showSecretFields && (
                  <MBox
                    position="absolute"
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    background="rgba(255, 255, 255, 0.91)"
                    zIndex="1"
                  >
                    <MButton
                      variant="ghost"
                      color="tIndigo.base"
                      onClick={() => setShowSecretFields(true)}
                    >
                      Click to Edit
                    </MButton>
                  </MBox>
                )}
                <MGrid
                  templateColumns="1fr 1fr .65fr"
                  rowGap="0"
                  columnGap="2"
                  alignItems="center"
                >
                  <MFormField
                    error={errors.secretKey}
                    label="Secret Key"
                    isRequired={!gatewayId}
                  >
                    <Controller
                      name="secretKey"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, ...rest } }) => (
                        <MPasswordInput
                          placeholder={gatewayId ? '********' : 'sk_...'}
                          {...rest}
                          onChange={(e: any) => {
                            onChange(e);
                            setIsVerified(false);
                          }}
                        />
                      )}
                    />
                  </MFormField>

                  <MFormField
                    error={errors.publicKey}
                    label="Publishable Key"
                    isRequired
                  >
                    <Controller
                      name="publicKey"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <MInput placeholder="pk_..." {...field} />
                      )}
                    />
                  </MFormField>

                  <MFlex alignSelf="end">
                    {isVerified ? (
                      <MFlex
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        pb="1"
                      >
                        <MIcon as={MdCheck} color="tGreen.mDarkShade" />
                        <MText
                          color="tGreen.mDarkShade"
                          fontWeight="bold"
                          ml={1}
                        >
                          Success!
                        </MText>
                      </MFlex>
                    ) : (
                      <MFlex
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        height="100%"
                      >
                        <MButton
                          variant="tertiary"
                          isLoading={doVerifyStripeKey.isPending}
                          isDisabled={
                            doVerifyStripeKey.isPending ||
                            loadingPaymentGateway ||
                            !isTestConnectionEnabled
                          }
                          onClick={onVerify}
                        >
                          Test Connection
                        </MButton>
                      </MFlex>
                    )}
                  </MFlex>
                </MGrid>
              </MBox>
            </MGridItem>
          </MGrid>

          <MGridItem colSpan={12}>
            <MDivider my={4} />
          </MGridItem>
          <MGridItem>
            <MFlex justify="flex-end" gap={4}>
              <MButton
                variant="cancel"
                isDisabled={doCreateOrUpdateStripe.isPending}
                onClick={resetFormVisible}
              >
                Cancel
              </MButton>
              <MButton
                type="submit"
                variant="primary"
                isDisabled={!isSaveEnabled}
                isLoading={doCreateOrUpdateStripe.isPending}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </MButton>
            </MFlex>
          </MGridItem>
        </form>
      </CardBody>
    </Card>
  );
};
export default StripeForm;
