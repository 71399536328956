import React, { FC, useEffect, useMemo, useState } from 'react';
import { formatNumberWithCommas } from '../../api/utils';
import { DEFAULT_QUOTE_COLORS } from '../../constants/quotes';
import { QuantityText } from '../../routes/Quotes/Quote/components/QuantityText';
import { useQuoteContext } from '../../routes/Quotes/Quote/quoteContext';
import { useFlags } from '../../services/launchDarkly';
import {
  IPriceUnderProductSchemaUI,
  IQuoteItem,
  IQuoteRespSchema,
  QuoteAmendmentVersionEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '../../types';
import { getQuoteColors, isQuoteQtyChanged } from '../../utils';
import { MCustomSelect, MGrid } from '../Monetize';

interface QuantityDropdownProps {
  index: number;
  quote: IQuoteRespSchema | null;
  item: IQuoteItem;
  value: number;
  isScheduledChange: boolean;
  scheduledChangePriorQty?: number;
  isAmendment: boolean;
  isRenewal: boolean;
  placeholder: string;
  isRemoved?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  orderedPricesUnderProduct: IPriceUnderProductSchemaUI[];
  onChange: (valueAsString: string) => void;
}

export const QuantityDropdown: FC<QuantityDropdownProps> = React.forwardRef<
  any,
  QuantityDropdownProps
>(
  (
    {
      index,
      item,
      quote,
      isScheduledChange,
      scheduledChangePriorQty,
      isAmendment,
      isRenewal,
      value,
      isRemoved,
      isDisabled,
      orderedPricesUnderProduct,
      placeholder,
      isReadOnly,
      onChange,
    }: QuantityDropdownProps,
    ref: any,
  ) => {
    const { showCommaFormattedQty } = useFlags();
    const isNew = !isAmendment && !isRenewal;
    const isDraft = quote?.status === QuoteStatusEnum.DRAFT;
    // For amend v2 and new quotes, start in edit mode by default.
    const shouldEditByDefault =
      (!isDisabled &&
        quote?.amendmentVersion === QuoteAmendmentVersionEnum.v2) ||
      isNew;
    const [editMode, setEditMode] = useState<boolean>(shouldEditByDefault);
    const [focused, setFocused] = useState<boolean>(shouldEditByDefault);
    const [formattedQuantity, setFormattedQuantity] = useState('');

    useEffect(() => {
      if (value && showCommaFormattedQty) {
        setFormattedQuantity(formatNumberWithCommas(value));
      }
    }, [value, showCommaFormattedQty]);

    useEffect(() => {
      if (shouldEditByDefault) {
        setEditMode(true);
        setFocused(true);
      } else {
        setEditMode(false);
        setFocused(false);
      }
    }, [shouldEditByDefault]);

    const checkQtyChanged = isQuoteQtyChanged(
      item,
      Number(value),
      isScheduledChange
        ? scheduledChangePriorQty
        : (item.previousQuantity ?? 0),
    );
    const quoteType = quote?.type || QuoteTypeEnum.NEW;
    const { useAmendmentV2 } = useQuoteContext();
    const quoteColors =
      checkQtyChanged && !useAmendmentV2
        ? getQuoteColors(quoteType)
        : DEFAULT_QUOTE_COLORS;
    const styleProps = {
      fontWeight: '400',
      minWidth: `min(calc(${String(value || '').length}ch + 1.2rem), 7rem)`,
      onClick: (ev: React.MouseEvent<HTMLInputElement>) => {
        const inputElement = ev.currentTarget;
        if (inputElement.value === '0') {
          inputElement.select();
        }
      },
      ...quoteColors,
    };

    const tierOptions = useMemo(() => {
      const product = orderedPricesUnderProduct.find(
        (prod) => prod.id === item.productId,
      );
      if (product?.prices) {
        return product.prices.map((price) => ({
          title: showCommaFormattedQty
            ? formatNumberWithCommas(price?.to ?? '')
            : price?.to?.toString(),
          value: price.to,
        }));
      }
      return [];
    }, [orderedPricesUnderProduct, item.productId, showCommaFormattedQty]);

    return (
      <MGrid
        onMouseEnter={() =>
          !isRemoved && !isNew && isDraft && !isDisabled && setEditMode(true)
        }
        onMouseLeave={() =>
          !isRemoved && !isNew && isDraft && !isDisabled && setEditMode(false)
        }
        onMouseDown={() =>
          !isRemoved && !isNew && isDraft && !isDisabled && setFocused(true)
        }
        justifyContent="center"
        alignItems="center"
        data-testid={`quantity-wrap-${index}`}
      >
        {focused || editMode ? (
          <MCustomSelect
            isLazy
            ref={ref}
            items={tierOptions}
            itemTitle="title"
            itemValue="value"
            value={value}
            placeholder={placeholder}
            isDisabled={isRemoved || isDisabled}
            inputProps={styleProps}
            onChange={onChange}
          />
        ) : (
          <QuantityText
            value={showCommaFormattedQty ? formattedQuantity : value}
            quote={quote}
            item={item}
            scheduledChangePriorQty={scheduledChangePriorQty}
            type={quoteType}
            isScheduledChange={isScheduledChange}
            isRemoved={isRemoved}
            hideDifference={useAmendmentV2}
          />
        )}
      </MGrid>
    );
  },
);
