import { boolean, object, string, z } from 'zod';
import { getRequiredMessage } from '../utils/messages';
import { AddressSchema } from './addressTypes';
import { UseEntityDataResponseType } from './componentTypes';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { BaseResponseSchema } from './miscTypes';

export type ContactTypeExternal = 'external';
export type ContactTypeInternal = 'internal';
export type ContactType = ContactTypeExternal | ContactTypeInternal;

export enum ContactStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}
export const ContactStatusEnumZ = z.nativeEnum(ContactStatusEnum);

// from /api/accounts/{accountId}/contacts and /api/quotes/{quoteId}->.contact[type]
export const ContactRequestSchema = z.object({
  snapshotId: string().nullish(),
  fullName: string().max(60).nonempty(getRequiredMessage('Full Name')),
  email: string().max(70).email().nonempty(getRequiredMessage('Email')),
  phone: string().max(50).nullish(),
  address: AddressSchema.nullish(),
  customId: string().nullish(),
  status: ContactStatusEnumZ,
  title: string().max(40).nullish(),
  internal: boolean().optional(),
  primary: boolean().optional(),
  esigner: boolean().optional(),
  defaultESigner: boolean().optional(),
  customFields: CustomFieldRecordSchema,
});
export type IContactRequestSchema = z.infer<typeof ContactRequestSchema>;

/** @deprecated TODO: remove  */
export const ESignContactsFormSchema = object({
  // this is a temporary measure as the feature will be changing again soon
  fullNameInt: string().nonempty(getRequiredMessage('Name')),
  emailInt: string().email().nonempty(getRequiredMessage('Email')),
  titleInt: string().optional(),
  fullNameExt: string().nonempty(getRequiredMessage('Name')),
  emailExt: string().email().nonempty(getRequiredMessage('Email')),
  titleExt: string().optional(),
});

/** @deprecated TODO: remove */
export type IeSignContactRequestSchema = z.infer<
  typeof ESignContactsFormSchema
>;

// from /api/accounts/{accountId}/contacts
export const ContactRespSchema = BaseResponseSchema.extend({
  snapshotId: string().nullish(),
  accountId: string(),
  address: AddressSchema,
  customId: string().nullable(),
  email: string(),
  fullName: string(),
  internal: boolean(),
  phone: string().nullable(),
  primary: boolean(),
  status: ContactStatusEnumZ,
  title: string().nullable(),
  esigner: boolean().optional(),
  defaultESigner: boolean().optional(),
  customFields: CustomFieldRecordSchema,
});
export type IContactRespSchema = z.infer<typeof ContactRespSchema>;

export type ContactDataType = UseEntityDataResponseType<IContactRespSchema> & {
  selected: any;
  setSelected: any;
  openCreateModal: any;
  openEditModal: any;
};

export const QuoteContactsRequest = object({
  esignCcIds: string().array(),
  billingId: string().nullish(),
  esignIds: string().array(),
  primaryId: string().nullish(),
});

export type IQuoteContactsRequest = z.infer<typeof QuoteContactsRequest>;

export const QuoteContacts = object({
  additionalESignRecipients: ContactRespSchema.array(),
  billing: ContactRespSchema.nullable(),
  esign: ContactRespSchema.array(),
  primary: ContactRespSchema.nullable(),
});

export type IQuoteContacts = z.infer<typeof QuoteContacts>;

export type ContactTypes = keyof Omit<
  IQuoteContacts,
  'additionalESignRecipients'
>;
/**
 * Used for quote contacts
 */
export interface ContactWithTypes {
  contact: IContactRespSchema;
  // name: string; // Used for sorting
  primary: boolean;
  billing: boolean;
  esign: boolean;
}

/**
 * Used for onetime invoices
 */
export type ContactWithoutEsignTypes = Omit<ContactWithTypes, 'esign'>;

/**
 * Used for processed address
 */
export interface ContactFormattedAddress {
  addressLine1: string;
  addressLine2: string;
  /** City, State Zip */
  otherAddress: string;
  country: string;
  /** City, State Zip, Country */
  cityStatePCCountry: string;
  /** lin1, line2, cityStatePCCountry */
  fullAddress: string;
}
