import { useDisclosure } from '@chakra-ui/react';
import { toDateShort } from '@monetize/utils/core';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetAccountById } from '../../../../api/accountsService';
import { useGetById } from '../../../../api/queryUtils';
import {
  MBox,
  MFlex,
  MPageContainer,
  MPageHeader,
} from '../../../../components/Monetize';
import { MEntityStatusBadge } from '../../../../components/Monetize/MEntityStatusBadge';
import { ROUTES } from '../../../../constants';
import { CreditStatusEnumDisplay } from '../../../../constants/credits';
import { PAPER_PAGE_MAX_WIDTH } from '../../../../constants/quotes';
import { getAccountCreditsRoute } from '../../../../constants/routes';
import {
  CreditStatusEnum,
  CustomFieldEntityEnum,
  ICustomFieldRecordSchema,
  IGetCreditSchema,
  TransactableSourceType,
} from '../../../../types';
import { CreditActions } from '../CreditActions';
import { CreditAdditionalFieldsBanner } from '../CreditAdditionalFieldsBanner';
import { CreditEditModal } from './CreditFormEditModal';
import { PreviewCreditPdfDrawer } from './PreviewCreditPdfDrawer';
import { ViewCreditHtml } from './ViewCreditHtml';

export const ViewCredit = () => {
  const navigate = useNavigate();
  const { creditId, accountId = '' } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isLoading,
    data: credit,
    isError,
  } = useGetById<IGetCreditSchema>('accountCredits', creditId!, {
    enabled: !!creditId,
    meta: { showErrorToast: true },
  });

  const { data: accountDetails, isError: isAccountByIdError } =
    useGetAccountById(accountId, {
      enabled: !!accountId,
    });

  useEffect(() => {
    if (isError && accountId) {
      navigate(ROUTES.getAccountCreditsRoute(accountId));
    } else if (isAccountByIdError && accountId) {
      navigate(ROUTES.getAccountCreditsRoute(accountId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, isError]);

  const [customFields, setCustomFields] = useState<ICustomFieldRecordSchema>(
    credit?.customFields ?? {},
  );

  useEffect(() => {
    if (accountId && credit?.creditNoteId) {
      navigate(
        ROUTES.getAccountCreditNoteViewRoute(accountId, credit?.creditNoteId),
        { replace: true },
      );
    }
  }, [accountId, credit?.creditNoteId, navigate]);

  const additionalValues = useMemo<
    {
      label: string;
      value: string;
    }[]
  >(() => {
    const output: { label: string; value: string }[] = [];
    if (credit) {
      output.push({
        label: 'Name',
        value: credit.name!,
      });
      if (credit.expirationDate) {
        output.push({
          label: 'Expiration Date',
          value: toDateShort(credit.expirationDate),
        });
      }
    }
    return output;
  }, [credit]);

  const handleBackNavigation = () => {
    navigate(ROUTES.getAccountCreditsRoute(credit?.accountId!));
  };

  useEffect(() => {
    if (credit?.customFields) {
      setCustomFields(credit?.customFields);
    }
  }, [credit?.customFields]);

  const handleOnCloseModal = (
    savedCustomFieldData: ICustomFieldRecordSchema,
  ) => {
    setCustomFields(savedCustomFieldData);
    onClose();
  };

  const isDetailBannerEditable =
    credit?.status !== CreditStatusEnum.VOID ||
    Object.keys(customFields).length > 0;

  return (
    <MPageContainer height="auto">
      <MPageHeader
        hasBackButton
        backButtonCallback={handleBackNavigation}
        title="View Credit"
        parentLink={getAccountCreditsRoute(credit ? credit?.accountId : '')}
        parentLinkTitle={credit && credit.accountSummary.accountName}
        copyUrl
        id={credit?.id}
        tag={
          credit && (
            <MEntityStatusBadge
              status={CreditStatusEnumDisplay[credit.status]}
            />
          )
        }
      >
        <MFlex
          alignContent="center"
          alignItems="center"
          columnGap={3}
          display={['none', 'none', 'flex']}
        >
          {!!credit?.id && <PreviewCreditPdfDrawer creditId={credit?.id} />}
          {!!credit?.id && (
            <CreditActions
              sourceType={TransactableSourceType.credit}
              accountId={credit.accountId}
              applicationsSourceId={credit.id}
              amount={credit.amount}
              appliedAmount={credit.amountApplied}
              currency={credit.currency}
              billGroupId={credit.billGroupId}
              creditStatus={credit.status}
              creditNoteId={credit.creditNoteId}
            />
          )}
        </MFlex>
      </MPageHeader>

      <MBox flex="1" pl="10" w="full" pb={8}>
        <MBox mx="auto" mt={2.5} maxW={PAPER_PAGE_MAX_WIDTH} w="full" mb="2">
          <CreditAdditionalFieldsBanner
            billGroupId={credit?.billGroupId}
            isLoading={isLoading}
            customFields={customFields}
            additionalValues={additionalValues}
            reason={credit?.reason || ''}
            isEditable={isDetailBannerEditable}
            onOpen={onOpen}
            voidReason={credit?.voidReason ?? ''}
            creditType={CustomFieldEntityEnum.CREDIT}
          />
          <MBox
            w="full"
            mt={6}
            borderWidth={0.5}
            borderColor="tBlue.hover"
            background="tWhite.titanWhite"
          >
            {!!credit && (
              <ViewCreditHtml
                creditId={credit?.id}
                modifyDate={credit?.modifyDate}
              />
            )}
          </MBox>
          {isOpen && accountDetails && credit && (
            <CreditEditModal
              account={accountDetails}
              credit={credit}
              onClose={onClose}
              onSave={handleOnCloseModal}
            />
          )}
        </MBox>
      </MBox>
    </MPageContainer>
  );
};
