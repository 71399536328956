import { z } from 'zod';
import {
  AmountUnitTypeEnum,
  ProductTypeEnum,
  QuoteTypeEnum,
  RateBillingFrequencyEnum,
} from './enums.types';
import { IQuotePrice } from './quote.types';
import { RateUsageBillingFrequencyEnum } from './rate.types';

export type Maybe<T> = T | null | undefined;

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

// export type MonetizeNowOperator =
//   | 'equal'
//   | 'notEqual'
//   | 'in'
//   | 'notIn'
//   | 'contains'
//   | 'doesNotContain'
//   | 'lessThan'
//   | 'lessThanInclusive'
//   | 'greaterThan'
//   | 'greaterThanInclusive';

export const BaseResponseSchema = z.object({
  createdBy: z.string(),
  createDate: z.string(),
  lastModifiedBy: z.string(),
  modifyDate: z.string(),
  id: z.string(),
});
export type IBaseResponse = z.infer<typeof BaseResponseSchema>;

export const MCustomNumberTypeNullish = z
  .union([z.string().nullish(), z.number().nullish()])
  .transform((val: any) => (val === null ? null : Number(val)));

/**
 * We don't want to be too strict around callers' JSON encoding behavior, so we'll treat
 * nulls and undefined as equivalent.
 */
export type Nullish<T> = T | null | undefined;
export function isNullish<A>(value: Nullish<A>): value is null | undefined {
  return value === null || value === undefined;
}
export function isDefined<A>(value: Nullish<A>): value is A {
  return !isNullish(value);
}

// This is the minimum types required for helper functions to work.
// TODO: figure out strategy for sharing types with FE in a way that is easy to maintain
export type Product = { [key: string]: unknown } & {
  id: string;
  name: string;
  productType: string;
};

export type Quote = { [key: string]: unknown } & {
  type: string;
  quoteOfferings: QuoteOffering[];
  quoteOfferingPrices?: IQuotePrice[];
};

export type QuoteOffering = { [key: string]: unknown } & {
  id: string;
  parentQuoteOfferingId?: string;
  offeringName: string;
  rateName: string;
  rateId: string;
  startDate: string;
  endDate: string;
  items: QuoteItem[];
};

export type Discount = {
  discountAmount: number | null;
  discountType: AmountUnitTypeEnum;
};

export type QuoteItem = { [key: string]: unknown } & {
  id: string;
  amendmentStatus?: string;
  amount: number;
  productId: string;
  productType: ProductTypeEnum;
  productName: string;
  previousQuantity: number | null;
  quantity: number;
  debitProrationMultiplier: number;
  debit: number;
  creditProrationMultiplier: number;
  credit: number;
  customDiscountAmountOrPercent: number | null;
  customDiscountType: AmountUnitTypeEnum;
  discountPercent: number | null;
  discountAmount: number | null;
  discounts: Discount[];
};

export type Rate = { [key: string]: unknown } & {
  id: string;
  billingFrequency: RateBillingFrequencyEnum;
  usageBillingFrequency?: RateUsageBillingFrequencyEnum;
};

export const PRODUCT_TYPES_SUPPORTING_PRORATION: ProductTypeEnum[] = [
  ProductTypeEnum.ADVANCE,
];

export const ONE_TIME_PRODUCT_TYPES = new Set<ProductTypeEnum>([
  ProductTypeEnum.ONETIME,
  ProductTypeEnum.ONETIME_PREPAID_CREDIT,
]);

// copied from FE repo frontend/src/types/quoteTemplate.ts, do not change in this file
export interface QuoteTemplateConfigSchema {
  showCredits: boolean;
  showProration: boolean;
  showPricePerUnit: boolean;
  showProductDescription: boolean;
  showPricing: boolean;
  showRateDescription?: boolean;
  showOfferingDescription?: boolean; // need to remove when BE stops supporting this
  showUnusedItems?: boolean;
}

// copied from FE repo frontend/src/types/miscTypes.ts, do not change in this file
export enum NumberStyleEnum {
  DECIMAL = 'decimal',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  UNIT = 'unit',
}

// copied from FE repo frontend/src/types/miscTypes.ts, do not change in this file
export interface FormatNumberOptions {
  style?: NumberStyleEnum;
  zeroStr?: string;
  currency?: string;
  currencySign?: 'standard' | 'accounting';
  minimumFractionDigits?: number;
  maximumFractionDigits?: 'max' | number; // JS max is 20
}

// only properties needed for PDF included:
export interface IInvoiceItemRespSchema {
  amount: number;
  amountWithoutDiscount: number;
  description: string | null;
  discount: number;
  offeringDescription: string;
  offeringName: string;
  productName: string;
  productType: ProductTypeEnum;
  prorationMultiplier: number;
  quantity: number;
  rateName: string;
  subscriptionId: string;
}

export const QUOTE_TYPE_FOR_SHOWING_ADDITIONAL_INFO = new Set([
  QuoteTypeEnum.AMENDMENT,
  QuoteTypeEnum.RENEWAL,
]);

export interface invoiceSettings {
  invoiceEmailingConfig: {
    automatic: boolean;
  };
  invoiceTerms: {
    invoiceTermsActive: boolean;
    invoiceTerms: string;
  };
  invoiceAutomation: {
    invoiceCreation: 'AUTOMATIC' | 'AUTOMATIC_EXCEPT_CONTRACTS' | 'MANUAL';
    invoiceDelay: number;
  };
  invoiceTemplateConfig: {
    showUsageWithoutEvents: boolean;
    showOffering: boolean;
  };
}
